import { IUser } from "Shared/Types/user";
import React from "react";
import styles from "./Details.module.scss";

import { Grid } from "@mui/material";
import InfoField from "Components/UI/InfoField/InfoField";
import Block from "Components/UI/Block/Block";
import AvatarDefaultIcon from "Assets/default-avatar.svg";

type DetailsProps = {
  employee: IUser;
};

const Details: React.FC<DetailsProps> = ({ employee }) => {
  return (
    <div className={styles.details}>
      <div className={styles.details__avatar}>
        {employee?.avatar ? (
          <img src={employee.avatar.toString()} alt="" />
        ) : (
          <img src={AvatarDefaultIcon} alt="default-icon" />
        )}
      </div>
      <Grid container className={styles.content} rowSpacing={2} rowGap={3}>
        <Grid container item xs={12} columnSpacing={2} rowGap={2}>
          <Grid sm={6} xs={12} item className={styles.details__block}>
            <Block>
              <h5 className={styles.details__block__title}>Basic Info</h5>
              <div className={styles.details__block__fields}>
                <InfoField
                  label="First name"
                  labelColor="gray"
                  value={employee.first_name}
                />
                <InfoField
                  label="Last name"
                  labelColor="gray"
                  value={employee.last_name}
                />
                <InfoField
                  label="Email"
                  labelColor="gray"
                  value={employee.email}
                />
                <InfoField
                  label="Phone"
                  labelColor="gray"
                  value={employee.phone}
                />
              </div>
            </Block>
          </Grid>
          <Grid sm={6} xs={12} item className={styles.details__block}>
            <Block>
              <h5 className={styles.details__block__title}>Address Info</h5>
              <div className={styles.details__block__fields}>
                <InfoField
                  label="Street address"
                  labelColor="gray"
                  value={employee.street_address}
                />
                <InfoField
                  label="Street address 2"
                  labelColor="gray"
                  value={employee.street_address_2}
                />
                <InfoField
                  label="State"
                  labelColor="gray"
                  value={employee.state}
                />
                <InfoField
                  label="City"
                  labelColor="gray"
                  value={employee.city}
                />
                <InfoField
                  label="Country"
                  labelColor="gray"
                  value={employee.country}
                />
                <InfoField
                  label="Zip/Postal Code"
                  labelColor="gray"
                  value={employee.zip_code}
                />
              </div>
            </Block>
          </Grid>
        </Grid>

        <Grid container item xs={12} columnSpacing={2} rowGap={2}>
          <Grid md={6} xs={12} item className={styles.details__block}>
            <Block>
              <h5 className={styles.details__block__title}>Access</h5>
              <div className={styles.details__block__fields}>
                <InfoField
                  label="Assigned shop"
                  labelColor="gray"
                  value={employee.shop?.shop_name}
                />
                <InfoField
                  label="Role"
                  labelColor="gray"
                  value={employee.role?.name}
                />
              </div>
            </Block>
          </Grid>
          <Grid md={6} xs={12} item className={styles.details__block}>
            <Block>
              <h5 className={styles.details__block__title}>
                Additional details
              </h5>
              <div className={styles.details__block__fields}>
                <InfoField
                  label="Hire date"
                  labelColor="gray"
                  value={employee.created_at}
                />
                <InfoField
                  label="Additional information"
                  labelColor="gray"
                  value={employee.last_login}
                />
              </div>
            </Block>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};
export default Details;
