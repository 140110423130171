import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "Service/api/api";
import {
  IDispatchCallback,
  IDispatchCallbackWithId,
  IDispatchCallbackWithoutData,
} from "Shared/Types/common";
import {
  IActionPartForm,
  IActionPartUpdateByField,
  IActionPartWaitinOnVendor,
} from "Shared/Types/action-item-part";
import { errorHandler } from "Shared/Utils/app";

const getActionPartList = createAsyncThunk(
  "actionPart/getActionPartList",
  async () => {
    try {
      const response = await api.actionPart.getActionPartList();
      return response.data;
    } catch (error) {
      if (error instanceof Error) {
        errorHandler(error);
      }
      throw error;
    }
  }
);

const getActionPart = createAsyncThunk(
  "actionPart/getActionPart",
  async (id: string) => {
    try {
      const response = await api.actionPart.getActionPart(id);
      return response.data;
    } catch (error) {
      if (error instanceof Error) {
        errorHandler(error);
      }
      throw error;
    }
  }
);

const createActionPart = createAsyncThunk(
  "actionPart/createActionPart",
  async ({ data, onSuccess }: IDispatchCallback<IActionPartForm>) => {
    try {
      const response = await api.actionPart.addActionPart({
        ...data,
        vendor: data.vendor?.id,
        price: data.price || 0,
      });
      onSuccess?.();
      return response.data;
    } catch (error) {
      if (error instanceof Error) {
        errorHandler(error, true);
      }
      throw error;
    }
  }
);

const updateActionPart = createAsyncThunk(
  "actionPart/updateActionPart",
  async ({ id, data, onSuccess }: IDispatchCallbackWithId<IActionPartForm>) => {
    try {
      const response = await api.actionPart.updateActionPart(id, {
        ...data,
        vendor: data.vendor?.id,
      });
      onSuccess?.();
      return response.data;
    } catch (error) {
      if (error instanceof Error) {
        errorHandler(error, true);
      }
      throw error;
    }
  }
);

const updateActionPartByField = createAsyncThunk(
  "order/updateActionItemByField",
  async ({
    id,
    data: { field, value },
    onSuccess,
  }: IDispatchCallbackWithId<IActionPartUpdateByField>) => {
    try {
      const response = await api.actionPart.updateActionPartField(id, {
        [field]: value,
      });
      onSuccess?.();
      return response.data;
    } catch (error) {
      if (error instanceof Error) {
        errorHandler(error, true);
      }
      throw error;
    }
  }
);

const waitingOnVendor = createAsyncThunk(
  "actionPart/waitingOnVendor",
  async ({
    id,
    data,
    onSuccess,
  }: IDispatchCallbackWithId<IActionPartWaitinOnVendor>) => {
    try {
      const response = await api.actionPart.waitingOnVendor(id, data);
      onSuccess?.();
      return response.data;
    } catch (error) {
      if (error instanceof Error) {
        errorHandler(error, true);
      }
      throw error;
    }
  }
);

const deleteActionPart = createAsyncThunk(
  "actionPart/deleteActionPart",
  async ({ id, onSuccess }: IDispatchCallbackWithoutData) => {
    try {
      const response = await api.actionPart.deleteActionPart(id);
      onSuccess?.();
      return response.data;
    } catch (error) {
      if (error instanceof Error) {
        errorHandler(error, true);
      }
      throw error;
    }
  }
);

const getActionItemPartStatuses = createAsyncThunk(
  "actionPart/getActionItemPartStatuses",
  async () => {
    try {
      const response = await api.actionPart.getActionItemPartStatuses();
      return response.data;
    } catch (error) {
      if (error instanceof Error) {
        errorHandler(error);
      }
      throw error;
    }
  }
);

export {
  getActionPartList,
  getActionPart,
  createActionPart,
  updateActionPart,
  deleteActionPart,
  updateActionPartByField,
  waitingOnVendor,
  getActionItemPartStatuses,
};
