import React, { useState } from "react";
import styles from "./Row.module.scss";
import StatusView from "Components/StatusView/StatusView";
import More from "Components/More/More";
import cn from "classnames";
import { toCamelCase } from "Shared/Utils/app";
import { CardProps } from "../Card/Card";

const Row: React.FC<CardProps> = ({
  dataId,
  fields,
  className,
  onClick,
  openDeleteModal,
  isNeedStatus,
  status,
  cardData,
  withoutMore,
}) => {
  const [openMore, setOpenMore] = useState(false);
  const closeMore = () => {
    setOpenMore(false);
  };
  return (
    <div
      className={cn(styles.row, className)}
      onClick={() => onClick?.(dataId)}
      onMouseLeave={closeMore}
    >
      <div className={cn(styles.row__body)}>
        {isNeedStatus && (
          <span className={styles.row__body__item}>
            <StatusView
              title={status?.title || ""}
              color={toCamelCase(status?.color || "")}
              className={styles.card__head__status}
            />
          </span>
        )}
        {cardData.map(({ title, value, isValueBlue }) => (
          <div
            className={cn(
              styles.row__body__item,
              !fields.includes(title) && styles.row__body__item_hidden
            )}
            key={title}
          >
            <span>{title}</span>
            <span className={cn(isValueBlue && styles.row__body__item__blue)}>
              {value}
            </span>
          </div>
        ))}
        {!withoutMore && (
          <div
            className={cn(
              styles.row__body__item,
              styles.row__body__item_withoutBorder
            )}
          >
            <More
              openDelete={() => openDeleteModal?.(dataId)}
              openMore={openMore}
              setOpenMore={setOpenMore}
            />
          </div>
        )}
      </div>
    </div>
  );
};
export default Row;
