export const read = (key: string, isSession?: boolean) => {
  let data = localStorage.getItem(key);
  if (isSession) {
    data = sessionStorage.getItem(key);
  }
  return data;
};

export const readObj = (key: string, isSession?: boolean) => {
  let data = localStorage.getItem(key);
  if (isSession) {
    data = sessionStorage.getItem(key);
  }
  if (data && data != "undefined") {
    return JSON.parse(data);
  }
};

export const write = (
  key: string,
  value: string | object,
  isSession?: boolean
) => {
  if (typeof value === "object") {
    value = JSON.stringify(value);
  }
  if (isSession) {
    sessionStorage.setItem(key, value);
  } else {
    localStorage.setItem(key, value);
  }
};

export const clear = (key: string, isSession?: boolean) => {
  if (isSession) {
    sessionStorage.removeItem(key);
  } else {
    localStorage.removeItem(key);
  }
};

export const clearAll = (isSession?: boolean) => {
  if (isSession) {
    sessionStorage.clear();
  } else {
    localStorage.clear();
  }
};

export function getCookie(name: string) {
  let cookieValue = null;
  if (document.cookie && document.cookie !== "") {
    const cookies = document.cookie.split(";");
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();
      if (cookie.substring(0, name.length + 1) === name + "=") {
        cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
        break;
      }
    }
  }
  return cookieValue;
}
