import React from "react";
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import styles from './TariffCard.module.scss';

interface TariffCard {
  title: string;
  price: number;
  services: string[];
}

const TariffCard:React.FC<TariffCard> = ({title, price, services}) => {
  return <Card className={styles.tariffcard} sx={{ maxWidth: 345, minWidth: 244, boxShadow: 'none'}}>
    <CardHeader className={styles.tariffcard__header}
      title={title}
    />
    <CardContent>
      <p className={styles.tariffcard__price}>
        $ {price.toFixed(2)}
      </p>
      <ul className={styles.tariffcard__list}>
        {services.map(service => (
          <li className={styles.tariffcard__list__item} key={service}>{service}</li>
        ))}
      </ul>
      <button className={styles.tariffcard__btn}>Edit</button>
    </CardContent>
  </Card>
};

export default TariffCard;