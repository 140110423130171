import { ICompany } from "Shared/Types/company";
import React, { useState } from "react";
import ListLayout from "Components/Layout/ListLayout/ListLayout";
import { paymentFields } from "Shared/Constants/payment";
import Modal from "Components/UI/Modal/Modal";
import { tariffTable } from "Shared/Constants/company";
import { IPayment } from "Shared/Types/payment";
import Card from "Components/UI/Card/Card/Card";
import { getCompanyCardData } from "Shared/Utils/payment";
import Row from "Components/UI/Card/Row/Row";

type PaymentHistoryProps = {
  company: ICompany;
};

const PaymentHistory: React.FC<PaymentHistoryProps> = () => {
  const [isCard, setIsCard] = useState(true);
  const [fields, setFields] = useState(paymentFields);
  const [openDialog, setOpenDialog] = useState(false);

  const handleClick = () => {
    setOpenDialog(true);
  };
  
  const props =(payment: IPayment) => {
    return {
      dataId: payment.id,
      fields: fields,
      onClick: handleClick,
      cardData: getCompanyCardData(payment),
    };
  }

  return (
    <div>
      <ListLayout
        pagesNumber={1}
        isCard={isCard}
        setIsCard={setIsCard}
        fields={fields}
        setFields={setFields}
        filters={["search"]}
        fieldAllList={paymentFields}
      >
        {isCard
          ? tariffTable.map((payment) => (
              <Card key={payment.id} {...props(payment)} />
            ))
          : tariffTable.map((payment) => (
              <Row key={payment.id} {...props(payment)} />
            ))}
        <Modal
          isClosed={openDialog}
          open={false}
          onClose={() => setOpenDialog(false)}
          title={"Invoice"}
        />
      </ListLayout>
    </div>
  );
};
export default PaymentHistory;
