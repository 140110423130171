import React from "react";
import Button from "Components/UI/Button/Button";
import { useAppDispatch } from "Redux/store";
import { IOrderInvoice } from "Shared/Types/invoice";
import styles from "./InviocePay.module.scss";
import { notifySuccess } from "Components/Notify/notify";
import { payOrderInvoice } from "Redux/OrderInvoice/reducer";
import { getOrder } from "Redux/Order/reducer";

type InvoicePayProps = {
  invoice: IOrderInvoice;
};

const InvoicePay: React.FC<InvoicePayProps> = ({ invoice }) => {
  const dispatch = useAppDispatch();
  const isPaid = invoice.is_paid;

  const payInvoice = () => {
    const onSuccess = () => {
      notifySuccess("Invoice is paid!");
      dispatch(getOrder(invoice.order));
    };
    dispatch(payOrderInvoice({ id: invoice.id, onSuccess }));
  };
  return (
    <div className={styles.action}>
      <Button>Capture Signature</Button>
      <Button color={isPaid ? "secondary" : "primary"} onClick={payInvoice}>
        Pay Invoice
      </Button>
    </div>
  );
};
export default InvoicePay;
