import React, { ReactNode } from "react";
import {
  Table as MuiTable,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";

import styles from "./Table.module.scss";
import { reformatData } from "Shared/Utils/reformatDate";
import cn from "classnames";

interface Column {
  id: string;
  label: string;
  renderCell?: (columnId: string) => ReactNode;
  width?: string;
}

interface TableProps {
  columns: Column[];
  data: { [key: string]: any }[];
  renderCell?: (
    cellData: any,
    columnId: string,
    row: any,
    openDelete?: (id: string) => void
  ) => ReactNode;
  onRowClick?: (data: any) => void;
  openDelete?: (id: string) => void;
  title?: string;
  isInDetailPage?: boolean;
}

const Table: React.FC<TableProps> = ({
  columns,
  data,
  renderCell,
  onRowClick,
  openDelete,
  title,
  isInDetailPage,
}) => {
  return (
    <div
      className={cn(
        styles.container,
        isInDetailPage && styles.container_inDetail
      )}
    >
      {title && <h4 className={styles.container__title}>{title}</h4>}
      <div className={styles.tableWrapper}>
        <MuiTable
          sx={{ borderSpacing: "0 10px", borderCollapse: "separate" }}
          className={styles.table}
        >
          <colgroup>
            {columns.map((col) => (
              <col key={col.id} style={{ width: col.width }} />
            ))}
          </colgroup>
          <TableHead style={{ width: "100%" }}>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  align={
                    column.id === "default" || column.id === "action"
                      ? "center"
                      : "inherit"
                  }
                  key={column.id}
                >
                  {column.renderCell
                    ? column.renderCell(column.id)
                    : column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row, index) => (
              <TableRow
                sx={{ marginTop: "5px" }}
                key={row.id}
                onClick={() => onRowClick?.(row)}
              >
                {columns.map((column) => {
                  if (column.id === "created_at") {
                    const date = reformatData(row[column.id]);
                    return <TableCell key={column.id}>{date}</TableCell>;
                  }
                  if (column.id === "index") {
                    return <TableCell key={column.id}>{index + 1}</TableCell>;
                  }
                  return (
                    <TableCell
                      align={
                        column.id === "default" || column.id === "action"
                          ? "center"
                          : "inherit"
                      }
                      key={column.id}
                    >
                      {renderCell
                        ? renderCell(row[column.id], column.id, row, openDelete)
                        : row[column.id]}
                    </TableCell>
                  );
                })}
              </TableRow>
            ))}
          </TableBody>
        </MuiTable>
      </div>
    </div>
  );
};

export default Table;
