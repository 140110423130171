import React, { ReactNode } from "react";
import FilterPanel from "Components/Order/FilterPanel/FilterPanel";
import styles from "./ListLayout.module.scss";
import cn from "classnames";
import Pagination from "Components/UI/Pagination/Pagination";
import { ISortField } from "Shared/Types/common";

type ListLayoutProps = {
  pagesNumber: number;
  children: ReactNode;
  isCard: boolean;
  setIsCard: (isCard: boolean) => void;
  fields: string[];
  setFields: (fields: string[]) => void;
  fieldAllList: string[];
  limitFields?: number;
  filters: string[];
  filters2?: string[];
  showImportExportIcons?: boolean;
  sortFields?: ISortField[];
};

const ListLayout: React.FC<ListLayoutProps> = ({
  children,
  isCard,
  setIsCard,
  fields,
  setFields,
  fieldAllList,
  limitFields,
  pagesNumber,
  filters,
  filters2,
  showImportExportIcons,
  sortFields,
}) => {
  return (
    <div className={styles.layout}>
      <div className={styles.layout__addOrder}></div>
      <FilterPanel
        setIsCard={setIsCard}
        isCard={isCard}
        fields={fields}
        setFields={setFields}
        fieldAllList={fieldAllList}
        limitFields={limitFields}
        filters={filters}
        filters2={filters2}
        showImportExportIcons={showImportExportIcons}
        sortFields={sortFields}
      />
      <div className={styles.layout__content}>
        <div
          className={cn(
            styles.layout__container,
            !isCard && styles.layout__container_row
          )}
        >
          {children}
        </div>
        {pagesNumber > 1 && <Pagination count={pagesNumber} />}
      </div>
    </div>
  );
};
export default ListLayout;
