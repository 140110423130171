import SearchField from "Components/Layout/Inputs/SearchField";
import { getGlobalSearch } from "Redux/GlobalSearch/reducer";
import { useAppDispatch } from "Redux/store";
import React, { useEffect, useState } from "react";
import SearchResult from "./SearchResult/SearchResult";
import styles from "./GlobalSearch.module.scss";
import { clear } from "Redux/GlobalSearch/slice";

const GlobalSearch: React.FC = () => {
  const [open, setOpen] = useState(false);
  const dispatch = useAppDispatch();
  const [searchValue, setSearchValue] = useState<string>();

  const modalRef = React.createRef<HTMLDivElement>(); // Create a ref for the modal

  const onSearch = (value?: string) => {
    setSearchValue(value);
    dispatch(
      getGlobalSearch({
        query: value || null,
      })
    );
  };

  const onClose = () => {
    if (!searchValue || searchValue === "") {
      dispatch(clear());
    }

    setOpen(false);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        modalRef.current &&
        !modalRef.current.contains(event.target as Node)
      ) {
        onClose();
      }
    };

    document.body.addEventListener("click", handleClickOutside);

    return () => {
      document.body.removeEventListener("click", handleClickOutside);
    };
  }, [modalRef]);
  return (
    <div className={styles.search} ref={modalRef}>
      <SearchField
        placeholder="Search"
        onChange={(event) => onSearch(event.target.value)}
        onFocus={() => setOpen(true)}
        autoComplete="off"
        isGlobal
        fullWidth
      />
      {open && <SearchResult onClose={onClose} />}
    </div>
  );
};
export default GlobalSearch;
