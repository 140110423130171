import React, { useEffect } from "react";
import styles from "./EmployeeUpdate.module.scss";
import EmployeeForm from "Components/Employee/EmployeeForm/EmployeeForm";
import { IUserUpdate } from "Shared/Types/user";
import { notifySuccess } from "Components/Notify/notify";
import { getUser, updateUser } from "Redux/User/reducer";
import { selectCurrentUser } from "Redux/User/userSlice";
import { useAppDispatch } from "Redux/store";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import { userSchema } from "Shared/Utils/user";

export const EmployeeUpdate: React.FC = () => {
  const dispatch = useAppDispatch();
  const param = useParams();
  const employee = useSelector(selectCurrentUser);
  const navigate = useNavigate();
  const onSubmit = async (values: IUserUpdate) => {
    if (employee) {
      await dispatch(
        updateUser({
          id: employee.id,
          data: values,
          onSuccess: () => {
            notifySuccess("Updated Employee!");
            navigate("/employees");
          },
        }),
      );
    }
  };

  useEffect(() => {
    if (param.id) {
      dispatch(getUser(param.id));
    }
  }, [param.id]);

  return (
    <div className={styles.addEmployee}>
      <h3 className={styles.addEmployee__title}></h3>
      {employee && (
        <EmployeeForm
          onSubmit={onSubmit}
          type="update"
          scheme={userSchema}
          employee={{
            ...employee,
            role: employee.role?.id,
            shop: employee.shop?.id,
          }}
        />
      )}
    </div>
  );
};
