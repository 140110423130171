import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  IDispatchCallback,
  IDispatchCallbackWithId,
  IDispatchCallbackWithoutData,
} from "Shared/Types/common";
import {
  IEstimateOrderAdd,
  IEstimateOrderUpdate,
  IEstimateOrderUpdateByField,
  IEstimatePartUpdate,
  IEstimatePartUpdateByField,
  IEstimateServiceUpdate,
  IEstimateServiceUpdateByField,
  IEstimatesOrderSearchParams,
  IEstimatesPartAdd,
  IEstimatesServiceAdd,
} from "Shared/Types/estimate";
import { readObj } from "Service/storage";
import api from "Service/api/api";
import { errorHandler } from "Shared/Utils/app";

const getEstimatesOrderList = createAsyncThunk(
  "order/getEstimatesOrderList",
  async (params: IEstimatesOrderSearchParams | null) => {
    try {
      const shop = readObj("current_shop");
      const response = await api.estimate.getEstimatesOrderList({
        ...params,
        shop: shop.id,
      });
      return response.data;
    } catch (error) {
      if (error instanceof Error) {
        errorHandler(error);
      }
      throw error;
    }
  },
);
const getOpenEstimatesOrderList = createAsyncThunk(
  "order/getOpenEstimatesOrderList",
  async (params: IEstimatesOrderSearchParams | null) => {
    try {
      const shop = readObj("current_shop");
      const response = await api.estimate.getEstimatesOrderList({
        ...params,
        shop: shop.id,
        status: "Active",
      });
      return response.data;
    } catch (error) {
      if (error instanceof Error) {
        errorHandler(error);
      }
      throw error;
    }
  },
);
const getClosedEstimatesOrderList = createAsyncThunk(
  "order/getClosedEstimatesOrderList",
  async (params: IEstimatesOrderSearchParams | null) => {
    try {
      const shop = readObj("current_shop");
      const response = await api.estimate.getEstimatesOrderList({
        ...params,
        shop: shop.id,
        status: "Archived",
      });
      return response.data;
    } catch (error) {
      if (error instanceof Error) {
        errorHandler(error);
      }
      throw error;
    }
  },
);
const createEstimateOrder = createAsyncThunk(
  "order/createEstimateOrder",
  async ({ data, onSuccess }: IDispatchCallback<IEstimateOrderAdd>) => {
    try {
      const shop = readObj("current_shop");
      const response = await api.estimate.addEstimatesOrder(data, {
        shop: shop.id,
      });
      onSuccess?.();
      return response.data;
    } catch (error) {
      if (error instanceof Error) {
        errorHandler(error, true);
      }
      throw error;
    }
  },
);
const getEstimateOrder = createAsyncThunk(
  "order/getEstimateOrder",
  async (id: string) => {
    try {
      const shop = readObj("current_shop");
      const response = await api.estimate.getEstimateOrder(id, {
        shop: shop.id,
      });
      return response.data;
    } catch (error) {
      if (error instanceof Error) {
        errorHandler(error);
      }
      throw error;
    }
  },
);
const updateEstimateOrder = createAsyncThunk(
  "order/updateEstimateOrder",
  async ({
    id,
    data,
    onSuccess,
  }: IDispatchCallbackWithId<IEstimateOrderUpdate>) => {
    try {
      const shop = readObj("current_shop");
      const response = await api.estimate.updateEstimateOrder(id, data, {
        shop: shop.id,
      });
      onSuccess?.();
      return response.data;
    } catch (error) {
      if (error instanceof Error) {
        errorHandler(error, true);
      }
      throw error;
    }
  },
);
const updateEstimateOrderByField = createAsyncThunk(
  "order/updateEstimateOrderByField",
  async ({
    id,
    data: { field, value },
    onSuccess,
  }: IDispatchCallbackWithId<IEstimateOrderUpdateByField>) => {
    try {
      const shop = readObj("current_shop");
      const response = await api.estimate.updateEstimateOrder(
        id,
        {
          [field]: value,
        },
        {
          shop: shop.id,
        },
      );
      onSuccess?.();
      return response.data;
    } catch (error) {
      if (error instanceof Error) {
        errorHandler(error, true);
      }
      throw error;
    }
  },
);
const deleteEstimateOrder = createAsyncThunk(
  "order/deleteEstimateOrder",
  async ({ id, onSuccess }: IDispatchCallbackWithoutData) => {
    try {
      const shop = readObj("current_shop");
      const response = await api.estimate.deleteEstimatesOrder(id, {
        shop: shop.id,
      });
      onSuccess?.();
      return response.data;
    } catch (error) {
      if (error instanceof Error) {
        errorHandler(error, true);
      }
      throw error;
    }
  },
);
const createEstimateService = createAsyncThunk(
  "order/createEstimateService",
  async ({ data, onSuccess }: IDispatchCallback<IEstimatesServiceAdd>) => {
    try {
      const shop = readObj("current_shop");
      const response = await api.estimate.addEstimatesService(data, {
        shop: shop.id,
      });
      onSuccess?.();
      return response.data;
    } catch (error) {
      if (error instanceof Error) {
        errorHandler(error, true);
      }
      throw error;
    }
  },
);
const getEstimateService = createAsyncThunk(
  "order/getEstimateService",
  async (id: string) => {
    try {
      const shop = readObj("current_shop");
      const response = await api.estimate.getEstimateService(id, {
        shop: shop.id,
      });

      const estimatePartList = await api.estimate.getEstimatePartList({
        service: id,
        shop: shop.id,
      });

      return {
        estimateService: response.data,
        estimatePartList: estimatePartList.data,
      };
    } catch (error) {
      if (error instanceof Error) {
        errorHandler(error);
      }
      throw error;
    }
  },
);
const getEstimateServiceList = createAsyncThunk(
  "order/getEstimateServiceList",
  async (params: IEstimatesOrderSearchParams) => {
    try {
      const shop = readObj("current_shop");
      const response = await api.estimate.getEstimateServiceList({
        ...params,
        shop: shop.id,
      });
      return response.data;
    } catch (error) {
      if (error instanceof Error) {
        errorHandler(error);
      }
      throw error;
    }
  },
);
const updateEstimateService = createAsyncThunk(
  "order/updateEstimateService",
  async ({
    id,
    data,
    onSuccess,
  }: IDispatchCallbackWithId<IEstimateServiceUpdate>) => {
    try {
      const shop = readObj("current_shop");
      const response = await api.estimate.updateEstimateService(id, data, {
        shop: shop.id,
      });
      onSuccess?.();
      return response.data;
    } catch (error) {
      if (error instanceof Error) {
        errorHandler(error, true);
      }
      throw error;
    }
  },
);
const updateEstimateServiceByFields = createAsyncThunk(
  "order/updateEstimateService",
  async ({
    id,
    data: { field, value },
    onSuccess,
    onError,
  }: IDispatchCallbackWithId<IEstimateServiceUpdateByField>) => {
    try {
      const shop = readObj("current_shop");
      const response = await api.estimate.updateEstimateService(
        id,
        {
          [field]: value,
        },
        {
          shop: shop.id,
        },
      );
      onSuccess?.();
      return response.data;
    } catch (error) {
      if (error instanceof Error) {
        errorHandler(error, true);
      } else {
        onError?.();
      }
      throw error;
    }
  },
);
const deleteEstimateService = createAsyncThunk(
  "order/deleteEstimateService",
  async ({ id, onSuccess }: IDispatchCallbackWithoutData) => {
    try {
      const shop = readObj("current_shop");
      const response = await api.estimate.deleteEstimatesService(id, {
        shop: shop.id,
      });
      onSuccess?.();
      return response.data;
    } catch (error) {
      if (error instanceof Error) {
        errorHandler(error, true);
      }
      throw error;
    }
  },
);
const createEstimatePart = createAsyncThunk(
  "order/createEstimatePart",
  async ({ data, onSuccess }: IDispatchCallback<IEstimatesPartAdd>) => {
    try {
      const shop = readObj("current_shop");
      const response = await api.estimate.addEstimatesPart(data, {
        shop: shop.id,
      });
      onSuccess?.();
      return response.data;
    } catch (error) {
      if (error instanceof Error) {
        errorHandler(error, true);
      }
      throw error;
    }
  },
);
const deleteEstimatePart = createAsyncThunk(
  "order/deleteEstimatePart",
  async ({ id, onSuccess }: IDispatchCallbackWithoutData) => {
    try {
      const shop = readObj("current_shop");
      const response = await api.estimate.deleteEstimatesPart(id, {
        shop: shop.id,
      });
      onSuccess?.();
      return response.data;
    } catch (error) {
      if (error instanceof Error) {
        errorHandler(error, true);
      }
      throw error;
    }
  },
);
const updateEstimatePart = createAsyncThunk(
  "order/updateEstimatePart",
  async ({
    id,
    data,
    onSuccess,
  }: IDispatchCallbackWithId<IEstimatePartUpdate>) => {
    try {
      const shop = readObj("current_shop");
      const response = await api.estimate.updateEstimatePart(id, data, {
        shop: shop.id,
      });
      onSuccess?.();
      return response.data;
    } catch (error) {
      if (error instanceof Error) {
        errorHandler(error, true);
      }
      throw error;
    }
  },
);
const updateEstimatePartByFields = createAsyncThunk(
  "order/updateEstimatePartByFields",
  async ({
    id,
    data: { field, value },
    onSuccess,
  }: IDispatchCallbackWithId<IEstimatePartUpdateByField>) => {
    try {
      const shop = readObj("current_shop");
      const response = await api.estimate.updateEstimatePart(
        id,
        {
          [field]: value,
        },
        {
          shop: shop.id,
        },
      );
      onSuccess?.();
      return response.data;
    } catch (error) {
      if (error instanceof Error) {
        errorHandler(error, true);
      }
      throw error;
    }
  },
);
const getEstimatePart = createAsyncThunk(
  "order/getEstimatePart",
  async (id: string) => {
    try {
      const shop = readObj("current_shop");
      const response = await api.estimate.getEstimatePart(id, {
        shop: shop.id,
      });
      return response.data;
    } catch (error) {
      if (error instanceof Error) {
        errorHandler(error);
      }
      throw error;
    }
  },
);
const getEstimatePartList = createAsyncThunk(
  "order/getEstimatePartList",
  async (params: IEstimatesOrderSearchParams) => {
    try {
      const shop = readObj("current_shop");
      const response = await api.estimate.getEstimatePartList({
        ...params,
        shop: shop.id,
      });
      return response.data;
    } catch (error) {
      if (error instanceof Error) {
        errorHandler(error);
      }
      throw error;
    }
  },
);
const convertToServiceOrder = createAsyncThunk(
  "order/convertToServiceOrder",
  async ({ id, onSuccess }: IDispatchCallbackWithoutData) => {
    try {
      const response = await api.estimate.convertToOrder(id);
      onSuccess?.();
      return response.data;
    } catch (error) {
      if (error instanceof Error) {
        errorHandler(error, true);
      }
      throw error;
    }
  },
);
export {
  createEstimateOrder,
  getEstimateOrder,
  updateEstimateOrder,
  updateEstimateOrderByField,
  deleteEstimateOrder,
  getEstimatesOrderList,
  getOpenEstimatesOrderList,
  getClosedEstimatesOrderList,
  getEstimateService,
  getEstimateServiceList,
  updateEstimateService,
  updateEstimateServiceByFields,
  deleteEstimateService,
  createEstimateService,
  getEstimatePartList,
  getEstimatePart,
  createEstimatePart,
  deleteEstimatePart,
  updateEstimatePart,
  updateEstimatePartByFields,
  convertToServiceOrder,
};
