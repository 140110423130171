import React, { useEffect } from "react";
import { Control } from "react-hook-form";
import styles from "./UnitForm.module.scss";
import { Grid } from "@mui/material";
import { IUnitAdd } from "Shared/Types/unit";
import SelectField from "Components/UI/SelectField/SelectField";
import InputField from "Components/UI/Inputs/InputField";
import { useSelector } from "react-redux";
import { selectSubtypeList } from "Redux/Subtype/slice";
import { selectUnitTypeList } from "Redux/UnitType/slice";
import { useAppDispatch } from "Redux/store";
import { getSubtypeList } from "Redux/Subtype/reducer";
import { getUnitTypeList } from "Redux/UnitType/reducer";
import { selectStates } from "Redux/Address/slice";
import { getStates } from "Redux/Address/reducer";
import { CustomerSearch } from "Components/UI/AutoCompleteSearchInputs/CustomerSearch";
import { usaStates } from "Shared/Constants/units";

type BasicInfoProps = {
  control: Control<IUnitAdd>;
};

const BasicInfo: React.FC<BasicInfoProps> = ({ control }) => {
  const subtypes = useSelector(selectSubtypeList) || [];
  const unitTypes = useSelector(selectUnitTypeList) || [];
  const states = useSelector(selectStates) || [];
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getSubtypeList(null));
    dispatch(getUnitTypeList(null));
    dispatch(getStates(6252001));
  }, []);

  return (
    <div className={styles.block}>
      <h4>Basic Info</h4>
      <Grid container>
        <Grid xs={12} item>
          <CustomerSearch label="Customer" name="customer" required />
        </Grid>
        <Grid xs={12} item>
          <SelectField
            placeholder="Select one"
            control={control}
            name="type_of_chassis"
            options={unitTypes.map(({ name, id }) => ({
              label: name,
              value: id,
            }))}
            label="Type of chassis"
            withControl
            required
          />
        </Grid>
        <Grid xs={12} item>
          <SelectField
            placeholder="Select one"
            control={control}
            name="subtype"
            options={subtypes.map(({ name, id }) => ({
              label: name,
              value: id,
            }))}
            label="Subtype"
            withControl
            required
          />
        </Grid>
        <Grid xs={12} item>
          <InputField
            control={control}
            name="name"
            label="Name"
            withController
            required
          />
        </Grid>
        <Grid xs={12} item>
          <InputField
            control={control}
            name="vin"
            label="VIN"
            withController
            required
          />
        </Grid>
        <Grid xs={12} item>
          <SelectField
            placeholder="Select one"
            control={control}
            name="license_plate"
            options={usaStates.map((state) => ({
              label: state,
              value: state,
            }))}
            label="License Plate State"
            withControl
          />
        </Grid>
        <Grid container item xs={12} spacing={1}>
          <Grid xs={4} item>
            <InputField
              control={control}
              name="year"
              label="Year"
              withController
            />
          </Grid>
          <Grid xs={8} item>
            <InputField
              control={control}
              name="brand"
              label="Make"
              withController
            />
          </Grid>
        </Grid>
        <Grid xs={8} item>
          <InputField
            control={control}
            name="model"
            label="Model"
            withController
          />
        </Grid>
        <Grid container item xs={12} spacing={1}>
          <Grid xs={6} item>
            <InputField
              control={control}
              name="number"
              label="Unit number"
              withController
              required
            />
          </Grid>
          <Grid xs={6} item>
            <InputField
              control={control}
              name="nickname"
              label="Unit nickname"
              withController
            />
          </Grid>
        </Grid>
        <Grid xs={12} item>
          <InputField
            control={control}
            name="fleet"
            label="Fleet #"
            withController
          />
        </Grid>
      </Grid>
    </div>
  );
};
export default BasicInfo;
