import React, { MouseEvent } from "react";
import {
  IconButtonProps as MUIIconButtonProps,
  IconButton as MUIIconButton,
} from "@mui/material";
import styles from "./IconButton.module.scss";
import Icon from "Components/UI/Icon/Icon";
import cn from "classnames";

interface IconButtonProps
  extends Omit<MUIIconButtonProps, "color" | "onClick"> {
  icon: React.ElementType;
  color?: string;
  withBorder?: boolean;
  label?: string;
  containerClassName?: string;
  variant?: "primary";
  width?: number;
  height?: number;
  isAlwaysOpen?: boolean;
  onClick?: (event: MouseEvent<HTMLDivElement>) => void;
}

const IconButton: React.FC<IconButtonProps> = ({
  icon,
  className,
  color,
  label,
  containerClassName,
  variant,
  width = 21,
  height = 21,
  isAlwaysOpen,
  onClick,
  ...props
}) => {
  return (
    <div style={{ width: label && "160px" }} className={styles.block_cont}>
      <div
        className={cn(
          label ? styles.label_block : styles.block,
          isAlwaysOpen && styles.block__alwaysOpen,
          containerClassName
        )}
        onClick={(event) => {
          onClick?.(event);
        }}
      >
        <MUIIconButton
          size="medium"
          classes={{
            root: cn(styles.btn, variant && styles.btn__primary, className),
          }}
          {...props}
        >
          <Icon component={icon} color={color} width={width} height={height} />
        </MUIIconButton>
        {label && <span className={styles.label_block__label}>{label}</span>}
      </div>
    </div>
  );
};
export default IconButton;
