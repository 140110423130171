import { IActionPart } from "Shared/Types/action-item-part";
import React, { useState } from "react";
import PartInfo from "../PartInfo/PartInfo";
import PartItem from "../PartItem/PartItem";

type PartItemSectionProps = {
  part: IActionPart;
  actionItemId: string;
  openDeleteModal: (id: string) => void;
};

const PartItemSection: React.FC<PartItemSectionProps> = ({
  actionItemId,
  part,
  openDeleteModal,
}) => {
  const [edit, setEdit] = useState(false);
  const handleEdited = () => {
    setEdit(true);
  };
  const handleDisableEdit = () => {
    setEdit(false);
  };
  return (
    <>
      {edit ? (
        <PartItem
          edit={edit}
          actionItemId={actionItemId}
          part={part}
          handleEdited={handleEdited}
          handleDisableEdit={handleDisableEdit}
          openDeleteModal={() => openDeleteModal(part.id)}
        />
      ) : (
        <PartInfo
          part={part}
          handleEdited={handleEdited}
          openDeleteModal={() => openDeleteModal(part.id)}
        />
      )}
    </>
  );
};
export default PartItemSection;
