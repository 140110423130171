import React from "react";
import styles from "./AuthLayout.module.scss";
import Icon from "Components/UI/Icon/Icon";
import {
  GoogleIcon,
  InstagramIcon,
  LinkedinIcon2,
  TelegramIcon,
  WhiteLogo,
  YouTubeIcon,
} from "Assets/index";
import { EASY_SHOP_DESCRIPTION } from "Shared/Constants/auth";
import AuthFormContainer, {
  AuthFormContainerProps,
} from "./AuthFormContainer/AuthFormContainer";
import { useMediaQuery } from "@mui/material";

const icons = [
  LinkedinIcon2,
  GoogleIcon,
  YouTubeIcon,
  InstagramIcon,
  TelegramIcon,
];

const AuthLayout: React.FC<AuthFormContainerProps> = (props) => {
  const isDesktop = useMediaQuery("(min-width: 1600px)");
  return (
    <div className={styles.wrapper}>
      <div className={styles.wrapper__bg}></div>
      <div className={styles.container}>
        <div className={styles.description}>
          <Icon component={WhiteLogo} color="#FFF" />
          <p className={styles.description__text}>{EASY_SHOP_DESCRIPTION}</p>
          <div className={styles.lines}>
            <hr className={styles.lines__line} />
            <hr className={styles.lines__line} />
          </div>
          <div className={styles.description__icons}>
            {icons.map((icon, index) => (
              <Icon
                key={icon.toString() + index}
                component={icon}
                color="transparent"
                height={isDesktop ? 27 : 21}
              />
            ))}
          </div>
        </div>
        <AuthFormContainer {...props} />
      </div>
    </div>
  );
};
export default AuthLayout;
