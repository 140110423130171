import React, { useEffect } from "react";
import styles from "Containers/Settings/Rate/LaborRate/LaborRateUpdate/LaborRateUpdate.module.scss";
import { notifySuccess } from "Components/Notify/notify";
import { useNavigate, useParams } from "react-router";
import { useSelector } from "react-redux";
import { useAppDispatch } from "Redux/store";
import { removeCurrentTaxRate, selectTaxRate } from "Redux/TaxRate/slice";
import TaxForm from "Components/Settings/TaxRate/Form/TaxForm";
import { ITaxRateUpdate } from "Shared/Types/taxRate";
import { getTaxRate, updateTaxRate } from "Redux/TaxRate/recuder";
import { taxRateScheme } from "Shared/Utils/taxRate";

const TaxRateUpdate = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const taxRate = useSelector(selectTaxRate);
  const dispatch = useAppDispatch();

  const editTaxRate = async (values: ITaxRateUpdate) => {
    const onSuccess = () => {
      notifySuccess("Updated TaxRate!");
      back();
    };
    if (taxRate?.id) {
      await dispatch(
        updateTaxRate({ id: taxRate.id, data: values, onSuccess }),
      );
    }
  };

  useEffect(() => {
    if (id) {
      dispatch(getTaxRate(id));
    }
    return () => {
      dispatch(removeCurrentTaxRate());
    };
  }, []);

  const back = () => {
    navigate("/settings/rate-settings/tax-rate");
  };

  return (
    <div className={styles.labor}>
      {taxRate && (
        <TaxForm
          taxRate={taxRate}
          onSubmit={editTaxRate}
          scheme={taxRateScheme}
        />
      )}
    </div>
  );
};

export default TaxRateUpdate;
