import { client } from "Service/axios";
import { SearchResult } from "Shared/Types/common";
import {
  ICustomer,
  ICustomerAdd,
  ICustomerSearchParams,
  ICustomerUpdate,
  ICustomerUpdateStatus,
} from "Shared/Types/customer";

const CUSTOMER_URL = "customer/";

class Customer {
  getCustomerList(params: ICustomerSearchParams | null) {
    return client.get<SearchResult<ICustomer>>(`${CUSTOMER_URL}`, { params });
  }
  getCustomer(id: string) {
    return client.get<ICustomer>(`${CUSTOMER_URL}${id}/`);
  }
  addCustomer(data: ICustomerAdd) {
    return client.post(`${CUSTOMER_URL}create/`, data);
  }
  updateCustomer(id: string, data: ICustomerUpdate) {
    return client.patch(`${CUSTOMER_URL}${id}/update/`, data);
  }
  changeStatus(id: string, data: ICustomerUpdateStatus) {
    return client.patch(`${CUSTOMER_URL}${id}/update/`, data);
  }
  deleteCustomer(id: string) {
    return client.delete(`${CUSTOMER_URL}${id}/delete/`);
  }
}

export default Customer;
