import { client } from "Service/axios";
import { SearchResult } from "Shared/Types/common";
import {
  ICompanyRequest,
  ICompanySearchParams,
  ICompanyRequestAdd,
  ICompanyRequestUpdate,
  ICompanyRequestReject,
} from "Shared/Types/company";

const COMPANY_REQUEST_URL = "company/request/";

class CompanyRequest {
  getCompanyRequestsList(params: ICompanySearchParams | null) {
    return client.get<SearchResult<ICompanyRequest>>(COMPANY_REQUEST_URL, {
      params,
    });
  }
  getCompanyRequest(id: string) {
    return client.get<ICompanyRequest>(`${COMPANY_REQUEST_URL}${id}/`);
  }
  addCompanyRequest(data: ICompanyRequestAdd) {
    return client.post(COMPANY_REQUEST_URL, data);
  }
  updateCompanyRequest(id: string, data: ICompanyRequestUpdate) {
    return client.put(`${COMPANY_REQUEST_URL}${id}/`, data);
  }
  rejectCompanyRequest(id: string, data: ICompanyRequestReject) {
    return client.post(`${COMPANY_REQUEST_URL}${id}/reject/`, data);
  }
  deleteCompanyRequest(id: string) {
    return client.delete(`${COMPANY_REQUEST_URL}${id}/`);
  }
}

export default CompanyRequest;
