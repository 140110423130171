import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import styles from "./InActivateModal.module.scss";
import Button from "../Button/Button";
import Icon from "../Icon/Icon";
import { CloseIcon, InfoIcon } from "Assets/index";
import { customerOnDeleteDependencyOccursMessage } from "Shared/Constants/customers";
import { CustomerExtraError } from "Shared/Types/customer";
import { useNavigate } from "react-router";
import { getCustomer } from "Redux/Customer/reducer";
import { useAppDispatch } from "Redux/store";
import { useSelector } from "react-redux";
import { selectCurrentCustomer } from "Redux/Customer/slice";

interface InActivateModalProps {
  title?: string;
  open: boolean;
  handleClose: (() => void) | ((event: React.MouseEvent) => void);
  text: string;
  inActivate: (() => void) | ((event: React.MouseEvent) => void);
  dependencies?: CustomerExtraError | null;
  inActivateTitle?: string;
  inActivateId?: string | null | undefined;
}

const InActivateModal: React.FC<InActivateModalProps> = ({
  open,
  handleClose,
  text,
  inActivate,
  title = "Delete Customer",
  inActivateTitle = "Make Inactive",
  dependencies,
  inActivateId,
}) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const customer = useSelector(selectCurrentCustomer);

  React.useEffect(() => {
    if (inActivateId) {
      dispatch(getCustomer(inActivateId));
    }
  }, [inActivateId]);

  const handleNavigate = (path: string) => {
    navigate(path);
  };
  const createLinks = (items: string[], basePath: string) =>
    items.map((item: string, index: number) => (
      <>
        <span
          key={item}
          onClick={() => handleNavigate(`${basePath}/${item}`)}
          className={styles.modal__link}
        >
          {item}
        </span>
        {index < items.length - 1 && ", "}
      </>
    ));
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth
      maxWidth="sm"
      classes={{ root: styles.modal }}
    >
      <DialogTitle
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <span className={styles.modal__head}>
          <Icon component={InfoIcon} />
          <span className={styles.modal__head__title}>{title}</span>
        </span>
        <Icon onClick={handleClose} component={CloseIcon} />
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {customerOnDeleteDependencyOccursMessage}
        </DialogContentText>
        {dependencies?.Units && dependencies.Units.length > 0 && (
          <DialogContentText>
            <p className={styles.modal__links}>
              <span className={styles.modal__label}>Units: </span>{" "}
              {createLinks(dependencies.Units, "/units/edit")}
            </p>
          </DialogContentText>
        )}
        {dependencies?.Orders && dependencies.Orders.length > 0 && (
          <DialogContentText>
            <p className={styles.modal__links}>
              <span className={styles.modal__label}>SO’s: </span>{" "}
              {createLinks(dependencies.Orders, "/orders/assigned")}
            </p>
          </DialogContentText>
        )}
      </DialogContent>
      {customer?.status !== "inactive" && (
        <DialogContent style={{ paddingTop: "2px" }}>
          <DialogContentText>{text}</DialogContentText>
        </DialogContent>
      )}

      <DialogActions>
        <Button
          className={styles.dialogWindow__cancelBtn}
          autoFocus
          color="secondary"
          onClick={handleClose}
        >
          Cancel
        </Button>

        {customer?.status !== "inactive" && (
          <Button
            className={styles.dialogWindow__blockBtn}
            onClick={inActivate}
          >
            {inActivateTitle}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default InActivateModal;
