import * as Yup from "yup";

export const supplyRateScheme = Yup.object().shape({
  title: Yup.string().required("Name is required"),
  display_title: Yup.string().required("Display title is required"),
  rate: Yup.number().required("Rate is required"),
  include_in_calculation: Yup.string().required("Field is required"),
  max_amount: Yup.number().required("Field is required"),
  min_amount: Yup.number().required("Field is required"),
  default: Yup.boolean(),
});
