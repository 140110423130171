import React from "react";
import RadarChart from "Components/Diagrams/RadarDiagram";
import OverviewCard from "Containers/Overview/OverviewCard/OverviewCard";
import styles from "./CompanyOverview.module.scss";
import CurveChart from "Components/Diagrams/CurveDiagram";
import GaugeChart from "Components/Diagrams/RadialChart";

const CompanyOverview: React.FC = () => {
  //   TODO: replace hardcoded values
  const indicators = [
    { name: "Scheduled", max: 300 },
    { name: "Invoiced", max: 450 },
    { name: "Completed Work", max: 350 },
    { name: "Work in Progress", max: 10 },
    { name: "Authorized", max: 200 },
  ];

  return (
    <div className={styles.overview}>
      <div className={styles.overview__container}>
        <RadarChart
          indicators={indicators}
          className={styles.overview__chart}
        />
        <div className={styles.overview__stats}>
          <OverviewCard hour="24" title="All requests" percentage="21">
            <CurveChart />
          </OverviewCard>
          <OverviewCard
            hour="12"
            title="Undereview"
            percentage="2"
          >
            <GaugeChart percentage={30} />
          </OverviewCard>
          <OverviewCard
            hour="24"
            title="Rejected"
            percentage="15"
          >
            <GaugeChart percentage={80} />
          </OverviewCard>
        </div>
      </div>
    </div>
  );
};

export default CompanyOverview;
