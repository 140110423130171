import React from "react";
import Modal from "Components/UI/Modal/Modal";
import Button from "Components/UI/Button/Button";
import { useAppDispatch } from "Redux/store";
import { updateUserField } from "Redux/User/reducer";
import { useSelector } from "react-redux";
import { selectAccount } from "Redux/Auth/slice";

type ConfirmDeleteModalProps = {
  open: boolean;
  onClose: () => void;
  closeOptions: () => void;
};

const ConfirmDeleteModal: React.FC<ConfirmDeleteModalProps> = ({
  open,
  onClose,
  closeOptions,
}) => {
  const dispatch = useAppDispatch();
  const account = useSelector(selectAccount);
  const onDeleteAvatar = async () => {
    if (account) {
      await dispatch(updateUserField({ id: account.id, data: { avatar: "" } }));
      onClose();
      closeOptions();
    }
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      isClosed={open}
      title="Are you sure want to delete the avatar"
    >
      <div
        style={{
          display: "flex",
          gap: "30px",
          justifyContent: "end",
          marginTop: "20px",
        }}
      >
        <Button onClick={onClose} variant="outlined">
          Cancel
        </Button>
        <Button onClick={onDeleteAvatar}>Delete</Button>
      </div>
    </Modal>
  );
};

export default ConfirmDeleteModal;
