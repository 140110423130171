import { client } from "Service/axios";
import { SearchResult } from "Shared/Types/common";
import {
  IManufacturer,
  IManufacturerAdd,
  IManufacturerSearchParams,
  IManufacturerUpdate,
} from "Shared/Types/manufacturer";

const MANUFACTURER_URL = "part/manufacturer/";

class Manufacturer {
  getManufacturersList(params: IManufacturerSearchParams | null) {
    return client.get<SearchResult<IManufacturer>>(MANUFACTURER_URL, {
      params,
    });
  }
  getManufacturer(id: string) {
    return client.get<IManufacturer>(`${MANUFACTURER_URL}${id}/`);
  }
  addManufacturer(data: IManufacturerAdd) {
    return client.post(MANUFACTURER_URL, data);
  }
  updateManufacturer(id: string, data: IManufacturerUpdate) {
    return client.put(`${MANUFACTURER_URL}${id}/`, data);
  }
  deleteManufacturer(id: string) {
    return client.delete(`${MANUFACTURER_URL}${id}/`);
  }
}

export default Manufacturer;
