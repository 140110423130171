import Block from "Components/UI/Block/Block";
import {
  getPurchaseOrder,
  receivePurchaseOrder,
} from "Redux/PurchaseOrder/reducer";
import { selectCurrentPurchaseOrder } from "Redux/PurchaseOrder/slice";
import { useAppDispatch } from "Redux/store";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import ActionIcons from "./ActionIcons/ActionIcons";
import PurchaseOrderCommonInfo from "Components/PurchaseOrder/Update/CommonInfo/CommonInfo";
import { readObj } from "Service/storage";
import LineItem from "./LineItem/LineItem";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  getPurchaseOrderReceiveInitValues,
  getPurchaseOrderRoute,
  purchaseOrderReceiveSchema,
} from "Shared/Utils/purchase-order";
import Form from "Components/UI/Form/Form";
import { Grid } from "@mui/material";
import ReceivePart from "./ReceivePart/ReceivePart";
import Amount from "./Amount/Amount";
import Button from "Components/UI/Button/Button";
import styles from "./PurchaseOrderUpdate.module.scss";
import { IPurchaseOrderRecieved } from "Shared/Types/purchase-order";
import { notifyError, notifySuccess } from "Components/Notify/notify";

const PurchaseOrderUpdate: React.FC = () => {
  const purchaseOrder = useSelector(selectCurrentPurchaseOrder);
  const dispatch = useAppDispatch();
  const params = useParams();
  const shop = readObj("current_shop");
  const navigate = useNavigate();
  const [isApproved, setIsApproved] = useState(false);
  const methods = useForm({
    resolver: yupResolver(purchaseOrderReceiveSchema),
  });

  const onSubmit = (values: IPurchaseOrderRecieved) => {
    if (!isApproved) {
      notifyError("You must be approved");
      return;
    }
    if (purchaseOrder) {
      dispatch(
        receivePurchaseOrder({
          data: values,
          id: purchaseOrder.id,
          onSuccess: () => {
            setIsApproved(false);
            notifySuccess("Purchase order is received");
            navigate(getPurchaseOrderRoute("LIST"));
          },
        })
      );
    }
  };

  useEffect(() => {
    if (purchaseOrder) {
      methods.reset(getPurchaseOrderReceiveInitValues(purchaseOrder));
    }
  }, [purchaseOrder]);
  useEffect(() => {
    if (params.id) {
      dispatch(getPurchaseOrder(params.id));
    }
  }, [params.id]);
  return (
    <div>
      <FormProvider {...methods}>
        <Form onSubmit={methods.handleSubmit(onSubmit)}>
          <ActionIcons />
          {purchaseOrder && (
            <>
              <Block title={`Purchase Order ${purchaseOrder?.number}`}>
                <PurchaseOrderCommonInfo
                  purchaseOrder={purchaseOrder}
                  shop={shop}
                />
                {purchaseOrder.line_items.map((lineItem, index) => (
                  <LineItem
                    key={lineItem.id}
                    lineItem={lineItem}
                    index={index}
                    purchaseOrderId={purchaseOrder.id}
                  />
                ))}
                <Grid container mt="10px" columnSpacing={2}>
                  <Grid item xs={6}>
                    <ReceivePart />
                  </Grid>

                  <Grid item xs={6}>
                    <Amount
                      isApproved={isApproved}
                      setIsApproved={setIsApproved}
                    />
                  </Grid>
                </Grid>
              </Block>
              <div className={styles.action}>
                <Button type="submit">Submit</Button>
              </div>
            </>
          )}
        </Form>
      </FormProvider>
    </div>
  );
};
export default PurchaseOrderUpdate;
