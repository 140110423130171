import React from "react";
import Icon from "Components/UI/Icon/Icon";
import { LogoBlueIcon } from "Assets/index";
import Button from "Components/UI/Button/Button";
import { Link } from "react-router-dom";
import cn from "classnames";
import styles from "./Login.module.scss";

interface SuccessRegisterConfirmationModalProps {
  name?: string;
}

const SuccessRegisterConfirmationModal: React.FC<
  SuccessRegisterConfirmationModalProps
> = ({ name }) => {
  return (
    <div className={styles.successModal}>
      <Icon height={66} width={204} component={LogoBlueIcon} />
      <h2 className={styles.successModal__title}>Thank You !</h2>
      <div className={styles.successModal__descCont}>
        <p>
          Dear
          <span className={cn(styles.successModal__blueText, styles.underline)}>
            {" "}
            {name || "User"}{" "}
          </span>
          Thank you for choosing
          <span className={styles.successModal__blueText}> EasyShop</span>! Your
          free trial request is currently being reviewed. Keep an eye on your
          inbox for an approval email with all the details.
        </p>
        <p>
          In the meantime, explore our website for more information. If you have
          questions, contact our support team.
        </p>
      </div>
      <p style={{ margin: "24px 0 66px" }}>
        We look forward to having you on board
        <span className={styles.successModal__blueText}> Easy Shop team</span>
      </p>
      <Link to="https://landing.easyshopservice.com/">
        <Button style={{ width: "157px" }}>Got it</Button>
      </Link>
    </div>
  );
};

export default SuccessRegisterConfirmationModal;
