import React, { useEffect, useState } from "react";
import styles from "./ActionItemDetial.module.scss";
import PartItem from "./PartItem/PartItem";
import { IActionItem, IActionItemFields } from "Shared/Types/order";
import { isNotWaiting } from "Shared/Utils/order";
import { useAppDispatch } from "Redux/store";
import {
  getActionItem,
  updateActionItemByField,
} from "Redux/ActionItem/reducer";
import { useSelector } from "react-redux";
import { selectActionItemList } from "Redux/ActionItem/slice";
import PartItemSection from "./PartItemSection/PartItemSection";

import { deleteActionPart } from "Redux/ActionPart/reducer";
import DeleteModal from "Components/UI/DialogWindow/DeleteModal";
import ActionItemDetailHeader from "./ActionItemDetailHeader/ActionItemDetailHeader";
import { notifySuccess } from "Components/Notify/notify";
import ActionItemMain from "./ActionItemMain/ActionItemMain";
import ActionItemButtons from "./ActionItemButtons/ActionItemButtons";

type ActionItemDetailProps = {
  onClose: () => void;
  actionItem: IActionItem;
  openDelete: (id: string) => void;
  index: number;
  setActiveTab?: (tab: number) => void;
  pushInvoice: () => void;
};

const ActionItemDetail: React.FC<ActionItemDetailProps> = ({
  onClose,
  actionItem,
  openDelete,
  index,
  setActiveTab,
  pushInvoice,
}) => {
  const dispatch = useAppDispatch();
  const [deletePartId, setDeletePartId] = useState<string | null>();
  const [openPartDeleteModal, setOpenPartDeleteModal] = useState(false);
  const currentActionItem = useSelector(selectActionItemList)?.find(
    (action) => action.id === actionItem.id
  );

  const handlePartDialogClose = () => {
    setOpenPartDeleteModal(false);
    setDeletePartId(null);
  };

  const handlePartDialogOpen = (id: string) => {
    setOpenPartDeleteModal(true);
    setDeletePartId(id);
  };

  const handleDeletePart = () => {
    if (deletePartId) {
      dispatch(
        deleteActionPart({
          id: deletePartId,
          onSuccess: () => {
            handlePartDialogClose();
            notifySuccess("Part is deleted!");
            dispatch(getActionItem(actionItem.id));
          },
        })
      );
    }
  };

  const saveActionItemField = (
    field: IActionItemFields,
    value?: string,
    withOutCallBack?: boolean
  ) => {
    if (!value) return;
    const onSuccess = () => {
      notifySuccess("Action item is updated");
      if (currentActionItem) {
        dispatch(getActionItem(currentActionItem?.id));
      }
    };
    if (currentActionItem) {
      dispatch(
        updateActionItemByField({
          id: currentActionItem?.id,
          data: { field, value },
          onSuccess: withOutCallBack ? undefined : onSuccess,
        })
      );
    }
  };

  useEffect(() => {
    dispatch(getActionItem(actionItem.id));
  }, [actionItem]);

  if (!currentActionItem) {
    return <></>;
  }

  return (
    <div className={styles.action}>
      <ActionItemDetailHeader
        currentActionItem={currentActionItem}
        index={index}
        onClose={onClose}
        openDelete={openDelete}
        saveActionItemField={saveActionItemField}
      />
      <ActionItemMain
        currentActionItem={currentActionItem}
        saveActionItemField={saveActionItemField}
      />

      {currentActionItem.action_item_part?.map((part) => (
        <PartItemSection
          actionItemId={actionItem.id}
          key={part.id}
          part={part}
          openDeleteModal={handlePartDialogOpen}
        />
      ))}
      {isNotWaiting(currentActionItem?.status?.queue) && (
        <PartItem last actionItemId={currentActionItem.id} />
      )}
      <ActionItemButtons
        currentActionItem={currentActionItem}
        index={index}
        setActiveTab={setActiveTab}
        pushInvoice={pushInvoice}
      />
      <DeleteModal
        acceptTitle="Delete Actoin Item Part"
        open={openPartDeleteModal}
        handleClose={handlePartDialogClose}
        title="Delete Actoin Item Part"
        text="Are you sure you want to delete this Actoin Item Part?"
        accept={handleDeletePart}
      />
    </div>
  );
};
export default ActionItemDetail;
