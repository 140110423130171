import React, { useEffect } from "react";
import Modal from "Components/UI/Modal/Modal";
import { IPart } from "Shared/Types/part";
import { Grid } from "@mui/material";
import Form from "Components/UI/Form/Form";
import { FormProvider, useFieldArray, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { partSchema } from "Shared/Utils/part";
import InputField from "Components/UI/Inputs/InputField";
import { DeleteIcon } from "Assets/index";
import { changeLocationOptions } from "Shared/Utils/app";
import { useSelector } from "react-redux";
import { selectLocationList } from "Redux/PartSettings/Location/slice";
import { getLocationList } from "Redux/PartSettings/Location/reducer";
import { useAppDispatch } from "Redux/store";
import SelectField from "Components/UI/SelectField/SelectField";
import Button from "Components/UI/Button/Button";

interface InventoryLocationModalProps {
  inventoryPart?: IPart | null;
  setCurrentInventoryPart: React.Dispatch<React.SetStateAction<IPart | null>>;
  showLocationModal: boolean;
  setShowLocationModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const InventoryLocationModal: React.FC<InventoryLocationModalProps> = ({
  inventoryPart,
  setCurrentInventoryPart,
  showLocationModal,
  setShowLocationModal,
}) => {
  const locationsList = useSelector(selectLocationList) || [];
  const dispatch = useAppDispatch();
  const methods = useForm({
    resolver: yupResolver(partSchema),
    defaultValues: {
      locations: [
        {
          quantity: inventoryPart?.quantity ?? 0,
          min_quantity: +(inventoryPart?.min_quantity || 0),
          max_quantity: +(inventoryPart?.max_quantity || 0),
          location: inventoryPart?.location ?? "",
        },
      ],
    },
  });

  const { fields, append, remove } = useFieldArray({
    control: methods.control,
    name: "locations",
  });

  const addNewLocation = () => {
    append({});
  };

  const deleteLocation = (index: string) => {
    if (fields.length > 1) {
      remove(+index);
    }
  };

  const handleToCloseModal = () => {
    setShowLocationModal(false);
    setCurrentInventoryPart(null);
  };

  useEffect(() => {
    dispatch(getLocationList({}));
  }, []);

  return (
    <Modal
      title="Part Location"
      open={showLocationModal}
      onClose={handleToCloseModal}
      isClosed
    >
      <Grid container>
        <Grid
          item
          xs={12}
          display="grid"
          justifyContent="center"
          fontSize="18px"
        >
          {inventoryPart?.part_number} - SS home
        </Grid>
        <Grid item xs={12}>
          {inventoryPart?.description}
        </Grid>
        <FormProvider {...methods}>
          <Form>
            {fields.map((field, index) => (
              <Grid
                key={field.id}
                container
                item
                xs={12}
                alignItems="end"
                columnGap={2}
              >
                <Grid item xs={5}>
                  <SelectField
                    name={`locations.${index}.location`}
                    label="Location"
                    options={changeLocationOptions(locationsList)}
                    withControl
                    control={methods.control}
                  />
                </Grid>
                <Grid item xs={1.5}>
                  <InputField
                    name={`locations.${index}.quantity`}
                    label="Qty"
                    type="number"
                    control={methods.control}
                    withController
                    validatePositiveValue
                  />
                </Grid>
                <Grid item xs={1.5}>
                  <InputField
                    name={`locations.${index}.max_quantity`}
                    label="Max qty"
                    type="number"
                    control={methods.control}
                    withController
                    validatePositiveValue
                  />
                </Grid>
                <Grid item xs={1.5}>
                  <InputField
                    name={`locations.${index}.min_quantity`}
                    label="Min qty"
                    type="number"
                    control={methods.control}
                    withController
                    validatePositiveValue
                  />
                </Grid>
                <Grid item xs={1} justifyContent="end">
                  <DeleteIcon
                    width={24}
                    height={24}
                    onClick={() => deleteLocation(field.id)}
                  />
                </Grid>
              </Grid>
            ))}
            <Grid container item xs={12} marginTop={2}>
              <Grid item xs={6} color={`#147ED8`} onClick={addNewLocation}>
                + Add another location
              </Grid>
              <Grid item xs={6} display="flex" justifyContent="end">
                Amount Qty: {inventoryPart?.quantity}
              </Grid>
            </Grid>
            <Grid container item xs={12} marginTop={2}>
              <Grid item xs={6}>
                Qty change: {inventoryPart?.quantity}
              </Grid>
              <Grid item xs={6} gap={2} display="flex" justifyContent="end">
                <Grid item xs={4} display={"flex"} justifyContent="flex-end">
                  <Button color="secondary">Cancel</Button>
                </Grid>
                <Grid item xs={4} display={"flex"} justifyContent="flex-end">
                  <Button>Submit</Button>
                </Grid>
              </Grid>
            </Grid>
          </Form>
        </FormProvider>
      </Grid>
    </Modal>
  );
};

export default InventoryLocationModal;
