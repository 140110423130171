import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "Service/api/api";
import {
  IDispatchCallback,
  IDispatchCallbackWithId,
  IDispatchCallbackWithoutData,
} from "Shared/Types/common";
import {
  ISubtypeSearchParams,
  ISubtypeAdd,
  ISubtypeUpdate,
} from "Shared/Types/subtype";
import { errorHandler } from "Shared/Utils/app";

const getSubtypeList = createAsyncThunk(
  "subtype/getSubtypesList",
  async (params: ISubtypeSearchParams | null) => {
    try {
      const response = await api.subtype.getSubtypeList(params);
      return response.data;
    } catch (error) {
      if (error instanceof Error) {
        errorHandler(error);
      }
      throw error;
    }
  }
);

const getSubtype = createAsyncThunk(
  "subtype/getSubtype",
  async (id: string) => {
    try {
      const response = await api.subtype.getSubtype(id);
      return response.data;
    } catch (error) {
      if (error instanceof Error) {
        errorHandler(error);
      }
      throw error;
    }
  }
);

const addSubtype = createAsyncThunk(
  "subtype/addSubtype",
  async ({ data, onSuccess }: IDispatchCallback<ISubtypeAdd>) => {
    try {
      const response = await api.subtype.addSubtype(data);
      onSuccess?.();
      return response.data;
    } catch (error) {
      if (error instanceof Error) {
        errorHandler(error, true);
      }
      throw error;
    }
  }
);

const updateSubtype = createAsyncThunk(
  "subtype/updateSubtype",
  async ({ id, data, onSuccess }: IDispatchCallbackWithId<ISubtypeUpdate>) => {
    try {
      const response = await api.subtype.updateSubtype(id, data);
      onSuccess?.();
      return response.data;
    } catch (error) {
      if (error instanceof Error) {
        errorHandler(error, true);
      }
      throw error;
    }
  }
);

const deleteSubtype = createAsyncThunk(
  "subtype/deleteSubtype",
  async ({ id, onSuccess }: IDispatchCallbackWithoutData) => {
    try {
      const response = await api.subtype.deleteSubtype(id);
      onSuccess?.();
      return response.data;
    } catch (error) {
      if (error instanceof Error) {
        errorHandler(error, true);
      }
      throw error;
    }
  }
);

export { getSubtypeList, getSubtype, addSubtype, updateSubtype, deleteSubtype };
