import React, { useState } from "react";
import styles from "Components/Order/Estimate/EstimateForm/EstimateForm.module.scss";
import { Grid } from "@mui/material";
import { DeleteIcon } from "Assets/index";
import ComplaintServiceForm from "Components/Order/Estimate/EstimateForm/ComplaintServiceForm/ComplaintServiceForm";
import ComplaintPartForm from "Components/Order/Estimate/EstimateForm/ComplaintPartForm/ComplaintPartForm";
import { useFieldArray, useFormContext } from "react-hook-form";
import IconButton from "Components/UI/IconButton/IconButton";
import DeleteModal from "Components/UI/DialogWindow/DeleteModal";

type ComplaintServiceFormContainerProps = {
  complaintIndex: number;
  complaintLength: number;
  serviceLength: number;
  deleteComplaint: (index: number, id: string) => void;
  isDetails: boolean;
};

const ComplaintForm: React.FC<ComplaintServiceFormContainerProps> = ({
  complaintIndex,
  deleteComplaint,
  serviceLength,
  complaintLength,
  isDetails,
}) => {
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const { getValues, control } = useFormContext();

  const {
    fields: partFields,
    append,
    remove,
  } = useFieldArray({
    control,
    name: `action_items.${complaintIndex}.part`,
  });

  const handleDialogClose = () => {
    setOpenDeleteModal(false);
  };

  const handleDialogOpen = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => {
    if (complaintLength - 1 === 0) {
      return;
    }
    event.stopPropagation();
    setOpenDeleteModal(true);
  };

  return (
    <div className={styles.form__complaints__item}>
      <div className={styles.form__complaints__item__head}>
        <h4>Complaint {serviceLength + complaintIndex + 1}</h4>
        <Grid item xs={0.5} display="flex" alignItems="end">
          <IconButton onClick={handleDialogOpen} icon={DeleteIcon} />
          <DeleteModal
            open={openDeleteModal}
            handleClose={handleDialogClose}
            title="Delete Complaint"
            text="Are you sure...?"
            accept={() =>
              deleteComplaint(
                complaintIndex,
                getValues(`action_items.${complaintIndex}.service.id`),
              )
            }
            acceptTitle="Delete"
            color="red"
          />
        </Grid>
      </div>
      <hr className={styles.form__complaints__item__separator} />
      <Grid container>
        <ComplaintServiceForm
          index={complaintIndex}
          append={append}
          isDetails={isDetails}
        />
        <ComplaintPartForm
          complaintIndex={complaintIndex}
          partFields={partFields}
          append={append}
          remove={remove}
        />
      </Grid>
    </div>
  );
};

export default ComplaintForm;
