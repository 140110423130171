import React from "react";
import Layout from "Components/Layout/Layout";
import ServiceOrderAdd from "Containers/Order/ServiceOrderAdd/ServiceOrderAdd";
import { BreadcrumbItem } from "Components/Breadcrumb/Breadcrumb";
import { useBreadcrumb } from "Shared/Hooks/use-breadcrumb";
import { useLocation } from "react-router";

export const ServiceOrderPage = () => {
  const { state } = useLocation();
  const breads: BreadcrumbItem[] = [
    { title: state ? "Home" : "Orders", link: "/orders" },
    { title: "Create SO", link: "" },
  ];

  useBreadcrumb({ links: breads });

  return (
    <Layout>
      <ServiceOrderAdd />
    </Layout>
  );
};
