import React, { useState } from "react";
import Modal from "Components/UI/Modal/Modal";
import Video from "Components/Order/ServiceOrderForms/Attachments/Video";
import Camera from "Components/Order/ServiceOrderForms/Attachments/Camera";
import Icon from "Components/UI/Icon/Icon";
import {
  CameraIcon,
  CameraRotateIcon,
  CloseIcon,
  VideoCameraIcon,
} from "Assets/index";

type SystemProps = {
  openModal: boolean;
  handleModal: () => void;
  setSelectedImages: (file: File[]) => void;
  selectedImages: File[];
};

const CameraAndVideo: React.FC<SystemProps> = ({
  openModal,
  handleModal,
  setSelectedImages,
  selectedImages,
}) => {
  const [isVideo, setIsVideo] = useState(false);
  const [front, setFront] = useState(true);

  const frontConstraints = {
    width: 730,
    height: 720,
    facingMode: "face",
  };

  const backConstraints = {
    width: 730,
    height: 720,
    facingMode: { exact: "environment" },
  };

  const switchToFront = () => setFront(!front);
  const switchToPhoto = () => setIsVideo(false);
  const switchToVideo = () => setIsVideo(true);

  return (
    <Modal
      fullScreen
      open={openModal}
      onClose={handleModal}
      style={{ width: "100%", height: "100%", position: "relative" }}
    >
      {isVideo ? (
        <Video
          closeModal={handleModal}
          selectedImages={selectedImages}
          setSelectedImages={setSelectedImages}
          constraints={front ? frontConstraints : backConstraints}
        />
      ) : (
        <Camera
          closeModal={handleModal}
          selectedImages={selectedImages}
          setSelectedImages={setSelectedImages}
          constraints={front ? frontConstraints : backConstraints}
        />
      )}
      <div
        style={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          gap: "20px",
        }}
      >
        {isVideo ? (
          <Icon
            style={{ fontSize: "50px", color: "transparent" }}
            onClick={switchToPhoto}
            component={CameraIcon}
          />
        ) : (
          <Icon
            style={{ fontSize: "60px", color: "transparent" }}
            onClick={switchToVideo}
            component={VideoCameraIcon}
          />
        )}
        <Icon
          style={{ fontSize: "60px", color: "transparent" }}
          onClick={switchToFront}
          component={CameraRotateIcon}
        />
      </div>
      <Icon
        style={{
          position: "absolute",
          top: "30px",
          right: "30px",
          fontSize: "40px",
          color: "transparent",
        }}
        onClick={handleModal}
        component={CloseIcon}
      />
    </Modal>
  );
};

export default CameraAndVideo;
