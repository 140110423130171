import { IPermission } from "Shared/Types/role";
import { IPermissionObject } from "Shared/Types/role";

export const convertPermissionsToObject = (permissions: IPermission[]) => {
  const obj: IPermissionObject = {};
  permissions.forEach((per) => {
    obj[per.id] = per;
  });
  return obj;
};
