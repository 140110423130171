import { client } from "Service/axios";
import { SearchResult } from "Shared/Types/common";
import {
  ICompanySearchParams,
  ICompany,
  ICompanyAdd,
  ICompanyUpdate,
} from "Shared/Types/company";

const COMPANY_URL = "company/";

class Company {
  getCompanyList(params: ICompanySearchParams | null) {
    return client.get<SearchResult<ICompany>>(COMPANY_URL, { params });
  }
  getCompany(id: string) {
    return client.get<ICompany>(`${COMPANY_URL}${id}/`);
  }
  addCompany(data: ICompanyAdd) {
    return client.post(COMPANY_URL, data);
  }
  updateCompany(id: string, data: ICompanyUpdate) {
    return client.put(`${COMPANY_URL}${id}/`, data);
  }
  updateCompanyStatus(id: string, data: ICompanyUpdate) {
    return client.patch(`${COMPANY_URL}${id}/`, data);
  }
}

export default Company;
