import React from "react";
import InvoiceComponent from "Components/Office/Invoice/Invoice";

const Invoice = () => {
  return (
    <div>
      <InvoiceComponent />
    </div>
  );
};

export default Invoice;
