const timeZones = [
  "UTC-12:00 - Baker Island, US Minor Outlying Islands",
  "UTC-11:00 - American Samoa, Niue",
  "UTC-10:00 - Hawaii (USA), French Polynesia",
  "UTC-09:00 - Alaska (USA)",
  "UTC-08:00 - Pacific Time Zone (USA & Canada), Baja California (Mexico)",
  "UTC-07:00 - Mountain Time Zone (USA & Canada), Arizona (USA)",
  "UTC-06:00 - Central Time Zone (USA & Canada), Mexico City (Mexico)",
  "UTC-05:00 - Eastern Time Zone (USA & Canada), Bogota (Colombia)",
  "UTC-04:00 - Atlantic Time Zone (Canada), Caracas (Venezuela)",
  "UTC-03:00 - Buenos Aires (Argentina), Brasilia (Brazil)",
  "UTC-02:00 - South Georgia and the South Sandwich Islands",
  "UTC-01:00 - Azores (Portugal), Cape Verde",
  "UTC±00:00 - Greenwich Mean Time (GMT), London (United Kingdom)",
  "UTC+01:00 - Central European Time (CET), Paris (France), Berlin (Germany)",
  "UTC+02:00 - Eastern European Time (EET), Athens (Greece), Istanbul (Turkey)",
  "UTC+03:00 - Moscow (Russia), Riyadh (Saudi Arabia)",
  "UTC+03:30 - Tehran (Iran)",
  "UTC+04:00 - Dubai (United Arab Emirates), Baku (Azerbaijan)",
  "UTC+04:30 - Kabul (Afghanistan)",
  "UTC+05:00 - Islamabad (Pakistan), Tashkent (Uzbekistan)",
  "UTC+05:30 - New Delhi (India), Colombo (Sri Lanka)",
  "UTC+05:45 - Kathmandu (Nepal)",
  "UTC+06:00 - Almaty (Kazakhstan), Dhaka (Bangladesh)",
  "UTC+06:30 - Cocos Islands (Australia), Yangon (Myanmar)",
  "UTC+07:00 - Bangkok (Thailand), Ho Chi Minh City (Vietnam)",
  "UTC+08:00 - Beijing (China), Singapore",
  "UTC+08:45 - Eucla (Western Australia)",
  "UTC+09:00 - Tokyo (Japan), Seoul (South Korea)",
  "UTC+09:30 - Adelaide (Australia), Darwin (Australia)",
  "UTC+10:00 - Eastern Australia Time Zone, Vladivostok (Russia)",
  "UTC+10:30 - Lord Howe Island (Australia)",
  "UTC+11:00 - Solomon Islands, New Caledonia",
  "UTC+11:30 - Norfolk Island (Australia)",
  "UTC+12:00 - Fiji, Tuvalu",
  "UTC+12:45 - Chatham Islands (New Zealand)",
  "UTC+13:00 - Tonga, Samoa",
  "UTC+14:00 - Line Islands (Kiribati)",
];

export const timeZoneList = timeZones.map((zone) => {
  return { label: zone, value: zone };
});

export const technicianPayTypeOptions = [
  { label: "Flat", value: "Flat" },
  { label: "Hourly", value: "Hourly" },
  { label: "Percentage", value: "Percentage" },
  { label: "Salary", value: "Salary" },
];

enum setOfTypesIndicators {
  "Flat" = "/billed hour",
  "Hourly" = "/worked hour",
  "Percentage" = "% of revenue from labor items",
  "Salary" = "/month",
}

export const indicatorMapping: { [key: string]: setOfTypesIndicators } = {
  Flat: setOfTypesIndicators.Flat,
  Hourly: setOfTypesIndicators.Hourly,
  Percentage: setOfTypesIndicators.Percentage,
  Salary: setOfTypesIndicators.Salary,
};
