import Layout from "Components/Layout/Layout";
import SettingsLayout from "Components/Settings/Layout/Layout";
import ManufactureUpdate from "Containers/Settings/Part/Manufacturer/ManufacturerUpdate";
import React from "react";
import { BreadcrumbItem } from "Components/Breadcrumb/Breadcrumb";
import { useBreadcrumb } from "Shared/Hooks/use-breadcrumb";
import { useSelector } from "react-redux";
import { selectCurrentManufacturer } from "Redux/PartSettings/Manufacturer/slice";

export const ManufactureUpdatePages: React.FC = () => {
  const manufacture = useSelector(selectCurrentManufacturer);

  const breads: BreadcrumbItem[] = [
    { title: "Settings", link: "/settings/part-settings/manufactures" },
    { title: "Manufacturers", link: "/settings/part-settings/manufactures" },
    { title: `Update ${manufacture?.name || ""}`, link: "" },
  ];
  useBreadcrumb({ links: breads, option: { upd: manufacture?.name } });

  return (
    <Layout>
      <SettingsLayout>
        <ManufactureUpdate />
      </SettingsLayout>
    </Layout>
  );
};
