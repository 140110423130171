import { yupResolver } from "@hookform/resolvers/yup";
import Button from "Components/UI/Button/Button";
import Form from "Components/UI/Form/Form";
import Checkbox from "Components/UI/Inputs/Checkbox";
import InputField from "Components/UI/Inputs/InputField";
import { ILaborRate, ILaborRateAdd } from "Shared/Types/laborRate";
import React from "react";
import { useForm } from "react-hook-form";
import { ObjectSchema } from "yup";
import styles from "./LaborForm.module.scss";
import Block from "Components/UI/Block/Block";
import { DollarWhiteIcon } from "Assets/index";

type LaborFormProps = {
  onSubmit: (values: ILaborRateAdd) => void;
  scheme: ObjectSchema<ILaborRateAdd>;
  laborRate?: ILaborRate | undefined;
};

const LaborForm: React.FC<LaborFormProps> = ({
  onSubmit,
  scheme,
  laborRate,
}) => {
  const { handleSubmit, control } = useForm({
    resolver: yupResolver(scheme),
    defaultValues: laborRate,
  });

  return (
    <Block className={styles.form}>
      <Form className={styles.form__cont} onSubmit={handleSubmit(onSubmit)}>
        <InputField
          withController
          control={control}
          name="name"
          required
          label="Name"
        />
        <div className={styles.form__signed_input}>
          <InputField
            icon={DollarWhiteIcon}
            control={control}
            name="rate"
            required
            type="number"
            label="Rate"
            withController
          />
        </div>
        <Checkbox
          control={control}
          withController
          label="Is Default"
          name="default"
          labelPosition="right"
        />
        <div className={styles.form__cont__actions}>
          {laborRate ? (
            <Button variant="outlined" type="submit">
              Save
            </Button>
          ) : (
            <Button type="submit">Create</Button>
          )}
        </div>
      </Form>
    </Block>
  );
};
export default LaborForm;
