import React from "react";
import SettingsLayout from "Components/Settings/Layout/Layout";
import Layout from "Components/Layout/Layout";
import SupplyRateUpdate from "Containers/Settings/Rate/SupplyRate/SupplyRateUpdate/SupplyRateUpdate";
import { BreadcrumbItem } from "Components/Breadcrumb/Breadcrumb";
import { useBreadcrumb } from "Shared/Hooks/use-breadcrumb";
import { useSelector } from "react-redux";
import { selectSupplyRate } from "Redux/SupplyRate/slice";

export const SupplyRateUpdatePage = () => {
  const supplyRate = useSelector(selectSupplyRate);

  const breads: BreadcrumbItem[] = [
    { title: "Settings", link: "/settings/rate-settings/supply-rate" },
    { title: "Supply Rate", link: "/settings/rate-settings/supply-rate" },
    { title: `Update ${supplyRate?.title || ""}`, link: "" },
  ];
  useBreadcrumb({ links: breads, option: { upd: supplyRate?.title } });

  return (
    <Layout>
      <SettingsLayout>
        <SupplyRateUpdate />
      </SettingsLayout>
    </Layout>
  );
};
