import React, { useEffect } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { Controller, useFormContext } from "react-hook-form";
import { useSelector } from "react-redux";
import { useAppDispatch } from "Redux/store";
import styles from "./AutoCompleteSearchInput.module.scss";
import { selectServiceList } from "Redux/Service/slice";
import { getServiceList } from "Redux/Service/reducer";
import { IService } from "Shared/Types/service";
import { componentsProps } from "./LaborRateSearch";
import { useNavigate } from "react-router";
import { ArrowDownBlackIcon } from "Assets/index";

interface SearchProps {
  name: string;
  required?: boolean;
  label?: string;
  isAdd?: boolean;
  beforeCreateNew?: () => void;
  inputRef?: React.RefObject<HTMLInputElement>;
}

export const ServiceSearch: React.FC<SearchProps> = ({
  name,
  required,
  label,
  beforeCreateNew,
  isAdd,
  inputRef,
}) => {
  const { control } = useFormContext();
  const services = useSelector(selectServiceList) || [];
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const goToCreateNew = () => {
    beforeCreateNew?.();
    navigate("/services/new", { state: "back" });
  };
  const searchServices = (text: string | null) => {
    dispatch(
      getServiceList({
        search: text || null,
      })
    );
  };
  const getLabel = (service: IService | null) => {
    if (service) {
      return service?.name;
    }
    return "";
  };

  useEffect(() => {
    dispatch(
      getServiceList({
        search: control._defaultValues?.service?.name || null,
      })
    );
  }, []);
  return (
    <div className={styles.autocomplete}>
      <label data-id={name} className={styles.autocomplete__label}>
        {label}
        {required && <span className={styles.autocomplete__error}>*</span>}
      </label>
      <Controller
        name={name}
        control={control}
        defaultValue={control._defaultValues.service}
        render={({ field, fieldState }) => (
          <Autocomplete
            {...field}
            options={services}
            getOptionLabel={getLabel}
            onChange={(_, value) => {
              field.onChange(value);
            }}
            disablePortal={true}
            componentsProps={componentsProps}
            popupIcon={<ArrowDownBlackIcon/>}

            noOptionsText={
              isAdd && <label onClick={goToCreateNew}>+ Add new Service</label>
            }
            renderInput={(params) => (
              <>
                <TextField
                  sx={{
                    ".MuiOutlinedInput-notchedOutline": {
                      borderStyle: "none",
                    },
                  }}
                  inputRef={inputRef}
                  classes={{ root: styles.autocomplete__input }}
                  name={name}
                  required={required}
                  onChange={(event) => {
                    searchServices(event.target.value);
                  }}
                  {...params}
                />
                {fieldState.error && (
                  <div className={styles.autocomplete__error}>
                    {fieldState.error.message}
                  </div>
                )}
              </>
            )}
          />
        )}
      />
    </div>
  );
};
