import React, { useState } from "react";
import styles from "../CalendarForSchedule.module.scss";
import "./CalendarBlue.scss";
import { Controller, useFormContext } from "react-hook-form";
import Calendar from "react-calendar";
import { reformatData } from "Shared/Utils/reformatDate";
import cn from "classnames";
import { times } from "Components/Order/ServiceOrderForms/CalendarForSchedule/mockTimeList";

const DateTimeCalendarBlue = () => {
  const { control, getValues } = useFormContext();

  const [selectedDate, setSelectedDate] = useState(
    getValues("start_date") || new Date(),
  );
  const [choosenTime, setChoosenTime] = useState("00:00:00");

  return (
    <Controller
      control={control}
      name="start_date"
      defaultValue={new Date()}
      render={({ field }) => (
        <div className={`${styles.calendar} blue`}>
          <Calendar
            value={field.value}
            onChange={(selectedDate) => {
              if (selectedDate instanceof Date) {
                setSelectedDate(reformatData(selectedDate.toString()));
                field.onChange(
                  `${reformatData(selectedDate.toString())} ${choosenTime}`,
                );
              }
            }}
          />
          <div className={styles.calendar__times}>
            {times.map((el) => (
              <div
                onClick={() => {
                  if (selectedDate instanceof Date) {
                    const formattedTime = `${
                      selectedDate.toISOString().split("T")[0]
                    } ${el.value}`;
                    field.onChange(formattedTime);
                  } else {
                    field.onChange(`${reformatData(selectedDate)} ${el.value}`);
                  }
                  setChoosenTime(el.value);
                }}
                className={cn(
                  styles.calendar__times_timeBlue,
                  el.value === choosenTime &&
                    styles.calendar__times_timeBlue_active,
                )}
                key={el.id}
              >
                {el.time}
              </div>
            ))}
          </div>
        </div>
      )}
    />
  );
};

export default DateTimeCalendarBlue;
