import { clear, write } from "Service/storage";

export const orderFieldList = [
  "Order #",
  "Unit",
  "Created",
  "Client",
  "Complaint",
  "Invoice",
];

export const historyFieldList = [
  "Date",
  "Time",
  "Type",
  "Client",
  "Completed Hours",
  "SO Hours",
  "Complaint",
];

export const historyFieldAllList = [
  ...historyFieldList,
  "Employee",
  "Vendor",
  "Clocked Hours",
];
export const orderFieldAllList = [
  ...orderFieldList,
  "Creator",
  "Lead Tech",
  "Invoice Hours",
];

export const orderStatuses = {
  unassained: {
    link: "unassained",
    title: "Unassigned SO",
  },
  assigned: {
    link: "assigned",
    title: "Assigned SO",
  },
  diagnosed: {
    link: "diagnosed",
    title: "Diagnosed SO",
  },
  authorize: {
    link: "authorize",
    title: "Authorize SO",
  },
  repair: {
    link: "repair",
    title: "Repair SO",
  },
  invoiced: {
    link: "invoiced",
    title: "Invoice SO",
  },
  done: {
    link: "done",
    title: "Done SO",
  },
};

export const orderDetailHistory = [
  {
    title: "Assigned",
    status: 1,
  },
  {
    title: "Diagnose",
    status: 2,
  },
  {
    title: "Authorize",
    status: 3,
  },
  {
    title: "Repair",
    status: 4,
  },
  {
    title: "Invoice",
    status: 5,
  },
];

export const orderPartHistory = [
  {
    title: "Quote",
    status: false,
  },
  {
    title: "Order",
    status: false,
  },
  {
    title: "Spare part ordered",
    status: false,
  },
];

export const actionItems = [
  { id: 1, title: "Action Item1" },
  { id: 2, title: "Action Item2" },
  { id: 3, title: "Action Item3" },
  { id: 4, title: "Action Item4" },
  { id: 5, title: "Action Item5" },
  { id: 6, title: "Action Item6" },
];

export const severityList = [
  { value: "DOT - Unsafe to operate", label: "DOT - Unsafe to operate" },
  {
    value: "DOT - Operational - Correct ASAP",
    label: "DOT - Operational - Correct ASAP",
  },
  {
    value: "OOS - Out of Service - Do not operate",
    label: "OOS - Out of Service - Do not operate",
  },
  {
    value: "Damage - Damage if operated",
    label: "Damage - Damage if operated",
  },
  {
    value: "5 Days - Correct before 5 days",
    label: "5 Days - Correct before 5 days",
  },
  {
    value: "15 Days - Correct before 15 days",
    label: "15 Days - Correct before 15 days",
  },
  {
    value: "30 Days - Correct before 30 days",
    label: "30 Days - Correct before 30 days",
  },
  { value: "Outside - Outside Repair", label: "Outside - Outside Repair" },
];

export const causeTypes = [
  { value: "Damage caused by Driver", label: "Damage caused by Driver" },
  { value: "Others", label: "Others" },
  { value: "Preventive Maintenance", label: "Preventive Maintenance" },
  { value: "Unscheduled Repair", label: "Unscheduled Repair" },
];

export const actionItemDeleteMessage = `You are on the verge of initiating the deletion of this action item, which will also remove all linked timesheet entries, parts, notes, attachments, and other related items. Any inventory parts that have been received will be automatically returned to inventory. If you prefer to manage the return of inventory parts individually using the "Return Parts" section on your Parts page, kindly click "Cancel" and mark the relevant parts as "Not Needed."
Please bear in mind that proceeding with this action will result in irreversible deletion of the action item. To confirm your decision to delete, please click "Delete Action."`;

export const orderFilterFields = ["search", "unit", "customer", "date"];
export const historyFilterFields = ["customer", "vendor", "employee", "date"];
export const employeeFilterFields = ["search"];

export const orderStatusesList = {
  assigned: "assigned",
  diagnosed: "diagnosed",
  authorize: "authorize",
  repair: "repair",
  invoiced: "invoiced",
};

export const orderStatusesLinks = [
  "assigned",
  "diagnosed",
  "authorize",
  "repair",
  "invoiced",
  "done",
];

export const ORDER_CREATE = {
  createLink: "/orders/new",
  createTitle: "SO",
  handleClickToCreate: () => {
    write("formStep", "1");
    clear("created_service_order");
  },
  isNeedCreateBtn: true,
};

export const warning_fields: { [key: string]: string } = {
  availability_date: "Availability Date",
  finish_date: "Finish Date",
  start_date: "Start Date",
  notes: "Notes",
  customer: "Customer",
  service_requester: "Contact",
  unit: "Unit",
  lead_technician: "Lead Technician",
  action_items: "Action Item",
};
