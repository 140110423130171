import React, { useEffect, useState } from "react";
import { IEstimatesPart, IEstimatesService } from "Shared/Types/estimate";
import Table from "Components/UI/Table/Table";
import { partTableHead, serviceTableHead } from "Shared/Constants/estimates";
import styles from "./ComplaintInfo.module.scss";
import { renderCell } from "Components/Order/Estimate/EstimateDetail/renderCell/renderCell";
import { renderCellParts } from "Components/Order/Estimate/EstimateDetail/renderCell/renderCellParts";
import { useAppDispatch } from "Redux/store";
import { getEstimatePartList } from "Redux/Estimate/reducer";

type ComplaintInfoProps = {
  index: number;
  service: IEstimatesService;
};

const ComplaintInfo: React.FC<ComplaintInfoProps> = ({ index, service }) => {
  const [parts, setParts] = useState<IEstimatesPart[]>([]);
  const dispatch = useAppDispatch();

  const getTotalPrices = (
    service: IEstimatesService,
    parts: IEstimatesPart[],
  ) => {
    let total = 0;
    total += (service.invoice_hours || 0) * (service.labor_rate?.rate || 0);
    total += parts.reduce(
      (acc, item) => (acc += (item.quantity || 0) * +(item.selling_price || 0)),
      0,
    );
    return total.toFixed(3);
  };

  useEffect(() => {
    if (service.id) {
      (async () => {
        const res = await dispatch(
          getEstimatePartList({ service: service.id }),
        );
        setParts(res.payload);
      })();
    }
  }, [service.id]);

  return (
    <div className={styles.complaint__info}>
      <div>
        <h4>Complaint {index + 1}</h4>
      </div>
      <div className={styles.complaint__info__separator} />
      <div className={styles.complaint__info__body}>
        <Table
          renderCell={renderCell}
          columns={serviceTableHead}
          data={[{ ...service, rate: service.labor_rate?.rate }]}
          isInDetailPage
        />
        {parts.length > 0 && (
          <div className={styles.complaint__info__body__parts}>
            <Table
              renderCell={renderCellParts}
              columns={partTableHead}
              data={parts || []}
              isInDetailPage
            />
          </div>
        )}
      </div>
      <div className={styles.complaint__info__total}>
        Total:{" "}
        <span className={styles.complaint__info__total__price}>
          {getTotalPrices(service, parts)}
        </span>
      </div>
    </div>
  );
};

export default ComplaintInfo;
