import { yupResolver } from "@hookform/resolvers/yup";
import Form from "Components/UI/Form/Form";
import React, { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { ObjectSchema } from "yup";
import Button from "Components/UI/Button/Button";
import styles from "./VendorForm.module.scss";
import { IVendor, IVendorAdd, IVendorUpdate } from "Shared/Types/vendor";
import BasicInfo from "./BasicInfo";
import AddressInfo from "./AddressInfo";
import ContactPerson from "./ContactPerson";
import AdditionalDetails from "./Additional";
import Block from "Components/UI/Block/Block";

export type IVendorForm = IVendorAdd | IVendorUpdate;
type VendorFormProps = {
  onSubmit: (values: IVendorForm) => void;
  schema: ObjectSchema<IVendorForm>;
  vendor?: IVendor;
  onSkip?: () => void;
  isFirstAuth?: boolean;
};

const VendorForm: React.FC<VendorFormProps> = ({
  onSubmit,
  schema,
  vendor,
  isFirstAuth,
  onSkip,
}) => {
  const medhods = useForm({
    resolver: yupResolver(schema),
    defaultValues: vendor,
  });
  const [active] = useState<boolean>(!!vendor?.is_active || true);

  return (
    <Block className={styles.form}>
      <FormProvider {...medhods}>
        <Form onSubmit={medhods.handleSubmit(onSubmit)}>
          <BasicInfo active={active} control={medhods.control} />
          <AddressInfo control={medhods.control} />
          <ContactPerson control={medhods.control} />
          <AdditionalDetails control={medhods.control} />
          <div className={styles.form__action}>
            {isFirstAuth && (
              <Button onClick={onSkip} color="secondary" type="button">
                Skip
              </Button>
            )}
            {vendor ? (
              <Button variant="outlined" type="submit">
                Save
              </Button>
            ) : (
              <Button type="submit">Create</Button>
            )}
          </div>
        </Form>
      </FormProvider>
    </Block>
  );
};
export default VendorForm;
