export const idRegex =
  /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/;

export const ESTIMATE_CREATE = {
  createLink: "/orders/estimates/new",
  createTitle: "Estimate",
  isNeedCreateBtn: true,
};

export const estimateStatuses = {
  all: {
    link: "all",
    title: "All Estimates",
  },
  open: {
    link: "open",
    title: "Open Estimates",
  },
  close: {
    link: "close",
    title: "Close Estimates",
  },
};

export const estimateFieldList = [
  "Estimate #",
  "Unit",
  "Client",
  "Hours",
  "Total (USD)",
  "Created",
];

export const estimateFieldAllList = [...estimateFieldList];

export const estimateBreadcrumbs = [
  { title: "Orders", link: "/orders" },
  { title: "Estimates", link: "/orders/estimates" },
];

export const serviceTableHead = [
  {
    label: "Complain",
    id: "complaint",
    width: "20%",
  },
  {
    label: "Hours",
    id: "invoice_hours",
    width: "20%",
  },
  {
    label: "Labor Rate",
    id: "labor_rate",
    width: "10%",
  },
  {
    label: "Rate",
    id: "rate",
    width: "10%",
  },
  {
    label: "Amount",
    id: "amount",
    width: "10%",
  },
  {
    label: "",
    id: "empty1",
    width: "10%",
  },
  {
    label: "",
    id: "empty2",
    width: "10%",
  },
  {
    label: "",
    id: "empty3",
    width: "10%",
  },
];

export const partTableHead = [
  {
    label: "Part name",
    id: "description",
    width: "20%",
  },
  {
    label: "#",
    id: "part_number",
    width: "20%",
  },
  {
    label: "Q-ty",
    id: "quantity",
    width: "10%",
  },
  {
    label: "Selling price",
    id: "selling_price",
    width: "10%",
  },
  {
    label: "Amount",
    id: "amount",
    width: "10%",
  },
  {
    label: "Price",
    id: "cost",
    width: "10%",
  },
  {
    label: "Markup",
    id: "markup",
    width: "10%",
  },
  {
    label: "Margin",
    id: "margin",
    width: "10%",
  },
];
