import { BreadcrumbItem } from "Components/Breadcrumb/Breadcrumb";
import Layout from "Components/Layout/Layout";
import OrderList from "Containers/Order/AllOrder/OrderList/OrderList";
import { orderStatuses } from "Shared/Constants/order";
import { useBreadcrumb } from "Shared/Hooks/use-breadcrumb";
import React from "react";
import { useLocation } from "react-router";
import { ORDER_CREATE } from "Shared/Constants/order";

export const InvoicedOrdersPage: React.FC = () => {
  const { title, link } = orderStatuses.invoiced;
  const { state } = useLocation();
  const breads: BreadcrumbItem[] = [
    { title: state ? "Home" : "Orders", link: state ? "/" : "/orders" },
    { title: state ? "SO's" : "All orders", link: state ? "/" : "/orders" },
    { title: title, link: "" },
  ];
  useBreadcrumb({ links: breads, ...ORDER_CREATE });
  return (
    <Layout>
      <OrderList status={link} />
    </Layout>
  );
};
