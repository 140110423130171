import React, { useState } from "react";
import { DeleteIcon, EditIcon } from "Assets/index";
import styles from "../ComponentSystemContainer.module.scss";
import { deleteComponent, getComponentList } from "Redux/Component/reducers";
import { notifySuccess } from "Components/Notify/notify";
import { useAppDispatch } from "Redux/store";
import { HeadProps } from "Components/UI/Accordion/Accordion";
import DeleteModal from "Components/UI/DialogWindow/DeleteModal";
import { useNavigate } from "react-router";
import IconButton from "Components/UI/IconButton/IconButton";

type ComponentItemProps = HeadProps & {
  componentId: string;
  index: number;
};

export const ComponentItem = ({
  id,
  name,
  handlePreventOpenAccordion,
  index,
  componentId,
}: ComponentItemProps) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [openDialog, setOpenDialog] = useState(false);
  const handleDialog = () => setOpenDialog(!openDialog);
  const onDelete = async (e: React.MouseEvent) => {
    const onSuccess = () => {
      notifySuccess("Component deleted");
      dispatch(getComponentList());
      handleDialog();
    };
    if (id != null) {
      handlePreventOpenAccordion(e);
      await dispatch(deleteComponent({ id, onSuccess }));
    }
  };

  const onClickUpdateButton = () => {
    navigate(`/settings/component-system/${componentId}/`, {
      state: { isEditComponent: true },
    });
  };

  const onClickDeleteButton = (e: React.MouseEvent) => {
    handlePreventOpenAccordion(e);
    setOpenDialog(!openDialog);
  };

  return (
    <>
      <div className={styles.component__head}>
        <div className={styles.component__head__number}>{index + 1}</div>
        <div>{name}</div>
        <div className={styles.component__head__actions}>
          <IconButton onClick={onClickUpdateButton} icon={EditIcon} />
          <IconButton onClick={onClickDeleteButton} icon={DeleteIcon} />
        </div>
      </div>
      <DeleteModal
        acceptTitle="Delete"
        open={openDialog}
        handleClose={onClickDeleteButton}
        title="Delete"
        text="Are you sure...?"
        accept={onDelete}
      />
    </>
  );
};
