import React from "react";
import Layout from "Components/Layout/Layout";
import ShopDetail from "Containers/Shop/ShopDetail";
import { BreadcrumbItem } from "Components/Breadcrumb/Breadcrumb";
import { useBreadcrumb } from "Shared/Hooks/use-breadcrumb";
import { useSelector } from "react-redux";
import { selectCurrentShop } from "Redux/Shop/slice";

export const ShopDetailPage = () => {
  const shop = useSelector(selectCurrentShop);
  const breads: BreadcrumbItem[] = [
    { title: "Shop", link: "/shops" },
    { title: shop?.shop_name || "", link: "" },
  ];

  useBreadcrumb({ links: breads, option: { upd: shop?.shop_name } });
  return (
    <Layout>
      <ShopDetail />
    </Layout>
  );
};
