import { IActionPart, IActionPartForm } from "Shared/Types/action-item-part";
import { ICardData } from "Shared/Types/common";
import { IOrderStatus } from "Shared/Types/order";
import { IVendor } from "Shared/Types/vendor";
import * as Yup from "yup";
import { format, toCamelCase } from "./app";
import { ReactNode } from "react";
import { getShortValue } from "./order";
import {
  IPurchaseOrderAdd,
  IPurchaseOrderRecieved,
} from "Shared/Types/purchase-order";
import {
  PURCAHSE_ORDER_STATUS,
  PURCHASE_ORDER_TYPE,
} from "Shared/Constants/purchase-order";

export const actionItemPartSchema = Yup.object().shape({
  part_number: Yup.string(),
  description: Yup.string().required(),
  quantity: Yup.number().integer().min(1).required(),
  selling_price: Yup.number(),
  price: Yup.number(),
  action_item: Yup.string().required(),
  part: Yup.string().uuid(),
  vendor: Yup.mixed<IVendor>(),
  status: Yup.string(),
});

export const getActionItemPartData = (
  part: IActionPart,
  partNumber: ReactNode,
  cost: ReactNode
) => {
  const data: ICardData[] = [
    {
      title: "Description",
      value: part.description || "-",
      isOnlyValue: true,
      isMain: true,
      withoutBorder: true,
    },
    { title: "QTY", value: part.quantity },
    { title: "Part #", value: partNumber },
    { title: "Cost", value: cost },
    { title: "Vendor", value: part.vendor?.vendor_name },
  ];
  return data;
};

export const getActionItemPartCardStatus = (status: IOrderStatus) => {
  const obj: { [key: string]: string } = {
    waitingOnAuthorization: "yellowRed",
    authorizedToOrder: "lightRed",
    needsQuote: "red",
    received: "green",
    waitingToReceive: "greenYellow",
    waitingOnVendor: "yellow",
  };
  return {
    title: status.name,
    color: obj[toCamelCase(status?.name || "")],
  };
};

export const getDefaultActionPart = (
  part?: IActionPart,
  actionItemId?: string
) => {
  const data = {} as IActionPartForm;
  if (!part) {
    if (actionItemId) {
      data.action_item = actionItemId;
      data.part_number = "";
      data.description = "";
      data.price = 0;
      data.vendor = undefined;
      return data;
    }
    return data;
  }

  return {
    ...part,
    part: part?.part?.id,
    status: part.status.id,
  } as IActionPartForm;
};

export const checkButtonActive = (parts: IActionPart[], status: number) => {
  if (!parts.length) return false;
  if (status === 5) {
    return parts.every(
      (part) =>
        part.status.queue === status &&
        parts[0].vendor &&
        part.vendor?.id === parts[0].vendor?.id &&
        part.purchase_order_line_item?.purchase_order &&
        part.purchase_order_line_item?.purchase_order ===
          parts[0].purchase_order_line_item?.purchase_order
    );
  }
  return parts.every((part) => part.status.queue === status);
};

export const getActionItemPartButtonTitle = (part: IActionPart) => {
  const arr = ["Add Quote", "Remove", "Remove", "Order", "Receive", "Return"];
  return arr[part.status.queue - 1];
};

export const getActionItemPartOrderFields = (parts: IActionPart[]) => {
  return [
    {
      label: "Vendor",
      value: getShortValue(parts[0].vendor?.vendor_name, 10),
    },
    {
      label: "Qty",
      value: parts.reduce((total, part) => total + part.quantity, 0),
    },
    {
      label: "Price ($)",
      value: parts.reduce(
        (total, part) => total + part.quantity * part.price,
        0
      ),
    },
  ];
};

export const getActionItemPartRecieveFields = (part: IActionPart) => {
  if (!part) return [];
  return [
    {
      label: "Part",
      value: getShortValue(part.part_number, 10),
    },
    {
      label: "Qty",
      value: part.quantity,
    },
    {
      label: "Price ($)",
      value: part.price,
    },
  ];
};

export const convertActionPartToPurchaseOrder = (
  parts: IActionPart[],
  orderId: string,
  shopId: string
) => {
  const data = {} as IPurchaseOrderAdd;
  data.order = orderId;
  data.vendor = parts[0]?.vendor?.id as string;
  data.type = PURCHASE_ORDER_TYPE.PO;
  data.status = PURCAHSE_ORDER_STATUS.SENT;
  data.shop = shopId;
  data.line_items = parts.map((part) => ({
    part: part.part?.id,
    quantity: part.quantity,
    part_number: part.part_number,
    actual_cost: part.price,
    description: part.description,
    action_item_part: part.id,
  }));
  return data;
};

export const convertActionPartToReceivePurchaseOrder = (
  parts: IActionPart[]
) => {
  const data = {} as IPurchaseOrderRecieved;
  data.credit_amount = 0;
  data.date = format(new Date(), "YYYY-MM-DD");
  data.number = "";
  data.packing_slip = "";
  data.receive_line_items =
    (parts &&
      parts.map((part) => ({
        received_quantity: 0,
        line_item_id: part.purchase_order_line_item?.line_item as string,
      }))) ||
    [];
  data.sub_total = 0;
  data.total = 0;
  return data;
};
