import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";
import {
  IOrder,
  IOrderCountStatus,
  IOrderList,
  IOrderNotification,
  IOrderStatus,
} from "Shared/Types/order";
import {
  createOrder,
  deleteOrder,
  getOnHoldOrderList,
  getOrder,
  getOrderActionItemParts,
  getOrderCountByStatus,
  getOrderList,
  getOrderListByEmployee,
  getOrderPartStatus,
  getOrderStatusList,
  updateOrder,
} from "./reducer";
import { IOrderAdd } from "Shared/Types/orderAdd";
import { readObj, write } from "Service/storage";
import { IActionPart } from "Shared/Types/action-item-part";

interface OrderState {
  orderList?: IOrderList[];
  onHoldOrderList?: IOrderList[];
  orderListByEmployee?: IOrderList[];
  currentOrder?: IOrder | null;
  loading: boolean;
  statusList?: IOrderStatus[];
  orderCountByStatus?: IOrderCountStatus;
  total?: number;
  totalOnHold?: number;
  numPage?: number;
  numOnHoldPage?: number;
  createdOrder?: IOrderAdd | null;
  notification: IOrderNotification[];
  createdOrderStatus?: string;
  actionItemParts: IActionPart[];
  partStatusList?: IOrderStatus[];
}

const initialState: OrderState = {
  currentOrder: readObj("created_service_order"),
  loading: false,
  statusList: [],
  notification: [],
  partStatusList: [],
  actionItemParts: [],
};

const orderSlice = createSlice({
  name: "order",
  initialState,
  reducers: {
    setCreatedOrder(state, action) {
      write("created_service_order", action.payload);
    },
  },
  extraReducers(builder) {
    builder.addCase(getOrder.pending, (state) => {
      state.loading = true;
      state.currentOrder = null;
    });
    builder.addCase(getOrder.rejected, (state) => {
      state.loading = false;
      state.currentOrder = null;
    });
    builder.addCase(getOrder.fulfilled, (state, action) => {
      state.currentOrder = action.payload;
      state.loading = false;
    });
    builder.addCase(createOrder.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(createOrder.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(createOrder.fulfilled, (state, action) => {
      state.loading = false;
      state.createdOrderStatus = action.payload.status_order;
    });
    builder.addCase(updateOrder.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateOrder.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(updateOrder.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(deleteOrder.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(deleteOrder.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(deleteOrder.fulfilled, (state) => {
      state.loading = false;
    });

    builder.addCase(getOrderList.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getOrderList.rejected, (state) => {
      state.loading = false;
      state.orderList = [];
    });
    builder.addCase(getOrderList.fulfilled, (state, action) => {
      state.loading = false;
      state.orderList = action.payload.results;
      state.total = action.payload.count;
      state.numPage = action.payload.num_pages;
    });
    builder.addCase(getOnHoldOrderList.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getOnHoldOrderList.rejected, (state) => {
      state.loading = false;
      state.onHoldOrderList = [];
    });
    builder.addCase(getOnHoldOrderList.fulfilled, (state, action) => {
      state.loading = false;
      state.onHoldOrderList = action.payload.results;
      state.totalOnHold = action.payload.count;
      state.numOnHoldPage = action.payload.num_pages;
    });
    builder.addCase(getOrderStatusList.fulfilled, (state, action) => {
      state.loading = false;
      state.statusList = action.payload;
    });
    builder.addCase(getOrderCountByStatus.fulfilled, (state, action) => {
      state.loading = false;
      state.orderCountByStatus = action.payload;
    });
    builder.addCase(getOrderPartStatus.fulfilled, (state, action) => {
      state.loading = false;
      state.partStatusList = action.payload;
    });

    builder.addCase(getOrderListByEmployee.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getOrderListByEmployee.rejected, (state) => {
      state.loading = false;
      state.orderList = [];
    });
    builder.addCase(getOrderListByEmployee.fulfilled, (state, action) => {
      state.loading = false;
      state.orderListByEmployee = action.payload.results;
    });

    builder.addCase(getOrderActionItemParts.fulfilled, (state, action) => {
      state.loading = false;
      state.actionItemParts = action.payload;
    });
  },
});

export const { setCreatedOrder } = orderSlice.actions;

export const selectIsLoading = (state: RootState) => state.order.loading;
export const selectOrderLoading = (state: RootState) => state.order.loading;
export const selectOrderTotal = (state: RootState) => state.order.total;
export const selectOnHoldOrderTotal = (state: RootState) =>
  state.order.totalOnHold;
export const selectOrderNumPage = (state: RootState) => state.order.numPage;
export const selectOnHoldOrderNumPage = (state: RootState) =>
  state.order.numOnHoldPage;
export const selectOrderList = (state: RootState) => state.order.orderList;
export const selectOnHoldOrderList = (state: RootState) =>
  state.order.onHoldOrderList;
export const selectOrderCountByStatus = (state: RootState) =>
  state.order.orderCountByStatus;
export const selectCurrentOrder = (state: RootState) =>
  state.order.currentOrder;
export const selectCreatedOrder = (state: RootState) =>
  state.order.createdOrder;
export const selectOrderNotification = (state: RootState) =>
  state.order.notification;
export const selectOrderStatusList = (state: RootState) =>
  state.order.statusList;
export const selectCreatedOrderStatus = (state: RootState) =>
  state.order.createdOrderStatus;
export const selectOrderListByEmployee = (state: RootState) =>
  state.order.orderListByEmployee;
export const selectOrderActionItemParts = (state: RootState) =>
  state.order.actionItemParts;

export const selectOrderPartStatusList = (state: RootState) =>
  state.order.partStatusList;

export default orderSlice;
