import React, { useEffect, useState } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { Controller, useFormContext } from "react-hook-form";
import { useSelector } from "react-redux";
import { useAppDispatch } from "Redux/store";
import styles from "./AutoCompleteSearchInput.module.scss";
import { selectVendorList } from "Redux/Vendor/slice";
import { getVendorList } from "Redux/Vendor/reducer";
import { IVendor } from "Shared/Types/vendor";
import { componentsProps } from "./LaborRateSearch";
import { ArrowDownBlackIcon } from "Assets/index";
import { isInstanced } from "Shared/Utils/app";

interface VendorSearchProps {
  name: string;
  required?: boolean;
  label?: string;
  withControl?: boolean;
  onChange?: (vendor: IVendor | null) => void;
  labelColor?: string;
  defaultValue?: IVendor;
  value?: IVendor | null;
}

export const VendorSearch: React.FC<VendorSearchProps> = ({
  name,
  required,
  label,
  onChange,
  withControl,
  labelColor,
  defaultValue,
  value,
}) => {
  const { control } = useFormContext();
  const vendors = useSelector(selectVendorList) || [];
  const [searchText, setSearchText] = useState<string | null>("");
  const [currentValue, setCurrentValue] = useState<IVendor | null | undefined>(
    value
  );
  const dispatch = useAppDispatch();
  const searchVendors = (text: string | null) => {
    dispatch(
      getVendorList({
        search: text || null,
      })
    );
  };
  const getLabel = (vendor: IVendor | null) => {
    if (vendor) {
      return vendor?.vendor_name;
    }
    return "";
  };
  useEffect(() => {
    if (!value) {
      setCurrentValue(undefined);
      setSearchText("");
    } else {
      setCurrentValue(value);
      setSearchText(value?.vendor_name);
    }
  }, [value]);
  useEffect(() => {
    searchVendors(searchText);
  }, [searchText]);

  useEffect(() => {
    const text =
      defaultValue?.vendor_name ||
      isInstanced<IVendor>(control._defaultValues?.[name])
        ? control._defaultValues?.[name]?.vendor_name
        : null;
    dispatch(
      getVendorList({
        search: text,
      })
    );
  }, [control._defaultValues?.[name]]);

  return (
    <div className={styles.autocomplete}>
      <label
        data-id={name}
        className={styles.autocomplete__label}
        style={{ color: labelColor }}
      >
        {label}
        {required && <span className={styles.autocomplete__error}>*</span>}
      </label>
      {withControl ? (
        <Controller
          name={name}
          control={control}
          defaultValue={control._defaultValues?.[name]}
          render={({ field }) => (
            <Autocomplete
              {...field}
              options={vendors}
              getOptionLabel={getLabel}
              value={currentValue}
              onChange={(_, value) => {
                onChange?.(value);
                setCurrentValue(value);
                field.onChange(value);
              }}
              disablePortal={true}
              popupIcon={<ArrowDownBlackIcon />}
              componentsProps={componentsProps}
              renderInput={(params) => (
                <TextField
                  sx={{
                    ".MuiOutlinedInput-notchedOutline": {
                      borderStyle: "none",
                    },
                  }}
                  classes={{ root: styles.autocomplete__input }}
                  name={name}
                  required={required}
                  value={searchText}
                  onChange={(event) => {
                    setSearchText(event.target.value);
                  }}
                  {...params}
                />
              )}
            />
          )}
        />
      ) : (
        <Autocomplete
          options={vendors}
          defaultValue={defaultValue}
          getOptionLabel={getLabel}
          disablePortal={true}
          componentsProps={componentsProps}
          value={currentValue}
          onChange={(_, val) => {
            onChange?.(val || null);
            setCurrentValue(val);
          }}
          popupIcon={<ArrowDownBlackIcon />}
          renderInput={(params) => (
            <TextField
              {...params}
              sx={{
                ".MuiOutlinedInput-notchedOutline": {
                  borderStyle: "none",
                },
              }}
              classes={{ root: styles.autocomplete__input }}
              name={name}
              required={required}
              onChange={(event) => {
                setSearchText(event.target.value);
              }}
            />
          )}
        />
      )}
    </div>
  );
};
