import React from "react";
import styles from "./EstimateOrderAdd.module.scss";
import EstimateForm from "Components/Order/Estimate/EstimateForm/EstimateForm";
import { estimatesScheme } from "Shared/Utils/estimate";
import { useSelector } from "react-redux";
import { selectCurrentEstimateOrder } from "Redux/Estimate/slice";

export function EstimateOrderAdd() {
  const currentEstimate = useSelector(selectCurrentEstimateOrder);
  return (
    <div className={styles.container}>
      <EstimateForm
        schema={estimatesScheme}
        currentEstimate={currentEstimate}
      />
    </div>
  );
}
