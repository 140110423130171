import RequestContainer from "Containers/Requests/RequestContainer";
import React from "react";
import Layout from "Components/Layout/Layout";

export const Requests: React.FC = () => {
  return (
    <Layout>
      <RequestContainer />
    </Layout>
  );
};
