import React from "react";
import { BreadcrumbItem } from "Components/Breadcrumb/Breadcrumb";
import Layout from "Components/Layout/Layout";
import OrderList from "Containers/Order/AllOrder/OrderList/OrderList";
import { useBreadcrumb } from "Shared/Hooks/use-breadcrumb";
import { ORDER_CREATE } from "Shared/Constants/order";

export const OnHoldOrdersPage: React.FC = () => {
  const breads: BreadcrumbItem[] = [
    { title: "Orders", link: "/orders" },
    { title: "On Hold", link: "/orders/on-hold" },
  ];
  useBreadcrumb({ links: breads, ...ORDER_CREATE });
  return (
    <Layout>
      <OrderList onHold />
    </Layout>
  );
};
