import { yupResolver } from "@hookform/resolvers/yup";
import Button from "Components/UI/Button/Button";
import Form from "Components/UI/Form/Form";
import Checkbox from "Components/UI/Inputs/Checkbox";
import InputField from "Components/UI/Inputs/InputField";
import React from "react";
import { useForm } from "react-hook-form";
import { ObjectSchema } from "yup";
import styles from "./SupplyForm.module.scss";
import { ISupplyRate, ISupplyRateAdd } from "Shared/Types/supplyRate";
import SelectField from "Components/UI/SelectField/SelectField";
import { DollarWhiteIcon } from "Assets/index";

type SupplyFormProps = {
  onSubmit: (values: ISupplyRateAdd) => void;
  scheme: ObjectSchema<ISupplyRateAdd>;
  supplyRate?: ISupplyRate | undefined;
};

const calculation_options = [
  { label: "Labor Only", value: "Labor Only" },
  { label: "Parts & Labor", value: "Parts & Labor" },
];

const SupplyForm: React.FC<SupplyFormProps> = ({
  onSubmit,
  scheme,
  supplyRate,
}) => {
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(scheme),
    defaultValues: supplyRate,
  });

  return (
    <Form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
      <InputField
        withController
        control={control}
        name="title"
        required
        label="Name"
      />
      <InputField
        withController
        control={control}
        name="display_title"
        required
        label="Display Title:"
      />
      <InputField
        control={control}
        name="rate"
        required
        type="number"
        label="Rate (i.e. 8%):"
        withController
      />
      <SelectField
        withControl
        control={control}
        label="Include in calculation:"
        options={calculation_options}
        name="include_in_calculation"
        required
      />
      <div className={styles.form__signed_input}>
        <InputField
          icon={DollarWhiteIcon}
          control={control}
          name="min_amount"
          required
          type="number"
          label="Minimum Amount:"
          withController
        />
      </div>
      <div className={styles.form__signed_input}>
        <InputField
          control={control}
          icon={DollarWhiteIcon}
          name="max_amount"
          required
          type="number"
          label="Maximum Amount:"
          withController
        />
      </div>
      <Checkbox
        control={control}
        withController
        label="Is Default"
        name="default"
        labelPosition="right"
      />
      <div className={styles.form__actions}>
        {supplyRate ? (
          <Button variant="outlined" type="submit">
            Save
          </Button>
        ) : (
          <Button type="submit">Create</Button>
        )}
      </div>
    </Form>
  );
};
export default SupplyForm;
