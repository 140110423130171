import React, { useEffect, useState } from "react";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import styles from "./DateRange.module.scss";
import { CalendarIcon } from "Assets/index";
import { useSearchParams } from "react-router-dom";
import { format } from "Shared/Utils/app";

type ValuePiece = Date | null;

export type Value = ValuePiece | [ValuePiece, ValuePiece];

const DateRange: React.FC = () => {
  const today = new Date();
  const thirtyDaysAgo = new Date();
  thirtyDaysAgo.setDate(today.getDate() - 30);
  const [value, onChange] = useState<Value>([thirtyDaysAgo, today]);
  const [searchParams, setSearchParams] = useSearchParams();
  const changeDate = (value: Value) => {
    if (value && Array.isArray(value)) {
      if (value[0]) {
        searchParams.set("from", format(value[0], "MM-DD-YYYY"));
      }
      if (value[1]) {
        searchParams.set("to", format(value[1], "MM-DD-YYYY"));
      }
    } else {
      searchParams.delete("from");
      searchParams.delete("to");
    }
    setSearchParams(searchParams);
  };

  useEffect(() => {
    const from = searchParams.get("from");
    const to = searchParams.get("to");

    if (from && to) {
      const fromDate = new Date(from);
      const toDate = new Date(to);
      if (isNaN(fromDate.getTime()) || isNaN(toDate.getTime())) return;
      onChange([new Date(from), new Date(to)]);
    }
  }, []);
  return (
    <div className={styles.date}>
      <DateRangePicker
        onChange={(value) => {
          changeDate?.(value);
          onChange(value);
        }}
        value={value}
        calendarIcon={<CalendarIcon />}
        dayPlaceholder="dd"
        monthPlaceholder="mm"
        yearPlaceholder="yyyy"
        rangeDivider=" "
      />
    </div>
  );
};
export default DateRange;
