import React, { ChangeEvent, useState } from "react";
import InputField from "Components/UI/Inputs/InputField";
import styles from "../ComponentSystemContainer.module.scss";
import Button from "Components/UI/Button/Button";
import { useAppDispatch } from "Redux/store";
import { useSelector } from "react-redux";
import { notifySuccess } from "Components/Notify/notify";
import { createComponent } from "Redux/Component/reducers";
import { selectCurrentComponent } from "Redux/Component/componentSlice";
import Block from "Components/UI/Block/Block";
import { useNavigate } from "react-router";
import StepProgress from "Components/UI/StepProgress/StepProgress";
import { checkFirstAuth } from "Shared/Utils/app";
import { selectAccount } from "Redux/Auth/slice";

export const stepDesc = (
  <div style={{ fontSize: "14px" }}>
    <p>Components</p>
    <p>
      In &apos;Components,&apos; list each repair or service item for precise
      inventory tracking, keeping your shop organized and well-stocked.
    </p>
    <br />
    <p>Systems</p>
    <p>
      In &apos;Systems,&apos; categorize broader components like brakes or
      electrical systems for a comprehensive view, ensuring systematic
      organization of your shop&apos;s infrastructure.
    </p>
  </div>
);

const ComponentAdd: React.FC = () => {
  const dispatch = useAppDispatch();
  const addedComponent = useSelector(selectCurrentComponent);
  const navigate = useNavigate();
  const profile = useSelector(selectAccount);
  const isFirstAuth = checkFirstAuth(profile);
  const [componentValue, setComponentValue] = useState("");
  const onChangeValue = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setComponentValue(e.target.value);
  };
  const goToCustomerAdd = () => {
    navigate("/customers/new");
  };
  const onCreate = async (isAdd?: boolean) => {
    const onSuccess = () => {
      notifySuccess("Component success");
      if (!isAdd) {
        navigate("/settings/component-system");
      } else {
        navigate(`/settings/component-system/${addedComponent?.component.id}`);
      }
      setComponentValue("");
    };
    await dispatch(
      createComponent({ data: { name: componentValue }, onSuccess })
    );
  };

  return (
    <>
      {isFirstAuth && (
        <StepProgress
          description={stepDesc}
          active={3}
          title="Creating Services"
        />
      )}
      <Block className={styles.component__add}>
        {isFirstAuth && (
          <p className={styles.component__add__title}>Component</p>
        )}
        <InputField
          onChange={onChangeValue}
          value={componentValue}
          label="Name"
          name="name"
          required
        />
        <div className={styles.component__add__action}>
          {isFirstAuth && (
            <Button onClick={goToCustomerAdd} color="secondary" type="button">
              Skip
            </Button>
          )}
          {!isFirstAuth && (
            <Button variant="outlined" onClick={() => onCreate(true)}>
              Add System
            </Button>
          )}
          <Button variant="contained" onClick={() => onCreate()}>
            Create
          </Button>
        </div>
      </Block>
    </>
  );
};

export default ComponentAdd;
