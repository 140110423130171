import React, { useEffect, useState } from "react";
import { useAppDispatch } from "Redux/store";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import ListLayout from "Components/Layout/ListLayout/ListLayout";
import { useSearchParams } from "react-router-dom";
import { selectEstimatePartList, selectPartNumPage } from "Redux/Product/slice";
import { getEstimatePartList } from "Redux/Product/reducer";
import {
  getEstimatePartCardData,
  getEstimatePartCardStatus,
} from "Shared/Utils/part";
import { estimatePartFields, partFields } from "Shared/Constants/part";
import Card from "Components/UI/Card/Card/Card";
import Row from "Components/UI/Card/Row/Row";
import { IEstimatesOrder } from "Shared/Types/estimate";

const EstimatePartList: React.FC = () => {
  const parts = useSelector(selectEstimatePartList) || [];
  const numPage = useSelector(selectPartNumPage);
  const dispatch = useAppDispatch();
  const [isCard, setIsCard] = useState(true);
  const [fields, setFields] = useState(estimatePartFields);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const goToDetailPage = (id: string) => {
    navigate(`/orders/estimates/${id}`);
  };

  const props = (part: IEstimatesOrder) => {
    return {
      fields: fields,
      onClick: goToDetailPage,
      cardData: getEstimatePartCardData(part),
      dataId: part.id,
      status: getEstimatePartCardStatus(part.status),
      isNeedStatus: true,
      withoutMore: true,
    };
  };

  useEffect(() => {
    dispatch(
      getEstimatePartList({
        search: searchParams.get("search"),
        page: +(searchParams.get("page") || 1),
      })
    );
  }, [searchParams]);

  return (
    <ListLayout
      pagesNumber={numPage || 1}
      isCard={isCard}
      setIsCard={setIsCard}
      fields={fields}
      setFields={setFields}
      filters={["search", "customer", "unit", "date"]}
      fieldAllList={partFields}
    >
      {isCard
        ? parts.map((part) => <Card key={part.id} {...props(part)} />)
        : parts.map((part) => <Row key={part.id} {...props(part)} />)}
    </ListLayout>
  );
};
export default EstimatePartList;
