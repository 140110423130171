import React from "react";
import { ButtonProps, Button as MUIButton } from "@mui/material";
import cn from "classnames";
import styles from "./Button.module.scss";
import { BeatLoader } from "react-spinners";
interface IButton extends Omit<ButtonProps, "variant" | "color"> {
  color?: "primary" | "secondary" | "success" | "error";
  variant?: "outlined" | "contained" | "text";
  loading?: boolean;
}

const Button: React.FC<IButton> = ({
  color = "primary",
  className,
  loading,
  ...props
}) => {
  const buttonClasses = {
    root: cn(styles.button, styles[`button__${color}`], className),
  };

  return (
    <MUIButton
      variant="contained"
      color={color}
      classes={buttonClasses}
      {...props}
    >
      {loading ? (
        <BeatLoader
          color="white"
          loading={loading}
          cssOverride={{ margin: "auto", padding: "4px 0" }}
          size={10}
          aria-label="Loading Spinner"
          data-testid="loader"
        />
      ) : (
        props.children
      )}
    </MUIButton>
  );
};

export default Button;
