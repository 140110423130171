import { clearAll, readObj } from "Service/storage";
import { AxiosError } from "axios";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import "dayjs/locale/en";

dayjs.extend(utc); // Enable utc plugin
dayjs.extend(timezone); // Enable timezone plugin
import { history } from "./search-params";
import { IUnit } from "Shared/Types/unit";
import { ICustomer } from "Shared/Types/customer";
import { IVendor } from "Shared/Types/vendor";
import { IUser } from "Shared/Types/user";
import { ISystem } from "Shared/Types/system";
import { IAccount } from "Shared/Types/auth";
import { IUnitType } from "Shared/Types/unit-type";
import { ICompanyRequest } from "Shared/Types/company";
import { ILaborRate } from "Shared/Types/laborRate";
import { IService } from "Shared/Types/service";
import { notifyError } from "Components/Notify/notify";
import { IManufacturer } from "Shared/Types/manufacturer";
import { ILocation } from "Shared/Types/location";
import { SHOP_NOT_ASSIGNED } from "Shared/Constants/app";

export const formatBackend = (
  date: string | Date,
  formatDate = "YYYY-MM-DDTHH:mm:ss.SSSSSSZ"
) => {
  let utcDate = dayjs(date);

  // Check if it's a Date object
  if (date instanceof Date) {
    utcDate = dayjs(date); // Convert to dayjs object
  }

  // Convert to UTC (timezone offset 0) and format for backend
  return utcDate.utc().format(formatDate);
};

export const format = (date: string | Date, formatDate = "h:mmA M/D/YYYY") => {
  const localDate = dayjs(date);
  return localDate.format(formatDate);
};

export const handleError = (error: AxiosError) => {
  if (error.response?.status === 401) {
    clearAll();
    history.push({ pathname: "login" });
  }
};

export const errorHandler = (error: Error, showError?: boolean) => {
  let msg = "";
  if (error instanceof AxiosError) {
    if (error.response?.data) {
      const errorData = error.response?.data;
      if (errorData instanceof Object) {
        const fields = errorData?.extra?.fields;
        if (fields instanceof Object) {
          for (const field in fields) {
            if (Array.isArray(fields[field])) {
              msg += `${field}: ${fields[field].join(" ")}`;
            } else {
              msg += `${field}: ${fields[field].toString()}`;
            }
          }
        } else {
          msg = errorData?.message;
        }
        error.message = msg;
      }
    }
  }
  if (showError) {
    notifyError(error.message);
  }
};

export function toCamelCase(inputString: string) {
  return inputString
    .split(" ")
    .map((word, index) => {
      if (index === 0) {
        // Capitalize the first word
        return word.charAt(0).toLowerCase() + word.slice(1);
      } else {
        // Capitalize the first letter of subsequent words
        return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
      }
    })
    .join("");
}

export const havePermission = (
  userPermissions: string[],
  permissions: string[]
) => {
  if (permissions.length === 0) return true;
  return permissions.some((permission) =>
    userPermissions.map((userPer) => userPer).includes(permission)
  );
};

export const changeUnitOptions = (units: IUnit[]) => {
  return units.map((unit) => ({ label: unit.number, value: unit.id }));
};

export const changeCustomerOptions = (customers: ICustomer[]) => {
  return customers.map((customer) => ({
    label: customer?.company_name || customer.first_name,
    value: customer.id,
  }));
};

export const changeTypeOfChassisOptions = (type_of_chassis: IUnitType[]) => {
  return type_of_chassis.map((type_of_chassis) => ({
    label: type_of_chassis.name,
    value: type_of_chassis.id,
  }));
};

export const changeVendorOptions = (vendor: IVendor[]) => {
  return vendor.map((vendor) => ({
    label: vendor.vendor_name,
    value: vendor.id,
  }));
};

export const changeEmployeeOptions = (employees: IUser[]) => {
  return employees.map((employee) => ({
    label: employee.first_name + " " + employee.last_name,
    value: employee.id,
  }));
};

export const changeCompanyRequestsOptions = (
  requestsList: ICompanyRequest[]
) => {
  return requestsList.map((request) => ({
    label: `${request.first_name} ${request.last_name}`,
    value: request.id,
  }));
};

export const changeSytemOptions = (systems: ISystem[]) => {
  return systems.map((system) => ({
    label: system.component?.name + ":  " + system.name,
    value: system.id,
  }));
};

export const changeLaborRateOptions = (laborRates: ILaborRate[]) => {
  return laborRates.map((laborRate) => ({
    label: laborRate.name,
    value: laborRate.id,
  }));
};

export const changeServiceOptions = (services: IService[]) => {
  return services.map((service) => ({
    label: service.name,
    value: service.name,
  }));
};

export const changeManufacturerOptions = (manufacturers: IManufacturer[]) => {
  return manufacturers.map((manufacturer) => ({
    label: manufacturer.name,
    value: manufacturer.id,
  }));
};

export const changeLocationOptions = (locations: ILocation[]) => {
  return locations.map((location) => ({
    label: location.name,
    value: location.id,
  }));
};

export const checkFirstAuth = (profile?: IAccount | null) => {
  return profile?.is_first_auth && profile?.is_company_admin;
};

export const checkPathname = (
  pathname: string,
  link: string,
  rootPath: string[]
) => {
  const paths = [
    "/orders/on-hold",
    "/orders/history",
    "/orders/estimates",
    "/returns",
    "/stats",
    "/profile",
    "/requests/all-requests",
    "/requests/review",
    "/requests/rejected",
    "/requests/approved",
    "/company",
  ];
  return (
    paths.some((path) => pathname === path || pathname.startsWith(path)) &&
    !rootPath.includes(link)
  );
};

export function secondsToHours(seconds?: number | null) {
  if (!seconds) return 0.0;
  return (seconds / 3600).toFixed(2);
}

export function isInstanced<T>(data: any): data is T {
  return typeof data === "object" && data !== null;
}

export const checkShopAssignedAndGet = () => {
  const shop = readObj("current_shop");
  if (!shop) {
    notifyError(SHOP_NOT_ASSIGNED);
    return false;
  }
  return shop;
};
