import React from "react";
import styles from "./UnitTypeAdd.module.scss";
import { useAppDispatch } from "Redux/store";
import { useSelector } from "react-redux";
import { notifySuccess } from "Components/Notify/notify";
import { addUnitType } from "Redux/UnitType/reducer";
import UnitTypeForm from "Components/UnitType/UnitTypeForm/UnitTypeForm";
import { unitTypeSchema } from "Shared/Utils/unit-type";
import { IUnitTypeAdd } from "Shared/Types/unit-type";
import { useNavigate } from "react-router";
import StepProgress from "Components/UI/StepProgress/StepProgress";
import { selectAccount } from "Redux/Auth/slice";
import { stepDescription } from "Containers/Home/Home";
import { checkFirstAuth } from "Shared/Utils/app";

const UnitTypeAdd: React.FC = () => {
  const dispatch = useAppDispatch();
  const profile = useSelector(selectAccount);
  const navigate = useNavigate();
  const isFristAuth = checkFirstAuth(profile);

  const createUnitType = (values: IUnitTypeAdd) => {
    const onSuccess = () => {
      notifySuccess("Unit type created");
      if (isFristAuth) {
        navigate("/employees/new");
      } else {
        navigate("/settings/unit-settings/unit-types");
      }
    };
    dispatch(addUnitType({ data: values, onSuccess }));
  };

  return (
    <div className={styles.unitTypeAdd}>
      {isFristAuth && (
        <StepProgress
          description={stepDescription}
          active={0}
          title="Unit settings"
        />
      )}
      <div className={styles.unitTypeAdd__form}>
        <UnitTypeForm onSubmit={createUnitType} schema={unitTypeSchema} />
      </div>
    </div>
  );
};
export default UnitTypeAdd;
