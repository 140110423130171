import { IOrderList } from "Shared/Types/order";
import { format } from "Shared/Utils/app";

export const unitTableHeads = [
  { id: "name", label: "Name" },
  { id: "type_of_chassis", label: "Type of chessis" },
  { id: "subtype", label: "Subtype" },
  { id: "vin", label: "VIN" },
  { id: "customer", label: "Customer" },
  { id: "license_plate", label: "License Plate State" },
  { id: "action", label: "Action" },
];

export const unitFields = [
  "Name",
  "Type of chassis",
  "Subtype",
  "Track PM's",
  "License Plate State",
  "Customer",
  "VIN",
];

export const SOUnitFields = [
  "Order",
  "Created",
  "Completed date",
  "Hours",
  "Creator",
];

export const usaStates = [
  "Alabama",
  "Alaska",
  "Arizona",
  "Arkansas",
  "California",
  "Colorado",
  "Connecticut",
  "Delaware",
  "Florida",
  "Georgia",
  "Hawaii",
  "Idaho",
  "Illinois",
  "Indiana",
  "Iowa",
  "Kansas",
  "Kentucky",
  "Louisiana",
  "Maine",
  "Maryland",
  "Massachusetts",
  "Michigan",
  "Minnesota",
  "Mississippi",
  "Missouri",
  "Montana",
  "Nebraska",
  "Nevada",
  "New Hampshire",
  "New Jersey",
  "New Mexico",
  "New York",
  "North Carolina",
  "North Dakota",
  "Ohio",
  "Oklahoma",
  "Oregon",
  "Pennsylvania",
  "Rhode Island",
  "South Carolina",
  "South Dakota",
  "Tennessee",
  "Texas",
  "Utah",
  "Vermont",
  "Virginia",
  "Washington",
  "West Virginia",
  "Wisconsin",
  "Wyoming",
];

export const getSOUnitCardData = (order: IOrderList) => {
  return [
    {
      title: "Order",
      value: order.order_number,
    },
    {
      title: "Created",
      value: format(order.created_at as string, "MM/DD/YYYY"),
    },
    {
      title: "Completed date",
      value: format(order.finish_date as string, "MM/DD/YYYY"),
    },
    {
      title: "Hours",
      value: order.invoice_hours ? `${order.invoice_hours} Hr` : "",
    },
    {
      title: "Creator",
      value: order.customer?.first_name || "",
    },
    {
      title: "Name",
      value: order.customer?.first_name || "",
    },
  ];
};
