import React, { useEffect, useState } from "react";
import { IActionItem, IActionItemUpdate } from "Shared/Types/order";

import { Button as MuiButton } from "@mui/material";
import { getActoinSendLabel, getStartWorkLabel } from "Shared/Utils/order";
import { useAppDispatch } from "Redux/store";
import {
  markActionAsDiagnosed,
  underReview,
  updateActionItem,
} from "Redux/ActionItem/reducer";
import { formatBackend } from "Shared/Utils/app";
import { notifySuccess } from "Components/Notify/notify";
import { useSelector } from "react-redux";
import { selectCurrentOrder } from "Redux/Order/slice";
import { getOrder } from "Redux/Order/reducer";

type CountRepairtimeProps = {
  currentActionItem: IActionItem;
  index: number;
};

const CountRepairtime: React.FC<CountRepairtimeProps> = ({
  currentActionItem,
  index,
}) => {
  const [counter, setCounter] = useState(currentActionItem?.actual_time || 0);
  const dispatch = useAppDispatch();
  const order = useSelector(selectCurrentOrder);

  const onSuccess = () => {
    notifySuccess("Action item is updated");
    if (order) {
      dispatch(getOrder(order.id));
    }
  };

  const endWork = () => {
    if (currentActionItem?.is_working) {
      dispatch(
        updateActionItem({
          id: currentActionItem.id,
          data: {
            end_work: formatBackend(new Date()),
            actual_time: counter,
            is_working: false,
            stop_date: formatBackend(new Date()),
          },
          onSuccess,
        })
      );
    }
    if (currentActionItem?.id) {
      if (currentActionItem.status?.queue === 2) {
        const values: IActionItemUpdate = {
          ...currentActionItem,
          status: currentActionItem?.status?.id,
        };
        dispatch(
          markActionAsDiagnosed({
            id: currentActionItem.id,
            data: values,
            onSuccess,
          })
        );
        return;
      }
      dispatch(underReview({ id: currentActionItem?.id, onSuccess }));
    }
  };

  const startWork = () => {
    if (currentActionItem) {
      const data: IActionItemUpdate = {};
      if (currentActionItem.is_working) {
        data.stop_date = formatBackend(new Date());
        data.actual_time = counter;
        data.is_working = false;
      } else {
        if (currentActionItem.start_work) {
          data.play_date = formatBackend(new Date());
          data.is_working = true;
        } else {
          data.start_work = formatBackend(new Date());
          data.is_working = true;
        }
      }
      dispatch(
        updateActionItem({
          id: currentActionItem.id,
          data,
          onSuccess,
        })
      );
    }
  };

  useEffect(() => {
    let intervalId: any;

    if (currentActionItem?.is_working) {
      const startWorkDate = new Date(
        currentActionItem?.play_date || new Date()
      );
      intervalId = setInterval(() => {
        const currentDate = new Date();
        const differenceInSeconds = Math.floor(
          (+currentDate - +startWorkDate) / 1000 +
            (currentActionItem.actual_time || 0)
        );
        setCounter(differenceInSeconds);
      }, 1000);
    } else {
      clearInterval(intervalId);
    }

    return () => {
      clearInterval(intervalId);
    };
  }, [currentActionItem]);
  return (
    <div>
      <MuiButton
        variant="contained"
        color={
          !currentActionItem.is_working && currentActionItem.actual_time
            ? "warning"
            : "primary"
        }
        onClick={startWork}
        sx={{
          mr: "10px",
          textTransform: "none",
        }}
      >
        {getStartWorkLabel(currentActionItem, counter)}
      </MuiButton>
      <MuiButton
        variant="contained"
        color="success"
        onClick={endWork}
        sx={{
          textTransform: "none",
        }}
      >
        {getActoinSendLabel(currentActionItem.status?.queue, index)}
      </MuiButton>
    </div>
  );
};
export default CountRepairtime;
