import Layout from "Components/Layout/Layout";
import React from "react";
import PartList from "Containers/Part/PartList/PartList";
import { BreadcrumbItem } from "Components/Breadcrumb/Breadcrumb";
import { useBreadcrumb } from "Shared/Hooks/use-breadcrumb";

export const PartListPage: React.FC = () => {
  const breads: BreadcrumbItem[] = [{ title: "Parts", link: "/parts" }];
  useBreadcrumb({
    links: breads,
    createTitle: "Part",
    isNeedCreateBtn: true,
  });
  return (
    <Layout>
      <PartList />
    </Layout>
  );
};
