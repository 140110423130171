import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import InputField from "Components/UI/Inputs/InputField";
import SelectField from "Components/UI/SelectField/SelectField";
import { useSelector } from "react-redux";
import {
  selectCities,
  selectCountries,
  selectStates,
} from "Redux/Address/slice";
import { useAppDispatch } from "Redux/store";
import { getCities, getCountries, getStates } from "Redux/Address/reducer";
import { Control } from "react-hook-form";

type AddressFieldsProps = {
  control: Control<any>;
  street_address_name?: string;
  isNeedPostalCode?: boolean;
  requiredFields?: string[];
  zip_code?: string;
};
export const AddressFields: React.FC<AddressFieldsProps> = ({
  control,
  street_address_name = "street_address_2",
  isNeedPostalCode,
  requiredFields,
  zip_code = "zip_code",
}) => {
  const countries = useSelector(selectCountries) || [];
  const [currentCountry, setCurrentCountry] = useState<string | null>(
    control._defaultValues.country
  );
  const [currentState, setCurrentState] = useState<string | null>(
    control._defaultValues.state
  );
  const states = useSelector(selectStates) || [];
  const cities = useSelector(selectCities) || [];
  const dispatch = useAppDispatch();

  const changeCountry = async (countryName: string) => {
    setCurrentCountry(countryName || null);
  };

  const changeState = async (stateName: string) => {
    setCurrentState(stateName || null);
  };
  useEffect(() => {
    const country = countries.find(
      (coun) => coun.countryName === currentCountry
    );
    if (country) {
      dispatch(getStates(country.geonameId));
    }
  }, [currentCountry, countries]);

  useEffect(() => {
    const state = states.find((state) => state.name === currentState);
    if (state) {
      dispatch(getCities(state.geonameId));
    }
  }, [currentState, states]);

  useEffect(() => {
    dispatch(getCountries());
  }, []);
  return (
    <>
      <Grid item xs={12}>
        <InputField
          name="street_address"
          label="Street address"
          withController
          control={control}
          required={requiredFields?.includes("street_address")}
        />
      </Grid>
      <Grid item xs={12}>
        <InputField
          name={street_address_name}
          label="Street address 2"
          withController
          control={control}
          required={requiredFields?.includes(street_address_name)}
        />
      </Grid>
      <Grid item xs={12}>
        <SelectField
          name="country"
          label="Country"
          placeholder="Select country"
          options={countries.map((country) => ({
            label: country.countryName,
            value: country.countryName,
          }))}
          onChange={(value) => changeCountry(value as string)}
          withControl
          control={control}
          required={requiredFields?.includes("country")}
        />
      </Grid>
      <Grid item xs={12}>
        <SelectField
          name="state"
          label="State"
          options={states.map((state) => ({
            label: state.name,
            value: state.name,
          }))}
          onChange={(value) => changeState(value as string)}
          withControl
          control={control}
          isDisabled={!currentCountry}
          required={requiredFields?.includes("state")}
        />
      </Grid>
      <Grid item xs={12}>
        <SelectField
          name="city"
          label="City"
          options={cities.map((city) => ({
            label: city.name,
            value: city.name,
          }))}
          withControl
          control={control}
          isDisabled={!currentCountry || !currentState}
          required={requiredFields?.includes("city")}
        />
      </Grid>
      {isNeedPostalCode && (
        <Grid item xs={12}>
          <InputField
            name={zip_code}
            label="Postal Code"
            withController
            control={control}
            required={requiredFields?.includes(zip_code)}
          />
        </Grid>
      )}
    </>
  );
};
