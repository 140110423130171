import React from "react";
import { IActionItem, IActionItemFields } from "Shared/Types/order";
import { useAppDispatch } from "Redux/store";
import {
  getActionItem,
  updateActionItemByField,
} from "Redux/ActionItem/reducer";
import { notifyError, notifySuccess } from "Components/Notify/notify";
import InfoOrChangeField from "Components/UI/InfoOrChangeField/InfoOrChangeField";

export const renderCell = (
  cellData: any,
  columnId: string,
  actionItem: IActionItem
) => {
  const dispatch = useAppDispatch();
  const onSuccess = () => {
    notifySuccess("Action item is Updated");
    dispatch(getActionItem(actionItem.id));
  };
  const onError = (error?: string) => {
    notifyError(error || "Someting went wrong");
  };
  const saveField = (
    field: IActionItemFields,
    value: string,
    withCallback?: boolean
  ) => {
    dispatch(
      updateActionItemByField({
        data: { field, value },
        id: actionItem.id,
        onSuccess: withCallback ? onSuccess : undefined,
        onError: withCallback ? onError : undefined,
      })
    );
  };

  const changeRate = (rate: string | null | number) => {
    saveField("rate", (rate || "").toString(), true);
    saveField("amount", (actionItem.invoice_hours * +(rate || 0)).toString());
  };
  if (columnId === "labor_rate") {
    return cellData?.labor_rate?.name;
  }

  if (columnId === "amount") {
    return (
      <InfoOrChangeField
        value={cellData}
        name="amount"
        onFieldChange={(text: string | null | number) =>
          saveField("amount", (text || "").toString(), true)
        }
        type="number"
      />
    );
  }
  if (columnId === "rate") {
    return (
      <InfoOrChangeField
        value={cellData}
        name="rate"
        onFieldChange={(text: string | null | number) => changeRate(text)}
        type="number"
      />
    );
  }
  return cellData;
};
