import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router";
import { useSelector } from "react-redux";
import { useAppDispatch } from "Redux/store";
import { notifySuccess } from "Components/Notify/notify";
import styles from "./Manufacturer.module.scss";
import { manufacturerSchema } from "Shared/Utils/part-settings";
import {
  removeCurrentManufacturer,
  selectCurrentManufacturer,
} from "Redux/PartSettings/Manufacturer/slice";
import { IManufacturerUpdate } from "Shared/Types/manufacturer";
import {
  getManufacturer,
  updateManufacturer,
} from "Redux/PartSettings/Manufacturer/reducer";
import ManufacturerForm from "Components/Settings/PartSettings/Manufacturer/ManufacturerForm";

const ManufactureUpdate = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const manufacture = useSelector(selectCurrentManufacturer);
  const dispatch = useAppDispatch();
  const editManufacture = (values: IManufacturerUpdate) => {
    const back = () => {
      navigate("/settings/part-settings/manufactures");
    };
    const onSuccess = () => {
      notifySuccess("Manufacture updated");
      back();
    };
    if (id) {
      dispatch(updateManufacturer({ id, data: values, onSuccess }));
    }
  };

  useEffect(() => {
    if (id) {
      dispatch(getManufacturer(id));
    }
    return () => {
      dispatch(removeCurrentManufacturer());
    };
  }, [id]);

  return (
    <div className={styles.manufacturer__form}>
      {manufacture && (
        <ManufacturerForm
          manufacturer={manufacture}
          onSubmit={editManufacture}
          schema={manufacturerSchema}
        />
      )}
    </div>
  );
};

export default ManufactureUpdate;
