import React, { useEffect, useState } from "react";
import { notifySuccess } from "Components/Notify/notify";
import DeleteModal from "Components/UI/DialogWindow/DeleteModal";
import { useAppDispatch } from "Redux/store";
import { useSelector } from "react-redux";
import ListLayout from "Components/Layout/ListLayout/ListLayout";
import Card from "Components/UI/Card/Card/Card";
import Row from "Components/UI/Card/Row/Row";
import {
  selectPurchaseOrderList,
  selectPurchaseOrderNumPage,
} from "Redux/PurchaseOrder/slice";
import { purchaseOrderFields } from "Shared/Constants/purchase-order";
import {
  deletePurchaseOrder,
  getPurchaseOrderList,
} from "Redux/PurchaseOrder/reducer";
import {
  getPurchaseOrderCardData,
  getPurchaseOrderCardStatus,
  getPurchaseOrderRoute,
} from "Shared/Utils/purchase-order";
import { IPurchaseOrder } from "Shared/Types/purchase-order";
import { useNavigate } from "react-router";

const PurchaseOrderList: React.FC = () => {
  const purchaseOrders = useSelector(selectPurchaseOrderList) || [];
  const numPage = useSelector(selectPurchaseOrderNumPage);
  const dispatch = useAppDispatch();
  const [showDelete, setShowDelete] = useState(false);
  const [deleteId, setDeleteId] = useState<string | null>();
  const [isCard, setIsCard] = useState(true);
  const [fields, setFields] = useState(purchaseOrderFields);
  const navigate = useNavigate();
  const handleDialogClose = () => {
    setShowDelete(false);
    setDeleteId(null);
  };

  const handleDialogOpen = (id: string) => {
    setShowDelete(true);
    setDeleteId(id);
  };

  const goto = (id: string) => {
    navigate(getPurchaseOrderRoute("UPDATE", id));
  };

  const handleDeletePurchaseOrder = async () => {
    const onSuccess = () => {
      notifySuccess("Purchase order is deleted");
      setShowDelete(false);
      dispatch(getPurchaseOrderList());
    };
    if (deleteId) {
      await dispatch(deletePurchaseOrder({ id: deleteId, onSuccess }));
    }
  };

  {
    const props = (purchaseOrder: IPurchaseOrder) => {
      return {
        openDeleteModal: handleDialogOpen,
        fields: fields,
        onClick: () => goto(purchaseOrder.id),
        cardData: getPurchaseOrderCardData(purchaseOrder),
        editClick: () => goto(purchaseOrder.id),
        status: getPurchaseOrderCardStatus(purchaseOrder.status),
        dataId: purchaseOrder.id,
        isNeedStatus: true,
      };
    };
    useEffect(() => {
      dispatch(getPurchaseOrderList());
    }, []);

    return (
      <ListLayout
        pagesNumber={numPage || 1}
        isCard={isCard}
        setIsCard={setIsCard}
        fields={fields}
        setFields={setFields}
        filters={["search", "status", "date"]}
        fieldAllList={purchaseOrderFields}
      >
        {isCard
          ? purchaseOrders.map((purchaseOrder) => (
              <Card key={purchaseOrder.id} {...props(purchaseOrder)} />
            ))
          : purchaseOrders.map((service) => (
              <Row key={service.id} {...props(service)} />
            ))}

        <DeleteModal
          open={showDelete}
          handleClose={handleDialogClose}
          title="Delete"
          text="Are you sure...?"
          acceptTitle="Delete"
          accept={handleDeletePurchaseOrder}
        />
      </ListLayout>
    );
  }
};
export default PurchaseOrderList;
