import React, { useEffect, useState } from "react";
import styles from "./LaborRate.module.scss";
import { useNavigate } from "react-router";
import Table from "Components/UI/Table/Table";
import { laborRateTableHead } from "Shared/Constants/settings";
import { renderCell } from "Components/Settings/LaborRate/RenderCell/renderCell";
import { useAppDispatch } from "Redux/store";
import { deleteLaborRate, getLaborRateList } from "Redux/LaborRate/reducer";
import { useSelector } from "react-redux";
import { selectLaborRateList } from "Redux/LaborRate/slice";
import DeleteModal from "Components/UI/DialogWindow/DeleteModal";
import { notifySuccess } from "Components/Notify/notify";
import IconButton from "Components/UI/IconButton/IconButton";
import { PlusIcon } from "Assets/index";

const LaborRate: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const laborRates = useSelector(selectLaborRateList) || [];
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [deleteId, setDeleteId] = useState<string | null>();

  const handleDialogClose = () => {
    setOpenDeleteModal(false);
    setDeleteId(null);
  };

  const handleDialogOpen = (id: string) => {
    setOpenDeleteModal(true);
    setDeleteId(id);
  };

  const goToCreateNavigate = () => {
    navigate("/settings/rate-settings/labor-rate/new");
  };

  const handleDeleteLaborRate = async () => {
    const onSuccess = () => {
      notifySuccess("Role is deleted");
      handleDialogClose();
      dispatch(getLaborRateList(null));
    };
    if (deleteId) {
      await dispatch(deleteLaborRate({ id: deleteId, onSuccess }));
    }
  };

  useEffect(() => {
    dispatch(getLaborRateList(null));
  }, []);

  return (
    <div className={styles.labor}>
      <div className={styles.labor__addBtn}>
        <IconButton
          onClick={goToCreateNavigate}
          label="Create"
          width={30}
          height={30}
          icon={PlusIcon}
        />
      </div>
      <Table
        title="Labor Rate"
        columns={laborRateTableHead}
        data={laborRates}
        renderCell={renderCell}
        openDelete={handleDialogOpen}
      />
      <DeleteModal
        acceptTitle="Delete"
        open={openDeleteModal}
        handleClose={handleDialogClose}
        title="Delete"
        text="Are you sure...?"
        accept={handleDeleteLaborRate}
      />
    </div>
  );
};
export default LaborRate;
