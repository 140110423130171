import React, { useEffect, useState } from "react";
import styles from "Containers/Settings/Rate/LaborRate/LaborRateList/LaborRate.module.scss";
import Table from "Components/UI/Table/Table";
import { supplyRateTableHead } from "Shared/Constants/settings";
import { renderCell } from "Components/Settings/SupplyRate/RenderCell/renderCell";
import { useNavigate } from "react-router";
import { useAppDispatch } from "Redux/store";
import { useSelector } from "react-redux";
import { selectSupplyRateList } from "Redux/SupplyRate/slice";
import { deleteSupplyRate, getSupplyRateList } from "Redux/SupplyRate/reducer";
import DeleteModal from "Components/UI/DialogWindow/DeleteModal";
import { notifySuccess } from "Components/Notify/notify";
import IconButton from "Components/UI/IconButton/IconButton";
import { PlusIcon } from "Assets/index";

const SupplyRateList = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const supplyRateList = useSelector(selectSupplyRateList) || [];
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [deleteId, setDeleteId] = useState<string | null>();

  const handleDialogClose = () => {
    setOpenDeleteModal(false);
    setDeleteId(null);
  };

  const handleDialogOpen = (id: string) => {
    setOpenDeleteModal(true);
    setDeleteId(id);
  };

  const goToCreateNavigate = () => {
    navigate("/settings/rate-settings/supply-rate/new");
  };

  const handleDeleteSupplyRate = async () => {
    const onSuccess = () => {
      notifySuccess("Supply rate deleted");
      handleDialogClose();
      dispatch(getSupplyRateList());
    };
    if (deleteId) {
      await dispatch(deleteSupplyRate({ id: deleteId, onSuccess }));
    }
  };

  useEffect(() => {
    dispatch(getSupplyRateList());
  }, []);

  return (
    <div className={styles.labor}>
      <div className={styles.labor__addBtn}>
        <IconButton
          onClick={goToCreateNavigate}
          label="Create"
          width={30}
          height={30}
          icon={PlusIcon}
        />
      </div>
      <Table
        title="Supply Rate"
        columns={supplyRateTableHead}
        data={supplyRateList}
        renderCell={renderCell}
        openDelete={handleDialogOpen}
      />
      <DeleteModal
        acceptTitle="Delete"
        open={openDeleteModal}
        handleClose={handleDialogClose}
        title="Delete"
        text="Are you sure...?"
        accept={handleDeleteSupplyRate}
      />
    </div>
  );
};

export default SupplyRateList;
