import React, { useEffect, useState } from "react";
import styles from "./Sidebar.module.scss";
import Icon from "Components/UI/Icon/Icon";
import cn from "classnames";
import { useLocation, useNavigate } from "react-router";
import { clear, read, write } from "Service/storage";
import Badge from "Components/UI/Badge/Badge";
import { useSelector } from "react-redux";
import { selectCompanyRequestReviewNotificationCount } from "Redux/CompanyRequests/slice";
import { getCompanyRequestsReviewList } from "Redux/CompanyRequests/reducers";
import { useAppDispatch } from "Redux/store";
import { selectAccount } from "Redux/Auth/slice";

type YourPermissionType = string;

type SubMenuItem = {
  icon: React.ElementType;
  title: string;
  isDropdown: boolean;
  withNotification: boolean;
  notificationsCount: number;
  url: string;
  permission: YourPermissionType[];
};

export type SidebarItemProps = {
  icon: React.ElementType;
  title: string;
  onClick: () => void;
  url: string;
  isAdmin?: boolean;
  isHavePermission?: boolean;
  subMenu?: SubMenuItem[];
  isHavePermissionFunc?: (pagePermissions: string[]) => boolean;
};

const SidebarItem: React.FC<SidebarItemProps> = ({
  icon,
  title,
  onClick,
  url,
  isAdmin = false,
  isHavePermission,
  subMenu,
  isHavePermissionFunc,
}) => {
  const { pathname, state } = useLocation();
  const navigate = useNavigate();
  const profile = useSelector(selectAccount);
  const [openSubItems, setOpenSubItems] = useState<boolean>(false);
  const is_sub_item_open = read("sub_menu_item");
  const dispatch = useAppDispatch();
  const requestCount = useSelector(selectCompanyRequestReviewNotificationCount);

  const toggleOpenSubItems = () => {
    if (pathname) write("sub_menu_item", "true");
    setOpenSubItems(true);
  };

  useEffect(() => {
    if (
      pathname.includes("shops") ||
      pathname.includes("services") ||
      pathname.includes("customers") ||
      pathname.includes("units") ||
      pathname.includes("vendors") ||
      pathname.includes("employees")
    ) {
      write("sub_menu_item", "true");
      setOpenSubItems(true);
    } else {
      clear("sub_menu_item");
    }
  }, []);

  const [isFirstRender, setIsFirstRender] = useState(true);

  useEffect(() => {
    setTimeout(() => setIsFirstRender(false), 500);
  }, [pathname]);

  const checkIsActive = () => {
    if (
      url === "/" ||
      url === "/returns" ||
      url === "/stats" ||
      url === "/profile" ||
      (url === "/orders" && state)
    ) {
      if (
        pathname === "/" ||
        pathname === "/returns" ||
        pathname === "/stats" ||
        pathname === "/profile" ||
        (pathname.includes("/orders") && state && title === "Home")
      )
        return true;
      return false;
    }

    if (url.startsWith("/settings")) {
      return pathname.includes("/settings");
    }
    return pathname.includes(url);
  };
  const active = checkIsActive();
  useEffect(() => {
    if (profile?.is_superuser) {
      dispatch(getCompanyRequestsReviewList({}));
    }
  }, [dispatch]);

  if (!isHavePermission) return <></>;
  return (
    <div
      className={cn(
        styles.sidebarItem,
        isAdmin && styles.sidebarItem__admin,
        active && styles.sidebarItem_active
      )}
    >
      {!subMenu ? (
        <div
          onClick={onClick}
          className={
            isFirstRender
              ? styles.sidebarItem__box_disable_hover
              : styles.sidebarItem__box
          }
        >
          <div className={cn(active && styles.icon_active, styles.icon)}>
            {isAdmin && url === "/requests" ? (
              <Badge messageLength={requestCount || 0} color={"error"}>
                <Icon component={icon} fontSize="medium" color="transparent" />
              </Badge>
            ) : (
              <Icon component={icon} fontSize="medium" color="transparent" />
            )}
          </div>
          <span className={styles.sidebarItem__title}>{title}</span>
        </div>
      ) : !is_sub_item_open && !openSubItems ? (
        <div
          onClick={() => toggleOpenSubItems()}
          className={
            isFirstRender
              ? styles.sidebarItem__box_disable_hover
              : styles.sidebarItem__box
          }
        >
          <div className={cn(active && styles.icon_active, styles.icon)}>
            <Icon component={icon} fontSize="medium" color="transparent" />
          </div>
          <span className={styles.sidebarItem__title}>{title}</span>
        </div>
      ) : (
        <div className={styles.sidebarItem__sub_box}>
          {subMenu?.map(({ title, icon, url, permission }) => (
            <>
              {isHavePermissionFunc?.(permission) && (
                <div
                  onClick={() => {
                    navigate(url);
                  }}
                  className={
                    pathname.includes(url)
                      ? styles.sidebarItem__sub_box__active_item
                      : styles.sidebarItem__sub_box__item
                  }
                  key={url}
                >
                  <div
                    className={cn(pathname.includes(url) && styles.icon_active)}
                  >
                    <Icon
                      component={icon}
                      fontSize="medium"
                      color="transparent"
                    />
                  </div>
                  <span className={styles.sidebarItem__title}>{title}</span>
                </div>
              )}
            </>
          ))}
        </div>
      )}
    </div>
  );
};
export default SidebarItem;
