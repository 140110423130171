import React, { useEffect } from "react";
import styles from "Components/Order/ServiceOrderForms/ServiceOrderAdd.module.scss";
import SelectField from "Components/UI/SelectField/SelectField";
import { UserSearch } from "Components/UI/AutoCompleteSearchInputs/UserSearch";
import { Control } from "react-hook-form";
import { IOrderAdd } from "Shared/Types/orderAdd";
import { useSelector } from "react-redux";
import { getUrgencyOptionList } from "Redux/UrgencyOption/reducer";
import { useAppDispatch } from "Redux/store";
import { selectUrgencyOptionList } from "Redux/UrgencyOption/slice";
import { Link } from "react-router-dom";
import { clear } from "Service/storage";
import Icon from "Components/UI/Icon/Icon";
import { CloseIcon } from "Assets/index";
import { selectAccount } from "Redux/Auth/slice";
import { access_unit, deliver_unit } from "Shared/Utils/orderAdd";

type AccessStatusInfoProps = {
  control: Control<IOrderAdd>;
  saveOrderValues: () => void;
};

const AccessStatusInfo: React.FC<AccessStatusInfoProps> = ({
  control,
  saveOrderValues,
}) => {
  const dispatch = useAppDispatch();
  const urgencyOptions = useSelector(selectUrgencyOptionList);
  const profile = useSelector(selectAccount);

  const urgencyOptionList = urgencyOptions.map((option) => ({
    label: option.title,
    value: option.id,
  }));

  useEffect(() => {
    if (profile?.company?.id) {
      dispatch(getUrgencyOptionList({ company: profile?.company?.id }));
    }
  }, [profile]);

  return (
    <div className={styles.form_section}>
      <div className={styles.form_section__title}>
        <p className={styles.title}>Access & Status</p>
        <Link onClick={() => clear("created_service_order")} to="/orders">
          <Icon component={CloseIcon} />
        </Link>
      </div>
      <SelectField
        theme="primary"
        control={control}
        withControl
        options={access_unit}
        name="access_unit"
        label="How will we access the unit?"
      />
      <UserSearch
        name="lead_technician"
        label="Who will be the lead technician"
        isAdd
        beforeCreateNew={saveOrderValues}
      />
      <SelectField
        theme="primary"
        withControl
        control={control}
        options={urgencyOptionList}
        name="time_status"
        label="Status"
      />
      <SelectField
        theme="primary"
        withControl
        control={control}
        options={deliver_unit}
        name="deliver_unit"
        label="Will we deliver the unit"
      />
    </div>
  );
};

export default AccessStatusInfo;
