import React, { useEffect, useState } from "react";
import styles from "./ServiceOrderAdd.module.scss";
import { PMUserIcon, ProtractorIcon } from "Assets/index";
import InputField from "Components/UI/Inputs/InputField";
import { UnitSearch } from "Components/UI/AutoCompleteSearchInputs/UnitSearch";
import OverduePM from "Components/Order/ServiceOrderForms/OverduePM";
import { Control, useFormContext } from "react-hook-form";
import { IActionItemAdd, IOrderAdd } from "Shared/Types/orderAdd";
import { useSelector } from "react-redux";
import { selectCurrentUnit } from "Redux/Unit/slice";
import { IService } from "Shared/Types/service";
import { IUnit } from "Shared/Types/unit";
import CustomerInfo from "Components/Order/ServiceOrderForms/CustomerInfo";
import Button from "Components/UI/Button/Button";
import Form from "Components/UI/Form/Form";
import { readObj, write } from "Service/storage";

type UnitInfoProps = {
  control: Control<IOrderAdd>;
  actionItemList: IService[];
  servicesList: IService[];
  setActionItemList: (e: IService[]) => void;
  setServicesList: (e: IService[]) => void;
  isFormReset: boolean;
  saveOrderValues: () => void;
  fields: IActionItemAdd[];
  append: (e: IActionItemAdd) => void;
  remove: (index: number) => void;
  setFormStep: (step: number) => void;
};

const UnitInfo: React.FC<UnitInfoProps> = ({
  control,
  actionItemList,
  servicesList,
  setActionItemList,
  setServicesList,
  saveOrderValues,
  fields,
  append,
  remove,
  setFormStep,
}) => {
  const unit = useSelector(selectCurrentUnit);
  const localSOValues = readObj("created_service_order");
  const [service_count, setServiceCount] = useState(0);
  const { setValue } = useFormContext();

  useEffect(() => {
    if (unit && Array.isArray(unit.service)) {
      setServiceCount(unit.service.length);
    } else if (
      localSOValues?.unit &&
      Array.isArray(localSOValues?.unit.service)
    ) {
      setServiceCount(localSOValues?.unit.service.length);
    } else {
      setServiceCount(0);
    }
  }, [unit]);
  const resetFields = () => {
    if (fields) {
      for (let index = fields.length - 1; index >= 0; index--) {
        remove(index);
      }
    }
  };

  useEffect(() => {
    if (unit && Array.isArray(unit.service)) {
      setServiceCount(unit.service.length);
    } else if (
      localSOValues?.unit &&
      Array.isArray(localSOValues?.unit.service)
    ) {
      setServiceCount(localSOValues?.unit.service.length);
    } else {
      setServiceCount(0);
    }

    if (localSOValues?.unit) {
      setValue("unit", localSOValues.unit);
      setValue("vin", localSOValues.unit.vin);
      setValue("pm", localSOValues.unit.length);
    }

    if (localSOValues?.action_items && fields.length === 0) {
      const arr: any[] = [];
      for (let index = 0; index < localSOValues?.action_items.length; index++) {
        const item = localSOValues?.action_items[index];
        const cur = fields.find((field) => field.index === item.index);
        const cur2 = arr.find((field) => field.index === item.index);
        if (!cur && !cur2) {
          arr.push(item);
          append(item);
        }
      }
    }

    if (localSOValues?.chassis_miles) {
      setValue("chassis_miles", localSOValues.chassis_miles);
    }
  }, []);

  const onChangeUnit = (unit?: IUnit | null) => {
    setValue("vin", unit?.vin || "");
    setServiceCount(0);
    resetFields();
  };

  return (
    <Form
      onSubmit={(e) => {
        e.preventDefault();
        saveOrderValues();
        if (unit) setValue("unit", unit);
        setFormStep(2);
        write("formStep", "2");
      }}
      className={styles.form_section}
    >
      <CustomerInfo saveOrderValues={saveOrderValues} />
      <p className={styles.title}>Unit</p>
      <div style={{ gap: "16px" }} className={styles.form_section__inputs}>
        <UnitSearch
          onChange={onChangeUnit}
          name="unit"
          required
          label="Which unit needs service?"
          beforeCreateNew={saveOrderValues}
          isAdd
        />
        <InputField
          withController
          control={control}
          disabled
          name="vin"
          label="VIN"
        />
        <InputField
          withController
          control={control}
          icon={ProtractorIcon}
          type="number"
          IconBg="#EE3529"
          name="chassis_miles"
          label="Chassis (Miles)"
        />
        {service_count ? (
          <InputField
            control={control}
            icon={PMUserIcon}
            IconBg="#007DF2"
            name="pm"
            disabled
            value={`${service_count} PM`}
            label="Preventive Maintenance"
          />
        ) : null}
        {service_count ? (
          <OverduePM
            actionItemList={actionItemList}
            servicesList={servicesList}
            setActionItemList={setActionItemList}
            setServicesList={setServicesList}
            remove={remove}
            append={append}
            control={control}
            services={unit?.service || localSOValues?.unit.service}
          />
        ) : null}
      </div>

      <div style={{ float: "right" }} className={styles.prev_next_buttons}>
        <Button type="submit">Next</Button>
      </div>
    </Form>
  );
};

export default UnitInfo;
