import IconButton from "Components/UI/IconButton/IconButton";
import React, { MouseEvent } from "react";
import { EditIcon, DeleteIcon } from "Assets/index";
import { useNavigate } from "react-router";
import { IUnitType } from "Shared/Types/unit-type";
import styles from "../../Settings/LaborRate/RenderCell/renderCell.module.scss";

export const renderCell = (
  cellData: any,
  columnId: string,
  unitType: IUnitType,
  openDelete?: (id: string) => void
) => {
  const navigate = useNavigate();
  const handleClick = (event: MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
    navigate(`/settings/unit-settings/unit-types/${unitType.id}`);
  };

  const handleDelete = (event: MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
    openDelete?.(unitType.id);
  };
  if (columnId === "action") {
    return (
      <div className={styles.buttons}>
        <IconButton icon={EditIcon} onClick={handleClick} />
        <IconButton icon={DeleteIcon} onClick={handleDelete} />
      </div>
    );
  }
  return cellData;
};
