import React from "react";
import styles from "./OrderStatusHistory.module.scss";
import cn from "classnames";
import { Checkbox } from "@mui/material";

export type OrderStatusHistoryProps = {
  title: string;
  status: number;
  orderStatus: number;
};

const OrderStatusHistory: React.FC<OrderStatusHistoryProps> = ({
  status,
  title,
  orderStatus,
}) => {
  let styleStatus = "feature";
  if (orderStatus > status) {
    styleStatus = "approved";
  } else if (orderStatus === status) {
    styleStatus = "current";
  }

  return (
    <div
      className={cn(
        styles.card,
        styleStatus === "approved" && styles.card_approved,
        styleStatus === "current" && styles.card_current
      )}
    >
      <Checkbox
        sx={{
          color: "#C9C9C9",
          "&.Mui-checked": {
            color: "#005DAC80, #005DAC33",
          },
        }}
        checked={orderStatus > status}
      />{" "}
      <h4>{title}</h4>
    </div>
  );
};
export default OrderStatusHistory;
