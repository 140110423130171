import React, { useEffect, useState } from "react";
import styles from "./GroupItem.module.scss";
import { IPermissionItem } from "Shared/Types/role";
import Checkbox from "Components/UI/Inputs/Checkbox";
import { ArrowDownIcon, CloseIcon } from "Assets/index";
import Icon from "Components/UI/Icon/Icon";
import PermissionItem from "../PermissionItem/PermissionItem";

type GroupItemProps = {
  group: IPermissionItem;
  changeRoles: (per: string[], isAdd: boolean) => void;
  curPermissions: Set<string>;
  changed: boolean;
  setChanged: (value: boolean) => void;
};

const GroupItem: React.FC<GroupItemProps> = ({
  group,
  changeRoles,
  curPermissions,
  changed,
  setChanged,
}) => {
  const [open, setOpen] = useState(false);

  const [value, setValue] = useState(
    group.list.every((per) => curPermissions.has(per))
  );
  const changeRole = (checked: boolean) => {
    setChanged(!changed);
    setValue(checked);
    changeRoles(group.list, checked);
  };

  useEffect(() => {
    setValue(group.list.every((per) => curPermissions.has(per)));
  }, [changed]);
  return (
    <div className={styles.group} key={group.perTitle}>
      <div className={styles.group__head} onClick={() => setOpen(true)}>
        <div className={styles.group__head__title}>
          <Checkbox
            name="permission"
            checked={value}
            onClick={(event) => event.stopPropagation()}
            onChange={(_, checked) => changeRole(checked)}
          />
          <p>{group.perTitle}</p>
        </div>
        {open ? (
          <Icon
            component={CloseIcon}
            onClick={(event) => {
              event.stopPropagation();
              setOpen(false);
            }}
          />
        ) : (
          <Icon component={ArrowDownIcon} />
        )}
      </div>
      {open &&
        group.list.map((permission) => (
          <PermissionItem
            permission={permission}
            key={permission}
            changeRoles={changeRoles}
            curPermissions={curPermissions}
            setChanged={setChanged}
            changed={changed}
          />
        ))}
    </div>
  );
};
export default GroupItem;
