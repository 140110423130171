import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "Service/api/api";
import { readObj } from "Service/storage";
import {
  IDispatchCallback,
  IDispatchCallbackWithId,
  IDispatchCallbackWithoutData,
} from "Shared/Types/common";
import {
  ILocationSearchParams,
  ILocationAdd,
  ILocationUpdate,
} from "Shared/Types/location";
import { errorHandler } from "Shared/Utils/app";

const getLocationList = createAsyncThunk(
  "location/getLocationList",
  async (params: ILocationSearchParams | null) => {
    try {
      const shop = readObj("current_shop");
      const response = await api.location.getLocationsList({
        ...params,
        shop: shop?.id,
      });
      return response.data;
    } catch (error) {
      if (error instanceof Error) {
        errorHandler(error);
      }
      throw error;
    }
  }
);

const getLocation = createAsyncThunk(
  "location/getLocation",
  async (id: string) => {
    try {
      const shop = readObj("current_shop");
      const response = await api.location.getLocation(id, {
        shop: shop?.id,
      });

      return response.data;
    } catch (error) {
      if (error instanceof Error) {
        errorHandler(error);
      }
      throw error;
    }
  }
);

const addLocation = createAsyncThunk(
  "location/addLocation",
  async ({ data, onSuccess }: IDispatchCallback<ILocationAdd>) => {
    try {
      const response = await api.location.addLocation(data);
      onSuccess?.();
      return response.data;
    } catch (error) {
      if (error instanceof Error) {
        errorHandler(error, true);
      }
      throw error;
    }
  }
);

const updateLocation = createAsyncThunk(
  "location/updateLocation",
  async ({ id, data, onSuccess }: IDispatchCallbackWithId<ILocationUpdate>) => {
    try {
      const shop = readObj("current_shop");
      const response = await api.location.updateLocation(id, {
        ...data,
        shop: shop?.id,
      });
      onSuccess?.();
      return response.data;
    } catch (error) {
      if (error instanceof Error) {
        errorHandler(error, true);
      }
      throw error;
    }
  }
);

const deleteLocation = createAsyncThunk(
  "location/deleteLocation",
  async ({ id, onSuccess }: IDispatchCallbackWithoutData) => {
    try {
      const shop = readObj("current_shop");
      const response = await api.location.deleteLocation(id, {
        shop: shop?.id,
      });
      onSuccess?.();
      return response.data;
    } catch (error) {
      if (error instanceof Error) {
        errorHandler(error, true);
      }
      throw error;
    }
  }
);

export {
  getLocationList,
  getLocation,
  addLocation,
  updateLocation,
  deleteLocation,
};
