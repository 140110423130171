import React, { useState } from "react";
import { InputAdornment, TextField, TextFieldProps } from "@mui/material";
import cn from "classnames";
import styles from "./Inputs.module.scss";
import Icon from "../../UI/Icon/Icon";
import { Control, Controller } from "react-hook-form";
import { EyeClosedIcon, EyeOpenIcon } from "Assets/index";

export type InputFieldProps = Omit<TextFieldProps, "name"> & {
  icon?: React.ElementType;
  withController?: boolean;
  // TODO: remove any type
  control?: Control<any>;
  name: string;
  error?: string;
  placeholder?: string;
  isPassword?: boolean;
};

const InputField: React.FC<InputFieldProps> = ({
  required,
  icon,
  withController = false,
  control,
  name,
  className,
  defaultValue = "",
  error = null,
  placeholder,
  isPassword,
  ...props
}) => {
  const [showPassword, setShowPassword] = useState<boolean>(false);

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className={styles.inputs}>
      {withController ? (
        <Controller
          control={control}
          name={name}
          defaultValue={defaultValue}
          render={({ field, fieldState }) => (
            <>
              <TextField
                variant="standard"
                placeholder={placeholder}
                {...field}
                onChange={(event) => {
                  field.onChange(event);
                  props.onChange?.(event);
                }}
                type={
                  isPassword ? (showPassword ? "text" : "password") : "text"
                }
                sx={{
                  ".MuiOutlinedInput-notchedOutline": {
                    borderStyle: "none",
                  },
                }}
                value={field.value || ""}
                classes={{ root: cn(styles.inputs__input, className) }}
                required={required}
                fullWidth
                {...props}
                InputProps={{
                  ...(icon
                    ? {
                        startAdornment: (
                          <InputAdornment position="start">
                            <Icon component={icon} color="transparent" />
                          </InputAdornment>
                        ),
                      }
                    : {}),
                  ...(isPassword
                    ? {
                        endAdornment: (
                          <InputAdornment position="end">
                            {showPassword ? (
                              <Icon
                                onClick={toggleShowPassword}
                                component={EyeOpenIcon}
                                color="transparent"
                              />
                            ) : (
                              <Icon
                                onClick={toggleShowPassword}
                                component={EyeClosedIcon}
                                color="transparent"
                              />
                            )}
                          </InputAdornment>
                        ),
                      }
                    : {}),
                  disableUnderline: true,
                }}
              />
              {fieldState.error && (
                <div className={styles.inputs__error}>
                  {fieldState.error.message}
                </div>
              )}
            </>
          )}
        />
      ) : (
        <>
          <TextField
            variant="standard"
            placeholder={placeholder}
            classes={{ root: cn(styles.inputs__input, className) }}
            required={required}
            type={isPassword ? (showPassword ? "text" : "password") : "text"}
            sx={{
              ".MuiOutlinedInput-notchedOutline": {
                borderStyle: "none",
              },
            }}
            {...props}
            InputProps={{
              ...(icon
                ? {
                    startAdornment: (
                      <InputAdornment position="start">
                        <Icon component={icon} color="transparent" />
                      </InputAdornment>
                    ),
                  }
                : {}),
              ...(isPassword
                ? {
                    endAdornment: (
                      <InputAdornment position="end">
                        {showPassword ? (
                          <Icon
                            onClick={toggleShowPassword}
                            component={EyeOpenIcon}
                            color="transparent"
                          />
                        ) : (
                          <Icon
                            onClick={toggleShowPassword}
                            component={EyeClosedIcon}
                            color="transparent"
                          />
                        )}
                      </InputAdornment>
                    ),
                  }
                : {}),
              disableUnderline: true,
            }}
          />
          {error && <div className={styles.inputs__error}>{error}</div>}
        </>
      )}
    </div>
  );
};
export default InputField;
