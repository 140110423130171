import React, { useEffect, useState } from "react";
import { notifySuccess } from "Components/Notify/notify";
import DeleteModal from "Components/UI/DialogWindow/DeleteModal";
import { useAppDispatch } from "Redux/store";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import ListLayout from "Components/Layout/ListLayout/ListLayout";
import { useSearchParams } from "react-router-dom";
import { selectContactList, selectContactNumPage } from "Redux/Contact/slice";
import { contactFields } from "Shared/Constants/contacts";
import { deleteContact, getContactList } from "Redux/Contact/reducer";
import { getContactCardData } from "Shared/Utils/contact";
import { IContact } from "Shared/Types/contact";
import Card from "Components/UI/Card/Card/Card";
import Row from "Components/UI/Card/Row/Row";

const ContactList: React.FC = () => {
  const contacts = useSelector(selectContactList) || [];
  const numPage = useSelector(selectContactNumPage);
  const dispatch = useAppDispatch();
  const [showDelete, setShowDelete] = useState(false);
  const [deleteId, setDeleteId] = useState<string | null>();
  const [isCard, setIsCard] = useState(true);
  const [fields, setFields] = useState(contactFields);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const handleDialogClose = () => {
    setShowDelete(false);
    setDeleteId(null);
  };

  const handleDialogOpen = (id: string) => {
    setShowDelete(true);
    setDeleteId(id);
  };

  const handleDeleteContact = async () => {
    const onSuccess = () => {
      notifySuccess("Service deleted");
      setShowDelete(false);
      dispatch(getContactList(null));
    };
    if (deleteId) {
      await dispatch(deleteContact({ id: deleteId, onSuccess }));
    }
  };

  const goToDetailPage = (id: string) => {
    navigate(`/customers/contacts/${id}`);
  };

  const goToEditPage = (id: string) => {
    navigate(`/customers/contacts/edit/${id}`);
  };
  const props = (contact: IContact) => {
    return {
      openDeleteModal: handleDialogOpen,
      fields: fields,
      onClick: goToDetailPage,
      cardData: getContactCardData(contact),
      editClick: goToEditPage,
      dataId: contact.id,
      status: {
        title: contact.customer.company_name,
        color: "",
      },
      isNeedStatus: true,
    };
  };

  useEffect(() => {
    dispatch(
      getContactList({
        search: searchParams.get("search"),
        customer: searchParams.get("customer"),
        page: +(searchParams.get("page") || 1),
      })
    );
  }, [searchParams]);

  return (
    <ListLayout
      pagesNumber={numPage || 1}
      isCard={isCard}
      setIsCard={setIsCard}
      fields={fields}
      setFields={setFields}
      filters={["search", "customer"]}
      fieldAllList={contactFields}
    >
      {isCard
        ? contacts.map((contact) => (
            <Card key={contact.id} {...props(contact)} />
          ))
        : contacts.map((contact) => (
            <Row key={contact.id} {...props(contact)} />
          ))}
      <DeleteModal
        open={showDelete}
        handleClose={handleDialogClose}
        title="Delete"
        text="Are you sure...?"
        acceptTitle="Delete"
        accept={handleDeleteContact}
      />
    </ListLayout>
  );
};
export default ContactList;
