import React, { useState } from "react";
import Modal from "Components/UI/Modal/Modal";
import styles from "./Attachments.module.scss";
import Icon from "Components/UI/Icon/Icon";
import {
  CameraIcon,
  DeleteIcon,
  DocumentIcon,
  PaperCLipIcon,
  PhotoIcon,
} from "Assets/index";
import Checkbox from "Components/UI/Inputs/Checkbox";
import Button from "Components/UI/Button/Button";
import {
  IActionItemFile,
  IActionItemImage,
  IOrderFile,
  IOrderImage,
} from "Shared/Types/order";
import { extractSubstring } from "Shared/Utils/extractCertainStringPart";
import CameraAndVideo from "Components/Order/ServiceOrderForms/Attachments/CameraAndVideo";

interface AttachmentsProps {
  onUpload: (files?: File[], images?: File[]) => void;
  onDeleteFiles: (deletingDocs?: string[], deletingImages?: string[]) => void;
  images?: IActionItemImage[] | IOrderImage[];
  documents?: IActionItemFile[] | IOrderFile[];
  selectedImages: File[] | [];
  selectedDocs: File[] | [];
  setSelectedImages: (files: File[]) => void;
  setSelectedDocs: (files: File[]) => void;
  deletingImages: string[];
  deletingDocs: string[];
  setDeletingDocs: (number: string[]) => void;
  setDeletingImages: (number: string[]) => void;
}

const Attachments: React.FC<AttachmentsProps> = ({
  onUpload,
  onDeleteFiles,
  images = [],
  documents = [],
  setSelectedImages,
  setSelectedDocs,
  selectedDocs,
  selectedImages,
  setDeletingImages,
  deletingImages,
  setDeletingDocs,
  deletingDocs,
}) => {
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [openCameraModal, setOpenCameraModal] = useState(false);

  const handleCameraModal = () => setOpenCameraModal(!openCameraModal);

  const onChooseDeleteDocs = (id: string) => {
    if (deletingDocs.includes(id)) {
      const index = deletingDocs.findIndex((el) => el === id);
      const updatedDeletingDocs = [...deletingDocs];
      updatedDeletingDocs.splice(index, 1);
      setDeletingDocs(updatedDeletingDocs);
    } else {
      setDeletingDocs([...deletingDocs, id]);
    }
  };

  const onChooseDeleteImages = (id: string) => {
    if (deletingImages.includes(id)) {
      const index = deletingImages.findIndex((el) => el === id);
      const updatedDeletingImages = [...deletingImages];
      updatedDeletingImages.splice(index, 1);
      setDeletingImages(updatedDeletingImages);
    } else {
      setDeletingImages([...deletingImages, id]);
    }
  };

  const onSelectImages = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files: File[] = Array.from(e.target.files || []);
    setSelectedImages([...selectedImages, ...files]);
  };

  const handleRemoveImageInput = (index: number) => {
    const updatedImages = [...selectedImages];
    updatedImages.splice(index, 1);
    setSelectedImages(updatedImages);
  };

  const onSelectDocs = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files: File[] = Array.from(e.target.files || []);
    setSelectedDocs([...selectedDocs, ...files]);
  };

  const handleRemoveDocInput = (index: number) => {
    const updatedDocs = [...selectedDocs];
    updatedDocs.splice(index, 1);
    setSelectedDocs(updatedDocs);
  };

  const attachments = [
    {
      id: 1,
      icon: PhotoIcon,
      name: "order_gallery",
      onChange: onSelectImages,
      accept: "image/png,image/jpg,image/jpeg, video/mp4",
      title: "Choose a Photo",
    },
    {
      id: 2,
      icon: DocumentIcon,
      name: "order_file",
      onChange: onSelectDocs,
      accept: ".pdf,.doc,.docx,.xlsx",
      title: "Choose a File",
    },
  ];

  const toggleModal = () => {
    setOpenModal(!openModal);
    setDeletingImages([]);
    setDeletingDocs([]);
    setSelectedDocs([]);
    setSelectedImages([]);
  };

  const handleUploadFiles = () => {
    onUpload(selectedDocs, selectedImages);
  };

  const handleDeleteChosenDocs = () => {
    onDeleteFiles(deletingDocs, deletingImages);
  };

  const checkIsImage = (url: string) => {
    return (
      url.includes(".png") || url.includes(".jpg") || url.includes(".jpeg")
    );
  };

  return (
    <div>
      <div
        onClick={toggleModal}
        style={{ padding: "8px 9.06px 5.67px" }}
        className={styles.icon}
      >
        <Icon
          color="transparent"
          component={PaperCLipIcon}
          width={18}
          height={18}
        />
      </div>
      <Modal
        isClosed
        open={openModal}
        onClose={toggleModal}
        title={
          documents.length > 0 || images.length > 0
            ? "Attachments"
            : "Add Attachments"
        }
      >
        <div className={styles.attachments}>
          {images.length > 0 && (
            <div className={styles.images}>
              {images?.map((item) => (
                <div key={Math.random()} className={styles.images__img}>
                  <Checkbox
                    className={styles.checkbox}
                    checked={deletingImages.includes(item.id)}
                    onChange={() => onChooseDeleteImages(item.id)}
                    name="sdfdd"
                  />
                  {checkIsImage(item.gallery as string) ? (
                    <img className={styles.img} src={item.gallery} alt="/" />
                  ) : (
                    <video className={styles.img} src={item.gallery} />
                  )}
                </div>
              ))}
            </div>
          )}

          {documents.length > 0 && (
            <div>
              {documents?.map((item) => (
                <div className={styles.attachments__docs} key={Math.random()}>
                  <Checkbox
                    checked={deletingDocs.includes(item.id)}
                    onChange={() => onChooseDeleteDocs(item.id)}
                    name="sdfdd"
                  />
                  <p>{extractSubstring(item.file as string)}</p>
                </div>
              ))}
            </div>
          )}
          {(deletingDocs.length > 0 || deletingImages.length > 0) && (
            <div
              onClick={handleDeleteChosenDocs}
              className={styles.button_cont}
            >
              <Button variant="contained">Delete</Button>
            </div>
          )}
        </div>

        {(documents.length > 0 || images.length > 0) && (
          <p className={styles.title}>Add attachments</p>
        )}

        <div className={styles.attachments}>
          {selectedImages.length > 0 && (
            <div className={styles.images}>
              {selectedImages?.map((file, index) => (
                <div key={Math.random()} className={styles.images__img}>
                  <Icon
                    onClick={() => handleRemoveImageInput(index)}
                    className={styles.delete_icon}
                    component={DeleteIcon}
                  />
                  {file.type.startsWith("image/") ? (
                    <img
                      className={styles.img}
                      src={URL.createObjectURL(file)}
                      alt="/"
                    />
                  ) : (
                    <video
                      className={styles.img}
                      src={URL.createObjectURL(file)}
                    />
                  )}
                </div>
              ))}
            </div>
          )}
          {selectedDocs.length > 0 && (
            <ol className={styles.docs}>
              {selectedDocs?.map((doc, index) => (
                <div className={styles.docs__doc} key={Math.random()}>
                  <li>{doc.name}</li>
                  <Icon
                    onClick={() => handleRemoveDocInput(index)}
                    component={DeleteIcon}
                  />
                </div>
              ))}
            </ol>
          )}
        </div>
        <p style={{ color: "#959595" }}>
          You may upload IMG or PDF (max size is 5 MB)
        </p>

        <div className={styles.attachments}>
          <div className={styles.attachments__attachment}>
            <div style={{ gap: "7px" }} className={styles.flex}>
              <button onClick={handleCameraModal} className={styles.icon}>
                <Icon
                  style={{ fontSize: "24px", color: "transparent" }}
                  color="transparent"
                  component={CameraIcon}
                />
              </button>
              <p style={{ marginTop: "7px" }}>Take a picture</p>
            </div>
            <Checkbox label="For Customer" name="mmkk" labelPosition="right" />
          </div>
          {attachments.map((attachment) => (
            <div key={attachment.id} className={styles.attachments__attachment}>
              <div style={{ gap: "7px" }} className={styles.flex}>
                <label
                  htmlFor={attachment.name}
                  className={styles.icon}
                  style={{ position: "relative" }}
                >
                  <Icon
                    style={{ fontSize: "24px", color: "transparent" }}
                    color="transparent"
                    component={attachment.icon}
                  />
                  <input
                    accept={attachment.accept}
                    multiple
                    onChange={attachment.onChange}
                    id={attachment.name}
                    type="file"
                    style={{ visibility: "hidden", position: "absolute" }}
                  />
                </label>
                <p style={{ marginTop: "7px" }}>{attachment.title}</p>
              </div>
              <Checkbox
                label="For Customer"
                name={attachment.accept}
                labelPosition="right"
              />
            </div>
          ))}
          <div className={styles.button_cont} onClick={handleUploadFiles}>
            <Button variant="contained">Upload</Button>
          </div>
        </div>
      </Modal>
      <CameraAndVideo
        selectedImages={selectedImages}
        setSelectedImages={setSelectedImages}
        openModal={openCameraModal}
        handleModal={handleCameraModal}
      />
    </div>
  );
};

export default Attachments;
