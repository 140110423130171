import React, { useEffect, useState } from "react";
import styles from "./UnitTypeList.module.scss";
import { useSelector } from "react-redux";
import { useAppDispatch } from "Redux/store";
import Table from "Components/UI/Table/Table";
import DeleteModal from "Components/UI/DialogWindow/DeleteModal";
import { useNavigate } from "react-router";
import { notifySuccess } from "Components/Notify/notify";
import { selectUnitTypeList } from "Redux/UnitType/slice";
import { unitTypeTableHeads } from "Shared/Constants/unit-types";
import { deleteUnitType, getUnitTypeList } from "Redux/UnitType/reducer";
import { renderCell } from "Components/UnitType/renderCell/rendelCell";
import IconButton from "Components/UI/IconButton/IconButton";
import { PlusIcon } from "Assets/index";

const UnitTypeList: React.FC = () => {
  const unitTypes = useSelector(selectUnitTypeList) || [];
  const dispatch = useAppDispatch();
  const [showDelete, setShowDelete] = useState(false);
  const [deleteId, setDeleteId] = useState<string | null>();
  const navigate = useNavigate();
  const handleDialogClose = () => {
    setShowDelete(false);
    setDeleteId(null);
  };

  const handleDialogOpen = (id: string) => {
    setShowDelete(true);
    setDeleteId(id);
  };

  const handleDeleteUser = async () => {
    const onSuccess = () => {
      notifySuccess("Unit deleted");
      setShowDelete(false);
      dispatch(getUnitTypeList(null));
    };
    if (deleteId) {
      await dispatch(deleteUnitType({ id: deleteId, onSuccess }));
    }
  };

  const goToCreateUnitType = () => {
    navigate("/settings/unit-settings/unit-types/new");
  };

  useEffect(() => {
    dispatch(getUnitTypeList(null));
  }, []);

  return (
    <div className={styles.unit}>
      <div className={styles.unit__addBtn}>
        <IconButton
          onClick={goToCreateUnitType}
          label="Create"
          width={30}
          height={30}
          icon={PlusIcon}
        />
      </div>
      <Table
        title="Unit Types"
        columns={unitTypeTableHeads}
        data={unitTypes}
        renderCell={renderCell}
        openDelete={handleDialogOpen}
      />

      <DeleteModal
        open={showDelete}
        handleClose={handleDialogClose}
        title="Delete"
        text="Are you sure...?"
        acceptTitle="Delete"
        accept={handleDeleteUser}
      />
    </div>
  );
};
export default UnitTypeList;
