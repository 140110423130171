import React from "react";
import { ButtonProps, Button as MUIButton } from "@mui/material";
import cn from "classnames";
import styles from "./Button.module.scss";
import { BeatLoader } from "react-spinners";
export interface IButton extends Omit<ButtonProps, "variant" | "color"> {
  variant?: "contained" | "outlined" | "text";
  color?: "primary" | "primary-light" | "primary-dark" | "secondary" | "white";
  loading?: boolean;
}

const Button: React.FC<IButton> = ({
  variant = "contained",
  color = "primary",
  className,
  loading,
  ...props
}) => {
  const buttonClasses = {
    root: cn(styles.button, styles[`button__${variant}__${color}`], className),
  };

  return (
    <MUIButton
      sx={{ textTransform: "none", borderRadius: 0 }}
      variant={variant}
      classes={buttonClasses}
      {...props}
    >
      {loading ? (
        <BeatLoader
          color="white"
          loading={loading}
          cssOverride={{ margin: "auto", padding: "4px 0" }}
          size={10}
          aria-label="Loading Spinner"
          data-testid="loader"
        />
      ) : (
        props.children
      )}
    </MUIButton>
  );
};

export default Button;
