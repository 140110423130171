import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { CustomerExtraError, ICustomer } from "Shared/Types/customer";
import {
  addCustomer,
  deleteCustomer,
  getCustomer,
  getCustomerList,
  updateCustomer,
  changeStatus,
} from "./reducer";

interface CustomerState {
  customerList?: ICustomer[];
  currentCustomer?: ICustomer | null;
  error?: string | null;
  extraErrorInfo?: CustomerExtraError | null;
  loading: boolean;
  success?: boolean;
}

const initialState: CustomerState = {
  currentCustomer: null,
  loading: false,
  success: false,
  extraErrorInfo: null,
};

const customerSlice = createSlice({
  name: "customer",
  initialState,
  reducers: {
    clear(state) {
      state.loading = false;
      state.error = null;
      state.success = false;
      state.extraErrorInfo = null;
    },
    setError(state, action) {
      state.extraErrorInfo = action.payload;
    },
    removeCurrentCustomer(state) {
      state.currentCustomer = null;
    },
  },
  extraReducers(builder) {
    builder.addCase(getCustomer.pending, (state) => {
      state.loading = true;
      state.currentCustomer = null;
    }),
      builder.addCase(getCustomer.rejected, (state) => {
        state.loading = false;
        state.currentCustomer = null;
      }),
      builder.addCase(getCustomer.fulfilled, (state, action) => {
        state.currentCustomer = action.payload;
        state.loading = false;
      });
    builder.addCase(addCustomer.pending, (state) => {
      state.loading = true;
    }),
      builder.addCase(addCustomer.rejected, (state) => {
        state.loading = false;
      }),
      builder.addCase(addCustomer.fulfilled, (state) => {
        state.loading = false;
      });
    builder.addCase(updateCustomer.pending, (state) => {
      state.loading = true;
    }),
      builder.addCase(updateCustomer.rejected, (state) => {
        state.loading = false;
      }),
      builder.addCase(updateCustomer.fulfilled, (state) => {
        state.loading = false;
        state.extraErrorInfo = null;
      }),
      builder.addCase(changeStatus.pending, (state) => {
        state.loading = true;
        state.error = null;
      }),
      builder.addCase(changeStatus.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      }),
      builder.addCase(changeStatus.fulfilled, (state) => {
        state.loading = false;
        state.error = null;
        state.success = true;
      }),
      builder.addCase(deleteCustomer.pending, (state) => {
        state.loading = true;
        state.error = null;
      }),
      builder.addCase(deleteCustomer.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      }),
      builder.addCase(deleteCustomer.fulfilled, (state) => {
        state.loading = false;
        state.error = null;
        state.success = true;
      });

    builder.addCase(getCustomerList.pending, (state) => {
      state.loading = true;
      state.customerList = [];
    }),
      builder.addCase(getCustomerList.rejected, (state) => {
        state.loading = false;
        state.customerList = [];
      }),
      builder.addCase(getCustomerList.fulfilled, (state, action) => {
        state.loading = false;
        state.customerList = action.payload.results;
      });
  },
});

export const { clear, removeCurrentCustomer, setError } = customerSlice.actions;

export const selectCustomerLoading = (state: RootState) =>
  state.customer.loading;
export const selectCustomerError = (state: RootState) => state.customer.error;
export const selectCustomerSuccess = (state: RootState) =>
  state.customer.success;
export const selectCustomerList = (state: RootState) =>
  state.customer.customerList;
export const selectCurrentCustomer = (state: RootState) =>
  state.customer.currentCustomer;
export const selectExtraErrorInfo = (state: RootState) =>
  state.customer.extraErrorInfo;

export default customerSlice;
