import { client } from "Service/axios";
import {
  IPurchaseOrder,
  IPurchaseOrderAdd,
  IPurchaseOrderLineItemUpdate,
  IPurchaseOrderRecieved,
  IPurchaseOrderSearchParams,
} from "Shared/Types/purchase-order";

const PURCHASE_ORDER_URL = "part/purchase-order/";

class PurchaseOrder {
  getPurchaseOrderList(params?: IPurchaseOrderSearchParams) {
    return client.get<IPurchaseOrder[]>(PURCHASE_ORDER_URL, { params });
  }
  getPurchaseOrder(id: string) {
    return client.get<IPurchaseOrder>(`${PURCHASE_ORDER_URL}${id}/`);
  }
  addPurchaseOrder(data: IPurchaseOrderAdd) {
    return client.post(`${PURCHASE_ORDER_URL}create/`, data);
  }
  deletePurchaseOrder(id: string) {
    return client.delete(`${PURCHASE_ORDER_URL}${id}/delete/`);
  }
  getPurchaseOrderLogs(id: string) {
    return client.get(`${PURCHASE_ORDER_URL}${id}/logs/`);
  }
  getPurchaseOrderNotes(id: string) {
    return client.get(`${PURCHASE_ORDER_URL}${id}/notes/`);
  }
  deletePurhcaseOrderNote(purchaseOrderId: string, noteId: string) {
    return client.delete(
      `${PURCHASE_ORDER_URL}${purchaseOrderId}notes/${noteId}/delete/`
    );
  }
  createPurchaseOrderNote(purchaseOrderId: string, note: string) {
    return client.post(`${PURCHASE_ORDER_URL}${purchaseOrderId}notes/`, {
      note,
    });
  }
  sendMailPurchaseOrder(id: string) {
    return client.post(`${PURCHASE_ORDER_URL}${id}/send-mail/`);
  }
  receivePurchaseOrder(id: string, data: IPurchaseOrderRecieved) {
    return client.post(`${PURCHASE_ORDER_URL}${id}/receive/`, data);
  }
  updatePurchaseOrderLineItems(id: string, data: IPurchaseOrderLineItemUpdate) {
    return client.put(`${PURCHASE_ORDER_URL}${id}/part/${data.partId}/`, {
      actual_cost: data.actual_cost,
    });
  }
}

export default PurchaseOrder;
