import React, { useEffect, useState } from "react";
import styles from "./EmployeeInfo.module.scss";
import { useSelector } from "react-redux";
import { selectCurrentUser } from "Redux/User/userSlice";
import { useNavigate, useParams } from "react-router";
import { useAppDispatch } from "Redux/store";
import { deleteUser, getUser } from "Redux/User/reducer";
import IconButton from "Components/UI/IconButton/IconButton";
import { DeleteIcon, EditIcon } from "Assets/index";
import Tabs from "Components/UI/Tabs/Tabs";
import Details from "./Details/Details";
import DeleteModal from "Components/UI/DialogWindow/DeleteModal";
import OrderList from "Containers/Order/AllOrder/OrderList/OrderList";
import EmployeeChartsContainer from "Containers/Employee/EmployeeInfo/EmployeeCharts/EmployeeChartsContainer";
import { notifySuccess } from "Components/Notify/notify";

const EmployeeInfo: React.FC = () => {
  const employee = useSelector(selectCurrentUser);
  const [activeTab, setActiveTab] = useState(0);
  const [showDelete, setShowDelete] = useState(false);
  const [deleteId, setDeleteId] = useState<string | null>();
  const navigate = useNavigate();
  const { id } = useParams();
  const dispatch = useAppDispatch();

  const goToEditPage = (id: string | undefined) => {
    if (!id) return;
    navigate(`/employees/edit/${id}`);
  };
  const handleDialogClose = () => {
    setShowDelete(false);
    setDeleteId(null);
  };

  const handleDialogOpen = (id: string | undefined) => {
    if (!id) return;
    setShowDelete(true);
    setDeleteId(id);
  };

  const handleDeleteEmployee = async () => {
    if (deleteId) {
      await dispatch(
        deleteUser({
          id: deleteId,
          onSuccess: () => {
            notifySuccess("Employee is deleted!");
            navigate("/employees");
          },
        })
      );
    }
  };

  useEffect(() => {
    if (id) {
      dispatch(getUser(id));
    }
  }, [id]);

  return (
    <div className={styles.info}>
      <div className={styles.info__head}>
        <div className={styles.info__head__icons}>
          <IconButton
            icon={EditIcon}
            size="small"
            onClick={() => goToEditPage(id)}
          />
          <IconButton
            icon={DeleteIcon}
            size="small"
            onClick={() => handleDialogOpen(id)}
          />
        </div>
      </div>
      {employee && (
        <Tabs
          tabs={["Main Info", "Orders", "Statistical Charts"]}
          tabPanels={[
            <Details key="userList" employee={employee} />,
            <OrderList key="orders" employee={employee} />,
            <EmployeeChartsContainer key="employeeChart" />,
          ]}
          activeTab={activeTab}
          onChange={setActiveTab}
        />
      )}
      <DeleteModal
        acceptTitle="Delete"
        open={showDelete}
        handleClose={handleDialogClose}
        title="Delete"
        text="Are you sure...?"
        accept={handleDeleteEmployee}
        color="red"
      />
    </div>
  );
};
export default EmployeeInfo;
