import { geoNamesClient } from "Service/axios";

class Address {
  getCountries() {
    return geoNamesClient.get('countryInfoJSON', { params: { username: 'syrgak' } })
  }
  getStates(country: number) {
    return geoNamesClient.get('childrenJSON', {
      params: {
        geonameId: country,
        // hierarchy: 'dependency',
        username: 'syrgak',
      }
    })
  }
}

export default Address;