import React from "react";
import { useAppDispatch } from "Redux/store";
import { notifyError, notifySuccess } from "Components/Notify/notify";
import InfoOrChangeField from "Components/UI/InfoOrChangeField/InfoOrChangeField";
import {
  IEstimateServiceFields,
  IEstimatesService,
} from "Shared/Types/estimate";
import {
  getEstimateService,
  updateEstimateServiceByFields,
} from "Redux/Estimate/reducer";

export const renderCell = (
  cellData: any,
  columnId: string,
  estimateService: IEstimatesService,
) => {
  const dispatch = useAppDispatch();

  const onSuccess = () => {
    if (estimateService.id) {
      notifySuccess("Estimate is Updated");
      dispatch(getEstimateService(estimateService.id));
    }
  };
  const onError = (error?: string) => {
    notifyError(error || "Something went wrong");
  };
  const saveField = (
    field: IEstimateServiceFields,
    value: string | number,
    withCallback?: boolean,
  ) => {
    if (estimateService.id) {
      dispatch(
        updateEstimateServiceByFields({
          id: estimateService.id,
          data: { field, value },
          onSuccess: withCallback ? onSuccess : undefined,
          onError: withCallback ? onError : undefined,
        }),
      );
    }
  };

  const changeInvoiceHours = (amount: string | null | number) => {
    saveField(
      "invoice_hours",
      (+(amount || 0) / (estimateService.labor_rate?.rate || 0)).toFixed(3),
      true,
    );
  };
  if (columnId === "labor_rate") {
    return cellData?.name;
  }
  if (columnId === "amount") {
    return (
      <InfoOrChangeField
        value={(
          (estimateService.labor_rate?.rate || 0) *
          (estimateService.invoice_hours || 0)
        ).toFixed(3)}
        name="amount"
        onFieldChange={(text: string | null | number) =>
          changeInvoiceHours(text)
        }
        type="number"
      />
    );
  }
  if (columnId === "rate") {
    return (
      <InfoOrChangeField
        value={cellData}
        name="rate"
        onFieldChange={(text: string | null | number) =>
          changeInvoiceHours(text)
        }
        type="number"
      />
    );
  }
  return cellData;
};
