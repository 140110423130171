import { client } from "Service/axios";
import {
  IOrderInvoice,
  IOrderInvoiceForm,
  IOrderInvoiceUpdateForm,
  ISendEmail,
} from "Shared/Types/invoice";

const ORDER_INVOICE_URL = "order/invoice/";

class OrderInvoice {
  getOrderInvoice(id: string) {
    return client.get<IOrderInvoice>(`${ORDER_INVOICE_URL}${id}/`);
  }
  addOrderInvoice(data: IOrderInvoiceForm) {
    return client.post<IOrderInvoice>(`${ORDER_INVOICE_URL}create/`, data, {
      headers: { "Content-Type": "multipart/form-data" },
    });
  }
  updateOrderInvoice(id: string, data: IOrderInvoiceUpdateForm) {
    return client.patch(`${ORDER_INVOICE_URL}${id}/update/`, data);
  }
  deleteOrderInvoice(id: string) {
    return client.delete(`${ORDER_INVOICE_URL}${id}/delete/`);
  }
  payInvoice(id: string) {
    return client.post(`${ORDER_INVOICE_URL}${id}/pay-invoice/`);
  }
  sendEmail(id: string, data: ISendEmail) {
    return client.post(`${ORDER_INVOICE_URL}${id}/send-email/`, data);
  }
}

export default OrderInvoice;
