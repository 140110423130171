import React, { useState } from "react";
import InputField from "Components/UI/Inputs/InputField";
import { updateActionPartByField } from "Redux/ActionPart/reducer";
import { useAppDispatch } from "Redux/store";
import { IActionPart, IActionPartFields } from "Shared/Types/action-item-part";
import styles from "./PartField.module.scss";
import { notifySuccess } from "Components/Notify/notify";
import { getOrderActionItemParts } from "Redux/Order/reducer";

type PartFieldProps = {
  part: IActionPart;
  name: IActionPartFields;
  defValue?: string | number;
  orderId: string;
};

const PartField: React.FC<PartFieldProps> = ({
  part,
  name,
  defValue,
  orderId,
}) => {
  const [text, setText] = useState(defValue);
  const dispatch = useAppDispatch();

  const savePart = () => {
    dispatch(
      updateActionPartByField({
        data: { field: name, value: text },
        id: part.id,
        onSuccess: () => {
          notifySuccess("Part is saved!");
          dispatch(
            getOrderActionItemParts({
              order: orderId,
            })
          );
        },
      })
    );
  };

  if (!part.vendor && name === "price") return <>-</>;

  if (defValue) return <>{defValue}</>;

  return (
    <div className={styles.part}>
      <InputField
        name={name}
        onBlur={savePart}
        value={text}
        onChange={(event) => setText(event.target.value)}
        className={styles.part__input}
      />
    </div>
  );
};
export default PartField;
