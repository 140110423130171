import Form from "Components/UI/Form/Form";
import { FIRST_AUTH } from "Shared/Constants/auth";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import styles from "./Login.module.scss";
import { useAppDispatch } from "Redux/store";
import { yupResolver } from "@hookform/resolvers/yup";
import { firstAuthSchema } from "Shared/Utils/auth";
import { firstAuth } from "Redux/Auth/reducers";
import { IFirstAuthForm } from "Shared/Types/auth";
import { useSelector } from "react-redux";
import {
  clear,
  selectAuthError,
  selectAuthLoading,
  selectAuthSuccess,
} from "Redux/Auth/slice";
import { useNavigate, useParams } from "react-router";
import { notifyError, notifySuccess } from "Components/Notify/notify";
import AuthLayout2 from "Components/Layout/AuthLayout/AuthLayout";
import InputField from "Components/Layout/Inputs/InputField";
import Button from "Components/Layout/Button/Button";

const FirstSign: React.FC = () => {
  const dispatch = useAppDispatch();
  const params = useParams();
  const navigate = useNavigate();
  const error = useSelector(selectAuthError);
  const success = useSelector(selectAuthSuccess);
  const loading = useSelector(selectAuthLoading);
  const { control, handleSubmit, setValue } = useForm<IFirstAuthForm>({
    resolver: yupResolver(firstAuthSchema),
  });

  const onSubmit = async (data: IFirstAuthForm) => {
    await dispatch(firstAuth({ ...data }));
  };

  useEffect(() => {
    const { uid, token } = params;
    if (uid) {
      setValue("uid", uid);
    }
    if (token) {
      setValue("token", token);
    }
  }, [params]);

  useEffect(() => {
    if (error) {
      notifyError(error);
      dispatch(clear());
    } else if (success) {
      notifySuccess("Your password changed!");
      dispatch(clear());
      navigate("/login");
    }
  }, [error, success]);

  return (
    <AuthLayout2 title={FIRST_AUTH.TITLE} descrption={FIRST_AUTH.DESCRIPTION}>
      <Form onSubmit={handleSubmit(onSubmit)} className={styles.login}>
        <InputField
          required
          name="new_password"
          placeholder="Password"
          control={control}
          withController
          isPassword
        />
        <InputField
          required
          name="confirm_password"
          placeholder="Confirm  password"
          control={control}
          withController
          isPassword
        />
        <Button
          variant="contained"
          color="primary"
          type="submit"
          fullWidth
          loading={loading}
        >
          Create
        </Button>
      </Form>
    </AuthLayout2>
  );
};
export default FirstSign;
