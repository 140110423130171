import { IUser } from "Shared/Types/user";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import AcynsSelectField from "Components/UI/AcyncSelect/AcyncSelect";
import { selectUserList, selectUserLoading } from "Redux/User/userSlice";
import { useAppDispatch } from "Redux/store";
import { getUserList } from "Redux/User/reducer";
import { IActionItemFields } from "Shared/Types/order";
import { changeEmployeeOptions } from "Shared/Utils/app";

type TechnicianFieldProps = {
  saveActionItemField: (field: IActionItemFields, value: string) => void;
  technician?: IUser | null;
};

const TechnicianField: React.FC<TechnicianFieldProps> = ({
  saveActionItemField,
  technician,
}) => {
  const technicianList = useSelector(selectUserList) || [];
  const systemLoading = useSelector(selectUserLoading);
  const dispatch = useAppDispatch();
  const [selected, setSelected] = useState<string>();
  const inputChange = (text: string | null) => {
    dispatch(getUserList({ search: text }));
  };

  const onBlur = () => {
    if (selected) {
      saveActionItemField("technician", selected);
    }
  };

  useEffect(() => {
    inputChange(null);
  }, []);
  return (
    <AcynsSelectField
      options={changeEmployeeOptions(technicianList)}
      label="Technician"
      loading={systemLoading}
      defaultValue={{
        label: technician?.first_name + ":  " + technician?.last_name,
        value: technician?.id || "",
      }}
      onInputChange={(text) => inputChange(text)}
      onChange={(value) => setSelected(value as string)}
      onBlur={onBlur}
    />
  );
};
export default TechnicianField;
