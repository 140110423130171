import React from "react";
import Layout from "Components/Layout/Layout";
import UnitTypeUpdate from "Containers/UnitType/UnitTypeUpdate/UnitTypeUpdate";
import SettingsLayout from "Components/Settings/Layout/Layout";
import { useSelector } from "react-redux";
import { BreadcrumbItem } from "Components/Breadcrumb/Breadcrumb";
import { useBreadcrumb } from "Shared/Hooks/use-breadcrumb";
import { selectCurrentUnit } from "Redux/Unit/slice";

export const UnitTypeUpdatePage: React.FC = () => {
  const unitType = useSelector(selectCurrentUnit);

  const breads: BreadcrumbItem[] = [
    { title: "Setting", link: "/settings/unit-settings/unit-types" },
    { title: "Unit Types", link: "/settings/unit-settings/unit-types" },
    { title: `Update ${unitType?.name || ""}`, link: "" },
  ];
  useBreadcrumb({ links: breads, option: { upd: unitType?.name } });

  return (
    <Layout>
      <SettingsLayout>
        <UnitTypeUpdate />
      </SettingsLayout>
    </Layout>
  );
};
