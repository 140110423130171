import React, { HTMLAttributes, useEffect, useRef } from "react";
import * as echarts from "echarts";
import ReactECharts from "echarts-for-react";
import { RadarIndicatorOption } from "echarts/types/src/coord/radar/RadarModel";

type EChartsOption = echarts.EChartsOption;

interface RadarChartProps extends HTMLAttributes<HTMLDivElement> {
  indicators: RadarIndicatorOption[];
}

const RadarChart: React.FC<RadarChartProps> = ({ indicators, className }) => {
  const chartOption: EChartsOption = {
    backgroundColor: "transparent",

    animation: true,
    tooltip: {
      show: true,
    },

    textStyle: {
      fontSize: 13,
    },
    radar: {
      indicator: indicators,
      shape: "polygon",
      radius: "82%",
      splitNumber: 5,
      axisName: {
        borderType: "solid",
        color: "#FFF",
      },
      splitLine: {
        lineStyle: {
          color: "#5B5B5B",
          width: 1,
        },
      },
      splitArea: {
        show: false,
      },
      axisLine: {
        lineStyle: {
          color: "#5B5B5B",
          width: 1,
        },
      },
    },

    series: [
      {
        name: "Overview",
        type: "radar",
        lineStyle: {
          width: 3,
          opacity: 1,
        },
        data: [[234, 185, 230, 4.09, 123]],
        symbol: "circle",
        symbolSize: 10,
        itemStyle: {
          color: "#007DF2",
          borderColor: "rgba(255, 255, 255, 0.8)",
          borderWidth: 2,
        },
        areaStyle: {
          opacity: 1,
          color: "rgba(116, 188, 255, 0.53)",
        },
      },
    ],
  };

  const chartRef = useRef<ReactECharts>(null);

  useEffect(() => {
    const chartDom = document.getElementById("main");

    if (chartDom) {
      const myChart = echarts.init(chartDom);
      myChart.setOption(chartOption);

      return () => {
        myChart.dispose();
      };
    }
  }, [chartOption]);

  return (
    <ReactECharts ref={chartRef} option={chartOption} className={className} />
  );
};

export default RadarChart;
