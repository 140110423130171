import { ICompany, ICompanyRequest } from "Shared/Types/company";
import { format } from "Shared/Utils/app";
import { getShortValue } from "Shared/Utils/order";

export const getCompanyRequestInformation = (
  company: ICompanyRequest | ICompany | null | undefined,
) => {
  if (company) {
    return [
      {
        title: "Date",
        value:
          (company.created_at && format(company?.created_at, "MM-DD-YY")) ||
          "-",
      },
      { title: "Main Phone", value: company?.phone || "-" },
      { title: "Legal Name", value: company?.legal_name || "-" },
      { title: "Fax", value: company?.fax || "-" },
      {
        title: "Company name",
        value: "company_name" in company ? company.company_name : company?.name,
      },
      { title: "Website", value: company?.website || "-" },
      { title: "Email", value: company?.email || "-" },
    ];
  }
  return [];
};

export const getCompanyRequestCardData = (company: ICompanyRequest) => {
  const data = [
    {
      title: "Date",
      value:
        (company.created_at && format(company?.created_at, "MM-DD-YY")) || "-",
    },
    {
      title: "First name",
      value: company.first_name,
    },
    {
      title: "Last name",
      value: company.last_name,
    },
    {
      title: "Business name",
      value: getShortValue(company.company_name, 12),
    },
    {
      title: "Email",
      value: getShortValue(company.email, 12),
    },
    {
      title: "Contact",
      value: company.phone,
    },
  ];
  return data;
};

export const getCompanyBilling = (
  company: ICompanyRequest | ICompany | null | undefined,
) => {
  const billing = [
    {
      title: "Billing contact",
      value: company?.billing_contact || "-",
      xs: 12,
    },
    {
      title: "Payment method",
      value: company?.payment_method || "-",
      xs: 12,
    },
    {
      title: "Billing address",
      value: company?.billing_address || "-",
      xs: 12,
    },
  ];
  const primary = [
    {
      title: "Primary contact",
      value: company?.primary_contact || "-",
      xs: 12,
    },
    {
      title: "Primary address",
      value: company?.primary_address || "-",
      xs: 12,
    },
  ];

  return { billing, primary };
};
