import React, { FC } from "react";
import { IOrder } from "Shared/Types/order";

import styles from "./PayStatus.module.scss";
import cn from "classnames";

type PayStatusProps = {
  order: IOrder;
};
const PayStatus: FC<PayStatusProps> = ({ order }) => {
  return (
    <div
      className={cn(
        styles.pay,
        order?.service_order_invoice?.is_paid && styles.pay_paid
      )}
    >
      {order?.service_order_invoice?.is_paid ? "Paid" : "Unpaid"}
    </div>
  );
};

export default PayStatus;
