import Modal from "Components/UI/Modal/Modal";
import { IActionPart } from "Shared/Types/action-item-part";
import React, { useEffect } from "react";
import ReceivePartItem from "./ReceivePartItem/ReceivePartItem";
import { Grid } from "@mui/material";
import InputField from "Components/UI/Inputs/InputField";
import Button from "Components/UI/Button/Button";
import styles from "./ReceivePartModal.module.scss";
import { FormProvider, useFieldArray, useForm } from "react-hook-form";
import { IPurchaseOrderRecieved } from "Shared/Types/purchase-order";
import { yupResolver } from "@hookform/resolvers/yup";
import { purchaseOrderReceiveSchema } from "Shared/Utils/purchase-order";
import Form from "Components/UI/Form/Form";
import { convertActionPartToReceivePurchaseOrder } from "Shared/Utils/action-item-part";
import { usePartListOperation } from "Shared/Hooks/order/part-list/use-part-list-operation";

type ReceiveActionItemPartProps = {
  open: boolean;
  setOpen: (open: boolean) => void;
  selectedParts: IActionPart[];
  setSelectedParts: (parts: IActionPart[]) => void;
};

const ReceiveActionItemPart: React.FC<ReceiveActionItemPartProps> = ({
  open,
  setOpen,
  selectedParts,
  setSelectedParts,
}) => {
  const { receiveParts } = usePartListOperation({
    selectedParts,
    onClose: setOpen,
    setSelectedParts,
  });
  const methods = useForm({
    resolver: yupResolver(purchaseOrderReceiveSchema),
  });
  const { fields } = useFieldArray({
    control: methods.control,
    name: "receive_line_items",
  });

  const getModalTitle = () => {
    return "Vendor:    " + selectedParts[0]?.vendor?.vendor_name;
  };

  useEffect(() => {
    methods.reset(convertActionPartToReceivePurchaseOrder(selectedParts));
  }, [selectedParts]);

  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      title={getModalTitle()}
      isClosed
      onCancel={() => setOpen(false)}
    >
      <FormProvider {...methods}>
        <Form
          onSubmit={methods.handleSubmit((values: IPurchaseOrderRecieved) =>
            receiveParts(values, () => methods.reset())
          )}
        >
          {fields.map((part, index) => (
            <ReceivePartItem
              key={part.id}
              part={selectedParts[index]}
              index={index}
            />
          ))}
          <Grid container columnSpacing={2}>
            <Grid item xs={4}>
              <InputField
                label="Date"
                name="date"
                type="date"
                control={methods.control}
                withController
                disabled
              />
            </Grid>
            <Grid item xs={4}>
              <InputField
                label="Vendor Bill #"
                name="number"
                control={methods.control}
                withController
              />
            </Grid>
            <Grid item xs={4}>
              <InputField
                label="Packing Slip #"
                name="packing_slip"
                control={methods.control}
                withController
              />
            </Grid>
            <div className={styles.action}>
              <Button type="submit">Submit</Button>
            </div>
          </Grid>
        </Form>
      </FormProvider>
    </Modal>
  );
};
export default ReceiveActionItemPart;
