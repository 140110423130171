import { BreadcrumbItem } from "Components/Breadcrumb/Breadcrumb";
import Layout from "Components/Layout/Layout";
import OrderDetailAction from "Containers/Order/AllOrder/OrderDetail/OrderDetailAction/OrderDetailAction";
import { useBreadcrumb } from "Shared/Hooks/use-breadcrumb";
import React from "react";
import { useParams } from "react-router";

export const OnHoldDetailPage: React.FC = () => {
  const { id } = useParams();
  const breads: BreadcrumbItem[] = [
    { title: "Orders", link: "/orders" },
    { title: "On Hold", link: "/orders/on-hold" },
    { title: id || "", link: "" },
  ];
  useBreadcrumb({ links: breads, option: { upd: id } });
  return (
    <Layout>
      <OrderDetailAction />
    </Layout>
  );
};
