export const customDarkStyles = (textAlign: string) => ({
  control: (baseStyles: any) => ({
    ...baseStyles,
    cursor: "pointer",
    textAlign: textAlign,
  }),
  input: (baseStyles: any) => ({
    ...baseStyles,
    color: "white",
  }),
  singleValue: (baseStyles: any) => ({
    ...baseStyles,
    color: "#ffffff",
    boxShadow: "none",
    fontSize: "14px",
    fontWeight: "600",
    width: "100px",
  }),
  menu: (baseStyles: any) => ({
    ...baseStyles,
    zIndex: 9999,
    marginTop: "-4px",
    width: "100%",
    backdropFilter: "blur(100px)",
  }),
  option: (baseStyles: any, state: any) => ({
    ...baseStyles,
    backgroundColor: "#1d1d1d",
    cursor: state.isSelected ? "initial" : "pointer",
    backdropFilter: state.isSelected
      ? "blur(100px) brightness(80%)"
      : "blur(100px)",
    "&:hover": {
      backgroundColor: state.isSelected ? "#363636" : "black",
    },
  }),
});

export const customStyles = {
  control: (baseStyles: any) => ({
    ...baseStyles,
    cursor: "pointer",
  }),
  menu: (baseStyles: any) => ({
    ...baseStyles,
    zIndex: 9999,
    marginTop: "-4px",
  }),
  option: (baseStyles: any, state: any) => ({
    ...baseStyles,
    backgroundColor: state.isSelected && "transparent",
    color: state.isSelected && "black",
    cursor: state.isSelected ? "initial" : "pointer",
    "&:focus": {
      backgroundColor: "transparent",
    },
  }),
};
