import React, { useEffect, useState } from "react";
import { notifySuccess } from "Components/Notify/notify";
import DeleteModal from "Components/UI/DialogWindow/DeleteModal";
import { useAppDispatch } from "Redux/store";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { selectServiceList, selectServiceNumPage } from "Redux/Service/slice";
import { deleteService, getServiceList } from "Redux/Service/reducer";
import { serviceFields } from "Shared/Constants/service";
import ListLayout from "Components/Layout/ListLayout/ListLayout";
import { getServiceCardData } from "Shared/Utils/service";
import { useSearchParams } from "react-router-dom";
import Card from "Components/UI/Card/Card/Card";
import Row from "Components/UI/Card/Row/Row";
import { IService } from "Shared/Types/service";

const ServiceList: React.FC = () => {
  const services = useSelector(selectServiceList) || [];
  const numPage = useSelector(selectServiceNumPage);
  const dispatch = useAppDispatch();
  const [showDelete, setShowDelete] = useState(false);
  const [deleteId, setDeleteId] = useState<string | null>();
  const [isCard, setIsCard] = useState(true);
  const [fields, setFields] = useState(serviceFields);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const handleDialogClose = () => {
    setShowDelete(false);
    setDeleteId(null);
  };

  const handleDialogOpen = (id: string) => {
    setShowDelete(true);
    setDeleteId(id);
  };

  const handleDeleteService = async () => {
    const onSuccess = () => {
      notifySuccess("Service deleted");
      setShowDelete(false);
      dispatch(getServiceList(null));
    };
    if (deleteId) {
      await dispatch(deleteService({ id: deleteId, onSuccess }));
    }
  };

  const goToDetailPage = (id: string) => {
    navigate(`/services/${id}`);
  };

  const goToEditPage = (id: string) => {
    navigate(`/services/edit/${id}`);
  };

  const props = (service: IService) => {
    return {
      openDeleteModal: handleDialogOpen,
      fields: fields,
      onClick: goToDetailPage,
      cardData: getServiceCardData(service),
      editClick: goToEditPage,
      dataId: service.id,
    };
  };
  useEffect(() => {
    dispatch(
      getServiceList({
        search: searchParams.get("search"),
        system: searchParams.get("category"),
        page: searchParams.get("page") || "1",
      })
    );
  }, [searchParams]);

  return (
    <ListLayout
      pagesNumber={numPage || 1}
      isCard={isCard}
      setIsCard={setIsCard}
      fields={fields}
      setFields={setFields}
      filters={["search", "category"]}
      fieldAllList={serviceFields}
    >
      {isCard
        ? services.map((service) => (
            <Card key={service.id} {...props(service)} />
          ))
        : services.map((service) => (
            <Row key={service.id} {...props(service)} />
          ))}

      <DeleteModal
        open={showDelete}
        handleClose={handleDialogClose}
        title="Delete"
        text="Are you sure...?"
        acceptTitle="Delete"
        accept={handleDeleteService}
      />
    </ListLayout>
  );
};
export default ServiceList;
