import React, { FC } from "react";
import { Document, Page, View } from "@react-pdf/renderer";
import { IOrderInvoiceData } from "Shared/Types/invoice";
import HeadInfo from "./HeadInfo/HeadInfo";
import ActionItems from "./ActionItems/ActionItems";
import UnitInfo from "./UnitInfo/UnitInfo";
import Cost from "./Cost/Cost";
import { IAccount } from "Shared/Types/auth";
import { styles } from "./style";

export type OrderInvoicePDFProps = {
  data: IOrderInvoiceData;
  profile: IAccount;
};

const OrderInvoicePDF: FC<OrderInvoicePDFProps> = ({ data, profile }) => {
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <HeadInfo data={data} profile={profile} />
        <View>
          {data?.action_itmes.map((actionItem) => (
            <ActionItems actionItem={actionItem} key={actionItem.id} />
          ))}
        </View>
        <View style={styles.footer}>
          <UnitInfo orderData={data} />
          <Cost orderData={data} profile={profile} />
        </View>
      </Page>
    </Document>
  );
};

export default OrderInvoicePDF;
