import { BreadcrumbItem } from "Components/Breadcrumb/Breadcrumb";
import Layout from "Components/Layout/Layout";
import { VendorList } from "Containers/Vendor/VendorList/VendorList";
import { useBreadcrumb } from "Shared/Hooks/use-breadcrumb";
import React from "react";

export const VendorListPage: React.FC = () => {
  const breads: BreadcrumbItem[] = [{ title: "Vendors", link: "/vendors" }];
  useBreadcrumb({
    links: breads,
    createTitle: "Vendor",
    isNeedCreateBtn: true,
  });
  return (
    <Layout>
      <VendorList />
    </Layout>
  );
};
