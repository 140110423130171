import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { IGlobalSearch } from "Shared/Types/common";
import { getGlobalSearch } from "./reducer";
interface IGlobalSearchState {
  result?: IGlobalSearch;
  success: string | null;
  error?: string | null;
  loading: boolean;
}

const initialSearchResult: IGlobalSearch = {
  units: [],
  orders: [],
  products: [],
  services: [],
  action_items: [],
  vendors: [],
  customers: [],
};

const initialState: IGlobalSearchState = {
  success: null,
  loading: false,
  error: null,
  result: initialSearchResult,
};

const globalSearchSlice = createSlice({
  name: "globalSearch",
  initialState,
  reducers: {
    clear(state) {
      state.result = undefined;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getGlobalSearch.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(getGlobalSearch.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
    builder.addCase(getGlobalSearch.fulfilled, (state, action) => {
      state.loading = false;
      state.error = null;
      state.result = action.payload;
    });
  },
});

export const { clear } = globalSearchSlice.actions;

export const selectGlobalSearchResult = (state: RootState) =>
  state.globalSearch.result;

export default globalSearchSlice;
