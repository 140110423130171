import React from "react";
import Layout from "Components/Layout/Layout";
import { useBreadcrumb } from "Shared/Hooks/use-breadcrumb";
import { getPurchaseOrderBreadCrumb } from "Shared/Utils/purchase-order";
import PurchaseOrderAdd from "Containers/PurchaseOrder/PurchaseOrderAdd/PurchaseOrderAdd";

export const PurchaseOrderAddPage: React.FC = () => {
  useBreadcrumb({
    links: getPurchaseOrderBreadCrumb("ADD"),
  });

  return (
    <Layout>
      <PurchaseOrderAdd />
    </Layout>
  );
};
