import React, { useEffect, useState } from "react";
import styles from "./Manufacturer.module.scss";
import IconButton from "Components/UI/IconButton/IconButton";
import { DeleteIcon, EditIcon, PlusIcon } from "Assets/index";
import { useAppDispatch } from "Redux/store";
import { useSelector } from "react-redux";
import { selectManufacturerList } from "Redux/PartSettings/Manufacturer/slice";
import {
  deleteManufacturer,
  getManufacturerList,
} from "Redux/PartSettings/Manufacturer/reducer";
import { notifySuccess } from "Components/Notify/notify";
import DeleteModal from "Components/UI/DialogWindow/DeleteModal";
import { useNavigate } from "react-router";

const Manufacturer: React.FC = () => {
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [deleteId, setDeleteId] = useState<string | null>();

  const dispatch = useAppDispatch();
  const manufacturerList = useSelector(selectManufacturerList) || [];
  const navigate = useNavigate();

  const handleDeleteDialogClose = () => {
    setOpenDeleteModal(false);
    setDeleteId(null);
  };
  const handleDeleteDialogOpen = (id: string) => {
    setOpenDeleteModal(true);
    setDeleteId(id);
  };

  const onDelete = async () => {
    const onSuccess = () => {
      notifySuccess("Manufacturer deleted");
      handleDeleteDialogClose();
      dispatch(getManufacturerList(null));
    };
    if (deleteId != null) {
      await dispatch(deleteManufacturer({ id: deleteId, onSuccess }));
    }
  };

  const createManufacturer = () => {
    navigate(`/settings/part-settings/manufactures/new`);
  };

  const editButtonClick = (id: string) => {
    navigate(`/settings/part-settings/manufactures/${id}`);
  };

  useEffect(() => {
    dispatch(getManufacturerList(null));
  }, []);

  return (
    <div className={styles.manufacturer}>
      <div className={styles.manufacturer__addBtn}>
        <IconButton
          onClick={createManufacturer}
          label="Create"
          width={30}
          height={30}
          icon={PlusIcon}
        />
      </div>
      <div className={styles.manufacturer__list}>
        <p className={styles.manufacturer__list__title}>Manufacturers</p>
        {manufacturerList.map((manufacturer) => (
          <div
            className={styles.manufacturer__list__item}
            key={manufacturer.id}
          >
            <p className={styles.manufacturer__list__item__name}>
              {manufacturer.name}
            </p>
            <div className={styles.manufacturer__list__item__buttons}>
              <IconButton
                icon={EditIcon}
                onClick={() => editButtonClick(manufacturer?.id)}
              />
              <IconButton
                onClick={() => handleDeleteDialogOpen(manufacturer?.id)}
                icon={DeleteIcon}
              />
            </div>
          </div>
        ))}
      </div>
      <DeleteModal
        acceptTitle="Delete"
        open={openDeleteModal}
        handleClose={handleDeleteDialogClose}
        title="Delete"
        text="Are you sure...?"
        accept={onDelete}
      />
    </div>
  );
};
export default Manufacturer;
