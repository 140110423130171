import { BreadcrumbItem } from "Components/Breadcrumb/Breadcrumb";
import Layout from "Components/Layout/Layout";
import SettingsLayout from "Components/Settings/Layout/Layout";
import RolesList from "Containers/Settings/Roles/RolesList/RolesList";
import { useBreadcrumb } from "Shared/Hooks/use-breadcrumb";
import React from "react";

export const RolesListPages: React.FC = () => {
  const breads: BreadcrumbItem[] = [
    { title: "Settings", link: "/settings/part-settings/categories" },
    { title: "Role & Access", link: "" },
  ];

  useBreadcrumb({ links: breads });

  return (
    <Layout>
      <SettingsLayout>
        <RolesList />
      </SettingsLayout>
    </Layout>
  );
};
