import React, { useEffect, useState } from "react";
import Tabs from "Components/UI/Tabs/Tabs";
import SOHistoryTab from "Containers/Unit/UnitDetail/SOHistoryTab/SOHistoryTab";
import { useAppDispatch } from "Redux/store";
import { deleteUnit, getUnit } from "Redux/Unit/reducer";
import { useNavigate, useParams } from "react-router";
import IconButton from "Components/UI/IconButton/IconButton";
import styles from "./UnitDetail.module.scss";
import { DeleteIcon, EditIcon } from "Assets/index";
import { notifySuccess } from "Components/Notify/notify";
import DeleteModal from "Components/UI/DialogWindow/DeleteModal";
import DetailUnit from "./DetailUnit/DetailUnit";

const UnitDetail = () => {
  const [activeTab, setActiveTab] = useState<number>(0);
  const [showDelete, setShowDelete] = useState(false);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { id } = useParams();

  const handleDialogClose = () => {
    setShowDelete(false);
  };
  const handleDialogOpen = () => {
    setShowDelete(true);
  };
  const goToEditPage = () => {
    navigate(`/units/edit/${id}`);
  };
  const handleDeleteUnit = () => {
    const onSuccess = () => {
      notifySuccess("Unit Deleted");
      navigate("/units");
    };
    if (id) {
      dispatch(deleteUnit({ id, onSuccess }));
    }
  };
  useEffect(() => {
    if (id) {
      dispatch(getUnit(id));
    }
  }, []);

  return (
    <div className={styles.detail__head}>
      <Tabs
        onChange={setActiveTab}
        tabs={["Details", "SO History"]}
        activeTab={activeTab}
        tabPanels={[
          <DetailUnit key="detailTab" />,
          <SOHistoryTab key="SOHistoryTab" />,
        ]}
        type="oval"
      />
      <div className={styles.detail__head__action}>
        <IconButton icon={EditIcon} onClick={goToEditPage} />
        <IconButton icon={DeleteIcon} onClick={handleDialogOpen} />
      </div>
      <DeleteModal
        open={showDelete}
        handleClose={handleDialogClose}
        title="Delete"
        text="Are you sure...?"
        acceptTitle="Delete"
        accept={handleDeleteUnit}
      />
    </div>
  );
};

export default UnitDetail;
