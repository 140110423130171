export const paymentFields = [
  "Tariff", 
  "Invoice",
  "Number",
  "Next Payment",
  "Price",
  "Payment method",
  "Business name",
  "Contact"
  ];
  