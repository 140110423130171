import React from "react";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import dayjs, { Dayjs } from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import styles from "./DatePicker.module.scss";
import { IOrder } from "Shared/Types/order";
import cn from "classnames";

interface DatePickerProps extends React.HTMLAttributes<HTMLDivElement> {
  name: string;
  value: string;
  label?: string;
  required?: boolean;
  borderColor?: "blue" | "orange" | "red";
  handleOnBlur?: (field: keyof IOrder, value: string) => void;
}

const DatePicker: React.FC<DatePickerProps> = ({
  value,
  label,
  name,
  required,
  borderColor,
  handleOnBlur,
}) => {
  const [defaultValue, setDefaultValue] = React.useState<Dayjs | null>(
    dayjs(value),
  );

  const handleOnAccept = (value: Dayjs | null) => {
    if (value) {
      handleOnBlur?.(name as keyof IOrder, value.format());
    }
  };

  return (
    <div className={styles.date}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <label
          data-id={name}
          className={cn(
            styles.date__label,
            borderColor && styles[`date__label_${borderColor}`],
          )}
        >
          {label} {required && <span className={styles.date__error}>*</span>}
        </label>
        <div
          className={cn(
            styles.date__calendar,
            styles[`date__calendar_${borderColor}`],
          )}
        >
          <DateTimePicker
            value={defaultValue}
            onChange={setDefaultValue}
            onAccept={handleOnAccept}
          />
        </div>
      </LocalizationProvider>
    </div>
  );
};

export default DatePicker;
