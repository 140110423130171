import { client } from "Service/axios";
import { SearchResult } from "Shared/Types/common";
import {
  IShop,
  IShopAddApi,
  IShopList,
  IShopSearchParams,
  IShopUpdateApi,
} from "Shared/Types/shop";

const SHOP_URL = "company/shop/";
class Shop {
  getShopList(params: IShopSearchParams | null) {
    return client.get<SearchResult<IShopList>>(SHOP_URL, { params });
  }

  getShop(id: string) {
    return client.get<IShop>(`${SHOP_URL}${id}/`);
  }

  addShop(params: IShopAddApi) {
    return client.post(SHOP_URL, params);
  }

  deleteShop(id: string) {
    return client.delete(`${SHOP_URL}${id}/`);
  }

  updateShop(id: string, data: IShopUpdateApi) {
    return client.patch(`${SHOP_URL}${id}/`, data);
  }
}

export default Shop;
