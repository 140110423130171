import React, { useEffect, useState } from "react";
import styles from "../ComponentSystemContainer.module.scss";
import Icon from "Components/UI/Icon/Icon";
import { DeleteIcon, EditIcon } from "Assets/index";
import DeleteModal from "Components/UI/DialogWindow/DeleteModal";
import { useAppDispatch } from "Redux/store";
import { useSelector } from "react-redux";
import { deleteSystem, getSystemList } from "Redux/System/reducers";
import { useLocation, useNavigate } from "react-router";
import cn from "classnames";
import IconButton from "Components/UI/IconButton/IconButton";
import SystemForm from "../Component/SystemForm";
import { ISystem } from "Shared/Types/system";
import { checkFirstAuth } from "Shared/Utils/app";
import { selectAccount } from "Redux/Auth/slice";
import { notifySuccess } from "Components/Notify/notify";
import { getComponent } from "Redux/Component/reducers";

export type System = {
  system: ISystem;
  setShowComponentEdit?: (show: boolean) => void;
  componentEdit?: boolean;
  setAddShow?: (show: boolean) => void;
  componentId: string;
  isInDetail?: boolean;
};

const System: React.FC<System> = ({
  system,
  componentId,
  isInDetail,
  setAddShow,
  setShowComponentEdit,
  componentEdit,
}) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const location = useLocation();
  const [edit, setIsEdit] = useState(location.state?.systemId == system.id);
  const profile = useSelector(selectAccount);
  const isFirstAuth = checkFirstAuth(profile);

  const goToSystemUpdatePage = () => {
    navigate(`/settings/component-system/${componentId}`, {
      state: { systemId: system.id },
    });
  };

  const onUpdateSystem = () => {
    if (setAddShow) {
      setAddShow(false);
    }
    if (setShowComponentEdit) {
      setShowComponentEdit(false);
    }
    setIsEdit(true);
  };

  const handleCloseDeleteModal = () => {
    setOpenDeleteModal(false);
  };

  const handleOpenDeleteModal = () => {
    setOpenDeleteModal(true);
  };
  const onDelete = async () => {
    const onSuccess = () => {
      notifySuccess("System deleted");
      if (isInDetail) {
        dispatch(getComponent(componentId));
      } else {
        dispatch(getSystemList({ component: componentId }));
      }
      handleCloseDeleteModal();
      setIsEdit(false);
    };
    if (system.id) {
      await dispatch(deleteSystem({ id: system.id, onSuccess }));
    }
  };

  useEffect(() => {
    if (componentEdit) {
      setIsEdit(false);
    }
  }, [componentEdit]);

  return (
    <>
      {edit ? (
        <SystemForm
          componentId={componentId}
          system={system}
          setShow={setIsEdit}
        />
      ) : (
        <div
          className={cn(
            styles.component__head,
            styles.component__head__system,
            isInDetail && styles.component__head__system_inDetail
          )}
        >
          <div>{system.name}</div>
          {!isFirstAuth && isInDetail ? (
            <div className={styles.component__head__innerAction}>
              <IconButton
                width={16}
                height={16}
                onClick={() => setOpenDeleteModal(true)}
                icon={DeleteIcon}
              />
              <IconButton
                width={16}
                height={16}
                onClick={onUpdateSystem}
                icon={EditIcon}
              />
            </div>
          ) : (
            !isFirstAuth && (
              <div className={styles.component__head__actions}>
                <Icon onClick={handleOpenDeleteModal} component={DeleteIcon} />
                <Icon onClick={goToSystemUpdatePage} component={EditIcon} />
              </div>
            )
          )}
        </div>
      )}

      <DeleteModal
        acceptTitle="Delete"
        open={openDeleteModal}
        handleClose={handleCloseDeleteModal}
        title="Delete"
        text="Are you sure...?"
        accept={onDelete}
      />
    </>
  );
};

export default System;
