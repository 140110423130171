import {
  PURCAHSE_ORDER_FIELDS,
  PURCAHSE_ORDER_STATUS,
  PURCAHSE_ORDER_STATUSES,
  PURCHASE_ORDER_ROUTE,
  PURCHASE_ORDER_TYPE,
  purchaseOrderStatusCard,
} from "Shared/Constants/purchase-order";
import { ICardData, TField } from "Shared/Types/common";
import {
  IPurchaseOrder,
  IPurchaseOrderAdd,
  IPurchaseOrderCreateFrom,
  IPurchaseOrderRecieved,
  IPurchaseOrderRouteKeys,
  IPurchaseOrderStatus,
} from "Shared/Types/purchase-order";
import * as yup from "yup";
import { format } from "./app";
import { BreadcrumbItem } from "Components/Breadcrumb/Breadcrumb";
import { IVendor } from "Shared/Types/vendor";
import { IActionPart } from "Shared/Types/action-item-part";
import { IShop } from "Shared/Types/shop";
import { IAccount } from "Shared/Types/auth";

const lineItemsScheme = yup.object().shape({
  quantity: yup.number().required(),
  actual_cost: yup.number().required(),
  part: yup.string().uuid(),
  part_number: yup.string(),
  received_quantity: yup.number(),
  returned_quantity: yup.number(),
  description: yup.string(),
  action_item_part: yup.string().uuid(),
});

export const purchaseOrderScheme = yup.object().shape({
  vendor: yup.string().uuid().required(),
  type: yup.string().required(),
  status: yup.string().required(),
  shop: yup.string().uuid().required(),
  line_items: yup.array().of(lineItemsScheme).required(),
  order: yup.string().uuid(),
});

export const purchasePartScheme = yup.object().shape({
  part_number: yup.mixed(),
  description: yup.string(),
  part: yup.mixed<IActionPart>(),
  vendor: yup.mixed<IVendor>(),
  in_stock: yup.number().nullable(),
  in_transit: yup.boolean().nullable(),
  min_quantity: yup.number().nullable(),
  max_quantity: yup.number().nullable(),
  average_cost: yup.number().nullable(),
  last_cost: yup.number().nullable(),
  price: yup.number().required("Price is required"),
  quantity: yup
    .number()
    .min(1, "Quantity must be greater than 0")
    .required("Quantity is required"),
});

export const purchaseOrderItemSchema = yup.object().shape({
  vendor: yup.mixed<IVendor>(),
  contact: yup.string(),
  email: yup.string(),
  address: yup.string(),
  order_total: yup.number(),
  order_threshold: yup.number(),
  pending_order: yup.number(),
  freight_difference: yup.number(),
  freight_threshold: yup.number(),
  order_difference: yup.number(),
  parts: yup.array().of(purchasePartScheme),
});

export const purchaseOrderCreateSchema = yup.object().shape({
  vendor: yup.mixed<IVendor>(),
  parts: yup.array().of(purchasePartScheme),
  order_items: yup.array().of(purchaseOrderItemSchema),
});

export const purchaseOrderReceiveLineItemSchema = yup.object().shape({
  line_item_id: yup.string().required(),
  received_quantity: yup.number().required(),
});

export const purchaseOrderReceiveSchema = yup.object().shape({
  date: yup.string().required(),
  number: yup.string().required(),
  packing_slip: yup.string().required(),
  sub_total: yup.number().required(),
  credit_amount: yup.number().required(),
  total: yup.number().required(),
  receive_line_items: yup
    .array()
    .of(purchaseOrderReceiveLineItemSchema)
    .required(),
});

export const getPurchaseOrderRoute = (
  type: IPurchaseOrderRouteKeys,
  id?: string
) => {
  return `${PURCHASE_ORDER_ROUTE[type]}${id || ""}`;
};

export const getPurchaseOrderCardData = (purchaseOrder: IPurchaseOrder) => {
  const data: ICardData[] = [
    {
      title: PURCAHSE_ORDER_FIELDS.TYPE,
      value: purchaseOrder.type,
    },
    {
      title:
        purchaseOrder.type === "PO"
          ? PURCAHSE_ORDER_FIELDS.PO
          : PURCAHSE_ORDER_FIELDS.SO,
      value:
        purchaseOrder.type === "PO"
          ? purchaseOrder.number
          : purchaseOrder.order?.order_number,
    },
    {
      title: PURCAHSE_ORDER_FIELDS.VENDOR,
      value: purchaseOrder.vendor.vendor_name,
    },
    {
      title: PURCAHSE_ORDER_FIELDS.DATA,
      value: format(purchaseOrder.created_at),
    },
    {
      title: PURCAHSE_ORDER_FIELDS.EST_TOTAL,
      value: purchaseOrder.est_total,
    },
    {
      title: PURCAHSE_ORDER_FIELDS.ACTUAL_TOTAL,
      value: purchaseOrder.actual_total,
    },
    {
      title: PURCAHSE_ORDER_FIELDS.NUMBER_OF_PARTS,
      value: purchaseOrder.total_quantity,
    },
  ];
  return data;
};

export const getPurchaseOrderCardStatus = (status?: IPurchaseOrderStatus) => {
  if (status) {
    return purchaseOrderStatusCard[status];
  }
};

export const getPurchaseOrderBreadCrumb = (
  type: IPurchaseOrderRouteKeys,
  title = "Order Part"
) => {
  const breads: BreadcrumbItem[] = [
    { title: "Part", link: "/parts" },
    { title: "Purchase Order", link: getPurchaseOrderRoute("LIST") },
  ];

  if (type !== "LIST") {
    breads.push({ title: title || "", link: "" });
  }

  return breads;
};

export const getPurchaseOrderPartInitialValues = (vendor?: IVendor) => {
  return {
    part_number: "",
    description: "",
    part: undefined,
    vendor: vendor,
    in_stock: 0,
    in_transit: false,
    min_quantity: 0,
    max_quantity: 0,
    average_cost: 0,
    last_cost: 0,
    price: 0,
    quantity: 0,
  };
};

export const getPurchaseOrderInitialValues = () => {
  return {
    parts: [getPurchaseOrderPartInitialValues()],
    order_items: [],
    vendor: undefined,
  };
};

export const convertFormDataToPurchaseOrder = (
  data: IPurchaseOrderCreateFrom,
  shop: string
) => {
  const values: IPurchaseOrderAdd[] = [];
  data.order_items?.map((item) => {
    values.push({
      vendor: item.vendor?.id as string,
      type: PURCHASE_ORDER_TYPE.PO,
      status: PURCAHSE_ORDER_STATUS.SENT,
      shop: shop,
      line_items:
        item.parts?.map((part) => ({
          part: part.part?.id,
          quantity: part.quantity,
          part_number:
            part.part_number !== "string"
              ? part.part_number?.id || ""
              : part.part_number,
          actual_cost: part.price,
          description: part.description,
        })) || [],
    });
  });

  return values;
};

export const getPurchaseOrderCommonInfo = (
  purchaseOrder: IPurchaseOrder,
  shop?: IShop
) => {
  return [
    {
      label: PURCAHSE_ORDER_FIELDS.VENDOR,
      value: purchaseOrder.vendor.vendor_name,
      xs: 3,
    },
    {
      label: PURCAHSE_ORDER_FIELDS.STATUS,
      value: PURCAHSE_ORDER_STATUSES[purchaseOrder.status],
      xs: 3,
    },
    {
      label: PURCAHSE_ORDER_FIELDS.DROP_SHIP,
      value: "-",
      xs: 3,
    },
    {
      label: PURCAHSE_ORDER_FIELDS.SHOP,
      value: shop?.shop_name,
      xs: 3,
    },
    {
      label: "divider",
      value: "",
      xs: 12,
      type: "divider",
    },
    {
      label: PURCAHSE_ORDER_FIELDS.ORDER_TOTAL,
      value: purchaseOrder.total,
      xs: 2,
    },
    {
      label: PURCAHSE_ORDER_FIELDS.ORDER_THRESHOLD,
      value: 0,
      xs: 2,
    },
    {
      label: PURCAHSE_ORDER_FIELDS.PENDING_ORDERS,
      value: 0,
      xs: 2,
    },
    {
      label: PURCAHSE_ORDER_FIELDS.FREIGHT_DIFFERENCE,
      value: 0,
      xs: 2,
    },
    {
      label: PURCAHSE_ORDER_FIELDS.FREIGHT_THRESHOLD,
      value: 0,
      xs: 2,
    },
    {
      label: PURCAHSE_ORDER_FIELDS.ORDER_DIFFERENCE,
      value: 0,
      xs: 2,
    },
  ] as TField[];
};

export const getPurchaseOrderReceiveInitValues = (
  purchaseOrder: IPurchaseOrder
) => {
  const data: IPurchaseOrderRecieved = {
    date: format(new Date(), "YYYY-MM-DD"),
    number: "",
    packing_slip: "",
    sub_total: +(purchaseOrder.total || 0),
    credit_amount: 0,
    total: +(purchaseOrder.total || 0),
    receive_line_items: purchaseOrder.line_items?.map((item) => ({
      line_item_id: item.id,
      received_quantity: item.received_quantity,
    })),
  };

  return data;
};

export const getPurchaseOrderAmountTitle = (profile?: IAccount | null) => {
  if (!profile) return "";
  return `I am ${profile?.first_name} ${profile?.last_name} received the item above`;
};

export const getTotal = (values: IPurchaseOrderRecieved) => {
  return values.sub_total - values.credit_amount;
};
