import React from "react";
import { useSelector } from "react-redux";
import { selectCompanyRequestApprovedList } from "Redux/CompanyRequests/slice";
import RequestList from "./List";
import { useGetRequestList } from "Shared/Hooks/request/use-get-request-list";

const RequestApprovedList: React.FC = () => {
  const requestsApprovedList = useSelector(selectCompanyRequestApprovedList);
  const { getCompanyApprovedRequests } = useGetRequestList();

  return (
    <RequestList
      filteredBy="active"
      requestsList={requestsApprovedList || []}
      getList={getCompanyApprovedRequests}
    />
  );
};
export default RequestApprovedList;
