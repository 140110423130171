import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "Service/api/api";
import { readObj } from "Service/storage";
import {
  IDispatchCallback,
  IDispatchCallbackWithId,
  IDispatchCallbackWithoutData,
} from "Shared/Types/common";
import {
  IPurchaseOrderAdd,
  IPurchaseOrderLineItemUpdate,
  IPurchaseOrderRecieved,
} from "Shared/Types/purchase-order";
import { errorHandler } from "Shared/Utils/app";

const getPurchaseOrderList = createAsyncThunk(
  "purchaseOrder/getPurchaseOrderList",
  async () => {
    try {
      const shop = readObj("current_shop");
      const response = await api.purchaseOrder.getPurchaseOrderList({
        shop: shop?.id,
      });
      return response.data;
    } catch (error) {
      if (error instanceof Error) {
        errorHandler(error);
      }
      throw error;
    }
  }
);

const getPurchaseOrder = createAsyncThunk(
  "purchaseOrder/getPurchaseOrder",
  async (id: string) => {
    try {
      const response = await api.purchaseOrder.getPurchaseOrder(id);
      return response.data;
    } catch (error) {
      if (error instanceof Error) {
        errorHandler(error);
      }
      throw error;
    }
  }
);

const addPurchaseOrder = createAsyncThunk(
  "purchaseOrder/addPurchaseOrder",
  async ({ data, onSuccess }: IDispatchCallback<IPurchaseOrderAdd>) => {
    try {
      const response = await api.purchaseOrder.addPurchaseOrder(data);
      onSuccess?.();
      return response.data;
    } catch (error) {
      if (error instanceof Error) {
        errorHandler(error, true);
      }
      throw error;
    }
  }
);

const deletePurchaseOrder = createAsyncThunk(
  "purchaseOrder/deletePurchaseOrder",
  async ({ id, onSuccess }: IDispatchCallbackWithoutData) => {
    try {
      const response = await api.purchaseOrder.deletePurchaseOrder(id);
      onSuccess?.();
      return response.data;
    } catch (error) {
      if (error instanceof Error) {
        errorHandler(error, true);
      }
      throw error;
    }
  }
);

const receivePurchaseOrder = createAsyncThunk(
  "purchaseOrder/receivePurchaseOrder",
  async ({
    id,
    onSuccess,
    data,
  }: IDispatchCallbackWithId<IPurchaseOrderRecieved>) => {
    try {
      const response = await api.purchaseOrder.receivePurchaseOrder(id, data);
      onSuccess?.();
      return response.data;
    } catch (error) {
      if (error instanceof Error) {
        errorHandler(error, true);
      }
      throw error;
    }
  }
);

const updatePurchaseOrderLineItem = createAsyncThunk(
  "purchaseOrder/updatePurchaseOrderLineItem",
  async ({
    id,
    data,
    onSuccess,
  }: IDispatchCallbackWithId<IPurchaseOrderLineItemUpdate>) => {
    try {
      const response = await api.purchaseOrder.updatePurchaseOrderLineItems(
        id,
        data
      );
      onSuccess?.();
      return response.data;
    } catch (error) {
      if (error instanceof Error) {
        errorHandler(error, true);
      }
      throw error;
    }
  }
);

export {
  getPurchaseOrderList,
  getPurchaseOrder,
  addPurchaseOrder,
  deletePurchaseOrder,
  receivePurchaseOrder,
  updatePurchaseOrderLineItem,
};
