import { StyleSheet } from "@react-pdf/renderer";

export const styles = StyleSheet.create({
  unitItem: {
    display: "flex",
    flexDirection: "row",
  },
  unitItemTitle: {
    fontWeight: "bold",
  },
});
