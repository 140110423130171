import React, { useEffect } from "react";
import cn from "classnames";
import styles from "Components/Order/FilterPanel/FilterPanel.module.scss";
import SearchFieldNew from "Components/Layout/Inputs/SearchField";
import SelectField from "Components/UI/SelectField/SelectField";
import AcynsSelectField from "Components/UI/AcyncSelect/AcyncSelect";
import {
  changeCustomerOptions,
  changeEmployeeOptions,
  changeLocationOptions,
  changeManufacturerOptions,
  changeSytemOptions,
  changeTypeOfChassisOptions,
  changeUnitOptions,
  changeVendorOptions,
} from "Shared/Utils/app";
import DateRange from "Components/UI/DateRange/DateRange";
import { useSelector } from "react-redux";
import {
  removeCurrentUnit,
  selectCurrentUnit,
  selectUnitList,
  selectUnitLoading,
} from "Redux/Unit/slice";
import {
  removeCurrentCustomer,
  selectCurrentCustomer,
  selectCustomerList,
  selectCustomerLoading,
} from "Redux/Customer/slice";
import {
  removeCurrentVendor,
  selectCurrentVendor,
  selectVendorList,
  selectVendorLoading,
} from "Redux/Vendor/slice";
import {
  removeCurrentUser,
  selectCurrentUser,
  selectUserList,
  selectUserLoading,
} from "Redux/User/userSlice";
import {
  removeCurrentSystem,
  selectCurrentSystem,
  selectSystemList,
  selectSystemLoading,
} from "Redux/System/systemSlice";
import {
  selectCurrentUnitType,
  selectUnitTypeList,
  selectUnitTypeLoading,
} from "Redux/UnitType/slice";
import { getUnit, getUnitsList } from "Redux/Unit/reducer";
import { getCustomer, getCustomerList } from "Redux/Customer/reducer";
import { getVendor, getVendorList } from "Redux/Vendor/reducer";
import { getUser, getUserList } from "Redux/User/reducer";
import { getSystem, getSystemList } from "Redux/System/reducers";
import { getUnitTypeList } from "Redux/UnitType/reducer";
import { useAppDispatch } from "Redux/store";
import { useLocation } from "react-router";
import { useSearchParams } from "react-router-dom";
import {
  removeCurrentManufacturer,
  selectCurrentManufacturer,
  selectManufacturerList,
  selectManufacturerLoading,
} from "Redux/PartSettings/Manufacturer/slice";
import {
  getManufacturer,
  getManufacturerList,
} from "Redux/PartSettings/Manufacturer/reducer";
import {
  removeCurrentLocation,
  selectCurrentLocation,
  selectLocationList,
  selectLocationLoading,
} from "Redux/PartSettings/Location/slice";
import {
  getLocation,
  getLocationList,
} from "Redux/PartSettings/Location/reducer";

interface FiltersProps {
  isFull: boolean;
  filters?: string[];
}

export const Filters: React.FC<FiltersProps> = ({ isFull, filters }) => {
  const unitList = useSelector(selectUnitList) || [];
  const customerList = useSelector(selectCustomerList) || [];
  const vendorList = useSelector(selectVendorList) || [];
  const employeeList = useSelector(selectUserList) || [];
  const systemList = useSelector(selectSystemList) || [];
  const unitTypeList = useSelector(selectUnitTypeList) || [];
  const manufacturerList = useSelector(selectManufacturerList) || [];
  const locationsList = useSelector(selectLocationList) || [];
  const unitLoading = useSelector(selectUnitLoading);
  const unitTypeLoading = useSelector(selectUnitTypeLoading);
  const customerLoading = useSelector(selectCustomerLoading);
  const vendorLoading = useSelector(selectVendorLoading);
  const employeeLoading = useSelector(selectUserLoading);
  const systemLoading = useSelector(selectSystemLoading);
  const manufacturerLoading = useSelector(selectManufacturerLoading);
  const locationsLoading = useSelector(selectLocationLoading);
  const currentUnit = useSelector(selectCurrentUnit);
  const currentCustomer = useSelector(selectCurrentCustomer);
  const currentEmployee = useSelector(selectCurrentUser);
  const currentVendor = useSelector(selectCurrentVendor);
  const currentUnitType = useSelector(selectCurrentUnitType);
  const currentSystem = useSelector(selectCurrentSystem);
  const currentManufacturer = useSelector(selectCurrentManufacturer);
  const currentLocation = useSelector(selectCurrentLocation);
  const [searchParams, setSearchParams] = useSearchParams();

  const dispatch = useAppDispatch();
  const location = useLocation();

  const changeSearch = (value: string) => {
    if (value) {
      searchParams.set("search", value);
    } else {
      searchParams.delete("search");
    }
    // setSearchText(value);
    setSearchParams(searchParams);
  };
  const changeSelect = (value: number | string | undefined, field: string) => {
    if (
      field === "shopStatus" ||
      field === "employeesStatus" ||
      field === "customersStatus" ||
      field === "partInventoryStatus"
    ) {
      if (!value) {
        searchParams.delete("status");
      } else {
        searchParams.set("status", "" + value);
      }
    }
    if (field === "category") {
      if (!value) {
        searchParams.delete("category");
      } else {
        searchParams.set("category", "" + value);
      }
    }
    if (field === "unit") {
      if (!value) {
        searchParams.delete("unit");
      } else {
        searchParams.set("unit", "" + value);
      }
    }
    if (field === "customer") {
      if (!value) {
        searchParams.delete("customer");
      } else {
        searchParams.set("customer", "" + value);
      }
    }
    if (field === "vendor") {
      if (!value) {
        searchParams.delete("vendor");
      } else {
        searchParams.set("vendor", "" + value);
      }
    }
    if (field === "employee") {
      if (!value) {
        searchParams.delete("employee");
      } else {
        searchParams.set("employee", "" + value);
      }
    }
    if (field === "type_of_chassis") {
      if (!value) {
        searchParams.delete("type_of_chassis");
      } else {
        searchParams.set("type_of_chassis", "" + value);
      }
    }
    if (field === "manufacturer") {
      if (!value) {
        searchParams.delete("manufacturer");
      } else {
        searchParams.set("manufacturer", "" + value);
      }
    }
    if (field === "location") {
      if (!value) {
        searchParams.delete("manufacturer");
      } else {
        searchParams.set("location", "" + value);
      }
    }
    setSearchParams(searchParams);
  };
  const inputChange = (text: string | null, field: string) => {
    if (field === "unit") {
      dispatch(getUnitsList({ search: text }));
      return;
    }
    if (field === "customer") {
      dispatch(getCustomerList({ search: text }));
      return;
    }
    if (field === "vendor") {
      dispatch(getVendorList({ search: text }));
      return;
    }
    if (field === "employee") {
      dispatch(getUserList({ search: text }));
      return;
    }
    if (field === "category") {
      dispatch(getSystemList({ search: text }));
      return;
    }
    if (field === "type_of_chassis") {
      dispatch(getUnitTypeList({ search: text }));
      return;
    }
    if (field === "manufacturer") {
      dispatch(getManufacturerList({}));
      return;
    }
    if (field === "location") {
      dispatch(getLocationList({}));
      return;
    }
  };
  const isFilterHave = (field: string) => {
    return filters?.includes(field);
  };

  useEffect(() => {
    const unitId = searchParams.get("unit");
    const customerId = searchParams.get("customer");
    const vendorId = searchParams.get("vendor");
    const employeeId = searchParams.get("employee");
    const systemId = searchParams.get("category");
    const manufacturerId = searchParams.get("manufacturer");
    const locationId = searchParams.get("location");
    if (unitId) {
      dispatch(getUnit(unitId));
    } else {
      dispatch(removeCurrentUnit());
    }
    if (customerId) {
      dispatch(getCustomer(customerId));
    } else {
      dispatch(removeCurrentCustomer());
    }
    if (vendorId) {
      dispatch(getVendor(vendorId));
    } else {
      dispatch(removeCurrentVendor());
    }
    if (employeeId) {
      dispatch(getUser(employeeId));
    } else if (!location.pathname.includes("employees")) {
      dispatch(removeCurrentUser());
    }
    if (systemId) {
      dispatch(getSystem(systemId));
    } else {
      dispatch(removeCurrentSystem());
    }
    if (manufacturerId) {
      dispatch(getManufacturer(manufacturerId));
    } else {
      dispatch(removeCurrentManufacturer());
    }
    if (locationId) {
      dispatch(getLocation(locationId));
    } else {
      dispatch(removeCurrentLocation());
    }
  }, []);

  return (
    <>
      {isFilterHave("search") && (
        <div
          className={cn(
            styles.filter__search,
            !isFull && styles.filter__search_30
          )}
        >
          <SearchFieldNew
            placeholder="Search"
            value={searchParams.get("search")}
            onChange={(event) => changeSearch(event.target.value)}
            fullWidth
          />
        </div>
      )}
      {isFilterHave("shopStatus") && (
        <div
          className={cn(styles.filter__cont, !isFull && styles.filter__cont_30)}
        >
          <SelectField
            isClearable
            name="shopStatus"
            options={[
              { label: "Active", value: "active" },
              { label: "Inactive", value: "inactive" },
            ]}
            onChange={(value) => changeSelect(value, "shopStatus")}
            theme="secondary"
            placeholder="Status"
          />
        </div>
      )}

      {isFilterHave("customersStatus") && (
        <div
          className={cn(styles.filter__cont, !isFull && styles.filter__cont_30)}
        >
          <SelectField
            isClearable
            name="customersStatus"
            options={[
              { label: "Active", value: "active" },
              { label: "Inactive", value: "inactive" },
            ]}
            onChange={(value) => changeSelect(value, "customersStatus")}
            theme="secondary"
            placeholder="Status"
          />
        </div>
      )}
      {isFilterHave("employeesStatus") && (
        <div
          className={cn(styles.filter__cont, !isFull && styles.filter__cont_30)}
        >
          <SelectField
            isClearable
            name="employeesStatus"
            options={[
              { label: "Active", value: "active" },
              { label: "Inactive", value: "inactive" },
            ]}
            onChange={(value) => changeSelect(value, "employeesStatus")}
            theme="secondary"
            placeholder="Status"
          />
        </div>
      )}
      {isFilterHave("partInventoryStatus") && (
        <div
          className={cn(styles.filter__cont, !isFull && styles.filter__cont_30)}
        >
          <SelectField
            isClearable
            name="partInventoryStatus"
            options={[
              { label: "Active", value: "active" },
              { label: "Inactive", value: "inactive" },
              { label: "Obsolete", value: "obsolete" },
              { label: "Low stock", value: "low_stock" },
              { label: "Out of stock", value: "out_of_stock" },
              { label: "Needs ordered", value: "needs_ordered" },
            ]}
            onChange={(value) => changeSelect(value, "partInventoryStatus")}
            theme="secondary"
            placeholder="Status"
          />
        </div>
      )}
      {isFilterHave("timespan") && (
        <div
          className={cn(styles.filter__cont, !isFull && styles.filter__cont_30)}
        >
          <SelectField
            isClearable
            name="timespan"
            options={[
              { label: "Today", value: "" },
              { label: "Yesterday", value: "" },
              { label: "This week", value: "" },
              { label: "Last week", value: "" },
              { label: "This month", value: "" },
            ]}
            onChange={(value) => changeSelect(value, "timespan")}
            theme="secondary"
            placeholder="Daily"
          />
        </div>
      )}

      {isFilterHave("category") && (
        <div
          className={cn(styles.filter__cont, !isFull && styles.filter__cont_30)}
        >
          <AcynsSelectField
            options={changeSytemOptions(systemList)}
            loading={systemLoading}
            placeholder="All categories"
            defaultValue={{
              label: currentSystem?.name || "",
              value: currentSystem?.id || "",
            }}
            onInputChange={(text) => inputChange(text, "category")}
            onChange={(value) => changeSelect(value, "category")}
            theme="secondary"
            containerClassName={styles.filter__item}
          />
        </div>
      )}
      {isFilterHave("unit") && (
        <div
          className={cn(styles.filter__cont, !isFull && styles.filter__cont_30)}
        >
          <AcynsSelectField
            options={changeUnitOptions(unitList)}
            loading={unitLoading}
            placeholder="All units"
            defaultValue={{
              label: currentUnit?.number || "",
              value: currentUnit?.id || "",
            }}
            onInputChange={(text) => inputChange(text, "unit")}
            onChange={(value) => changeSelect(value, "unit")}
            theme="secondary"
            containerClassName={styles.filter__item}
          />
        </div>
      )}
      {isFilterHave("customer") && (
        <div
          className={cn(styles.filter__cont, !isFull && styles.filter__cont_30)}
        >
          <AcynsSelectField
            options={changeCustomerOptions(customerList)}
            defaultValue={{
              label: currentCustomer?.company_name || "",
              value: currentCustomer?.id || "",
            }}
            loading={customerLoading}
            onInputChange={(text) => inputChange(text, "customer")}
            onChange={(value) => changeSelect(value, "customer")}
            theme="secondary"
            containerClassName={styles.filter__item}
            placeholder="All clients"
          />
        </div>
      )}
      {isFilterHave("typeOfChassis") && (
        <div
          className={cn(styles.filter__cont, !isFull && styles.filter__cont_30)}
        >
          <AcynsSelectField
            options={changeTypeOfChassisOptions(unitTypeList)}
            defaultValue={{
              label: currentUnitType?.name || "",
              value: currentUnitType?.id || "",
            }}
            loading={unitTypeLoading}
            onInputChange={(text) => inputChange(text, "type_of_chassis")}
            onChange={(value) => changeSelect(value, "type_of_chassis")}
            theme="secondary"
            containerClassName={styles.filter__item}
            placeholder="All type of chassis"
          />
        </div>
      )}
      {isFilterHave("manufacturer") && (
        <div
          className={cn(styles.filter__cont, !isFull && styles.filter__cont_30)}
        >
          <AcynsSelectField
            options={changeManufacturerOptions(manufacturerList)}
            defaultValue={{
              label: currentManufacturer?.name || "",
              value: currentManufacturer?.id || "",
            }}
            loading={manufacturerLoading}
            onInputChange={(text) => inputChange(text, "manufacturer")}
            onChange={(value) => changeSelect(value, "manufacturer")}
            theme="secondary"
            containerClassName={styles.filter__item}
            placeholder="All Manufacturers"
          />
        </div>
      )}
      {isFilterHave("vendor") && (
        <div
          className={cn(styles.filter__cont, !isFull && styles.filter__cont_30)}
        >
          <AcynsSelectField
            options={changeVendorOptions(vendorList)}
            defaultValue={{
              label: currentVendor?.vendor_name || "",
              value: currentVendor?.id || "",
            }}
            loading={vendorLoading}
            onInputChange={(text) => inputChange(text, "vendor")}
            onChange={(value) => changeSelect(value, "vendor")}
            theme="secondary"
            containerClassName={styles.filter__item}
            placeholder="All vendors"
          />
        </div>
      )}
      {isFilterHave("location") && (
        <div
          className={cn(styles.filter__cont, !isFull && styles.filter__cont_30)}
        >
          <AcynsSelectField
            options={changeLocationOptions(locationsList)}
            defaultValue={{
              label: currentLocation?.name || "",
              value: currentLocation?.id || "",
            }}
            loading={locationsLoading}
            onInputChange={(text) => inputChange(text, "location")}
            onChange={(value) => changeSelect(value, "location")}
            theme="secondary"
            containerClassName={styles.filter__item}
            placeholder="All locations"
          />
        </div>
      )}
      {isFilterHave("employee") && (
        <div
          className={cn(styles.filter__cont, !isFull && styles.filter__cont_30)}
        >
          <AcynsSelectField
            options={changeEmployeeOptions(employeeList)}
            defaultValue={{
              label:
                (currentEmployee?.first_name || "") +
                " " +
                (currentEmployee?.last_name || ""),
              value: currentEmployee?.id || "",
            }}
            loading={employeeLoading}
            onInputChange={(text) => inputChange(text, "employee")}
            onChange={(value) => changeSelect(value, "employee")}
            theme="secondary"
            containerClassName={styles.filter__item}
            placeholder="All employees"
          />
        </div>
      )}
      {isFilterHave("date") && (
        <div
          className={cn(styles.filter__date, !isFull && styles.filter__date_30)}
        >
          <DateRange />
        </div>
      )}
    </>
  );
};
