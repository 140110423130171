import React, { ChangeEvent, useEffect, useState } from "react";
import InputField from "Components/UI/Inputs/InputField";
import styles from "../ComponentSystemContainer.module.scss";
import Button from "Components/UI/Button/Button";
import { useAppDispatch } from "Redux/store";
import { useSelector } from "react-redux";
import { notifySuccess } from "Components/Notify/notify";
import {
  deleteComponent,
  getComponent,
  updateComponent,
} from "Redux/Component/reducers";
import {
  removeCurrentComponent,
  selectCurrentComponent,
} from "Redux/Component/componentSlice";
import Block from "Components/UI/Block/Block";
import { useLocation, useNavigate, useParams } from "react-router";
import IconButton from "Components/UI/IconButton/IconButton";
import { DeleteIcon, EditIcon, PlusIcon } from "Assets/index";
import System from "../System/System";
import SystemForm from "./SystemForm";
import StepProgress from "Components/UI/StepProgress/StepProgress";
import { selectAccount } from "Redux/Auth/slice";
import { checkFirstAuth } from "Shared/Utils/app";
import { stepDesc } from "Containers/ComponentSystem/Component/ComponentAdd";
import DeleteModal from "Components/UI/DialogWindow/DeleteModal";

const ComponentUpdate: React.FC = () => {
  const dispatch = useAppDispatch();
  const component = useSelector(selectCurrentComponent);
  const location = useLocation();
  const params = useParams();
  const [edit, setEdit] = useState(location?.state?.isEditComponent);
  const profile = useSelector(selectAccount);
  const isFirstAuth = checkFirstAuth(profile);
  const [isAddSystem, setIsAddSystem] = useState(
    location?.state?.addSystem || isFirstAuth
  );
  const [openDialog, setOpenDialog] = useState(false);
  const handleDialog = () => setOpenDialog(!openDialog);
  const navigate = useNavigate();
  const id = component?.component.id;

  const [componentValue, setComponentValue] = useState(
    component?.component.name || ""
  );
  const handlePreventOpenAccordion = (event: React.MouseEvent) => {
    event.stopPropagation();
  };
  const onChangeValue = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setComponentValue(e.target.value);
  };

  const onUpdateBack = async (back?: boolean) => {
    const onSuccess = () => {
      if (back) {
        navigate("/settings/component-system");
      } else {
        setEdit(false);
        params.id && dispatch(getComponent(params.id));
      }
      notifySuccess("Component updated");
    };

    if (component?.component.id) {
      const data = {
        id: component?.component.id,
        name: componentValue,
      };
      await dispatch(updateComponent({ id: data.id, data, onSuccess }));
    }
  };

  const onDelete = async (e: React.MouseEvent) => {
    const onSuccess = () => {
      notifySuccess("Component deleted");
      navigate(-1);
      handleDialog();
    };
    if (id != null) {
      handlePreventOpenAccordion(e);
      await dispatch(deleteComponent({ id, onSuccess }));
    }
  };

  const onClickDeleteButton = (e: React.MouseEvent) => {
    setIsAddSystem(false);

    handlePreventOpenAccordion(e);
    setOpenDialog(!openDialog);
  };
  const onClickUpdateButton = () => {
    setIsAddSystem(false);
    setEdit(!edit);
  };

  useEffect(() => {
    setComponentValue(component?.component.name || "");
  }, [component]);

  useEffect(() => {
    if (params.id) {
      dispatch(getComponent(params.id));
    }

    return () => {
      dispatch(removeCurrentComponent());
    };
  }, [params.id]);

  return (
    <>
      {isFirstAuth && (
        <StepProgress
          description={stepDesc}
          active={3}
          title="Creating Services"
        />
      )}

      <Block className={styles.component}>
        {isFirstAuth && (
          <p className={styles.component__add__title}>Component</p>
        )}
        {edit ? (
          <InputField
            value={componentValue}
            onChange={onChangeValue}
            label="Component name"
            name="name"
          />
        ) : (
          <div className={styles.component__head}>
            <div style={isFirstAuth ? { marginLeft: "12px" } : undefined}>
              {component?.component.name}
            </div>
            {!isFirstAuth && (
              <div className={styles.component__head__innerAction}>
                <IconButton
                  width={16}
                  height={16}
                  onClick={() => setIsAddSystem(true)}
                  icon={PlusIcon}
                />
                <IconButton
                  width={16}
                  height={16}
                  onClick={onClickDeleteButton}
                  icon={DeleteIcon}
                />
                <IconButton
                  width={16}
                  height={16}
                  onClick={onClickUpdateButton}
                  icon={EditIcon}
                />
              </div>
            )}
          </div>
        )}
        {component?.systems.map((system) => (
          <System
            setShowComponentEdit={setEdit}
            setAddShow={setIsAddSystem}
            system={system}
            key={system.id}
            componentEdit={edit}
            componentId={component.component.id}
            isInDetail
          />
        ))}
        {isAddSystem && component && (
          <SystemForm
            componentId={component?.component.id}
            setShow={setIsAddSystem}
          />
        )}
        {edit && (
          <div className={styles.component__add__action}>
            <Button variant="outlined" onClick={() => onUpdateBack()}>
              Continue
            </Button>
            <Button variant="contained" onClick={() => onUpdateBack(true)}>
              Update & Back
            </Button>
          </div>
        )}
      </Block>
      <DeleteModal
        acceptTitle="Delete"
        open={openDialog}
        handleClose={onClickDeleteButton}
        title="Delete"
        text="Are you sure...?"
        accept={onDelete}
      />
    </>
  );
};

export default ComponentUpdate;
