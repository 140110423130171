import React, { useEffect, useState } from "react";
import styles from "Components/Order/ServiceOrderForms/ServiceOrderAdd.module.scss";
import InputField from "Components/UI/Inputs/InputField";
import DateTimeCalendarBlue from "./CalendarForSchedule/CalendarBlue/DateTimeCalendarBlue";
import DateTimeCalendar from "./CalendarForSchedule/CalendarOrange/DateTimeCalendar";
import DateTimeCalendarRed from "./CalendarForSchedule/CalendarRed/DateTimeCalendarRed";
import Form from "Components/UI/Form/Form";
import Button from "Components/UI/Button/Button";
import { Link } from "react-router-dom";
import Icon from "Components/UI/Icon/Icon";
import { CloseIcon } from "Assets/index";
import { clear, readObj, write } from "Service/storage";
import { useFormContext } from "react-hook-form";

type SchedulingAccessInfoProps = {
  setFormStep: (step: number) => void;
  saveOrderValues: () => void;
};

const SchedulingAccessInfo: React.FC<SchedulingAccessInfoProps> = ({
  saveOrderValues,
  setFormStep,
}) => {
  const [calendarType, setCalendarType] = useState<string>("orange");

  const { setValue } = useFormContext();
  const localSOValues = readObj("created_service_order");
  useEffect(() => {
    if (localSOValues?.availability_date) {
      setValue("availability_date", localSOValues.availability_date);
    }
    if (localSOValues?.finish_date) {
      setValue("finish_date", localSOValues.finish_date);
    }
    if (localSOValues?.start_date) {
      setValue("start_date", localSOValues.start_date);
    }
  }, []);

  return (
    <Form
      onSubmit={(e) => {
        e.preventDefault();
        if (saveOrderValues) saveOrderValues();
        setFormStep(4);
        write("formStep", "4");
      }}
      className={styles.form_section}
    >
      <div className={styles.form_section__title}>
        <p className={styles.title}>Scheduling & Access</p>
        <Link onClick={() => clear("created_service_order")} to="/orders">
          <Icon component={CloseIcon} />
        </Link>
      </div>
      <div style={{ gap: "8px" }} className={styles.form_section__inputs}>
        <div onClick={() => setCalendarType("orange")}>
          <InputField
            disabled={calendarType === "orange"}
            withController
            borderColor="#FF800C"
            labelColor="#FF800C"
            name="availability_date"
            label="Availability of the unit?"
          />
        </div>
        <div onClick={() => setCalendarType("red")}>
          <InputField
            disabled={calendarType === "red"}
            withController
            borderColor="#D30405"
            labelColor="#D30405"
            name="finish_date"
            label="When would you like it back?"
          />
        </div>
        <div onClick={() => setCalendarType("blue")}>
          <InputField
            disabled={calendarType === "blue"}
            withController
            borderColor="#007DF2"
            labelColor="#007DF2"
            name="start_date"
            label="When would you like to start work?"
          />
        </div>
      </div>

      <div style={{ marginTop: "30px" }}>
        {calendarType === "red" && <DateTimeCalendarRed />}
        {calendarType === "orange" && <DateTimeCalendar />}
        {calendarType === "blue" && <DateTimeCalendarBlue />}
      </div>
      <div className={styles.prev_next_buttons}>
        <Button
          color="secondary"
          onClick={() => {
            if (saveOrderValues) {
              saveOrderValues();
            }
            setFormStep(2);
            write("formStep", "2");
          }}
        >
          Previous
        </Button>
        <Button type="submit">Next</Button>
      </div>
    </Form>
  );
};

export default SchedulingAccessInfo;
