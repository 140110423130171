import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { IUnit } from "Shared/Types/unit";
import {
  getUnitsList,
  getUnit,
  addUnit,
  updateUnit,
  deleteUnit,
} from "./reducer";

interface IUnitState {
  unitsList?: IUnit[];
  currentUnit?: IUnit | null;
  loading: boolean;
  numPage?: number;
}

const initialState: IUnitState = {
  unitsList: [],
  currentUnit: null,
  loading: false,
};

const unitSlice = createSlice({
  name: "unit",
  initialState,
  reducers: {
    removeCurrentUnit(state) {
      state.currentUnit = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getUnitsList.pending, (state) => {
      state.loading = true;
      state.unitsList = [];
    });
    builder.addCase(getUnitsList.rejected, (state) => {
      state.loading = false;
      state.unitsList = [];
    });
    builder.addCase(getUnitsList.fulfilled, (state, action) => {
      state.loading = false;
      state.unitsList = action.payload.results;
      state.numPage = action.payload.num_pages;
    });
    builder.addCase(getUnit.pending, (state) => {
      state.loading = true;
      state.currentUnit = null;
    });
    builder.addCase(getUnit.rejected, (state) => {
      state.loading = false;
      state.currentUnit = null;
    });
    builder.addCase(getUnit.fulfilled, (state, action) => {
      state.loading = false;
      state.currentUnit = action.payload;
    });

    builder.addCase(addUnit.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(addUnit.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(addUnit.fulfilled, (state) => {
      state.loading = false;
    });

    builder.addCase(updateUnit.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateUnit.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(updateUnit.fulfilled, (state) => {
      state.loading = false;
    });

    builder.addCase(deleteUnit.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(deleteUnit.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(deleteUnit.fulfilled, (state) => {
      state.loading = false;
    });
  },
});

export const { removeCurrentUnit } = unitSlice.actions;

export const selectCurrentUnit = (state: RootState) => state.unit.currentUnit;
export const selectUnitLoading = (state: RootState) => state.unit.loading;
export const selectUnitList = (state: RootState) => state.unit.unitsList;
export const select = (state: RootState) => state.unit.unitsList;

export default unitSlice;
