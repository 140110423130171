import React from "react";
import cn from "classnames";
import {
  checkPossibityInvoice,
  getActoinSendLabel,
  getChangedFields,
  isMoreButton,
  isNeedSave,
} from "Shared/Utils/order";
import styles from "./ActionItemButtons.module.scss";
import { IActionItem, IActionItemUpdate } from "Shared/Types/order";
import Button from "Components/UI/Button/Button";
import { useAppDispatch } from "Redux/store";
import { useSelector } from "react-redux";
import {
  selectActionItemList,
  selectActionItemLoading,
  selectActionItemStatusList,
} from "Redux/ActionItem/slice";
import { updateActionItem } from "Redux/ActionItem/reducer";
import CountRepairtime from "./CountRepairTime/CountRepairtime";
import { selectCurrentOrder } from "Redux/Order/slice";
import { checkActionItemNeedCalcTime } from "Shared/Utils/action-item";
import { notifySuccess } from "Components/Notify/notify";
import { getOrder } from "Redux/Order/reducer";

type ActionItemButtonsProps = {
  currentActionItem: IActionItem;
  setActiveTab?: (tab: number) => void;
  pushInvoice: () => void;
  index: number;
};

const ActionItemButtons: React.FC<ActionItemButtonsProps> = ({
  currentActionItem,
  setActiveTab,
  index,
  pushInvoice,
}) => {
  const dispatch = useAppDispatch();
  const statusList = useSelector(selectActionItemStatusList) || [];
  const order = useSelector(selectCurrentOrder);
  const actionItems = useSelector(selectActionItemList);
  const loading = useSelector(selectActionItemLoading);
  const onSuccess = () => {
    notifySuccess("Action item is updated");
    if (order) {
      dispatch(getOrder(order.id));
    }
  };
  const onSubmit = () => {
    const values: IActionItemUpdate = {
      ...currentActionItem,
      status: currentActionItem?.status?.id,
    };

    if (currentActionItem?.id && currentActionItem?.status?.queue === 7) {
      const statusId = statusList.find((stat) => stat.queue === 8)?.id;
      dispatch(
        updateActionItem({
          id: currentActionItem.id,
          data: { status: statusId },
          onSuccess,
        })
      );
      return;
    }

    if (currentActionItem?.status?.queue === 8) {
      const check = checkPossibityInvoice(actionItems || [], order);
      if (check) {
        pushInvoice();
        setActiveTab?.(3);
        return;
      }
      return;
    }

    if (currentActionItem) {
      dispatch(
        updateActionItem({
          id: currentActionItem.id,
          data: getChangedFields(
            { ...currentActionItem, status: currentActionItem.status?.id },
            values
          ),
          onSuccess,
        })
      );
    }
  };

  const authorize = () => {
    setActiveTab?.(1);
  };
  return (
    <div
      className={cn(
        styles.send,
        isMoreButton(currentActionItem?.status?.queue) && styles.send_between
      )}
    >
      {isNeedSave(currentActionItem.status?.queue) && (
        <Button
          type="button"
          disabled={loading}
          color="success"
          onClick={onSubmit}
        >
          {getActoinSendLabel(currentActionItem.status?.queue, index)}
        </Button>
      )}
      {currentActionItem.status?.queue === 4 && (
        <Button onClick={authorize}>Authorize</Button>
      )}
      {checkActionItemNeedCalcTime(currentActionItem.status?.queue) && (
        <CountRepairtime currentActionItem={currentActionItem} index={index} />
      )}
    </div>
  );
};
export default ActionItemButtons;
