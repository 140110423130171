import React, { useEffect } from "react";
import { Grid } from "@mui/material";
import SelectOrTextField from "Components/UI/SelectField/SelectOrTextField";
import InputField from "Components/UI/Inputs/InputField";
import { idRegex } from "Shared/Constants/estimates";
import { getPart, getPartList } from "Redux/Product/reducer";
import { IPart } from "Shared/Types/part";
import { notifyError } from "Components/Notify/notify";
import { useSelector } from "react-redux";
import { selectPartList } from "Redux/Product/slice";
import { readObj } from "Service/storage";
import { useAppDispatch } from "Redux/store";
import { useFormContext } from "react-hook-form";

type ComplaintPartInputFieldsProps = {
  complaintIndex: number;
  partIndex: number;
  isAddedPart?: boolean;
  isEdit: boolean;
};

const ComplaintPartInputFields: React.FC<ComplaintPartInputFieldsProps> = ({
  complaintIndex,
  partIndex,
  isAddedPart,
  isEdit,
}) => {
  const partsList = useSelector(selectPartList) || [];
  const shop = readObj("current_shop");
  const dispatch = useAppDispatch();

  const { control, setValue, watch } = useFormContext();
  const currentService = watch(`action_items.${complaintIndex}.service.id`);

  const fetchCurrentPart = async (id?: string) => {
    try {
      if (!id || !idRegex.test(id)) return;
      const res = await dispatch(getPart(id));
      const currentPart = res.payload as IPart;
      setPartValues(currentPart);
    } catch (error) {
      if (typeof error === "string") notifyError(error);
    }
  };

  const setPartValues = (part: IPart) => {
    const {
      id = "",
      description = "",
      part_number = 0,
      selling_price = 0,
      quantity = 0,
      price = 0,
    } = part;
    const baseKey = `action_items.${complaintIndex}.part.${partIndex}`;

    setValue(`${baseKey}.id`, id);
    setValue(`${baseKey}.description`, description);
    setValue(`${baseKey}.part_number`, part_number);
    setValue(`${baseKey}.selling_price`, selling_price);
    setValue(`${baseKey}.quantity`, quantity);
    setValue(`${baseKey}.cost`, price);
  };

  const handleOnBlurValue = async (value?: string) => {
    if (value && !idRegex.test(value)) {
      setValue(
        `action_items.${complaintIndex}.part.${partIndex}.description`,
        value
      );
    }
  };

  useEffect(() => {
    setValue(
      `action_items.${complaintIndex}.part.${partIndex}.service`,
      currentService
    );
    setValue(`action_items.${complaintIndex}.part.${partIndex}.shop`, shop.id);
  }, [shop.id, currentService]);

  useEffect(() => {
    dispatch(getPartList({}));
  }, []);

  return (
    <>
      <Grid
        container
        item
        display={"flex"}
        alignItems={"center"}
        xs={4}
        spacing={2}
      >
        <Grid item xs={8}>
          <SelectOrTextField
            name={`action_items.${complaintIndex}.part.${partIndex}.description`}
            label="Part Description"
            options={partsList.map((part) => ({
              label: `${part.description} - ${part.part_number}`,
              value: part.id,
            }))}
            onChange={(value) => fetchCurrentPart(value?.toString())}
            onBlur={(value) => handleOnBlurValue(value?.toString())}
            isDisabled={isAddedPart && !isEdit}
          />
        </Grid>

        <Grid item xs={4}>
          <InputField
            name={`action_items.${complaintIndex}.part.${partIndex}.part_number`}
            label="#"
            withController
            control={control}
            disabled={isAddedPart && !isEdit}
          />
        </Grid>
      </Grid>
      <Grid item xs={1}>
        <InputField
          name={`action_items.${complaintIndex}.part.${partIndex}.quantity`}
          label="Qty"
          withController
          control={control}
          type="number"
          validatePositiveValue
          disabled={isAddedPart && !isEdit}
        />
      </Grid>
      <Grid item xs={2}>
        <InputField
          name={`action_items.${complaintIndex}.part.${partIndex}.cost`}
          label="Price ($)"
          withController
          control={control}
          type="number"
          validatePositiveValue
          disabled={isAddedPart && !isEdit}
        />
      </Grid>
      <Grid item xs={2}>
        <InputField
          name={`action_items.${complaintIndex}.part.${partIndex}.selling_price`}
          label="Selling price ($)"
          withController
          control={control}
          type="number"
          validatePositiveValue
          disabled={isAddedPart && !isEdit}
        />
      </Grid>
    </>
  );
};

export default ComplaintPartInputFields;
