import React, { useState } from "react";
import { notifySuccess } from "Components/Notify/notify";
import PartForm from "Components/Part/Form/PartForm";
import Modal from "Components/UI/Modal/Modal";
import { selectAccount } from "Redux/Auth/slice";
import { createPart } from "Redux/Product/reducer";
import { useAppDispatch } from "Redux/store";
import { IPartAdd } from "Shared/Types/part";
import { partSchema } from "Shared/Utils/part";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import styles from "./PartAdd.module.scss";
import Icon from "Components/UI/Icon/Icon";
import { LogoTitleIcon, SideBarLogoIcon } from "Assets/index";
import Button from "Components/UI/Button/Button";
import { updateUserField } from "Redux/User/reducer";
import { checkFirstAuth } from "Shared/Utils/app";

const PartAdd: React.FC = () => {
  const dispatch = useAppDispatch();
  const profile = useSelector(selectAccount);
  const navigate = useNavigate();
  const location = useLocation();
  const isFristAuth = checkFirstAuth(profile);
  const [openModal, setOpenModal] = useState(false);

  const onSkip = () => {
    setOpenModal(true);
  };

  const createNewPart = (values: IPartAdd) => {
    const onSuccess = () => {
      notifySuccess("Part created");
      if (isFristAuth) {
        onSkip();
      } else if (location.state) {
        navigate(-1);
      } else {
        navigate("/parts/inventory");
      }
    };
    dispatch(createPart({ data: values, onSuccess }));
  };

  const goHome = () => {
    if (profile) {
      dispatch(
        updateUserField({
          id: profile.id,
          data: { is_first_auth: false },
          onSuccess: () => {
            navigate("/");
          },
        })
      );
    }
  };

  return (
    <div>
      {!openModal && (
        <PartForm
          onSubmit={createNewPart}
          schema={partSchema}
          onSkip={onSkip}
          isFirstAuth={isFristAuth}
        />
      )}
      <Modal open={openModal}>
        <div className={styles.home__progress}>
          <div className={styles.home__progress__head}>
            <Icon
              component={SideBarLogoIcon}
              alt="Easy Shop"
              fontSize="large"
            />
            <Icon component={LogoTitleIcon} />
          </div>
          <div className={styles.home__progress__description}>
            Congratulations! You&apos;ve completed the setup process. Your Easy
            Shop Platform is now configured to efficiently manage your repair
            shop operations.
          </div>
          <div className={styles.home__progress__description}>
            If you have any questions, refer to our comprehensive help
            documentation or reach out to our <span>Support team</span>.
          </div>
          <div className={styles.home__progress__action}>
            <Button onClick={goHome}>Let&apos;s go</Button>
          </div>
        </div>
      </Modal>
    </div>
  );
};
export default PartAdd;
