import React from "react";
import {
  Divider as MUIDivider,
  DividerProps as MUIDividerProps,
} from "@mui/material";
import styles from "./Divider.module.scss";
import cn from "classnames";
type DividerPropss = MUIDividerProps & {
  mt?: number;
  mb?: number;
};

const Divider: React.FC<DividerPropss> = ({
  color = "primary",
  mt,
  mb,
  ...props
}) => {
  return (
    <MUIDivider
      className={cn(styles.divider, styles[`divider__${color}`])}
      style={{ marginTop: `${mt}px`, marginBottom: `${mb}px` }}
      {...props}
    />
  );
};
export default Divider;
