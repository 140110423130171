import React from "react";
import { Grid } from "@mui/material";
import InputField from "Components/UI/Inputs/InputField";
import { Control } from "react-hook-form";
import { IVendorForm } from "./VendorForm";
import Checkbox from "Components/UI/Inputs/Checkbox";

type BasicInfoProps = {
  control: Control<IVendorForm>;
  active: boolean;
};

const BasicInfo: React.FC<BasicInfoProps> = ({ control, active }) => {
  return (
    <div>
      <h4>Basic Info</h4>
      <Grid container spacing={1} rowSpacing={-1}>
        <Grid item xs={12}>
          <Checkbox
            label="Active"
            name="is_active"
            defaultChecked={active}
            control={control}
            withController
          />
        </Grid>

        <Grid item xs={12}>
          <InputField
            name="vendor_name"
            label="Vendor name"
            required
            withController
            control={control}
          />
        </Grid>
        <Grid item xs={12}>
          <InputField
            name="first_name"
            label="First name"
            required
            withController
            control={control}
          />
        </Grid>

        <Grid item xs={12}>
          <InputField
            name="last_name"
            label="Last name"
            required
            withController
            control={control}
          />
        </Grid>

        <Grid item xs={12}>
          <InputField
            name="email"
            label="Email"
            required
            withController
            control={control}
          />
        </Grid>

        <Grid item xs={12}>
          <InputField
            name="phone"
            label="Phone number"
            required
            withController
            control={control}
          />
        </Grid>
        <Grid item xs={12}>
          <InputField
            name="cell_phone"
            label="Cell phone"
            required
            withController
            control={control}
          />
        </Grid>
      </Grid>
    </div>
  );
};
export default BasicInfo;
