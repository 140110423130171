import React, { useEffect } from "react";
import styles from "./UnitUpdate.module.scss";
import UnitForm from "Components/Unit/UnitForm/UnitForm";
import { useAppDispatch } from "Redux/store";
import { useSelector } from "react-redux";
import { selectCurrentUnit } from "Redux/Unit/slice";
import { getUnit, updateUnit } from "Redux/Unit/reducer";
import { IUnitUpdate } from "Shared/Types/unit";
import { notifySuccess } from "Components/Notify/notify";
import { getInitialValue, unitSchema } from "Shared/Utils/units";
import { useNavigate, useParams } from "react-router";

const UnitUpdate: React.FC = () => {
  const dispatch = useAppDispatch();
  const unit = useSelector(selectCurrentUnit);
  const params = useParams();
  const navigate = useNavigate();

  const editUnit = (values: IUnitUpdate) => {
    const onSuccess = () => {
      notifySuccess("Unit updated");
      navigate("/units");
    };
    if (unit) {
      dispatch(updateUnit({ id: unit.id, data: values, onSuccess }));
    }
  };

  useEffect(() => {
    if (params.id) {
      dispatch(getUnit(params.id));
    }
  }, [params.id]);

  return (
    <div className={styles.unitUpdate}>
      <h3>Update a unit</h3>
      <div className={styles.unitUpdate__form}>
        {unit && (
          <UnitForm
            onSubmit={editUnit}
            schema={unitSchema}
            unit={getInitialValue(unit)}
          />
        )}
      </div>
    </div>
  );
};
export default UnitUpdate;
