import React from "react";
import { BreadcrumbItem } from "Components/Breadcrumb/Breadcrumb";
import { useBreadcrumb } from "Shared/Hooks/use-breadcrumb";
import RequestApprovedList from "Containers/Requests/List/RequestApprovedList";

export function RequestsApprove() {
  const breads: BreadcrumbItem[] = [
    { title: "Requests", link: "/requests" },
    { title: "Approved", link: "approve" },
  ];

  useBreadcrumb({ links: breads });

  return <RequestApprovedList />;
}
