import React from "react";
import styles from "./CategoryAdd.module.scss";
import { useLocation, useNavigate } from "react-router";
import { useAppDispatch } from "Redux/store";
import { useSelector } from "react-redux";
import { selectAccount } from "Redux/Auth/slice";
import { notifyError, notifySuccess } from "Components/Notify/notify";
import StepProgress from "Components/UI/StepProgress/StepProgress";
import { ICategoryAdd } from "Shared/Types/category";
import { addCategory } from "Redux/PartSettings/Categories/reducer";
import CategoriesForm from "Components/Settings/PartSettings/Categories/CategoriesForm";
import { categorySchema } from "Shared/Utils/part-settings";
import { checkFirstAuth } from "Shared/Utils/app";
import { readObj } from "Service/storage";

export const partSettingStep = (
  <>
    <p>Now configure the following Part Settings:</p>
    <ol>
      <li>
        Part Settings&nbsp;&nbsp; <span>a)</span>Category&nbsp;&nbsp;
        <span>b)</span>Manufacturer&nbsp;&nbsp;
        <span>c)</span>Location&nbsp;&nbsp;
        <span>d)</span>Part Type
      </li>
    </ol>
  </>
);
const CategoryAdd: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const profile = useSelector(selectAccount);
  const isFirstAuth = checkFirstAuth(profile);

  const onSkip = () => {
    navigate("/settings/part-settings/manufactures/new");
  };
  const onSuccess = () => {
    if (isFirstAuth) {
      onSkip();
    } else if (location.state) {
      navigate(-1);
    } else {
      navigate("/settings/part-settings/categories");
    }

    notifySuccess("Category created");
  };
  const addNewCategory = (values: ICategoryAdd) => {
    const shop = readObj("current_shop");
    if (!shop) {
      notifyError("You can't create service, because you don't assign to shop");
      return;
    }
    dispatch(addCategory({ data: { ...values, shop: shop.id }, onSuccess }));
  };

  return (
    <div>
      {isFirstAuth && (
        <StepProgress
          description={partSettingStep}
          title="Part Settings"
          active={7}
        />
      )}
      <div className={styles.category}>
        <CategoriesForm
          onSubmit={addNewCategory}
          schema={categorySchema}
          onSkip={onSkip}
          isFirstAuth={isFirstAuth}
        />
      </div>
    </div>
  );
};
export default CategoryAdd;
