import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "Service/api/api";
import {
  IDispatchCallback,
  IDispatchCallbackWithoutData,
  IDispatchCallbackWithId,
} from "Shared/Types/common";
import { IRoleSearchParams, IRolesAdd, IRolesUpdate } from "Shared/Types/role";
import { errorHandler } from "Shared/Utils/app";

const getRolesList = createAsyncThunk(
  "role/getRolesList",
  async (params: IRoleSearchParams | null) => {
    try {
      const response = await api.role.getRolesList(params);
      return response.data;
    } catch (error) {
      if (error instanceof Error) {
        errorHandler(error);
      }
      throw error;
    }
  }
);

const getRole = createAsyncThunk("role/getRole", async (id: string) => {
  try {
    const response = await api.role.getRole(id);
    return response.data;
  } catch (error) {
    if (error instanceof Error) {
      errorHandler(error);
    }
    throw error;
  }
});

const addRole = createAsyncThunk(
  "role/addRole",
  async ({ data, onSuccess }: IDispatchCallback<IRolesAdd>) => {
    try {
      const response = await api.role.addRole(data);
      onSuccess?.();
      return response.data;
    } catch (error) {
      if (error instanceof Error) {
        errorHandler(error, true);
      }
      throw error;
    }
  }
);

const updateRole = createAsyncThunk(
  "role/updateRole",
  async ({ id, data, onSuccess }: IDispatchCallbackWithId<IRolesUpdate>) => {
    try {
      const response = await api.role.updateRole(id, data);
      onSuccess?.();
      return response.data;
    } catch (error) {
      if (error instanceof Error) {
        errorHandler(error, true);
      }
      throw error;
    }
  }
);

const deleteRole = createAsyncThunk(
  "role/deleteRole",
  async ({ id, onSuccess }: IDispatchCallbackWithoutData) => {
    try {
      const response = await api.role.deleteRole(id);
      onSuccess?.();
      return response.data;
    } catch (error) {
      if (error instanceof Error) {
        errorHandler(error, true);
      }
      throw error;
    }
  }
);

const getPermissions = createAsyncThunk("role/getPermissions", async () => {
  try {
    const response = await api.role.getPermissions();
    return response.data;
  } catch (error) {
    if (error instanceof Error) {
      errorHandler(error);
    }
    throw error;
  }
});

export {
  getRolesList,
  getRole,
  addRole,
  updateRole,
  deleteRole,
  getPermissions,
};
