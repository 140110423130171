import React, { useEffect, useMemo, useState } from "react";
import styles from "./Header.module.scss";
import Icon from "Components/UI/Icon/Icon";
import {
  ArrowDownIcon,
  LogoTitleIcon,
  MessageIcon,
  MoonStartsIcon,
} from "Assets/index";
import Profile from "Components/Headers/Profile/Profile";
import ProfileInfoModal from "Components/Headers/Profile/ProfileInfoModal";
import { read } from "Service/storage";
import GlobalSearch from "Components/GlobalSearch/GlobalSearch";
import Notification from "./Notifcation/Notifcation";
import ClockIn from "Components/Sidebar/ClockIn";
import { useSelector } from "react-redux";
import { selectAccount } from "Redux/Auth/slice";
import { selectShopList } from "Redux/Shop/slice";
import { useAppDispatch } from "Redux/store";
import { getShopList } from "Redux/Shop/reducer";
import { getShortValue } from "Shared/Utils/order";

const Header = () => {
  const dispatch = useAppDispatch();
  const [profileShow, setProfileShow] = useState<boolean>(false);
  const [modal, setModal] = useState<boolean>(false);
  const account = useSelector(selectAccount);
  const currentShopName = read("current_shop_name");
  const shops = useSelector(selectShopList) || [];

  const shopName = useMemo(() => {
    if (account?.is_superuser) {
      return "Easy Shop";
    }
    if (account?.is_company_admin) {
      if (currentShopName) return currentShopName;
      return (
        shops[0]?.shop_name || account?.company?.legal_name || "Your business"
      );
    }

    return currentShopName || "Your business";
  }, [shops, account, currentShopName]);

  const openProfile = () => {
    setProfileShow(true);
  };

  const closeProfile = () => {
    setProfileShow(false);
  };

  const toggleModal = () => {
    setModal(!modal);
  };
  useEffect(() => {
    if (account?.is_company_admin) {
      dispatch(getShopList(null));
    }
  }, []);

  return (
    <div className={styles.header}>
      <div className={styles.header__clockIn_cont}>
        <Icon component={LogoTitleIcon} />
        <ClockIn />
      </div>
      <GlobalSearch />
      <div className={styles.options}>
        <Notification />
        <div className={styles.icon}>
          <Icon color="transparent" component={MoonStartsIcon} />
        </div>
        <div className={styles.icon}>
          <Icon color="transparent" component={MessageIcon} />
        </div>
        <div
          onClick={(e) => {
            e.stopPropagation();
            openProfile();
          }}
          className={styles.icon_avatar}
        >
          <span className={styles.icon_avatar__text}>
            {getShortValue(shopName, 15)}
            <Icon component={ArrowDownIcon} color="transparent" />
          </span>
        </div>
      </div>
      {profileShow && (
        <Profile
          profile={profileShow}
          closeProfile={closeProfile}
          openModal={toggleModal}
        />
      )}
      <ProfileInfoModal open={modal} closeModal={toggleModal} />
    </div>
  );
};

export default Header;
