import React from 'react';
import { Table as MuiTable, TableHead, TableRow, TableCell, TableBody } from '@mui/material';
import styles from './ProfileTable.module.scss'
import { profileData } from 'Shared/Constants/profile';

const ProfileTable = () => {
  return (
    <div className={styles.container}>
      <MuiTable  className={styles.table}>
        <TableHead>
          <TableRow>
          <TableCell>Schedule</TableCell>
            {profileData.dates.map((date) => (
              <TableCell key={date}>{date}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
        {profileData.stats.map((stat) => {
            const valuesWithPlaceholders = new Array(6).fill('');
            stat.values.forEach((value, index) => {
              if (value !== undefined && index < valuesWithPlaceholders.length) {
                valuesWithPlaceholders[index] = value;
              }
            });

            return (
              <TableRow key={stat.label}>
                <TableCell component="th" scope="row">
                  {stat.label}
                </TableCell>
                {valuesWithPlaceholders.map((value, index) => (
                  <TableCell key={index}>{value}</TableCell>
                ))}
                
              </TableRow>
            );
          })}
        </TableBody>
      </MuiTable>
    </div>
  );
};

export default ProfileTable;


