import { StyleSheet } from "@react-pdf/renderer";

export const styles = StyleSheet.create({
  container: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: 10,
    marginTop: 50,
  },
  block: {
    width: "30%",
  },
  item: {
    display: "flex",
    flexDirection: "row",
  },
  itemTitle: {
    fontWeight: "bold",
  },
});
