import React, { useEffect } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { Controller, useFormContext } from "react-hook-form";
import { useSelector } from "react-redux";
import { useAppDispatch } from "Redux/store";
import styles from "./AutoCompleteSearchInput.module.scss";
import { selectContactList } from "Redux/Contact/slice";
import { getContactList } from "Redux/Contact/reducer";
import { IContact } from "Shared/Types/contact";
import { componentsProps } from "./LaborRateSearch";
import { useNavigate } from "react-router";
import { ArrowDownBlackIcon } from "Assets/index";

interface SearchProps {
  name: string;
  required?: boolean;
  label?: string;
  customerId?: string;
  disabled?: boolean;
  beforeCreateNew?: () => void;
  isAdd?: boolean;
}

export const ContactSearch: React.FC<SearchProps> = ({
  name,
  required,
  label,
  customerId,
  disabled,
  isAdd,
  beforeCreateNew,
}) => {
  const { control } = useFormContext();
  const contacts = useSelector(selectContactList) || [];
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const searchContacts = (text: string | null) => {
    dispatch(
      getContactList({
        search: text || null,
        customer: customerId,
      })
    );
  };

  const goToCreateNew = () => {
    beforeCreateNew?.();
    navigate("/customers/contacts/new", { state: "back" });
  };

  const getLabel = (contact: IContact | null) => {
    if (contact) {
      return contact?.first_name + " " + contact?.last_name;
    }
    return "";
  };

  useEffect(() => {
    dispatch(
      getContactList({
        search: control._defaultValues?.contact?.first_name || null,
        customer: customerId,
      })
    );
  }, [customerId]);

  useEffect(() => {
    dispatch(
      getContactList({
        search: control._defaultValues?.contact?.first_name || null,
      })
    );
  }, []);
  return (
    <div className={styles.autocomplete}>
      <label data-id={name} className={styles.autocomplete__label}>
        {label}
        {required && <span className={styles.autocomplete__error}>*</span>}
      </label>
      <Controller
        name={name}
        control={control}
        defaultValue={control._defaultValues.contact}
        render={({ field, fieldState }) => (
          <Autocomplete
            {...field}
            options={contacts}
            getOptionLabel={getLabel}
            onChange={(_, value) => {
              field.onChange(value);
            }}
            disablePortal={true}
            disabled={disabled}
            popupIcon={<ArrowDownBlackIcon />}
            componentsProps={componentsProps}
            noOptionsText={
              isAdd && <label onClick={goToCreateNew}>+ Add new Contacts</label>
            }
            renderInput={(params) => (
              <>
                <TextField
                  sx={{
                    ".MuiOutlinedInput-notchedOutline": {
                      borderStyle: "none",
                    },
                  }}
                  classes={{ root: styles.autocomplete__input }}
                  name={name}
                  required={required}
                  onChange={(event) => {
                    searchContacts(event.target.value);
                  }}
                  {...params}
                />
                {fieldState.error && (
                  <div className={styles.autocomplete__error}>
                    {fieldState.error.message}
                  </div>
                )}
              </>
            )}
          />
        )}
      />
    </div>
  );
};
