import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useAppDispatch } from "Redux/store";
import { useNavigate } from "react-router";
import DeleteModal from "Components/UI/DialogWindow/DeleteModal";
import { notifySuccess } from "Components/Notify/notify";
import { deleteVendor, getVendorList } from "Redux/Vendor/reducer";
import { selectVendorList, selectVendorNumPage } from "Redux/Vendor/slice";
import { vendorFields } from "Shared/Constants/vendor";
import ListLayout from "Components/Layout/ListLayout/ListLayout";
import { getVendorCardData } from "Shared/Utils/vendor";
import { useSearchParams } from "react-router-dom";
import Card from "Components/UI/Card/Card/Card";
import Row from "Components/UI/Card/Row/Row";
import { IVendor } from "Shared/Types/vendor";
import { getEmployeeCardStatus } from "Shared/Utils/user";

export const VendorList: React.FC = () => {
  const dispatch = useAppDispatch();
  const vendors = useSelector(selectVendorList) || [];
  const numPage = useSelector(selectVendorNumPage);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [showDelete, setShowDelete] = useState(false);
  const [deleteId, setDeleteId] = useState<string | null>();
  const [isCard, setIsCard] = useState(true);
  const [fields, setFields] = useState(vendorFields);

  const goToDetailPage = (id: string) => {
    navigate(`/vendors/${id}`);
  };

  const goToEditPage = (id: string) => {
    navigate(`/vendors/edit/${id}`);
  };

  const props = (vendor: IVendor) => {
    return {
      openDeleteModal: handleDialogOpen,
      fields: fields,
      vendor: vendor,
      dataId: vendor.id,
      cardData: getVendorCardData(vendor),
      status: getEmployeeCardStatus(vendor.is_active),
      isNeedStatus: true,
      onClick: goToDetailPage,
      editClick: goToEditPage,
    };
  };

  const handleDialogClose = () => {
    setShowDelete(false);
    setDeleteId(null);
  };

  const handleDialogOpen = (id: string) => {
    setShowDelete(true);
    setDeleteId(id);
  };

  const handleDeleteVendor = async () => {
    const onSuccess = () => {
      notifySuccess("Vendor deleted");
      setShowDelete(false);
      setDeleteId(null);
      dispatch(getVendorList(null));
    };
    if (deleteId) {
      await dispatch(deleteVendor({ id: deleteId, onSuccess }));
    }
  };

  useEffect(() => {
    dispatch(
      getVendorList({
        search: searchParams.get("search"),
        status: searchParams.get("status"),
        page: searchParams.get("page") || "1",
      })
    );
  }, [searchParams]);

  return (
    <ListLayout
      pagesNumber={numPage}
      isCard={isCard}
      setIsCard={setIsCard}
      fields={fields}
      setFields={setFields}
      filters={["search", "shopStatus"]}
      fieldAllList={vendorFields}
    >
      {isCard
        ? vendors.map((vendor) => <Card key={vendor.id} {...props(vendor)} />)
        : vendors.map((vendor) => <Row key={vendor.id} {...props(vendor)} />)}

      <DeleteModal
        open={showDelete}
        handleClose={handleDialogClose}
        title="Delete"
        text="Are you sure...?"
        acceptTitle="Delete"
        accept={handleDeleteVendor}
      />
    </ListLayout>
  );
};
