import {
  BuildingsIcon,
  EmailIcon,
  FaxIcon,
  GlobusIcon,
  LocationIcon,
  NotePencil,
  PhoneIcon2,
  UserIcon,
} from "Assets/index";

export const LOGIN = {
  TITLE: "Get Started Now",
  DESCRIPTION: "Enter Your credentials to access your account",
};

export const FIRST_AUTH = {
  TITLE: "Create password",
  DESCRIPTION: "Your Email address is your Login",
};

export const RESET_PASSWORD = {
  TITLE: "Forgot password?",
  DESCRIPTION: `We have sent a link to activate your account by email
  hello@exaple.com. Follow the link to create a new password.`,
};

export const REGISTER = {
  TITLE: "Start your free trail",
  DESCRIPTION: `We have sent a link to activate your account by email
  hello@exaple.com. Follow the link to create a new password`,
};
export const EASY_SHOP_DESCRIPTION = `EasyShop is a robust and specialized solution designed to streamline
operations in heavy equipment repair and maintenance facilities.
This software enables efficient tracking of work orders, inventory
management, and scheduling of tasks, ensuring minimal downtime and
optimal resource utilization. With its comprehensive reporting and
analytics features, EasyShop software empowers businesses to make
data-driven decisions, enhance productivity, and ultimately improve
their bottom line.`;

export const authLinks = [
  { name: "Forgot password?", url: "/forgot-password-email" },
  { name: "Have an account?", url: "/register" },
];
export const registerLinks = [
  { name: "Login", url: "/login" },
  { name: "Forgot password?", url: "/forgot-password-email" },
];
export const forgotPasswordLinks = [{ name: "Login", url: "/login" }];
export const FORGOT_PASSWORD = {
  TITLE: "Forgot password?",
};

export const registerFields = [
  {
    icon: UserIcon,
    required: true,
    name: "first_name",
    placeholder: "First name",
    xs: 6,
  },
  {
    icon: EmailIcon,
    required: true,
    name: "email",
    placeholder: "hello@example.com",
    xs: 6,
  },
  {
    icon: UserIcon,
    required: true,
    name: "last_name",
    placeholder: "Last name",
    xs: 6,
  },
  {
    icon: FaxIcon,
    required: false,
    name: "fax",
    placeholder: "Enter company fax",
    xs: 6,
  },
  {
    icon: NotePencil,
    required: true,
    name: "legal_name",
    placeholder: "Legal name",
    xs: 6,
  },
  {
    icon: GlobusIcon,
    required: false,
    name: "website",
    placeholder: "Enter company website",
    xs: 6,
  },
  {
    icon: BuildingsIcon,
    required: true,
    name: "company_name",
    placeholder: "Enter company name",
    xs: 6,
  },
  {
    icon: PhoneIcon2,
    required: true,
    name: "phone",
    placeholder: "+1(12)",
    xs: 6,
  },
  {
    icon: LocationIcon,
    required: false,
    name: "address",
    placeholder: "Enter company address",
    xs: 12,
  },
];
