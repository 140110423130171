import React, { useEffect } from "react";
import { notifySuccess } from "Components/Notify/notify";
import { getPart, updatePart } from "Redux/Product/reducer";
import { selectCurrentPart } from "Redux/Product/slice";
import { useAppDispatch } from "Redux/store";
import { IPartUpdate } from "Shared/Types/part";
import { partSchema } from "Shared/Utils/part";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import PartForm from "Components/Part/Form/PartForm";

const PartUpdate: React.FC = () => {
  const dispatch = useAppDispatch();
  const part = useSelector(selectCurrentPart);
  const navigate = useNavigate();
  const params = useParams();

  const onUpdatePart = (values: IPartUpdate) => {
    if (params.id) {
      dispatch(
        updatePart({
          id: params.id,
          data: values,
          onSuccess: () => {
            notifySuccess("Part updated");
            navigate("/parts/inventory");
          },
        })
      );
    }
  };

  useEffect(() => {
    if (params.id) {
      dispatch(getPart(params.id));
    }
  }, [params.id]);

  return (
    <div>
      <PartForm
        onSubmit={onUpdatePart}
        schema={partSchema}
        part={part || undefined}
      />
    </div>
  );
};
export default PartUpdate;
