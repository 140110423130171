import React from "react";
import Layout from "Components/Layout/Layout";
import PurchaseOrderList from "Containers/PurchaseOrder/PurchaseOrderList/PurchaseOrderList";
import { useBreadcrumb } from "Shared/Hooks/use-breadcrumb";
import { getPurchaseOrderBreadCrumb } from "Shared/Utils/purchase-order";

export const PurchaseOrderListPage: React.FC = () => {
  useBreadcrumb({
    links: getPurchaseOrderBreadCrumb("LIST"),
    createTitle: "Purchase Order",
    isNeedCreateBtn: true,
  });

  return (
    <Layout>
      <PurchaseOrderList />
    </Layout>
  );
};
