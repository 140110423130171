import React, { useState } from "react";
import styles from "./FilterPanel.module.scss";
import cn from "classnames";
import FilterBlock from "../FieldChange/FieldChange";

import { Filters } from "Components/Order/FilterPanel/Filters";
import IconButton from "Components/UI/IconButton/IconButton";
import {
  CardIcon,
  ExportIcon,
  FilterIcon,
  ImportIcon,
  SortIcon,
} from "Assets/index";
import Button from "Components/UI/Button/Button";
import { SortModal } from "Components/Part/SortModal/SortModal";
import { ISortField } from "Shared/Types/common";

type FilterPanelProps = {
  setIsCard: (isCard: boolean) => void;
  isCard: boolean;
  fields: string[];
  setFields: (arr: string[]) => void;
  fieldAllList: string[];
  limitFields?: number;
  filters?: string[];
  filters2?: string[];
  showCardAndFilterIcons?: boolean;
  showSortIcon?: boolean;
  showImportExportIcons?: boolean;
  sortFields?: ISortField[];
};

const FilterPanel: React.FC<FilterPanelProps> = ({
  setIsCard,
  isCard,
  fields,
  setFields,
  fieldAllList,
  limitFields,
  filters,
  filters2,
  showCardAndFilterIcons = true,
  showImportExportIcons = false,
  showSortIcon = true,
  sortFields,
}) => {
  const [showFilterBlock, setShowFilterBlock] = useState(false);
  const [showSortModal, setShowSortModal] = useState(false);

  const isFull = (filters?.length || 0) >= 3;
  const isFullFilter2 = (filters2?.length || 0) >= 3;

  const closeOrderFieldChangeBlock = () => {
    setShowFilterBlock(false);
  };
  const closeSortByFieldModal = () => {
    setShowSortModal(false);
  };
  const changeCardStatus = () => {
    setIsCard(!isCard);
  };

  return (
    <div>
      <div className={cn(styles.filter, !isFull && styles.filter_30)}>
        <Filters filters={filters} isFull={isFull} />
        {showImportExportIcons && (
          <div className={styles.filter__actions}>
            <Button
              className={styles.filter__actions__buttons}
              variant="outlined"
              onClick={() => console.log("export")}
            >
              <ExportIcon width={20} height={20} />
              <p>Export</p>
            </Button>
            <Button
              variant="outlined"
              className={styles.filter__actions__buttons}
              onClick={() => console.log("import")}
            >
              <ImportIcon width={20} height={20} />
              <p>Import</p>
            </Button>
          </div>
        )}
        {showCardAndFilterIcons && (
          <div className={styles.filter__actions}>
            <IconButton
              icon={CardIcon}
              color="none"
              containerClassName={cn(
                styles.filter__icons,
                isCard && styles.filter__icons_active
              )}
              onClick={changeCardStatus}
            />
            <IconButton
              icon={FilterIcon}
              color="none"
              containerClassName={styles.filter__icons}
              onClick={() => setShowFilterBlock?.(!showFilterBlock)}
            />
            {showSortIcon && (
              <IconButton
                icon={SortIcon}
                color="none"
                containerClassName={styles.filter__icons}
                onClick={() => setShowSortModal?.(!showSortModal)}
              />
            )}
          </div>
        )}
        {showFilterBlock && (
          <FilterBlock
            onClose={closeOrderFieldChangeBlock}
            orderFields={fields}
            setOrderFields={setFields}
            allFields={fieldAllList}
            limit={limitFields}
          />
        )}
        {showSortModal && (
          <SortModal onClose={closeSortByFieldModal} sortFields={sortFields} />
        )}
      </div>
      <div className={cn(styles.filter, !isFullFilter2 && styles.filter_30)}>
        <Filters filters={filters2} isFull={isFullFilter2} />
      </div>
    </div>
  );
};
export default FilterPanel;
