import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "Service/api/api";
import { readObj, write } from "Service/storage";
import {
  IAuthLogin,
  IAuthRefresh,
  IAuthVerify,
  IFirstAuth,
  IFirstAuthForm,
} from "Shared/Types/auth";
import { errorHandler } from "Shared/Utils/app";
import { AxiosError } from "axios";

const login = createAsyncThunk("account/login", async (data: IAuthLogin) => {
  try {
    const token = await api.auth.login(data);
    write("access", token.data.access);
    write("refresh", token.data.refresh);
    const me = await api.auth.getMe();
    let currentShop;
    if (me.data.shop) {
      currentShop = me.data.shop;
    } else if (me.data.is_company_admin) {
      const shopList = await api.shop.getShopList(null);
      if (shopList.data.results.length) {
        currentShop = shopList.data.results[0];
      }
    }
    if (currentShop) {
      write("current_shop", currentShop);
      write("current_shop_name", currentShop.shop_name);
    }

    return { ...token.data, ...me.data };
  } catch (error) {
    if (error instanceof Error) {
      errorHandler(error);
      if (error instanceof AxiosError) {
        if (error.response?.status === 401) {
          error.message = "You entered an incorrect username or password";
        }
      }
    }

    throw error;
  }
});

const getMe = createAsyncThunk("auth/getMe", async () => {
  try {
    const me = await api.auth.getMe();
    if (me.data.is_company_admin) {
      const curShop = readObj("current_shop");
      if (!curShop) {
        const shopResponse = await api.shop.getShopList(null);
        write("current_shop", shopResponse.data.results[0]);
      }
    }
    return { ...me.data };
  } catch (error) {
    if (error instanceof Error) {
      errorHandler(error);
    }
    throw error;
  }
});

const refresh = createAsyncThunk("auth/refresh", async (data: IAuthRefresh) => {
  try {
    const response = await api.auth.refresh(data);
    return response.data;
  } catch (error) {
    if (error instanceof Error) {
      errorHandler(error);
    }
    throw error;
  }
});

const verify = createAsyncThunk("auth/verify", async (data: IAuthVerify) => {
  try {
    const response = await api.auth.verify(data);
    return response.data;
  } catch (error) {
    if (error instanceof Error) {
      errorHandler(error);
    }
    throw error;
  }
});

const firstAuth = createAsyncThunk(
  "auth/firstAuth",
  async (data: IFirstAuthForm) => {
    const newData: IFirstAuth = {
      new_password: data.new_password,
      token: data.token,
      uid: data.uid,
    };
    try {
      const response = await api.auth.firstAuth(newData);
      return response.data;
    } catch (error) {
      if (error instanceof Error) {
        errorHandler(error);
      }
      throw error;
    }
  }
);

const resetPasswordByEmail = createAsyncThunk(
  "auth/resetPasswordByEmail",
  async (email: string) => {
    try {
      const response = await api.auth.resetPasswordByEmail(email);
      return response.data;
    } catch (error) {
      if (error instanceof Error) {
        errorHandler(error);
      }
      throw error;
    }
  }
);

const resetPassword = createAsyncThunk(
  "auth/resetPassword",
  async (data: { data: IFirstAuth; onSuccess: () => void }) => {
    try {
      const response = await api.auth.resetPassword(data.data);
      data.onSuccess();
      return response.data;
    } catch (error) {
      if (error instanceof Error) {
        errorHandler(error);
      }
      throw error;
    }
  }
);

const restoreSession = createAsyncThunk(
  `auth/restoreSession`,
  async (_, { dispatch }) => {
    const profile = readObj("account");
    const me = dispatch(getMe());
    if (me && profile) {
      return { ...profile, ...me };
    }
  }
);

export {
  login,
  refresh,
  verify,
  restoreSession,
  firstAuth,
  getMe,
  resetPasswordByEmail,
  resetPassword,
};
