import { client } from "Service/axios";
import { SearchResult } from "Shared/Types/common";
import { IPart, IPartSearchParams, IPartUpdateApi } from "Shared/Types/part";
import { ILocation } from "Shared/Types/location";
import { IEstimatesOrder } from "Shared/Types/estimate";
import { IOrderList } from "Shared/Types/order";

const PART_URL = "part/";

class Part {
  getPartList(params: IPartSearchParams | null) {
    return client.get<SearchResult<IPart>>(`${PART_URL}`, {
      params,
    });
  }
  getPart(id: string) {
    return client.get<IPart>(`${PART_URL}${id}/`);
  }
  getPartLocation(id: string) {
    return client.get<ILocation>(`${PART_URL}${id}/locations/`);
  }
  addPart(data: FormData) {
    return client.post(`${PART_URL}create/`, data, {
      headers: { "Content-Type": "multipart/form-data" },
    });
  }
  updatePart(id: string, data: IPartUpdateApi) {
    return client.patch(`${PART_URL}${id}/update/`, data);
  }
  deletePart(id: string) {
    return client.delete(`${PART_URL}${id}/delete/`);
  }
  getEstimatePartList(params: IPartSearchParams | null) {
    return client.get<IEstimatesOrder[]>(
      `${PART_URL}price_parts_on_estimates/`,
      {
        params,
      }
    );
  }
  getPricePartList(params: IPartSearchParams | null) {
    return client.get<IOrderList[]>(
      `${PART_URL}price_parts_on_service_orders/`,
      {
        params,
      }
    );
  }
}

export default Part;
