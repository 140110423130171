import { client } from "Service/axios";
import { ITaxRate, ITaxRateAdd, ITaxRateUpdate } from "Shared/Types/taxRate";

const TAX_RATE_URL = "company/tax-rate/";
class TaxRate {
  getTaxRateList() {
    return client.get(TAX_RATE_URL);
  }

  getTaxRate(id: string) {
    return client.get<ITaxRate>(`${TAX_RATE_URL}${id}/`);
  }

  createTaxRate(params: ITaxRateAdd) {
    return client.post(TAX_RATE_URL, params);
  }

  deleteTaxRate(id: string) {
    return client.delete(`${TAX_RATE_URL}${id}/`);
  }

  updateTaxRate(id: string, data: ITaxRateUpdate) {
    return client.patch(`${TAX_RATE_URL}${id}/`, data);
  }
}

export default TaxRate;
