export const times = [
  { id: 1, time: "9:00am", value: "09:00:00" },
  { id: 2, time: "9:30am", value: "09:30:00" },
  { id: 3, time: "10:00am", value: "10:00:00" },
  { id: 4, time: "10:30am", value: "10:30:00" },
  { id: 5, time: "11:00am", value: "11:00:00" },
  { id: 6, time: "11:30am", value: "11:30:00" },
  { id: 7, time: "12:00pm", value: "12:00:00" },
  { id: 8, time: "12:30pm", value: "12:30:00" },
  { id: 9, time: "1:00pm", value: "13:00:00" },
  { id: 10, time: "1:30pm", value: "13:30:00" },
  { id: 11, time: "2:00pm", value: "14:00:00" },
  { id: 12, time: "2:30pm", value: "14:30:00" },
  { id: 13, time: "3:00pm", value: "15:00:00" },
  { id: 14, time: "3:30pm", value: "15:30:00" },
  { id: 15, time: "4:00pm", value: "16:00:00" },
  { id: 16, time: "4:30pm", value: "16:30:00" },
  { id: 17, time: "5:00pm", value: "17:00:00" },
  { id: 18, time: "5:30pm", value: "17:30:00" },
  // { id: 1, time: "6:00am", value: "18:00:00" },
  // { id: 1, time: "6:30am", value: "18:30:00" },
  // { id: 1, time: "7:00am", value: "19:00:00" },
];
