import { Grid } from "@mui/material";
import { ICompany } from "Shared/Types/company";
import React from "react";
import styles from "./Company.module.scss";
import InfoField from "Components/UI/InfoField/InfoField";
import {
  getCompanyBilling,
  getCompanyInformation,
} from "Shared/Utils/company";
import Block from "Components/UI/Block/Block";

type ProfileProps = {
  company: ICompany;
};

const Profile: React.FC<ProfileProps> = ({ company }) => {
  const companyInformation = getCompanyInformation(company);
  const {billing, primary} = getCompanyBilling(company);

  return (
    <div className={styles.company}>
      <Block className={styles.company__block}>
        <h3>Company information</h3>
        <Grid container className={styles.company__content}>
          {companyInformation &&
            companyInformation.map((company, index) => (
              <Grid
                xs={5.9}
                key={index}
                className={styles.company__content__item}
              >
                <InfoField
                  label={company.title}
                  value={company.value}
                  labelColor="gray"
                />
              </Grid>
            ))}
        </Grid>
      </Block>
      <Block className={styles.company__block}>
        <h3>Billing Information</h3>
        <Grid container className={styles.company__content__item}>
          {billing.map((company, index) => {
            return (
              <Grid
                xs={5.9}
                key={index}
                className={styles.company__content__item}
              >
                <InfoField
                  label={company.title}
                  value={company.value}
                  labelColor="gray"
                />
              </Grid>
            );
          })}
        </Grid>
      </Block>

      <Block className={styles.company__block}>
        <h3>Primary Contact and Address</h3>
        <Grid container className={styles.company__content__item}>
          {primary.map((company, index) => {
            return (
              <Grid
                xs={5.9}
                key={index}
                className={styles.company__content__item}
              >
                <InfoField
                  label={company.title}
                  value={company.value}
                  labelColor="gray"
                />
              </Grid>
            );
          })}
        </Grid>
      </Block>
    </div>
  );
};
export default Profile;
