import React, { useCallback, useEffect, useState } from "react";
import { useAppDispatch } from "Redux/store";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import {
  getCompanyRequest,
  updateCompanyRequest,
} from "Redux/CompanyRequests/reducers";
import styles from "./RequestReviewDetails.module.scss";
import Block from "Components/UI/Block/Block";
import { Grid } from "@mui/material";
import { selectCurrentCompanyRequest } from "Redux/CompanyRequests/slice";
import InfoField from "Components/UI/InfoField/InfoField";
import { getCompanyRequestInformation } from "Shared/Utils/companyRequest";
import { ICompanyAdd, ICompanyRequest } from "Shared/Types/company";
import RequestReject from "Containers/Requests/RequestReject/RequestReject";
import AcceptButton from "Components/UI/Button/AcceptButton";
import RejectButton from "Components/UI/Button/RejectButton";
import { notifySuccess } from "Components/Notify/notify";
import InputField from "Components/UI/Inputs/InputField";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { companySchema } from "Shared/Utils/company";
import Form from "Components/UI/Form/Form";
import SelectField from "Components/UI/SelectField/SelectField";
import { addCompany } from "Redux/Company/reducers";

const initialValues: ICompanyAdd = {
  legal_name: "",
  name: "",
  email: "",
  phone: "",
};

const RequestReviewDetails: React.FC = () => {
  const [show, setShow] = useState(false);
  const dispatch = useAppDispatch();
  const request = useSelector(selectCurrentCompanyRequest);
  const navigate = useNavigate();
  const params = useParams();
  const requestInformation = getCompanyRequestInformation(request);
  const [rejectRequestId, setRejectRequestId] = useState<string>();

  const { handleSubmit, control, setValue } = useForm<any>({
    resolver: yupResolver(companySchema),
    defaultValues: {
      ...initialValues,
      billing_contact: request?.billing_contact || "",
      payment_method: request?.payment_method || "",
      billing_address: request?.billing_address || "",
      primary_contact: request?.primary_contact || "",
      primary_address: request?.primary_address || "",
    },
  });

  const handleAccept = (values: ICompanyAdd) => {
    const onSuccess = () => {
      notifySuccess("Successfully accept");
      navigate("/requests/review");
    };
    dispatch(addCompany({ data: values, onSuccess }));
  };

  const handleReject = useCallback(
    (request: ICompanyRequest) => {
      setShow(true);
      setRejectRequestId(request.id);
    },
    [setShow, setRejectRequestId]
  );

  useEffect(() => {
    if (request) {
      setValue("email", request.email);
      setValue("name", request.company_name);
      setValue("first_name", request.first_name);
      setValue("last_name", request.last_name);
      setValue("phone", request.phone);
      setValue("company_request", request.id);
      setValue("legal_name", request.legal_name);
      setValue("fax", request.fax);
      setValue("website", request.website);
      setValue("address", request.address);

      const onSuccess = () => {
        notifySuccess("Successfully update");
      };

      if (!request.is_read) {
        dispatch(
          updateCompanyRequest({
            id: request.id,
            data: {
              ...request,
              is_read: true,
            },
            onSuccess,
          })
        );
      }
    }
  }, [request]);

  useEffect(() => {
    if (params.requestId) {
      dispatch(getCompanyRequest(params.requestId));
    }
  }, [params.requestId]);

  return (
    <Form onSubmit={handleSubmit(handleAccept)} className={styles.request}>
      <Block className={styles.request__block}>
        <h3>Company information</h3>
        <Grid container className={styles.request__content}>
          {requestInformation &&
            requestInformation.map((request, index) => (
              <Grid
                xs={5.9}
                key={index}
                className={styles.request__company__content__item}
              >
                <InfoField
                  label={request.title}
                  value={request.value}
                  labelColor="gray"
                />
              </Grid>
            ))}
        </Grid>
      </Block>
      <Block className={styles.request__block}>
        <h3>Billing Information</h3>
        <Grid container className={styles.request__content}>
          <Grid xs={5.9} className={styles.request__company__content__item}>
            <InputField
              label="Billing contact"
              name="billing_contact"
              control={control}
              withController
            />
          </Grid>
          <Grid xs={5.9} className={styles.request__company__content__item}>
            <SelectField
              isClearable
              label="Payment method"
              options={[
                { label: "Cash", value: "cash" },
                { label: "Credit card", value: "credit_card" },
                { label: "Credit terms", value: "credit_terms" },
                { label: "Check", value: "check" },
                { label: "Other", value: "other" },
              ]}
              theme="primary"
              name="payment_method"
              control={control}
              withControl
            />
          </Grid>
          <Grid xs={5.9} className={styles.request__company__content__item}>
            <InputField
              label="Billing address"
              name="billing_address"
              control={control}
              withController
            />
          </Grid>
        </Grid>
      </Block>
      <Block className={styles.request__block}>
        <h3>Primary Contact and Address</h3>
        <Grid container className={styles.request__content}>
          <Grid xs={5.9} className={styles.request__company__content__item}>
            <InputField
              label="Primary Contact"
              name="primary_contact"
              control={control}
              withController
            />
          </Grid>
          <Grid xs={5.9} className={styles.request__company__content__item}>
            <InputField
              label="Primary Address"
              name="primary_address"
              control={control}
              withController
            />
          </Grid>
        </Grid>
      </Block>
      {request && (
        <div className={styles.request__actions__button}>
          <AcceptButton type="submit" className={styles.acceptBtn}>
            Accept
          </AcceptButton>
          <RejectButton onClick={() => handleReject(request)}>
            Reject
          </RejectButton>
        </div>
      )}
      {rejectRequestId && (
        <RequestReject open={show} setOpen={setShow} id={rejectRequestId} />
      )}
    </Form>
  );
};

export default RequestReviewDetails;
