import * as Yup from "yup";

export const categorySchema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  description: Yup.string(),
  shop: Yup.string(),
});

export const manufacturerSchema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  description: Yup.string(),
  shop: Yup.string(),
});

export const locationSchema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  description: Yup.string(),
  shop: Yup.string(),
});

export const typeSchema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  description: Yup.string(),
  shop: Yup.string(),
});
