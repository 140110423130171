export const settingsSidebar = [
  {
    title: "Part Settings",
    link: "part-settings",
    width: "20%",
  },
  {
    title: "Rate Settings",
    link: "rate-settings",
    width: "20%",
  },
  {
    title: "Unit Settings",
    link: "unit-settings",
    width: "20%",
  },
  {
    title: "Component & System",
    link: "component-system",
    width: "20%",
  },
  {
    title: "Role & Access",
    link: "roles",
    width: "20%",
  },
];

export const settingsPartsMenu = [
  {
    title: "Categories",
    link: "categories",
    width: "20%",
  },
  {
    title: "Manufacturers",
    link: "manufactures",
    width: "20%",
  },
  {
    title: "Locations",
    link: "locations",
    width: "20%",
  },
];

export const settingsRatesMenu = [
  {
    title: "Labor Rate",
    link: "labor-rate",
    width: "20%",
  },
  {
    title: "Tax Rate",
    link: "tax-rate",
    width: "20%",
  },
  {
    title: "Supply Rate",
    link: "supply-rate",
    width: "20%",
  },
];

export const settingsUnitsMenu = [
  {
    title: "Subtypes",
    link: "subtypes",
    width: "90%",
  },
  {
    title: "Unit Types",
    link: "unit-types",
    width: "10%",
  },
];

export const rolesTableHead = [
  {
    label: "#",
    id: "index",
    width: "20%",
  },
  {
    label: "Role",
    id: "name",
    width: "20%",
  },
  {
    label: "Action",
    id: "action",
    width: "20%",
  },
];

export const laborRateTableHead = [
  {
    label: "Name",
    id: "name",
    width: "30%",
  },
  {
    label: "Rate",
    id: "rate",
    width: "30%",
  },
  {
    label: "Default",
    id: "default",
    width: "20%",
  },
  {
    label: "Action",
    id: "action",
    width: "10%",
  },
];

export const taxRateTableHead = [
  {
    label: "Name",
    id: "title",
    width: "25%",
  },
  {
    label: "Labor Type",
    id: "make_labor_always",
    width: "35%",
  },
  {
    label: "Rate",
    id: "rate",
    width: "15%",
  },
  {
    label: "Action",
    id: "action",
    width: "10%",
  },
];

export const supplyRateTableHead = [
  {
    label: "Name",
    id: "title",
    width: "15%",
  },
  {
    label: "Display Title",
    id: "display_title",
    width: "16%",
  },
  {
    label: "Minimum",
    id: "min_amount",
    width: "17%",
  },
  {
    label: "Maximum",
    id: "max_amount",
    width: "17%",
  },
  {
    label: "Rate",
    id: "rate",
    width: "10%",
  },
  {
    label: "Default",
    id: "default",
    width: "15%",
  },
  {
    label: "Action",
    id: "action",
    width: "10%",
  },
];

export const categoriesTableHead = [
  {
    label: "Name",
    id: "name",
    width: "20%",
  },
  {
    label: "Description",
    width: "20%",
    id: "description",
  },
  {
    label: "Actions",
    width: "20%",
    id: "action",
  },
];

export const expCategoriesDate = [
  {
    name: "Text1",
    id: 1,
  },
  {
    name: "Text2",
    id: 2,
  },
];
export const expLaborRateDate = [
  {
    name: "Text",
    rate: 3,
    is_default: true,
    id: 1,
  },
  {
    name: "Text",
    rate: 3,
    is_default: false,
    id: 2,
  },
];

export const typesTableHead = [
  {
    label: "Name",
    id: "name",
  },
  {
    label: "Description",
    id: "description",
  },
  {
    label: "Action",
    id: "action",
  },
];
