import React, { useEffect, useState } from "react";
import styles from "./Categories.module.scss";
import IconButton from "Components/UI/IconButton/IconButton";
import { DeleteIcon, EditIcon, PlusIcon } from "Assets/index";
import { useAppDispatch } from "Redux/store";
import { useSelector } from "react-redux";
import { selectCategoryList } from "Redux/PartSettings/Categories/slice";
import {
  deleteCategory,
  getCategoryList,
} from "Redux/PartSettings/Categories/reducer";
import { notifySuccess } from "Components/Notify/notify";
import DeleteModal from "Components/UI/DialogWindow/DeleteModal";
import { useNavigate } from "react-router";

const Categories: React.FC = () => {
  const dispatch = useAppDispatch();
  const categoryList = useSelector(selectCategoryList) || [];
  const navigate = useNavigate();
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [deleteId, setDeleteId] = useState<string | null>();

  const handleDeleteDialogClose = () => {
    setOpenDeleteModal(false);
    setDeleteId(null);
  };
  const handleDeleteDialogOpen = (id: string) => {
    setOpenDeleteModal(true);
    setDeleteId(id);
  };

  const goToCreateCategory = () => {
    navigate("/settings/part-settings/categories/new");
  };

  const onDelete = async () => {
    const onSuccess = () => {
      notifySuccess("Category deleted");
      handleDeleteDialogClose();
      dispatch(getCategoryList(null));
    };
    if (deleteId != null) {
      await dispatch(deleteCategory({ id: deleteId, onSuccess }));
    }
  };

  const editButtonClick = (id: string) => {
    navigate(`/settings/part-settings/categories/${id}`);
  };

  useEffect(() => {
    dispatch(getCategoryList(null));
  }, []);

  return (
    <div className={styles.categories}>
      <div className={styles.categories__addBtn}>
        <IconButton
          onClick={goToCreateCategory}
          label="Create"
          width={30}
          height={30}
          icon={PlusIcon}
        />
      </div>
      <div className={styles.categories__list}>
        <p className={styles.categories__list__title}>Categories</p>
        {categoryList.map((category) => (
          <div className={styles.categories__list__item} key={category.id}>
            <p className={styles.categories__list__item__name}>
              {category.name}
            </p>
            <div className={styles.categories__list__item__buttons}>
              <IconButton
                icon={EditIcon}
                onClick={() => editButtonClick(category?.id)}
              />
              <IconButton
                onClick={() => handleDeleteDialogOpen(category?.id)}
                icon={DeleteIcon}
              />
            </div>
          </div>
        ))}
      </div>

      <DeleteModal
        acceptTitle="Delete"
        open={openDeleteModal}
        handleClose={handleDeleteDialogClose}
        title="Delete"
        text="Are you sure...?"
        accept={onDelete}
      />
    </div>
  );
};
export default Categories;
