import React from "react";
import { SearchIcon } from "Assets/index";
import { InputAdornment, TextField, TextFieldProps } from "@mui/material";
import Icon from "../../UI/Icon/Icon";
import cn from "classnames";
import styles from "./Inputs.module.scss";

type SearchFieldProps = Omit<
  TextFieldProps,
  "withControl" | "control" | "name"
> & {
  isGlobal?: boolean;
};

const SearchFieldNew: React.FC<SearchFieldProps> = ({
  label,
  placeholder,
  isGlobal,
  ...props
}) => {
  return (
    <TextField
      classes={{ root: cn(styles.search, props.className) }}
      label={label}
      {...props}
      placeholder={placeholder}
      variant="outlined"
      InputProps={{
        classes: { input: styles.search },
        startAdornment: (
          <InputAdornment position="end">
            <Icon
              className={cn(styles.icon, isGlobal && styles.icon_global)}
              component={SearchIcon}
            />
          </InputAdornment>
        ),
      }}
    />
  );
};

export default SearchFieldNew;
