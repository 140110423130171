import { client } from "Service/axios";
import { SearchResult } from "Shared/Types/common";
import {
  IVendor,
  IVendorAdd,
  IVendorSearchParams,
  IVendorUpdate,
} from "Shared/Types/vendor";

const VENDOR_URL = "part/vendor/";

class Vendor {
  getVendorList(params: IVendorSearchParams | null) {
    return client.get<SearchResult<IVendor>>(VENDOR_URL, { params });
  }
  getVendor(id: string) {
    return client.get<IVendor>(`${VENDOR_URL}${id}/`);
  }
  addVendor(data: IVendorAdd | FormData) {
    return client.post(`${VENDOR_URL}create/`, data, {
      headers: { "Content-Type": "multipart/form-data" },
    });
  }
  updateVendor(id: string, data: IVendorUpdate | FormData) {
    return client.patch(`${VENDOR_URL}${id}/update/`, data, {
      headers: { "Content-Type": "multipart/form-data" },
    });
  }
  deleteVendor(id: string) {
    return client.delete(`${VENDOR_URL}${id}/delete/`);
  }
}

export default Vendor;
