import { BreadcrumbItem } from "Components/Breadcrumb/Breadcrumb";
import Layout from "Components/Layout/Layout";
import ServiceList from "Containers/Service/ServiceList/ServiceList";
import { useBreadcrumb } from "Shared/Hooks/use-breadcrumb";
import React from "react";

export const ServiceListPage: React.FC = () => {
  const breads: BreadcrumbItem[] = [{ title: "Services", link: "/shops" }];
  useBreadcrumb({
    links: breads,
    createTitle: "Service",
    isNeedCreateBtn: true,
  });

  return (
    <Layout>
      <ServiceList />
    </Layout>
  );
};
