import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";
import {
  firstAuth,
  getMe,
  login,
  resetPassword,
  resetPasswordByEmail,
  restoreSession,
} from "./reducers";
import { IAccount } from "Shared/Types/auth";
import { clearAll, write } from "Service/storage";

interface ICompanyRequestsState {
  account: IAccount | null;
  error?: string | null;
  loading: boolean;
  success?: boolean | null;
}

const initialState: ICompanyRequestsState = {
  loading: false,
  account: null,
  error: null,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    logout(state) {
      state.account = null;
      clearAll();
    },
    clear(state) {
      state.error = null;
      state.loading = false;
      state.success = null;
    },
    setToken: (state, action) => {
      if (state.account) {
        state.account = { ...state.account, ...action.payload };
        write("access", action.payload.access);
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(restoreSession.fulfilled, (state, action) => {
      state.loading = false;
      state.error = null;
      state.account = action.payload;
    });
    builder.addCase(login.pending, (state) => {
      state.loading = true;
      state.error = null;
      state.account = null;
    });
    builder.addCase(login.fulfilled, (state, action) => {
      state.loading = false;
      state.error = null;
      state.account = action.payload;
      write("account", action.payload);
    });
    builder.addCase(login.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
      state.account = null;
    });
    builder.addCase(firstAuth.pending, (state) => {
      state.loading = true;
      state.error = null;
      state.success = null;
    });

    builder.addCase(resetPasswordByEmail.fulfilled, (state) => {
      state.loading = false;
      state.error = null;
    });
    builder.addCase(resetPasswordByEmail.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
      state.account = null;
    });
    builder.addCase(resetPasswordByEmail.pending, (state) => {
      state.loading = true;
      state.error = null;
      state.success = null;
    });

    builder.addCase(resetPassword.fulfilled, (state) => {
      state.loading = false;
      state.error = null;
    });
    builder.addCase(resetPassword.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
      state.account = null;
    });
    builder.addCase(resetPassword.pending, (state) => {
      state.loading = true;
      state.error = null;
      state.success = null;
    });

    builder.addCase(firstAuth.fulfilled, (state) => {
      state.loading = false;
      state.error = null;
      state.success = true;
    });
    builder.addCase(firstAuth.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
      state.success = false;
    });
    builder.addCase(getMe.fulfilled, (state, action) => {
      state.loading = false;
      state.error = null;
      state.account = { ...state.account, ...action.payload };
      write("account", state.account || "");
    });
  },
});

export const { logout, clear, setToken } = authSlice.actions;

export const selectAccount = (state: RootState) => state.auth.account;
export const selectAuthLoading = (state: RootState) => state.auth.loading;
export const selectAuthError = (state: RootState) => state.auth.error;
export const selectAuthSuccess = (state: RootState) => state.auth.success;
export const selectIsFristAuth = (state: RootState) =>
  state.auth.account?.is_first_auth;

export default authSlice;
