import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../store";
import { ILocation } from "Shared/Types/location";
import {
  getLocationList,
  addLocation,
  updateLocation,
  deleteLocation,
  getLocation,
} from "./reducer";

interface ILocationState {
  locationList?: ILocation[];
  currentLocation?: ILocation | null;
  loading: boolean;
}

const initialState: ILocationState = {
  locationList: [],
  loading: false,
};

const locationSlice = createSlice({
  name: "location",
  initialState,
  reducers: {
    removeCurrentLocation(state) {
      state.currentLocation = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(addLocation.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(addLocation.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(addLocation.fulfilled, (state) => {
      state.loading = false;
    });

    builder.addCase(getLocation.pending, (state) => {
      state.loading = true;
      state.currentLocation = null;
    });
    builder.addCase(getLocation.rejected, (state) => {
      state.loading = false;
      state.currentLocation = null;
    });
    builder.addCase(getLocation.fulfilled, (state, action) => {
      state.loading = false;
      state.currentLocation = action.payload;
    });

    builder.addCase(getLocationList.pending, (state) => {
      state.loading = true;
      state.locationList = [];
    });
    builder.addCase(getLocationList.rejected, (state) => {
      state.loading = false;
      state.locationList = [];
    });
    builder.addCase(getLocationList.fulfilled, (state, action) => {
      state.loading = false;
      state.locationList = action.payload;
    });

    builder.addCase(updateLocation.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateLocation.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(updateLocation.fulfilled, (state) => {
      state.loading = false;
    });

    builder.addCase(deleteLocation.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(deleteLocation.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(deleteLocation.fulfilled, (state) => {
      state.loading = false;
    });
  },
});

export const { removeCurrentLocation } = locationSlice.actions;

export const selectCurrentLocation = (state: RootState) =>
  state.location.currentLocation;
export const selectLocationLoading = (state: RootState) =>
  state.location.loading;
export const selectLocationList = (state: RootState) =>
  state.location.locationList;

export default locationSlice;
