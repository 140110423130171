import React, { useEffect } from "react";
import styles from "./CustomerUpdate.module.scss";
import { notifySuccess } from "Components/Notify/notify";
import { useAppDispatch } from "Redux/store";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import { customerScheme } from "Shared/Utils/customer";
import CustomerForm from "Components/Customer/CustomerForm/CustomerForm";
import { ICustomerUpdate } from "Shared/Types/customer";
import { getCustomer, updateCustomer } from "Redux/Customer/reducer";
import { selectCurrentCustomer } from "Redux/Customer/slice";

export const CustomerUpdate: React.FC = () => {
  const dispatch = useAppDispatch();
  const currentCustomer = useSelector(selectCurrentCustomer);
  const params = useParams();
  const navigate = useNavigate();

  const onSubmit = async (values: ICustomerUpdate) => {
    const onSuccess = () => {
      notifySuccess("Updated Customer!");
      navigate("/customers");
    };
    if (params.id) {
      await dispatch(
        updateCustomer({
          id: params.id,
          data: values,
          onSuccess,
        })
      );
    }
  };

  useEffect(() => {
    if (params.id) {
      dispatch(getCustomer(params.id));
    }
  }, [params.id]);

  return (
    <div className={styles.customerUpdate}>
      <div className={styles.customerUpdate__form}>
        {currentCustomer && (
          <CustomerForm
            onSubmit={onSubmit}
            schema={customerScheme}
            customer={currentCustomer}
          />
        )}
      </div>
    </div>
  );
};
