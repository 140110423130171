import {
  ActionHistoryIcon,
  CommentIcon,
  DownloadDocIcon,
  ReSendIcon,
} from "Assets/index";
import { IStatusView } from "Components/StatusView/StatusView";
import { IPurchaseOrderStatus } from "Shared/Types/purchase-order";

const MAIN_ROUTE = "/parts/purchase-orders/";

export const PURCHASE_ORDER_ROUTE = {
  LIST: MAIN_ROUTE,
  UPDATE: `${MAIN_ROUTE}edit/`,
  DETAIL: `${MAIN_ROUTE}info/`,
  ADD: `${MAIN_ROUTE}new/`,
};

export const PURCHASE_ORDER_TYPE = {
  PO: "PO",
  SO: "SO",
};

export const PURCAHSE_ORDER_STATUS = {
  DRAFT: "DRAFT",
  SENT: "SENT",
  ACKNOWLEDGED: "ACKNOWLEDGED",
  WAITING: "WAITING",
  PARTIALLY_RECEIVED: "PARTIALLY_RECEIVED",
  CLOSED: "CLOSED",
  RECEIVED: "RECEIVED",
};

export const PURCAHSE_ORDER_STATUSES = {
  DRAFT: "Draft",
  SENT: "Sent",
  ACKNOWLEDGED: "Acknowledged",
  WAITING: "Waiting",
  PARTIALLY_RECEIVED: "Partially received",
  CLOSED: "Closed",
  RECEIVED: "Received",
};

export const PURCAHSE_ORDER_FIELDS = {
  TYPE: "Type",
  PO: "PO #",
  SO: "SO #",
  VENDOR: "Vendor",
  DATA: "Data",
  EST_TOTAL: "Est. Total",
  ACTUAL_TOTAL: "Actual Total",
  NUMBER_OF_PARTS: "Total QTY",
  STATUS: "Status",
  DROP_SHIP: "Drop Ship",
  SHOP: "Shop",
  ORDER_TOTAL: "Order Total",
  ORDER_THRESHOLD: "Order Threshold",
  PENDING_ORDERS: "Pending Orders",
  FREIGHT_DIFFERENCE: "Freight Difference",
  FREIGHT_THRESHOLD: "Freight Threshold",
  ORDER_DIFFERENCE: "Order Difference",
};

export const PURCHASE_ORDER_LINE_ITEMS_FIELDS = {
  PART_NUMBER: "Part #",
  VENDOR_PART_NUMBER: "Vendor Part #",
  ORDER_QUANTITY: "Order QTY",
  LAST_COST: "Last Cost",
  ACTUAL_COST: "Actual Cost",
  AVERAGE_COST: "Average Cost",
  EXTENDED_COST: "Extended Cost",
  RECEIVED_QUANTITY: "Received QTY",
  SHOP: "Shop",
  DESCRIPTION: "Description",
};

export const purchaseOrderFields = [
  PURCAHSE_ORDER_FIELDS.TYPE,
  PURCAHSE_ORDER_FIELDS.PO,
  PURCAHSE_ORDER_FIELDS.VENDOR,
  PURCAHSE_ORDER_FIELDS.DATA,
  PURCAHSE_ORDER_FIELDS.EST_TOTAL,
  PURCAHSE_ORDER_FIELDS.ACTUAL_TOTAL,
  PURCAHSE_ORDER_FIELDS.NUMBER_OF_PARTS,
];
type status = {
  [key in IPurchaseOrderStatus]: IStatusView;
};
export const purchaseOrderStatusCard: status = {
  DRAFT: { color: "green", title: PURCAHSE_ORDER_STATUSES.DRAFT },
  SENT: { color: "none", title: PURCAHSE_ORDER_STATUSES.SENT },
  ACKNOWLEDGED: {
    color: "lightYellow",
    title: PURCAHSE_ORDER_STATUSES.ACKNOWLEDGED,
  },
  WAITING: { color: "red", title: PURCAHSE_ORDER_STATUSES.WAITING },
  PARTIALLY_RECEIVED: {
    color: "lightYellow",
    title: PURCAHSE_ORDER_STATUSES.PARTIALLY_RECEIVED,
  },
  CLOSED: { color: "red", title: PURCAHSE_ORDER_STATUSES.CLOSED },
  RECEIVED: { color: "red", title: PURCAHSE_ORDER_STATUSES.RECEIVED },
};

export const PURCAHSE_ORDER_ACTIONS = {
  HISTORY: "history",
  RESEND: "re-send",
  DOWNLOAD: "download",
  NOTE: "note",
};
const SIZE = 25;
const PADDING = "7px";
export const purchaseOrderActions = [
  {
    icon: ActionHistoryIcon,
    title: PURCAHSE_ORDER_ACTIONS.HISTORY,
    padding: PADDING,
    size: SIZE,
  },
  {
    icon: ReSendIcon,
    title: PURCAHSE_ORDER_ACTIONS.RESEND,
    padding: PADDING,
    size: SIZE,
  },
  {
    icon: DownloadDocIcon,
    title: PURCAHSE_ORDER_ACTIONS.DOWNLOAD,
    padding: PADDING,
    size: SIZE,
  },
  {
    icon: CommentIcon,
    title: PURCAHSE_ORDER_ACTIONS.NOTE,
    padding: PADDING,
    size: SIZE,
  },
];
