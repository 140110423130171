import React from "react";
import { BreadcrumbItem } from "Components/Breadcrumb/Breadcrumb";
import { useBreadcrumb } from "Shared/Hooks/use-breadcrumb";
import Layout from "Components/Layout/Layout";
import EstimateDetail from "Containers/Order/Estimates/EstimateDetail/EstimateDetail";
import { useParams } from "react-router";
import { getBreadcrumbs } from "Shared/Utils/estimate";

export const EstimateDetailPage = () => {
  const params = useParams();
  const breads: BreadcrumbItem[] = getBreadcrumbs(params?.id || "");

  useBreadcrumb({
    links: breads,
  });

  return (
    <Layout>
      <EstimateDetail />
    </Layout>
  );
};
