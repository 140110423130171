import React, { useEffect } from "react";
import styles from "./ServiceUpdate.module.scss";
import { useAppDispatch } from "Redux/store";
import { useSelector } from "react-redux";
import { notifySuccess } from "Components/Notify/notify";
import { useNavigate, useParams } from "react-router";
import { selectCurrentService } from "Redux/Service/slice";
import { getService, updateService } from "Redux/Service/reducer";
import { IServiceUpdate } from "Shared/Types/service";
import ServiceForm from "Components/Service/ServiceForm/ServiceForm";
import { getInitService, serviceSchema } from "Shared/Utils/service";

const ServiceUpdate: React.FC = () => {
  const dispatch = useAppDispatch();
  const service = useSelector(selectCurrentService);
  const params = useParams();
  const navigate = useNavigate();

  const editService = (values: IServiceUpdate) => {
    const onSuccess = () => {
      notifySuccess("Service updated");
      navigate("/services");
    };
    if (params.id) {
      dispatch(
        updateService({
          id: params.id,
          data: values,
          onSuccess,
        })
      );
    }
  };

  useEffect(() => {
    if (params.id) {
      dispatch(getService(params.id));
    }
  }, [params.id]);

  return (
    <div className={styles.serviceUpdate}>
      <div className={styles.serviceUpdate__form}>
        {service && (
          <ServiceForm
            onSubmit={editService}
            schema={serviceSchema}
            service={getInitService(service)}
          />
        )}
      </div>
    </div>
  );
};
export default ServiceUpdate;
