import Layout from "Components/Layout/Layout";
import SettingsLayout from "Components/Settings/Layout/Layout";
import RolesUpdate from "Containers/Settings/Roles/RolesUpdate/RolesUpdate";
import React from "react";
import { BreadcrumbItem } from "Components/Breadcrumb/Breadcrumb";
import { useBreadcrumb } from "Shared/Hooks/use-breadcrumb";
import { useSelector } from "react-redux";
import { selectCurrentRole } from "Redux/Role/slice";

export const RolesUpdatePages: React.FC = () => {
  const role = useSelector(selectCurrentRole);

  const breads: BreadcrumbItem[] = [
    { title: "Settings", link: "/settings/rate-settings/roles" },
    { title: "Role & Access", link: "/settings/rate-settings/roles" },
    { title: `Update ${role?.name || ""}`, link: "" },
  ];
  useBreadcrumb({ links: breads, option: { upd: role?.name } });

  return (
    <Layout>
      <SettingsLayout>
        <RolesUpdate />
      </SettingsLayout>
    </Layout>
  );
};
