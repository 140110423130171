import { BreadcrumbItem } from "Components/Breadcrumb/Breadcrumb";
import { STATUS_COLOR } from "Shared/Constants/app";
import { ICardData } from "Shared/Types/common";
import { IUser } from "Shared/Types/user";
import { ReactNode } from "react";
import * as Yup from "yup";

export const userSchema = Yup.object().shape({
  phone: Yup.string()
    .required("Phone is required")
    .matches(/\d/, "It must contain only digits"),

  email: Yup.string()
    .required("Email is required")
    .email("Invalid email format"),

  first_name: Yup.string().required("First name is required"),

  last_name: Yup.string().required("Last name is required"),
});

export const getEmployeeCardData = (
  employee: IUser,
  titleComponent: ReactNode
) => {
  const data: ICardData[] = [
    {
      title: "Title",
      value: titleComponent,
      isOnlyValue: true,
      withoutBorder: true,
    },
    { title: "Phone Number", value: employee.phone, isWrap: true },
    {
      title: "Email Address",
      value: employee.email,
      isWrap: true,
      isValueBlue: true,
    },
    { title: "Assigned Shop", value: employee.shop?.shop_name },
  ];

  return data;
};

export const getEmployeeCardStatus = (status?: boolean) => {
  const key = status ? "active" : "inactive";

  return STATUS_COLOR[key];
};

export const getBreads = (text?: string) => {
  const breads: BreadcrumbItem[] = [{ title: "Employees", link: "/employees" }];
  if (text) {
    breads.push({ title: text || "", link: "" });
  }

  return breads;
};
