import styles from "./ProfileInfo.module.scss";
import React from 'react'
import HomeFilter from 'Components/Home/HomeFilter/HomeFilter';
import { ProfileDetails } from '../ProfileDetails/ProfileDetails';



 const ProfileInfo = () => {


  return (
   <div >
    <HomeFilter/>
    <div className={styles.profile}>
    <ProfileDetails/>
    </div>
   </div>
  )
}
export default ProfileInfo
