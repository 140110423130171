import React from "react";
import { useSelector } from "react-redux";
import System from "Containers/ComponentSystem/System/System";
import { Accordion } from "Components/UI/Accordion/Accordion";
import { IComponent } from "Shared/Types/component";
import { selectSystemList } from "Redux/System/systemSlice";
import { ComponentItem } from "./ComponentItem";
import styles from "../ComponentSystemContainer.module.scss";
import { useNavigate } from "react-router";

type ComponentProps = {
  component: IComponent;
  index: number;
  expanded: boolean | string;
  handleChange: (
    panel: string
  ) => (event: React.SyntheticEvent, newExpanded: boolean) => void;
};

export const Component: React.FC<ComponentProps> = ({
  component,
  index,
  expanded,
  handleChange,
}) => {
  const { id } = component;
  const systems = useSelector(selectSystemList);

  const navigate = useNavigate();
  const goToCreateSystemPage = () => {
    navigate(`/settings/component-system/${id}/`, {
      state: { addSystem: true },
    });
  };

  return (
    <>
      <Accordion
        item={component}
        key={id}
        expanded={expanded}
        handleChange={handleChange}
        head={(headProps) => (
          <ComponentItem {...headProps} componentId={id} index={index} />
        )}
      >
        <div className={styles.component__system__title}>System</div>
        {systems?.map((system) => (
          <System key={system.id} system={system} componentId={component.id} />
        ))}
        <div
          className={styles.component__system__addLink}
          onClick={goToCreateSystemPage}
        >
          + Add system
        </div>
      </Accordion>
    </>
  );
};
