import React, { useEffect } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import Form from "Components/UI/Form/Form";
import { FormProvider, useForm } from "react-hook-form";
import { ObjectSchema } from "yup";
import styles from "./PartForm.module.scss";
import Button from "Components/UI/Button/Button";
import Block from "Components/UI/Block/Block";
import { Grid } from "@mui/material";
import SelectField from "Components/UI/SelectField/SelectField";
import { IPart, IPartAdd, IPartUpdate } from "Shared/Types/part";
import { VendorSearch } from "Components/UI/AutoCompleteSearchInputs/VendorSearch";
import InputField from "Components/UI/Inputs/InputField";
import { readObj } from "Service/storage";
import StepProgress from "Components/UI/StepProgress/StepProgress";
import usePartFields, { TField } from "Shared/Hooks/part/use-part-fields";

const step = (
  <>
    Finally, let&apos;s add the parts used in your service orders: Fill in part
    details, including name, vendor, and cost. Save the part information.
  </>
);

type PartFormProps = {
  onSubmit: (values: IPartAdd) => void;
  schema: ObjectSchema<IPartAdd>;
  onSkip?: () => void;
  isFirstAuth?: boolean;
  part?: IPart;
};

const PartForm: React.FC<PartFormProps> = ({
  onSubmit,
  schema,
  onSkip,
  isFirstAuth,
  part,
}) => {
  const { fields } = usePartFields();
  const methods = useForm({
    resolver: yupResolver(schema),
  });

  const renderInput = (field: TField) => {
    switch (field.type) {
      case "select":
        return (
          <SelectField
            label={field.label}
            required={field.required}
            options={field.options || []}
            name={field.name}
            control={methods.control}
            withControl
          />
        );
      case "vendor-search":
        return (
          <VendorSearch name={field.name} label={field.label} withControl />
        );
      default:
        return (
          <InputField
            label={field.label}
            required={field.required}
            type={field.name}
            name={field.name}
            control={methods.control}
            withController
            multiline={field.multiline}
            rows={field.rows}
          />
        );
    }
  };

  useEffect(() => {
    if (part) {
      const newPart: IPartUpdate = {
        description: part.description,
      };
      for (const key in part) {
        const curKey = key as keyof IPartUpdate;
        if (part[curKey] !== null) {
          newPart[curKey] = part[curKey] as never;
        }
      }
      methods.reset(newPart);
    }
  }, [part]);
  useEffect(() => {
    const shop = readObj("current_shop");
    if (!part) {
      methods.setValue("shop", shop?.id);
    }
  }, []);
  return (
    <div className={styles.form}>
      {isFirstAuth && !part && (
        <StepProgress title="Managing Part" description={step} active={7} />
      )}
      <FormProvider {...methods}>
        <Form onSubmit={methods.handleSubmit(onSubmit)}>
          <Block>
            <h4 className={styles.form__form__title}>Info</h4>
            <Grid container rowGap={1} columnSpacing={2}>
              {fields.map((field) => (
                <Grid item xs={field.xs} key={field.name}>
                  {renderInput(field)}
                </Grid>
              ))}
            </Grid>
            <div className={styles.form__action}>
              {isFirstAuth && !part && (
                <Button onClick={onSkip} color="secondary" type="button">
                  Skip
                </Button>
              )}
              <Button type="submit">{part ? "Save" : "Create"}</Button>
            </div>
          </Block>
        </Form>
      </FormProvider>
    </div>
  );
};
export default PartForm;
