import React, { useEffect, useState } from "react";
import { historyFieldAllList, historyFieldList } from "Shared/Constants/order";
import { useSelector } from "react-redux";
import { useAppDispatch } from "Redux/store";
import { useNavigate } from "react-router";
import OrderLayout from "Containers/Order/AllOrder/OrderLayout/OrderLayout";
import { getOrderHistory } from "Redux/History/reducer";
import { useSearchParams } from "react-router-dom";
import { selectHistoryList } from "Redux/History/slice";
import { getHistoryCardData } from "Shared/Utils/order";
import { formatBackend } from "Shared/Utils/app";
import { IHistory, IHistorySearchParam } from "Shared/Types/order";
import Card from "Components/UI/Card/Card/Card";
import Row from "Components/UI/Card/Row/Row";

type HistoryListProps = {
  status?: string;
};

const HistoryList: React.FC<HistoryListProps> = () => {
  const [isCard, setIsCard] = useState(true);
  const historyList = useSelector(selectHistoryList) || [];
  const dispatch = useAppDispatch();
  const [orderFields, setOrderFields] = useState(historyFieldList);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const goToDetail = (id?: string) => {
    if (id) {
      navigate("/orders/assigned/" + id);
    }
  };

  const props = (history: IHistory) => {
    return {
      fields: orderFields,
      onClick: goToDetail,
      cardData: getHistoryCardData(history),
      dataId: history.id,
      withoutHead: true,
    };
  };

  useEffect(() => {
    const params: IHistorySearchParam = {
      page: +(searchParams.get("page") || 1),
      vendors: searchParams.get("vendor"),
      employees: searchParams.get("employee"),
      customers: searchParams.get("customer"),
    };
    let from = searchParams.get("from");
    let to = searchParams.get("to");

    if (!from || !to) {
      const currentDate = new Date();
      const thirtyDaysAgo = new Date(
        currentDate.setDate(currentDate.getDate() - 30)
      );
      from = formatBackend(thirtyDaysAgo, "YYYY-MM-DD");
      to = formatBackend(new Date(), "YYYY-MM-DD");
    }
    params.created_at_after = formatBackend(new Date(from), "YYYY-MM-DD");
    params.created_at_before = formatBackend(new Date(to), "YYYY-MM-DD");

    dispatch(getOrderHistory(params));
  }, [searchParams]);
  return (
    <OrderLayout
      fields={orderFields}
      setFields={setOrderFields}
      isCard={isCard}
      setIsCard={setIsCard}
      fieldAllList={historyFieldAllList}
      limitFields={10}
    >
      {isCard
        ? historyList.map((history) => (
            <Card key={history.id} {...props(history)} />
          ))
        : historyList.map((history) => (
            <Row key={history.id} {...props(history)} />
          ))}
    </OrderLayout>
  );
};
export default HistoryList;
