import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router";
import { useSelector } from "react-redux";
import { useAppDispatch } from "Redux/store";
import { notifySuccess } from "Components/Notify/notify";
import styles from "Containers/Settings/Rate/LaborRate/LaborRateUpdate/LaborRateUpdate.module.scss";
import {
  removeCurrentCategory,
  selectCurrentCategory,
} from "Redux/PartSettings/Categories/slice";
import {
  getCategory,
  updateCategory,
} from "Redux/PartSettings/Categories/reducer";
import { ICategoryUpdate } from "Shared/Types/category";
import CategoriesForm from "Components/Settings/PartSettings/Categories/CategoriesForm";
import { categorySchema } from "Shared/Utils/part-settings";

const CategoryUpdate: React.FC = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const category = useSelector(selectCurrentCategory);
  const dispatch = useAppDispatch();

  const back = () => {
    navigate("/settings/part-settings/categories");
  };
  const editCategory = (values: ICategoryUpdate) => {
    const onSuccess = () => {
      notifySuccess("Category updated");
      back();
    };
    if (id) {
      dispatch(updateCategory({ id, data: values, onSuccess }));
    }
  };

  useEffect(() => {
    if (id) {
      dispatch(getCategory(id));
    }
    return () => {
      dispatch(removeCurrentCategory());
    };
  }, [id]);

  return (
    <div className={styles.labor}>
      {category && (
        <CategoriesForm
          category={category}
          onSubmit={editCategory}
          schema={categorySchema}
        />
      )}
    </div>
  );
};

export default CategoryUpdate;
