import { client } from "Service/axios";
import {
  ISupplyRate,
  ISupplyRateAdd,
  ISupplyRateUpdate,
} from "Shared/Types/supplyRate";

const SUPPLY_RATE_URL = "company/supply-rate/";
class SupplyRate {
  getSupplyRateList() {
    return client.get(SUPPLY_RATE_URL);
  }

  getSupplyRate(id: string) {
    return client.get<ISupplyRate>(`${SUPPLY_RATE_URL}${id}/`);
  }

  createSupplyRate(params: ISupplyRateAdd) {
    return client.post(SUPPLY_RATE_URL, params);
  }

  deleteSupplyRate(id: string) {
    return client.delete(`${SUPPLY_RATE_URL}${id}/`);
  }

  updateSupplyRate(id: string, data: ISupplyRateUpdate) {
    return client.patch(`${SUPPLY_RATE_URL}${id}/`, data);
  }
}

export default SupplyRate;
