import React from "react";
import { BreadcrumbItem } from "Components/Breadcrumb/Breadcrumb";
import { useBreadcrumb } from "Shared/Hooks/use-breadcrumb";
import { ESTIMATE_CREATE, estimateStatuses } from "Shared/Constants/estimates";
import Layout from "Components/Layout/Layout";
import EstimatesList from "Containers/Order/Estimates/EstimatesList/EstimatesList";
import { getBreadcrumbs } from "Shared/Utils/estimate";

export function EstimatesAllList() {
  const { link, title } = estimateStatuses.all;
  const breads: BreadcrumbItem[] = getBreadcrumbs(title);

  useBreadcrumb({
    links: breads,
    ...ESTIMATE_CREATE,
  });

  return (
    <Layout>
      <EstimatesList status={link} />
    </Layout>
  );
}
