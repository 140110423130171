export function extractSubstring(url: string | File | null) {
  if (!url) return "";
  if (url instanceof File) {
    return url.name;
  }
  const stringPattern =
    /https:\/\/easyshopback-public-asset-bucket\.s3\.amazonaws\.com\/(.*?)\?AWSAccessKeyId=/;
  const match = url.match(stringPattern);
  if (match && match[1]) {
    return match[1];
  } else {
    return "";
  }
}
