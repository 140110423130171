import React, { useEffect, useState } from "react";
import styles from "./Estimate.module.scss";
import { useAppDispatch } from "Redux/store";
import { useSelector } from "react-redux";
import { selectCurrentOrder } from "Redux/Order/slice";
import { useParams } from "react-router";
import { getOrder } from "Redux/Order/reducer";
import { IActionItem } from "Shared/Types/order";
import { deleteActionItem, entreeInternally } from "Redux/ActionItem/reducer";
import DeleteModal from "Components/UI/DialogWindow/DeleteModal";
import { Grid } from "@mui/material";
import SendAuthorization from "Components/Order/ActionItemDetial/SendAuthorization/SendAuthorization";
import Invoiced from "Components/Order/ActionItemDetial/Invoiced/Invoiced";
import Button from "Components/UI/Button/Button";
import OrderBasic from "./OrderBasic/OrderBasic";
import ActionItemDetailEstimate from "./ActionItemDetialEstimate/ActionItemDetailEstimate";
import { notifySuccess } from "Components/Notify/notify";

const Estimate: React.FC = () => {
  const dispatch = useAppDispatch();
  const currentOrder = useSelector(selectCurrentOrder);
  const { id } = useParams();
  const [actionItemDetails, setActionItemDetails] = useState<IActionItem[]>([]);

  const [deleteId, setDeleteId] = useState<string | null>();
  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  const closeActionItem = (id: string) => {
    const idx = actionItemDetails.findIndex(
      (actionItem) => actionItem.id === id
    );
    if (idx > -1) {
      setActionItemDetails([
        ...actionItemDetails.slice(0, idx),
        ...actionItemDetails.slice(idx + 1),
      ]);
    }
  };

  const sendToCustomer = (email: string) => {
    console.log(email);
  };

  const authorizeInternelly = () => {
    const onSuccess = () => {
      notifySuccess("Action items are updated");
      if (currentOrder?.id) {
        dispatch(getOrder(currentOrder.id));
      }
    };
    if (currentOrder?.id) {
      dispatch(entreeInternally({ id: currentOrder.id, onSuccess }));
    }
  };

  const handleDialogClose = () => {
    setOpenDeleteModal(false);
    setDeleteId(null);
  };

  const handleDialogOpen = (id: string) => {
    setOpenDeleteModal(true);
    setDeleteId(id);
  };

  const handleDeleteOrder = () => {
    const onSuccess = () => {
      setOpenDeleteModal(false);
      closeActionItem(deleteId as string);
      notifySuccess("Order is deleted");
      if (currentOrder?.id) {
        dispatch(getOrder(currentOrder.id));
      }
    };
    if (deleteId) {
      dispatch(deleteActionItem({ id: deleteId, onSuccess }));
    }
  };

  useEffect(() => {
    if (id) {
      dispatch(getOrder(id));
    }
  }, [id]);

  useEffect(() => {
    setActionItemDetails(currentOrder?.actionitem_set || []);
  }, [currentOrder]);
  if (!currentOrder) return null;
  return (
    <div className={styles.action}>
      <OrderBasic order={currentOrder} />
      {actionItemDetails.map((actionItem, index) => (
        <ActionItemDetailEstimate
          onClose={() => closeActionItem(actionItem.id)}
          actionItem={actionItem}
          key={actionItem.id}
          openDelete={handleDialogOpen}
          index={index + 1}
        />
      ))}
      <DeleteModal
        acceptTitle="Delete"
        open={openDeleteModal}
        handleClose={handleDialogClose}
        title="Delete"
        text="Are you sure...?"
        accept={handleDeleteOrder}
      />
      <span className={styles.action__addAction}>
        + Add another action item
      </span>
      <Grid container columnSpacing={1} mt="20px">
        <Grid item xs={6}>
          <SendAuthorization onClick={sendToCustomer} />
        </Grid>
        <Grid item xs={6}>
          <Invoiced order={currentOrder} />
        </Grid>
      </Grid>
      <div className={styles.action__btns}>
        <Button>Capture Signature</Button>
        <div>
          <Button color="secondary">Add misc change</Button>
          <Button onClick={authorizeInternelly}>Authorize Internally</Button>
        </div>
      </div>
    </div>
  );
};
export default Estimate;
