import { client } from "Service/axios";
import {
  IComponent,
  IComponentCreate,
  IComponentUpdate,
} from "Shared/Types/component";

const COMPONENT_URL = "service/component/";
class Component {
  getComponentList() {
    return client.get(COMPONENT_URL);
  }
  getComponent(id: string) {
    return client.get<IComponent>(`${COMPONENT_URL}${id}/`);
  }

  createComponent(params: IComponentCreate) {
    return client.post(COMPONENT_URL, params);
  }

  deleteComponent(id: string) {
    return client.delete(`${COMPONENT_URL}${id}/`);
  }
  updateComponent(params: IComponentUpdate) {
    const { id, name } = params;
    return client.patch(`${COMPONENT_URL}${id}/`, { name });
  }
}

export default Component;
