export const customerTableHeads = [
  {
    id: "company_name",
    label: "Company name",
  },
  {
    id: "full_name",
    label: "Full Name",
  },
  {
    id: "email",
    label: "Email",
  },
  {
    id: "phone",
    label: "Phone",
  },
  {
    id: "Unit",
    label: "unit",
  },
  {
    id: "action",
    label: "",
  },
];
export const customerOnDeleteDependencyOccursMessage =
  "This customer is still being utilized in the items listed below. Before you can delete this customer, you must first remove their dependencies from the following items.";
export const customerFields = [
  "Company Name",
  "Unit",
  "Full Name",
  "Phone Number",
  "Email Address",
];

export const customerPriceLevel = [
  { label: "Fleet", value: "fleet" },
  { label: "List", value: "list" },
  { label: "Whole sale", value: "wholesale" },
];
