import React from "react";
import { BreadcrumbItem } from "Components/Breadcrumb/Breadcrumb";
import { useBreadcrumb } from "Shared/Hooks/use-breadcrumb";
import RequestReviewedList from "Containers/Requests/List/RequestReviewedList";

export function RequestsReview() {
  const breads: BreadcrumbItem[] = [
    { title: "Requests", link: "/requests" },
    { title: "Review", link: "review" },
  ];

  useBreadcrumb({ links: breads });

  return <RequestReviewedList />;
}
