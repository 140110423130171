import React, { ReactNode } from "react";
import ReactDOM from "react-dom";

type PortalProps = {
  children: ReactNode;
  position: {
    top: number;
    left: number;
  };
};
const Portal: React.FC<PortalProps> = ({ children, position }) => {
  const portalRoot = document.getElementById("menu-portal-root");
  if (!portalRoot) return null;
  portalRoot.style.position = "absolute";
  portalRoot.style.top = `${position.top}px`;
  portalRoot.style.left = `${position.left}px`;

  return ReactDOM.createPortal(children, portalRoot);
};

export default Portal;
