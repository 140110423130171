import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "Service/api/api";
import {
  IDispatchCallback,
  IDispatchCallbackWithId,
  IDispatchCallbackWithoutData,
} from "Shared/Types/common";
import {
  ISystemCreate,
  ISystemSearchParams,
  ISystemUpdate,
} from "Shared/Types/system";
import { errorHandler } from "Shared/Utils/app";

const getSystemList = createAsyncThunk(
  "system/getSystemList",
  async (params?: ISystemSearchParams | null) => {
    try {
      const response = await api.system.getSystemList(params);
      return response.data;
    } catch (error) {
      if (error instanceof Error) {
        errorHandler(error);
      }
      throw error;
    }
  }
);

const getSystem = createAsyncThunk("system/getSystem", async (id: string) => {
  try {
    const response = await api.system.getSystem(id);
    return response.data;
  } catch (error) {
    if (error instanceof Error) {
      errorHandler(error);
    }
    throw error;
  }
});

const createSystem = createAsyncThunk(
  "system/createSystemList",
  async ({ data, onSuccess }: IDispatchCallback<ISystemCreate>) => {
    try {
      const response = await api.system.createSystem(data);
      onSuccess?.();
      return response.data;
    } catch (error) {
      if (error instanceof Error) {
        errorHandler(error, true);
      }
      throw error;
    }
  }
);

const deleteSystem = createAsyncThunk(
  "system/deleteSystem",
  async ({ id, onSuccess }: IDispatchCallbackWithoutData) => {
    try {
      const response = await api.system.deleteSystem(id);
      onSuccess?.();
      return response.data;
    } catch (error) {
      if (error instanceof Error) {
        errorHandler(error, true);
      }
      throw error;
    }
  }
);

const updateSystem = createAsyncThunk(
  "system/updateSystem",
  async ({ data, onSuccess }: IDispatchCallbackWithId<ISystemUpdate>) => {
    try {
      const response = await api.system.updateSystem(data);
      onSuccess?.();
      return response.data;
    } catch (error) {
      if (error instanceof Error) {
        errorHandler(error, true);
      }
      throw error;
    }
  }
);

export { getSystemList, createSystem, deleteSystem, updateSystem, getSystem };
