import React, { useEffect, useState } from "react";
import { useAppDispatch } from "Redux/store";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import ListLayout from "Components/Layout/ListLayout/ListLayout";
import { useSearchParams } from "react-router-dom";
import { selectPartNumPage, selectPricePartList } from "Redux/Product/slice";
import { partFields, partPriceFields } from "Shared/Constants/part";
import Card from "Components/UI/Card/Card/Card";
import Row from "Components/UI/Card/Row/Row";
import { IOrderList } from "Shared/Types/order";
import { getPartPriceCardData } from "Shared/Utils/part";
import { getPricePartList } from "Redux/Product/reducer";

const PricePartList: React.FC = () => {
  const parts = useSelector(selectPricePartList) || [];
  const numPage = useSelector(selectPartNumPage);
  const dispatch = useAppDispatch();
  const [isCard, setIsCard] = useState(true);
  const [fields, setFields] = useState(partPriceFields);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const goToDetailPage = (id: string) => {
    navigate(`/orders/assigned/${id}`, { state: { tab: "part" } });
  };

  const props = (part: IOrderList) => {
    return {
      fields: fields,
      onClick: goToDetailPage,
      cardData: getPartPriceCardData(part),
      dataId: part.id,
      status: {
        color: "red",
        title: "Needs Quote",
      },
      isNeedStatus: true,
      withoutMore: true,
    };
  };

  useEffect(() => {
    dispatch(
      getPricePartList({
        page: +(searchParams.get("page") || 1),
      })
    );
  }, [searchParams]);

  return (
    <ListLayout
      pagesNumber={numPage || 1}
      isCard={isCard}
      setIsCard={setIsCard}
      fields={fields}
      setFields={setFields}
      filters={["search", "customer", "unit", "date"]}
      fieldAllList={partFields}
    >
      {isCard
        ? parts.map((part) => <Card key={part.id} {...props(part)} />)
        : parts.map((part) => <Row key={part.id} {...props(part)} />)}
    </ListLayout>
  );
};
export default PricePartList;
