import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

interface IconProps extends Omit<SvgIconProps, "color" | "width" | "height"> {
  alt?: string;
  color?: string;
  component: React.ElementType;
  width?: number | "auto";
  height?: number | "auto";
  clickable?: boolean;
}

const Icon: React.FC<IconProps> = ({
  color = "none",
  component,
  width = "auto",
  fontSize = "inherit",
  height = "auto",
  ...props
}) => {
  return (
    <SvgIcon
      sx={{
        ":hover": {
          cursor: "pointer",
        },
      }}
      component={component}
      color="primary"
      inheritViewBox={true}
      style={{ fill: color, width: width, height: height }}
      fontSize={fontSize}
      {...props}
    />
  );
};

export default Icon;
