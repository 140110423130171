import { client } from "Service/axios";
import { SearchResult } from "Shared/Types/common";
import {
  IService,
  IServiceAdd,
  IServiceSearchParams,
  IServiceUpdate,
} from "Shared/Types/service";

const SERVICE_URL = "service/shop/";

class Service {
  getServiceList(params: IServiceSearchParams | null) {
    return client.get<SearchResult<IService>>(SERVICE_URL, { params });
  }
  getService(id: string) {
    return client.get<IService>(`${SERVICE_URL}${id}/`);
  }
  addService(data: IServiceAdd) {
    return client.post(SERVICE_URL, data);
  }
  updateService(id: string, data: IServiceUpdate) {
    return client.put(`${SERVICE_URL}${id}/`, data);
  }
  deleteService(id: string) {
    return client.delete(`${SERVICE_URL}${id}/`);
  }
}

export default Service;
