import React from "react";
import Layout from "Components/Layout/Layout";
import SubtypeUpdate from "Containers/Subtype/SubTypeUpdate/SubtypeUpdate";
import SettingsLayout from "Components/Settings/Layout/Layout";
import { BreadcrumbItem } from "Components/Breadcrumb/Breadcrumb";
import { useBreadcrumb } from "Shared/Hooks/use-breadcrumb";
import { useSelector } from "react-redux";
import { selectCurrentSubtype } from "Redux/Subtype/slice";

export const SubtypeUpdatePage: React.FC = () => {
  const subtype = useSelector(selectCurrentSubtype);

  const breads: BreadcrumbItem[] = [
    { title: "Setting", link: "/unit-settings/subtypes" },
    { title: "Subtypes", link: "/unit-settings/subtypes" },
    { title: `Update ${subtype?.name || ""}`, link: "" },
  ];
  useBreadcrumb({ links: breads, option: { upd: subtype?.name } });

  return (
    <Layout>
      <SettingsLayout>
        <SubtypeUpdate />
      </SettingsLayout>
    </Layout>
  );
};
