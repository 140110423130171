import { Grid } from "@mui/material";
import Block from "Components/UI/Block/Block";
import InputField from "Components/UI/Inputs/InputField";
import usePurchaseOrderFields from "Shared/Hooks/purchase-order/use-purchase-order-fields";
import React from "react";
import { useFormContext } from "react-hook-form";
import styles from "./ReceivePart.module.scss";

const ReceivePart: React.FC = () => {
  const { receiveFields } = usePurchaseOrderFields();
  const { control } = useFormContext();
  return (
    <Block isBorder title="Receive Parts" className={styles.receive}>
      {receiveFields.map((field) => (
        <Grid item xs={field.xs} key={field.label}>
          <InputField
            name={field.name}
            label={field.label}
            required
            withController
            control={control}
            disabled={field.disabled}
          />
        </Grid>
      ))}
    </Block>
  );
};
export default ReceivePart;
