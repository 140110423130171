import React, { useState } from "react";
import Modal from "Components/UI/Modal/Modal";
import SelectField from "Components/UI/SelectField/SelectField";
import { selectVendorList } from "Redux/Vendor/slice";
import { changeVendorOptions } from "Shared/Utils/app";
import { useSelector } from "react-redux";
import { useAppDispatch } from "Redux/store";
import { getVendorList } from "Redux/Vendor/reducer";
import { IActionPart } from "Shared/Types/action-item-part";
import { usePartListOperation } from "Shared/Hooks/order/part-list/use-part-list-operation";

type AddQuoteModalProps = {
  open: boolean;
  setOpen: (open: boolean) => void;
  selectedParts: IActionPart[];
  setSelectedParts: (parts: IActionPart[]) => void;
};

const AddQuoteModal: React.FC<AddQuoteModalProps> = ({
  open,
  setOpen,
  selectedParts,
  setSelectedParts,
}) => {
  const dispatch = useAppDispatch();
  const [selectVendor, setSelectedVendor] = useState<string>();
  const vendors = useSelector(selectVendorList) || [];
  const { addQuote } = usePartListOperation({
    selectedParts,
    onClose: setOpen,
    setSelectedParts,
  });

  const saveQuote = () => {
    if (selectVendor) {
      addQuote(selectVendor);
    }
  };

  const searchVendor = (text?: string | null) => {
    dispatch(getVendorList({ search: text || null }));
  };

  return (
    <Modal
      open={open}
      title="Quote"
      acceptFunc={saveQuote}
      isClosed
      isNeedAction
      acceptTitle="Submit"
      onCancel={() => setOpen(false)}
      onClose={() => setOpen(false)}
    >
      <SelectField
        label="Select Vendor"
        name="vendor"
        options={changeVendorOptions(vendors)}
        onChange={(vendorId) => setSelectedVendor(vendorId?.toString())}
        onInputChange={(text) => searchVendor(text)}
      />
    </Modal>
  );
};
export default AddQuoteModal;
