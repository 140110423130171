import React from "react";
import AuthLayout2 from "Components/Layout/AuthLayout/AuthLayout";
import InputField from "Components/Layout/Inputs/InputField";
import { EmailIcon } from "Assets/index";
import Button from "Components/Layout/Button/Button";
import { useAppDispatch } from "Redux/store";
import { resetPasswordByEmail } from "Redux/Auth/reducers";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useSelector } from "react-redux";
import { selectAuthLoading } from "Redux/Auth/slice";
import { forgotPasswordSchema } from "Shared/Utils/auth";
import { FORGOT_PASSWORD, forgotPasswordLinks } from "Shared/Constants/auth";

const ForgotPasswordByEmailContainer = () => {
  const dispatch = useAppDispatch();
  const loading = useSelector(selectAuthLoading);

  const { control, handleSubmit } = useForm({
    resolver: yupResolver(forgotPasswordSchema),
  });

  const handleSendEmailForResetPassword = (values: { email: string }) => {
    dispatch(resetPasswordByEmail(values.email));
  };

  return (
    <AuthLayout2 links={forgotPasswordLinks} title={FORGOT_PASSWORD.TITLE}>
      <form onSubmit={handleSubmit(handleSendEmailForResetPassword)}>
        <InputField
          placeholder="email"
          name="email"
          icon={EmailIcon}
          required
          control={control}
          withController
        />

        <Button
          loading={loading}
          variant="contained"
          color="white"
          type="submit"
          fullWidth
        >
          Send
        </Button>
      </form>
    </AuthLayout2>
  );
};

export default ForgotPasswordByEmailContainer;
