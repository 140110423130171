import React, { useState } from "react";
import styles from "./Sidebar.module.scss";
import SelectField, {
  OptionsProps,
} from "Components/UI/SelectField/SelectField";
import cn from "classnames";

const ClockIn = () => {
  const [currentCountry, setCurrentCountry] = useState<
    string | number | undefined
  >("0");

  const arr: OptionsProps[] = [
    { label: "Clock Out", value: "0" },
    { label: "Clock In", value: "1" },
    { label: "Break", value: "2" },
    { label: "Misc Shop Work", value: "3" },
    { label: "Cleanup", value: "4" },
    { label: "Shop Meeting", value: "5" },
  ];
  const getValue = (value?: string | number) => {
    setCurrentCountry(value);
  };
  return (
    <div
      className={cn(
        currentCountry === "0" && styles.sidebar__clockOut,
        currentCountry === "1" && styles.sidebar__clockIn,
        currentCountry === "2" && styles.sidebar__clockIn_break,
        currentCountry === "3" && styles.sidebar__clockIn_misc,
        currentCountry === "4" && styles.sidebar__clockIn_cleanup,
        currentCountry === "5" && styles.sidebar__clockIn_shop,
      )}
    >
      <SelectField
        style={{ color: "white" }}
        onChange={getValue}
        value={currentCountry}
        defaultValue={{ label: "Break", value: 1 }}
        options={arr}
        name="clock_in"
        theme="secondary"
        isSearchable={false}
        textAlign="center"
      />
    </div>
  );
};

export default ClockIn;
