import { client } from "Service/axios";
import { readObj } from "Service/storage";
import { SearchResult } from "Shared/Types/common";
import { IOrderNoteAdd, IOrderNoteSearchParams } from "Shared/Types/notes";
import {
  IActionItemPartSearchParams,
  IHistory,
  IHistorySearchParam,
  IInnerHistory,
  IOrder,
  IOrderCountStatus,
  IOrderList,
  IOrderNotification,
  IOrderSearchParams,
  IOrderStatus,
  IOrderUpdate,
  IOrderUpdateByField,
} from "Shared/Types/order";
const ORDER_URL = "order/";

class Order {
  getOrderList(params: IOrderSearchParams | null) {
    return client.get<SearchResult<IOrderList>>(`${ORDER_URL}`, { params });
  }
  getOrder(id: string) {
    const shop = readObj("current_shop");
    return client.get<IOrder>(`${ORDER_URL}${id}/`, {
      params: { shop: shop?.id },
    });
  }

  addOrder(data: FormData) {
    return client.post(`${ORDER_URL}create/`, data, {
      headers: { "Content-Type": "multipart/form-data" },
    });
  }
  updateOrder(id: string, data: IOrderUpdate | FormData) {
    return client.patch(`${ORDER_URL}${id}/update/`, data, {
      headers: { "Content-Type": "multipart/form-data" },
    });
  }
  updateOrderByField(id: string, data: IOrderUpdateByField) {
    return client.patch(`${ORDER_URL}${id}/update/`, data);
  }
  deleteOrder(id: string) {
    return client.delete(`${ORDER_URL}${id}/delete/`);
  }
  getOrderStatuses() {
    return client.get<IOrderStatus[]>(`${ORDER_URL}status/`);
  }

  getOrderPartStatusList() {
    return client.get<IOrderStatus[]>(`${ORDER_URL}so_part_status/`);
  }
  markAsReadedNotification(id: string) {
    return client.patch<IOrderNotification>(
      `${ORDER_URL}notification/${id}/mark_as_read/`,
      { is_read: true }
    );
  }
  getOrderNotification() {
    return client.get<IOrderNotification[]>(`${ORDER_URL}notification_list/`);
  }
  getOrderCountByStatus(params: IOrderSearchParams) {
    return client.get<IOrderCountStatus>(
      `${ORDER_URL}order_counted_by_status_list/`,
      {
        params,
      }
    );
  }
  getOrderHistory(params: IHistorySearchParam | null) {
    return client.get<SearchResult<IHistory>>(`${ORDER_URL}history/`, {
      params,
    });
  }
  getOrderInnerHistory(id: string) {
    return client.get<IInnerHistory[]>(`${ORDER_URL}so_inner_history/`, {
      params: {
        order: id,
      },
    });
  }
  addOrderNote(data: IOrderNoteAdd) {
    return client.post(`${ORDER_URL}note/`, data);
  }
  getListOrderNote(params: IOrderNoteSearchParams) {
    return client.get(`${ORDER_URL}note/`, {
      params,
    });
  }
  deleteOrderNote(id: string) {
    return client.delete(`${ORDER_URL}note/${id}/`);
  }
  getOrderActionItemParts(params: IActionItemPartSearchParams) {
    return client.get(`${ORDER_URL}action_item_part/`, {
      params,
    });
  }
}

export default Order;
