import React from "react";
import styles from "./FieldChange.module.scss";
import { Checkbox } from "@mui/material";
import { CloseIcon } from "Assets/index";
import Icon from "Components/UI/Icon/Icon";
import cn from "classnames";
import { notifyInfo } from "Components/Notify/notify";

type FieldChangeProps = {
  onClose?: () => void;
  orderFields: string[];
  setOrderFields: (arr: string[]) => void;
  allFields: string[];
  limit?: number;
};
const FieldChange: React.FC<FieldChangeProps> = ({
  onClose,
  orderFields,
  setOrderFields,
  allFields,
  limit = 20,
}) => {
  const changeFields = (field: string) => {
    const index = orderFields.indexOf(field);
    if (index > -1) {
      setOrderFields([
        ...orderFields.slice(0, index),
        ...orderFields.slice(index + 1),
      ]);
    } else {
      setOrderFields([...orderFields, field]);
    }
  };

  return (
    <div className={styles.filter}>
      <div className={styles.filter__head}>
        <h2>Manage column</h2> <Icon component={CloseIcon} onClick={onClose} />
      </div>
      <div className={styles.filter__content}>
        {allFields.map((filterItem) => (
          <div
            key={filterItem}
            className={cn(
              styles.filter__content__item,
              orderFields.includes(filterItem) &&
                styles.filter__content__item_active
            )}
          >
            <span>{filterItem}</span>{" "}
            <Checkbox
              checked={orderFields.includes(filterItem)}
              onChange={(event) => {
                if (event.target.checked && orderFields.length === limit) {
                  notifyInfo(`You cannot choose more than ${limit} fields.`);
                  return;
                }
                changeFields(filterItem);
              }}
              sx={{
                color: "white",
              }}
            />
          </div>
        ))}
      </div>
    </div>
  );
};
export default FieldChange;
