import React from "react";
import Layout from "Components/Layout/Layout";
import UnitTypeList from "Containers/UnitType/UnitTypeList/UnitTypeList";
import SettingsLayout from "Components/Settings/Layout/Layout";
import { BreadcrumbItem } from "Components/Breadcrumb/Breadcrumb";
import { useBreadcrumb } from "Shared/Hooks/use-breadcrumb";

export const UnitTypeListPage: React.FC = () => {
  const breads: BreadcrumbItem[] = [
    { title: "Setting", link: "/settings/unit-settings/unit-types" },
    { title: "Unit Types", link: "" },
  ];
  useBreadcrumb({ links: breads });

  return (
    <Layout>
      <SettingsLayout>
        <UnitTypeList />
      </SettingsLayout>
    </Layout>
  );
};
