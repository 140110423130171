import { PERMISSIONS } from "./app";

export const orderPermissions = {
  perTitle: "Order",
  list: [
    PERMISSIONS.add_order,
    PERMISSIONS.change_order,
    PERMISSIONS.delete_order,
    PERMISSIONS.view_order,
    PERMISSIONS.change_orderfile,
    PERMISSIONS.delete_orderfile,
    PERMISSIONS.view_orderfile,
    PERMISSIONS.add_orderfile,
    PERMISSIONS.change_ordergallery,
    PERMISSIONS.delete_ordergallery,
    PERMISSIONS.view_ordergallery,
    PERMISSIONS.add_ordergallery,
    PERMISSIONS.view_orderhistory,
    PERMISSIONS.view_serviceorderstatus,
    PERMISSIONS.view_sopartstatus,
    PERMISSIONS.view_serviceorderstatus,
    PERMISSIONS.view_sourgencyoption,
  ],
};

export const actionItemPermissions = {
  perTitle: "Action Item",
  list: [
    PERMISSIONS.add_actionitem,
    PERMISSIONS.change_actionitem,
    PERMISSIONS.delete_actionitem,
    PERMISSIONS.view_actionitem,
    PERMISSIONS.add_actionitemfile,
    PERMISSIONS.change_actionitemfile,
    PERMISSIONS.delete_actionitemfile,
    PERMISSIONS.view_actionitemfile,
    PERMISSIONS.add_actionitemgallery,
    PERMISSIONS.change_actionitemgallery,
    PERMISSIONS.delete_actionitemgallery,
    PERMISSIONS.view_actionitemgallery,
    PERMISSIONS.view_actionitemhistory,
    PERMISSIONS.add_actionitemnote,
    PERMISSIONS.change_actionitemnote,
    PERMISSIONS.delete_actionitemnote,
    PERMISSIONS.add_actionitempart,
    PERMISSIONS.change_actionitempart,
    PERMISSIONS.delete_actionitempart,
    PERMISSIONS.view_actionitempart,
    PERMISSIONS.view_soactionitemstatus,
  ],
};
export const partPermissions = {
  perTitle: "Part",
  list: [
    PERMISSIONS.add_part,
    PERMISSIONS.change_part,
    PERMISSIONS.delete_part,
    PERMISSIONS.view_part,
    PERMISSIONS.add_partlocation,
    PERMISSIONS.change_partlocation,
    PERMISSIONS.delete_partlocation,
    PERMISSIONS.view_partlocation,
  ],
};
export const shopPermissions = {
  perTitle: "Shop",
  list: [
    PERMISSIONS.add_shop,
    PERMISSIONS.change_shop,
    PERMISSIONS.delete_shop,
    PERMISSIONS.view_shop,
  ],
};
export const servicePermissions = {
  perTitle: "Service",
  list: [
    PERMISSIONS.add_shopservice,
    PERMISSIONS.change_shopservice,
    PERMISSIONS.delete_shopservice,
    PERMISSIONS.view_shopservice,
  ],
};
export const unitPermissions = {
  perTitle: "Unit",
  list: [
    PERMISSIONS.add_unit,
    PERMISSIONS.change_unit,
    PERMISSIONS.delete_unit,
    PERMISSIONS.view_unit,
  ],
};
export const vendorPermissions = {
  perTitle: "Vendor",
  list: [
    PERMISSIONS.add_vendor,
    PERMISSIONS.change_vendor,
    PERMISSIONS.delete_vendor,
    PERMISSIONS.view_vendor,
  ],
};
export const employeePermissions = {
  perTitle: "Employee",
  list: [
    PERMISSIONS.add_user,
    PERMISSIONS.change_user,
    PERMISSIONS.delete_user,
    PERMISSIONS.view_user,
  ],
};
export const categoryPermissinos = {
  perTitle: "Category",
  list: [
    PERMISSIONS.add_category,
    PERMISSIONS.change_category,
    PERMISSIONS.delete_category,
    PERMISSIONS.view_category,
  ],
};
export const manufacturerPermissions = {
  perTitle: "Manufacturer",
  list: [
    PERMISSIONS.add_manufacturer,
    PERMISSIONS.change_manufacturer,
    PERMISSIONS.delete_manufacturer,
    PERMISSIONS.view_manufacturer,
  ],
};
export const locationPermissions = {
  perTitle: "Location",
  list: [
    PERMISSIONS.add_location,
    PERMISSIONS.change_location,
    PERMISSIONS.delete_location,
    PERMISSIONS.view_location,
  ],
};
export const laborRatePermissions = {
  perTitle: "Labor Rate",
  list: [
    PERMISSIONS.add_laborrate,
    PERMISSIONS.change_laborrate,
    PERMISSIONS.delete_laborrate,
    PERMISSIONS.view_laborrate,
  ],
};
export const taxRatePermissions = {
  perTitle: "Tax Rate",
  list: [
    PERMISSIONS.add_taxrate,
    PERMISSIONS.change_taxrate,
    PERMISSIONS.delete_taxrate,
    PERMISSIONS.view_taxrate,
  ],
};
export const supplyRatePermissions = {
  perTitle: "Supply Rate",
  list: [
    PERMISSIONS.add_supplyrate,
    PERMISSIONS.change_supplyrate,
    PERMISSIONS.delete_supplyrate,
    PERMISSIONS.view_supplyrate,
  ],
};
export const subTypePermissins = {
  perTitle: "Subtype",
  list: [
    PERMISSIONS.add_subtype,
    PERMISSIONS.change_subtype,
    PERMISSIONS.delete_subtype,
    PERMISSIONS.view_subtype,
  ],
};
export const unitTypePermissions = {
  perTitle: "Unit Type",
  list: [
    PERMISSIONS.add_typeofchassis,
    PERMISSIONS.change_typeofchassis,
    PERMISSIONS.delete_typeofchassis,
    PERMISSIONS.view_typeofchassis,
  ],
};
export const componentSystemPermissions = {
  perTitle: "Component & System",
  list: [
    PERMISSIONS.add_component,
    PERMISSIONS.change_component,
    PERMISSIONS.delete_component,
    PERMISSIONS.view_component,
    PERMISSIONS.add_system,
    PERMISSIONS.change_system,
    PERMISSIONS.delete_system,
    PERMISSIONS.view_system,
  ],
};
export const rolePermissions = {
  perTitle: "Role & Access",
  list: [
    PERMISSIONS.add_role,
    PERMISSIONS.change_role,
    PERMISSIONS.delete_role,
    PERMISSIONS.view_role,
    PERMISSIONS.view_rolepermission,
  ],
};
export const notifactionPermissions = {
  perTitle: "Notification",
  list: [PERMISSIONS.view_notification],
};

const onlyVeiwPermissions = {
  part: {
    perTitle: "Part",
    list: [PERMISSIONS.view_part],
  },
  service: {
    perTitle: "Service",
    list: [PERMISSIONS.view_shopservice],
  },
  unit: {
    perTitle: "Unit",
    list: [PERMISSIONS.view_unit],
  },
  vendor: {
    perTitle: "Vendor",
    list: [PERMISSIONS.view_vendor],
  },
  employee: {
    perTitle: "Employee",
    list: [PERMISSIONS.view_user],
  },

  laborRate: {
    perTitle: "Labor Rate",
    list: [PERMISSIONS.view_laborrate],
  },
  taxRate: {
    perTitle: "Tax Rate",
    list: [PERMISSIONS.view_taxrate],
  },
  supplyRate: {
    perTitle: "Supply Rate",
    list: [PERMISSIONS.view_supplyrate],
  },
};
type IPermissionField = keyof typeof onlyVeiwPermissions;
export const getViewPermissions = (field: IPermissionField) => {
  return onlyVeiwPermissions[field];
};
