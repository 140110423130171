import React, { useState } from "react";
import { Grid } from "@mui/material";
import ComplaintPartActionButtons from "./ComplaintPartActionButtons";
import ComplaintPartInputFields from "./ComplaintPartInputFields";
import styles from "../ComplaintPartForm.module.scss";
import { IEstimatePartUpdate, IEstimatesPartAdd } from "Shared/Types/estimate";

interface IComplaintPartFormProps {
  complaintIndex: number;
  partIndex: number;
  addNewPart: (part: IEstimatesPartAdd, id: number) => void;
  deletePart: (index: number, id: string) => void;
  updatePart: (id: string, data: IEstimatePartUpdate) => void;
  isAddedPart?: boolean;
}

const ComplaintPartFields: React.FC<IComplaintPartFormProps> = ({
  complaintIndex,
  partIndex,
  addNewPart,
  deletePart,
  updatePart,
  isAddedPart = false,
}) => {
  const [isEdit, setIsEdit] = useState(false);

  return (
    <Grid container className={styles.form}>
      <ComplaintPartInputFields
        complaintIndex={complaintIndex}
        partIndex={partIndex}
        isAddedPart={isAddedPart}
        isEdit={isEdit}
      />
      <ComplaintPartActionButtons
        complaintIndex={complaintIndex}
        partIndex={partIndex}
        isAddedPart={isAddedPart}
        isEdit={isEdit}
        setIsEdit={setIsEdit}
        addNewPart={addNewPart}
        deletePart={deletePart}
        updatePart={updatePart}
      />
    </Grid>
  );
};
export default ComplaintPartFields;
