import React, { useEffect, useRef } from "react";
import * as echarts from "echarts";

const CurveChart: React.FC = () => {
  const chartRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (chartRef.current) {
      const myChart = echarts.init(chartRef.current);

      const option = {
        background: "transparent",
        xAxis: {
          axisLabel: {
            show: false,
          },
          type: "category",
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
        },
        yAxis: {
          type: "value",
          axisTick: {
            inside: true,
          },
          splitLine: {
            show: false,
          },
          axisLabel: {
            show: false,
          },
        },
        series: [
          {
            name: "Fake Data",
            type: "line",
            smooth: true,
            symbolSize: 0,
            sampling: "average",
            itemStyle: {
              color: "#34B27A",
            },
            stack: "a",
            areaStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: "#34B27A",
                },
                {
                  offset: 1,
                  color: "transparent",
                },
              ]),
            },
            data: [0, 5, 16, 20, 15, 10, 15, 30, 39],
          },
        ],
      };

      window.addEventListener('resize', function() {
        myChart.resize();
      });
      
      option && myChart.setOption(option);

      return () => {
        myChart.dispose();
      };
    }
  }, []);

  return <div ref={chartRef} style={{ width: "30%", height: "70px" }} />;
};

export default CurveChart;
