import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import styles from "./DeleteModal.module.scss";
import Button from "../Button/Button";
import Icon from "../Icon/Icon";
import { CloseIcon } from "Assets/index";
import cn from "classnames";

interface DeleteModalProps {
  open: boolean;
  handleClose: (() => void) | ((event: React.MouseEvent) => void);
  title: string;
  text: string;
  accept: (() => void) | ((event: React.MouseEvent) => void);
  acceptTitle?: "Block" | "Unblock" | string;
  color?: "red" | "green";
}

const DeleteModal: React.FC<DeleteModalProps> = ({
  open,
  handleClose,
  title,
  text,
  accept,
  acceptTitle,
  color = "red",
}) => {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth
      maxWidth="sm"
      classes={{
        root: cn(
          styles.modal,
          color === "red" ? styles.modal__red : styles.modal__green,
        ),
      }}
    >
      <DialogTitle
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <span className={styles.modal__head__title}>{title}</span>
        <Icon onClick={handleClose} component={CloseIcon} />
      </DialogTitle>
      <DialogContent>
        <DialogContentText>{text}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          className={styles.modal__cancelBtn}
          autoFocus
          color="secondary"
          onClick={handleClose}
        >
          Cancel
        </Button>
        <Button className={styles.modal__actionBtn} onClick={accept}>
          {acceptTitle}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteModal;
