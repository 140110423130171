import React, { useEffect, useState } from "react";
import Block from "Components/UI/Block/Block";
import InfoField from "Components/UI/InfoField/InfoField";
import { useNavigate, useParams } from "react-router";
import { useAppDispatch } from "Redux/store";
import { useSelector } from "react-redux";
import styles from "./CustomerDetails.module.scss";
import DeleteModal from "Components/UI/DialogWindow/DeleteModal";
import { deleteCustomer, getCustomer } from "Redux/Customer/reducer";
import {
  selectCurrentCustomer,
  selectCustomerSuccess,
} from "Redux/Customer/slice";
import { getCustomerDetail } from "Shared/Utils/customer";
const CustomerDetails = () => {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const success = useSelector(selectCustomerSuccess);

  const [showDelete, setShowDelete] = useState(false);
  const customer = useSelector(selectCurrentCustomer);
  const { customerDetail, billingInfo } = getCustomerDetail(customer);
  const handleDialogClose = () => {
    setShowDelete(false);
  };

  const handleDeleteShop = async () => {
    if (id) {
      await dispatch(deleteCustomer(id));
    }
  };

  useEffect(() => {
    if (success) {
      navigate("/customers");
    }
  }, [success]);

  useEffect(() => {
    if (id) {
      dispatch(getCustomer(id));
    }
  }, [id]);

  return (
    <div className={styles.detail}>
      <Block className={styles.detail__block}>
        <h3>Basic Info</h3>
        {customerDetail.map(({ label, value, labelColor, mt }) => (
          <InfoField
            key={label}
            label={label}
            value={value?.toString()}
            mt={mt}
            labelColor={labelColor}
          />
        ))}
      </Block>
      <Block className={styles.detail__block} mt={20}>
        <h3>Address/ Billing Info</h3>
        {billingInfo.map(({ label, value, labelColor, mt }) => (
          <InfoField
            label={label}
            key={label}
            value={value}
            mt={mt}
            labelColor={labelColor}
          />
        ))}
      </Block>
      <DeleteModal
        open={showDelete}
        handleClose={handleDialogClose}
        title="Delete"
        text="Are you sure...?"
        acceptTitle="Delete"
        accept={handleDeleteShop}
      />
    </div>
  );
};

export default CustomerDetails;
