import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "Service/api/api";
import {
  IDispatchCallback,
  IDispatchCallbackWithId,
  IDispatchCallbackWithoutData,
} from "Shared/Types/common";
import { IUserAdd, IUserSearchParams, IUserUpdate } from "Shared/Types/user";
import { getMe } from "Redux/Auth/reducers";
import { errorHandler } from "Shared/Utils/app";
import { readObj } from "Service/storage";

const getUserList = createAsyncThunk(
  "user/getUserList",
  async (params: IUserSearchParams | null) => {
    try {
      const shop = readObj("current_shop");
      const response = await api.user.getUserList({
        ...params,
        shop: shop?.id,
      });
      return response.data;
    } catch (error) {
      if (error instanceof Error) {
        errorHandler(error);
      }
      throw error;
    }
  }
);

const getUser = createAsyncThunk("user/getUser", async (id: string) => {
  try {
    const response = await api.user.getUser(id);
    return response.data;
  } catch (error) {
    if (error instanceof Error) {
      errorHandler(error);
    }
    throw error;
  }
});

const addUser = createAsyncThunk(
  "user/addUser",
  async ({ data, onSuccess }: IDispatchCallback<IUserAdd>) => {
    try {
      const formData = new FormData();
      Object.entries(data).forEach(([key, value]) => {
        if (value instanceof File) {
          formData.append(key, value);
        } else if (value !== undefined) {
          formData.append(key, String(value));
        }
      });
      const response = await api.user.addUser(formData);
      onSuccess?.();
      return response.data;
    } catch (error) {
      if (error instanceof Error) {
        errorHandler(error, true);
      }
      throw error;
    }
  }
);

const updateUser = createAsyncThunk(
  "user/updateUser",
  async ({ id, data, onSuccess }: IDispatchCallbackWithId<IUserUpdate>) => {
    try {
      const formData = new FormData();
      Object.entries(data).forEach(([key, value]) => {
        if (value instanceof File) {
          formData.append(key, value);
        } else if (value !== undefined && value !== null && key !== "avatar") {
          formData.append(key, String(value));
        }
      });
      const response = await api.user.updateUser(id, formData);
      onSuccess?.();
      return response.data;
    } catch (error) {
      if (error instanceof Error) {
        errorHandler(error, true);
      }
      throw error;
    }
  }
);

const updateUserField = createAsyncThunk(
  "user/updateUserField",
  async (
    { id, data, onSuccess }: IDispatchCallbackWithId<IUserUpdate>,
    { dispatch }
  ) => {
    try {
      const formData = new FormData();
      Object.entries(data).forEach(([key, value]) => {
        if (value instanceof File) {
          formData.append(key, value);
        } else {
          formData.append(key, String(value));
        }
      });
      await api.user
        .updateUserByField(id, formData)
        .then(() => dispatch(getMe()));
      onSuccess?.();
    } catch (error) {
      if (error instanceof Error) {
        errorHandler(error);
      }
      throw error;
    }
  }
);

const deleteUser = createAsyncThunk(
  "user/deleteUser",
  async ({ id, onSuccess }: IDispatchCallbackWithoutData) => {
    try {
      const response = await api.user.deleteUser(id);
      onSuccess?.();
      return response.data;
    } catch (error) {
      if (error instanceof Error) {
        errorHandler(error, true);
      }
      throw error;
    }
  }
);

const blockUser = createAsyncThunk(
  "user/blockUser",
  async ({ id, onSuccess }: IDispatchCallbackWithoutData) => {
    try {
      const response = await api.user.blockUser(id);
      onSuccess?.();
      return response.data;
    } catch (error) {
      if (error instanceof Error) {
        errorHandler(error, true);
      }
      throw error;
    }
  }
);

const unblockUser = createAsyncThunk(
  "user/unblockUser",
  async ({ id, onSuccess }: IDispatchCallbackWithoutData) => {
    try {
      const response = await api.user.blockUser(id); //TODO: need to be api.user.unblockUser(id)
      onSuccess?.();
      return response.data;
    } catch (error) {
      if (error instanceof Error) {
        errorHandler(error, true);
      }
      throw error;
    }
  }
);

export {
  getUserList,
  getUser,
  addUser,
  updateUser,
  deleteUser,
  blockUser,
  unblockUser,
  updateUserField,
};
