import { IUserAdd, IUserUpdate } from "Shared/Types/user";
import React, { useState } from "react";
import { ObjectSchema } from "yup";
import styles from "./EmployeeForm.module.scss";
import BasicInfo from "./BasicInfo";
import AddressInfo from "./AddressInfo";
import Access from "./Access";
import AddionalDetails from "./AddionalDetails";
import Button from "Components/UI/Button/Button";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import Form from "Components/UI/Form/Form";

type EmployeeFormProps = {
  onSubmit: (values: IUserAdd) => void;
  scheme: ObjectSchema<any>;
  type: "add" | "update";
  employee?: IUserAdd | IUserUpdate;
};

const EmployeeForm: React.FC<EmployeeFormProps> = ({
  onSubmit,
  scheme,
  employee,
  type,
}) => {
  const medhods = useForm({
    resolver: yupResolver(scheme),
    defaultValues: employee,
  });

  const [active, setActive] = useState(employee ? employee?.is_active : true);

  return (
    <div className={styles.form}>
      <FormProvider {...medhods}>
        <Form onSubmit={medhods.handleSubmit(onSubmit)} className={styles.form}>
          <div className={styles.form__photo}>
            {/* <DragDrop name="avatar" label="Photo" /> */}
          </div>
          <div className={styles.form__content}>
            <BasicInfo
              active={active}
              setActive={setActive}
              isAdd={type === "add"}
              control={medhods.control}
            />
            <AddressInfo control={medhods.control} />
            <Access control={medhods.control} />
            <AddionalDetails />
            <div className={styles.form__btns}>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                className={styles.form__btns__btn}
              >
                {employee ? "Save" : "Create"}
              </Button>
            </div>
          </div>
        </Form>
      </FormProvider>
    </div>
  );
};
export default EmployeeForm;
