import { Text, View } from "@react-pdf/renderer";
import { invoiceActionItemPartTableHead } from "Shared/Constants/action-item";
import { IInvoiceAI } from "Shared/Types/invoice";
import { getHeadInvoiceAI } from "Shared/Utils/invoice";
import React from "react";
import { styles } from "./style";

type ActionItemsProps = {
  actionItem: IInvoiceAI;
};

const ActionItems: React.FC<ActionItemsProps> = ({ actionItem }) => {
  return (
    <>
      <View style={styles.head}>
        {getHeadInvoiceAI(actionItem)?.map(({ title, value }) => (
          <View style={styles.headItem} key={title}>
            <Text style={styles.headItemTitle}>{title}</Text>
            <Text>{value}</Text>
          </View>
        ))}
      </View>

      <View style={styles.table}>
        <View style={styles.tableHead}>
          {invoiceActionItemPartTableHead.map((item) => (
            <Text key={item.id} style={{ width: item.pdfWidth }}>
              {item.label}
            </Text>
          ))}
        </View>
        <View style={styles.tableBody}>
          {actionItem.parts?.map((part) => (
            <View key={part.id} style={styles.tableRow}>
              <Text style={styles.partName}>{part.name}</Text>
              <Text style={styles.cell}>{part.quantity}</Text>
              <Text style={styles.cell}>{part.price}</Text>
              <Text style={styles.cell}>{part.amount}</Text>
            </View>
          ))}
        </View>

        <View style={styles.tableRow}>
          <Text style={styles.partName}>Labor: {actionItem.labor_name}</Text>
          <Text style={styles.cell}>{actionItem.hours}</Text>
          <Text style={styles.cell}>{actionItem.labor_rate}</Text>
          <Text style={styles.cell}>{actionItem.labor_amount}</Text>
        </View>

        <View style={styles.tableRow}>
          <Text style={styles.partName} />
          <Text style={styles.cell} />
          <Text style={styles.cell}>Subtotal:</Text>
          <Text style={styles.cell}>$ {actionItem.subTotal}</Text>
        </View>
      </View>
    </>
  );
};
export default ActionItems;
