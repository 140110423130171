import React, { useEffect } from "react";
import Modal from "Components/UI/Modal/Modal";

type ComponentProps = {
  openModal: boolean;
  handleModal: () => void;
};

const InDevelopmentModal: React.FC<ComponentProps> = ({
  openModal,
  handleModal,
}) => {
  return (
    <Modal
      open={openModal}
      onClose={handleModal}
      style={{ width: "100%", height: "100%", position: "relative" }}
    >
      In Development
    </Modal>
  );
};

export default InDevelopmentModal;
