import React from "react";
import { BreadcrumbItem } from "Components/Breadcrumb/Breadcrumb";
import { useBreadcrumb } from "Shared/Hooks/use-breadcrumb";
import Layout from "Components/Layout/Layout";
import UnitDetail from "Containers/Unit/UnitDetail/UnitDetail";
import { useSelector } from "react-redux";
import { selectCurrentUnit } from "Redux/Unit/slice";

export const UnitDetailPage = () => {
  const unit = useSelector(selectCurrentUnit);
  const breads: BreadcrumbItem[] = [
    { title: "Units", link: "/units" },
    { title: unit?.name || "", link: "" },
  ];
  useBreadcrumb({ links: breads, option: { upd: unit?.name } });

  return (
    <Layout>
      <UnitDetail />
    </Layout>
  );
};
