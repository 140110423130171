import Layout from "Components/Layout/Layout";
import React from "react";
import CompanyList from "Containers/Company/CompanyList";
import { useBreadcrumb } from "Shared/Hooks/use-breadcrumb";
import { getCompanyBreadCrumbs } from "Shared/Utils/company";

export const CompanyListPage: React.FC = () => {
  useBreadcrumb({ links: getCompanyBreadCrumbs("user-list") });

  return (
    <Layout>
      <CompanyList status="active" />
    </Layout>
  );
};
