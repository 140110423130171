import { client } from "Service/axios";
import {
  ILocation,
  ILocationAdd,
  ILocationSearchParams,
  ILocationUpdate,
} from "Shared/Types/location";

const LOCATION_URL = "part/location/";

class Location {
  getLocationsList(params: ILocationSearchParams | null) {
    return client.get<ILocation[]>(LOCATION_URL, { params });
  }
  addLocation(data: ILocationAdd) {
    return client.post(`${LOCATION_URL}create/`, data);
  }
  getLocation(id: string, params: ILocationSearchParams | null) {
    return client.get<ILocation>(`${LOCATION_URL}${id}/`, {
      params,
    });
  }
  updateLocation(id: string, data: ILocationUpdate) {
    return client.put(`${LOCATION_URL}${id}/update/`, data, {
      params: {
        shop: data.shop,
      },
    });
  }
  deleteLocation(id: string, data: { shop: string }) {
    return client.delete(`${LOCATION_URL}${id}/delete/`, {
      data,
    });
  }
}

export default Location;
