import React, { useEffect } from "react";
import Icon from "Components/UI/Icon/Icon";
import styles from "./ServiceOrderAdd.module.scss";
import { CloseIcon } from "Assets/index";
import { CustomerSearch } from "Components/UI/AutoCompleteSearchInputs/CustomerSearch";
import { ContactSearch } from "Components/UI/AutoCompleteSearchInputs/ContactSearch";
import { useSelector } from "react-redux";
import { selectCurrentCustomer } from "Redux/Customer/slice";
import { useFormContext } from "react-hook-form";
import { Link } from "react-router-dom";
import { clear, readObj } from "Service/storage";

type CustomerInfoProps = {
  saveOrderValues?: () => void;
};

const CustomerInfo: React.FC<CustomerInfoProps> = ({ saveOrderValues }) => {
  const localSOValues = readObj("created_service_order");
  const customer =
    useSelector(selectCurrentCustomer) || localSOValues?.customer;
  const { setValue } = useFormContext();

  useEffect(() => {
    if (!customer) setValue("service_requester", null);
  }, [customer]);

  useEffect(() => {
    if (localSOValues?.customer) {
      setValue("customer", localSOValues.customer);
    }
    if (localSOValues?.service_requester) {
      setValue("service_requester", localSOValues.service_requester);
    }
  }, []);

  return (
    <div style={{ marginTop: 0 }} className={styles.form_section}>
      <div className={styles.form_section__title}>
        <p className={styles.title}>Customer</p>
        <Link onClick={() => clear("created_service_order")} to="/orders">
          <Icon component={CloseIcon} />
        </Link>
      </div>
      <div style={{ gap: "16px" }} className={styles.form_section__inputs}>
        <CustomerSearch
          name="customer"
          required
          label="Who is the customer?"
          beforeCreateNew={saveOrderValues}
          isAdd
        />
        <ContactSearch
          customerId={customer?.id}
          name="service_requester"
          label="Who is requesting service?"
          disabled={customer === null && localSOValues?.customer === null}
          isAdd
          beforeCreateNew={saveOrderValues}
        />
      </div>
    </div>
  );
};

export default CustomerInfo;
