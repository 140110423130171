import { OptionsProps } from "Components/UI/SelectField/SelectField";
import {
  IPurchaseOrderCreateOrdersKeys,
  IPurchaseOrderRecievedKeys,
} from "Shared/Types/purchase-order";

type TSelectOption = OptionsProps;

export type TField = {
  type: "text" | "number" | "info";
  label?: string;
  name: IPurchaseOrderCreateOrdersKeys;
  xs?: number;
  required?: boolean;
  options?: TSelectOption[];
  disabled?: boolean;
};

export type TReceivedField = Omit<TField, "options" | "name"> & {
  name: IPurchaseOrderRecievedKeys;
  isGreen?: boolean;
};

const usePurchaseOrderFields = () => {
  const XS = 2;

  const fields: TField[] = [
    {
      name: "contact",
      label: "Contact",
      xs: 4,
      type: "text",
      disabled: true,
    },
    {
      name: "email",
      label: "Additional Email",
      xs: 4,
      type: "text",
    },

    {
      name: "address",
      label: "Drop ship address",
      xs: 4,
      type: "text",
    },
    {
      name: "order_total",
      label: "Order total",
      xs: XS,
      type: "info",
    },
    {
      name: "order_threshold",
      label: "Order threshold",
      xs: XS,
      type: "info",
    },
    {
      name: "pending_order",
      label: "Pending orders",
      xs: XS,
      type: "info",
    },
    {
      name: "freight_difference",
      label: "Freight difference",
      xs: XS,
      type: "info",
    },
    {
      name: "freight_threshold",
      label: "Freight threshold",
      xs: XS,
      type: "info",
    },
    {
      name: "order_difference",
      label: "Order difference",
      xs: XS,
      type: "info",
    },
  ];

  const receiveFields: TReceivedField[] = [
    {
      name: "date",
      label: "Date",
      xs: 12,
      type: "text",
      disabled: true,
    },
    {
      name: "number",
      label: "Vendor Bill #",
      xs: 12,
      type: "text",
      required: true,
    },
    {
      name: "packing_slip",
      label: "Vendor Bill #",
      xs: 12,
      type: "text",
      required: true,
    },
  ];

  const amountFields: TReceivedField[] = [
    {
      name: "sub_total",
      label: "Sub total",
      xs: 12,
      type: "info",
    },
    {
      name: "credit_amount",
      label: "Discount",
      xs: 12,
      type: "number",
    },
    {
      name: "total",
      label: "Total",
      xs: 12,
      type: "info",
    },
  ];

  return { fields, receiveFields, amountFields };
};

export default usePurchaseOrderFields;
