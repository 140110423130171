import Block from "Components/UI/Block/Block";
import InfoField from "Components/UI/InfoField/InfoField";
import { IOrder } from "Shared/Types/order";
import { getPartListOrderDatalData } from "Shared/Utils/order";
import React from "react";
import styles from "./OrderInfo.module.scss";
import { Grid } from "@mui/material";

type OrderInfoProps = {
  order: IOrder;
};

const OrderInfo: React.FC<OrderInfoProps> = ({ order }) => {
  return (
    <Block isBorder>
      <Grid container className={styles.order} columnSpacing={2}>
        {getPartListOrderDatalData(order).map((data) => (
          <Grid item xs={6} md={3} key={data.label} mb="8px" mt="8px">
            <InfoField
              label={data.label}
              value={data.value}
              valueColor={data.isBlue ? "blue" : undefined}
              key={data.label}
              isValueUnderline={data.isUnderLine}
              isHorizontal
            />
          </Grid>
        ))}
      </Grid>
    </Block>
  );
};
export default OrderInfo;
