import React, { useEffect } from "react";
import { useAppDispatch } from "Redux/store";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import { getCompanyRequest } from "Redux/CompanyRequests/reducers";
import Block from "Components/UI/Block/Block";
import { Grid } from "@mui/material";
import { selectCurrentCompanyRequest } from "Redux/CompanyRequests/slice";
import InfoField from "Components/UI/InfoField/InfoField";
import { getCompanyRequestInformation } from "Shared/Utils/companyRequest";
import styles from "./RequestsApprovedDetails.module.scss";

const RequestApprovedDetails: React.FC = () => {
  const dispatch = useAppDispatch();
  const request = useSelector(selectCurrentCompanyRequest);
  const params = useParams();
  const requestInformation = getCompanyRequestInformation(request);

  useEffect(() => {
    if (params.requestId) {
      dispatch(getCompanyRequest(params.requestId));
    }
  }, [params.requestId]);

  return (
    <div className={styles.request}>
      <Block className={styles.request__block}>
        <h3>Company information</h3>
        <Grid container className={styles.request__content}>
          {requestInformation &&
            requestInformation.map((request, index) => (
              <Grid
                xs={5.9}
                key={index}
                className={styles.request__company__content__item}
              >
                <InfoField
                  label={request.title}
                  value={request.value}
                  labelColor="gray"
                />
              </Grid>
            ))}
        </Grid>
      </Block>
      <Block className={styles.request__block}>
        <h3>Billing Information</h3>
        <Grid container className={styles.request__content}>
          <Grid xs={5.9} className={styles.request__company__content__item}>
            <InfoField
              label="Billing contact"
              value={request?.billing_address}
              labelColor="gray"
            />
          </Grid>
          <Grid xs={5.9} className={styles.request__company__content__item}>
            <InfoField
              label="Payment method"
              value={request?.payment_method}
              labelColor="gray"
            />
          </Grid>
          <Grid xs={5.9} className={styles.request__company__content__item}>
            <InfoField
              label="Billing address"
              value={request?.billing_address}
              labelColor="gray"
            />
          </Grid>
        </Grid>
      </Block>
      <Block className={styles.request__block}>
        <h3>Primary Contact and Address</h3>
        <Grid container className={styles.request__content}>
          <Grid xs={5.9} className={styles.request__company__content__item}>
            <InfoField
              label="Primary Contact"
              value={request?.primary_contact}
              labelColor="gray"
            />
          </Grid>
          <Grid xs={5.9} className={styles.request__company__content__item}>
            <InfoField
              label="Primary Address"
              value={request?.primary_address}
              labelColor="gray"
            />
          </Grid>
        </Grid>
      </Block>
    </div>
  );
};

export default RequestApprovedDetails;
