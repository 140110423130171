import { ILaborRate } from "Shared/Types/laborRate";
import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "Redux/store";
import {
  addLaborRate,
  deleteLaborRate,
  getLaborRate,
  getLaborRateList,
  updateLaborRate,
} from "Redux/LaborRate/reducer";

interface ILaborRateState {
  loading: boolean;
  laborRateList?: ILaborRate[];
  laborRate?: ILaborRate;
}

const initialState: ILaborRateState = {
  loading: false,
  laborRateList: [],
  laborRate: undefined,
};

const LaborRateSlice = createSlice({
  name: "laborRate",
  initialState,
  reducers: {
    removeCurrentLaborRate(state) {
      state.laborRate = undefined;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getLaborRateList.pending, (state) => {
      state.loading = true;
      state.laborRateList = [];
    });
    builder.addCase(getLaborRateList.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(getLaborRateList.fulfilled, (state, action) => {
      state.loading = false;
      state.laborRateList = action.payload.results;
    });

    builder.addCase(getLaborRate.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getLaborRate.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(getLaborRate.fulfilled, (state, action) => {
      state.loading = false;
      state.laborRate = action.payload;
    });

    builder.addCase(addLaborRate.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(addLaborRate.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(addLaborRate.fulfilled, (state) => {
      state.loading = false;
    });

    builder.addCase(deleteLaborRate.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(deleteLaborRate.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(deleteLaborRate.fulfilled, (state) => {
      state.loading = false;
    });

    builder.addCase(updateLaborRate.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateLaborRate.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(updateLaborRate.fulfilled, (state) => {
      state.loading = false;
    });
  },
});

export const { removeCurrentLaborRate } = LaborRateSlice.actions;

export const selectLaborRateList = (state: RootState) =>
  state.laborRate.laborRateList;
export const selectLaborRate = (state: RootState) => state.laborRate.laborRate;

export const selectLaborRateLoading = (state: RootState) =>
  state.laborRate.loading;

export default LaborRateSlice;
