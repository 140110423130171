import Layout from "Components/Layout/Layout";
import { useBreadcrumb } from "Shared/Hooks/use-breadcrumb";
import React from "react";
import { Dashboard } from "Containers/Order/Estimates/Dashboard/Dashboard";
import {
  ESTIMATE_CREATE,
  estimateBreadcrumbs,
} from "Shared/Constants/estimates";

export const EstimatesOrdersPage: React.FC = () => {
  useBreadcrumb({
    links: estimateBreadcrumbs,
    ...ESTIMATE_CREATE,
  });

  return (
    <Layout>
      <Dashboard />
    </Layout>
  );
};
