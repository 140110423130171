import { ICardData } from "Shared/Types/common";
import { ICustomer } from "Shared/Types/customer";
import * as yup from "yup";
import { getShortValue } from "./order";

export const customerScheme = yup.object().shape({
  company_name: yup.string(),
  first_name: yup.string().required(),
  last_name: yup.string(),
  phone: yup.string().required(),
  cell_phone: yup.string().required(),
  email: yup.string().email().required(),
  dot_number: yup.string(),
  street_address: yup.string(),
  street_address_2: yup.string(),
  state: yup.string().required(),
  city: yup.string().required(),
  country: yup.string().required(),
  postal_code: yup.string().required(),
  address_name: yup.string(),
  tax_rate: yup.string().nullable(),
  use_default_billing: yup.boolean(),
  labor_rate: yup.string().nullable(),
  customer_price_level: yup
    .string()
    .oneOf(["fleet", "list", "wholesale"])
    .required(),
});

export const getCustomerCardData = (customer: ICustomer) => {
  const data: ICardData[] = [
    {
      title: "Company Name",
      value: customer.company_name,
      isOnlyValue: true,
      isMain: true,
      withoutBorder: true,
    },
    {
      title: "Unit",
      value: customer.unit_count,
    },
    {
      title: "Full Name",
      value: `${customer.first_name} ${customer.last_name}`,
      isWrap: true,
    },
    {
      title: "Phone Number",
      value: customer.phone,
      isWrap: true,
    },
    {
      title: "Email Address",
      value: getShortValue(customer.email, 23),
      isValueBlue: true,
      isWrap: true,
    },
  ];
  return data;
};

export const getCustomerDetail = (customer?: ICustomer | null) => {
  const customerDetail = [
    {
      label: "Company Name",
      value: customer?.company_name,
      mt: 17,
      labelColor: "gray",
    },
    {
      label: "First Name",
      value: customer?.first_name,
      mt: 17,
      labelColor: "gray",
    },
    {
      label: "Last Name",
      value: customer?.last_name,
      mt: 17,
      labelColor: "gray",
    },
    {
      label: "Email",
      value: customer?.email,
      mt: 17,
      labelColor: "gray",
    },
    {
      label: "Phone",
      value: customer?.phone,
      mt: 17,
      labelColor: "gray",
    },
  ];

  const billingInfo = [
    {
      label: "Street Address",
      value: customer?.street_address,
      mt: 17,
      labelColor: "gray",
    },
    {
      label: "Street Address 2:",
      value: customer?.street_address,
      mt: 17,
      labelColor: "gray",
    },
    {
      label: "State",
      value: customer?.state,
      mt: 17,
      labelColor: "gray",
    },
    {
      label: "City",
      value: customer?.city,
      mt: 17,
      labelColor: "gray",
    },
    {
      label: "Country",
      value: customer?.country,
      mt: 17,
      labelColor: "gray",
    },
  ];
  return { customerDetail, billingInfo };
};
