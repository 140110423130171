import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { ICompany } from "Shared/Types/company";
import {
  addCompany,
  getCompany,
  getCompanyBlockedList,
  getCompanyList,
  updateCompany,
  updateCompanyStatus,
} from "./reducers";

interface ICompanyState {
  companyList?: ICompany[];
  companyCount?: number;
  companyBlockedList?: ICompany[];
  companyBlockedCount?: number;
  currentCompany?: ICompany | null;
  loading: boolean;
}

const initialState: ICompanyState = {
  companyList: [],
  loading: false,
  currentCompany: null,
};

const companySlice = createSlice({
  name: "company",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getCompany.pending, (state) => {
      state.loading = true;
      state.currentCompany = null;
    });
    builder.addCase(getCompany.rejected, (state) => {
      state.loading = false;
      state.currentCompany = null;
    });
    builder.addCase(getCompany.fulfilled, (state, action) => {
      state.loading = false;
      state.currentCompany = action.payload;
    });
    builder.addCase(addCompany.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(addCompany.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(addCompany.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(updateCompany.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateCompany.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(updateCompany.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(updateCompanyStatus.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateCompanyStatus.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(updateCompanyStatus.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(getCompanyList.pending, (state) => {
      state.loading = true;
      state.companyList = [];
    });
    builder.addCase(getCompanyList.rejected, (state) => {
      state.loading = false;
      state.companyList = [];
    });
    builder.addCase(getCompanyList.fulfilled, (state, action) => {
      state.loading = false;
      state.companyList = action.payload.results;
      state.companyCount = action.payload.count;
    });
    builder.addCase(getCompanyBlockedList.pending, (state) => {
      state.loading = true;
      state.companyBlockedList = [];
    });
    builder.addCase(getCompanyBlockedList.rejected, (state) => {
      state.loading = false;
      state.companyBlockedList = [];
    });
    builder.addCase(getCompanyBlockedList.fulfilled, (state, action) => {
      state.loading = false;
      state.companyBlockedList = action.payload.results;
      state.companyBlockedCount = action.payload.count;
    });
  },
});

export const selectCurrentCompany = (state: RootState) =>
  state.company.currentCompany;
export const selectCompanyLoading = (state: RootState) => state.company.loading;
export const selectCompanyList = (state: RootState) =>
  state.company.companyList;
export const selectCompanyCount = (state: RootState) =>
  state.company.companyCount;
export const selectCompanyBlockedList = (state: RootState) =>
  state.company.companyBlockedList;
export const selectCompanyBlockedCount = (state: RootState) =>
  state.company.companyBlockedCount;

export default companySlice;
