import * as Yup from "yup";

export const forgotPasswordSchema = Yup.object().shape({
  email: Yup.string().email("Incorrect email!").required(),
});

export const loginSchema = Yup.object().shape({
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
  password: Yup.string().required("Password is required"),
});

const passwordRules = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/;

export const resetPasswordSchema = Yup.object().shape({
  password: Yup.string()
    .matches(passwordRules, { message: "Please create a stronger password" })
    .min(8, "Must be at least 8 characters")
    .required(),
  confirm_password: Yup.string()
    .required()
    .oneOf([Yup.ref("password")], "Passwords must match"),
});

export const firstAuthSchema = Yup.object().shape({
  new_password: Yup.string().required("Password is required"),
  confirm_password: Yup.string()
    .required("Password confirmation is required")
    .oneOf([Yup.ref("new_password")], "Passwords must match"), // Add the password match validation
  token: Yup.string().required("Token is required"),
  uid: Yup.string().required("UID is required"),
});

export const registerAuthSchema = Yup.object().shape({
  first_name: Yup.string().required("First name is required"),
  last_name: Yup.string().required("Last name is required"),
  phone: Yup.string().required("Phone number is required"),
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
  company_name: Yup.string().required("Company name is required"),
  address: Yup.string().required("Address is required"),
  legal_name: Yup.string().required("Legal name is required"),
});
