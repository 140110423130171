import IconButton from "Components/UI/IconButton/IconButton";
import React, { MouseEvent } from "react";
import { EditIcon, DeleteIcon } from "Assets/index";
import { useNavigate } from "react-router";
import { ISubtype } from "Shared/Types/subtype";
import labor_styles from "../../Settings/LaborRate/RenderCell/renderCell.module.scss";

export const renderCell = (
  cellData: any,
  columnId: string,
  subtype: ISubtype,
  openDelete?: (id: string) => void
) => {
  const navigate = useNavigate();
  const handleClick = (event: MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
    navigate(`/settings/unit-settings/subtypes/${subtype.id}`);
  };

  const handleDelete = (event: MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
    openDelete?.(subtype.id);
  };
  if (columnId === "action") {
    return (
      <div className={labor_styles.buttons}>
        <IconButton icon={EditIcon} onClick={handleClick} />
        <IconButton icon={DeleteIcon} onClick={handleDelete} />
      </div>
    );
  }
  return cellData;
};
