import React from "react";
import styles from "./SubtypeAdd.module.scss";
import { useAppDispatch } from "Redux/store";
import { useSelector } from "react-redux";
import { notifySuccess } from "Components/Notify/notify";

import { addSubtype } from "Redux/Subtype/reducer";
import SubtypeForm from "Components/Subtype/SubtypeForm/SubtypeForm";
import { ISubtypeAdd } from "Shared/Types/subtype";
import { useNavigate } from "react-router";
import { subtypeSchema } from "Shared/Utils/subtype";
import StepProgress from "Components/UI/StepProgress/StepProgress";
import { selectAccount } from "Redux/Auth/slice";
import { stepDescription } from "Containers/Home/Home";
import { checkFirstAuth } from "Shared/Utils/app";

const SubtypeAdd: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const profile = useSelector(selectAccount);
  const isFristAuth = checkFirstAuth(profile);

  const back = () => {
    navigate("/settings/unit-settings/subtypes");
  };
  const createSubtype = (values: ISubtypeAdd) => {
    const onSuccess = () => {
      notifySuccess("Subtype created");
      if (isFristAuth) {
        navigate("/settings/unit-settings/unit-types/new");
      } else {
        back();
      }
    };
    dispatch(addSubtype({ data: values, onSuccess }));
  };

  return (
    <div className={styles.subtypeAdd}>
      {isFristAuth && (
        <StepProgress
          description={stepDescription}
          active={0}
          title="Unit settings"
        />
      )}
      <div className={styles.subtypeAdd__form}>
        <SubtypeForm onSubmit={createSubtype} schema={subtypeSchema} />
      </div>
    </div>
  );
};
export default SubtypeAdd;
