import React from "react";
import styles from "./EmployeeAdd.module.scss";
import EmployeeForm from "Components/Employee/EmployeeForm/EmployeeForm";
import { IUserAdd } from "Shared/Types/user";
import { notifySuccess } from "Components/Notify/notify";
import { addUser } from "Redux/User/reducer";
import { useAppDispatch } from "Redux/store";
import { useSelector } from "react-redux";
import { selectAccount } from "Redux/Auth/slice";
import { useLocation, useNavigate } from "react-router";
import { userSchema } from "Shared/Utils/user";
import StepProgress from "Components/UI/StepProgress/StepProgress";
import { checkFirstAuth } from "Shared/Utils/app";

export const EmployeeAdd: React.FC = () => {
  const dispatch = useAppDispatch();
  const profile = useSelector(selectAccount);
  const navigate = useNavigate();
  const isFirstAuth = checkFirstAuth(profile);
  const location = useLocation();
  const onSubmit = async (values: IUserAdd) => {
    await dispatch(
      addUser({
        data: values,
        onSuccess: () => {
          notifySuccess("Created Employee!");
          if (isFirstAuth) {
            navigate("/shops/new");
          } else if (location.state) {
            navigate(-1);
          } else {
            navigate("/employees");
          }
        },
      })
    );
  };
  const stepDescription = (
    <>
      Time to add your team members:
      <br /> Enter employee details, including name, role, and contact
      information.
      <br /> Save the employee profile
    </>
  );

  return (
    <div className={styles.addEmployee}>
      {isFirstAuth && (
        <StepProgress
          active={1}
          description={stepDescription}
          title="Managing Employees"
        />
      )}
      <EmployeeForm onSubmit={onSubmit} type="add" scheme={userSchema} />
    </div>
  );
};
