import React, { useEffect, useState } from "react";
import { historyFieldList } from "Shared/Constants/order";
import { useAppDispatch } from "Redux/store";
import { getOrderHistory } from "Redux/History/reducer";
import { useSearchParams } from "react-router-dom";
import { IHistorySearchParam } from "Shared/Types/order";
import { formatBackend } from "Shared/Utils/app";
import StatsTable from "Components/Home/Stats/StatsTable";
import HomeFilter from "Components/Home/HomeFilter/HomeFilter";
import FilterPanel from "Components/Order/FilterPanel/FilterPanel";
import { statsFilterFields } from "Shared/Constants/stats";

type StatsContentProps = {
  status?: string;
};

const StatsContent: React.FC<StatsContentProps> = () => {
  const [isCard, setIsCard] = useState(true);
  const dispatch = useAppDispatch();
  const [orderFields, setOrderFields] = useState(historyFieldList);
  const [searchParams] = useSearchParams();


  useEffect(() => {
    const params: IHistorySearchParam = {
      page: +(searchParams.get("page") || 1),
      customers: searchParams.get("customer"),
    };
    const from = searchParams.get("from");
    const to = searchParams.get("to");
    if (from && to) {
      params.created_at_after = formatBackend(new Date(from), "YYYY-MM-DD");
      params.created_at_before = formatBackend(new Date(to), "YYYY-MM-DD");
    }
    dispatch(getOrderHistory(params));
  }, [searchParams]);
  return (
    <div>
    <HomeFilter />
    <FilterPanel
      setIsCard={setIsCard}
      isCard={isCard}
      fields={orderFields}
      setFields={setOrderFields}
      fieldAllList={['']}
      limitFields={10}
      filters={statsFilterFields}
      showCardAndFilterIcons={false}
    />
     <StatsTable/>
    </div>
   
   
  );
};
export default StatsContent;


