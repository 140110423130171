import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { ICompanyRequest } from "Shared/Types/company";
import {
  addCompanyRequest,
  deleteCompanyRequest,
  getCompanyRequest,
  getCompanyRequestsApprovedList,
  getCompanyRequestsList,
  getCompanyRequestsRejectedList,
  getCompanyRequestsReviewList,
  rejectCompanyRequest,
} from "./reducers";

interface ICompanyRequestsState {
  companyRequestsList?: ICompanyRequest[];
  companyRequestsCount?: number;
  companyRequestsApprovedList?: ICompanyRequest[];
  companyRequestsApprovedCount?: number;
  companyRequestsReviewList?: ICompanyRequest[];
  companyRequestsReviewCount?: number;
  companyRequestsRejectedList?: ICompanyRequest[];
  companyRequestsRejectedCount?: number;
  currentCompanyRequest?: ICompanyRequest | null;
  loading: boolean;
}

const initialState: ICompanyRequestsState = {
  companyRequestsList: [],
  loading: false,
  currentCompanyRequest: null,
};

const userSlice = createSlice({
  name: "companyRequest",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getCompanyRequest.pending, (state) => {
      state.loading = true;
      state.currentCompanyRequest = null;
    });
    builder.addCase(getCompanyRequest.rejected, (state) => {
      state.loading = false;
      state.currentCompanyRequest = null;
    });
    builder.addCase(getCompanyRequest.fulfilled, (state, action) => {
      state.loading = false;
      state.currentCompanyRequest = action.payload;
    });
    builder.addCase(addCompanyRequest.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(addCompanyRequest.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(addCompanyRequest.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(getCompanyRequestsList.pending, (state) => {
      state.loading = true;
      state.companyRequestsList = [];
    });
    builder.addCase(getCompanyRequestsList.rejected, (state) => {
      state.loading = false;
      state.companyRequestsList = [];
    });
    builder.addCase(getCompanyRequestsList.fulfilled, (state, action) => {
      state.loading = false;
      state.companyRequestsList = action.payload.results;
      state.companyRequestsCount = action.payload.count;
    });
    builder.addCase(getCompanyRequestsApprovedList.pending, (state) => {
      state.loading = true;
      state.currentCompanyRequest = null;
    });
    builder.addCase(getCompanyRequestsApprovedList.rejected, (state) => {
      state.loading = false;
      state.currentCompanyRequest = null;
    });
    builder.addCase(
      getCompanyRequestsApprovedList.fulfilled,
      (state, action) => {
        state.loading = false;
        state.companyRequestsApprovedList = action.payload.results;
        state.companyRequestsApprovedCount = action.payload.count;
      }
    );
    builder.addCase(getCompanyRequestsReviewList.pending, (state) => {
      state.loading = true;
      state.currentCompanyRequest = null;
    });
    builder.addCase(getCompanyRequestsReviewList.rejected, (state) => {
      state.loading = false;
      state.currentCompanyRequest = null;
    });
    builder.addCase(getCompanyRequestsReviewList.fulfilled, (state, action) => {
      state.loading = false;
      state.companyRequestsReviewList = action.payload.results;
      state.companyRequestsReviewCount = action.payload.count;
    });
    builder.addCase(getCompanyRequestsRejectedList.pending, (state) => {
      state.loading = true;
      state.currentCompanyRequest = null;
    });
    builder.addCase(getCompanyRequestsRejectedList.rejected, (state) => {
      state.loading = false;
      state.currentCompanyRequest = null;
    });
    builder.addCase(
      getCompanyRequestsRejectedList.fulfilled,
      (state, action) => {
        state.loading = false;
        state.companyRequestsRejectedList = action.payload.results;
        state.companyRequestsRejectedCount = action.payload.count;
      }
    );
    builder.addCase(rejectCompanyRequest.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(rejectCompanyRequest.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(rejectCompanyRequest.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(deleteCompanyRequest.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(deleteCompanyRequest.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(deleteCompanyRequest.fulfilled, (state) => {
      state.loading = false;
    });
  },
});

export const selectCurrentCompanyRequest = (state: RootState) =>
  state.companyRequest.currentCompanyRequest;
export const selectCompanyRequestLoading = (state: RootState) =>
  state.companyRequest.loading;
export const selectCompanyRequestList = (state: RootState) =>
  state.companyRequest.companyRequestsList;
export const selectCompanyRequestCount = (state: RootState) =>
  state.companyRequest.companyRequestsCount;
export const selectCompanyRequestApprovedList = (state: RootState) =>
  state.companyRequest.companyRequestsApprovedList;
export const selectCompanyRequestApprovedCount = (state: RootState) =>
  state.companyRequest.companyRequestsApprovedCount;
export const selectCompanyRequestReviewList = (state: RootState) =>
  state.companyRequest.companyRequestsReviewList;
export const selectCompanyRequestReviewCount = (state: RootState) =>
  state.companyRequest.companyRequestsReviewCount;
export const selectCompanyRequestReviewNotificationCount = (state: RootState) =>
  state.companyRequest.companyRequestsReviewList?.filter(
    (req) => req.is_read === false
  ).length;
export const selectCompanyRequestRejectedList = (state: RootState) =>
  state.companyRequest.companyRequestsRejectedList;
export const selectCompanyRequestRejectedCount = (state: RootState) =>
  state.companyRequest.companyRequestsRejectedCount;

export default userSlice;
