import React, { useEffect, useState } from "react";
import BlueLogo from "Assets/logo-blue.svg";
import styles from "./OrderInvoice.module.scss";
import { useSelector } from "react-redux";
import { selectCurrentOrder } from "Redux/Order/slice";
import { selectAccount } from "Redux/Auth/slice";
import InvoiceHeader from "./InvoiceHeader/InvoiceHeader";
import InvoiceActionItem from "./InvoiceActionItem/InvoiceActionItem";
import UnitInfo from "./UnitInfo/UnitInfo";
import { useAppDispatch } from "Redux/store";
import { getActionItem } from "Redux/ActionItem/reducer";
import { selectActionItemList } from "Redux/ActionItem/slice";
import { IOrderInvoiceData, IOrderInvoiceForm } from "Shared/Types/invoice";
import { readObj } from "Service/storage";
import Button from "Components/UI/Button/Button";
import InvoiceForm from "./InvoiceForm/InvoiceForm";
import { addOrderInvoice, sendEmail } from "Redux/OrderInvoice/reducer";
import { notifyError, notifySuccess } from "Components/Notify/notify";
import Cost from "./Cost/Cost";
import {
  checkInvioceCreated,
  getInvoiceData,
  getOverviewInfo,
} from "Shared/Utils/invoice";
import { selectSupplyRate } from "Redux/SupplyRate/slice";
import { selectTaxRate } from "Redux/TaxRate/slice";
import EditInvoiceForm from "./EditInvoiceForm/EditInvoiceForm";
import { getOrder } from "Redux/Order/reducer";
import InvoicePay from "./InvoicePay/InvoicePay";
import { pdf } from "@react-pdf/renderer";
import OrderInvoicePDF from "Components/Order/Invoice/InvoicePDF/InvoicePdf";

const OrderInvoice = () => {
  const currentOrder = useSelector(selectCurrentOrder);
  const profile = useSelector(selectAccount);
  const dispatch = useAppDispatch();
  const actionItems = useSelector(selectActionItemList) || [];
  const supplyRate = useSelector(selectSupplyRate);
  const taxRate = useSelector(selectTaxRate);
  const [orderData, setOrderData] = useState<IOrderInvoiceData>();
  const [po, setPO] = useState<string>();
  const [emails, setEmails] = useState<string>(
    currentOrder?.customer?.email || ""
  );

  const createInvoice = async () => {
    const onSuccess = async (id?: string) => {
      notifySuccess("Invoice is Created");
      if (id && emails) {
        await dispatch(sendEmail({ id, data: { recipients: [emails] } }));
      }
      if (currentOrder?.id) {
        dispatch(getOrder(currentOrder?.id));
      }
    };
    if (profile && currentOrder) {
      const data = {} as IOrderInvoiceForm;
      data.shop = profile.is_company_admin
        ? readObj("current_shop").id
        : profile.shop.id;
      data.order = currentOrder.id;
      if (po) {
        data.purchase_order = +po;
      }
      if (!emails) {
        notifyError("Email is required!");
        return;
      }
      if (orderData) {
        const pdfBlob = await pdf(
          <OrderInvoicePDF data={orderData} profile={profile} />
        ).toBlob();
        if (pdfBlob) {
          data.data = JSON.stringify({
            ...orderData,
            emails: [emails],
          });
          data.pdf_file = pdfBlob;
          dispatch(addOrderInvoice({ data, onSuccess }));
        }
      }
    }
  };

  const fetchActionItems = async () => {
    currentOrder?.actionitem_set?.forEach(
      async ({ id }) => await dispatch(getActionItem(id))
    );
  };

  useEffect(() => {
    if (currentOrder?.service_order_invoice) {
      setOrderData(undefined);
      if (typeof currentOrder.service_order_invoice?.data === "string") {
        setOrderData(JSON.parse(currentOrder?.service_order_invoice.data));
      } else {
        setOrderData(currentOrder?.service_order_invoice.data);
      }

      return;
    }

    if (currentOrder && profile) {
      setOrderData(undefined);
      setOrderData(
        getInvoiceData(currentOrder, actionItems, profile, supplyRate, taxRate)
      );
    }
  }, [currentOrder, actionItems]);

  useEffect(() => {
    fetchActionItems();
  }, []);
  if (!currentOrder || !profile || !orderData) return <></>;
  return (
    <>
      <div className={styles.invoice}>
        <img className={styles.invoice__logo} src={BlueLogo} alt="logo" />
        <InvoiceHeader orderData={orderData} />

        {orderData?.action_itmes.map((actionItem) => (
          <InvoiceActionItem actionItem={actionItem} key={actionItem.id} />
        ))}
        <div className={styles.invoice__overview}>
          <UnitInfo orderData={orderData} />
          <Cost orderData={orderData} profile={profile} />
        </div>

        {checkInvioceCreated(currentOrder) && (
          <>
            <div className={styles.invoice__total}>
              {getOverviewInfo(orderData, currentOrder.invoice_hours).map(
                ({ title, value }) => (
                  <p key={title}>
                    {title}
                    {value}
                  </p>
                )
              )}
            </div>
            <InvoiceForm
              emails={emails}
              setEmails={setEmails}
              po={po}
              setPO={setPO}
            />
          </>
        )}
      </div>
      {checkInvioceCreated(currentOrder) && (
        <div className={styles.invoice__action}>
          <Button onClick={createInvoice}>Create Invoice</Button>
        </div>
      )}
      {!checkInvioceCreated(currentOrder) && (
        <>
          <EditInvoiceForm
            invoice={currentOrder.service_order_invoice}
            orderData={orderData}
          />
          <InvoicePay invoice={currentOrder.service_order_invoice} />
        </>
      )}
    </>
  );
};

export default OrderInvoice;
