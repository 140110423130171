import React from "react";
import styles from "./SortModal.module.scss";
import Icon from "Components/UI/Icon/Icon";
import { ArrowDownIcon, ArrowUpIcon, CloseIcon } from "Assets/index";
import { useSearchParams } from "react-router-dom";
import cn from "classnames";
import { ISortField } from "Shared/Types/common";

type SortModalProps = {
  onClose?: () => void;
  sortFields?: ISortField[];
};

export const SortModal: React.FC<SortModalProps> = ({
  onClose,
  sortFields,
}) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const handleSort = (value: string) => {
    searchParams.set("sortBy", value);
    setSearchParams(searchParams);
  };
  const isActive = (value: string) => {
    return searchParams.get("sortBy") === value;
  };

  return (
    <div className={styles.modal}>
      <p>Sort by</p>
      <Icon
        component={CloseIcon}
        onClick={onClose}
        className={styles.modal__close}
      />
      {sortFields?.map((field) => (
        <div key={field.label} className={styles.modal__field}>
          <p>{field.label}</p>
          <div className={styles.modal__field__icons}>
            <Icon
              component={ArrowUpIcon}
              onClick={() => handleSort(field.value)}
              className={cn(isActive(field.value) && styles.active)}
            />
            <Icon
              component={ArrowDownIcon}
              onClick={() => handleSort("-" + field.value)}
              className={cn(isActive("-" + field.value) && styles.active)}
            />
          </div>
        </div>
      ))}
    </div>
  );
};
