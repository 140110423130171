import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";
import {
  getSubtypeList,
  getSubtype,
  addSubtype,
  updateSubtype,
  deleteSubtype,
} from "./reducer";
import { ISubtype } from "Shared/Types/subtype";

interface ISubtypeState {
  subtypeList?: ISubtype[];
  currentSubtype?: ISubtype | null;
  loading: boolean;
}

const initialState: ISubtypeState = {
  subtypeList: [],
  currentSubtype: null,
  loading: false,
};

const subtypeSlice = createSlice({
  name: "subtype",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getSubtype.pending, (state) => {
      state.loading = true;
      state.currentSubtype = null;
    });
    builder.addCase(getSubtype.rejected, (state) => {
      state.loading = false;
      state.currentSubtype = null;
    });
    builder.addCase(getSubtype.fulfilled, (state, action) => {
      state.loading = false;
      state.currentSubtype = action.payload;
    });

    builder.addCase(addSubtype.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(addSubtype.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(addSubtype.fulfilled, (state) => {
      state.loading = false;
    });

    builder.addCase(getSubtypeList.pending, (state) => {
      state.loading = true;
      state.subtypeList = [];
    });
    builder.addCase(getSubtypeList.rejected, (state) => {
      state.loading = false;
      state.subtypeList = [];
    });
    builder.addCase(getSubtypeList.fulfilled, (state, action) => {
      state.loading = false;
      state.subtypeList = action.payload.results;
    });

    builder.addCase(updateSubtype.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateSubtype.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(updateSubtype.fulfilled, (state) => {
      state.loading = false;
    });

    builder.addCase(deleteSubtype.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(deleteSubtype.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(deleteSubtype.fulfilled, (state) => {
      state.loading = false;
    });
  },
});

export const selectCurrentSubtype = (state: RootState) =>
  state.subtype.currentSubtype;
export const selectSubtypeLoading = (state: RootState) => state.subtype.loading;
export const selectSubtypeList = (state: RootState) =>
  state.subtype.subtypeList;

export default subtypeSlice;
