import React from "react";
import styles from "./Accordion.module.scss";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
export interface InnerFunctionProps {
  id: string;
  name: string;
}

export interface HeadProps {
  id: string;
  name: string;
  handlePreventOpenAccordion: (event: React.MouseEvent) => void;
}

type FunctionReturningJSX = (props: HeadProps) => JSX.Element;
interface AccordionManualProps {
  item: InnerFunctionProps;
  head: FunctionReturningJSX;
  children: React.ReactNode;
  expanded: string | boolean;
  handleChange: (
    panel: string
  ) => (event: React.SyntheticEvent, newExpanded: boolean) => void;
}

export const Accordion: React.FC<AccordionManualProps> = ({
  item,
  children,
  head,
  expanded,
  handleChange,
}) => {
  const handlePreventOpenAccordion = (event: React.MouseEvent) => {
    event.stopPropagation();
  };

  const HeadInfoProps: HeadProps = {
    ...item,
    handlePreventOpenAccordion,
  };

  return (
    <div className={styles.accardion}>
      <MuiAccordion
        expanded={expanded === `${item.id}`}
        onChange={handleChange(`${item.id}`)}
      >
        <MuiAccordionSummary
          aria-controls="panel1d-content"
          id="panel1d-header"
        >
          {head(HeadInfoProps)}
        </MuiAccordionSummary>
        <MuiAccordionDetails>{children}</MuiAccordionDetails>
      </MuiAccordion>
    </div>
  );
};
