import React from "react";
import { IAccount } from "Shared/Types/auth";
import { getInvoiceRateFields } from "Shared/Utils/invoice";
import { IOrderInvoiceData } from "Shared/Types/invoice";
import { Text, View } from "@react-pdf/renderer";
import { styles } from "./style";

type CostProps = {
  orderData: IOrderInvoiceData;
  profile: IAccount;
};

const Cost: React.FC<CostProps> = ({ orderData }) => {
  return (
    <View>
      {getInvoiceRateFields(orderData)?.map(({ title, value, isGreen }) => (
        <View key={title} style={styles.costItem}>
          <Text style={styles.costTitle}>{title}</Text>
          <Text
            style={[styles.costValue, isGreen ? styles.costValueGreen : ""]}
          >
            {value}
          </Text>
        </View>
      ))}
    </View>
  );
};
export default Cost;
