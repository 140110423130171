import Switch from "Components/UI/Switch/Switch";
import React, { useEffect, useState } from "react";
import styles from "./PermissionItem.module.scss";
import { permissionsTitle } from "Shared/Constants/app";

type PermissionItemProps = {
  permission: string;
  changeRoles: (per: string[], isAdd: boolean) => void;
  curPermissions: Set<string>;
  changed: boolean;
  setChanged: (value: boolean) => void;
};

const PermissionItem: React.FC<PermissionItemProps> = ({
  permission,
  changeRoles,
  curPermissions,
  changed,
  setChanged,
}) => {
  const [value, setValue] = useState(curPermissions.has(permission));
  const changeRole = (checked: boolean) => {
    setChanged(!changed);
    setValue(checked);
    changeRoles([permission], checked);
  };

  useEffect(() => {
    setValue(curPermissions.has(permission));
  }, [changed]);

  return (
    <div key={permission} className={styles.permission}>
      <p>{permissionsTitle[permission]}</p>
      <Switch checked={value} onChange={(_, checked) => changeRole(checked)} />
    </div>
  );
};
export default PermissionItem;
