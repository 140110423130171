import { BreadcrumbItem } from "Components/Breadcrumb/Breadcrumb";
import Layout from "Components/Layout/Layout";
import ServiceUpdate from "Containers/Service/SericeUpdate/ServiceUpdate";
import { selectCurrentService } from "Redux/Service/slice";
import { useBreadcrumb } from "Shared/Hooks/use-breadcrumb";
import React from "react";
import { useSelector } from "react-redux";

export const ServiceUpdatePage: React.FC = () => {
  const service = useSelector(selectCurrentService);
  const breads: BreadcrumbItem[] = [
    { title: "Service", link: "/services" },
    { title: service?.name || "", link: "" },
  ];

  useBreadcrumb({ links: breads, option: { upd: service?.name } });

  return (
    <Layout>
      <ServiceUpdate />
    </Layout>
  );
};
