import { client } from "Service/axios";
import { SearchResult } from "Shared/Types/common";
import { IUnit, IUnitSearchParams } from "Shared/Types/unit";

const UNIT_URL = "unit/";

class Unit {
  getUnitsList(params: IUnitSearchParams | null) {
    return client.get<SearchResult<IUnit>>(UNIT_URL, { params });
  }
  getUnit(id: string) {
    return client.get<IUnit>(`${UNIT_URL}${id}/`);
  }
  addUnit(data: FormData) {
    return client.post(UNIT_URL, data, {
      headers: { "Content-Type": "multipart/form-data" },
    });
  }
  updateUnit(id: string, data: FormData) {
    return client.patch(`${UNIT_URL}${id}/`, data, {
      headers: { "Content-Type": "multipart/form-data" },
    });
  }
  deleteUnit(id: string) {
    return client.delete(`${UNIT_URL}${id}/`);
  }
}

export default Unit;
