import React from 'react';
import { TariffsContainer } from "Containers/Tariffs/TariffsContainer";


export const Tariffs = () => {
  return (
    <div>
      <TariffsContainer />
    </div>
  )
};