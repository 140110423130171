import React, { useState } from "react";
import styles from "./ActionItemDetailHeader.module.scss";
import ActionItemNote from "../ActionItemNote/ActionItemNote";
import {
  IActionItem,
  IActionItemFields,
  IActionItemFile,
} from "Shared/Types/order";
import Attachments from "Components/Attachments/Attachments";
import ActionItemHistory from "../ActionItemHistory/ActionItemHistory";
import IconButton from "Components/UI/IconButton/IconButton";
import Icon from "Components/UI/Icon/Icon";
import { CloseIcon, DeleteIcon } from "Assets/index";
import { useAppDispatch } from "Redux/store";
import { getActionItem, updateActionItem } from "Redux/ActionItem/reducer";
import { useSelector } from "react-redux";
import { selectActionItemStatusList } from "Redux/ActionItem/slice";
import { getActionItemAmount } from "Shared/Utils/action-item";
import { getShortValue } from "Shared/Utils/order";
import SelectField from "Components/UI/SelectField/SelectField";
import { notifySuccess } from "Components/Notify/notify";

type ActionItemDetailHeaderProps = {
  currentActionItem: IActionItem;
  onClose: () => void;
  openDelete: (id: string) => void;
  index: number;
  saveActionItemField?: (field: IActionItemFields, value?: string) => void;
  isEstimate?: boolean;
};

const ActionItemDetailHeader: React.FC<ActionItemDetailHeaderProps> = ({
  currentActionItem,
  onClose,
  openDelete,
  index,
  saveActionItemField,
  isEstimate,
}) => {
  const [selectedImages, setSelectedImages] = useState<File[]>([]);
  const [selectedDocs, setSelectedDocs] = useState<File[]>([]);
  const [deletingDocs, setDeletingDocs] = useState<string[]>([]);
  const [deletingImages, setDeletingImages] = useState<string[]>([]);
  const statusList = useSelector(selectActionItemStatusList) || [];
  const dispatch = useAppDispatch();

  const onSuccess = () => {
    notifySuccess("Action item is updated");
    setSelectedImages([]);
    setSelectedDocs([]);
    setDeletingImages([]);
    setDeletingDocs([]);
    dispatch(getActionItem(currentActionItem.id));
  };
  const onUploadImages = (files?: File[], images?: File[]) => {
    const data = {
      action_item_gallery: images,
      action_item_file: files,
    };
    dispatch(
      updateActionItem({
        id: currentActionItem.id,
        data,
        isFile: true,
        onSuccess,
      })
    );
  };

  const onDeleteFiles = (
    deletingDocs?: string[],
    deletingImages?: string[]
  ) => {
    const data = {
      file_id_list: JSON.stringify(deletingDocs),
      gallery_id_list: JSON.stringify(deletingImages),
    };
    dispatch(
      updateActionItem({
        id: currentActionItem.id,
        data,
        isFile: false,
        onSuccess,
      })
    );
  };

  return (
    <div className={styles.head}>
      <div className={styles.head__info}>
        Action item {index}
        {isEstimate && (
          <span> {getShortValue(currentActionItem.complaint || "", 15)}</span>
        )}
        {!isEstimate && (
          <SelectField
            onChange={(value) =>
              saveActionItemField?.("status", value?.toString())
            }
            defaultValue={{
              label: currentActionItem.status?.name || "",
              value: currentActionItem.status?.id || "",
            }}
            options={statusList
              .filter(
                (status) =>
                  status.queue <= (currentActionItem?.status?.queue || 1) &&
                  status.queue > 1
              )
              .map((status) => ({
                label: status.name,
                value: status.id,
              }))}
            name="status"
          />
        )}
        {isEstimate && (
          <div className={styles.head__info__status}>
            {currentActionItem.status?.name}
          </div>
        )}
        <span>
          Total{" "}
          <span className={styles.head__info__total}>
            {getActionItemAmount(currentActionItem)} $
          </span>
        </span>
      </div>

      <div className={styles.head__btns}>
        <div className={styles.head_icons}>
          <ActionItemNote
            actionItemId={currentActionItem.id}
            noteCount={currentActionItem.note_count}
          />
          <Attachments
            deletingImages={deletingImages}
            deletingDocs={deletingDocs}
            setDeletingImages={setDeletingImages}
            setDeletingDocs={setDeletingDocs}
            selectedImages={selectedImages}
            setSelectedImages={setSelectedImages}
            selectedDocs={selectedDocs}
            setSelectedDocs={setSelectedDocs}
            onDeleteFiles={onDeleteFiles}
            documents={currentActionItem.action_item_file as IActionItemFile[]}
            images={currentActionItem.action_item_gallery as IActionItemFile[]}
            onUpload={onUploadImages}
          />
          <ActionItemHistory
            actionItemId={currentActionItem.id}
            actionItemIndex={index}
          />
          <IconButton
            variant="primary"
            icon={DeleteIcon}
            onClick={() => openDelete(currentActionItem.id)}
          />
          <Icon component={CloseIcon} fontSize="large" onClick={onClose} />
        </div>
      </div>
    </div>
  );
};
export default ActionItemDetailHeader;
