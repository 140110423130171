import React, { ReactNode } from "react";
import styles from "./Layout.module.scss";
import Sidebar from "Components/Settings/Sidebar/Sidebar";

type LayoutProps = {
  children: ReactNode;
};
const Layout: React.FC<LayoutProps> = ({ children }) => {
  return (
    <div className={styles.settings}>
      <div className={styles.settings__container}>
        <Sidebar />
        <div className={styles.settings__container__content}>{children}</div>
      </div>
    </div>
  );
};
export default Layout;
