import { BreadcrumbItem } from "Components/Breadcrumb/Breadcrumb";
import Layout from "Components/Layout/Layout";
import { VendorUpdate } from "Containers/Vendor/VendorUpdate/VendorUpdate";
import { selectCurrentVendor } from "Redux/Vendor/slice";
import { useBreadcrumb } from "Shared/Hooks/use-breadcrumb";
import React from "react";
import { useSelector } from "react-redux";

export const VendorUpdatePage: React.FC = () => {
  const vendor = useSelector(selectCurrentVendor);
  const breads: BreadcrumbItem[] = [
    { title: "Vendors", link: "/vendors" },
    { title: `Update ${vendor?.vendor_name}`, link: "" },
  ];

  useBreadcrumb({ links: breads, option: { upd: vendor?.vendor_name } });
  return (
    <Layout>
      <VendorUpdate />
    </Layout>
  );
};
