import React, { useEffect, useState } from "react";
import Block from "Components/UI/Block/Block";
import InfoField from "Components/UI/InfoField/InfoField";
import { useNavigate, useParams } from "react-router";
import { useAppDispatch } from "Redux/store";
import { useSelector } from "react-redux";
import { getLaborRate } from "Redux/LaborRate/reducer";
import styles from "./ServiceDetail.module.scss";
import { selectCurrentService } from "Redux/Service/slice";
import { deleteService, getService } from "Redux/Service/reducer";
import { Grid } from "@mui/material";
import DeleteModal from "Components/UI/DialogWindow/DeleteModal";
import IconButton from "Components/UI/IconButton/IconButton";
import { DeleteIcon, EditIcon } from "Assets/index";
import { notifySuccess } from "Components/Notify/notify";
import { getInFoService } from "Shared/Utils/service";

const ServiceDetail = () => {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const service = useSelector(selectCurrentService);
  const navigate = useNavigate();
  const [showDelete, setShowDelete] = useState(false);
  const { basicInfoService, infoService } = getInFoService(service);

  const goToEditPage = () => {
    navigate(`/services/edit/${id}`);
  };

  const handleDialogClose = () => {
    setShowDelete(false);
  };

  const handleDialogOpen = () => {
    setShowDelete(true);
  };

  const handleDeleteService = async () => {
    const onSuccess = () => {
      notifySuccess("Service deleted");
      navigate("/services");
    };
    if (id) {
      await dispatch(deleteService({ id, onSuccess }));
    }
  };

  useEffect(() => {
    if (id) {
      dispatch(getService(id));
    }
  }, [id]);

  useEffect(() => {
    if (service?.labor_rate) {
      dispatch(getLaborRate(service.labor_rate));
    }
  }, []);

  return (
    <div className={styles.detail}>
      <div className={styles.detail__head}>
        <div className={styles.detail__head__action}>
          <IconButton icon={EditIcon} onClick={goToEditPage} />
          <IconButton icon={DeleteIcon} onClick={handleDialogOpen} />
        </div>
      </div>
      <Block className={styles.detail__block} title="Service">
        <Grid container>
          {basicInfoService.map(({ label, value, xs }) => (
            <Grid item xs={xs} key={label} mt={2}>
              <InfoField
                key={label}
                label={label}
                value={value?.toString()}
                xs={xs}
              />
            </Grid>
          ))}
        </Grid>
      </Block>
      <Block className={styles.detail__block} mt={20} title="Preventative Maintenance (PM)">
        <Grid container>
          {infoService.map(({ label, value, xs }) => (
            <Grid item xs={xs} key={label} mt={2}>
              <InfoField
                key={label}
                label={label}
                value={value?.toString()}
                xs={xs}
              />
            </Grid>
          ))}
        </Grid>
      </Block>

      <DeleteModal
        open={showDelete}
        handleClose={handleDialogClose}
        title="Delete"
        text="Are you sure...?"
        acceptTitle="Delete"
        accept={handleDeleteService}
      />
    </div>
  );
};

export default ServiceDetail;
