import React, { FC, ReactNode, useState } from "react";
import styles from "./Card.module.scss";
import More from "Components/More/More";
import { Grid } from "@mui/material";
import cn from "classnames";
import { ICardData } from "Shared/Types/common";
import StatusView, { IStatusView } from "Components/StatusView/StatusView";
import { toCamelCase } from "Shared/Utils/app";
import Checkbox from "Components/UI/Inputs/Checkbox";
import Button from "Components/UI/Button/Button";
import { ICompanyRequest } from "Shared/Types/company";

export type ICardAction = IStatusView & {
  onClick: (id: string) => void;
};

export type CardProps = {
  fields: string[];
  dataId: string;
  cardData: ICardData[];
  status?: IStatusView;
  onClick?: (id: string) => void;
  openDeleteModal?: (id: string) => void;
  className?: string;
  isNeedStatus?: boolean;
  withoutHead?: boolean;
  editClick?: (id: string) => void;
  withoutMore?: boolean;
  isNeedCheck?: boolean;
  onCheck?: (id: string) => void;
  isNeedAction?: boolean;
  actionTitle?: string;
  actionClick?: (id: string) => void;
  checked?: boolean;
  isNeedHeadAction?: boolean;
  headActionNode?: ReactNode;
};

const Card: FC<CardProps> = ({
  fields,
  onClick,
  openDeleteModal,
  cardData,
  dataId,
  isNeedStatus,
  status,
  withoutHead,
  editClick,
  withoutMore,
  className,
  isNeedCheck,
  actionClick,
  actionTitle,
  onCheck,
  checked,
  isNeedAction,
  isNeedHeadAction,
  headActionNode,
}) => {
  const [openMore, setOpenMore] = useState(false);
  const closeMore = () => {
    setOpenMore(false);
  };

  return (
    <Grid item md={2.9}>
      <div
        className={cn(styles.card, className)}
        onClick={() => onClick?.(dataId)}
        onMouseLeave={closeMore}
      >
        {!withoutHead && (
          <div className={styles.card__head}>
            {isNeedCheck && (
              <div className={styles.card__head__check}>
                <Checkbox
                  name="edit"
                  onChange={() => onCheck?.(dataId)}
                  checked={checked}
                />
              </div>
            )}
            <span className={styles.row__body__item}>
              {isNeedStatus && (
                <StatusView
                  title={status?.title || ""}
                  color={toCamelCase(status?.color || "")}
                  className={styles.card__head__status}
                />
              )}
              {isNeedHeadAction && <>{headActionNode}</>}
            </span>
            <div className={styles.card__head__actions}>
              {!withoutMore && (
                <More
                  openDelete={() => openDeleteModal?.(dataId)}
                  openMore={openMore}
                  setOpenMore={setOpenMore}
                  editClick={() => editClick?.(dataId)}
                />
              )}
            </div>
          </div>
        )}
        <div className={cn(styles.card__body)}>
          {cardData.map(
            ({
              title,
              value,
              isValueBlue,
              isOnlyValue,
              isWrap,
              withoutBorder,
              isMain,
            }) => (
              <div
                className={cn(
                  styles.card__body__item,
                  !fields.includes(title) && styles.card__body__item_hidden,
                  isWrap && styles.card__body__item_wrap,
                  withoutBorder && styles.card__body__item_withoutBorder,
                  isMain && styles.card__body__item_main,
                )}
                key={title}
              >
                {!isOnlyValue && (
                  <span className={cn(styles.card__body__item__title)}>
                    {title}
                  </span>
                )}
                <span
                  className={cn(
                    isValueBlue && styles.card__body__item__blue,
                    isWrap && styles.card__body__item__value_wrap,
                  )}
                >
                  {value}
                </span>
              </div>
            ),
          )}
        </div>
        {isNeedAction && (
          <div className={styles.card__action}>
            <Button
              onClick={(event) => {
                event.stopPropagation();
                actionClick?.(dataId);
              }}
            >
              {actionTitle}
            </Button>
          </div>
        )}
      </div>
    </Grid>
  );
};
export default Card;
