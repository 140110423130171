import React, { useState } from "react";
import Modal from "Components/UI/Modal/Modal";
import styles from "./AttachmentWithoutCamera.module.scss";
import Icon from "Components/UI/Icon/Icon";
import { PaperCLipIcon } from "Assets/index";
import Button from "Components/UI/Button/Button";
import DragDrop from "Components/UI/DragDrop/DragDrop";
import { useFormContext } from "react-hook-form";

type UploadFileModalProps = {
  imgName?: string;
  documentName?: string;
  isHaveImg?: boolean;
  isHaveDocument?: boolean;
};
const UploadFileModal: React.FC<UploadFileModalProps> = ({
  isHaveDocument,
  isHaveImg,
  imgName = "photo",
  documentName = "document",
}) => {
  const [openModal, setOpenModal] = useState<boolean>(false);
  const { setValue, getValues } = useFormContext();

  const onClose = () => {
    setOpenModal(false);
    if (getValues(imgName) instanceof File) {
      setValue(imgName, null);
    }
    if (getValues(documentName) instanceof File) {
      setValue(documentName, null);
    }
  };

  const toggleModal = () => {
    setOpenModal(!openModal);
  };

  return (
    <div>
      <div
        onClick={toggleModal}
        style={{ padding: "6px 10px 4px" }}
        className={styles.icon}
      >
        <Icon color="transparent" component={PaperCLipIcon} />
      </div>
      <Modal open={openModal} onClose={onClose} title="Attachments" isClosed>
        <p style={{ color: "#959595" }}>
          You may upload IMG or PDF (max size is 5 MB)
        </p>
        {isHaveImg && (
          <DragDrop name={imgName} type="image" label="Choose photo" />
        )}
        {isHaveDocument && (
          <DragDrop name={documentName} type="file" label="Choose file" />
        )}
        <div className={styles.modal__action}>
          <Button onClick={toggleModal}>Upload</Button>
        </div>
      </Modal>
    </div>
  );
};

export default UploadFileModal;
