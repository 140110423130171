import React from "react";
import Block from "Components/UI/Block/Block";
import styles from "./DetailUnit.module.scss";
import { useSelector } from "react-redux";
import { selectCurrentUnit } from "Redux/Unit/slice";
import { getBasicInfo } from "Shared/Utils/units";
import InfoField from "Components/UI/InfoField/InfoField";
import { Grid } from "@mui/material";

const DetailUnit: React.FC = () => {
  const unit = useSelector(selectCurrentUnit);
  const { basicInfo, additionalDetails } = getBasicInfo(unit);

  return (
    <div className={styles.detail_tab}>
      <div className={styles.details}>
        <div className={styles.details__cont}>
          <Block>
            <p className={styles.details__cont__title}>Basic Info</p>
            <Grid container>
              {basicInfo.map(({ label, value, xs }) => (
                <Grid item xs={xs} key={label} mt={2}>
                  <InfoField
                    key={label}
                    label={label}
                    value={value?.toString()}
                    xs={xs}
                  />
                </Grid>
              ))}
            </Grid>
          </Block>
        </div>
        <div className={styles.details__cont}>
          <Block>
            <p className={styles.details__cont__title}>Additional Details</p>
            <Grid container>
              {additionalDetails.map(({ label, value, xs }) => (
                <Grid item xs={xs} key={label} mt={2}>
                  <InfoField
                    key={label}
                    value={String(value)}
                    xs={xs}
                    label={label}
                  />
                </Grid>
              ))}
            </Grid>
          </Block>
        </div>
      </div>
    </div>
  );
};

export default DetailUnit;
