import React from "react";
import Layout from "Components/Layout/Layout";
import EmployeeInfoContainer from "Containers/Employee/EmployeeInfo/EmployeeInfo";
import { useBreadcrumb } from "Shared/Hooks/use-breadcrumb";
import { useSelector } from "react-redux";
import { selectCurrentUser } from "Redux/User/userSlice";
import { getBreads } from "Shared/Utils/user";

export const EmployeeInfoPage: React.FC = () => {
  const employee = useSelector(selectCurrentUser);

  useBreadcrumb({
    links: getBreads(employee?.last_name || ""),
    option: { upd: employee?.last_name },
  });

  return (
    <Layout>
      <EmployeeInfoContainer />
    </Layout>
  );
};
