import React, {
  ChangeEvent,
  MouseEvent,
  useEffect,
  useRef,
  useState,
} from "react";
import styles from "./DragDrop.module.scss";
import { DeleteIcon, DocumentIcon, EditIcon, PhotoIcon } from "Assets/index";
import { useFormContext } from "react-hook-form";
import Icon from "../Icon/Icon";
import { extractSubstring } from "Shared/Utils/extractCertainStringPart";

type DragDropProps = {
  label?: string;
  name: string;
  type?: "image" | "file";
};
const DragDrop: React.FC<DragDropProps> = ({ label, name, type = "image" }) => {
  const { control, setValue } = useFormContext();
  const [currentFile, setCurrentFile] = useState(control?._defaultValues[name]);
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const [fileName, setFileName] = useState(
    extractSubstring(control._defaultValues[name] || "")
  );
  const handleFileChange = (files: FileList | null) => {
    if (files?.length) {
      const selectedFile = files[0];
      setFileName(selectedFile.name);
      setCurrentFile(URL.createObjectURL(selectedFile));
      setValue(name, selectedFile);
    }
  };

  const handleEditClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleDeleteClick = (event: MouseEvent<SVGSVGElement>) => {
    event.stopPropagation();
    setCurrentFile(null);
    setValue(name, null);
  };

  useEffect(() => {
    const file = control._formValues[name];
    if (file instanceof File) {
      setCurrentFile(URL.createObjectURL(file));
      setFileName(file.name);
    }
  }, []);
  return (
    <div className={styles.drag}>
      {currentFile && (
        <div className={styles.drag__withImg}>
          {type === "image" ? (
            <img
              src={currentFile as string}
              className={styles.drag__withImg__img}
            />
          ) : (
            <div className={styles.drag__withImg__docs}>{fileName}</div>
          )}

          <div className={styles.drag__withImg__actions}>
            <Icon component={EditIcon} onClick={handleEditClick} />
            <Icon
              component={DeleteIcon}
              onClick={(event) => handleDeleteClick(event)}
            />
          </div>
        </div>
      )}
      <label>
        {!currentFile && (
          <div className={styles.drag__content}>
            <span>
              <Icon
                component={type === "image" ? PhotoIcon : DocumentIcon}
                style={{ fontSize: "24px", color: "transparent" }}
                color="transparent"
              />
            </span>

            {label && <p className={styles.drag__label}>{label}</p>}
          </div>
        )}
        <input
          ref={fileInputRef}
          type="file"
          accept={
            type === "image"
              ? "image/*"
              : "application/pdf, application/msword, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          }
          className={styles.drag__input}
          onChange={(event: ChangeEvent<HTMLInputElement>) => {
            handleFileChange(event.target.files);
          }}
        />
      </label>
    </div>
  );
};

export default DragDrop;
