import React, { useState } from "react";
import { Control } from "react-hook-form";
import styles from "./ServiceForm.module.scss";
import Button from "Components/UI/Button/Button";
import { Grid } from "@mui/material";
import InputField from "Components/UI/Inputs/InputField";
import { IServiceAdd, IServiceUpdate } from "Shared/Types/service";
import SelectField from "Components/UI/SelectField/SelectField";
import { timeIntervalOptoins } from "Shared/Utils/service";
import TimeField from "Components/UI/Inputs/TimeField/TimeField";
import Block from "Components/UI/Block/Block";

type PMProps = {
  control: Control<IServiceAdd | IServiceUpdate>;
  defaultTime?: string;
};

const PM: React.FC<PMProps> = ({ control, defaultTime }) => {
  const [showForm, setShowForm] = useState(!!control._defaultValues.miles);

  const handleClick = () => {
    setShowForm(true);
  };

  return (
    <Block className={styles.block} zIndex={1}>
      <div className={styles.pm__head}>
        <h3>Preventative Maintenance (PM)</h3>
        {!showForm && (
          <Button variant="outlined" onClick={handleClick}>
            Add
          </Button>
        )}
      </div>
      {showForm && (
        <Grid container>
          <Grid item xs={12}>
            <InputField
              name="miles"
              label="Useage interval(miles)"
              control={control}
              withController
              type="number"
            />
          </Grid>
          <Grid container item xs={12} columnSpacing={1}>
            <Grid item xs={6}>
              <InputField
                name="time_interval"
                label="Time interval"
                control={control}
                withController
                type="number"
              />
            </Grid>
            <Grid item xs={3}>
              <SelectField
                name="date_choice"
                label="."
                control={control}
                options={timeIntervalOptoins}
                withControl
              />
            </Grid>
            <Grid item xs={3}>
              <TimeField
                control={control}
                className={styles.pm__time}
                withController
                name="time"
                label="."
                defaultValue={defaultTime}
              />
            </Grid>
          </Grid>
        </Grid>
      )}
    </Block>
  );
};
export default PM;
