import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { IUser } from "Shared/Types/user";
import {
  addUser,
  blockUser,
  deleteUser,
  getUser,
  getUserList,
  updateUser,
  updateUserField,
} from "./reducer";

interface UserState {
  userList: IUser[];
  currentUser?: IUser | null;
  loading: boolean;
}

const initialState: UserState = {
  userList: [],
  currentUser: null,
  loading: false,
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    removeCurrentUser(state) {
      state.currentUser = null;
    },
  },
  extraReducers(builder) {
    builder.addCase(blockUser.pending, (state) => {
      state.loading = true;
    }),
      builder.addCase(blockUser.rejected, (state) => {
        state.loading = false;
        state.currentUser = null;
      }),
      builder.addCase(blockUser.fulfilled, (state) => {
        state.loading = false;
      });
    builder.addCase(getUser.pending, (state) => {
      state.loading = true;
      state.currentUser = null;
    }),
      builder.addCase(getUser.rejected, (state) => {
        state.loading = false;
        state.currentUser = null;
      }),
      builder.addCase(getUser.fulfilled, (state, action) => {
        state.currentUser = action.payload;
        state.loading = false;
      });
    builder.addCase(addUser.pending, (state) => {
      state.loading = true;
    }),
      builder.addCase(addUser.rejected, (state) => {
        state.loading = false;
      }),
      builder.addCase(addUser.fulfilled, (state) => {
        state.loading = false;
      });
    builder.addCase(updateUser.pending, (state) => {
      state.loading = true;
    }),
      builder.addCase(updateUser.rejected, (state) => {
        state.loading = false;
      }),
      builder.addCase(updateUser.fulfilled, (state) => {
        state.loading = false;
      }),
      builder.addCase(updateUserField.pending, (state) => {
        state.loading = true;
      }),
      builder.addCase(updateUserField.rejected, (state) => {
        state.loading = false;
      }),
      builder.addCase(updateUserField.fulfilled, (state) => {
        state.loading = false;
      }),
      builder.addCase(deleteUser.pending, (state) => {
        state.loading = true;
      }),
      builder.addCase(deleteUser.rejected, (state) => {
        state.loading = false;
      }),
      builder.addCase(deleteUser.fulfilled, (state) => {
        state.loading = false;
      });

    builder.addCase(getUserList.pending, (state) => {
      state.loading = true;
      state.userList = state.userList || [];
    }),
      builder.addCase(getUserList.rejected, (state) => {
        state.loading = false;
        state.userList = state.userList || [];
      }),
      builder.addCase(getUserList.fulfilled, (state, action) => {
        state.loading = false;
        state.userList = action.payload.results;
      });
  },
});

export const { removeCurrentUser } = userSlice.actions;

export const selectUserLoading = (state: RootState) => state.user.loading;
export const selectUserList = (state: RootState) => state.user.userList;
export const selectCurrentUser = (state: RootState) => state.user.currentUser;

export default userSlice;
