export const companyTableHeads = [
  { id: "tariff", label: "Tarif" },
  { id: "next_payment", label: "Next payment" },
  { id: "company_name", label: "Business name" },
  { id: "first_name", label: "First Name" },
  { id: "last_name", label: "Last Name" },
  { id: "phone", label: "Phone Number" },
  { id: "email", label: "Email" },
  { id: "company_type", label: "Company type" },
  { id: "action", label: "Actions" },
];

export const companyFields = [
  "Next Payment",
  "Business name",
  "First name",
  "Last name",
  "Contact",
  "Email",
];

export const tariffTable = [
  {
    tariff: "Pro",
    number: 12345,
    "Next Payment": "06/21/2023",
    price: 120,
    "Payment method": "Cash",
    "Business name": "Pride Truck",
    contact: "pridetruck@gamil.com/+1 (555) 555-1234",
    id: "dafadsfads",
  },
  {
    tariff: "Pro",
    number: 12345,
    "Next Payment": "06/21/2023",
    price: 120,
    "Payment method": "Cash",
    "Business name": "Pride Truck",
    contact: "pridetruck@gamil.com/+1 (555) 555-1234",
    id: "dsafdsfdsaf",
  },
];
