import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "Service/api/api";
import { IGlobalSearchQuery } from "Shared/Types/common";
import { errorHandler } from "Shared/Utils/app";

const getGlobalSearch = createAsyncThunk(
  "globalSearch/getGlobalSearch",
  async (params: IGlobalSearchQuery | null) => {
    try {
      const response = await api.globalSearch.getGlobalSearch(params);
      return response.data;
    } catch (error) {
      if (error instanceof Error) {
        errorHandler(error);
      }
      throw error;
    }
  }
);

export { getGlobalSearch };
