import { BreadcrumbItem } from "Components/Breadcrumb/Breadcrumb";
import Layout from "Components/Layout/Layout";
import OrderDetailAction from "Containers/Order/AllOrder/OrderDetail/OrderDetailAction/OrderDetailAction";
import { orderStatuses } from "Shared/Constants/order";
import { useBreadcrumb } from "Shared/Hooks/use-breadcrumb";
import React from "react";
import { useLocation, useParams } from "react-router";

export const UnnassianedDetailAction: React.FC = () => {
  const { id } = useParams();
  const { link, title } = orderStatuses.unassained;
  const {state}=useLocation()
  const breads: BreadcrumbItem[] = [
    { title: state?'Home':'Orders', link: state?'/':'/orders' },
    { title: state?"SO's":'All orders', link: state?'/':'/orders' },
    { title: title, link: `/orders/${link}`},
    { title: id || "", link: "" },
  ];

  useBreadcrumb({ links: breads, option: { upd: id } });
  return (
    <Layout>
      <OrderDetailAction status={link} />
    </Layout>
  );
};
