import React, { useEffect, useState } from "react";
import styles from "./PartList.module.scss";
import OrderInfo from "Components/Order/OrderDetail/PartList/OrderInfo/OrderInfo";
import { useSelector } from "react-redux";
import {
  selectCurrentOrder,
  selectOrderActionItemParts,
  selectOrderPartStatusList,
} from "Redux/Order/slice";
import ActionItemWithPart from "Components/Order/OrderDetail/PartList/ActionItemWithPart/ActionItemWithPart";
import { useAppDispatch } from "Redux/store";
import {
  getOrderActionItemParts,
  getOrderPartStatus,
} from "Redux/Order/reducer";
import PartActions from "Components/Order/OrderDetail/PartList/ActionItemWithPart/PartActions/PartActions";
import { IActionPart } from "Shared/Types/action-item-part";
import OrderStatusHistory from "Components/Order/OrderDetail/OrderStatusHisotry/OrderStatusHistory";
import { getActionItemPartStatuses } from "Redux/ActionPart/reducer";

const PartList: React.FC = () => {
  const currentOrder = useSelector(selectCurrentOrder);
  const statusList = useSelector(selectOrderPartStatusList);
  const actionItemAllParts = useSelector(selectOrderActionItemParts) || [];
  const dispatch = useAppDispatch();
  const [selectedParts, setSelectedParts] = useState<IActionPart[]>([]);
  // TODO: dispatch by Action Item id
  useEffect(() => {
    dispatch(getOrderPartStatus());
    if (currentOrder) {
      dispatch(
        getOrderActionItemParts({
          order: currentOrder.id,
        })
      );
    }
  }, [currentOrder]);
  useEffect(() => {
    dispatch(getActionItemPartStatuses());
  }, []);
  if (!currentOrder) return <></>;
  return (
    <div className={styles.parts}>
      <OrderInfo order={currentOrder} />
      <PartActions
        selectedParts={selectedParts}
        setSelectedParts={setSelectedParts}
        order={currentOrder}
      />
      {currentOrder.actionitem_set?.map((actionItem) => (
        <ActionItemWithPart
          key={actionItem.id}
          setSelectedParts={setSelectedParts}
          selectedParts={selectedParts}
          actionItem={actionItem}
          parts={actionItemAllParts.filter(
            (part) => part.action_item === actionItem.id
          )}
          order={currentOrder}
        />
      ))}
      <div className={styles.parts__status}>
        {statusList?.map((status) => (
          <OrderStatusHistory
            key={status.id}
            title={status.name}
            status={status.queue}
            orderStatus={currentOrder?.status_part?.queue || 1}
          />
        ))}
      </div>
    </div>
  );
};
export default PartList;
