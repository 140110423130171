import { StyleSheet } from "@react-pdf/renderer";

export const styles = StyleSheet.create({
  page: {
    margin: 20,
    marginRight: 20,
    fontSize: 12,
  },
  footer: {
    marginTop: 50,
    marginRight: 20,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
});
