import React, { useState } from "react";
import ShopForm from "Components/Shop/ShopForm/ShopForm";
import { IShopAdd } from "Shared/Types/shop";
import { shopScheme } from "Shared/Utils/shop";
import { useAppDispatch } from "Redux/store";
import { addShop } from "Redux/Shop/reducer";
import { notifySuccess } from "Components/Notify/notify";
import { useSelector } from "react-redux";
import { selectCurrentShop } from "Redux/Shop/slice";
import { useNavigate } from "react-router";
import { selectAccount } from "Redux/Auth/slice";
import styles from "./Shop.module.scss";
import StepProgress from "Components/UI/StepProgress/StepProgress";
import Modal from "Components/UI/Modal/Modal";
import Icon from "Components/UI/Icon/Icon";
import { LogoTitleIcon, SideBarLogoIcon } from "Assets/index";
import { checkFirstAuth } from "Shared/Utils/app";
import { readObj, write } from "Service/storage";

const AddShop = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const createdShop = useSelector(selectCurrentShop);
  const profile = useSelector(selectAccount);
  const isFirstAuth = checkFirstAuth(profile);
  const [shopCongrations, setShopCongrations] = useState(false);
  const curShop = readObj("current_shop");

  const onAddShop = (values: IShopAdd) => {
    if (values.status) {
      values.status = "active";
    } else {
      values.status = "inactive";
    }

    const onSuccess = () => {
      if (!curShop && createdShop) {
        write("current_shop", createdShop);
      }
      if (isFirstAuth) {
        setShopCongrations(true);
      } else {
        navigate("/shops");
      }

      notifySuccess("Shop is successfully created");
    };
    dispatch(
      addShop({
        data: values,
        onSuccess,
      })
    );
  };

  const goToComponents = () => {
    navigate("/settings/component-system/new");
  };

  const stepDescription = (
    <>
      Enter your company profile details, including name, address, and contact
      information.
      <br />
      Upload your company logo and any other relevant images.
      <br />
      Save your shop details.
    </>
  );

  return (
    <div className={styles.shop}>
      {isFirstAuth && (
        <StepProgress
          description={stepDescription}
          active={2}
          title="Creating a Shop"
        />
      )}
      {!shopCongrations && (
        <ShopForm onSubmit={onAddShop} scheme={shopScheme} />
      )}
      <Modal open={shopCongrations}>
        <div className={styles.shop__modal}>
          <span>
            <Icon
              component={SideBarLogoIcon}
              alt="Easy Shop"
              fontSize="large"
            />
            <Icon component={LogoTitleIcon} />
          </span>
          <span>Congratulations</span>
          <span>You&apos;ve successfully created your shop</span>
          <label>
            Now, let&apos;s configure some essential{" "}
            <strong onClick={goToComponents}>settings</strong>.
          </label>
        </div>
      </Modal>
    </div>
  );
};

export default AddShop;
