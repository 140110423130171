import React, { useState } from "react";
import { useNavigate } from "react-router";
import styles from "./Sidebar.module.scss";
import cn from "classnames";
import { DownIcon } from "Assets/index";
import { settingsPartsMenu } from "Shared/Constants/settings";

type PartsProps = {
  title: string;
  link: string;
};

const Parts: React.FC<PartsProps> = ({ title, link }) => {
  const navigate = useNavigate();
  const isActive = (link: string) => {
    return location.pathname.includes(link);
  };
  const [open, setOpen] = useState(isActive(link));
  const handleClick = (link: string) => {
    navigate("/settings/part-settings/" + link);
  };
  return (
    <>
      <p
        key={title}
        onClick={() => setOpen(!open)}
        className={cn(
          styles.sidebar__item,
          styles.sidebar__item__part,
          isActive(link) && styles.sidebar__item_active
        )}
      >
        {title}
        <DownIcon />
      </p>
      {open && (
        <div>
          {settingsPartsMenu.map((menu) => (
            <p
              key={title}
              onClick={() => handleClick(menu.link)}
              className={cn(
                styles.sidebar__item__part__items,
                isActive(menu.link) && styles.sidebar__item__part__items_active
              )}
            >
              {menu.title}
            </p>
          ))}
        </div>
      )}
    </>
  );
};
export default Parts;
