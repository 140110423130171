import React, { useEffect, useState } from "react";
import styles from "./ServiceOrderAdd.module.scss";
import UnitInfo from "Components/Order/ServiceOrderForms/UnitInfo";
import SchedulingAccessInfo from "Components/Order/ServiceOrderForms/SchedulingAccessInfo";
import AuthorizationInfo from "Components/Order/ServiceOrderForms/AuthorizationInfo";
import { FormProvider, useFieldArray, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { ObjectSchema } from "yup";
import {
  IOrderAdd,
  IOrderUpdate,
  OrderInitialValues,
} from "Shared/Types/orderAdd";
import Button from "Components/UI/Button/Button";
import { createOrder, getOrderStatusList } from "Redux/Order/reducer";
import { useAppDispatch } from "Redux/store";
import {
  notifyError,
  notifySuccess,
  notifyWarning,
} from "Components/Notify/notify";
import { useSelector } from "react-redux";
import {
  selectCreatedOrder,
  selectCreatedOrderStatus,
  selectOrderLoading,
  selectOrderStatusList,
  setCreatedOrder,
} from "Redux/Order/slice";
import { useNavigate } from "react-router";
import { removeCurrentUnit } from "Redux/Unit/slice";
import { IService } from "Shared/Types/service";
import ActionItemInfo from "Components/Order/ServiceOrderForms/ActionItemInfo";
import { read, readObj, write } from "Service/storage";
import { orderStatusesLinks, warning_fields } from "Shared/Constants/order";
import { getUrgencyOptionList } from "Redux/UrgencyOption/reducer";
import { selectAccount } from "Redux/Auth/slice";
import { selectUrgencyOptionList } from "Redux/UrgencyOption/slice";

type OrderFormProps = {
  schema: ObjectSchema<IOrderAdd | IOrderUpdate>;
};

const ServiceOrderForm: React.FC<OrderFormProps> = ({ schema }) => {
  const createdOrder = useSelector(selectCreatedOrder);
  const createdOrderValues = readObj("created_service_order");
  const createdOrderStatus = useSelector(selectCreatedOrderStatus);
  const timeStatuses = useSelector(selectUrgencyOptionList);
  const profile = useSelector(selectAccount);
  const methods = useForm({
    resolver: yupResolver(schema),
    defaultValues: createdOrder
      ? { ...OrderInitialValues, ...createdOrder }
      : { ...OrderInitialValues },
    mode: "onBlur",
  });

  const [isFormReset, setIsFormReset] = useState<boolean>(false);
  const [actionItemList, setActionItemList] = useState<IService[]>([]);
  const [servicesList, setServicesList] = useState<IService[]>([]);
  const [authBlankChecked, setAuthBlankChecked] = useState(false);
  const [formStep, setFormStep] = useState<number>(1);
  const statusList = useSelector(selectOrderStatusList) || [];
  const localFormStep = read("formStep");
  const current_shop = readObj("current_shop");

  const dispatch = useAppDispatch();
  const loading = useSelector(selectOrderLoading);
  const navigate = useNavigate();

  const control = methods.control;

  const { fields, append, remove } = useFieldArray({
    control,
    name: "action_items",
  });

  const onCreateSubmit = async (values: IOrderAdd) => {
    if (current_shop) {
      values.shop = current_shop.id;
    }
    const onSuccess = () => {
      const curStatus = statusList.find(
        (status) => status.id === createdOrderStatus
      );
      if (curStatus) {
        navigate(`/orders/${orderStatusesLinks[curStatus.queue - 1]}`);
      } else {
        navigate("/orders");
      }

      notifySuccess("Order successfully created");
      dispatch(setCreatedOrder(null));
    };
    await dispatch(createOrder({ data: values, onSuccess }));
  };

  const onCreateAndSaveSubmit = async (values: IOrderAdd) => {
    if (current_shop) {
      values.shop = current_shop.id;
    } else {
      notifyError("You don't assign any shop so you can't create Order");
      return;
    }
    const onSuccess = () => {
      write("formStep", "1");
      setFormStep(1);
      notifySuccess("Order successfully created");
      setIsFormReset(true);
      setTimeout(() => setIsFormReset(false), 100);
      setActionItemList([]);
      setServicesList([]);
      setAuthBlankChecked(false);
      methods.reset();
      dispatch(removeCurrentUnit());
      dispatch(setCreatedOrder(null));
    };
    await dispatch(createOrder({ data: values, onSuccess }));
  };

  const saveOrderValues = () => {
    dispatch(
      setCreatedOrder({ ...createdOrderValues, ...methods.getValues() })
    );
  };

  useEffect(() => {
    if (!methods.getValues("time_status")) {
      const id = timeStatuses.find((status) => status.title === "None")?.id;
      methods.setValue("time_status", id);
    }
  }, [timeStatuses]);

  useEffect(() => {
    const errors = Object.keys(methods.formState.errors);
    if (errors.length) {
      if (errors.length === 1) {
        const missed_field = errors[0];
        notifyWarning(`Missing field "${warning_fields[missed_field]}" !`);
      } else {
        notifyWarning("You missed one or more required fields!");
      }
    }
  }, [methods.formState.errors]);

  useEffect(() => {
    write("formStep", "1");
    dispatch(getOrderStatusList());
    if (profile?.company?.id) {
      dispatch(getUrgencyOptionList({ company: profile?.company?.id }));
    }
  }, []);

  return (
    <FormProvider {...methods}>
      <div className={styles.form}>
        {formStep === 1 && localFormStep === "1" && (
          <UnitInfo
            setFormStep={setFormStep}
            fields={fields}
            remove={remove}
            append={append}
            servicesList={servicesList}
            setActionItemList={setActionItemList}
            setServicesList={setServicesList}
            actionItemList={actionItemList}
            control={methods.control}
            isFormReset={isFormReset}
            saveOrderValues={saveOrderValues}
          />
        )}
        {formStep === 2 && localFormStep === "2" && (
          <ActionItemInfo
            onCreateSubmit={onCreateSubmit}
            setFormStep={setFormStep}
            actionItemList={actionItemList}
            servicesList={servicesList}
            setActionItemList={setActionItemList}
            setServicesList={setServicesList}
            remove={remove}
            append={append}
            fields={fields}
            control={control}
            saveOrderValues={saveOrderValues}
          />
        )}
        {formStep === 3 && localFormStep === "3" && (
          <SchedulingAccessInfo
            setFormStep={setFormStep}
            saveOrderValues={saveOrderValues}
          />
        )}
        {formStep === 4 && localFormStep === "4" && (
          <AuthorizationInfo
            setFormStep={setFormStep}
            authBlankChecked={authBlankChecked}
            setAuthBlankChecked={setAuthBlankChecked}
            control={methods.control}
            saveOrderValues={saveOrderValues}
          />
        )}
        {formStep === 4 && localFormStep === "4" && (
          <div className={styles.form__action}>
            <Button
              color="secondary"
              onClick={() => {
                saveOrderValues();
                setFormStep(3);
                write("formStep", "3");
              }}
            >
              Previous
            </Button>
            <div>
              <Button
                onClick={() => {
                  methods.handleSubmit(onCreateAndSaveSubmit)();
                }}
                disabled={loading}
                variant="outlined"
                type="button"
                loading={loading}
              >
                Save & Create other
              </Button>
              <Button
                style={{ marginLeft: "12px" }}
                disabled={loading}
                onClick={() => {
                  methods.handleSubmit(onCreateSubmit)();
                }}
                type="button"
                loading={loading}
              >
                Create SO
              </Button>
            </div>
          </div>
        )}
      </div>
    </FormProvider>
  );
};

export default ServiceOrderForm;
