import {
  IEstimateOrderAdd,
  IEstimateOrderConvert,
  IEstimateOrderUpdate,
  IEstimatePartUpdate,
  IEstimateServiceUpdate,
  IEstimatesOrder,
  IEstimatesOrderSearchParams,
  IEstimatesPartAdd,
  IEstimatesServiceAdd,
} from "Shared/Types/estimate";
import { client } from "Service/axios";
import { SearchResult } from "Shared/Types/common";

const ESTIMATE_URL = "order/";

class Estimate {
  getEstimatesOrderList(params: IEstimatesOrderSearchParams | null) {
    return client.get<SearchResult<IEstimatesOrder>>(
      `${ESTIMATE_URL}estimate/`,
      {
        params,
      },
    );
  }
  addEstimatesOrder(
    data: IEstimateOrderAdd,
    params: IEstimatesOrderSearchParams | null,
  ) {
    delete data.action_items;
    return client.post(`${ESTIMATE_URL}estimate/create/`, data, {
      params,
    });
  }
  getEstimateOrder(id: string, params: IEstimatesOrderSearchParams) {
    return client.get(`${ESTIMATE_URL}estimate/${id}/`, {
      params,
    });
  }
  updateEstimateOrder(
    id: string,
    data: IEstimateOrderUpdate,
    params: IEstimatesOrderSearchParams,
  ) {
    return client.patch(`${ESTIMATE_URL}estimate/${id}/update/`, data, {
      params,
    });
  }
  deleteEstimatesOrder(id: string, params: IEstimatesOrderSearchParams) {
    return client.delete(`${ESTIMATE_URL}estimate/${id}/delete/`, {
      params,
    });
  }
  addEstimatesService(
    data: IEstimatesServiceAdd,
    params: IEstimatesOrderSearchParams | null,
  ) {
    return client.post(`${ESTIMATE_URL}estimate_service/create/`, data, {
      params,
    });
  }
  updateEstimateService(
    id: string,
    data: IEstimateServiceUpdate,
    params: IEstimatesOrderSearchParams,
  ) {
    return client.patch(`${ESTIMATE_URL}estimate_service/${id}/update/`, data, {
      params,
    });
  }
  getEstimateServiceList(params: IEstimatesOrderSearchParams) {
    return client.get(`${ESTIMATE_URL}estimate_service/`, {
      params,
    });
  }
  getEstimateService(id: string, params: IEstimatesOrderSearchParams) {
    return client.get(`${ESTIMATE_URL}estimate_service/${id}/`, {
      params,
    });
  }
  deleteEstimatesService(id: string, params: IEstimatesOrderSearchParams) {
    return client.delete(`${ESTIMATE_URL}estimate_service/${id}/delete/`, {
      params,
    });
  }
  addEstimatesPart(
    data: IEstimatesPartAdd,
    params: IEstimatesOrderSearchParams,
  ) {
    return client.post(`${ESTIMATE_URL}estimate_part/create/`, data, {
      params,
    });
  }
  deleteEstimatesPart(id: string, params: IEstimatesOrderSearchParams) {
    return client.delete(`${ESTIMATE_URL}estimate_part/${id}/delete/`, {
      params,
    });
  }
  updateEstimatePart(
    id: string,
    data: IEstimatePartUpdate,
    params: IEstimatesOrderSearchParams,
  ) {
    return client.patch(`${ESTIMATE_URL}estimate_part/${id}/update/`, data, {
      params,
    });
  }
  getEstimatePart(id: string, params: IEstimatesOrderSearchParams) {
    return client.get(`${ESTIMATE_URL}estimate_part/${id}/`, {
      params,
    });
  }
  getEstimatePartList(params: IEstimatesOrderSearchParams) {
    return client.get(`${ESTIMATE_URL}estimate_part/`, {
      params,
    });
  }
  convertToOrder(id: string) {
    return client.post(`${ESTIMATE_URL}estimate/${id}/convert-to-order/`);
  }
}

export default Estimate;
