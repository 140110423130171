import { notifyError } from "Components/Notify/notify";
import Block from "Components/UI/Block/Block";
import StepProgress from "Components/UI/StepProgress/StepProgress";
import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router";
import styles from "./Home.module.scss";
import { LogoTitleIcon, SideBarLogoIcon } from "Assets/index";
import Icon from "Components/UI/Icon/Icon";
import Button from "Components/UI/Button/Button";
import { write } from "Service/storage";
import { useSelector } from "react-redux";
import { selectAccount } from "Redux/Auth/slice";
import { checkFirstAuth } from "Shared/Utils/app";
import Dashboard from "./Dashboard/Dashboard";

export const stepDescription = (
  <>
    <p>Now configure the following Settings:</p>
    <ol>
      <li>
        Rate Settings&nbsp;&nbsp; <span>a)</span>Labor Rate&nbsp;&nbsp;{" "}
        <span>b)</span>Tax Rate&nbsp;&nbsp;
        <span>c)</span>Supply Rate
      </li>
      <li>Role & Access</li>
      <li>
        Unit Settings&nbsp;&nbsp; <span>a)</span>Subtypes&nbsp;&nbsp;{" "}
        <span>b)</span>Unit Type
      </li>
    </ol>
  </>
);

const Home: React.FC = () => {
  const navigate = useNavigate();
  const profile = useSelector(selectAccount);
  const isFirstAuth = checkFirstAuth(profile);
  const location = useLocation();

  const goToSetting = () => {
    write("first_auth", "true");
    navigate("/settings/rate-settings/labor-rate/new");
  };

  useEffect(() => {
    if (location.pathname === "/forbidden") {
      notifyError("You do not have permission to view this page.");
    } else if (location.pathname === "/not-found") {
      notifyError("Page Not Found: The requested page could not be found");
    }
  }, []);
  return (
    <div className={styles.home}>
      <Dashboard />
      {isFirstAuth && (
        <div className={styles.home__container}>
          <Block isBlur isBorder className={styles.home__progress}>
            <div className={styles.home__progress__head}>
              Welcome to
              <Icon
                component={SideBarLogoIcon}
                alt="Easy Shop"
                fontSize="large"
              />
              <Icon component={LogoTitleIcon} />
            </div>
            <div className={styles.home__progress__description}>
              We&apos;re excited to have you on board. Before you start working,
              let&apos;s ensure your shop is configured for success. Follow
              these steps:
            </div>

            <StepProgress
              title="Shop Setting"
              active={0}
              description={stepDescription}
            />
            <div className={styles.home__progress__action}>
              <Button onClick={goToSetting}>
                Yes, let&apos;s set up my shop
              </Button>
            </div>
          </Block>
        </div>
      )}
    </div>
  );
};
export default Home;
