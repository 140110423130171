import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "Service/api/api";
import {
  IDispatchCallback,
  IDispatchCallbackWithId,
  IUpdateRequest,
} from "Shared/Types/common";
import {
  CustomerExtraError,
  ICustomerAdd,
  ICustomerSearchParams,
  ICustomerUpdate,
  ICustomerUpdateStatus,
} from "Shared/Types/customer";
import { errorHandler } from "Shared/Utils/app";
import { setError } from "./slice";
import { AxiosError } from "axios";

const getCustomerList = createAsyncThunk(
  "customer/getCustomerList",
  async (params: ICustomerSearchParams | null) => {
    try {
      const response = await api.customer.getCustomerList(params);
      return response.data;
    } catch (error) {
      if (error instanceof Error) {
        errorHandler(error);
      }
      throw error;
    }
  }
);

const getCustomer = createAsyncThunk(
  "customer/getCustomer",
  async (id: string) => {
    try {
      const response = await api.customer.getCustomer(id);
      return response.data;
    } catch (error) {
      if (error instanceof Error) {
        errorHandler(error);
      }
      throw error;
    }
  }
);

const addCustomer = createAsyncThunk(
  "customer/addCustomer",
  async ({ data, onSuccess }: IDispatchCallback<ICustomerAdd>) => {
    try {
      const response = await api.customer.addCustomer(data);
      onSuccess?.();
      return response.data;
    } catch (error) {
      if (error instanceof Error) {
        errorHandler(error, true);
      }
      throw error;
    }
  }
);

const updateCustomer = createAsyncThunk(
  "customer/updateCustomer",
  async ({ id, data, onSuccess }: IDispatchCallbackWithId<ICustomerUpdate>) => {
    try {
      const response = await api.customer.updateCustomer(id, data);
      onSuccess?.();
      return response.data;
    } catch (error) {
      if (error instanceof Error) {
        errorHandler(error, true);
      }
      throw error;
    }
  }
);
const changeStatus = createAsyncThunk(
  "customer/changeStatus",
  async ({ id, data }: IUpdateRequest<ICustomerUpdateStatus>) => {
    try {
      const response = await api.customer.changeStatus(id, data);
      return response.data;
    } catch (error) {
      if (error instanceof Error) {
        errorHandler(error);
      }
      throw error;
    }
  }
);

const deleteCustomer = createAsyncThunk(
  "customer/deleteCustomer",
  async (id: string, { dispatch }) => {
    try {
      const response = await api.customer.deleteCustomer(id);
      return response.data;
    } catch (error) {
      if (error instanceof Error) {
        if (error instanceof AxiosError) {
          const extra: CustomerExtraError = {
            Units: error.response?.data?.extra?.Units || [],
            Orders: error.response?.data?.extra?.Orders || [],
          };
          dispatch(setError(extra));
        }
      }
      throw error;
    }
  }
);

export {
  getCustomerList,
  getCustomer,
  addCustomer,
  updateCustomer,
  deleteCustomer,
  changeStatus,
};
