import React, { HTMLInputTypeAttribute, useState } from "react";
import styles from "./InfoOrChangeField.module.scss";
import Icon from "Components/UI/Icon/Icon";
import { EditIcon } from "Assets/index";
import InputField from "../Inputs/InputField";

type InfoOrChangeProps = {
  onFieldChange: (value: string | number | null) => void;
  value: string;
  name: string;
  type?: HTMLInputTypeAttribute;
};

const InfoOrChangeField: React.FC<InfoOrChangeProps> = ({
  onFieldChange,
  value,
  name,
  type,
}) => {
  const [edit, setEdit] = useState(false);
  const [text, setText] = useState<string>(value);
  const onBlur = () => {
    if (text) {
      onFieldChange(text);
    }

    setEdit(false);
  };
  return !edit ? (
    <div className={styles.user}>
      <div className={styles.user__block}>
        <span className={styles.user__block__name}>{value}</span>
        <Icon
          component={EditIcon}
          onClick={() => setEdit(true)}
          fontSize="small"
        />
      </div>
    </div>
  ) : (
    <InputField
      name={name}
      onBlur={onBlur}
      defaultValue={value}
      onChange={(event) => setText(event.target.value)}
      type={type}
    />
  );
};
export default InfoOrChangeField;
