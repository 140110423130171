import { createSlice } from "@reduxjs/toolkit";
import { IHistory, IInnerHistory } from "Shared/Types/order";
import { getOrderHistory, getOrderInnerHistory } from "./reducer";
import { RootState } from "Redux/store";

interface OrderState {
  historyList?: IHistory[];
  innerHistoryList?: IInnerHistory[];
  error?: string | null;
  loading: boolean;
  success?: boolean;
  total?: number;
  numPage?: number;
}

const initialState: OrderState = {
  loading: false,
  success: false,
};

const historySlice = createSlice({
  name: "history",
  initialState,
  reducers: {
    clear(state) {
      state.loading = false;
      state.error = null;
      state.success = false;
    },
    clearOrderInnerHistory(state) {
      state.innerHistoryList = [];
    },
  },
  extraReducers(builder) {
    builder.addCase(getOrderHistory.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getOrderHistory.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
    builder.addCase(getOrderHistory.fulfilled, (state, action) => {
      state.loading = false;
      state.error = null;
      state.historyList = action.payload.results;
      state.total = action.payload.count;
      state.numPage = action.payload.num_pages;
    });
    builder.addCase(getOrderInnerHistory.fulfilled, (state, action) => {
      state.innerHistoryList = action.payload;
    });
  },
});

export const { clear, clearOrderInnerHistory } = historySlice.actions;

export const selectHistoryError = (state: RootState) => state.history.error;
export const selectHistorySuccess = (state: RootState) => state.history.success;
export const selectHistoryLoading = (state: RootState) => state.history.loading;
export const selectHistoryTotal = (state: RootState) => state.history.total;
export const selectHistoryNumPage = (state: RootState) => state.history.numPage;
export const selectHistoryList = (state: RootState) =>
  state.history.historyList;
export const selectInnerHistoryList = (state: RootState) =>
  state.history.innerHistoryList;
export default historySlice;
