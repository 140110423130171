import { client } from "Service/axios";
import {
  IAccount,
  IAuthLogin,
  IAuthRefresh,
  IAuthVerify,
  IFirstAuth,
  IToken,
} from "Shared/Types/auth";

const url = "/auth/";

class Auth {
  login(data: IAuthLogin) {
    return client.post<IToken>(`${url}jwt/create/`, data);
  }
  refresh(data: IAuthRefresh) {
    return client.post<IToken>(`${url}jwt/refresh/`, data);
  }
  verify(data: IAuthVerify) {
    return client.post<IAuthVerify>(`${url}jwt/verify/`, data);
  }
  firstAuth(data: IFirstAuth) {
    return client.post<any>(`${url}reset_password_confirm/first_auth/`, data);
  }

  resetPasswordByEmail(email: string) {
    return client.post<any>(`${url}reset_password/`, {
      email,
    });
  }
  resetPassword(data: IFirstAuth) {
    return client.post<any>(`${url}reset_password_confirm/`, data);
  }
  getMe() {
    return client.get<IAccount>(`user/me/`);
  }
}

export default Auth;
