import { client } from "Service/axios";
import { SearchResult } from "Shared/Types/common";
import {
  IUnitType,
  IUnitTypeAdd,
  IUnitTypeSearchParams,
  IUnitTypeUpdate,
} from "Shared/Types/unit-type";

const UNIT_TYPE_URL = "unit/type_of_chassis/";

class UnitType {
  getUnitTypeList(params: IUnitTypeSearchParams | null) {
    return client.get<SearchResult<IUnitType>>(UNIT_TYPE_URL, { params });
  }
  getUnitType(id: string) {
    return client.get<IUnitType>(`${UNIT_TYPE_URL}${id}/`);
  }
  addUnitType(data: IUnitTypeAdd) {
    return client.post(UNIT_TYPE_URL, data);
  }
  updateUnitType(id: string, data: IUnitTypeUpdate) {
    return client.put(`${UNIT_TYPE_URL}${id}/`, data);
  }
  deleteUnitType(id: string) {
    return client.delete(`${UNIT_TYPE_URL}${id}/`);
  }
}

export default UnitType;
