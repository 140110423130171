import React from "react";
import { Grid } from "@mui/material";
import InputField from "Components/UI/Inputs/InputField";
import { Control } from "react-hook-form";
import { ICompanyForm } from "./CompanyForm";
import AttachmentWithoutCamera from "Components/Attachments/AttachmentWithoutCamera/AttachmentWithoutCamera";

type BillingInformationProps = {
  control?: Control<ICompanyForm>;
};

const BillingInformation: React.FC<BillingInformationProps> = ({ control }) => {
  return (
    <div>
      <h4>Billing Information</h4>
      <Grid container spacing={2} rowSpacing={-1}>
        <Grid item xs={6}>
          <InputField
            name="billing_contact"
            label="Billing Contact"
            withController
            control={control}
          />
        </Grid>

        <Grid item xs={6}>
          <InputField
            name="billing_address"
            label="Billing Address"
            withController
            control={control}
          />
        </Grid>

        <Grid item xs={6}>
          <InputField
            name="payment_method"
            label="Payment Method"
            withController
            control={control}
          />
        </Grid>
      </Grid>
    </div>
  );
};
export default BillingInformation;
