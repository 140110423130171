import { IUnit, IUnitAdd, IUnitUpdate } from "Shared/Types/unit";
import * as Yup from "yup";
import { IService } from "Shared/Types/service";
import { ICardData } from "Shared/Types/common";
import { ICustomer } from "Shared/Types/customer";
import { isInstanced } from "./app";

export const unitSchema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  vin: Yup.string()
    .required("VIN is required")
    .matches(/^.{16}$/, "VIN must be exactly 16 characters"),
  license_plate: Yup.string(),
  year: Yup.number(),
  brand: Yup.string(),
  model: Yup.string(),
  number: Yup.string().required("Number is required"),
  nickname: Yup.string(),
  fleet: Yup.string(),
  description: Yup.string(),
  company: Yup.string().nullable(),
  customer: Yup.mixed<ICustomer>().required("Customer is required"),
  type_of_chassis: Yup.string().required("Type of Chassis is required"),
  subtype: Yup.string().required("Subtype is required"),
  photo: Yup.mixed<File>().nullable(),
  service: Yup.mixed<IService[]>(),
});

export const getInitialValue = (unit: IUnit) => {
  const init: IUnitAdd | IUnitUpdate = {
    ...unit,
    type_of_chassis: unit.type_of_chassis.id,
    subtype: unit.subtype.id,
  };

  return init;
};

export const getUnitCardData = (unit: IUnit) => {
  const data: ICardData[] = [
    {
      title: "Name",
      value: unit.name,
      withoutBorder: true,
    },
    { title: "Type of chassis", value: unit.type_of_chassis.name },

    {
      title: "Subtype",
      value: unit.subtype.name,
    },
    { title: "Track PM's", value: unit.year },
    {
      title: "License Plate State",
      value: unit.license_plate,
    },
    {
      title: "Customer",
      value:
        typeof unit.customer == "string"
          ? unit.customer
          : unit.customer.first_name,
    },
    {
      title: "VIN",
      value: unit.vin,
    },
  ];

  return data;
};

export const getBasicInfo = (unit?: IUnit | null) => {
  const basicInfo = [
    {
      label: "Customer",
      value: isInstanced<ICustomer>(unit?.customer),
      xs: 12,
    },
    { label: "Type of chassis", value: unit?.type_of_chassis.name, xs: 12 },
    { label: "Subtype", value: unit?.subtype.name, xs: 12 },
    { label: "Name", value: unit?.name, xs: 12 },
    { label: "VIN", value: unit?.vin, xs: 12 },
    { label: "License Plate State", value: unit?.license_plate, xs: 12 },
    { label: "License Plate", value: unit?.license_plate, xs: 12 },
    { label: "Year", value: unit?.year, xs: 6 },
    { label: "Make", value: unit?.model, xs: 6 },
    { label: "Model", value: unit?.model, xs: 12 },
    { label: "Unit number", value: unit?.number, xs: 6 },
    { label: "Unit nickname", value: unit?.nickname, xs: 6 },
    { label: "Fleet#", value: unit?.fleet, xs: 12 },
  ];

  const additionalDetails = [
    {
      label: "Additional information",
      value: isInstanced<ICustomer>(unit?.customer),
      xs: 12,
    },
    { label: "Photo", value: unit?.type_of_chassis.name, xs: 12 },
    { label: "Document", value: unit?.subtype.name, xs: 12 },
  ];
  return { basicInfo, additionalDetails };
};
