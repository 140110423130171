import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";
import {
  getServiceList,
  getService,
  addService,
  updateService,
  deleteService,
} from "./reducer";
import { IService } from "Shared/Types/service";

interface IServiceState {
  serviceList?: IService[];
  currentService?: IService | null;
  loading: boolean;
  numPage?: number;
}

const initialState: IServiceState = {
  serviceList: [],
  currentService: null,
  loading: false,
};

const serviceSlice = createSlice({
  name: "service",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getService.pending, (state) => {
      state.loading = true;
      state.currentService = null;
    });
    builder.addCase(getService.rejected, (state) => {
      state.loading = false;
      state.currentService = null;
    });
    builder.addCase(getService.fulfilled, (state, action) => {
      state.loading = false;
      state.currentService = action.payload;
    });

    builder.addCase(addService.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(addService.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(addService.fulfilled, (state) => {
      state.loading = false;
    });

    builder.addCase(getServiceList.pending, (state) => {
      state.loading = true;
      state.serviceList = state.serviceList || [];
    });
    builder.addCase(getServiceList.rejected, (state) => {
      state.loading = false;
      state.serviceList = state.serviceList || [];
    });
    builder.addCase(getServiceList.fulfilled, (state, action) => {
      state.loading = false;
      state.serviceList = action.payload.results;
      state.numPage = action.payload.num_pages;
    });

    builder.addCase(updateService.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateService.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(updateService.fulfilled, (state) => {
      state.loading = false;
    });

    builder.addCase(deleteService.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(deleteService.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(deleteService.fulfilled, (state) => {
      state.loading = false;
    });
  },
});

export const selectCurrentService = (state: RootState) =>
  state.service.currentService;
export const selectServiceLoading = (state: RootState) => state.service.loading;
export const selectServiceNumPage = (state: RootState) => state.service.numPage;
export const selectServiceList = (state: RootState) =>
  state.service.serviceList;

export default serviceSlice;
