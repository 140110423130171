import React from "react";
import { Grid } from "@mui/material";
import Block from "Components/UI/Block/Block";
import styles from "./InventoryHistory.module.scss";
import InfoField from "Components/UI/InfoField/InfoField";
import { getInventoryInfo } from "Shared/Utils/part";
import { useSelector } from "react-redux";
import { selectCurrentPart } from "Redux/Product/slice";
import DateRange from "Components/UI/DateRange/DateRange";
import Table from "Components/UI/Table/Table";
import { inventoryTableHead } from "Shared/Constants/part";

const InventoryHistory: React.FC = () => {
  const part = useSelector(selectCurrentPart);

  const { historyInfo } = getInventoryInfo(part);

  return (
    <>
      <Block title="Info">
        <Grid container>
          {historyInfo.map(({ label, value, xs }) => (
            <Grid item xs={xs} key={label} mt={2}>
              <InfoField key={label} label={label} value={value} xs={xs} />
            </Grid>
          ))}
        </Grid>
      </Block>
      <div className={styles.info__date}>
        <DateRange />
      </div>
      <Table columns={inventoryTableHead} data={[]} />
    </>
  );
};
export default InventoryHistory;
