import { yupResolver } from "@hookform/resolvers/yup";
import Form from "Components/UI/Form/Form";
import React, { useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { ObjectSchema } from "yup";
import Button from "Components/UI/Button/Button";
import styles from "./ContactForm.module.scss";
import { IContact, IContactAdd } from "Shared/Types/contact";
import Block from "Components/UI/Block/Block";
import { Grid } from "@mui/material";
import { CustomerSearch } from "Components/UI/AutoCompleteSearchInputs/CustomerSearch";
import InputField from "Components/UI/Inputs/InputField";

type ContactFormProps = {
  onSubmit: (values: IContactAdd) => void;
  schema: ObjectSchema<IContactAdd>;
  contact?: IContact | null;
};

const ContactForm: React.FC<ContactFormProps> = ({
  onSubmit,
  schema,
  contact,
}) => {
  const methods = useForm({
    resolver: yupResolver(schema),
    defaultValues: contact || undefined,
  });
  useEffect(() => {
    if (contact) {
      methods.reset(contact);
      methods.setValue("customer", contact.customer);
    }
  }, [contact]);

  return (
    <Block className={styles.form}>
      <FormProvider {...methods}>
        <Form onSubmit={methods.handleSubmit(onSubmit)}>
          <Grid container spacing={1} rowSpacing={-1}>
            <Grid item xs={12}>
              <CustomerSearch label="Customer" name="customer" required />
            </Grid>
            <Grid item xs={12}>
              <InputField
                name="first_name"
                label="First name"
                required
                withController
                control={methods.control}
              />
            </Grid>

            <Grid item xs={12}>
              <InputField
                name="last_name"
                label="Last name"
                required
                withController
                control={methods.control}
              />
            </Grid>

            <Grid item xs={12}>
              <InputField
                name="email"
                label="Email"
                required
                withController
                control={methods.control}
              />
            </Grid>

            <Grid item xs={12}>
              <InputField
                name="phone"
                label="Phone number"
                required
                withController
                control={methods.control}
              />
            </Grid>
          </Grid>
          <div className={styles.form__action}>
            {contact ? (
              <Button variant="outlined" type="submit">
                Save
              </Button>
            ) : (
              <Button type="submit">Create</Button>
            )}
          </div>
        </Form>
      </FormProvider>
    </Block>
  );
};
export default ContactForm;
