import { DialogActions } from "@mui/material";
import { notifySuccess } from "Components/Notify/notify";
import Button from "Components/UI/Button/Button";
import InputField from "Components/UI/Inputs/InputField";
import { rejectCompanyRequest } from "Redux/CompanyRequests/reducers";
import { useAppDispatch } from "Redux/store";
import React, { ChangeEvent, useState } from "react";
import styles from "./RequestReject.module.scss";
import Modal from "Components/UI/Modal/Modal";
import { useNavigate } from "react-router";

type RequestRejectProps = {
  id: string;
  open: boolean;
  setOpen: (value: boolean) => void;
  getList?: () => void;
  isFromDetail?: boolean;
};
const MAX_LENGTH = 2000;

const RequestReject: React.FC<RequestRejectProps> = ({
  id,
  open,
  setOpen,
  getList,
  isFromDetail,
}) => {
  const [text, setText] = useState("");
  const navigate = useNavigate();

  const dispatch = useAppDispatch();
  const handleClose = () => {
    setOpen(false);
  };
  const handleSend = async () => {
    const onSuccess = () => {
      notifySuccess("Request rejected");
      handleClose();
      if (isFromDetail) {
        navigate("/requests/review");
      } else {
        getList?.();
      }
    };
    await dispatch(
      rejectCompanyRequest({
        id: id,
        data: {
          reject_text: text,
        },
        onSuccess,
      })
    );
  };

  const handleChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    if (event.target.value.length <= MAX_LENGTH) {
      setText(event.target.value);
    }
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      title={"Reason for reject"}
      fullWidth
    >
      <p className={styles.modal__description}>Write your reason</p>

      <div className={styles.modal__content}>
        <InputField
          name="comment"
          autoFocus
          fullWidth
          multiline
          rows={4}
          onChange={handleChange}
        />
        <p>
          {text.length}/{MAX_LENGTH}
        </p>
      </div>
      <DialogActions className={styles.modal__footer}>
        <Button variant="outlined" color="primary" onClick={handleClose}>
          Cancel
        </Button>
        <Button variant="contained" color="primary" onClick={handleSend}>
          Send
        </Button>
      </DialogActions>
    </Modal>
  );
};
export default RequestReject;
