import {
  AllOrdersIcon as CompanyUsersListIcon,
  RejectIcon,
} from "Assets/index";
import FilterCard from "Components/Cards/FilterCard/FilterCard";
import React, { useEffect } from "react";
import styles from "./CompanyFilter.module.scss";
import { Grid } from "@mui/material";
import { useSelector } from "react-redux";
import {
  selectCompanyBlockedCount,
  selectCompanyCount,
} from "Redux/Company/slice";
import { getCompanyBlockedList, getCompanyList } from "Redux/Company/reducers";
import { useAppDispatch } from "Redux/store";

const CompanyFilter: React.FC = () => {
  const companyCount = useSelector(selectCompanyCount);
  const companyBlockedCount = useSelector(selectCompanyBlockedCount);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getCompanyList({}));
    dispatch(getCompanyBlockedList({}));
  }, []);

  return (
    <Grid className={styles.filter}>
      <FilterCard
        title="Users List"
        icon={CompanyUsersListIcon}
        color="blue"
        link="user-list"
        description={`As of today ${companyCount} orders`}
      />
      <FilterCard
        title="Blocked"
        icon={RejectIcon}
        color="red"
        link="blocked"
        description={`As of today ${companyBlockedCount} orders`}
      />
    </Grid>
  );
};
export default CompanyFilter;
