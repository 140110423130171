import React from "react";
import { Grid } from "@mui/material";
import Modal from "Components/UI/Modal/Modal";
import InputField from "Components/UI/Inputs/InputField";
import Button from "Components/UI/Button/Button";
import styles from "./PartLocationModal.module.scss";

type PartLocationProps = {
  closeModal: () => void;
  handleToUpdate: () => void;
  open: boolean;
};

const PartLocationModal: React.FC<PartLocationProps> = ({
  closeModal,
  handleToUpdate,
  open,
}) => {
  const onCloseModal = () => {
    closeModal();
  };

  return (
    <Modal
      className={styles.part}
      open={open}
      onClose={onCloseModal}
      isClosed={open}
      title="Part Location"
    >
      <Grid container spacing={2}>
        <Grid item xs={12} className={styles.part__name}>
          <p>00001111 - SS home</p>
        </Grid>
        <Grid container item xs={12} gap={2}>
          <Grid item xs={5.7} className={styles.part__quantity}>
            <InputField name={"qty_needed"} />
            <p>QTY NEEDED</p>
          </Grid>
          <Grid item xs={5.7} className={styles.part__quantity}>
            <InputField name={"qty_stock"} />
            <p>QTY IN STOCK</p>
          </Grid>
        </Grid>
        <Grid container item xs={12}>
          <Grid container item xs={12} className={styles.part__location__head}>
            <Grid item xs={6.5}>
              Location
            </Grid>
            <Grid item xs={2.5}>
              In Stock
            </Grid>
            <Grid item xs={2.5}>
              Qty to use
            </Grid>
          </Grid>
          <Grid
            container
            item
            xs={12}
            className={styles.part__location__content}
          >
            <Grid item xs={6.5}>
              <InputField name={"location"} />
            </Grid>
            <Grid item xs={2.5}>
              <InputField name={"in_stock"} />
            </Grid>
            <Grid item xs={2.5}>
              <InputField name={"qty_to_use"} />
            </Grid>
          </Grid>
        </Grid>
        <Grid container item xs={12} className={styles.part__footer}>
          <Grid item xs={6} className={styles.part__footer__total}>
            <p>Needed qty remaining: +{0}</p>
          </Grid>
          <Grid item xs={4} className={styles.part__footer__actions}>
            <Button onClick={onCloseModal} color="secondary">
              Cancel
            </Button>
            <Button onClick={handleToUpdate}>Save</Button>
          </Grid>
        </Grid>
      </Grid>
    </Modal>
  );
};

export default PartLocationModal;
