import { notifyError, notifySuccess } from "Components/Notify/notify";
import PurchaseOrderCreateForm from "Components/PurchaseOrder/Form/PurchaseOrderCreateForm";
import { addPurchaseOrder } from "Redux/PurchaseOrder/reducer";
import { useAppDispatch } from "Redux/store";
import { readObj } from "Service/storage";
import { SHOP_NOT_ASSIGNED } from "Shared/Constants/app";
import { PURCAHSE_ORDER_STATUS } from "Shared/Constants/purchase-order";
import { IPurchaseOrderCreateFrom } from "Shared/Types/purchase-order";
import {
  convertFormDataToPurchaseOrder,
  getPurchaseOrderRoute,
} from "Shared/Utils/purchase-order";
import React from "react";
import { useNavigate } from "react-router";

const PurchaseOrderAdd: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const shop = readObj("current_shop");
  const onSubmit = (values: IPurchaseOrderCreateFrom, isDraft: boolean) => {
    if (!shop) return notifyError(SHOP_NOT_ASSIGNED);
    const arr = convertFormDataToPurchaseOrder(values, shop.id);
    arr.forEach((item, i) => {
      dispatch(
        addPurchaseOrder({
          data: {
            ...item,
            status: isDraft
              ? PURCAHSE_ORDER_STATUS.DRAFT
              : PURCAHSE_ORDER_STATUS.SENT,
          },
          onSuccess: () => {
            if (i === arr.length - 1) {
              notifySuccess("Purchase orders are created");
              navigate(getPurchaseOrderRoute("LIST"));
            }
          },
        })
      );
    });
  };
  return (
    <div>
      <PurchaseOrderCreateForm onSubmit={onSubmit} />
    </div>
  );
};
export default PurchaseOrderAdd;
