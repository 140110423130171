import { OptionsProps } from "Components/UI/SelectField/SelectField";
import { IPurchaseOrderCreatePartsKeys } from "Shared/Types/purchase-order";

type TSelectOption = OptionsProps;

export type TField = {
  type: "text" | "number" | "vendor-search" | "part-search";
  label?: string;
  name: IPurchaseOrderCreatePartsKeys;
  xs?: number;
  required?: boolean;
  options?: TSelectOption[];
  disabled?: boolean;
};

const usePurchasePartFields = () => {
  const XS = 1.5;

  const fields: TField[] = [
    {
      name: "part_number",
      label: "Part #",
      xs: 4,
      type: "part-search",
    },
    {
      name: "description",
      label: "Description",
      xs: 4,
      required: true,
      type: "text",
    },

    {
      name: "vendor",
      label: "Preferred vendor",
      xs: 4,
      type: "vendor-search",
      required: true,
    },
    {
      name: "in_stock",
      label: "In stock",
      xs: XS,
      type: "text",
      disabled: true,
    },
    {
      name: "in_transit",
      label: "In transit",
      xs: XS,
      type: "text",
      disabled: true,
    },
    {
      name: "min_quantity",
      label: "Min qty",
      xs: XS,
      type: "number",
      disabled: true,
    },
    {
      name: "max_quantity",
      label: "Max qty",
      xs: XS,
      type: "text",
      disabled: true,
    },
    {
      name: "average_cost",
      label: "Average cost",
      xs: XS,
      type: "number",
      disabled: true,
    },
    {
      name: "last_cost",
      label: "Last cost",
      xs: XS,
      type: "number",
      disabled: true,
    },
    {
      name: "price",
      label: "Actual Price",
      xs: XS,
      type: "number",
    },
    {
      name: "quantity",
      label: "Quantity",
      xs: XS,
      type: "number",
      required: true,
    },
  ];

  return { fields };
};

export default usePurchasePartFields;
