import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "Service/api/api";
import {
  IDispatchCallback,
  IDispatchCallbackWithId,
  IDispatchCallbackWithoutData,
} from "Shared/Types/common";
import {
  ICategorySearchParams,
  ICategoryAdd,
  ICategoryUpdate,
} from "Shared/Types/category";
import { errorHandler } from "Shared/Utils/app";
import { readObj } from "Service/storage";

const getCategoryList = createAsyncThunk(
  "category/getCategoryList",
  async (params: ICategorySearchParams | null) => {
    try {
      const shop = readObj("current_shop");
      const response = await api.category.getCategorysList({
        ...params,
        shop: shop.id,
      });
      return response.data;
    } catch (error) {
      if (error instanceof Error) {
        errorHandler(error);
      }
      throw error;
    }
  }
);

const getCategory = createAsyncThunk(
  "category/getCategory",
  async (id: string) => {
    try {
      const response = await api.category.getCategory(id);
      return response.data;
    } catch (error) {
      if (error instanceof Error) {
        errorHandler(error);
      }
      throw error;
    }
  }
);

const addCategory = createAsyncThunk(
  "category/addCategory",
  async ({ data, onSuccess }: IDispatchCallback<ICategoryAdd>) => {
    try {
      const response = await api.category.addCategory(data);
      onSuccess?.();
      return response.data;
    } catch (error) {
      if (error instanceof Error) {
        errorHandler(error, true);
      }
      throw error;
    }
  }
);

const updateCategory = createAsyncThunk(
  "category/updateCategory",
  async ({ id, data, onSuccess }: IDispatchCallbackWithId<ICategoryUpdate>) => {
    try {
      const response = await api.category.updateCategory(id, data);
      onSuccess?.();
      return response.data;
    } catch (error) {
      if (error instanceof Error) {
        errorHandler(error, true);
      }
      throw error;
    }
  }
);

const deleteCategory = createAsyncThunk(
  "category/deleteCategory",
  async ({ id, onSuccess }: IDispatchCallbackWithoutData) => {
    try {
      const response = await api.category.deleteCategory(id);
      onSuccess?.();
      return response.data;
    } catch (error) {
      if (error instanceof Error) {
        errorHandler(error, true);
      }
      throw error;
    }
  }
);

export {
  getCategoryList,
  getCategory,
  addCategory,
  updateCategory,
  deleteCategory,
};
