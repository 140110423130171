import React, { useEffect, useState } from "react";
import styles from "./ActionItems.module.scss";
import {
  actionItemDeleteMessage,
  orderDetailHistory,
} from "Shared/Constants/order";
import OrderStatusHistory from "Components/Order/OrderDetail/OrderStatusHisotry/OrderStatusHistory";
import ActionItemHeader from "Components/Order/OrderDetail/ActionItemHeader/ActionItemHeader";
import ActionItemDetail from "Components/Order/ActionItemDetial/ActionItemDetail";
import { useAppDispatch } from "Redux/store";
import { useSelector } from "react-redux";
import { selectCurrentOrder } from "Redux/Order/slice";
import { useLocation, useParams } from "react-router";
import { getOrder } from "Redux/Order/reducer";
import { IActionItem } from "Shared/Types/order";
import OrderBasicInformation from "Components/Order/OrderBasicInformation/OrderBasicInformation";
import {
  deleteActionItem,
  getActionItemStatusList,
} from "Redux/ActionItem/reducer";
import { notifySuccess } from "Components/Notify/notify";
import DeleteModal from "Components/UI/DialogWindow/DeleteModal";
import AddActionItem from "../AddActionItem/AddActionItem";
import { Grid } from "@mui/material";
type ActionItemsProps = {
  setActiveTab: (tab: number) => void;
  pushInvoice: () => void;
};
const ActionItems: React.FC<ActionItemsProps> = ({
  setActiveTab,
  pushInvoice,
}) => {
  const dispatch = useAppDispatch();
  const currentOrder = useSelector(selectCurrentOrder);
  const { id } = useParams();
  const { state } = useLocation();
  const [actionItemDetails, setActionItemDetails] = useState<IActionItem[]>([]);
  const [showDetail, setShowDetail] = useState(false);

  const [deleteId, setDeleteId] = useState<string | null>();
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const handleShowActionItems = (actionItem: IActionItem) => {
    const current = actionItemDetails.findIndex(
      (item) => item.id === actionItem.id
    );
    if (current > -1) {
      setActionItemDetails([
        ...actionItemDetails.slice(0, current),
        ...actionItemDetails.slice(current + 1),
      ]);
    } else {
      setActionItemDetails([...actionItemDetails, actionItem]);
    }
  };

  const closeActionItem = (id: string) => {
    const idx = actionItemDetails.findIndex(
      (actionItem) => actionItem.id === id
    );
    if (idx > -1) {
      setActionItemDetails([
        ...actionItemDetails.slice(0, idx),
        ...actionItemDetails.slice(idx + 1),
      ]);
    }
  };

  const isActive = (actionItemId: string) => {
    return (
      actionItemDetails.findIndex(
        (actionItem) => actionItem.id === actionItemId
      ) !== -1
    );
  };

  const handleDialogClose = () => {
    setOpenDeleteModal(false);
    setDeleteId(null);
  };

  const handleDialogOpen = (id: string) => {
    setOpenDeleteModal(true);
    setDeleteId(id);
  };

  const handleDeleteOrder = () => {
    const onSuccess = () => {
      notifySuccess("Action item is deleted");
      setOpenDeleteModal(false);
      closeActionItem(deleteId as string);
      if (currentOrder?.id) {
        dispatch(getOrder(currentOrder.id));
      }
    };
    if (deleteId) {
      dispatch(deleteActionItem({ id: deleteId, onSuccess }));
    }
  };

  const getIndex = (id: string) => {
    const idx = currentOrder?.actionitem_set.findIndex(
      (action) => action.id === id
    );
    if (!idx) return 1;

    return idx + 1;
  };

  useEffect(() => {
    if (state?.action_item && currentOrder) {
      const actionItem = currentOrder.actionitem_set.find(
        (item) => item.id === state.action_item
      );
      if (actionItem) {
        setActionItemDetails([actionItem]);
      }
    }
  }, [state, currentOrder]);

  useEffect(() => {
    if (id) {
      dispatch(getOrder(id));
    }
  }, [id]);

  useEffect(() => {
    dispatch(getActionItemStatusList());
  }, []);
  return (
    <div className={styles.action}>
      <div className={styles.action__history}>
        {orderDetailHistory?.map((status) => (
          <OrderStatusHistory
            key={status.title}
            title={status.title}
            status={status.status}
            orderStatus={currentOrder?.status_order?.queue || 1}
          />
        ))}
      </div>
      <OrderBasicInformation
        order={currentOrder}
        showDetail={showDetail}
        setShowDetail={setShowDetail}
      />
      <Grid container className={styles.action__actionItems}>
        {currentOrder?.actionitem_set?.map((actionItem, index) => (
          <ActionItemHeader
            isActive={isActive(actionItem.id)}
            key={actionItem.id}
            index={index}
            onClick={() => handleShowActionItems(actionItem)}
            actionItem={actionItem}
          />
        ))}
      </Grid>
      {actionItemDetails.map((actionItem) => (
        <ActionItemDetail
          onClose={() => closeActionItem(actionItem.id)}
          actionItem={actionItem}
          key={actionItem.id}
          openDelete={handleDialogOpen}
          index={getIndex(actionItem.id)}
          setActiveTab={setActiveTab}
          pushInvoice={pushInvoice}
        />
      ))}

      <DeleteModal
        acceptTitle="Delete Action Item"
        open={openDeleteModal}
        handleClose={handleDialogClose}
        title="Delete Action Item"
        text={actionItemDeleteMessage}
        accept={handleDeleteOrder}
      />
      <AddActionItem />
    </div>
  );
};
export default ActionItems;
