import { createSlice } from "@reduxjs/toolkit";
import {
  IEstimatesOrder,
  IEstimatesPart,
  IEstimatesService,
} from "Shared/Types/estimate";
import {
  createEstimateOrder,
  createEstimatePart,
  createEstimateService,
  deleteEstimatePart,
  deleteEstimateService,
  getClosedEstimatesOrderList,
  getEstimateOrder,
  getEstimatePart,
  getEstimatePartList,
  getEstimateService,
  getEstimateServiceList,
  getEstimatesOrderList,
  getOpenEstimatesOrderList,
  updateEstimateOrder,
} from "Redux/Estimate/reducer";
import { RootState } from "Redux/store";

interface EstimateState {
  loading: boolean;
  estimatesOrderList?: IEstimatesOrder[];
  openEstimatesOrderList?: IEstimatesOrder[];
  closedEstimatesOrderList?: IEstimatesOrder[];
  estimatesOrderCount?: number;
  openEstimatesOrderCount?: number;
  closedEstimatesOrderCount?: number;
  currentEstimatesOrder?: IEstimatesOrder | null;
  currentEstimatesService?: IEstimatesService | null;
  estimatesServiceList?: IEstimatesService[];
  estimatesPartList?: IEstimatesPart[];
  currentEstimateServicePart?: IEstimatesPart | null;
}

const initialState: EstimateState = {
  loading: false,
  estimatesOrderList: [],
};

const estimateSlice = createSlice({
  name: "estimate",
  initialState,
  reducers: {
    removeCurrentEstimate(state) {
      state.currentEstimatesOrder = null;
      state.currentEstimatesService = null;
      state.estimatesPartList = [];
    },
  },
  extraReducers(builder) {
    builder.addCase(getEstimatesOrderList.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getEstimatesOrderList.rejected, (state) => {
      state.loading = false;
      state.estimatesOrderList = [];
      state.estimatesOrderCount = 0;
    });
    builder.addCase(getEstimatesOrderList.fulfilled, (state, action) => {
      state.loading = false;
      state.estimatesOrderList = action.payload.results;
      state.estimatesOrderCount = action.payload.count;
    });
    builder.addCase(getOpenEstimatesOrderList.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getOpenEstimatesOrderList.rejected, (state) => {
      state.loading = false;
      state.openEstimatesOrderList = [];
      state.openEstimatesOrderCount = 0;
    });
    builder.addCase(getOpenEstimatesOrderList.fulfilled, (state, action) => {
      state.loading = false;
      state.openEstimatesOrderList = action.payload.results;
      state.openEstimatesOrderCount = action.payload.count;
    });
    builder.addCase(getClosedEstimatesOrderList.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getClosedEstimatesOrderList.rejected, (state) => {
      state.loading = false;
      state.closedEstimatesOrderList = [];
      state.closedEstimatesOrderCount = 0;
    });
    builder.addCase(getClosedEstimatesOrderList.fulfilled, (state, action) => {
      state.loading = false;
      state.closedEstimatesOrderList = action.payload.results;
      state.closedEstimatesOrderCount = action.payload.count;
    });
    builder.addCase(createEstimateOrder.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(createEstimateOrder.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(createEstimateOrder.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(getEstimateOrder.pending, (state) => {
      state.loading = true;
      state.currentEstimatesOrder = null;
    });
    builder.addCase(getEstimateOrder.rejected, (state) => {
      state.loading = false;
      state.currentEstimatesOrder = null;
    });
    builder.addCase(getEstimateOrder.fulfilled, (state, action) => {
      state.loading = false;
      state.currentEstimatesOrder = action.payload;
    });
    builder.addCase(updateEstimateOrder.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateEstimateOrder.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(updateEstimateOrder.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(createEstimateService.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(createEstimateService.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(createEstimateService.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(getEstimateService.pending, (state) => {
      state.loading = true;
      state.currentEstimatesService = null;
    });
    builder.addCase(getEstimateService.rejected, (state) => {
      state.loading = false;
      state.currentEstimatesService = null;
    });
    builder.addCase(getEstimateService.fulfilled, (state, action) => {
      state.loading = false;
      state.currentEstimatesService = action.payload.estimateService;

      const idx: number = (state.estimatesServiceList || []).findIndex(
        (service) => service.id === action.payload.estimateService.id,
      );

      if (idx === -1) {
        state.estimatesServiceList = [
          ...(state.estimatesServiceList || []),
          action.payload.estimateService,
        ];
      } else {
        state.estimatesServiceList = [
          ...(state.estimatesServiceList?.slice(0, idx) || []),
          action.payload.estimateService,
          ...(state.estimatesServiceList?.slice(idx + 1) || []),
        ];
      }

      const partIdx: number = (state.estimatesPartList || []).findIndex(
        (partEl) =>
          action.payload.estimatePartList.find(
            (element: IEstimatesPart) => element.id === partEl.id,
          ),
      );

      if (partIdx === -1) {
        state.estimatesPartList = [
          ...(state.estimatesPartList || []),
          ...action.payload.estimatePartList,
        ];
      } else {
        state.estimatesPartList = [
          ...(state.estimatesPartList?.slice(0, partIdx) || []),
          ...action.payload.estimatePartList,
          ...(state.estimatesPartList?.slice(partIdx + 1) || []),
        ];
      }
    });
    builder.addCase(getEstimateServiceList.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getEstimateServiceList.rejected, (state) => {
      state.loading = false;
      state.estimatesServiceList = [];
    });
    builder.addCase(getEstimateServiceList.fulfilled, (state, action) => {
      state.loading = false;
      state.estimatesServiceList = action.payload;
    });
    builder.addCase(deleteEstimateService.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(deleteEstimateService.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(deleteEstimateService.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(createEstimatePart.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(createEstimatePart.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(createEstimatePart.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(deleteEstimatePart.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(deleteEstimatePart.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(deleteEstimatePart.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(getEstimatePartList.pending, (state) => {
      state.loading = true;
      state.estimatesPartList = [];
    });
    builder.addCase(getEstimatePartList.rejected, (state) => {
      state.loading = false;
      state.estimatesPartList = [];
    });
    builder.addCase(getEstimatePartList.fulfilled, (state, action) => {
      state.estimatesPartList = action.payload;
      state.loading = false;
    });
    builder.addCase(getEstimatePart.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getEstimatePart.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(getEstimatePart.fulfilled, (state, action) => {
      state.loading = false;
      state.currentEstimateServicePart = action.payload;

      const idx: number = (state.estimatesPartList || []).findIndex(
        (service) => service.id === action.payload.id,
      );

      if (idx === -1) {
        state.estimatesPartList = [
          ...(state.estimatesPartList || []),
          action.payload,
        ];
      } else {
        state.estimatesPartList = [
          ...(state.estimatesPartList?.slice(0, idx) || []),
          action.payload,
          ...(state.estimatesPartList?.slice(idx + 1) || []),
        ];
      }
    });
  },
});

export const { removeCurrentEstimate } = estimateSlice.actions;

export const selectEstimatesOrderList = (state: RootState) =>
  state.estimate.estimatesOrderList;
export const selectEstimatesOrderCount = (state: RootState) =>
  state.estimate.estimatesOrderCount;
export const selectOpenEstimatesOrderList = (state: RootState) =>
  state.estimate.openEstimatesOrderList;
export const selectOpenEstimatesOrderCount = (state: RootState) =>
  state.estimate.openEstimatesOrderCount;
export const selectClosedEstimatesOrderList = (state: RootState) =>
  state.estimate.closedEstimatesOrderList;
export const selectClosedEstimatesOrderCount = (state: RootState) =>
  state.estimate.closedEstimatesOrderCount;
export const selectCurrentEstimateOrder = (state: RootState) =>
  state.estimate.currentEstimatesOrder;
export const selectEstimatesService = (state: RootState) =>
  state.estimate.currentEstimatesService;
export const selectEstimatesPartList = (state: RootState) =>
  state.estimate.estimatesPartList;
export const selectEstimatesServiceList = (state: RootState) =>
  state.estimate.estimatesServiceList;

export default estimateSlice;
