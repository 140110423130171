import React from "react";
import SettingsLayout from "Components/Settings/Layout/Layout";
import Layout from "Components/Layout/Layout";
import SupplyRateAdd from "Containers/Settings/Rate/SupplyRate/SupplyRateAdd/SupplyRateAdd";
import { BreadcrumbItem } from "Components/Breadcrumb/Breadcrumb";
import { useBreadcrumb } from "Shared/Hooks/use-breadcrumb";

export const SupplyRateAddPage = () => {
  const breads: BreadcrumbItem[] = [
    { title: "Settings", link: "/settings/rate-settings/supply-rate" },
    { title: "Supply Rate", link: "/settings/rate-settings/supply-rate" },
    { title: "Create New Supply Rate", link: "" },
  ];
  useBreadcrumb({ links: breads });

  return (
    <Layout>
      <SettingsLayout>
        <SupplyRateAdd />
      </SettingsLayout>
    </Layout>
  );
};
