import React from "react";
import styles from "./UnitInfo.module.scss";
import { getUnitInfo } from "Shared/Utils/invoice";
import { IOrderInvoiceData } from "Shared/Types/invoice";

type UnitInfoProps = {
  orderData: IOrderInvoiceData;
};

const UnitInfo: React.FC<UnitInfoProps> = ({ orderData }) => {
  return (
    <div className={styles.unit}>
      {getUnitInfo(orderData)?.map(({ title, value, title2, value2 }) => (
        <div key={title} className={styles.unit__item}>
          <span>{title}</span> {value}
          {(title2 || value2) && (
            <>
              <span>{title2}</span> {value2}
            </>
          )}
        </div>
      ))}
    </div>
  );
};
export default UnitInfo;
