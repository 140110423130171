import React, { useState } from "react";
import styles from "./ServiceOrderAdd.module.scss";
import InputField from "Components/UI/Inputs/InputField";
import Checkbox from "Components/UI/Inputs/Checkbox";
import { DollarWhiteIcon, PaperCLipIcon } from "Assets/index";
import Icon from "Components/UI/Icon/Icon";
import Attachments from "Components/Order/ServiceOrderForms/Attachments/Attachments";
import { Control, useFormContext } from "react-hook-form";
import { IOrderAdd } from "Shared/Types/orderAdd";
import AccessStatusInfo from "Components/Order/ServiceOrderForms/AccessStatusInfo";
import Form from "Components/UI/Form/Form";
import { useNavigate } from "react-router";
import { Grid } from "@mui/material";

type AuthorizationInfoProps = {
  control: Control<IOrderAdd>;
  authBlankChecked: boolean;
  setAuthBlankChecked: (e: boolean) => void;
  setFormStep: (step: number) => void;
  saveOrderValues: () => void;
};

const AuthorizationInfo: React.FC<AuthorizationInfoProps> = ({
  control,
  setAuthBlankChecked,
  authBlankChecked,
  saveOrderValues,
}) => {
  const [openModal, setOpenModal] = useState(false);
  const { getValues, setValue } = useFormContext();

  const toggleAuthBlankChecked = () => {
    setAuthBlankChecked(!authBlankChecked);
    setValue("invoice_hours", 0);
  };

  const handleModal = () => setOpenModal(!openModal);

  const attachments: string =
    getValues("order_gallery")?.length + getValues("order_file")?.length;

  return (
    <Form className={styles.form_section}>
      <AccessStatusInfo control={control} saveOrderValues={saveOrderValues} />
      <p className={styles.title}>Authorization</p>
      <InputField
        withController
        control={control}
        name="purchase_order_number"
        label="What is the customer purchase order number?"
      />
      <InputField
        withController
        control={control}
        multiline={true}
        rows={4}
        name="notes"
        label="Notes"
      />
      <Grid container spacing={2} marginY={1.5}>
        <Grid item xs={6} md={6}>
          <Checkbox
            withController
            control={control}
            label="Customer gives blanked authorization"
            name="auth_blank"
            labelPosition="right"
            onChange={toggleAuthBlankChecked}
          />
          <Checkbox
            withController
            control={control}
            label="Do not show note to customer"
            name="is_shown_note"
            labelPosition="right"
          />
        </Grid>
        {!authBlankChecked && (
          <Grid item xs={6} md={6}>
            <InputField
              withController
              control={control}
              icon={DollarWhiteIcon}
              type="number"
              defaultValue={0}
              IconBg="#007DF2"
              name="invoice_hours"
              label="How much has the customer pre-authorized?"
            />
          </Grid>
        )}
      </Grid>

      <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
        <div
          onClick={handleModal}
          style={{ marginTop: "7px", padding: "13px 17px" }}
          className={styles.icon}
        >
          <Icon color="transparent" component={PaperCLipIcon} />
        </div>
        {attachments ? (
          <p style={{ fontSize: "13px" }} className={styles.text}>
            {attachments} attachment
          </p>
        ) : null}
      </div>
      <Attachments handleModal={handleModal} openModal={openModal} />
    </Form>
  );
};

export default AuthorizationInfo;
