import Layout from "Components/Layout/Layout";
import SettingsLayout from "Components/Settings/Layout/Layout";
import ManufactureAdd from "Containers/Settings/Part/Manufacturer/ManufactureAdd";
import React from "react";
import { BreadcrumbItem } from "Components/Breadcrumb/Breadcrumb";
import { useBreadcrumb } from "Shared/Hooks/use-breadcrumb";

export const ManufactureAddPages: React.FC = () => {
  const breads: BreadcrumbItem[] = [
    { title: "Settings", link: "/settings/part-settings/manufactures" },
    { title: "Manufacturers", link: "/settings/part-settings/manufactures" },
    { title: "Create New Manufacturer", link: "" },
  ];
  useBreadcrumb({ links: breads });

  return (
    <Layout>
      <SettingsLayout>
        <ManufactureAdd />
      </SettingsLayout>
    </Layout>
  );
};
