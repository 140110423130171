import React from "react";
import { useNavigate } from "react-router";
import { Grid } from "@mui/material";
import styles from "./OrderCountByStatusCard.module.scss";
import { toCamelCase } from "Shared/Utils/app";
import cn from "classnames";

export type TimeStatusesProps = {
  name: string;
  count: number;
  color: string;
};
type fromHome = {
  state: boolean;
};

type OrderCountByStatusCardProps = {
  title: string;
  count?: number;
  timeStatuses: TimeStatusesProps[];
  link: string;
  fromHome?: fromHome;
  isEstimates?: boolean;
  isPart?: boolean;
  statusDirection?: "column" | "row";
};

const OrderCountByStatusCard: React.FC<OrderCountByStatusCardProps> = ({
  title,
  count,
  link,
  timeStatuses,
  fromHome,
  isEstimates,
  statusDirection = "row",
  isPart,
}) => {
  const navigate = useNavigate();

  const goToList = () => {
    if (fromHome?.state) {
      navigate("/orders/" + link, fromHome);
      return;
    }
    if (isEstimates) {
      navigate("/orders/estimates/" + link);
      return;
    }
    if (isPart) {
      navigate("/parts/" + link);
      return;
    }
    navigate("/orders/" + link);
  };

  const getSizeOfCard = (index: number) => {
    if (isEstimates || statusDirection === "column") {
      return 11.7;
    }

    if (index === timeStatuses.length - 1 && timeStatuses.length % 2 !== 0) {
      return 11.7;
    } else {
      return 5.6;
    }
  };

  return (
    <div
      className={cn(styles.card, isEstimates && styles.card__estimates)}
      onClick={goToList}
    >
      <div className={styles.card__all}>
        <h5>{title}</h5>
        {count && <span>{count}</span>}
      </div>
      <Grid
        container
        gap="10px"
        className={cn(
          styles.card__statuses,
          styles[`card__${statusDirection}`]
        )}
      >
        {timeStatuses.map(({ name, count, color }, index) => (
          <Grid
            item
            xs={11.7}
            lg={getSizeOfCard(index)}
            className={cn(
              styles[`card_${toCamelCase(color)}`],
              styles.card__status
            )}
            key={name}
            gap={1}
          >
            <h6>{name}</h6>
            <span>{count}</span>
          </Grid>
        ))}
      </Grid>
    </div>
  );
};
export default OrderCountByStatusCard;
