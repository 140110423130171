import React, { useEffect } from "react";
import styles from "./Dashboard.module.scss";
import OrderFilter from "Components/Order/OrderFilter/OrderFilter";
import { useSelector } from "react-redux";
import { selectOrderCountByStatus } from "Redux/Order/slice";
import { selectAccount } from "Redux/Auth/slice";
import { useAppDispatch } from "Redux/store";
import Calendar from "Components/Calendar/Calendar";
import OrderCountByStatusCard from "Components/Order/OrderCountByStatusCard/OrderCountByStatusCard";
import EstimateCharts from "Components/Order/Estimate/EstimateCharts/EstimateCharts";
import { format } from "Shared/Utils/app";
import {
  getClosedEstimatesOrderList,
  getEstimatesOrderList,
  getOpenEstimatesOrderList,
} from "Redux/Estimate/reducer";
import {
  selectClosedEstimatesOrderCount,
  selectEstimatesOrderCount,
  selectOpenEstimatesOrderCount,
} from "Redux/Estimate/slice";
import { getEstimates } from "Shared/Utils/estimate";

export function Dashboard() {
  const orderCountByStatus = useSelector(selectOrderCountByStatus);
  const profile = useSelector(selectAccount);
  const dispatch = useAppDispatch();
  const allEstimatesCount = useSelector(selectEstimatesOrderCount) || 0;
  const openEstimatesCount = useSelector(selectOpenEstimatesOrderCount) || 0;
  const closedEstimatesCount =
    useSelector(selectClosedEstimatesOrderCount) || 0;

  useEffect(() => {
    const today = new Date();
    const thirtyDaysAgo = new Date();
    thirtyDaysAgo.setDate(today.getDate() - 30);

    dispatch(getEstimatesOrderList({}));
    dispatch(
      getOpenEstimatesOrderList({
        created_at_before: format(today, "YYYY-MM-DD"),
        created_at_after: format(thirtyDaysAgo, "YYYY-MM-DD"),
      }),
    );
    dispatch(
      getClosedEstimatesOrderList({
        created_at_before: format(today, "YYYY-MM-DD"),
        created_at_after: format(thirtyDaysAgo, "YYYY-MM-DD"),
      }),
    );
  }, [profile]);

  return (
    <div className={styles.dashboard}>
      <OrderFilter orderTotal={orderCountByStatus?.total.count} />

      <div className={styles.dashboard__content}>
        <div className={styles.dashboard__content__statuses}>
          {getEstimates(
            allEstimatesCount,
            openEstimatesCount,
            closedEstimatesCount,
          ).map((estimate) => (
            <OrderCountByStatusCard
              key={estimate.title}
              title={estimate.title}
              link={estimate.link}
              timeStatuses={estimate.timeStatuses}
              isEstimates
            />
          ))}
        </div>
        <EstimateCharts />
        <Calendar isLong />
      </div>
    </div>
  );
}
