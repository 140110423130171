import React from "react";
import { BreadcrumbItem } from "Components/Breadcrumb/Breadcrumb";
import Layout from "Components/Layout/Layout";
import ReturnsList from "Containers/Home/ReturnsList/ReturnsList";
import { useBreadcrumb } from "Shared/Hooks/use-breadcrumb";

export const ReturnsPage = () => {
  const breads: BreadcrumbItem[] = [
    { title: "Home", link: "/" },
    { title: "Returns", link: "" },
  ];

  useBreadcrumb({ links: breads });
  return (
    <Layout>
      <ReturnsList />
    </Layout>
  );
};
