import FilterPanel from "Components/Order/FilterPanel/FilterPanel";
import OrderFilter from "Components/Order/OrderFilter/OrderFilter";
import React, { ReactNode, useEffect, useRef } from "react";
import styles from "./OrderLayout.module.scss";
import { useSelector } from "react-redux";
import {
  selectOnHoldOrderNumPage,
  selectOnHoldOrderTotal,
  selectOrderNumPage,
  selectOrderTotal,
} from "Redux/Order/slice";
import { useAppDispatch } from "Redux/store";
import { getOnHoldOrderList, getOrderList } from "Redux/Order/reducer";
import Pagination from "Components/UI/Pagination/Pagination";
import { useLocation } from "react-router-dom";
import { selectHistoryNumPage } from "Redux/History/slice";
import cn from "classnames";
import {
  employeeFilterFields,
  historyFilterFields,
  orderFilterFields,
} from "Shared/Constants/order";
import HomeFilter from "Components/Home/HomeFilter/HomeFilter";
import { IUser } from "Shared/Types/user";

type OrderLayoutProps = {
  status?: string;
  children: ReactNode;
  isCard: boolean;
  setIsCard: (isCard: boolean) => void;
  fields: string[];
  setFields: (fields: string[]) => void;
  fieldAllList: string[];
  limitFields?: number;
  onHold?: boolean;
  employee?: IUser;
};

const OrderLayout: React.FC<OrderLayoutProps> = ({
  children,
  isCard,
  setIsCard,
  fields,
  setFields,
  fieldAllList,
  limitFields,
  onHold,
  employee,
}) => {
  const total = useSelector(selectOrderTotal);
  const totalOnHoldOrders = useSelector(selectOnHoldOrderTotal);
  const numPage = useSelector(selectOrderNumPage);
  const onHoldNumPage = useSelector(selectOnHoldOrderNumPage);
  const historyNumPage = useSelector(selectHistoryNumPage);
  const { pathname, state } = useLocation();
  const dispatch = useAppDispatch();

  const isHistory = pathname.includes("history");

  const contentRef = useRef<HTMLDivElement>(null);
  const calendarRef = useRef<HTMLDivElement>(null);

  const getNumPage = () => {
    if (isHistory) {
      return historyNumPage || 1;
    }
    if (onHold) {
      return onHoldNumPage || 1;
    }

    return numPage || 1;
  };

  const getFilters = () => {
    if (employee) return employeeFilterFields;
    if (isHistory) return historyFilterFields;
    return orderFilterFields;
  };

  useEffect(() => {
    if (contentRef.current && calendarRef.current) {
      const contentWidth = contentRef.current.offsetWidth;
      calendarRef.current.style.width = `${contentWidth}px`;
    }
  }, [isCard]);

  useEffect(() => {
    if (onHold) {
      dispatch(getOrderList(null));
    } else {
      dispatch(getOnHoldOrderList(null));
    }
  }, []);

  return (
    <div>
      {!employee ? (
        state ? (
          <HomeFilter />
        ) : (
          <OrderFilter orderTotal={total} onHoldTotal={totalOnHoldOrders} />
        )
      ) : null}
      <FilterPanel
        setIsCard={setIsCard}
        isCard={isCard}
        fields={fields}
        setFields={setFields}
        fieldAllList={fieldAllList}
        limitFields={limitFields}
        filters={getFilters()}
      />
      <div className={styles.orders__content} ref={contentRef}>
        <div
          className={cn(
            styles.orders__container,
            !isCard && styles.orders__container_row,
          )}
        >
          {children}
        </div>
        {getNumPage() > 1 && <Pagination count={getNumPage()} />}
      </div>
    </div>
  );
};
export default OrderLayout;
