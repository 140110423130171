import React, { useEffect, useState } from "react";
import Select from "react-select";
import cn from "classnames";
import styles from "./AcyncSelect.module.scss";
import { customDarkStyles, customStyles } from "../SelectField/styles";

export type OptionsProps = {
  label: string | null;
  value: string | number;
};

interface AcyncSelectProps {
  options: OptionsProps[] | undefined;
  containerClassName?: string;
  theme?: "primary" | "secondary";
  labelColor?: string;
  label?: string; // Add a new prop for the label
  onChange?: (value?: string | number) => void;
  placeholder?: string;
  defaultValue?: OptionsProps | null;
  loading?: boolean;
  value?: OptionsProps;
  onInputChange?: (text: string | null) => void;
  onBlur?: () => void;
}

const AcynsSelectField: React.FC<AcyncSelectProps> = ({
  options = [],
  theme = "primary",
  label,
  loading,
  onInputChange,
  defaultValue,
  value,
  ...props
}) => {
  const [selectedValue, setSelectedValue] = useState<OptionsProps | null>(
    value || null
  );
  const [first, setFirst] = useState(true);

  useEffect(() => {
    // Update selectedDefaultValue whenever defaultValue changes
    if (first) {
      setSelectedValue(defaultValue?.value ? defaultValue : null);
    }
  }, [defaultValue]);

  return (
    <div
      className={cn(
        styles.select,
        theme === "primary" ? styles.select_primary : styles.select_secondary
      )}
    >
      {label && (
        <label
          className={styles.select__label}
          style={{ color: props.labelColor }}
        >
          {label}
        </label>
      )}

      <Select
        {...props}
        isLoading={loading}
        value={selectedValue}
        styles={
          theme === "secondary" ? customDarkStyles("start") : customStyles
        }
        placeholder={props.placeholder}
        onChange={(selectedOption) => {
          setSelectedValue(selectedOption);
          setFirst(false);
          props.onChange?.(selectedOption?.value);
        }}
        onInputChange={onInputChange}
        options={options}
        isClearable
        components={{
          IndicatorSeparator: () => null,
        }}
      />
    </div>
  );
};

export default AcynsSelectField;
