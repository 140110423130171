import React from "react";
import { BreadcrumbItem } from "Components/Breadcrumb/Breadcrumb";
import Layout from "Components/Layout/Layout";
import CustomerListBlock from "Containers/Customer/CustomerList/CustomerListBlock";
import { useBreadcrumb } from "Shared/Hooks/use-breadcrumb";
import { useSearchParams } from "react-router-dom";

export const CustemersListPage: React.FC = () => {
  const [searchParams] = useSearchParams();
  const isContact = searchParams.get("tab") === "Contacts";
  const breads: BreadcrumbItem[] = [{ title: "Customer", link: "/customers" }];
  const createTitle = isContact ? "Contact" : "Customer";
  const createLink = isContact ? "/customers/contacts/new" : "/customers/new";
  useBreadcrumb({
    links: breads,
    createLink,
    createTitle,
    isNeedCreateBtn: true,
    option: { upd: searchParams.get("tab") },
  });
  return (
    <Layout>
      <CustomerListBlock />
    </Layout>
  );
};
