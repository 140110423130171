import React from "react";
import styles from "./InvoiceHeader.module.scss";
import { getInvoiceHead } from "Shared/Utils/invoice";
import { IOrderInvoiceData } from "Shared/Types/invoice";

type InvoiceHeaderProps = {
  orderData: IOrderInvoiceData;
};

const InvoiceHeader: React.FC<InvoiceHeaderProps> = ({ orderData }) => {
  return (
    <div className={styles.address}>
      {getInvoiceHead(orderData).map((arr, index) => (
        <ul className={styles.address__block} key={index}>
          {arr?.map(({ title, value }) => (
            <li key={title + value} className={styles.item}>
              {title}
              {value}
            </li>
          ))}
        </ul>
      ))}
    </div>
  );
};
export default InvoiceHeader;
