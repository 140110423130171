export const employeeTableHead = [
  {
    id: "full_name",
    label: "Full name",
  },
  {
    id: "contact",
    label: "Contact",
  },
  {
    id: "role",
    label: "Role",
  },
  {
    id: "shop",
    label: "Assigned Shop",
  },
  {
    id: "action",
    label: "",
  },
];
export const employeeFields = ["Title", "Phone Number", "Email Address"];

export const employeeOrderFields = [
  "Order #",
  "Unit",
  "Client",
  "Created",
  "Creator",
];

export const employeeOrderAllFields = [...employeeOrderFields];
