import React, { useEffect } from "react";
import { BreadcrumbItem } from "Components/Breadcrumb/Breadcrumb";
import { useBreadcrumb } from "Shared/Hooks/use-breadcrumb";
import ShopForm from "Components/Shop/ShopForm/ShopForm";
import { IShopUpdate } from "Shared/Types/shop";
import { shopScheme } from "Shared/Utils/shop";
import { useSelector } from "react-redux";
import { removeCurrentShop, selectCurrentShop } from "Redux/Shop/slice";
import { useAppDispatch } from "Redux/store";
import { getShop, updateShop } from "Redux/Shop/reducer";
import { useNavigate, useParams } from "react-router";
import { notifySuccess } from "Components/Notify/notify";

const UpdateShop = () => {
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const currentShop = useSelector(selectCurrentShop);
  const navigate = useNavigate();
  // TODO: breads need to move to page
  const breads: BreadcrumbItem[] = [
    { title: "Shop", link: "/shops" },
    { title: "Update Shop", link: "" },
  ];

  const onUpdateShop = (values: IShopUpdate) => {
    const onSuccess = () => {
      navigate("/shops");
      notifySuccess("Shop successfully updated");
      dispatch(removeCurrentShop());
    };
    if (id) {
      dispatch(updateShop({ id, data: values, onSuccess }));
    }
  };

  useBreadcrumb({ links: breads, option: { upd: "New" } });

  useEffect(() => {
    if (id) {
      dispatch(getShop(id));
    }
  }, [id]);

  return (
    <div>
      {currentShop && (
        <ShopForm
          shop={currentShop}
          onSubmit={onUpdateShop}
          scheme={shopScheme}
        />
      )}
    </div>
  );
};

export default UpdateShop;
