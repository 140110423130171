import React, { useEffect, useMemo, useState } from "react";
import styles from "./ServiceForm.module.scss";
import { Grid } from "@mui/material";
import InputField from "Components/UI/Inputs/InputField";
import { Control } from "react-hook-form";
import { useSelector } from "react-redux";
import { selectComponentList } from "Redux/Component/componentSlice";
import { selectSystemList } from "Redux/System/systemSlice";
import { useAppDispatch } from "Redux/store";
import { getComponentList } from "Redux/Component/reducers";
import SelectField from "Components/UI/SelectField/SelectField";
import { getSystemList } from "Redux/System/reducers";
import { selectLaborRateList } from "Redux/LaborRate/slice";
import { getLaborRateList } from "Redux/LaborRate/reducer";
import Block from "Components/UI/Block/Block";

type BasicProps = {
  control: Control<any>;
  defaultComponent?: string;
};

const Basic: React.FC<BasicProps> = ({ control, defaultComponent }) => {
  const components = useSelector(selectComponentList) || [];
  const systems = useSelector(selectSystemList) || [];
  const laborRates = useSelector(selectLaborRateList) || [];
  const [currentComponent, setCurrentComponent] = useState<string | undefined>(
    defaultComponent
  );
  const disptach = useAppDispatch();
  const handleChangeComponent = (value: string) => {
    setCurrentComponent(value);
  };

  const filterSystems = useMemo(() => {
    return systems
      .filter((system) => system.component?.id === currentComponent)
      .map((system) => ({ label: system.name, value: system.id }));
  }, [currentComponent]);

  useEffect(() => {
    disptach(getComponentList());
    disptach(getSystemList(null));
    disptach(getLaborRateList(null));
  }, []);

  return (
    <Block className={styles.block} zIndex={2}>
      <h4 className={styles.form__form__title}>Basic Info</h4>
      <Grid container spacing={1} rowSpacing={-1}>
        <Grid item xs={12}>
          <SelectField
            name="component"
            label="Component"
            required
            options={components.map((component) => ({
              label: component.name,
              value: component.id,
            }))}
            control={control}
            withControl
            onChange={(value) => handleChangeComponent(value as string)}
          />
        </Grid>

        <Grid item xs={12}>
          <SelectField
            name="system"
            label="System"
            options={filterSystems}
            withControl
            control={control}
          />
        </Grid>

        <Grid item xs={12}>
          <InputField
            name="name"
            label="Name"
            required
            withController
            control={control}
          />
        </Grid>

        <Grid item xs={12}>
          <InputField
            name="description"
            label="Description"
            withController
            control={control}
          />
        </Grid>
        <Grid item container xs={12} columnSpacing={2}>
          <Grid item xs={6}>
            <InputField
              name="hour"
              label="Hour"
              withController
              control={control}
            />
          </Grid>
          <Grid item xs={6}>
            <SelectField
              name="labor_rate"
              label="Labor Rate"
              withControl
              options={laborRates.map((laborRate) => ({
                label: laborRate.name,
                value: laborRate.id,
              }))}
              control={control}
            />
          </Grid>
        </Grid>
      </Grid>
    </Block>
  );
};
export default Basic;
