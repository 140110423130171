import React from "react";
import styles from "./EmployeeForm.module.scss";
import { Grid } from "@mui/material";
import InputField from "Components/UI/Inputs/InputField";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Control } from "react-hook-form";
import cn from "classnames";

type AddionalDetailsProps = {
  control?: Control<any>;
};

const AddionalDetails: React.FC<AddionalDetailsProps> = ({ control }) => {
  return (
    <div className={cn(styles.block, styles.block__1)}>
      <h4 className={styles.form__form__title}>Addional Details</h4>
      <Grid container spacing={1} rowSpacing={-1}>
        <Grid item xs={12}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <p className={styles.block__date_label}>Hire date</p>
            <DatePicker
              className={styles.block__date_input}
              slotProps={{ textField: { size: "small", fullWidth: true } }}
              disableFuture
            />
          </LocalizationProvider>
        </Grid>

        <Grid item xs={12}>
          <InputField
            multiline
            withController
            control={control}
            rows={4}
            name="lastName"
            label="Additional information"
          />
        </Grid>
      </Grid>
    </div>
  );
};
export default AddionalDetails;
