import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import {
  selectClosedEstimatesOrderList,
  selectEstimatesOrderList,
  selectOpenEstimatesOrderList,
} from "Redux/Estimate/slice";
import { useAppDispatch } from "Redux/store";
import { useSearchParams } from "react-router-dom";
import { useNavigate } from "react-router";
import { readObj } from "Service/storage";
import {
  deleteEstimateOrder,
  getClosedEstimatesOrderList,
  getEstimatesOrderList,
  getOpenEstimatesOrderList,
} from "Redux/Estimate/reducer";
import { IOrderSearchParams } from "Shared/Types/order";
import OrderLayout from "Containers/Order/AllOrder/OrderLayout/OrderLayout";
import {
  estimateFieldAllList,
  estimateFieldList,
} from "Shared/Constants/estimates";
import { formatBackend } from "Shared/Utils/app";
import DeleteModal from "Components/UI/DialogWindow/DeleteModal";
import Card from "Components/UI/Card/Card/Card";
import { getEstimateCardFields, getStatuses } from "Shared/Utils/estimate";
import Row from "Components/UI/Card/Row/Row";
import { IEstimatesOrder } from "Shared/Types/estimate";

type EstimatesListProps = {
  status?: string;
};
const EstimatesList: React.FC<EstimatesListProps> = ({ status }) => {
  const [isCard, setIsCard] = useState(true);
  const estimatesList = useSelector(selectEstimatesOrderList) || [];
  const estimatesOpenList = useSelector(selectOpenEstimatesOrderList) || [];
  const estimatesCloseList = useSelector(selectClosedEstimatesOrderList) || [];
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  const [estimateFields, setEstimateFields] = useState(estimateFieldList);
  const [deleteId, setDeleteId] = useState<string | null>();
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const navigate = useNavigate();
  const curShop = readObj("current_shop");

  const goToDetail = (id?: string) => {
    if (id) {
      navigate("/orders/estimates/" + id);
    }
  };

  const handleDialogClose = () => {
    setOpenDeleteModal(false);
    setDeleteId(null);
  };

  const handleDialogOpen = (id: string) => {
    setOpenDeleteModal(true);
    setDeleteId(id);
  };

  const fetchEstimateList = (status?: string) => {
    if (status === "open") dispatch(getOpenEstimatesOrderList(params));
    if (status === "close") dispatch(getClosedEstimatesOrderList(params));
    if (status === "all") dispatch(getEstimatesOrderList(params));
  };

  const handleDeleteOrder = async () => {
    if (deleteId) {
      await dispatch(
        deleteEstimateOrder({
          id: deleteId,
          onSuccess: () => {
            fetchEstimateList(status);
            setOpenDeleteModal(false);
          },
        })
      );
    }
  };

  const getList = () => {
    if (status === "open") return estimatesOpenList;
    if (status === "close") return estimatesCloseList;
    return estimatesList;
  };

  const props = (estimate: IEstimatesOrder) => {
    return {
      dataId: estimate.id as string,
      fields: estimateFields,
      status: getStatuses(estimate.status),
      onClick: goToDetail,
      openDeleteModal: handleDialogOpen,
      cardData: getEstimateCardFields(estimate),
      isNeedStatus: true,
    };
  };

  const params = useMemo(() => {
    const curParams: IOrderSearchParams = {
      page: +(searchParams.get("page") || 1),
      unit: searchParams.get("unit"),
      customer: searchParams.get("customer"),
      shop: curShop?.id,
    };
    const from = searchParams.get("from");
    const to = searchParams.get("to");
    if (from && to) {
      curParams.created_at_after = formatBackend(new Date(from), "YYYY-MM-DD");
      curParams.created_at_before = formatBackend(new Date(to), "YYYY-MM-DD");
    }
    return curParams;
  }, [searchParams, status]);

  useEffect(() => {
    fetchEstimateList(status);
  }, [params, status]);

  return (
    <OrderLayout
      fields={estimateFields}
      setFields={setEstimateFields}
      isCard={isCard}
      setIsCard={setIsCard}
      fieldAllList={estimateFieldAllList}
    >
      {isCard
        ? getList().map((estimate) => (
            <Card key={estimate.id} {...props(estimate)} />
          ))
        : getList().map((estimate) => (
            <Row key={estimate.id} {...props(estimate)} />
          ))}
      <DeleteModal
        acceptTitle="Delete Estimate"
        open={openDeleteModal}
        handleClose={handleDialogClose}
        title="Delete Service Order "
        text="Are you sure you want to delete this Estimate?"
        accept={handleDeleteOrder}
      />
    </OrderLayout>
  );
};

export default EstimatesList;
