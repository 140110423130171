import React, { useEffect, useState } from "react";
import Modal from "Components/UI/Modal/Modal";
import IconButton from "Components/UI/IconButton/IconButton";
import { ActionHistoryIcon } from "Assets/index";
import { useSelector } from "react-redux";
import {
  clearActionItemHistory,
  selectActionItemHistoryList,
} from "Redux/ActionItem/slice";
import { useAppDispatch } from "Redux/store";
import { getActionItemHistory } from "Redux/ActionItem/reducer";
import styles from "./ActionItemHistory.module.scss";
import { format } from "Shared/Utils/app";

type ActionItemHistoryProps = {
  actionItemId: string;
  actionItemIndex?: number;
};

const ActionItemHistory: React.FC<ActionItemHistoryProps> = ({
  actionItemId,
  actionItemIndex,
}) => {
  const actionItemHistoryList = useSelector(selectActionItemHistoryList);
  const dispatch = useAppDispatch();
  const [openActionItemHistoryModal, setOpenActionItemHistoryModal] =
    useState<boolean>(false);

  const toggleActionItemHistoryModal = () => {
    setOpenActionItemHistoryModal(!openActionItemHistoryModal);
  };

  useEffect(() => {
    if (openActionItemHistoryModal) {
      dispatch(getActionItemHistory(actionItemId));
    }
    if (!openActionItemHistoryModal) {
      dispatch(clearActionItemHistory());
    }
  }, [openActionItemHistoryModal]);

  return (
    <div className={styles.history}>
      <IconButton
        variant="primary"
        icon={ActionHistoryIcon}
        width={23}
        height={23}
        style={{ padding: "7px" }}
        onClick={toggleActionItemHistoryModal}
      />
      <Modal
        isClosed
        open={openActionItemHistoryModal}
        onClose={toggleActionItemHistoryModal}
        title={`Action Item #${actionItemIndex}`}
      >
        {actionItemHistoryList?.map((history) => (
          <div key={history.id} className={styles.history__item}>
            <div className={styles.history__item__profile_cont}>
              <img
                className={styles.history__item__img}
                src={history.who.avatar || ""}
                alt="/"
              />
              <p className={styles.underline}>
                {history.who.first_name} {history.who.last_name}
              </p>
            </div>
            <p>{history.what}</p>
            <div>
              <p>
                {history.created_at && format(history.created_at, "h:mm a")}
              </p>
              <p>
                {history.created_at && format(history.created_at, "MM.DD.YYYY")}
              </p>
              <p>{history.who.phone} (phone)</p>
            </div>
          </div>
        ))}
      </Modal>
    </div>
  );
};

export default ActionItemHistory;
