import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "Service/api/api";
import {
  IDispatchCallback,
  IDispatchCallbackWithId,
  IDispatchCallbackWithoutData,
} from "Shared/Types/common";
import {
  IActionItemAdd,
  IActionItemSearchParams,
  IActionItemUpdate,
  IActionItemUpdateByField,
} from "Shared/Types/order";
import { errorHandler } from "Shared/Utils/app";

const getActionItemList = createAsyncThunk(
  "actionItem/getActionItemList",
  async (params: IActionItemSearchParams | null) => {
    try {
      const response = await api.actionItem.getActionItemList(params);
      return response.data;
    } catch (error) {
      if (error instanceof Error) {
        errorHandler(error);
      }
      throw error;
    }
  }
);

const getActionItem = createAsyncThunk(
  "actionItem/getActionItem",
  async (id: string) => {
    try {
      const response = await api.actionItem.getActionItem(id);
      return response.data;
    } catch (error) {
      if (error instanceof Error) {
        errorHandler(error);
      }
      throw error;
    }
  }
);

const createActionItem = createAsyncThunk(
  "actionItem/actionItem",
  async ({ data, onSuccess }: IDispatchCallback<IActionItemAdd>) => {
    try {
      const values = { ...data, service: data.service?.id };
      const response = await api.actionItem.addActionItem(values);
      onSuccess?.();
      return response.data;
    } catch (error) {
      if (error instanceof Error) {
        errorHandler(error, true);
      }
      throw error;
    }
  }
);

const updateActionItem = createAsyncThunk(
  "actionItem/updateActionItem",
  async ({
    id,
    data,
    isFile,
    onSuccess,
  }: IDispatchCallbackWithId<IActionItemUpdate>) => {
    try {
      let response;
      if (isFile) {
        const formData = new FormData();
        Object.entries(data).forEach(([key, value]) => {
          if (value instanceof Array && key === "action_item_gallery") {
            data.action_item_gallery?.length &&
              data.action_item_gallery?.forEach((file) => {
                if (file instanceof File) {
                  formData.append(key, file);
                }
              });
          } else if (value instanceof Array && key === "action_item_file") {
            data.action_item_file?.length &&
              data.action_item_file?.forEach((file) => {
                if (file instanceof File) {
                  formData.append(key, file);
                }
              });
          } else if (value !== undefined && value !== null) {
            formData.append(key, String(value));
          }
        });
        response = await api.actionItem.updateActionItem(id, formData);
      } else {
        const actionItem = {
          ...data,
          technician: data.technician?.id,
          labor_rate: data.labor_rate?.id,
          service: data.service?.id,
        };
        response = await api.actionItem.updateActionItem(id, actionItem);
      }
      onSuccess?.();
      return response.data;
    } catch (error) {
      if (error instanceof Error) {
        errorHandler(error, true);
      }
      throw error;
    }
  }
);

const updateActionItemByField = createAsyncThunk(
  "order/updateActionItemByField",
  async ({
    id,
    data: { field, value },
    onSuccess,
  }: IDispatchCallbackWithId<IActionItemUpdateByField>) => {
    try {
      const response = await api.actionItem.updateActionItemField(id, {
        [field]: value,
      });
      onSuccess?.();
      return response.data;
    } catch (error) {
      if (error instanceof Error) {
        errorHandler(error, true);
      }
      throw error;
    }
  }
);

const deleteActionItem = createAsyncThunk(
  "actionItem/deleteActionItem",
  async ({ id, onSuccess }: IDispatchCallbackWithoutData) => {
    try {
      await api.actionItem.deleteActionItem(id);
      onSuccess?.();
      return id;
    } catch (error) {
      if (error instanceof Error) {
        errorHandler(error, true);
      }
      throw error;
    }
  }
);

const getActionItemStatusList = createAsyncThunk(
  "actionItem/getActionItemStatusList",
  async () => {
    try {
      const response = await api.actionItem.getActionItemStatusList();
      return response.data;
    } catch (error) {
      if (error instanceof Error) {
        errorHandler(error);
      }
      throw error;
    }
  }
);

const markActionAsDiagnosed = createAsyncThunk(
  "actionItem/markActionAsDiagnosed",
  async ({
    id,
    data,
    onSuccess,
  }: IDispatchCallbackWithId<IActionItemUpdate>) => {
    try {
      const actionItem = {
        ...data,
        technician: data.technician?.id,
        labor_rate: data.labor_rate?.id,
        service: data.service?.id,
      };
      const response = await api.actionItem.markAction(id, actionItem);
      onSuccess?.();
      return response.data;
    } catch (error) {
      if (error instanceof Error) {
        errorHandler(error, true);
      }
      throw error;
    }
  }
);

const entreeInternally = createAsyncThunk(
  "actionItem/entreeInternally",
  async ({ id, onSuccess }: IDispatchCallbackWithoutData) => {
    try {
      const response = await api.actionItem.entreeInternally(id);
      onSuccess?.();
      return response.data;
    } catch (error) {
      if (error instanceof Error) {
        errorHandler(error, true);
      }
      throw error;
    }
  }
);
const readyToInvoice = createAsyncThunk(
  "actionItem/readyToInvoice",
  async ({ id, onSuccess }: IDispatchCallbackWithoutData) => {
    try {
      const response = await api.actionItem.readyToInvoice(id);
      onSuccess?.();
      return response.data;
    } catch (error) {
      if (error instanceof Error) {
        errorHandler(error, true);
      }
      throw error;
    }
  }
);
const underReview = createAsyncThunk(
  "actionItem/underReview",
  async ({ id, onSuccess }: IDispatchCallbackWithoutData) => {
    try {
      const response = await api.actionItem.underReview(id);
      onSuccess?.();
      return response.data;
    } catch (error) {
      if (error instanceof Error) {
        errorHandler(error, true);
      }
      throw error;
    }
  }
);
const waitingForAuth = createAsyncThunk(
  "actionItem/waitingForAuth",
  async ({ id, onSuccess }: IDispatchCallbackWithoutData) => {
    try {
      const response = await api.actionItem.waitingForAuth(id);
      onSuccess?.();
      return response.data;
    } catch (error) {
      if (error instanceof Error) {
        errorHandler(error, true);
      }
      throw error;
    }
  }
);

const getActionItemHistory = createAsyncThunk(
  "actionItem/getActionItemHistory",
  async (id: string) => {
    try {
      const response = await api.actionItem.getActionItemHistory(id);
      return response.data;
    } catch (error) {
      if (error instanceof Error) {
        errorHandler(error);
      }
      throw error;
    }
  }
);

export {
  getActionItemList,
  getActionItem,
  createActionItem,
  updateActionItem,
  deleteActionItem,
  getActionItemStatusList,
  markActionAsDiagnosed,
  entreeInternally,
  readyToInvoice,
  underReview,
  waitingForAuth,
  getActionItemHistory,
  updateActionItemByField,
};
