import React, { useEffect } from "react";
import styles from "./ActionItemDetialEstimate.module.scss";
import { IActionItem } from "Shared/Types/order";
import { useAppDispatch } from "Redux/store";
import { getActionItem } from "Redux/ActionItem/reducer";
import { useSelector } from "react-redux";
import { selectActionItemList } from "Redux/ActionItem/slice";
import ActionItemDetailHeader from "Components/Order/ActionItemDetial/ActionItemDetailHeader/ActionItemDetailHeader";
import Table from "Components/UI/Table/Table";
import {
  actionItemPartTableHead,
  actionTableHead,
} from "Shared/Constants/action-item";
import { renderCell } from "Components/Order/Estimate/ActionItem/renderCell";
import { renderCellAIPart } from "Components/Order/Estimate/ActionItem/renderCellAIPart";

type ActionItemDetailEstimateProps = {
  onClose: () => void;
  actionItem: IActionItem;
  openDelete: (id: string) => void;
  index: number;
  setActiveTab?: (tab: number) => void;
};

const ActionItemDetailEstimate: React.FC<ActionItemDetailEstimateProps> = ({
  onClose,
  actionItem,
  openDelete,
  index,
}) => {
  const dispatch = useAppDispatch();
  const currentActionItem = useSelector(selectActionItemList)?.find(
    (action) => action.id === actionItem.id
  );

  useEffect(() => {
    dispatch(getActionItem(actionItem.id));
  }, [actionItem]);

  if (!currentActionItem) {
    return <></>;
  }
  return (
    <div className={styles.action}>
      <ActionItemDetailHeader
        index={index}
        onClose={onClose}
        openDelete={openDelete}
        currentActionItem={currentActionItem}
        isEstimate
      />
      <div className={styles.action__body}>
        <Table
          columns={actionTableHead}
          data={[currentActionItem]}
          renderCell={renderCell}
          isInDetailPage
        />

        <div className={styles.action__body__parts}>
          <Table
            columns={actionItemPartTableHead}
            data={currentActionItem.action_item_part || []}
            renderCell={renderCellAIPart}
            isInDetailPage
          />
        </div>
      </div>
    </div>
  );
};
export default ActionItemDetailEstimate;
