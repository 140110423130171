import React from "react";
import { Grid } from "@mui/material";
import InfoField from "Components/UI/InfoField/InfoField";
import InputField from "Components/UI/Inputs/InputField";
import { IActionPart } from "Shared/Types/action-item-part";
import { getActionItemPartRecieveFields } from "Shared/Utils/action-item-part";
import { useFormContext } from "react-hook-form";

type ReceivePartItemProps = {
  part: IActionPart;
  index: number;
};

const ReceivePartItem: React.FC<ReceivePartItemProps> = ({ part, index }) => {
  const { control } = useFormContext();
  const getName = (name: string) => `receive_line_items[${index}].${name}`;

  return (
    <Grid container>
      {getActionItemPartRecieveFields(part).map(({ label, value }) => (
        <Grid key={label} item xs={3}>
          <InfoField label={label} value={value} />
        </Grid>
      ))}
      <Grid item xs={3}>
        <InputField
          name={getName("received_quantity")}
          type="number"
          label="Receive Qty"
          withController
          control={control}
        />
      </Grid>
    </Grid>
  );
};
export default ReceivePartItem;
