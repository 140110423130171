export const partFields = [
  "Name",
  "Code",
  "Price",
  "Quantity",
  "Vendor",
  "Category",
  "Manufacturer",
  "Location",
];

export const estimatePartFields = [
  "Estimate #",
  "Unit",
  "Customer",
  "Created",
  "Created",
  "Service Writer",
  "Labor & Parts",
];

export const partPriceFields = [
  "Part %",
  "Order #",
  "PO #",
  "Unit",
  "Customer",
  "SO Creator",
  "Part Manager",
];

export const inventoryPartFields = [
  "Part #",
  "In Stock",
  "Average Cost",
  "Selling Price",
  "Min",
  "Max",
  "Location",
  "Vendor",
];
export const inventoryTableHead = [
  {
    label: "ID",
    id: "id",
    width: "10%",
  },
  {
    label: "Date",
    id: "date",
    width: "10%",
  },
  {
    label: "Adjustment type",
    id: "adjustment type",
    width: "10%",
  },
  {
    label: "Starting Qyt ",
    id: "starting qyt",
    width: "10%",
  },
  {
    label: "Ending Qyt",
    id: "ending qyt",
    width: "10%",
  },
  {
    label: "Qyt Change",
    id: "qyt change",
    width: "10%",
  },
  {
    label: "Cost ",
    id: "cost ",
    width: "10%",
  },
  {
    label: "Total",
    id: "total",
    width: "10%",
  },
  {
    label: "Average cost",
    id: "average cost",
    width: "10%",
  },
  {
    label: "Inventory Balance ",
    id: "inventory balance ",
    width: "10%",
  },
  {
    label: "Selling Price",
    id: "selling price",
    width: "10%",
  },

  {
    label: "Markup %",
    id: "markup %",
    width: "10%",
  },
  {
    label: "Margin %",
    id: "margin %",
    width: "10%",
  },
];

export const unitOfMeasumenOptions = [
  { value: "cm", label: "cm" },
  { value: "ea", label: "ea" },
  { value: "ft", label: "ft" },
  { value: "g", label: "g" },
  { value: "gal", label: "gal" },
  { value: "in", label: "in" },
  { value: "kg", label: "kg" },
  { value: "kit", label: "kit" },
  { value: "lb", label: "lb" },
  { value: "lf", label: "lf" },
  { value: "liter", label: "liter" },
  { value: "m", label: "m" },
  { value: "ml", label: "ml" },
  { value: "oz", label: "oz" },
  { value: "pair", label: "pair" },
  { value: "pt", label: "pt" },
  { value: "qr", label: "qr" },
  { value: "set", label: "set" },
  { value: "sq ft", label: "sq ft" },
  { value: "yd", label: "yd" },
];

export const partStatusOptions = [
  { value: "active", label: "Active" },
  { value: "inactive", label: "Inactive" },
  { value: "obsolete", label: "Obsolete" },
  { value: "low_stock", label: "Low stock" },
  { value: "out_of_stock", label: "Out of stock" },
  { value: "needs_ordered", label: "Needs ordered" },
];

export const partStatusCard = {
  active: { color: "green", title: "Active" },
  inactive: { color: "none", title: "Inactive" },
  low_stock: { color: "lightYellow", title: "Low stock" },
  out_of_stock: { color: "red", title: "Out of stock" },
  obsolete: { color: "lightYellow", title: "Obsolete" },
  needs_ordered: { color: "red", title: "Needs ordered" },
};

export const partEstimateStatusCard = {
  Active: { color: "green", title: "Active" },
  Archived: { color: "none", title: "Archived" },
  "Converted to SO": { color: "lightYellow", title: "Converted To SO" },
};
export const partStatusLabel = {
  active: "Active",
  inactive: "Inactive",
  low_stock: "Low stock",
  out_of_stock: "Out of stock",
  obsolete: "Obsolete",
  needs_ordered: "Needs ordered",
};

export const partStatuses = {
  estimates: {
    link: "estimate",
    title: "Estimates Parts",
  },
  price: {
    link: "price",
    title: "Price Parts",
  },
  request: {
    link: "request",
    title: "Request Parts",
  },
  order: {
    link: "order",
    title: "Order Parts",
  },
  receive: {
    link: "receive",
    title: "Receive Parts",
  },
  return: {
    link: "return",
    title: "Return Parts",
  },
};

export const partInventoryBreads = [
  { title: "Parts", link: "/parts" },
  { title: "Inventory", link: "/parts/inventory" },
];
