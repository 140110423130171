import React, { useEffect } from "react";
import styles from "./SubtypeUpdate.module.scss";
import { useAppDispatch } from "Redux/store";
import { useSelector } from "react-redux";
import { notifySuccess } from "Components/Notify/notify";
import { selectCurrentSubtype } from "Redux/Subtype/slice";
import { getSubtype, updateSubtype } from "Redux/Subtype/reducer";
import SubtypeForm from "Components/Subtype/SubtypeForm/SubtypeForm";
import { subtypeSchema } from "Shared/Utils/subtype";
import { ISubtypeUpdate } from "Shared/Types/subtype";
import { useNavigate, useParams } from "react-router";

const SubtypeUpdate: React.FC = () => {
  const dispatch = useAppDispatch();
  const params = useParams();
  const subtype = useSelector(selectCurrentSubtype);
  const navigate = useNavigate();

  const createSubtype = (values: ISubtypeUpdate) => {
    const onSuccess = () => {
      notifySuccess("Subtype updated");
      navigate("/settings/unit-settings/subtypes");
    };
    if (params.id) {
      dispatch(updateSubtype({ id: params.id, data: values, onSuccess }));
    }
  };

  useEffect(() => {
    if (params.id) {
      dispatch(getSubtype(params.id));
    }
  }, []);

  return (
    <div className={styles.subtypeUpdate}>
      <h3>Edit a subtype</h3>
      <div className={styles.subtypeUpdate__form}>
        {subtype && (
          <SubtypeForm
            onSubmit={createSubtype}
            schema={subtypeSchema}
            subtype={subtype}
          />
        )}
      </div>
    </div>
  );
};
export default SubtypeUpdate;
