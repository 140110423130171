import React, { useEffect, useRef } from "react";
import styles from "Components/Order/ServiceOrderForms/ServiceOrderAdd.module.scss";
import InputField from "Components/UI/Inputs/InputField";
import Button from "Components/UI/Button/Button";
import Checkbox from "Components/UI/Inputs/Checkbox";
import { ClockIcon, CloseIcon, DeleteIcon } from "Assets/index";
import { LaborRateSearch } from "Components/UI/AutoCompleteSearchInputs/LaborRateSearch";
import { UserSearch } from "Components/UI/AutoCompleteSearchInputs/UserSearch";
import { Control, useFormContext } from "react-hook-form";
import { IActionItemAdd, IOrderAdd } from "Shared/Types/orderAdd";
import { actionItemInitialValues } from "Shared/Utils/action-item";
import { ComplaintServiceSearch } from "Components/UI/AutoCompleteSearchInputs/ComplaintServiceSearch";
import { ServiceSearch } from "Components/UI/AutoCompleteSearchInputs/ServiceSearch";
import Icon from "Components/UI/Icon/Icon";
import { IService } from "Shared/Types/service";
import Form from "Components/UI/Form/Form";
import { Link } from "react-router-dom";
import { clear, write } from "Service/storage";
import { useSelector } from "react-redux";
import { selectOrderLoading } from "Redux/Order/slice";

type ActionItemInfoProps = {
  control: Control<IOrderAdd>;
  fields?: IActionItemAdd[];
  append: (e: IActionItemAdd) => void;
  remove: (index: number) => void;
  actionItemList: IService[];
  servicesList: IService[];
  setActionItemList: (e: IService[]) => void;
  setServicesList: (e: IService[]) => void;
  saveOrderValues: () => void;
  setFormStep: (step: number) => void;
  onCreateSubmit: (values: any) => void;
};

const ActionItemInfo: React.FC<ActionItemInfoProps> = ({
  control,
  fields,
  append,
  remove,
  actionItemList,
  setActionItemList,
  saveOrderValues,
  setFormStep,
  onCreateSubmit,
}) => {
  const serviceInputRef = useRef<HTMLInputElement | null>(null);
  const loading = useSelector(selectOrderLoading);

  const { handleSubmit } = useFormContext();
  const deleteActionItem = (item: IActionItemAdd, index: number) => {
    const updatedServicesList = actionItemList.filter(
      (el) => el.id !== item.index
    );
    setActionItemList(updatedServicesList);
    remove(index);
  };

  const generateKey = () => {
    let max = 0;
    if (fields) {
      for (const field of fields) {
        max = Math.max(+(field?.index || ""), max);
      }
    }

    actionItemInitialValues.index = String(max + 1);
    return actionItemInitialValues;
  };

  const effectHasRun = useRef(false);

  useEffect(() => {
    if (!effectHasRun.current) {
      if (!fields?.length) {
        append(actionItemInitialValues);
      }
      effectHasRun.current = true;
    }
  }, [fields]);

  return (
    <Form
      onSubmit={(e) => {
        e.preventDefault();
        saveOrderValues();
        setFormStep(3);
        write("formStep", "3");
      }}
      className={styles.form_section}
    >
      <div style={{ display: "flex", justifyContent: "end" }}>
        <Link onClick={() => clear("created_service_order")} to="/orders">
          <Icon component={CloseIcon} />
        </Link>
      </div>
      {fields?.map((field, index) => (
        <div key={field.id}>
          <div className={styles.form_section__title}>
            <p className={styles.title}>Action Item {index + 1}</p>
            <Icon
              height={17}
              width={17}
              className={styles.action_item__icon}
              onClick={() => deleteActionItem(field, index)}
              component={DeleteIcon}
            />
          </div>
          <div className={styles.form_section__inputs}>
            <ComplaintServiceSearch
              index={index}
              name={`action_items[${index}].complaint`}
              label="Customer Complaint"
              service_name={`action_items[${index}].service`}
              isAdd
              beforeCreateNew={saveOrderValues}
              required
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  e.preventDefault();
                  serviceInputRef?.current?.focus();
                }
              }}
            />
            <ServiceSearch
              inputRef={serviceInputRef}
              name={`action_items[${index}].service`}
              label="Component / System"
              isAdd
              beforeCreateNew={saveOrderValues}
            />
            <UserSearch
              name={`action_items[${index}].technician`}
              label="Assign technician"
              isAdd
              beforeCreateNew={saveOrderValues}
            />
            <LaborRateSearch
              name={`action_items[${index}].labor_rate`}
              label="Labor Rate"
              isAdd
            />
            <InputField
              withController
              control={control}
              icon={ClockIcon}
              IconBg="#007DF2"
              required
              type="number"
              name={`action_items[${index}].invoice_hours`}
              label="Invoice hours"
            />
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div style={{ marginTop: "10px" }}>
              <Checkbox
                withController
                control={control}
                label="Pre -authorize"
                name={`action_items[${index}].is_pre_authorize`}
                labelPosition="right"
              />
              <Checkbox
                withController
                control={control}
                label="Skip Diagnosis"
                name={`action_items[${index}].is_skip_diagnosis`}
                labelPosition="right"
              />
            </div>
          </div>
        </div>
      ))}
      <button
        className={styles.add_button}
        onClick={() => append(generateKey())}
      >
        + Add another action item
      </button>
      <div className={styles.prev_next_buttons}>
        <Button
          color="secondary"
          onClick={() => {
            saveOrderValues();
            setFormStep(1);
            write("formStep", "1");
          }}
        >
          Previous
        </Button>
        <div>
          <Button
            style={{ marginRight: "20px" }}
            disabled={loading}
            onClick={() => {
              handleSubmit(onCreateSubmit)();
            }}
            type="button"
            loading={loading}
          >
            Create SO
          </Button>
          <Button type="submit">Next</Button>
        </div>
      </div>
    </Form>
  );
};

export default ActionItemInfo;
