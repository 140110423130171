import React, { useEffect } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { Controller, useFormContext } from "react-hook-form";
import { useSelector } from "react-redux";
import {
  removeCurrentCustomer,
  selectCustomerList,
} from "Redux/Customer/slice";
import { useAppDispatch } from "Redux/store";
import { getCustomer, getCustomerList } from "Redux/Customer/reducer";
import { ICustomer } from "Shared/Types/customer";
import styles from "./AutoCompleteSearchInput.module.scss";
import { TextFieldProps } from "@mui/material";
import { componentsProps } from "./LaborRateSearch";
import { useNavigate } from "react-router";
import { ArrowDownBlackIcon } from "Assets/index";

interface SearchProps {
  name: string;
  required?: boolean;
  label?: string;
  isAdd?: boolean;
  beforeCreateNew?: () => void;
}

export const CustomerSearch: React.FC<SearchProps & TextFieldProps> = ({
  name,
  required,
  label,
  isAdd,
  beforeCreateNew,
}) => {
  const { control } = useFormContext();
  const customers = useSelector(selectCustomerList) || [];
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const searchCustomers = (text: string | null) => {
    dispatch(
      getCustomerList({
        search: text || null,
      })
    );
  };

  const goToCreateNew = () => {
    beforeCreateNew?.();
    navigate("/customers/new", { state: "back" });
  };
  const getLabel = (customer: ICustomer | null) => {
    if (customer) {
      return customer?.company_name || customer.first_name;
    }
    return "";
  };

  useEffect(() => {
    if (control._defaultValues.customer?.id) {
      dispatch(getCustomer(control._defaultValues.customer?.id));
    }
    dispatch(
      getCustomerList({
        search: control._defaultValues?.customer?.first_name || null,
      })
    );
    return () => {
      dispatch(removeCurrentCustomer());
    };
  }, []);
  return (
    <div className={styles.autocomplete}>
      <label data-id={name} className={styles.autocomplete__label}>
        {label}
        {required && <span className={styles.autocomplete__error}>*</span>}
      </label>
      <Controller
        name={name}
        control={control}
        defaultValue={control._defaultValues.customer}
        render={({ field, fieldState }) => (
          <Autocomplete
            {...field}
            options={customers}
            getOptionLabel={getLabel}
            onChange={(_, value) => {
              field.onChange(value);
              if (value?.id) {
                dispatch(getCustomer(value?.id));
              } else {
                dispatch(removeCurrentCustomer());
              }
            }}
            popupIcon={<ArrowDownBlackIcon />}
            noOptionsText={
              isAdd && <label onClick={goToCreateNew}>+ Add new Customer</label>
            }
            disablePortal={true}
            componentsProps={componentsProps}
            renderInput={(params) => (
              <>
                <TextField
                  sx={{
                    ".MuiOutlinedInput-notchedOutline": {
                      borderStyle: "none",
                    },
                  }}
                  classes={{ root: styles.autocomplete__input }}
                  name={name}
                  required={required}
                  onChange={(event) => {
                    searchCustomers(event.target.value);
                  }}
                  {...params}
                />
                {fieldState.error && (
                  <div className={styles.autocomplete__error}>
                    {fieldState.error.message}
                  </div>
                )}
              </>
            )}
          />
        )}
      />
    </div>
  );
};
