import React, { useEffect } from "react";
import { Grid } from "@mui/material";
import SelectField from "Components/UI/SelectField/SelectField";
import InputField from "Components/UI/Inputs/InputField";
import { useSelector } from "react-redux";
import { selectCustomerList } from "Redux/Customer/slice";
import { selectUnitList } from "Redux/Unit/slice";
import { UseFieldArrayAppend, useFormContext } from "react-hook-form";
import { useAppDispatch } from "Redux/store";
import { getCustomerList } from "Redux/Customer/reducer";
import { getUnitsList } from "Redux/Unit/reducer";
import { readObj } from "Service/storage";
import {
  checkRequiredFieldsFilled,
  getEstimateComplaintServiceDefaultValues,
} from "Shared/Utils/estimate";
import {
  IEstimateOrderAdd,
  IEstimateOrderUpdate,
  IEstimatesUpdateFields,
} from "Shared/Types/estimate";
import {
  createEstimateOrder,
  getEstimateOrder,
  updateEstimateOrder,
} from "Redux/Estimate/reducer";
import { selectCurrentEstimateOrder } from "Redux/Estimate/slice";
import { notifySuccess } from "Components/Notify/notify";

interface EstimateCreateProps {
  append: UseFieldArrayAppend<IEstimateOrderAdd, "action_items">;
}

const EstimateCreate: React.FC<EstimateCreateProps> = ({ append }) => {
  const currentEstimate = useSelector(selectCurrentEstimateOrder);
  const customers = useSelector(selectCustomerList) || [];
  const units = useSelector(selectUnitList) || [];
  const shop = readObj("current_shop");
  const dispatch = useAppDispatch();

  const { control, getValues, handleSubmit, setValue, watch } =
    useFormContext();
  const isRequiredFieldsFilled = checkRequiredFieldsFilled(watch);

  const onSuccess = () => {
    append(getEstimateComplaintServiceDefaultValues());
    notifySuccess("Estimate successfully created");
  };
  const createNewEstimate = async (values: IEstimateOrderAdd) => {
    const res = await dispatch(
      createEstimateOrder({ data: values, onSuccess }),
    );
    await dispatch(getEstimateOrder(res.payload.id));
  };

  const updateEstimate = async (values: IEstimateOrderUpdate) => {
    if (currentEstimate?.id) {
      await dispatch(
        updateEstimateOrder({
          id: currentEstimate?.id,
          data: {
            ...values,
          },
        }),
      );
    }
  };

  const handleBlur = (key: IEstimatesUpdateFields) => {
    if (isRequiredFieldsFilled && getValues()[key]) {
      handleSubmit(updateEstimate)();
    }
  };

  useEffect(() => {
    setValue("shop", shop.id);
    dispatch(getCustomerList(null));
  }, [shop.id]);

  useEffect(() => {
    dispatch(
      getUnitsList({
        customer: getValues("customer"),
      }),
    );
  }, [getValues("customer")]);

  useEffect(() => {
    if (isRequiredFieldsFilled) {
      handleSubmit(createNewEstimate)();
      handleSubmit(updateEstimate)();
    }
  }, [isRequiredFieldsFilled]);

  return (
    <Grid container item xs={12} spacing={1}>
      <Grid item xs={6}>
        <SelectField
          name="customer"
          label="Who is the customer?"
          options={customers.map((customer) => ({
            label: `${customer.first_name} ${customer.last_name}`,
            value: customer.id,
          }))}
          control={control}
          withControl
          required
        />
      </Grid>
      <Grid item xs={6}>
        <InputField
          name="description"
          label="Description"
          withController
          control={control}
          onBlur={() => handleBlur("description")}
        />
      </Grid>
      <Grid item xs={6}>
        <InputField
          name="shop"
          label="Shop"
          value={shop.shop_name}
          withController
          control={control}
          required
        />
      </Grid>
      <Grid item xs={6}>
        <InputField
          name="authorization"
          label="Authorization #"
          withController
          control={control}
          onBlur={() => handleBlur("authorization")}
        />
      </Grid>
      <Grid item xs={6}>
        <SelectField
          name="unit"
          label="Unit"
          options={units.map((unit) => ({
            label: unit.name,
            value: unit.id,
          }))}
          control={control}
          withControl
          required
        />
      </Grid>
      <Grid item xs={6}>
        <InputField
          name="purchase_order_number"
          label="PO #"
          withController
          control={control}
          onBlur={() => handleBlur("purchase_order_number")}
        />
      </Grid>
    </Grid>
  );
};

export default EstimateCreate;
