import React, { useEffect, useMemo, useState } from "react";
import { orderFieldAllList, orderFieldList } from "Shared/Constants/order";
import { useSelector } from "react-redux";
import {
  selectOnHoldOrderList,
  selectOrderList,
  selectOrderListByEmployee,
  selectOrderStatusList,
} from "Redux/Order/slice";
import { useAppDispatch } from "Redux/store";
import {
  deleteOrder,
  getOnHoldOrderList,
  getOrderList,
  getOrderListByEmployee,
  getOrderStatusList,
} from "Redux/Order/reducer";
import { useNavigate } from "react-router";
import { notifySuccess } from "Components/Notify/notify";
import DeleteModal from "Components/UI/DialogWindow/DeleteModal";
import { getUrgencyOptionList } from "Redux/UrgencyOption/reducer";
import OrderLayout from "../OrderLayout/OrderLayout";
import { useSearchParams } from "react-router-dom";
import { IOrderList, IOrderSearchParams } from "Shared/Types/order";
import { getOrderStatusId, getOrdreCardData } from "Shared/Utils/order";
import { formatBackend } from "Shared/Utils/app";
import { readObj } from "Service/storage";
import { selectAccount } from "Redux/Auth/slice";
import { IUser } from "Shared/Types/user";
import {
  employeeOrderAllFields,
  employeeOrderFields,
} from "Shared/Constants/employee";
import Card from "Components/UI/Card/Card/Card";
import Row from "Components/UI/Card/Row/Row";

type OrderListProps = {
  status?: string;
  onHold?: boolean;
  employee?: IUser;
};

const OrderList: React.FC<OrderListProps> = ({ status, onHold, employee }) => {
  const [isCard, setIsCard] = useState(true);
  const orderList = useSelector(selectOrderList) || [];
  const onHoldOrderList = useSelector(selectOnHoldOrderList) || [];
  const orderEmployeeList = useSelector(selectOrderListByEmployee) || [];
  const timeStatusfromBackend = useSelector(selectOrderStatusList);
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  const [orderFields, setOrderFields] = useState(
    employee ? employeeOrderFields : orderFieldList
  );
  const [deleteId, setDeleteId] = useState<string | null>();
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const navigate = useNavigate();
  const curShop = readObj("current_shop");
  const profile = useSelector(selectAccount);
  const goToDetail = (id?: string) => {
    if (onHold && id) {
      navigate("/orders/on-hold/" + id);
      return;
    }
    if (id) {
      navigate("/orders/" + status + "/" + id);
    }
  };

  const handleDialogClose = () => {
    setOpenDeleteModal(false);
    setDeleteId(null);
  };

  const handleDialogOpen = (id: string) => {
    setOpenDeleteModal(true);
    setDeleteId(id);
  };

  const getList = () => {
    if (employee) return orderEmployeeList;
    if (onHold) return onHoldOrderList;
    return orderList;
  };

  const props = (order: IOrderList) => {
    return {
      fields: orderFields,
      dataId: order.id,
      onClick: goToDetail,
      openDeleteModal: handleDialogOpen,
      status: order.time_status,
      cardData: getOrdreCardData(order),
      isNeedStatus: true,
    };
  };

  const params = useMemo(() => {
    const curParams: IOrderSearchParams = {
      page: +(searchParams.get("page") || 1),
      unit: searchParams.get("unit"),
      customer: searchParams.get("customer"),
      status_order: getOrderStatusId(status, timeStatusfromBackend),
      shop: curShop?.id,
    };
    const from = searchParams.get("from");
    const to = searchParams.get("to");
    if (from && to) {
      curParams.created_at_after = formatBackend(new Date(from), "YYYY-MM-DD");
      curParams.created_at_before = formatBackend(new Date(to), "YYYY-MM-DD");
    }

    return curParams;
  }, [searchParams, timeStatusfromBackend]);

  const fetchOrders = () => {
    if (!timeStatusfromBackend?.length) return;
    if (onHold) {
      dispatch(getOnHoldOrderList(params));
    } else if (employee) {
      dispatch(
        getOrderListByEmployee({
          technician: employee?.id,
          ...params,
        })
      );
    } else {
      dispatch(getOrderList(params));
    }
  };

  const handleDeleteOrder = () => {
    if (deleteId) {
      dispatch(
        deleteOrder({
          id: deleteId,
          onSuccess: () => {
            notifySuccess("Order deleted");
            setOpenDeleteModal(false);
            fetchOrders();
          },
        })
      );
    }
  };

  useEffect(() => {
    fetchOrders();
  }, [params]);

  useEffect(() => {
    if (profile?.company?.id) {
      dispatch(getUrgencyOptionList({ company: profile?.company?.id }));
    }
  }, [profile]);
  useEffect(() => {
    dispatch(getOrderStatusList());
  }, []);
  return (
    <OrderLayout
      fields={orderFields}
      setFields={setOrderFields}
      isCard={isCard}
      setIsCard={setIsCard}
      fieldAllList={employee ? employeeOrderAllFields : orderFieldAllList}
      onHold={onHold}
      employee={employee}
    >
      {isCard
        ? getList().map((order) => <Card key={order.id} {...props(order)} />)
        : getList().map((order) => <Row key={order.id} {...props(order)} />)}

      <DeleteModal
        acceptTitle="Delete SO"
        open={openDeleteModal}
        handleClose={handleDialogClose}
        title="Delete Service Order "
        text="Are you sure you want to delete this Service Order?"
        accept={handleDeleteOrder}
      />
    </OrderLayout>
  );
};
export default OrderList;
