import * as Yup from "yup";
import { actionItemSchema } from "Shared/Utils/action-item";
import { IUser } from "Shared/Types/user";
import { ICustomer } from "Shared/Types/customer";
import { IUnit } from "Shared/Types/unit";
import { IContact } from "Shared/Types/orderAdd";

export const orderSchema = Yup.object().shape({
  availability_date: Yup.string(),
  finish_date: Yup.string(),
  start_date: Yup.string(),
  access_unit: Yup.string(),
  deliver_unit: Yup.string(),
  purchase_order_number: Yup.string(),
  notes: Yup.string(),
  auth_blank: Yup.boolean(),
  is_shown_note: Yup.boolean(),
  invoice_hours: Yup.number().nullable(),
  created_at: Yup.string(),
  updated_at: Yup.string(),
  chassis_miles: Yup.string(),
  shop: Yup.string(),
  customer: Yup.mixed<ICustomer>().required("Customer is required"),
  unit: Yup.mixed<IUnit>().required("Unit is required"),
  service_requester: Yup.mixed<IContact>().notRequired(),
  time_status: Yup.string(),
  lead_technician: Yup.mixed<IUser>().notRequired(),
  action_items: Yup.array().required().of(actionItemSchema).required(),
  order_gallery: Yup.mixed<File[]>(),
  order_file: Yup.mixed<File[]>(),
});

export const access_unit = [
  { label: "Customer will bring until to the shop", value: "1" },
  { label: "Shop will service unit on-site", value: "2" },
  { label: "Shop will pick up unit and bring to the shop", value: "3" },
  { label: "Shop will tow unit", value: "4" },
  { label: "No, preference, just get it done", value: "5" },
];

export const deliver_unit = [
  { label: "NO Customer will pick up unit from shop", value: "No" },
  { label: "YES Shop will retune unit to the shop", value: "Yes" },
];
