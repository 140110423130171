import React, { ChangeEvent, Dispatch, SetStateAction, useState } from "react";
import styles from "./FilterPanel.module.scss";
import SelectField from "Components/UI/SelectField/SelectField";
import { useSearchParams } from "react-router-dom";
import { changeToParams } from "Shared/Utils/search-params";
import cn from "classnames";
import SearchFieldNew from "Components/Layout/Inputs/SearchField";
import IconButton from "Components/UI/IconButton/IconButton";
import { CardIcon, FilterIcon } from "Assets/index";
import FilterBlock from "Components/Order/FieldChange/FieldChange";
import { useSelector } from "react-redux";
import AsyncSelectField from "Components/UI/AcyncSelect/AcyncSelect";
import { selectCompanyRequestList } from "Redux/CompanyRequests/slice";
import { changeCompanyRequestsOptions } from "Shared/Utils/app";
import DateRange from "Components/UI/DateRange/DateRange";

const statuses = [
  { label: "All status", value: "" },
  { label: "Review", value: "waiting" },
  { label: "Approved", value: "active" },
  { label: "Rejected", value: "inactive" },
];

type IEvent = ChangeEvent<HTMLInputElement | HTMLTextAreaElement>;

type FilterPanelProps = {
  setIsCard: Dispatch<SetStateAction<boolean>>;
  isCard: boolean;
  fields: string[];
  setFields: (arr: string[]) => void;
  fieldAllList: string[];
  limitFields?: number;
};

export const FilterPanel: React.FC<FilterPanelProps> = ({
  isCard,
  setIsCard,
  fields,
  setFields,
  fieldAllList,
  limitFields,
}) => {
  const [searchParams, setSearchParam] = useSearchParams();
  const [showFilterBlock, setShowFilterBlock] = useState(false);
  const requestsList = useSelector(selectCompanyRequestList) || [];

  const closeOrderFieldChangeBlock = () => {
    setShowFilterBlock(false);
  };
  const changeCardStatus = () => {
    setIsCard(!isCard);
  };

  const onSearchChange = (event: IEvent) => {
    setSearchParam(changeToParams("search", event.target.value, searchParams));
  };

  const statusChange = async (status: string) => {
    setSearchParam(changeToParams("status", status, searchParams));
  };
  const clientChange = (clientType: string) => {
    setSearchParam(changeToParams("client", clientType, searchParams));
  };

  return (
    <div className={styles.filter}>
      <div className={styles.filter__search}>
        <SearchFieldNew
          placeholder="Search..."
          onChange={onSearchChange}
          fullWidth
        />
      </div>
      <div className={styles.filter__cont}>
        <AsyncSelectField
          placeholder="All clients"
          options={changeCompanyRequestsOptions(requestsList)}
          theme="secondary"
          onChange={(value) => clientChange(value as string)}
          containerClassName={styles.filter__item}
        />
      </div>
      <div className={styles.filter__cont}>
        <SelectField
          isClearable
          placeholder="All State"
          options={statuses}
          theme="secondary"
          name="status"
          onChange={(value) => statusChange(value as string)}
          containerClassName={styles.filter__item}
        />
      </div>
      <div className={cn(styles.filter__date)}>
        <DateRange />
      </div>
      <div className={styles.filter__actions}>
        <IconButton
          icon={CardIcon}
          color="none"
          containerClassName={cn(
            styles.filter__icons,
            isCard && styles.filter__icons_active
          )}
          onClick={changeCardStatus}
        />
        <IconButton
          icon={FilterIcon}
          color="none"
          containerClassName={styles.filter__icons}
          onClick={() => setShowFilterBlock(!showFilterBlock)}
        />
      </div>
      {showFilterBlock && (
        <FilterBlock
          onClose={closeOrderFieldChangeBlock}
          orderFields={fields}
          setOrderFields={setFields}
          allFields={fieldAllList}
          limit={limitFields}
        />
      )}
    </div>
  );
};
