import Layout from "Components/Layout/Layout";
import React from "react";
import { CompanyUpdate } from "Containers/Company/CompanyUpdate";
import { useBreadcrumb } from "Shared/Hooks/use-breadcrumb";
import { useLocation } from "react-router";
import { getCompanyBreadCrumbs } from "Shared/Utils/company";

export const CompanyUpdatePage: React.FC = () => {
  const location = useLocation();

  useBreadcrumb({ links: getCompanyBreadCrumbs("update", location.pathname) });

  return (
    <Layout>
      <CompanyUpdate />
    </Layout>
  );
};
