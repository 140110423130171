import React from "react";
import Layout from "Components/Layout/Layout";
import UnitTypeAdd from "Containers/UnitType/UnitTypeAdd/UnitTypeAdd";
import SettingsLayout from "Components/Settings/Layout/Layout";
import { BreadcrumbItem } from "Components/Breadcrumb/Breadcrumb";
import { useBreadcrumb } from "Shared/Hooks/use-breadcrumb";

export const UnitTypeAddPage: React.FC = () => {
  const breads: BreadcrumbItem[] = [
    { title: "Setting", link: "/settings/unit-settings/unit-types" },
    { title: "Unit Types", link: "/settings/unit-settings/unit-types" },
    { title: `Create New Unit Type`, link: "" },
  ];
  useBreadcrumb({ links: breads });

  return (
    <Layout>
      <SettingsLayout>
        <UnitTypeAdd />
      </SettingsLayout>
    </Layout>
  );
};
