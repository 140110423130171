import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "Service/api/api";
import { readObj } from "Service/storage";
import {
  IDispatchCallback,
  IDispatchCallbackWithId,
  IDispatchCallbackWithoutData,
} from "Shared/Types/common";
import {
  IActionItemPartSearchParams,
  IOrderSearchParams,
  IOrderUpdate,
  IOrderUpdateByField,
} from "Shared/Types/order";
import { IOrderAdd } from "Shared/Types/orderAdd";
import { errorHandler, formatBackend } from "Shared/Utils/app";

const getOrderList = createAsyncThunk(
  "order/getOrderList",
  async (params: IOrderSearchParams | null) => {
    const shop = readObj("current_shop");
    try {
      const response = await api.order.getOrderList({
        ...params,
        on_hold: false,
        shop: shop?.id,
      });
      return response.data;
    } catch (error) {
      if (error instanceof Error) {
        errorHandler(error);
      }
      throw error;
    }
  }
);

const getOnHoldOrderList = createAsyncThunk(
  "order/getOnHoldOrderList",
  async (params: IOrderSearchParams | null) => {
    try {
      const shop = readObj("current_shop");
      const response = await api.order.getOrderList({
        ...params,
        on_hold: true,
        shop: shop?.id,
      });
      return response.data;
    } catch (error) {
      if (error instanceof Error) {
        errorHandler(error);
      }
      throw error;
    }
  }
);

const getOrderListByEmployee = createAsyncThunk(
  "order/getOrderListByEmployee",
  async (params: IOrderSearchParams | null) => {
    const shop = readObj("current_shop");
    try {
      const response = await api.order.getOrderList({
        ...params,
        shop: shop?.id,
      });
      return response.data;
    } catch (error) {
      if (error instanceof Error) {
        errorHandler(error);
      }
      throw error;
    }
  }
);

const getOrder = createAsyncThunk("order/getOrder", async (id: string) => {
  try {
    const response = await api.order.getOrder(id);
    return response.data;
  } catch (error) {
    if (error instanceof Error) {
      errorHandler(error);
    }
    throw error;
  }
});

const createOrder = createAsyncThunk(
  "order/createOrder",
  async ({ data, onSuccess }: IDispatchCallback<IOrderAdd>) => {
    try {
      const values = {
        ...data,
        action_items: JSON.stringify(
          data.action_items.map((item) => ({
            ...item,
            technician: item.technician?.id,
            labor_rate: item.labor_rate?.id,
            service: item.service?.id,
            complaint:
              typeof item.complaint === "string"
                ? item.complaint
                : item.complaint?.name,
          }))
        ),
        lead_technician: data.lead_technician?.id,
        customer: data.customer?.id,
        service_requester: data.service_requester?.id,
        unit: data.unit?.id,
        availability_date: formatBackend(data.availability_date || new Date()),
      };
      const formData = new FormData();
      Object.entries(values).forEach(([key, value]) => {
        if (value instanceof Array && key === "order_gallery") {
          data.order_gallery?.length &&
            data.order_gallery?.forEach((file) => {
              formData.append(key, file);
            });
        } else if (value instanceof Array && key === "order_file") {
          data.order_file?.length &&
            data.order_file?.forEach((file) => {
              formData.append(key, file);
            });
        } else if (value !== undefined && value !== null) {
          formData.append(key, String(value));
        }
      });
      const response = await api.order.addOrder(formData);
      onSuccess?.();
      return response.data;
    } catch (error) {
      if (error instanceof Error) {
        errorHandler(error, true);
      }
      throw error;
    }
  }
);

const updateOrder = createAsyncThunk(
  "order/updateOrder",
  async ({
    id,
    data,
    isFile,
    onSuccess,
  }: IDispatchCallbackWithId<IOrderUpdate>) => {
    try {
      let response;
      if (isFile) {
        const formData = new FormData();
        Object.entries(data).forEach(([key, value]) => {
          if (value instanceof Array && key === "order_gallery") {
            data.order_gallery?.length &&
              data.order_gallery?.forEach((file) => {
                if (file instanceof File) {
                  formData.append(key, file);
                }
              });
          } else if (value instanceof Array && key === "order_file") {
            data.order_file?.length &&
              data.order_file?.forEach((file) => {
                if (file instanceof File) {
                  formData.append(key, file);
                }
              });
          } else if (value !== undefined && value !== null) {
            formData.append(key, String(value));
          }
        });
        response = await api.order.updateOrder(id, formData);
      } else {
        response = await api.order.updateOrder(id, data);
      }
      onSuccess?.();
      return response.data;
    } catch (error) {
      if (error instanceof Error) {
        errorHandler(error, true);
      }
      throw error;
    }
  }
);

const deleteOrder = createAsyncThunk(
  "order/deleteOrder",
  async ({ id, onSuccess }: IDispatchCallbackWithoutData) => {
    try {
      const response = await api.order.deleteOrder(id);
      onSuccess?.();
      return response.data;
    } catch (error) {
      if (error instanceof Error) {
        errorHandler(error, true);
      }
      throw error;
    }
  }
);

const getOrderStatusList = createAsyncThunk(
  "order/getOrderStatusList",
  async () => {
    try {
      const response = await api.order.getOrderStatuses();
      return response.data;
    } catch (error) {
      if (error instanceof Error) {
        errorHandler(error);
      }
      throw error;
    }
  }
);

const getOrderCountByStatus = createAsyncThunk(
  "order/getOrderCountByStatus",
  async () => {
    const shop = readObj("current_shop");
    try {
      const response = await api.order.getOrderCountByStatus({
        shop: shop.id,
      });
      return response.data;
    } catch (error) {
      if (error instanceof Error) {
        errorHandler(error);
      }
      throw error;
    }
  }
);

const updateOrderByField = createAsyncThunk(
  "order/updateOrderByField",
  async ({
    id,
    data: { field, value },
    onSuccess,
  }: IDispatchCallbackWithId<IOrderUpdateByField>) => {
    try {
      const response = await api.order.updateOrder(id, { [field]: value });
      onSuccess?.();
      return response.data;
    } catch (error) {
      if (error instanceof Error) {
        errorHandler(error, true);
      }
      throw error;
    }
  }
);

const getOrderActionItemParts = createAsyncThunk(
  "order/getOrderActionItemPartsList",
  async (params: IActionItemPartSearchParams) => {
    try {
      const response = await api.order.getOrderActionItemParts(params);
      return response.data;
    } catch (error) {
      if (error instanceof Error) {
        errorHandler(error);
      }
      throw error;
    }
  }
);

const getOrderPartStatus = createAsyncThunk(
  "order/ getOrderPartStatus",
  async () => {
    try {
      const respons = await api.order.getOrderPartStatusList();
      return respons.data;
    } catch (error) {
      if (error instanceof Error) {
        errorHandler(error);
      }
      throw error;
    }
  }
);
export {
  getOrderList,
  getOrder,
  createOrder,
  updateOrder,
  deleteOrder,
  getOrderStatusList,
  getOrderCountByStatus,
  getOnHoldOrderList,
  updateOrderByField,
  getOrderListByEmployee,
  getOrderActionItemParts,
  getOrderPartStatus,
};
