import { yupResolver } from "@hookform/resolvers/yup";
import { Grid } from "@mui/material";
import Form from "Components/UI/Form/Form";
import InputField from "Components/UI/Inputs/InputField";
import {
  IUnitType,
  IUnitTypeAdd,
  IUnitTypeUpdate,
} from "Shared/Types/unit-type";
import React from "react";
import { useForm } from "react-hook-form";
import { ObjectSchema } from "yup";
import styles from "./UnitTypeForm.module.scss";
import Button from "Components/UI/Button/Button";

type UnitTypeFormProps = {
  onSubmit: (values: IUnitTypeAdd | IUnitTypeUpdate) => void;
  schema: ObjectSchema<IUnitTypeAdd | IUnitTypeUpdate>;
  unitType?: IUnitType;
};

const UnitTypeForm: React.FC<UnitTypeFormProps> = ({
  onSubmit,
  schema,
  unitType,
}) => {
  const { handleSubmit, control } = useForm({
    resolver: yupResolver(schema),
    defaultValues: unitType,
  });
  return (
    <Form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
      <div className={styles.block}>
        <Grid container>
          <Grid item xs={12}>
            <InputField
              name="name"
              control={control}
              withController
              label="Name"
              required
            />
          </Grid>
          <Grid item xs={12}>
            <InputField
              name="description"
              control={control}
              withController
              label="Description"
            />
          </Grid>
        </Grid>
      </div>
      <div className={styles.form__action}>
        {unitType ? (
          <Button variant="outlined" type="submit">
            Save
          </Button>
        ) : (
          <Button type="submit">Create</Button>
        )}
      </div>
    </Form>
  );
};
export default UnitTypeForm;
