import Layout from "Components/Layout/Layout";
import SettingsLayout from "Components/Settings/Layout/Layout";
import CategoryAdd from "Containers/Settings/Part/Categories/CategoryAdd/CategoryAdd";
import React from "react";
import { BreadcrumbItem } from "Components/Breadcrumb/Breadcrumb";
import { useBreadcrumb } from "Shared/Hooks/use-breadcrumb";

export const CategoryAddPages: React.FC = () => {
  const breads: BreadcrumbItem[] = [
    { title: "Setting", link: "/settings/part-settings/categories" },
    { title: "Categories", link: "/settings/part-settings/categories" },
    {
      title: "Create New Category",
      link: "",
    },
  ];
  useBreadcrumb({ links: breads });

  return (
    <Layout>
      <SettingsLayout>
        <CategoryAdd />
      </SettingsLayout>
    </Layout>
  );
};
