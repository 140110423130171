import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router";
import { useSelector } from "react-redux";
import {
  removeCurrentSupplyRate,
  selectSupplyRate,
} from "Redux/SupplyRate/slice";
import { useAppDispatch } from "Redux/store";
import { getSupplyRate, updateSupplyRate } from "Redux/SupplyRate/reducer";
import { notifySuccess } from "Components/Notify/notify";
import styles from "Containers/Settings/Rate/LaborRate/LaborRateUpdate/LaborRateUpdate.module.scss";
import SupplyForm from "Components/Settings/SupplyRate/Form/SupplyForm";
import { ISupplyRateUpdate } from "Shared/Types/supplyRate";
import { supplyRateScheme } from "Shared/Utils/supplyRate";

const SupplyRateUpdate = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const supplyRate = useSelector(selectSupplyRate);
  const dispatch = useAppDispatch();

  const back = () => {
    navigate("/settings/rate-settings/supply-rate");
  };

  const editSupplyRate = async (values: ISupplyRateUpdate) => {
    const onSuccess = () => {
      notifySuccess("Updated SupplyRate!");
      back();
    };
    if (supplyRate?.id) {
      await dispatch(
        updateSupplyRate({ id: supplyRate.id, data: values, onSuccess }),
      );
    }
  };

  useEffect(() => {
    if (id) {
      dispatch(getSupplyRate(id));
    }
    return () => {
      dispatch(removeCurrentSupplyRate);
    };
  }, []);

  return (
    <div className={styles.labor}>
      {supplyRate && (
        <SupplyForm
          supplyRate={supplyRate}
          onSubmit={editSupplyRate}
          scheme={supplyRateScheme}
        />
      )}
    </div>
  );
};

export default SupplyRateUpdate;
