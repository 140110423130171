import React, { useEffect } from "react";
import { Grid } from "@mui/material";
import Block from "Components/UI/Block/Block";
import { ObjectSchema } from "yup";
import { FormProvider, useFieldArray, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import Form from "Components/UI/Form/Form";
import Button from "Components/UI/Button/Button";
import { useAppDispatch } from "Redux/store";
import styles from "./EstimateForm.module.scss";
import EstimateCreate from "./EstimateCreate/EstimateCreate";
import ComplaintForm from "./ComplaintForm/ComplaintForm";
import EstimateInvoiced from "Components/Order/Estimate/EstimateForm/EstimateInvoiced/EstimateInvoiced";
import { removeCurrentLaborRate } from "Redux/LaborRate/slice";
import EstimateConvertToSoModal from "Components/Order/Estimate/EstimateForm/EstimateConvertToSOModal/EstimateConvertToSOModal";
import { useNavigate, useParams } from "react-router";
import {
  getEstimateComplaintServiceDefaultValues,
  getEstimateDefaultValues,
} from "Shared/Utils/estimate";
import { IEstimateOrderAdd, IEstimatesOrder } from "Shared/Types/estimate";
import {
  deleteEstimateService,
  getEstimateOrder,
} from "Redux/Estimate/reducer";
import cn from "classnames";
import { removeCurrentEstimate } from "Redux/Estimate/slice";

type EstimateFormProps = {
  currentEstimate?: IEstimatesOrder | null;
  schema: ObjectSchema<IEstimateOrderAdd>;
  defaultValues?: IEstimateOrderAdd;
  isDetails?: boolean;
  complaintLength?: number;
};

const EstimateForm: React.FC<EstimateFormProps> = ({
  schema,
  defaultValues,
  currentEstimate,
  isDetails = false,
  complaintLength,
}) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const params = useParams();

  const methods = useForm({
    resolver: yupResolver(schema),
    defaultValues: defaultValues ?? getEstimateDefaultValues(),
  });
  const { fields, append, remove } = useFieldArray({
    control: methods.control,
    name: "action_items",
  });

  const addNewComplaint = () => {
    append(getEstimateComplaintServiceDefaultValues());
    dispatch(removeCurrentLaborRate());
    if (isDetails && params.id) {
      dispatch(getEstimateOrder(params.id));
    }
  };

  const deleteComplaint = async (index: number, id?: string) => {
    try {
      if (id) {
        await dispatch(deleteEstimateService({ id }));
      }
      remove(index);
    } catch (e) {
      console.error(e);
    }
  };

  const handleToSave = () => {
    navigate("/orders/estimates");
  };

  useEffect(() => {
    if (!isDetails) dispatch(removeCurrentEstimate());
  }, []);

  return (
    <FormProvider {...methods}>
      <Form>
        <Block className={cn(isDetails && styles.form__details)}>
          {!isDetails && <EstimateCreate append={append} />}
          {currentEstimate && (
            <>
              <Grid container>
                <div
                  className={cn(
                    styles.form__complaints,
                    styles.form__complaints__details,
                  )}
                >
                  {fields &&
                    fields.map((field, index) => (
                      <ComplaintForm
                        key={field.id}
                        complaintIndex={index}
                        deleteComplaint={deleteComplaint}
                        complaintLength={fields.length + (complaintLength || 0)}
                        serviceLength={complaintLength || 0}
                        isDetails={isDetails}
                      />
                    ))}
                  <div className={styles.form__complaints__new}>
                    <span onClick={addNewComplaint}>
                      + Add another complaint
                    </span>
                  </div>
                </div>
              </Grid>
              <Grid container display="flex">
                <EstimateInvoiced isDetails={isDetails} />
              </Grid>
            </>
          )}

          <Grid container justifyContent="end" mt="10px" gap={4}>
            <Grid item>
              <Button onClick={handleToSave}>Save</Button>
            </Grid>
            <Grid item>
              <Button>Add misc change</Button>
            </Grid>
            <Grid item>
              <EstimateConvertToSoModal />
            </Grid>
          </Grid>
        </Block>
      </Form>
    </FormProvider>
  );
};

export default EstimateForm;
