import {
  AllOrdersIcon,
  EstimatesIcon,
  OnHoldIcon,
  HistoryIcon,
} from "Assets/index";
import FilterCard from "Components/Cards/FilterCard/FilterCard";
import React from "react";
import styles from "./OrderFilter.module.scss";
import { Grid } from "@mui/material";

type OrderFilterProps = {
  orderTotal?: number;
  onHoldTotal?: number;
};

const OrderFilter: React.FC<OrderFilterProps> = ({
  orderTotal = 0,
  onHoldTotal = 0,
}) => {
  return (
    <Grid className={styles.filter}>
      <FilterCard
        title="All orders"
        description={`As of today ${orderTotal} orders`}
        icon={AllOrdersIcon}
        color="blue"
        link="/orders"
      />
      <FilterCard
        title="Estimates"
        description="As of today 126 orders"
        icon={EstimatesIcon}
        color="green"
        link="/orders/estimates"
      />
      <FilterCard
        title="On hold"
        description={`As of today ${onHoldTotal || 0} orders`}
        icon={OnHoldIcon}
        color="orange"
        link="/orders/on-hold"
      />
      <FilterCard
        title="History"
        icon={HistoryIcon}
        color="ghost"
        link="/orders/history"
      />
    </Grid>
  );
};
export default OrderFilter;
