import React, { useRef } from "react";
import styles from "./More.module.scss";
import { Icon } from "@mui/material";
import {
  CardIcon,
  ColomIcon,
  DeleteIcon,
  EditIcon,
  ThreeDotsIcon,
} from "Assets/index";
import Portal from "Components/Portal/Portal";

type MoreProps = {
  isRow?: boolean;
  setIsRow?: (isRow: boolean) => void;
  openDelete: () => void;
  openMore: boolean;
  setOpenMore: (open: boolean) => void;
  editClick?: () => void;
  withOutEdit?: boolean;
};

const More: React.FC<MoreProps> = ({
  isRow,
  setIsRow,
  openDelete,
  openMore,
  setOpenMore,
  editClick,
  withOutEdit,
}) => {
  const buttonRef = useRef<HTMLDivElement>(null);

  const portalPosition = () => {
    if (buttonRef.current) {
      const rect = buttonRef.current.getBoundingClientRect();
      const scroll = scrollY ? scrollY + 25 : 0;
      if (innerWidth - rect.left < 40) {
        return { top: rect.top - 20 + scroll, left: rect.left - 100 };
      }
      return { top: rect.top - 20 + scroll, left: rect.left + 20 };
    }
    return { top: 0, left: 0 };
  };

  return (
    <div className={styles.more}>
      <div
        className={styles.more__icon}
        ref={buttonRef}
        onClick={(event) => {
          event.stopPropagation();
          setOpenMore(!openMore);
        }}
      >
        <Icon component={ThreeDotsIcon} />
      </div>

      {openMore && (
        <Portal position={portalPosition()}>
          <div className={styles.more__content}>
            <div
              className={styles.more__content__item}
              onClick={(event) => {
                event.stopPropagation();
                openDelete();
              }}
            >
              <Icon component={DeleteIcon} /> Delete
            </div>
            {!withOutEdit && (
              <div
                className={styles.more__content__item}
                onClick={(event) => {
                  event.stopPropagation();
                  editClick?.();
                }}
              >
                <Icon component={EditIcon} /> Edit
              </div>
            )}
            {setIsRow && (
              <div
                className={styles.more__content__item}
                onClick={(event) => {
                  event.stopPropagation();
                  setIsRow(!isRow);
                }}
              >
                {isRow ? (
                  <>
                    <Icon component={ColomIcon} /> Coloms
                  </>
                ) : (
                  <>
                    <Icon component={CardIcon} /> Card
                  </>
                )}
              </div>
            )}
          </div>
        </Portal>
      )}
    </div>
  );
};
export default More;
