import React, { useEffect } from "react";
import styles from "./ComponentSystemContainer.module.scss";
import { Component } from "Containers/ComponentSystem/Component/Component";
import { useSelector } from "react-redux";
import { selectComponentList } from "Redux/Component/componentSlice";
import { useAppDispatch } from "Redux/store";
import { getComponentList } from "Redux/Component/reducers";
import { useNavigate } from "react-router";
import IconButton from "Components/UI/IconButton/IconButton";
import { PlusIcon } from "Assets/index";
import Block from "Components/UI/Block/Block";
import { getSystemList } from "Redux/System/reducers";

export const ComponentSystemContainer: React.FC = () => {
  const componentList = useSelector(selectComponentList);
  const navigate = useNavigate();

  const dispatch = useAppDispatch();
  const goToCreate = () => {
    navigate("/settings/component-system/new");
  };

  const [expanded, setExpanded] = React.useState<string | boolean>("");
  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      if (panel) {
        dispatch(getSystemList({ component: panel }));
      }
      setExpanded(newExpanded ? panel : false);
    };

  useEffect(() => {
    dispatch(getComponentList());
  }, []);

  return (
    <Block className={styles.component}>
      <div className={styles.component__addBtn}>
        <IconButton
          onClick={goToCreate}
          label="Create Component"
          width={30}
          height={30}
          icon={PlusIcon}
        />
      </div>
      <div className={styles.component__head}>
        <div className={styles.component__head__number}>#</div>
        <div className={styles.component__head__number}>Component & System</div>
        <div
          style={{ marginRight: "20px" }}
          className={styles.component__head__actions}
        >
          Actions
        </div>
      </div>

      {componentList?.map((component, index) => (
        <Component
          expanded={expanded}
          handleChange={handleChange}
          key={component.id}
          component={component}
          index={index}
        />
      ))}
    </Block>
  );
};
