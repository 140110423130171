import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "Redux/store";
import {
  addSupplyRate,
  deleteSupplyRate,
  getSupplyRate,
  getSupplyRateList,
  updateSupplyRate,
} from "Redux/SupplyRate/reducer";
import { ISupplyRate } from "Shared/Types/supplyRate";

interface ISupplyRateState {
  loading: boolean;
  supplyRateList?: ISupplyRate[];
  supplyRate?: ISupplyRate;
}

const initialState: ISupplyRateState = {
  loading: false,
  supplyRateList: [],
  supplyRate: undefined,
};

const SupplyRateSlice = createSlice({
  name: "supplyRate",
  initialState,
  reducers: {
    removeCurrentSupplyRate(state) {
      state.supplyRate = undefined;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getSupplyRateList.pending, (state) => {
      state.loading = true;
      state.supplyRateList = [];
    });
    builder.addCase(getSupplyRateList.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(getSupplyRateList.fulfilled, (state, action) => {
      state.loading = false;
      state.supplyRateList = action.payload.results;
    });

    builder.addCase(getSupplyRate.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getSupplyRate.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(getSupplyRate.fulfilled, (state, action) => {
      state.loading = false;
      state.supplyRate = action.payload;
    });

    builder.addCase(addSupplyRate.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(addSupplyRate.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(addSupplyRate.fulfilled, (state) => {
      state.loading = false;
    });

    builder.addCase(deleteSupplyRate.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(deleteSupplyRate.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(deleteSupplyRate.fulfilled, (state) => {
      state.loading = false;
    });

    builder.addCase(updateSupplyRate.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateSupplyRate.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(updateSupplyRate.fulfilled, (state) => {
      state.loading = false;
    });
  },
});

export const { removeCurrentSupplyRate } = SupplyRateSlice.actions;

export const selectSupplyRateList = (state: RootState) =>
  state.supplyRate.supplyRateList;
export const selectSupplyRate = (state: RootState) =>
  state.supplyRate.supplyRate;

export const selectSupplyRateLoading = (state: RootState) =>
  state.supplyRate.loading;

export default SupplyRateSlice;
