import React from "react";
import styles from "./StatusView.module.scss";
import cn from "classnames";

export type IStatusView = {
  title: string;
  color: string;
};

type StatusViewProps = IStatusView & {
  className?: string;
};

const StatusView: React.FC<StatusViewProps> = ({ title, color, className }) => {
  return (
    <div className={cn(styles.status, styles[`status__${color}`], className)}>
      {title === "None" ? "" : title}
    </div>
  );
};
export default StatusView;
