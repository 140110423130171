import React, { ElementType } from "react";
import styles from "./FilterCard.module.scss";
import Icon from "Components/UI/Icon/Icon";
import cn from "classnames";
import { useLocation, useNavigate } from "react-router";
import { isLinkExcluded, isPathMatched } from "Shared/Utils/estimate";

export type FilterStatusColor =
  | "blue"
  | "green"
  | "orange"
  | "ghost"
  | "red"
  | "green_approve";

type FilterCardProps = {
  color?: FilterStatusColor;
  icon: ElementType | string | File;
  title: string;
  description?: string;
  link?: string;
};

const FilterCard: React.FC<FilterCardProps> = ({
  color = "ghost",
  icon,
  title,
  description = "",
  link,
}) => {
  const navigate = useNavigate();
  const location = useLocation();

  const isActive = () => {
    const rootPath = ["/", "/orders", "/parts"];
    if (!link) return false;

    if (isPathMatched(link, location.pathname)) {
      return location.pathname.startsWith(link) && !rootPath.includes(link);
    }

    return isLinkExcluded(link);
  };

  const goToPage = () => {
    if (link) {
      navigate(link);
    }
  };
  return (
    <div
      className={cn(
        styles.card,
        styles[`card_${color}`],
        isActive() && styles.card_active,
      )}
      onClick={goToPage}
    >
      {!(icon instanceof File) &&
        (typeof icon == "string" ? (
          <img
            src={icon}
            style={{ width: "60px", height: "60px" }}
            className={styles.card__avatar}
            alt="profile image"
          />
        ) : (
          <Icon component={icon} color="none" />
        ))}

      <div className={styles.card__text}>
        <h2>{title}</h2>
        <p>{description}</p>
      </div>
    </div>
  );
};
export default FilterCard;
