import React, { Fragment } from "react";
import styles from "./InvoiceActoinItem.module.scss";
import Table from "Components/UI/Table/Table";
import { invoiceActionItemPartTableHead } from "Shared/Constants/action-item";
import { renderCellAIPart } from "Components/Order/Invoice/renderCell/partRenderCell";
import { getHeadInvoiceAI } from "Shared/Utils/invoice";
import { IInvoiceAI } from "Shared/Types/invoice";

type InvoiceActionItemProps = {
  actionItem: IInvoiceAI;
};

const InvoiceActionItem: React.FC<InvoiceActionItemProps> = ({
  actionItem,
}) => {
  return (
    <Fragment>
      <div className={styles.complaint}>
        {getHeadInvoiceAI(actionItem)?.map(({ title, value }) => (
          <p className={styles.complaint__item} key={title}>
            <span className={styles.title}>{title}</span>
            {value}
          </p>
        ))}
      </div>

      <div>
        <Table
          columns={invoiceActionItemPartTableHead}
          data={actionItem.parts || []}
          renderCell={renderCellAIPart}
          isInDetailPage
        />

        <hr className={styles.line} />

        <ul className={styles.head}>
          <li className={styles.head__item1}>Labor: {actionItem.labor_name}</li>
          <li className={styles.head__item2}>{actionItem.hours}</li>
          <li className={styles.head__item2}>{actionItem.labor_rate}</li>
          <li className={styles.head__item2}>{actionItem.labor_amount}</li>
        </ul>

        <ul className={styles.head}>
          <li className={styles.head__item1} />
          <li className={styles.head__item2} />
          <li className={styles.head__item2}>Subtotal:</li>
          <li className={styles.head__item2}>$ {actionItem.subTotal}</li>
        </ul>
      </div>
    </Fragment>
  );
};
export default InvoiceActionItem;
