import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectUnitList } from "Redux/Unit/slice";
import { useAppDispatch } from "Redux/store";
import { deleteUnit, getUnitsList } from "Redux/Unit/reducer";
import DeleteModal from "Components/UI/DialogWindow/DeleteModal";
import { notifySuccess } from "Components/Notify/notify";
import ListLayout from "Components/Layout/ListLayout/ListLayout";
import { unitFields } from "Shared/Constants/units";
import { useNavigate, useSearchParams } from "react-router-dom";
import Card from "Components/UI/Card/Card/Card";
import Row from "Components/UI/Card/Row/Row";
import { IUnit } from "Shared/Types/unit";
import { getUnitCardData } from "Shared/Utils/units";

const UnitList: React.FC = () => {
  const units = useSelector(selectUnitList) || [];

  const dispatch = useAppDispatch();
  const [showDelete, setShowDelete] = useState(false);
  const [deleteId, setDeleteId] = useState<string | null>();
  const [fields, setFields] = useState(unitFields);
  const [isCard, setIsCard] = useState<boolean>(true);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const handleDialogClose = () => {
    setShowDelete(false);
    setDeleteId(null);
  };

  const goToDetailPage = (id: string) => {
    navigate(`/units/${id}`);
  };

  const goToEditPage = (id: string) => {
    navigate(`/units/edit/${id}`);
  };
  const handleDialogOpen = (id: string) => {
    setShowDelete(true);
    setDeleteId(id);
  };

  const handleDeleteUnit = async () => {
    const onSuccess = () => {
      notifySuccess("Unit deleted");
      dispatch(getUnitsList(null));
    };
    if (deleteId) {
      await dispatch(deleteUnit({ id: deleteId, onSuccess }));
      handleDialogClose();
    }
  };

  const props = (unit: IUnit) => {
    return {
      openDeleteModal: handleDialogOpen,
      fields: fields,
      unit: unit,
      onClick: goToDetailPage,
      cardData: getUnitCardData(unit),
      editClick: goToEditPage,
      dataId: unit.id,
      isNeedStatus: false,
    };
  };
  useEffect(() => {
    dispatch(getUnitsList(null));
  }, []);

  useEffect(() => {
    dispatch(
      getUnitsList({
        customer: searchParams.get("customer"),
        type_of_chassis: searchParams.get("type_of_chassis"),
        search: searchParams.get("search"),
      })
    );
  }, [searchParams]);

  return (
    <ListLayout
      pagesNumber={1}
      setIsCard={setIsCard}
      isCard={isCard}
      fields={fields}
      fieldAllList={unitFields}
      setFields={setFields}
      filters={["search", "customer", "typeOfChassis"]}
    >
      {isCard
        ? units.map((unit) => <Card key={unit.id} {...props(unit)} />)
        : units.map((unit) => <Row key={unit.id} {...props(unit)} />)}
      <DeleteModal
        open={showDelete}
        handleClose={handleDialogClose}
        title="Delete"
        text="Are you sure...?"
        acceptTitle="Delete"
        accept={handleDeleteUnit}
      />
    </ListLayout>
  );
};
export default UnitList;
