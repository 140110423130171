import * as yup from "yup";
import { IActionItem, IOrder } from "Shared/Types/order";
import { format } from "./app";
import { IAccount } from "Shared/Types/auth";
import { ISupplyRate } from "Shared/Types/supplyRate";
import { ITaxRate } from "Shared/Types/taxRate";
import {
  IInvoiceAI,
  IInvoiceAIPart,
  IOrderInvoiceData,
} from "Shared/Types/invoice";
import { readObj } from "Service/storage";
import { IActionPart } from "Shared/Types/action-item-part";

export const orderInvoiceSchema = yup.object().shape({
  data: yup.string().required(),
  purchase_order: yup.number().required().positive().integer(),
  promise_to_pay: yup.number().required().positive().integer(),
  is_paid: yup.boolean().required(),
  shop: yup.string().required().uuid(),
  order: yup.string().required().uuid(),
  pdf_file: yup.mixed(),
});

const getOrderInfo = (order: IOrderInvoiceData) => {
  return [
    { title: "Invoice:", value: "" },
    { title: "Order # ", value: order.order_number },
    { title: "Created ", value: format(order.order_created) },
  ];
};

const getCustomerInfo = (order: IOrderInvoiceData) => {
  return [
    { title: "Bill To :", value: "" },
    {
      title: "",
      value: order.customer_company,
    },
    {
      title: "",
      value: order.customer_address,
    },
    {
      title: "",
      value: order.customer_address_2,
    },
    { title: "", value: order.customer_phone },
  ];
};
const getCompanyInfo = (order: IOrderInvoiceData) => {
  return [
    { title: "From: ", value: "" },
    { title: "", value: order.company_name },
    {
      title: "",
      value: order.company_phone,
    },
    {
      title: "",
      value: order.company_email,
    },
    { title: "", value: order?.company_address },
  ];
};
export const getInvoiceHead = (order: IOrderInvoiceData) => {
  return [getOrderInfo(order), getCustomerInfo(order), getCompanyInfo(order)];
};

export const getInvoiceAITotals = (currentActionItem: IActionItem) => {
  let totalAmount =
    (currentActionItem?.labor_rate?.rate || 0) *
    (currentActionItem?.invoice_hours || 0);

  currentActionItem?.action_item_part?.forEach((part) => {
    totalAmount += part.quantity * (part.selling_price || 0);
  });

  return totalAmount.toFixed(2);
};

export const getHeadInvoiceAI = (currentActionItem: IInvoiceAI) => {
  return [
    { title: "Reason for complaint:", value: currentActionItem?.complaint },
    { title: "Cause:", value: currentActionItem?.cause },
  ];
};

export const getOrderSubTotals = (actionItems: IActionItem[]) => {
  let subTotal = 0;
  actionItems.forEach((actionItem) => {
    subTotal += +getInvoiceAITotals(actionItem);
  });

  return subTotal.toFixed(2);
};

export const getOrderLaborTotal = (actionItems: IActionItem[]) => {
  let subTotal = 0;
  actionItems.forEach((actionItem) => {
    subTotal += +(actionItem.labor_rate?.rate || 0);
  });

  return subTotal.toFixed(2);
};

export const getOrderAcualTimes = (actionItems: IActionItem[]) => {
  let subTotal = 0;
  actionItems.forEach((actionItem) => {
    subTotal += actionItem.actual_time || 0;
  });

  return (subTotal / 3600).toFixed(2);
};

export const getOrderInvoiceTotal = (
  total: number,
  supplyRate?: ISupplyRate,
  taxRate?: ITaxRate
) => {
  return total + (supplyRate?.rate || 0) + (taxRate?.rate || 0);
};

export const getInvoiceRateFields = (orderData: IOrderInvoiceData) => {
  return [
    {
      title: "Labor: ",
      value: `$ ${orderData.labor}`,
    },
    { title: "Shop Supplies: ", value: `$ ${orderData.supply}` },
    {
      title: "Pre-Charge Subtotal: ",
      value: `$ 0`,
    },
    {
      title: orderData.tax_name || "Tax Rate",
      value: `$ ${orderData.tax_rate}`,
    },
    {
      title: "Total",
      value: `$ ${orderData.total}`,
      isGreen: true,
    },
  ];
};

export const getUnitInfo = (orderData: IOrderInvoiceData) => {
  return [
    {
      title: "Unit: ",
      value: orderData.unit_number,
      title2: " VIN:",
      value2: orderData.unit_vin,
    },
    { title: "License Plate: ", value: orderData.unit_license_plate },
    {
      title: "Chassis miles: ",
      value: `${orderData.chasses_miles} Miles`,
    },
    {
      title: "",
      value: orderData.unit_info,
    },
  ];
};

export const getOverviewInfo = (
  orderData: IOrderInvoiceData,
  orderHours?: number
) => {
  return [
    {
      title: "Authorized Amount: ",
      value: `$ ${orderData.total}`,
    },
    {
      title: "Hours (Inv. vs Actual): ",
      value: `${orderHours || 0} / ${orderData.actual_hours}`,
    },
  ];
};

const getPartsData = (parts: IActionPart[]) => {
  return parts.map(
    (part) =>
      ({
        id: part.id,
        name: part.description,
        quantity: part.quantity,
        price: part.selling_price || 0,
        amount: (part.quantity * (part.selling_price || 0)).toFixed(2),
      }) as IInvoiceAIPart
  );
};

const getActionItemsData = (actionItems: IActionItem[]) => {
  return actionItems.map(
    (item) =>
      ({
        id: item.id,
        cause: item.cause || "",
        complaint: item.complaint,
        labor_name: item.labor_rate?.name || "",
        labor_amount: (
          (item.labor_rate?.rate || 0) * item.invoice_hours
        ).toFixed(2),
        labor_rate: item.labor_rate?.rate || 0,
        parts: getPartsData(item.action_item_part || []),
        subTotal: getInvoiceAITotals(item),
        hours: item.invoice_hours,
      }) as IInvoiceAI
  );
};

export const getInvoiceData = (
  order: IOrder,
  actionItems: IActionItem[],
  profile: IAccount,
  supply?: ISupplyRate,
  taxRate?: ITaxRate
) => {
  const data = {} as IOrderInvoiceData;
  const shop = profile.shop ? profile.shop : readObj("current_shop");
  data.order_number = order.order_number;
  data.order_created = order.created_at || "";
  data.customer_company = order.customer?.company_name || "";
  data.customer_address = `${order.customer?.street_address || ""} ${
    order.customer?.street_address_2 || ""
  }`;
  data.customer_address_2 = `${order.customer?.city || ""} ${
    order.customer?.country || ""
  }`;
  data.customer_phone = order.customer?.phone || "";
  data.company_name = shop?.shop_name || "";
  data.company_address = `${shop?.physical_address || ""}`;
  data.company_email = `${shop?.email || ""}`;
  data.company_phone = shop?.phone || "";
  data.action_itmes = getActionItemsData(actionItems);
  data.unit_number = order.unit?.number || "";
  data.unit_license_plate = order.unit?.license_plate || "";
  data.unit_info = `${order.unit?.year || ""} ${order.unit?.brand || ""} ${
    order.unit?.model || ""
  }`;
  data.unit_vin = order.unit?.vin || "";
  data.chasses_miles = order.chassis_miles || 0;
  data.labor = getOrderLaborTotal(actionItems);
  data.supply = supply?.rate || 0;
  data.pre_charge = 0;
  data.tax_name = taxRate?.title || "";
  data.tax_rate = taxRate?.rate || 0;
  data.total = getOrderInvoiceTotal(
    +getOrderSubTotals(actionItems),
    supply,
    taxRate
  );
  data.authorize_total = 0;
  data.hours = order.invoice_hours || 0;
  data.actual_hours = getOrderAcualTimes(actionItems);

  return data;
};

export const checkInvioceCreated = (order: IOrder) => {
  return !order?.service_order_invoice;
};
