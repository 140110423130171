export const requestTableHeads = [
  { id: "status", label: "Status" },
  { id: "created_at", label: "Date" },
  { id: "first_name", label: "First Name" },
  { id: "last_name", label: "Last Name" },
  { id: "phone", label: "Phone Number" },
  { id: "email", label: "Email" },
  { id: "company_name", label: "Business name" },
  { id: "address", label: "Company address" },
  { id: "action", label: "Action" },
];

export const requestStatus = {
  waiting: "orange",
  active: "green",
  rejected: "red",
};

export const requestFieldList = [
  "Date",
  "First name",
  "Last name",
  "Business name",
  "Email",
  "Contact",
];

export const requestFieldAllList = [
  ...requestFieldList,
  "Legal name",
  "Address",
  "Status",
  "Website",
  "Fax",
];
