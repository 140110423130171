import React, { useState } from "react";
import styles from "./AddActionItem.module.scss";
import Modal from "Components/UI/Modal/Modal";
import ActionItemForm from "Components/Order/OrderDetail/ActionItemForm/ActoinItemForm";
import { useSelector } from "react-redux";
import { selectCurrentOrder } from "Redux/Order/slice";
import { useAppDispatch } from "Redux/store";
import { IActionItemAdd } from "Shared/Types/order";
import { createActionItem } from "Redux/ActionItem/reducer";
import { notifySuccess } from "Components/Notify/notify";
import { getOrder } from "Redux/Order/reducer";

type AddActionItemProps = {
  test?: string;
};

const AddActionItem: React.FC<AddActionItemProps> = () => {
  const [open, setOpen] = useState(false);
  const currenOrder = useSelector(selectCurrentOrder);
  const dispatch = useAppDispatch();
  const createNewActionItem = (values: IActionItemAdd) => {
    const onSuccess = () => {
      setOpen(false);
      notifySuccess("Action item is created");
      if (currenOrder) {
        dispatch(getOrder(currenOrder.id));
      }
    };
    dispatch(
      createActionItem({
        data: values,
        onSuccess,
      })
    );
  };
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <>
      <span className={styles.addActionItem} onClick={handleOpen}>
        + Add another action item
      </span>
      <Modal isClosed open={open} onClose={handleClose} title="Add Action item">
        {currenOrder && (
          <ActionItemForm
            orderId={currenOrder?.id}
            onSubmit={createNewActionItem}
            onCancel={handleClose}
          />
        )}
      </Modal>
    </>
  );
};
export default AddActionItem;
