import { client } from "Service/axios";
import { SearchResult } from "Shared/Types/common";
import {
  IUserSearchParams,
  IUser,
  IUserAdd,
  IUserUpdate,
} from "Shared/Types/user";

const USER_URL = "user/";

class User {
  getUserList(params: IUserSearchParams | null) {
    return client.get<SearchResult<IUser>>(USER_URL, { params });
  }
  getUser(id: string) {
    return client.get<IUser>(`${USER_URL}${id}/`);
  }
  addUser(data: IUserAdd | FormData) {
    return client.post(USER_URL, data, {
      headers: { "Content-Type": "multipart/form-data" },
    });
  }
  updateUser(id: string, data: IUserUpdate | FormData) {
    return client.patch(`${USER_URL}${id}/`, data, {
      headers: { "Content-Type": "multipart/form-data" },
    });
  }

  updateUserByField(id: string, data: IUserUpdate | FormData) {
    return client.patch(`${USER_URL}${id}/`, data, {
      headers: { "Content-Type": "multipart/form-data" },
    });
  }

  deleteUser(id: string) {
    return client.delete(`${USER_URL}${id}/`);
  }
  blockUser(id: string) {
    return client.post(`${USER_URL}${id}/block/`);
  }
}

export default User;
