import React, { FC, useEffect } from "react";
import SelectOrTextField from "Components/UI/SelectField/SelectOrTextField";
import { getPartList } from "Redux/Product/reducer";
import { selectPartList } from "Redux/Product/slice";
import { useAppDispatch } from "Redux/store";
import { IPart } from "Shared/Types/part";
import { useSelector } from "react-redux";
import { Controller, useFormContext } from "react-hook-form";

type PartSearchProps = {
  name: string;
  onChange?: (value?: IPart | string | null | number) => void;
  onBlur?: (value?: string | number) => void;
  label: string;
  value?: string;
  requered?: boolean;
};

const PartSearch: FC<PartSearchProps> = ({
  name,
  onChange,
  label,
  value,
  requered,
}) => {
  const dispatch = useAppDispatch();
  const partList = useSelector(selectPartList) || [];
  const { control, setValue } = useFormContext();
  const fetchParts = async (text?: string | null) => {
    dispatch(getPartList({ search: text || null }));
  };
  const getLabel = (part: IPart) => {
    if (name.includes("description")) {
      return part.description || "-";
    }
    return part.part_number || "-";
  };

  useEffect(() => {
    dispatch(getPartList({ search: value || null }));
  }, []);

  useEffect(() => {
    setValue(name, {
      label: control._defaultValues?.[name],
      value: control._defaultValues?.[name],
    });
  }, [control._defaultValues?.[name], value]);
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={{
        label: control._defaultValues?.[name],
        value: control._defaultValues?.[name],
      }}
      render={({ field }) => (
        <SelectOrTextField
          {...field}
          name={name}
          defaultValue={{
            label: control._defaultValues?.[name],
            value: control._defaultValues?.[name],
          }}
          label={label}
          options={partList.map((part) => ({
            label: getLabel(part),
            value: part.id,
          }))}
          value={field.value}
          onChange={(value) => {
            field.onChange(value);

            const cur = partList.find((part) => part.id === value);
            onChange?.(cur);
          }}
          labelColor="white"
          onInputChange={fetchParts}
          required={requered}
          isClearable
        />
      )}
    />
  );
};

export default PartSearch;
