import React, { useEffect, useState } from "react";
import { notifySuccess } from "Components/Notify/notify";
import DeleteModal from "Components/UI/DialogWindow/DeleteModal";
import { useAppDispatch } from "Redux/store";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import ListLayout from "Components/Layout/ListLayout/ListLayout";
import { useSearchParams } from "react-router-dom";
import { selectPartList, selectPartNumPage } from "Redux/Product/slice";
import { deletePart, getPartList } from "Redux/Product/reducer";
import { getPartCardData, getPartCardStatus } from "Shared/Utils/part";
import { partFields } from "Shared/Constants/part";
import Card from "Components/UI/Card/Card/Card";
import { IPart } from "Shared/Types/part";
import Row from "Components/UI/Card/Row/Row";

const PartList: React.FC = () => {
  const parts = useSelector(selectPartList) || [];
  const numPage = useSelector(selectPartNumPage);
  const dispatch = useAppDispatch();
  const [showDelete, setShowDelete] = useState(false);
  const [deleteId, setDeleteId] = useState<string | null>();
  const [isCard, setIsCard] = useState(true);
  const [fields, setFields] = useState(partFields);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const handleDialogClose = () => {
    setShowDelete(false);
    setDeleteId(null);
  };

  const handleDialogOpen = (id: string) => {
    setShowDelete(true);
    setDeleteId(id);
  };

  const handleDeletePart = async () => {
    if (deleteId) {
      await dispatch(
        deletePart({
          id: deleteId,
          onSuccess: () => {
            notifySuccess("Part deleted");
            handleDialogClose();
          },
        })
      );
    }
  };

  const goToDetailPage = (id: string) => {
    navigate(`/parts/edit/${id}`);
  };

  const goToEditPage = (id: string) => {
    navigate(`/parts/edit/${id}`);
  };

  const props = (part: IPart) => {
    return {
      openDeleteModal: handleDialogOpen,
      fields: fields,
      onClick: goToDetailPage,
      cardData: getPartCardData(part),
      editClick: goToEditPage,
      dataId: part.id,
      status: getPartCardStatus(part.status),
      isNeedStatus: true,
    };
  };

  useEffect(() => {
    dispatch(
      getPartList({
        search: searchParams.get("search"),
        page: +(searchParams.get("page") || 1),
      })
    );
  }, [searchParams]);

  return (
    <ListLayout
      pagesNumber={numPage || 1}
      isCard={isCard}
      setIsCard={setIsCard}
      fields={fields}
      setFields={setFields}
      filters={["search"]}
      fieldAllList={partFields}
    >
      {isCard
        ? parts.map((part) => <Card key={part.id} {...props(part)} />)
        : parts.map((part) => <Row key={part.id} {...props(part)} />)}
      <DeleteModal
        open={showDelete}
        handleClose={handleDialogClose}
        title="Delete"
        text="Are you sure...?"
        acceptTitle="Delete"
        accept={handleDeletePart}
      />
    </ListLayout>
  );
};
export default PartList;
