export const statsFilterFields = [ "date",'timespan'];
export const statsTableSideCol=[
    {
        label: "Completed Hours",
        id: "compoleted_hours",
        width: "20%",
      },
      {
        label: "Clocked Hours",
        id: "clocked_hours",
        width: "20%",
      },
      {
        label: "Efficiency ( Completed)",
        id: "efficiency_completed",
        width: "20%",
      },
      {
        label: "Invoiced Hours",
        id: "invoiced",
        width: "20%",
      },
      {
        label: "Efficiency (Invoiced)",
        id: "efficiency_invoiced",
        width: "20%",
      },
      {
        label: "SO Hours",
        id: "so_hours",
        width: "20%",
      },
      {
        label: "Utilization",
        id: "utilization",
        width: "20%",
      },
      {
        label: "Revenue Per Hour",
        id: "revenue",
        width: "20%",
      },
]
/// soon it will be deleted
export const statsData = {
    dates: ["10/16/2023", "10/17/2023", "10/18/2023","10/18/2023","10/19/2023","10/20/2023","10/21/2023" ],
    stats: [
      {
        label: "Completed Hours",
        values: [24.0, 30.9,24.0, 30.9, 15.8,16.5,1], 
        total: 54.9, 
        rank: 5, 
      },
      {
        label: "Clocked Hours",
        values: [24.0, 45], 
        total: 69.0,
        rank: 4, 
      },
      {
        label: "Efficiency (Completed)",
        values: [10.4, 15.2], 
        total: 25.6, 
        rank: 3,
      },
      {
        label: "Invoiced Hours",
        values: [2.0, 21.5], 
        total: 23.5, 
        rank: 2, 
      },
      {
        label: "Efficiency (Invoiced)",
        values: [8.3, 89.6], 
        total: 97.9, 
        rank: 1, 
      },
      {
        label: "SO Hours",
        values: [5, 10], 
        total: 15, 
        rank: null, 
      },
      {
        label: "Utilization",
        values: [50, 60], 
        total: 110, 
        rank: null, 
      },
      {
        label: "Revenue Per Hour",
        values: [1000, 2000], 
        total: 3000, 
        rank: null, 
      }
   
    ],
  };