import React, { useEffect, useState } from "react";
import Select from "react-select";
import cn from "classnames";
import styles from "./SelectField.module.scss";
import { customDarkStyles, customStyles } from "./styles";

export type OptionsProps = {
  label: string | null;
  value: string | number;
};

interface SelectOrTextFieldProps {
  options: OptionsProps[] | undefined;
  name: string;
  containerClassName?: string;
  theme?: "primary" | "secondary";
  labelColor?: string;
  label?: string; // Add a new prop for the label
  required?: boolean;
  onChange?: (value?: string | number) => void;
  onBlur?: (value?: string | number) => void;
  value?: string | number | null; // Add the value prop
  placeholder?: string;
  defaultValue?: OptionsProps;
  style?: React.CSSProperties;
  isSearchable?: boolean;
  textAlign?: "center" | "start";
  isClearable?: boolean;
  onInputChange?: (value: string | null) => void;
  isDisabled?: boolean;
}

const SelectOrTextField: React.FC<SelectOrTextFieldProps> = ({
  name,
  options = [],
  theme = "primary",
  label,
  isSearchable,
  textAlign = "start",
  isClearable,
  isDisabled,
  ...props
}) => {
  const [inputText, setInputText] = useState("");
  const [curOptions, setCurOptions] = useState(options);
  const [value, setValue] = useState<OptionsProps | null | undefined>(
    curOptions.find((option) => option.value === props.value)
  );
  useEffect(() => {
    setCurOptions(options);
  }, [options]);

  useEffect(() => {
    if (!props.value) {
      setValue(null);
      setInputText("");
    }
  }, [props.value]);

  return (
    <div
      className={cn(
        styles.select,
        theme === "primary" ? styles.select_primary : styles.select_secondary
      )}
    >
      {label && (
        <label
          className={styles.select__label}
          id={name}
          style={{ color: props.labelColor }}
        >
          {label}
          {props.required && <span className={styles.select__error}>*</span>}
        </label>
      )}
      <Select
        {...props}
        name={name}
        isSearchable={isSearchable}
        styles={
          theme === "secondary" ? customDarkStyles(textAlign) : customStyles
        }
        value={value}
        onInputChange={(value) => {
          setInputText(value);
          props.onInputChange?.(value);
        }}
        onChange={(selectedOption) => {
          setInputText(selectedOption?.label || "");
          setValue(selectedOption);
          props.onChange?.(selectedOption?.value);
        }}
        onBlur={() => {
          if (inputText) {
            const newValue = { value: inputText, label: inputText };
            setCurOptions([...options, newValue]);
            setValue(newValue);
            props.onBlur?.(inputText);
            props.onChange?.(inputText);
          }
        }}
        options={curOptions}
        isClearable={isClearable}
        components={{
          IndicatorSeparator: () => null,
        }}
        isDisabled={isDisabled}
      />
    </div>
  );
};

export default SelectOrTextField;
