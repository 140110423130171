import React, { useState } from "react";
import Modal from "Components/UI/Modal/Modal";
import Icon from "Components/UI/Icon/Icon";
import { CameraIcon, DeleteIcon, DocumentIcon, PhotoIcon } from "Assets/index";
import Checkbox from "Components/UI/Inputs/Checkbox";
import styles from "../ServiceOrderAdd.module.scss";
import Button from "Components/UI/Button/Button";
import CameraAndVideo from "./CameraAndVideo";
import { useFormContext } from "react-hook-form";

type SystemProps = {
  openModal: boolean;
  handleModal: () => void;
};

const Attachments: React.FC<SystemProps> = ({ openModal, handleModal }) => {
  const { setValue } = useFormContext();
  const [openCameraModal, setOpenCameraModal] = useState(false);
  const [selectedImages, setSelectedImages] = useState<File[]>([]);
  const [selectedDocs, setSelectedDocs] = useState<File[]>([]);

  const handleCameraModal = () => setOpenCameraModal(!openCameraModal);

  const uploadFiles = () => {
    handleModal();
    setValue("order_gallery", selectedImages);
    setValue("order_file", selectedDocs);
  };

  const onSelectImages = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files: File[] = Array.from(e.target.files || []);
    setSelectedImages((prevSelectedImages: File[]) => [
      ...prevSelectedImages,
      ...files,
    ]);
  };

  const handleRemoveImageInput = (index: number) => {
    const updatedImages = [...selectedImages];
    updatedImages.splice(index, 1);
    setSelectedImages(updatedImages);
  };

  const onSelectDocs = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files: File[] = Array.from(e.target.files || []);
    setSelectedDocs((prevSelectedDocs: File[]) => [
      ...prevSelectedDocs,
      ...files,
    ]);
  };

  const handleRemoveDocInput = (index: number) => {
    const updatedDocs = [...selectedDocs];
    updatedDocs.splice(index, 1);
    setSelectedDocs(updatedDocs);
  };

  const attachments = [
    {
      id: 1,
      icon: PhotoIcon,
      name: "order_gallery",
      onChange: onSelectImages,
      accept: "image/png,image/jpg,image/jpeg",
      title: "Choose a Photo",
    },
    {
      id: 2,
      icon: DocumentIcon,
      name: "order_file",
      onChange: onSelectDocs,
      accept: ".pdf,.doc,.docx,.xlsx",
      title: "Choose a File",
    },
  ];

  return (
    <>
      <Modal open={openModal} onClose={handleModal} title="Add Attachments">
        <div className={styles.images}>
          {selectedImages?.map((file, index) => (
            <div key={Math.random()} className={styles.images__img}>
              <Icon
                onClick={() => handleRemoveImageInput(index)}
                className={styles.delete_icon}
                component={DeleteIcon}
              />
              {file.type.startsWith("image/") ? (
                <img
                  className={styles.img}
                  src={URL.createObjectURL(file)}
                  alt="/"
                />
              ) : (
                <video
                  className={styles.img}
                  src={URL.createObjectURL(file)}
                  autoPlay
                ></video>
              )}
            </div>
          ))}
        </div>
        <ol className={styles.docs}>
          {selectedDocs?.map((doc, index) => (
            <div className={styles.docs__doc} key={Math.random()}>
              <li>{doc.name}</li>
              <Icon
                onClick={() => handleRemoveDocInput(index)}
                component={DeleteIcon}
              />
            </div>
          ))}
        </ol>
        <p style={{ color: "#959595" }}>
          You may upload IMG or PDF (max size is 5 MB)
        </p>
        <div className={styles.attachments}>
          <div className={styles.attachments__attachment}>
            <div style={{ gap: "7px" }} className={styles.flex}>
              <button
                onClick={handleCameraModal}
                className={styles.icon}
                style={{
                  position: "relative",
                  borderBottom: "none",
                  borderRight: "none",
                }}
              >
                <Icon
                  style={{ fontSize: "24px", color: "transparent" }}
                  color="transparent"
                  component={CameraIcon}
                />
              </button>
              <p style={{ marginTop: "7px" }}>Take a picture</p>
            </div>
            <Checkbox label="For Customer" name="mmkk" labelPosition="right" />
          </div>
          {attachments.map((attachment) => (
            <div key={attachment.id} className={styles.attachments__attachment}>
              <div style={{ gap: "7px" }} className={styles.flex}>
                <label
                  htmlFor={attachment.name}
                  className={styles.icon}
                  style={{ position: "relative" }}
                >
                  <Icon
                    style={{ fontSize: "24px", color: "transparent" }}
                    color="transparent"
                    component={attachment.icon}
                  />
                  <input
                    accept={attachment.accept}
                    multiple
                    onChange={attachment.onChange}
                    id={attachment.name}
                    type="file"
                    style={{ visibility: "hidden", position: "absolute" }}
                  />
                </label>
                <p style={{ marginTop: "7px" }}>{attachment.title}</p>
              </div>
              <Checkbox
                label="For Customer"
                name={attachment.accept}
                labelPosition="right"
              />
            </div>
          ))}
          <div onClick={uploadFiles} style={{ float: "right" }}>
            <Button variant="contained">Upload</Button>
          </div>
        </div>
      </Modal>
      <CameraAndVideo
        selectedImages={selectedImages}
        setSelectedImages={setSelectedImages}
        openModal={openCameraModal}
        handleModal={handleCameraModal}
      />
    </>
  );
};

export default Attachments;
