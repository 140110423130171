import React from "react";
import styles from "./Sidebar.module.scss";
import { settingsSidebar } from "Shared/Constants/settings";
import { useLocation, useNavigate } from "react-router-dom";
import cn from "classnames";
import Parts from "./Parts";
import Rates from "Components/Settings/Sidebar/Rates";
import Units from "./Unit";

const Sidebar: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const isActive = (link: string) => {
    return location.pathname.includes(link);
  };
  const handleClick = (link: string) => {
    navigate("/settings/" + link);
  };

  const checkIfIsHasDropdown = (title: string, link: string) => {
    switch (title) {
      case "Part Settings":
        return <Parts key={link} title={title} link={link} />;
      case "Rate Settings":
        return <Rates key={link} title={title} link={link} />;
      case "Unit Settings":
        return <Units key={link} title={title} link={link} />;
      default:
        return (
          <p
            key={title}
            onClick={() => handleClick(link)}
            className={cn(
              styles.sidebar__item,
              isActive(link) && styles.sidebar__item_active
            )}
          >
            {title}
          </p>
        );
    }
  };
  return (
    <div className={styles.sidebar}>
      {settingsSidebar.map(({ title, link }) =>
        checkIfIsHasDropdown(title, link)
      )}
    </div>
  );
};
export default Sidebar;
