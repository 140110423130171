import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "Service/api/api";
import { readObj } from "Service/storage";
import {
  IDispatchCallback,
  IDispatchCallbackWithId,
  IDispatchCallbackWithoutData,
} from "Shared/Types/common";
import {
  IPartAdd,
  IPartAddApi,
  IPartSearchParams,
  IPartUpdate,
  IPartUpdateApi,
} from "Shared/Types/part";
import { errorHandler } from "Shared/Utils/app";

const getPartList = createAsyncThunk(
  "part/getPartList",
  async (params: IPartSearchParams) => {
    try {
      const shop = readObj("current_shop");
      const response = await api.part.getPartList({
        ...params,
        shop: shop?.id,
      });
      return response.data;
    } catch (error) {
      if (error instanceof Error) {
        errorHandler(error);
      }
      throw error;
    }
  }
);

const getEstimatePartList = createAsyncThunk(
  "part/getEstimatePartList",
  async (params: IPartSearchParams) => {
    try {
      const shop = readObj("current_shop");
      const response = await api.part.getEstimatePartList({
        ...params,
        shop: shop?.id,
      });
      return response.data;
    } catch (error) {
      if (error instanceof Error) {
        errorHandler(error);
      }
      throw error;
    }
  }
);

const getPricePartList = createAsyncThunk(
  "part/getPricePartList",
  async (params: IPartSearchParams) => {
    try {
      const shop = readObj("current_shop");
      const response = await api.part.getPricePartList({
        ...params,
        shop: shop?.id,
      });
      return response.data;
    } catch (error) {
      if (error instanceof Error) {
        errorHandler(error);
      }
      throw error;
    }
  }
);

const getPart = createAsyncThunk("part/getPart", async (id: string) => {
  try {
    const response = await api.part.getPart(id);
    return response.data;
  } catch (error) {
    if (error instanceof Error) {
      errorHandler(error);
    }
    throw error;
  }
});

const getPartLocation = createAsyncThunk(
  "part/getLocation",
  async (id: string) => {
    try {
      const response = await api.part.getPartLocation(id);
      return response.data;
    } catch (error) {
      if (error instanceof Error) {
        errorHandler(error);
      }
      throw error;
    }
  }
);

const createPart = createAsyncThunk(
  "part/createPart",
  async ({ data, onSuccess }: IDispatchCallback<IPartAdd>) => {
    const values: IPartAddApi = {
      ...data,
      vendor: data.vendor?.id || "",
      locations: JSON.stringify(data.locations),
    };
    try {
      const formData = new FormData();
      Object.entries(values).forEach(([key, value]) => {
        if (value instanceof File) {
          formData.append(key, value);
        } else if (value !== undefined && value !== null) {
          formData.append(key, String(value));
        }
      });
      const response = await api.part.addPart(formData);
      onSuccess?.();
      return response.data;
    } catch (error) {
      if (error instanceof Error) {
        errorHandler(error, true);
      }
      throw error;
    }
  }
);

const updatePart = createAsyncThunk(
  "part/updatePart",
  async ({ id, data, onSuccess }: IDispatchCallbackWithId<IPartUpdate>) => {
    const values: IPartUpdateApi = {
      ...data,
      vendor: data.vendor?.id || "",
    };
    try {
      const response = await api.part.updatePart(id, values);
      onSuccess?.();
      return response.data;
    } catch (error) {
      if (error instanceof Error) {
        errorHandler(error);
      }
      throw error;
    }
  }
);

const deletePart = createAsyncThunk(
  "part/deletePart",
  async ({ id, onSuccess }: IDispatchCallbackWithoutData) => {
    try {
      const response = await api.part.deletePart(id);
      onSuccess?.();
      return response.data;
    } catch (error) {
      if (error instanceof Error) {
        errorHandler(error, true);
      }
      throw error;
    }
  }
);

export {
  getPartList,
  getPart,
  getPartLocation,
  createPart,
  updatePart,
  deletePart,
  getEstimatePartList,
  getPricePartList,
};
