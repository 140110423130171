import React from "react";
import styles from "./CardHead.module.scss";
import { getShortValue } from "Shared/Utils/order";
import { IUser } from "Shared/Types/user";
import DefaultAvatarIcon from "Assets/default-avatar.svg";

type CardHeadProps = {
  employee: IUser;
};

const CardHead: React.FC<CardHeadProps> = ({ employee }) => {
  return (
    <div className={styles.head__name}>
      <div>
        {employee.avatar?.toString() ? (
          <img
            src={employee.avatar?.toString()}
            alt="avatar"
            className={styles.head__avatar}
          />
        ) : (
          <img
            src={DefaultAvatarIcon}
            alt="default-avatar"
            className={styles.head__avatar}
          />
        )}
      </div>
      <div className={styles.head__text}>
        <span className={styles.head__title}>
          {getShortValue(employee.first_name + " " + employee.last_name, 10)}
        </span>
        <span className={styles.head__role}>
          {employee?.role ? employee?.role.name : "Not selected"}
        </span>
      </div>
    </div>
  );
};
export default CardHead;
