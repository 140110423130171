import React from "react";
import { useSelector } from "react-redux";
import { selectCompanyRequestList } from "Redux/CompanyRequests/slice";
import RequestList from "./List";
import { useGetRequestList } from "Shared/Hooks/request/use-get-request-list";

const RequestAllList: React.FC = () => {
  const requestsAllList = useSelector(selectCompanyRequestList);
  const { getCompanyRequests } = useGetRequestList();

  return (
    <RequestList
      filteredBy="all"
      requestsList={requestsAllList || []}
      getList={getCompanyRequests}
    />
  );
};
export default RequestAllList;
