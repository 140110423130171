import { BreadcrumbItem } from "Components/Breadcrumb/Breadcrumb";
import Layout from "Components/Layout/Layout";
import { VendorAdd } from "Containers/Vendor/VendorAdd/VendorAdd";
import { useBreadcrumb } from "Shared/Hooks/use-breadcrumb";
import React from "react";

export const VendorAddPage: React.FC = () => {
  const breads: BreadcrumbItem[] = [
    { title: "Vendors", link: "/vendors" },
    { title: "Create a vendor", link: "" },
  ];

  useBreadcrumb({ links: breads });

  return (
    <Layout>
      <VendorAdd />
    </Layout>
  );
};
