import { BreadcrumbItem } from "Components/Breadcrumb/Breadcrumb";
import Layout from "Components/Layout/Layout";
import UnitAdd from "Containers/Unit/UnitAdd/UnitAdd";
import { useBreadcrumb } from "Shared/Hooks/use-breadcrumb";
import React from "react";

export const UnitAddPage: React.FC = () => {
  const breads: BreadcrumbItem[] = [
    { title: "Units", link: "/units" },
    { title: "Create a unit", link: "" },
  ];
  useBreadcrumb({ links: breads });

  return (
    <Layout>
      <UnitAdd />
    </Layout>
  );
};
