import React from "react";
import ForgotPasswordByEmailContainer from "Containers/Auth/ForgotPasswordByEmail";

export const ForgotPasswordByEmail = () => {
  return (
    <div>
      <ForgotPasswordByEmailContainer />
    </div>
  );
};
