import { IPermissionItem, IRoleItem } from "Shared/Types/role";
import {
  actionItemPermissions,
  categoryPermissinos,
  componentSystemPermissions,
  employeePermissions,
  getViewPermissions,
  laborRatePermissions,
  locationPermissions,
  manufacturerPermissions,
  notifactionPermissions,
  orderPermissions,
  partPermissions,
  rolePermissions,
  servicePermissions,
  shopPermissions,
  subTypePermissins,
  supplyRatePermissions,
  taxRatePermissions,
  unitPermissions,
  unitTypePermissions,
  vendorPermissions,
} from "./permissions";

export const OWNER_PERMISSIONS: IPermissionItem[] = [
  orderPermissions,
  actionItemPermissions,
  partPermissions,
  shopPermissions,
  servicePermissions,
  unitPermissions,
  vendorPermissions,
  employeePermissions,
  categoryPermissinos,
  manufacturerPermissions,
  locationPermissions,
  laborRatePermissions,
  taxRatePermissions,
  supplyRatePermissions,
  subTypePermissins,
  unitTypePermissions,
  componentSystemPermissions,
  rolePermissions,
  notifactionPermissions,
];

export const LEAD_PERMISSIONS: IPermissionItem[] = [
  orderPermissions,
  actionItemPermissions,
  getViewPermissions("part"),
  getViewPermissions("service"),
  getViewPermissions("unit"),
  getViewPermissions("vendor"),
  getViewPermissions("employee"),
  getViewPermissions("laborRate"),
  getViewPermissions("taxRate"),
  getViewPermissions("supplyRate"),
  notifactionPermissions,
];

export const SERVICE_MANAGER_PERMISSIONS: IPermissionItem[] = [
  orderPermissions,
  actionItemPermissions,
  getViewPermissions("part"),
  servicePermissions,
  unitPermissions,
  employeePermissions,
  laborRatePermissions,
  taxRatePermissions,
  supplyRatePermissions,
  subTypePermissins,
  unitPermissions,
  componentSystemPermissions,
  notifactionPermissions,
];

export const TECHNICIAN_PERMISSIONS: IPermissionItem[] = [
  orderPermissions,
  actionItemPermissions,
  getViewPermissions("part"),
  getViewPermissions("service"),
  getViewPermissions("unit"),
  getViewPermissions("vendor"),
  getViewPermissions("employee"),
  getViewPermissions("laborRate"),
  getViewPermissions("taxRate"),
  getViewPermissions("supplyRate"),
];

export const SHOP_MANAGER_PERMISSIONS: IPermissionItem[] = [
  orderPermissions,
  actionItemPermissions,
  partPermissions,
  servicePermissions,
  unitPermissions,
  vendorPermissions,
  employeePermissions,
  categoryPermissinos,
  manufacturerPermissions,
  locationPermissions,
  partPermissions,
  laborRatePermissions,
  taxRatePermissions,
  supplyRatePermissions,
  subTypePermissins,
  unitTypePermissions,
  componentSystemPermissions,
  rolePermissions,
  notifactionPermissions,
];

export const PART_MANAGER_PERMISSIONS: IPermissionItem[] = [
  partPermissions,
  vendorPermissions,
  categoryPermissinos,
  manufacturerPermissions,
  locationPermissions,
  laborRatePermissions,
  laborRatePermissions,
  supplyRatePermissions,
  notifactionPermissions,
];

export const roleList: IRoleItem[] = [
  {
    title: "Owner",
    permissions: OWNER_PERMISSIONS,
  },
  {
    title: "Lead Technician",
    permissions: LEAD_PERMISSIONS,
  },
  {
    title: "Service Manager",
    permissions: SERVICE_MANAGER_PERMISSIONS,
  },
  {
    title: "Technician",
    permissions: LEAD_PERMISSIONS,
  },
  {
    title: "Shop Manager",
    permissions: SHOP_MANAGER_PERMISSIONS,
  },
  {
    title: "Part Manager",
    permissions: PART_MANAGER_PERMISSIONS,
  },
];
