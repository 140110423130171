import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "Redux/store";
import { IUrgencyOption } from "Shared/Types/urgencyOption";
import {
  addUrgencyOption,
  deleteUrgencyOption,
  getUrgencyOption,
  getUrgencyOptionList,
  updateUrgencyOption,
} from "Redux/UrgencyOption/reducer";

interface IUrgencyOptionState {
  loading: boolean;
  success: boolean;
  error?: string | null;
  urgencyOptionList: IUrgencyOption[];
  urgencyOption?: IUrgencyOption;
}

const initialState: IUrgencyOptionState = {
  loading: false,
  success: false,
  error: null,
  urgencyOptionList: [],
  urgencyOption: undefined,
};

const urgencyOptionSlice = createSlice({
  name: "urgencyOption",
  initialState,
  reducers: {
    clear(state) {
      state.error = null;
      state.loading = false;
      state.success = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getUrgencyOptionList.pending, (state) => {
      state.loading = true;
      state.error = null;
      state.urgencyOptionList = state.urgencyOptionList || [];
    });
    builder.addCase(getUrgencyOptionList.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
    builder.addCase(getUrgencyOptionList.fulfilled, (state, action) => {
      state.loading = false;
      state.error = null;
      state.urgencyOptionList = action.payload.results;
    });

    builder.addCase(getUrgencyOption.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(getUrgencyOption.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
    builder.addCase(getUrgencyOption.fulfilled, (state, action) => {
      state.loading = false;
      state.error = null;
      state.urgencyOption = action.payload;
    });

    builder.addCase(addUrgencyOption.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(addUrgencyOption.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
    builder.addCase(addUrgencyOption.fulfilled, (state) => {
      state.loading = false;
      state.error = null;
      state.success = true;
    });

    builder.addCase(deleteUrgencyOption.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(deleteUrgencyOption.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
    builder.addCase(deleteUrgencyOption.fulfilled, (state) => {
      state.loading = false;
      state.error = null;
      state.success = true;
    });

    builder.addCase(updateUrgencyOption.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(updateUrgencyOption.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
    builder.addCase(updateUrgencyOption.fulfilled, (state) => {
      state.loading = false;
      state.error = null;
      state.success = true;
    });
  },
});

export const { clear } = urgencyOptionSlice.actions;

export const selectUrgencyOptionList = (state: RootState) =>
  state.urgencyOption.urgencyOptionList;
export const selectUrgencyOption = (state: RootState) =>
  state.urgencyOption.urgencyOption;
export const selectUrgencyOptionError = (state: RootState) =>
  state.urgencyOption.error;
export const selectUrgencyOptionSuccess = (state: RootState) =>
  state.urgencyOption.success;

export default urgencyOptionSlice;
