import { BreadcrumbItem } from "Components/Breadcrumb/Breadcrumb";
import Layout from "Components/Layout/Layout";
import ContactAdd from "Containers/Contacts/ContactAdd/ContactAdd";
import { useBreadcrumb } from "Shared/Hooks/use-breadcrumb";
import React from "react";

export const ContactAddPage: React.FC = () => {
  const breads: BreadcrumbItem[] = [
    { title: "Contacts", link: "/customers?tab=Contacts" },
    { title: "Create a contact", link: "" },
  ];

  useBreadcrumb({ links: breads });
  return (
    <Layout>
      <ContactAdd />
    </Layout>
  );
};
