import React, { useEffect } from "react";
import InfoOrChangeField from "Components/UI/InfoOrChangeField/InfoOrChangeField";
import { useAppDispatch } from "Redux/store";
import { notifyError, notifySuccess } from "Components/Notify/notify";
import { IEstimatePartFields, IEstimatesPart } from "Shared/Types/estimate";
import {
  getEstimatePart,
  getEstimateService,
  updateEstimatePartByFields,
} from "Redux/Estimate/reducer";
import { useSelector } from "react-redux";
import { selectEstimatesService } from "Redux/Estimate/slice";

export const renderCellParts = (
  cellData: any,
  columnId: string,
  part: IEstimatesPart,
) => {
  const currentEstimateService = useSelector(selectEstimatesService);
  const dispatch = useAppDispatch();
  const onSuccess = () => {
    notifySuccess("Part is Updated");
    if (currentEstimateService?.id) {
      dispatch(getEstimateService(currentEstimateService.id));
    }
    if (part.id) dispatch(getEstimatePart(part.id));
  };
  const onError = (error?: string) => {
    notifyError(error || "Something went wrong");
  };
  const saveField = (field: IEstimatePartFields, value: string) => {
    if (part.id) {
      dispatch(
        updateEstimatePartByFields({
          id: part.id,
          data: { field, value },
          onSuccess,
          onError,
        }),
      );
    }
  };
  if (columnId === "markup") {
    if (part.selling_price && part.cost) {
      return (
        (((+part.selling_price - +part.cost) / +part.cost) * 100).toFixed(2) +
        "%"
      );
    }
    return 0 + "%";
  }

  if (columnId === "margin") {
    if (part.selling_price && part.cost) {
      return (
        (
          ((+part.selling_price - +part.cost) / +part.selling_price) *
          100
        ).toFixed(2) + "%"
      );
    }
    return 0 + "%";
  }

  if (columnId === "amount" && part.selling_price && part.quantity) {
    return ((+part.selling_price || 0) * +part.quantity).toFixed(2);
  }
  if (columnId === "selling_price") {
    return (
      <InfoOrChangeField
        value={cellData}
        name="selling_price"
        onFieldChange={(text: string | null | number) =>
          saveField("selling_price", (text || "").toString())
        }
        type="number"
      />
    );
  }
  return cellData;
};
