import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "Service/api/api";
import {
  ILaborRateAdd,
  ILaborRateSearchParams,
  ILaborRateUpdate,
} from "Shared/Types/laborRate";
import {
  IDispatchCallback,
  IDispatchCallbackWithId,
  IDispatchCallbackWithoutData,
} from "Shared/Types/common";
import { errorHandler } from "Shared/Utils/app";

const getLaborRateList = createAsyncThunk(
  "laborRate/getLaborRateList",
  async (params: ILaborRateSearchParams | null) => {
    try {
      const response = await api.laborRate.getLaborRateList(params);
      return response.data;
    } catch (error) {
      if (error instanceof Error) {
        errorHandler(error, true);
      }
      throw error;
    }
  },
);

const getLaborRate = createAsyncThunk(
  "laborRate/getLaborRate",
  async (id: string) => {
    try {
      const response = await api.laborRate.getLaborRate(id);
      return response.data;
    } catch (error) {
      if (error instanceof Error) {
        errorHandler(error, true);
      }
      throw error;
    }
  },
);

const addLaborRate = createAsyncThunk(
  "laborRate/createLaborRate",
  async ({ data, onSuccess }: IDispatchCallback<ILaborRateAdd>) => {
    try {
      const response = await api.laborRate.createLaborRate(data);
      onSuccess?.();
      return response.data;
    } catch (error) {
      if (error instanceof Error) {
        errorHandler(error, true);
      }
      throw error;
    }
  },
);

const updateLaborRate = createAsyncThunk(
  "laborRate/updateLaborRate",
  async ({
    id,
    data,
    onSuccess,
  }: IDispatchCallbackWithId<ILaborRateUpdate>) => {
    try {
      const response = await api.laborRate.updateLaborRate(id, data);
      onSuccess?.();
      return response.data;
    } catch (error) {
      if (error instanceof Error) {
        errorHandler(error, true);
      }
      throw error;
    }
  },
);

const deleteLaborRate = createAsyncThunk(
  "laborRate/deleteLaborRate",
  async ({ id, onSuccess }: IDispatchCallbackWithoutData) => {
    try {
      const response = await api.laborRate.deleteLaborRate(id);
      onSuccess?.();
      return response.data;
    } catch (error) {
      if (error instanceof Error) {
        errorHandler(error, true);
      }
      throw error;
    }
  },
);

export {
  getLaborRateList,
  addLaborRate,
  deleteLaborRate,
  updateLaborRate,
  getLaborRate,
};
