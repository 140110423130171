import React, { useEffect } from "react";
import styles from "./VendorUpdate.module.scss";
import { notifySuccess } from "Components/Notify/notify";
import { useAppDispatch } from "Redux/store";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import { selectCurrentVendor } from "Redux/Vendor/slice";
import { IVendorUpdate } from "Shared/Types/vendor";
import { getVendor, updateVendor } from "Redux/Vendor/reducer";
import VendorForm from "Components/Vendor/VendorForm/VendorForm";
import { vendorSchema } from "Shared/Utils/vendor";

export const VendorUpdate: React.FC = () => {
  const dispatch = useAppDispatch();
  const vendor = useSelector(selectCurrentVendor);
  const params = useParams();
  const navigate = useNavigate();

  const onSubmit = async (values: IVendorUpdate) => {
    const onSuccess = () => {
      notifySuccess("Vendor Updated!");
      navigate("/vendors");
    };
    if (params.id) {
      await dispatch(
        updateVendor({
          id: params.id,
          data: values,
          onSuccess,
        })
      );
    }
  };

  useEffect(() => {
    if (params.id) {
      dispatch(getVendor(params.id));
    }
  }, [params.id]);

  return (
    <div className={styles.vendorUpdate}>
      <div className={styles.vendorUpdate__form}>
        {vendor && (
          <VendorForm
            onSubmit={onSubmit}
            schema={vendorSchema}
            vendor={vendor}
          />
        )}
      </div>
    </div>
  );
};
