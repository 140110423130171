import { BreadcrumbItem } from "Components/Breadcrumb/Breadcrumb";
import Layout from "Components/Layout/Layout";
import { CustomerUpdate } from "Containers/Customer/CustomerUpdate/CustomerUpdate";
import { useBreadcrumb } from "Shared/Hooks/use-breadcrumb";
import React from "react";

export const CustomersUpdatePage: React.FC = () => {
  const breads: BreadcrumbItem[] = [
    { title: "Customers", link: "/customers" },
    { title: "Update a customer", link: "" },
  ];

  useBreadcrumb({ links: breads });

  return (
    <Layout>
      <CustomerUpdate />
    </Layout>
  );
};
