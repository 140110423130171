import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "Service/api/api";
import { IShopAdd, IShopSearchParams, IShopUpdate } from "Shared/Types/shop";
import {
  IDispatchCallback,
  IDispatchCallbackWithId,
  IDispatchCallbackWithoutData,
} from "Shared/Types/common";
import { errorHandler } from "Shared/Utils/app";
import { clear } from "Service/storage";

const getShopList = createAsyncThunk(
  "shop/getShopList",
  async (params: IShopSearchParams | null) => {
    try {
      const response = await api.shop.getShopList(params);
      if (response.data.results.length === 0) {
        clear("current_shop_name");
      }
      return response.data;
    } catch (error) {
      if (error instanceof Error) {
        errorHandler(error);
      }
      throw error;
    }
  }
);

const getShop = createAsyncThunk("shop/getShop", async (id: string) => {
  try {
    const response = await api.shop.getShop(id);
    return response.data;
  } catch (error) {
    if (error instanceof Error) {
      errorHandler(error);
    }
    throw error;
  }
});

const addShop = createAsyncThunk(
  "shop/addShop",
  async ({ data, onSuccess }: IDispatchCallback<IShopAdd>) => {
    try {
      const response = await api.shop.addShop({
        ...data,
        owner: data.owner?.id,
      });
      onSuccess?.();
      return response.data;
    } catch (error) {
      if (error instanceof Error) {
        errorHandler(error, true);
      }
      throw error;
    }
  }
);

const updateShop = createAsyncThunk(
  "shop/updateShop",
  async ({ id, data, onSuccess }: IDispatchCallbackWithId<IShopUpdate>) => {
    try {
      const response = await api.shop.updateShop(id, {
        ...data,
        owner: data.owner?.id,
      });
      onSuccess?.();
      return response.data;
    } catch (error) {
      if (error instanceof Error) {
        errorHandler(error, true);
      }
      throw error;
    }
  }
);

const deleteShop = createAsyncThunk(
  "shop/deleteShop",
  async ({ id, onSuccess }: IDispatchCallbackWithoutData) => {
    try {
      const response = await api.shop.deleteShop(id);
      onSuccess?.();
      return response.data;
    } catch (error) {
      if (error instanceof Error) {
        errorHandler(error, true);
      }
      throw error;
    }
  }
);

export { getShopList, getShop, addShop, updateShop, deleteShop };
