import { client } from "Service/axios";
import {
  ICategory,
  ICategoryAdd,
  ICategorySearchParams,
  ICategoryUpdate,
} from "Shared/Types/category";
import { SearchResult } from "Shared/Types/common";

const CATEGORY_URL = "part/category/";

class Category {
  getCategorysList(params: ICategorySearchParams | null) {
    return client.get<SearchResult<ICategory>>(CATEGORY_URL, { params });
  }
  getCategory(id: string) {
    return client.get<ICategory>(`${CATEGORY_URL}${id}/`);
  }
  addCategory(data: ICategoryAdd) {
    return client.post(CATEGORY_URL, data);
  }
  updateCategory(id: string, data: ICategoryUpdate) {
    return client.put(`${CATEGORY_URL}${id}/`, data);
  }
  deleteCategory(id: string) {
    return client.delete(`${CATEGORY_URL}${id}/`);
  }
}

export default Category;
