import React from "react";
import {
  Stack,
  Pagination as MuiPagination,
  PaginationProps as MuiPaginationProps,
} from "@mui/material";
import styles from "./Pagination.module.scss";
import { useSearchParams } from "react-router-dom";
import { changeToParams } from "Shared/Utils/search-params";

type PaginationProps = MuiPaginationProps;

const Pagination: React.FC<PaginationProps> = ({ count = 0, ...props }) => {
  const [searchParams, setSearchParam] = useSearchParams();
  const handleChangePage = (page: number) => {
    setSearchParam(changeToParams("page", page, searchParams));
  };
  return (
    <Stack spacing={2} display="flex" alignItems="center" mt="20px">
      <MuiPagination
        classes={{ root: styles.pagination }}
        count={count}
        size="large"
        onChange={(_, value) => handleChangePage(value)}
        {...props}
      />
    </Stack>
  );
};
export default Pagination;
