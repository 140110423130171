import { BreadcrumbItem } from "Components/Breadcrumb/Breadcrumb";
import Layout from "Components/Layout/Layout";
import Dashboard from "Containers/Order/AllOrder/Dashboard/Dashboard";
import { useBreadcrumb } from "Shared/Hooks/use-breadcrumb";
import React from "react";
import { ORDER_CREATE } from "Shared/Constants/order";

export const OrdersPage: React.FC = () => {
  const breads: BreadcrumbItem[] = [
    { title: "Order", link: "/orders" },
    { title: "All Orders", link: "" },
  ];

  useBreadcrumb({
    links: breads,
    ...ORDER_CREATE,
  });

  return (
    <Layout>
      <Dashboard />
    </Layout>
  );
};
