import {
  AllOrdersIcon as HomeSOsIcon,
  SearchPlusIcon,
  RejectIcon,
  ApproveIcon,
} from "Assets/index";
import FilterCard from "Components/Cards/FilterCard/FilterCard";
import React, { useEffect } from "react";
import styles from "./RequestFilter.module.scss";
import { Grid } from "@mui/material";
import { BreadcrumbItem } from "Components/Breadcrumb/Breadcrumb";
import { useBreadcrumb } from "Shared/Hooks/use-breadcrumb";
import { useSelector } from "react-redux";
import {
  selectCompanyRequestApprovedCount,
  selectCompanyRequestCount,
  selectCompanyRequestRejectedCount,
  selectCompanyRequestReviewCount,
} from "Redux/CompanyRequests/slice";
import {
  getCompanyRequestsApprovedList,
  getCompanyRequestsList,
  getCompanyRequestsRejectedList,
  getCompanyRequestsReviewList,
} from "Redux/CompanyRequests/reducers";
import { useAppDispatch } from "Redux/store";

const RequestFilter: React.FC = () => {
  const breads: BreadcrumbItem[] = [{ title: "Requests", link: "/requests" }];

  const requestsAllCount = useSelector(selectCompanyRequestCount);
  const requestsApprovedCount = useSelector(selectCompanyRequestApprovedCount);
  const requestsRejectedCount = useSelector(selectCompanyRequestRejectedCount);
  const requestsReviewCount = useSelector(selectCompanyRequestReviewCount);
  const dispatch = useAppDispatch();

  useBreadcrumb({ links: breads });

  useEffect(() => {
    dispatch(getCompanyRequestsList({}));
    dispatch(getCompanyRequestsApprovedList({}));
    dispatch(getCompanyRequestsRejectedList({}));
    dispatch(getCompanyRequestsReviewList({}));
  }, []);

  return (
    <Grid className={styles.filter}>
      <FilterCard
        title="All Request"
        description={`As today ${requestsAllCount} orders`}
        icon={HomeSOsIcon}
        color="blue"
        link="/requests/all-requests"
      />
      <FilterCard
        title="Review"
        description={`As today  ${requestsReviewCount}  orders`}
        icon={SearchPlusIcon}
        color="orange"
        link="/requests/review"
      />
      <FilterCard
        title="Rejected"
        description={`As today  ${requestsRejectedCount} orders`}
        icon={RejectIcon}
        color="red"
        link="/requests/rejected"
      />
      <FilterCard
        title="Approved"
        description={`As today ${requestsApprovedCount} orders`}
        icon={ApproveIcon}
        color="green_approve"
        link="/requests/approved"
      />
    </Grid>
  );
};
export default RequestFilter;
