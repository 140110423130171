import { BreadcrumbItem } from 'Components/Breadcrumb/Breadcrumb';
import Layout from 'Components/Layout/Layout';
import { CustomerAdd } from 'Containers/Customer/CustomerAdd/CustomerAdd';
import { useBreadcrumb } from 'Shared/Hooks/use-breadcrumb';
import React from 'react';

export const CustomersAddPage: React.FC = () => {
  const breads: BreadcrumbItem[] = [
    { title: "Customer", link: "/customers" },
    { title: "Create Customer", link: "" },
  ];
  useBreadcrumb({ links: breads, option: { upd: "New" } });


  return (
    <Layout>
      <CustomerAdd />
    </Layout>
  )

}