import { Grid } from "@mui/material";
import { notifySuccess } from "Components/Notify/notify";
import Block from "Components/UI/Block/Block";
import InfoField from "Components/UI/InfoField/InfoField";
import InputField from "Components/UI/Inputs/InputField";
import { updatePurchaseOrderLineItem } from "Redux/PurchaseOrder/reducer";
import { useAppDispatch } from "Redux/store";
import usePurchaseOrderLineItemsFields, {
  TField,
} from "Shared/Hooks/purchase-order/use-purchase-order-line-items";
import {
  IPurchaseOrderLineItem,
  IPurchaseOrderLineItemKey,
} from "Shared/Types/purchase-order";
import React, { useState } from "react";
import { useFormContext } from "react-hook-form";

type LineItemProps = {
  lineItem: IPurchaseOrderLineItem;
  purchaseOrderId: string;
  index: number;
};

const LineItem: React.FC<LineItemProps> = ({
  lineItem,
  index,
  purchaseOrderId,
}) => {
  const [actualCost, setActualCost] = useState(+lineItem.actual_cost || 0);
  const { fields } = usePurchaseOrderLineItemsFields();
  const { control, setValue } = useFormContext();
  const dispatch = useAppDispatch();
  const getName = (name: string) => {
    return `receive_line_items[${index}].${name}`;
  };
  const saveLineItem = (fieldName: IPurchaseOrderLineItemKey) => {
    if (fieldName === "actual_cost") {
      dispatch(
        updatePurchaseOrderLineItem({
          id: purchaseOrderId,
          data: {
            actual_cost: actualCost,
            partId: lineItem.id,
          },
          onSuccess: () => {
            notifySuccess("Part is udpated!");
            setValue(getName("actual_cost"), actualCost);
          },
        })
      );
    }
  };
  const renderInput = (field: TField) => {
    const onChange = (name: string, value?: number) => {
      if (name === "actual_cost") {
        setActualCost(value || 0);
        return;
      }
    };

    const isPartReceived = lineItem.quantity <= lineItem.received_quantity;
    switch (field.type) {
      case "number":
        if (isPartReceived) {
          return <InfoField label={field.label} value={"Received"} />;
        }
        if (field.isInForm) {
          return (
            <InputField
              name={getName(field.name)}
              onChange={(e) => onChange(field.name, +e.target.value)}
              label={field.label}
              withController={field.isInForm}
              control={control}
            />
          );
        }
        return (
          <InputField
            name={getName(field.name)}
            value={actualCost}
            onChange={(e) => onChange(field.name, +e.target.value)}
            onBlur={() => saveLineItem(field.name)}
            label={field.label}
          />
        );

      default:
        return (
          <InfoField
            label={field.label}
            value={
              field.name === "saved_quantity"
                ? lineItem["received_quantity"]
                : lineItem[field.name]
            }
            valueColor={field.isBlue ? "blue" : undefined}
          />
        );
    }
  };

  return (
    <Block isBlur isBorder mb={8}>
      <Grid container spacing={1}>
        {fields.map((field) => (
          <Grid key={field.label} item xs={field.xs}>
            {renderInput(field)}
          </Grid>
        ))}
      </Grid>
    </Block>
  );
};
export default LineItem;
