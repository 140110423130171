import Tabs from "Components/UI/Tabs/Tabs";
import React, { useEffect, useState } from "react";
import Estimate from "../Estimate/Estimate";
import ActionItems from "../ActionItems/ActionItems";
import PartList from "../PartList/PartList";
import styles from "./OrderDetailAction.module.scss";
import { DeleteIcon } from "Assets/index";
import IconButton from "Components/UI/IconButton/IconButton";
import { useSelector } from "react-redux";
import { selectCurrentOrder } from "Redux/Order/slice";
import { useAppDispatch } from "Redux/store";
import {
  deleteOrder,
  getOrder,
  getOrderActionItemParts,
  updateOrder,
} from "Redux/Order/reducer";
import DeleteModal from "Components/UI/DialogWindow/DeleteModal";
import { useLocation, useNavigate } from "react-router";
import OrderNote from "./OrderNote/OrderNote";
import OnHold from "./OnHold/OnHold";
import Attachments from "Components/Attachments/Attachments";
import { IOrderFile, IOrderImage } from "Shared/Types/order";
import OrderHistory from "Containers/Order/AllOrder/OrderDetail/OrderInnerHistory/OrderHistory";
import { selectActionItemList } from "Redux/ActionItem/slice";
import OrderInvoice from "../OrderInvoice/OrderInvioce";
import PayStatus from "./PayStatus/PayStatus";
import { getOrderActiveTab } from "Shared/Utils/order";

type OrderDetailActionProps = {
  status?: string;
};

const orderTabs = ["Action item", "Estimate", "Part list"];

const OrderDetailAction: React.FC<OrderDetailActionProps> = () => {
  const currentOrder = useSelector(selectCurrentOrder);
  const dispatch = useAppDispatch();
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const navigate = useNavigate();
  const currentActionItems = useSelector(selectActionItemList);
  const [selectedImages, setSelectedImages] = useState<File[]>([]);
  const [selectedDocs, setSelectedDocs] = useState<File[]>([]);
  const [deletingDocs, setDeletingDocs] = useState<string[]>([]);
  const [deletingImages, setDeletingImages] = useState<string[]>([]);
  const location = useLocation();
  const [activeTab, setActiveTab] = useState(
    getOrderActiveTab(location.state?.tab)
  );
  const [tabs, setTabs] = useState<string[]>(orderTabs);

  const handleDeleteOrder = () => {
    if (currentOrder) {
      setOpenDeleteModal(true);
      dispatch(
        deleteOrder({
          id: currentOrder.id,
          onSuccess: () => {
            navigate(-1);
            handleDialogClose();
          },
        })
      );
    }
  };

  const handleDialogClose = () => {
    setOpenDeleteModal(false);
  };

  const handleDialogOpen = () => {
    setOpenDeleteModal(true);
  };

  const onSuccessAfterFiles = () => {
    setSelectedImages([]);
    setSelectedDocs([]);
    setDeletingImages([]);
    setDeletingDocs([]);
    if (currentOrder) {
      dispatch(getOrder(currentOrder.id));
    }
  };

  const pushInvoice = () => {
    if (!tabs.includes("Invoice")) {
      tabs.push("Invoice");
    }
    setTabs(tabs);
  };

  const handleChangeTab = (tab: number) => {
    setActiveTab(tab);
    if (tab === 2 && currentOrder) {
      dispatch(getOrderActionItemParts({ order: currentOrder.id }));
    }
  };

  const tabPanels = [
    <ActionItems
      key="actions"
      setActiveTab={setActiveTab}
      pushInvoice={pushInvoice}
    />,
    <Estimate key="estimate" />,
    <PartList key="parts" />,
    <OrderInvoice key="invoice" />,
  ];

  const onUploadFiles = (files?: File[], images?: File[]) => {
    if (currentOrder) {
      const data = {
        order_gallery: images,
        order_file: files,
      };
      dispatch(
        updateOrder({
          id: currentOrder.id,
          data,
          isFile: true,
          onSuccess: onSuccessAfterFiles,
        })
      );
    }
  };

  const onDeleteFiles = (
    deletingDocs?: string[],
    deletingImages?: string[]
  ) => {
    if (currentOrder) {
      const data = {
        file_id_list: JSON.stringify(deletingDocs),
        image_id_list: JSON.stringify(deletingImages),
      };
      dispatch(
        updateOrder({
          id: currentOrder?.id,
          data,
          onSuccess: onSuccessAfterFiles,
        })
      );
    }
  };

  useEffect(() => {
    if (currentOrder?.service_order_invoice) {
      if (!tabs.includes("Invoice")) {
        setTabs([...tabs, "Invoice"]);
      }
    } else {
      if (tabs.includes("Invoice")) {
        setTabs(tabs.slice(0, -1));
      }
    }
  }, [currentActionItems]);

  return (
    <div className={styles.orders}>
      <Tabs
        tabs={tabs}
        tabPanels={tabPanels}
        activeTab={activeTab}
        onChange={handleChangeTab}
      />
      <div className={styles.orders__actions}>
        {!currentOrder?.service_order_invoice && (
          <OnHold order={currentOrder} />
        )}
        {currentOrder?.service_order_invoice && (
          <PayStatus order={currentOrder} />
        )}
        <OrderNote orderId={currentOrder?.id} />
        <OrderHistory orderItemId={currentOrder?.id} />
        <Attachments
          setDeletingDocs={setDeletingDocs}
          setDeletingImages={setDeletingImages}
          deletingDocs={deletingDocs}
          deletingImages={deletingImages}
          selectedImages={selectedImages}
          setSelectedImages={setSelectedImages}
          selectedDocs={selectedDocs}
          setSelectedDocs={setSelectedDocs}
          documents={currentOrder?.order_file as IOrderFile[]}
          images={currentOrder?.order_gallery as IOrderImage[]}
          onUpload={onUploadFiles}
          onDeleteFiles={onDeleteFiles}
        />
        <IconButton
          icon={DeleteIcon}
          variant="primary"
          onClick={handleDialogOpen}
        />
      </div>
      <DeleteModal
        acceptTitle="Delete SO"
        open={openDeleteModal}
        handleClose={handleDialogClose}
        title="Delete Service Order "
        text="Are you sure you want to delete this Service Order?"
        accept={handleDeleteOrder}
      />
    </div>
  );
};
export default OrderDetailAction;
