import React from "react";
import { BreadcrumbItem } from "Components/Breadcrumb/Breadcrumb";
import { useBreadcrumb } from "Shared/Hooks/use-breadcrumb";
import RequestAllList from "Containers/Requests/List/RequestAllList";

export const RequestsAllRequests: React.FC = () => {
  const breads: BreadcrumbItem[] = [
    { title: "Requests", link: "/requests" },
    { title: "All Requests", link: "all-requests" },
  ];

  useBreadcrumb({ links: breads });

  return <RequestAllList />;
};
