import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "Service/api/api";
import {
  IDispatchCallback,
  IDispatchCallbackWithId,
  IDispatchCallbackWithoutData,
} from "Shared/Types/common";
import { ICustomer } from "Shared/Types/customer";
import { IUnitSearchParams, IUnitAdd, IUnitUpdate } from "Shared/Types/unit";
import { errorHandler, isInstanced } from "Shared/Utils/app";

const getUnitsList = createAsyncThunk(
  "unit/getUnitsList",
  async (params: IUnitSearchParams | null) => {
    try {
      const response = await api.unit.getUnitsList(params);
      return response.data;
    } catch (error) {
      if (error instanceof Error) {
        errorHandler(error);
      }
      throw error;
    }
  }
);

const getUnit = createAsyncThunk("unit/getUnit", async (id: string) => {
  try {
    const response = await api.unit.getUnit(id);
    return response.data;
  } catch (error) {
    if (error instanceof Error) {
      errorHandler(error);
    }
    throw error;
  }
});

const addUnit = createAsyncThunk(
  "unit/addUnit",
  async ({ data, onSuccess }: IDispatchCallback<IUnitAdd>) => {
    try {
      if (isInstanced<ICustomer>(data.customer)) {
        data.customer = data.customer?.id;
      }
      const formData = new FormData();
      Object.entries(data).forEach(([key, value]) => {
        if (value instanceof File) {
          formData.append(key, value);
        } else if (value !== undefined && value !== null) {
          formData.append(key, String(value));
        }
      });
      const response = await api.unit.addUnit(formData);
      onSuccess?.();
      return response.data;
    } catch (error) {
      if (error instanceof Error) {
        errorHandler(error, true);
      }
      throw error;
    }
  }
);

const updateUnit = createAsyncThunk(
  "unit/updateUnit",
  async ({ id, data, onSuccess }: IDispatchCallbackWithId<IUnitUpdate>) => {
    try {
      if (isInstanced<ICustomer>(data.customer)) {
        data.customer = data.customer?.id;
      }
      const formData = new FormData();
      Object.entries(data).forEach(([key, value]) => {
        if (value instanceof File) {
          formData.append(key, value);
        } else if (
          value !== undefined &&
          value !== null &&
          key !== "photo" &&
          key !== "service"
        ) {
          formData.append(key, String(value));
        }
      });
      const response = await api.unit.updateUnit(id, formData);
      onSuccess?.();
      return response.data;
    } catch (error) {
      if (error instanceof Error) {
        errorHandler(error, true);
      }
      throw error;
    }
  }
);

const deleteUnit = createAsyncThunk(
  "unit/deleteUnit",
  async ({ id, onSuccess }: IDispatchCallbackWithoutData) => {
    try {
      const response = await api.unit.deleteUnit(id);
      onSuccess?.();
      return response.data;
    } catch (error) {
      if (error instanceof Error) {
        errorHandler(error, true);
      }
      throw error;
    }
  }
);

export { getUnitsList, getUnit, addUnit, updateUnit, deleteUnit };
