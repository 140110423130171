import React from "react";
import { IActionPart } from "Shared/Types/action-item-part";
import styles from "./OrderPartItem.module.scss";
import InfoField from "Components/UI/InfoField/InfoField";
import Button from "Components/UI/Button/Button";
import { getActionItemPartOrderFields } from "Shared/Utils/action-item-part";

type OrderPartItemProps = {
  parts: IActionPart[];
  orderParts: (parts: IActionPart[]) => void;
};

const OrderPartItem: React.FC<OrderPartItemProps> = ({ parts, orderParts }) => {
  return (
    <div className={styles.part}>
      {getActionItemPartOrderFields(parts).map(({ label, value }) => (
        <InfoField label={label} value={value} key={label} />
      ))}
      <Button onClick={() => orderParts(parts)}>Submit</Button>
    </div>
  );
};
export default OrderPartItem;
