import React, { useEffect } from "react";
import styles from "./LaborRateUpdate.module.scss";
import { useNavigate, useParams } from "react-router";
import LaborForm from "Components/Settings/LaborRate/Form/LaborForm";
import { ILaborRateUpdate } from "Shared/Types/laborRate";
import { useSelector } from "react-redux";
import { removeCurrentLaborRate, selectLaborRate } from "Redux/LaborRate/slice";
import { useAppDispatch } from "Redux/store";
import { getLaborRate, updateLaborRate } from "Redux/LaborRate/reducer";
import { notifySuccess } from "Components/Notify/notify";
import { laborRateScheme } from "Shared/Utils/laborRate";

const LaborRateUpdate: React.FC = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const laborRate = useSelector(selectLaborRate);
  const dispatch = useAppDispatch();

  const back = () => {
    navigate("/settings/rate-settings/labor-rate");
  };

  const editLaborRate = async (values: ILaborRateUpdate) => {
    const onSuccess = () => {
      notifySuccess("Updated LaborRate!");
      back();
    };
    if (laborRate?.id) {
      await dispatch(
        updateLaborRate({ id: laborRate?.id, data: values, onSuccess }),
      );
    }
  };

  useEffect(() => {
    if (id) {
      dispatch(getLaborRate(id));
    }
    dispatch(removeCurrentLaborRate());
  }, []);

  return (
    <div className={styles.labor}>
      {laborRate && (
        <LaborForm
          laborRate={laborRate}
          onSubmit={editLaborRate}
          scheme={laborRateScheme}
        />
      )}
    </div>
  );
};
export default LaborRateUpdate;
