import React from "react";
import styles from "./Location.module.scss";
import { useNavigate } from "react-router";
import { useAppDispatch } from "Redux/store";
import { useSelector } from "react-redux";
import { selectAccount } from "Redux/Auth/slice";
import { notifyError, notifySuccess } from "Components/Notify/notify";
import StepProgress from "Components/UI/StepProgress/StepProgress";
import { manufacturerSchema } from "Shared/Utils/part-settings";
import { ILocationAdd } from "Shared/Types/location";
import { addLocation } from "Redux/PartSettings/Location/reducer";
import LocationForm from "Components/Settings/PartSettings/Location/LocationForm";
import { partSettingStep } from "../Categories/CategoryAdd/CategoryAdd";
import { checkFirstAuth } from "Shared/Utils/app";
import { readObj } from "Service/storage";

const LocationAdd = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const profile = useSelector(selectAccount);
  const isFirstAuth = checkFirstAuth(profile);

  const onSkip = () => {
    navigate("/parts/new");
  };
  const onSuccess = () => {
    if (isFirstAuth) {
      onSkip();
    } else {
      navigate("/settings/part-settings/locations");
    }

    notifySuccess("Location created");
  };

  const addNewLocation = (values: ILocationAdd) => {
    const shop = readObj("current_shop");
    if (!shop) {
      notifyError("You can't create service, because you don't assign to shop");
      return;
    }
    dispatch(addLocation({ data: { ...values, shop: shop.id }, onSuccess }));
  };

  return (
    <div>
      {isFirstAuth && (
        <StepProgress
          description={partSettingStep}
          title="Part Settings"
          active={7}
        />
      )}
      <div className={styles.location__form}>
        <LocationForm
          onSubmit={addNewLocation}
          schema={manufacturerSchema}
          onSkip={onSkip}
          isFirstAuth={isFirstAuth}
        />
      </div>
    </div>
  );
};

export default LocationAdd;
