import React, { useEffect } from "react";
import styles from "./UnitTypeUpdate.module.scss";
import { BreadcrumbItem } from "Components/Breadcrumb/Breadcrumb";
import { useBreadcrumb } from "Shared/Hooks/use-breadcrumb";
import { useAppDispatch } from "Redux/store";
import { useSelector } from "react-redux";
import { notifySuccess } from "Components/Notify/notify";
import { selectCurrentUnitType } from "Redux/UnitType/slice";
import { getUnitType, updateUnitType } from "Redux/UnitType/reducer";
import UnitTypeForm from "Components/UnitType/UnitTypeForm/UnitTypeForm";
import { unitTypeSchema } from "Shared/Utils/unit-type";
import { IUnitTypeAdd } from "Shared/Types/unit-type";
import { useNavigate, useParams } from "react-router";

const UnitTypeUpdate: React.FC = () => {
  const dispatch = useAppDispatch();
  const params = useParams();
  const unitType = useSelector(selectCurrentUnitType);
  const navigate = useNavigate();
  const breads: BreadcrumbItem[] = [
    { title: "Unit Types", link: "/unit-settings/unit-types" },
    { title: "Edit a unit type", link: "" },
  ];

  const createUnitType = (values: IUnitTypeAdd) => {
    const onSuccess = () => {
      notifySuccess("Unit type updated");
      navigate("/settings/unit-settings/unit-types");
    };
    if (params.id) {
      dispatch(updateUnitType({ id: params.id, data: values, onSuccess }));
    }
  };

  useEffect(() => {
    if (params.id) {
      dispatch(getUnitType(params.id));
    }
  }, []);

  useBreadcrumb({ links: breads });
  return (
    <div className={styles.unitTypeUpdate}>
      <h3>Edit a unit type</h3>
      <div className={styles.unitTypeUpdate__form}>
        {unitType && (
          <UnitTypeForm
            onSubmit={createUnitType}
            schema={unitTypeSchema}
            unitType={unitType}
          />
        )}
      </div>
    </div>
  );
};
export default UnitTypeUpdate;
