import React, { useEffect, useState } from "react";
import styles from "./RolesList.module.scss";
import { selectRoleList } from "Redux/Role/slice";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { useAppDispatch } from "Redux/store";
import { deleteRole, getRolesList } from "Redux/Role/reducers";
import DeleteModal from "Components/UI/DialogWindow/DeleteModal";
import IconButton from "Components/UI/IconButton/IconButton";
import { DeleteIcon, EditIcon, PlusIcon } from "Assets/index";
import Block from "Components/UI/Block/Block";
import { notifySuccess } from "Components/Notify/notify";

const RolesList: React.FC = () => {
  const roles = useSelector(selectRoleList) || [];
  const [showDelete, setShowDelete] = useState(false);
  const [deleteId, setDeleteId] = useState<string | null>();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const goToCreatePage = () => {
    navigate("/settings/roles/new");
  };

  const goToEditPage = (id: string) => {
    navigate(`/settings/roles/${id}`);
  };

  const handleDialogClose = () => {
    setShowDelete(false);
    setDeleteId(null);
  };

  const handleDialogOpen = (id: string) => {
    setShowDelete(true);
    setDeleteId(id);
  };

  const handleBlock = async () => {
    const onSuccess = () => {
      notifySuccess("Role is deleted");
      handleDialogClose();
      dispatch(getRolesList(null));
    };
    if (deleteId) {
      await dispatch(deleteRole({ id: deleteId, onSuccess }));
    }
  };

  useEffect(() => {
    dispatch(getRolesList(null));
  }, []);
  return (
    <Block className={styles.role}>
      <div className={styles.role__addOrder}>
        <IconButton
          onClick={goToCreatePage}
          label="Create role"
          width={30}
          height={30}
          icon={PlusIcon}
        />
      </div>
      <div className={styles.role__head}>
        <h3>Role & Access</h3>
      </div>
      {roles.map((role) => (
        <div key={role.id} className={styles.role__item}>
          <span>{role.name}</span>
          <span className={styles.role__item__action}>
            <IconButton icon={EditIcon} onClick={() => goToEditPage(role.id)} />
            <IconButton
              icon={DeleteIcon}
              onClick={() => handleDialogOpen(role.id)}
            />
          </span>
        </div>
      ))}
      <DeleteModal
        open={showDelete}
        handleClose={handleDialogClose}
        title="Delete"
        text="Are you sure...?"
        acceptTitle="Delete"
        accept={handleBlock}
      />
    </Block>
  );
};
export default RolesList;
