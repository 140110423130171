import type { Location } from "history";

import { hasAccess, hasMatch } from "Routes/route-utils";
import { useSelector } from "react-redux";
import { selectAccount } from "Redux/Auth/slice";
import { readObj } from "Service/storage";
import { IAccount } from "Shared/Types/auth";

export const useAuthRedirect = ({ pathname }: Location) => {
  const profile =
    useSelector(selectAccount) || (readObj("account") as IAccount);
  const permissions = profile?.role?.permissions || [];

  const pagesWithoutAuth = [
    "/login",
    "/forgot-password-email",
    "/register",
    "/healthcheck",
  ];

  if (profile == null) {
    if (
      pagesWithoutAuth.includes(pathname) ||
      pathname.startsWith("/first_auth") ||
      pathname.startsWith("/password/reset/confirm")
    )
      return;

    return "/login";
  } else if (pathname === "/login") {
    if (profile.is_superuser) return "/requests";
    return "/";
  }

  const notFound = !hasMatch(pathname);

  if (notFound) return "/not-found";

  const forbidden = !hasAccess(
    permissions,
    pathname,
    Boolean(profile?.is_company_admin)
  );

  if (forbidden) return "/forbidden";
};
