import React from "react";
import styles from "./ServiceOrderAdd.module.scss";
import ServiceOrderForm from "Components/Order/ServiceOrderForms/ServiceOrderForm";
import { orderSchema } from "Shared/Utils/orderAdd";

const ServiceOrderAdd = () => {
  return (
    <div className={styles.service_order}>
      <ServiceOrderForm schema={orderSchema} />
    </div>
  );
};

export default ServiceOrderAdd;
