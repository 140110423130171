import { BreadcrumbItem } from 'Components/Breadcrumb/Breadcrumb'
import Layout from 'Components/Layout/Layout'
import StatsContent from 'Containers/Home/StatsContent/StatsContent'
import { useBreadcrumb } from 'Shared/Hooks/use-breadcrumb'
import React from 'react'

export const StatsPage = () => {
    const breads: BreadcrumbItem[] = [
      {  title: "Home", link: "/" },
      { title: "Stats", link: "" },
    ];
  
    useBreadcrumb({ links: breads });
    return (
       <Layout>
        <StatsContent/>
       </Layout>
    )
}