import { BreadcrumbItem } from "Components/Breadcrumb/Breadcrumb";
import Layout from "Components/Layout/Layout";
import UnitUpdate from "Containers/Unit/UnitUpdate/UnitUpdate";
import { useBreadcrumb } from "Shared/Hooks/use-breadcrumb";
import React from "react";

export const UnitUpdatePage: React.FC = () => {
  const breads: BreadcrumbItem[] = [
    { title: "Units", link: "/units" },
    { title: "Update a unit", link: "" },
  ];

  useBreadcrumb({ links: breads });

  return (
    <Layout>
      <UnitUpdate />
    </Layout>
  );
};
