import React from "react";
import ReactECharts from "echarts-for-react";
import styles from "./EstimateCharts.module.scss";

const EstimateCharts = () => {
  const option = {
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "cross",
      },
      backgroundColor: "rgba(0, 0, 0, 1)",
      borderWidth: 0,
    },
    grid: {
      left: "3%",
      right: "4%",
      bottom: "3%",
      containLabel: true,
      show: false,
    },

    xAxis: [
      {
        type: "category",
        boundaryGap: false,
        data: [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ],
      },
    ],
    yAxis: [
      {
        type: "value",
        splitLine: {
          show: false,
        },
      },
    ],
    series: [
      {
        type: "line",
        smooth: true,
        lineStyle: {
          color: "#A344E6",
          width: 4,
        },
        emphasis: {
          focus: "series",
        },
        showSymbol: false,
        data: [40, 55, 70, 100, 70, 40, 20, 30, 50, 60, 70, 80, 90, 10],
      },
      {
        type: "line",
        smooth: true,
        lineStyle: {
          color: "#77DED7",
          width: 4,
        },
        emphasis: {
          focus: "series",
        },
        showSymbol: false,
        data: [10, 15, 40, 20, 70, 40, 100, 20, 50, 60, 100, 80, 90, 10],
      },
    ],
  };

  return (
    <div className={styles.container}>
      <ReactECharts
        style={{ minHeight: "250px", height: "calc(100vh - 600px)" }}
        option={option}
        className={styles.chart}
      />
    </div>
  );
};

export default EstimateCharts;
