import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "Service/api/api";
import { IOrderNoteAdd, IOrderNoteSearchParams } from "Shared/Types/notes";
import { errorHandler } from "Shared/Utils/app";

const addOrderNote = createAsyncThunk(
  "orderNote/addOrderNote",
  async (data: IOrderNoteAdd) => {
    try {
      const response = await api.order.addOrderNote(data);
      return response.data;
    } catch (error) {
      if (error instanceof Error) {
        errorHandler(error);
      }
      throw error;
    }
  },
);

const deleteOrderNotes = createAsyncThunk(
  "orderNote/deleteOrderNote",
  async (id: string) => {
    try {
      await api.order.deleteOrderNote(id);
      return id;
    } catch (error) {
      if (error instanceof Error) {
        errorHandler(error);
      }
      throw error;
    }
  },
);

const getOrderNoteList = createAsyncThunk(
  "orderNote/getOrderNoteList",
  async (params: IOrderNoteSearchParams) => {
    try {
      const response = await api.order.getListOrderNote(params);
      return response.data;
    } catch (error) {
      if (error instanceof Error) {
        errorHandler(error);
      }
      throw error;
    }
  },
);

export { addOrderNote, deleteOrderNotes, getOrderNoteList };
