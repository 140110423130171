import { BreadcrumbItem } from 'Components/Breadcrumb/Breadcrumb';
import Layout from 'Components/Layout/Layout';
import ProfileInfo from 'Containers/Home/Profile/ProfileInfo/ProfileInfo';
import { useBreadcrumb } from 'Shared/Hooks/use-breadcrumb';
import React from 'react'

export const ProfilePage = () => {
  const breads: BreadcrumbItem[] = [
    {  title: "Home", link: "/" },
    { title: "Profile", link: "" },
  ];

  useBreadcrumb({ links: breads });
  return (
     <Layout>
     <ProfileInfo/>
     </Layout>
  )
}
