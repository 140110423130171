import { Grid } from "@mui/material";
import Divider from "Components/UI/Divider/Divider";
import InfoField from "Components/UI/InfoField/InfoField";
import { TField } from "Shared/Types/common";
import { IPurchaseOrder } from "Shared/Types/purchase-order";
import { IShop } from "Shared/Types/shop";
import { getPurchaseOrderCommonInfo } from "Shared/Utils/purchase-order";
import React from "react";

type PurchaseOrderCommonInfoProps = {
  purchaseOrder: IPurchaseOrder;
  shop: IShop;
};

const PurchaseOrderCommonInfo: React.FC<PurchaseOrderCommonInfoProps> = ({
  purchaseOrder,
  shop,
}) => {
  const info = getPurchaseOrderCommonInfo(purchaseOrder, shop);
  const renderInput = (field: TField) => {
    switch (field.type) {
      case "divider":
        return <Divider color="secondary" mt={10} mb={10} />;
      default:
        return <InfoField value={field.value} label={field.label} />;
    }
  };
  return (
    <Grid container mt={2} mb={1}>
      {info.map((field) => (
        <Grid key={field.label} item xs={field.xs}>
          {renderInput(field)}
        </Grid>
      ))}
    </Grid>
  );
};
export default PurchaseOrderCommonInfo;
