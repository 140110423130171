import { yupResolver } from "@hookform/resolvers/yup";
import Form from "Components/UI/Form/Form";
import React from "react";
import { FormProvider, useForm } from "react-hook-form";
import { ObjectSchema } from "yup";
import Button from "Components/UI/Button/Button";
import styles from "./CompanyForm.module.scss";
import { ICompany, ICompanyAdd, ICompanyUpdate } from "Shared/Types/company";
import CompanyInformation from "./CompanyInformation";
import PrimaryContact from "./PrimaryContact";
import BillingInformation from "./BillingInformation";
import Block from "Components/UI/Block/Block";

export type ICompanyForm = ICompanyAdd | ICompanyUpdate;

type CompanyFormProps = {
  onSubmit: (values: ICompanyForm) => void;
  schema: ObjectSchema<any>;
  company?: ICompany;
};

const CompanyForm: React.FC<CompanyFormProps> = ({
  onSubmit,
  schema,
  company,
}) => {
  const methods = useForm({
    resolver: yupResolver(schema),
    defaultValues: company ? { ...company, owner: company?.owner?.id } : {},
  });
  return (
    <Block className={styles.form}>
      <FormProvider {...methods}>
        <Form onSubmit={methods.handleSubmit(onSubmit)}>
          <div className={styles.form__content}>
            <CompanyInformation control={methods.control} />
            <BillingInformation control={methods.control} />
            <PrimaryContact control={methods.control} />
          </div>
          <div className={styles.form__action}>
            <Button variant="outlined" type="submit">
              Save
            </Button>
          </div>
        </Form>
      </FormProvider>
    </Block>
  );
};
export default CompanyForm;
