import React, { useEffect, useRef, useState } from "react";
import styles from "./Profile.module.scss";
import Icon from "Components/UI/Icon/Icon";
import {
  CameraIcon,
  CheckClickIcon,
  CloseIcon,
  EditIcon,
  EmailIcon,
  PhoneIcon2,
  PhotoIcon,
} from "Assets/index";
import { useSelector } from "react-redux";
import { selectAccount } from "Redux/Auth/slice";
import Modal from "Components/UI/Modal/Modal";
import Options from "Components/Headers/Profile/Options";
import { useAppDispatch } from "Redux/store";
import { updateUserField } from "Redux/User/reducer";

type ProfileProps = {
  closeModal: () => void;
  open: boolean;
};

const ProfileInfoModal: React.FC<ProfileProps> = ({ closeModal, open }) => {
  const dispatch = useAppDispatch();
  const account = useSelector(selectAccount);

  const [editPhone, setEditPhone] = useState(false);
  const [editNameSurname, setEditNameSurname] = useState(false);
  const [options, setOptions] = useState(false);
  const [phoneValue, setPhoneValue] = useState(account?.phone);
  const [firstNameValue, setFirstNameValue] = useState(account?.first_name);
  const [lastNameValue, setLastNameValue] = useState(account?.last_name);
  const phoneRef = useRef<HTMLInputElement | null>(null);

  const handleUpdatePhone = () => {
    if (account && phoneValue !== account.phone) {
      dispatch(
        updateUserField({ id: account.id, data: { phone: phoneValue } }),
      );
    }
    setEditPhone(false);
  };

  const handleCancelUpdatePhone = () => {
    if (account) {
      setPhoneValue(account?.phone);
    }
    setEditPhone(false);
  };

  const handleUpdateNameSurname = () => {
    if (
      account &&
      (firstNameValue !== account.first_name ||
        lastNameValue !== account.last_name)
    ) {
      dispatch(
        updateUserField({
          id: account.id,
          data: { first_name: firstNameValue, last_name: lastNameValue },
        }),
      );
    }
    setEditNameSurname(false);
  };

  const handleCancelUpdateNameSurname = () => {
    if (account) {
      setFirstNameValue(account?.first_name);
      setLastNameValue(account?.last_name);
    }
    setEditNameSurname(false);
  };

  const onCloseProfile = () => {
    closeModal();
    setEditNameSurname(false);
    setEditPhone(false);
  };

  useEffect(() => {
    setFirstNameValue(account?.first_name);
    setLastNameValue(account?.last_name);
  }, [account]);

  return (
    <Modal
      className={styles.profile_info}
      open={open}
      onClose={onCloseProfile}
      isClosed={open}
      title="Profile"
    >
      <div className={styles.profile_info__container}>
        <div
          onClick={(event) => {
            event.stopPropagation();
            setOptions(true);
          }}
          className={styles.profile_info__avatar}
        >
          <img
            className={styles.avatar}
            src={
              account?.avatar instanceof File
                ? URL.createObjectURL(account.avatar)
                : account?.avatar || ""
            }
            alt="/"
          />
          {!account?.avatar ? (
            <Icon
              width={100}
              className={styles.avatar_icon}
              component={PhotoIcon}
            />
          ) : null}
          {options && <Options closeOptions={() => setOptions(false)} />}
          <div className={styles.profile_info__avatar_text_cont}>
            <Icon width={50} height={50} component={CameraIcon} />
            <p className={styles.text}>
              {account?.avatar ? "Change profile photo" : "Add profile photo"}
            </p>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div className={styles.profile_info__details_item__text}>
            {editNameSurname ? (
              <div
                style={{
                  display: "flex",
                  gap: "10px",
                }}
              >
                <input
                  className={styles.profile_info__name_input}
                  disabled={!editNameSurname}
                  type="text"
                  value={firstNameValue || ""}
                  onChange={(e) => setFirstNameValue(e.target.value)}
                />
                <input
                  className={styles.profile_info__name_input}
                  disabled={!editNameSurname}
                  type="text"
                  value={lastNameValue || ""}
                  onChange={(e) => setLastNameValue(e.target.value)}
                />
              </div>
            ) : (
              <span className={styles.profile_info__name_input_text}>
                {account?.first_name} {account?.last_name}
              </span>
            )}
          </div>
          {editNameSurname ? (
            <div style={{ display: "flex" }}>
              <Icon
                onClick={handleUpdateNameSurname}
                color="transparent"
                component={CheckClickIcon}
              />
              <Icon
                onClick={handleCancelUpdateNameSurname}
                width={25}
                height={25}
                color="transparent"
                component={CloseIcon}
              />
            </div>
          ) : (
            <Icon
              onClick={() => setEditNameSurname(true)}
              width={25}
              height={25}
              color="transparent"
              component={EditIcon}
            />
          )}
        </div>
      </div>
      <div className={styles.profile_info__details}>
        <p className={styles.text}>Contact Information</p>
        <div className={styles.profile_info__details_item}>
          <div style={{ display: "flex", gap: "30px", alignItems: "center" }}>
            <div className={styles.icon_box}>
              <Icon
                width={25}
                height={25}
                style={{
                  cursor: "initial",
                  color: "transparent",
                  fontSize: "20px",
                }}
                component={EmailIcon}
              />
            </div>
            <div className={styles.profile_info__details_item__text}>
              <p>Email Address</p>
              <p style={{ color: "#147ED8" }}>{account?.email}</p>
            </div>
          </div>
        </div>
        <div className={styles.profile_info__details_item}>
          <div style={{ display: "flex", gap: "30px", alignItems: "center" }}>
            <div className={styles.icon_box}>
              <Icon
                width={25}
                height={25}
                style={{
                  cursor: "initial",
                  color: "transparent",
                  fontSize: "20px",
                }}
                component={PhoneIcon2}
              />
            </div>
            <div className={styles.profile_info__details_item__text}>
              <p>Phone Number</p>
              <input
                ref={phoneRef}
                className={styles.profile_info__input}
                disabled={!editPhone}
                type="text"
                value={phoneValue || account?.phone}
                onChange={(e) => setPhoneValue(e.target.value)}
              />
            </div>
          </div>
          {editPhone ? (
            <div>
              <Icon
                onClick={handleUpdatePhone}
                color="transparent"
                component={CheckClickIcon}
              />
              <Icon
                onClick={handleCancelUpdatePhone}
                width={25}
                height={25}
                color="transparent"
                component={CloseIcon}
              />
            </div>
          ) : (
            <Icon
              onClick={() => setEditPhone(true)}
              width={25}
              height={25}
              color="transparent"
              component={EditIcon}
            />
          )}
        </div>
      </div>
    </Modal>
  );
};

export default ProfileInfoModal;
