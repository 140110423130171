import { BreadcrumbItem } from "Components/Breadcrumb/Breadcrumb";
import Layout from "Components/Layout/Layout";
import ServiceAdd from "Containers/Service/ServiceAdd/ServiceAdd";
import { useBreadcrumb } from "Shared/Hooks/use-breadcrumb";
import React from "react";

export const ServiceAddPage: React.FC = () => {
  const breads: BreadcrumbItem[] = [
    { title: "Services", link: "/services" },
    { title: "Create a service", link: "" },
  ];
  useBreadcrumb({ links: breads });
  return (
    <Layout>
      <ServiceAdd />
    </Layout>
  );
};
