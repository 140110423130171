import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "Service/api/api";
import { errorHandler } from "Shared/Utils/app";

const getOrderNotification = createAsyncThunk(
  "notification/getOrderNotication",
  async () => {
    try {
      const response = await api.order.getOrderNotification();
      return response.data;
    } catch (error) {
      if (error instanceof Error) {
        errorHandler(error);
      }
      throw error;
    }
  },
);

const markAsReadedNotification = createAsyncThunk(
  "notification/markAsReadedNotification",
  async (id: string) => {
    try {
      const response = await api.order.markAsReadedNotification(id);
      return response.data;
    } catch (error) {
      if (error instanceof Error) {
        errorHandler(error);
      }
      throw error;
    }
  },
);

export { getOrderNotification, markAsReadedNotification };
