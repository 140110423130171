import React, { useState } from "react";
import { IActionItem, IOrder } from "Shared/Types/order";
import styles from "./ActionItemWithPart.module.scss";
import { actionItemPartFields } from "Shared/Constants/action-item";
import { useAppDispatch } from "Redux/store";
import { deleteActionPart } from "Redux/ActionPart/reducer";
import {
  getActionItemPartButtonTitle,
  getActionItemPartCardStatus,
  getActionItemPartData,
} from "Shared/Utils/action-item-part";
import DeleteModal from "Components/UI/DialogWindow/DeleteModal";
import Block from "Components/UI/Block/Block";
import { IActionPart } from "Shared/Types/action-item-part";
import { notifySuccess } from "Components/Notify/notify";
import { getOrderActionItemParts } from "Redux/Order/reducer";
import Card from "Components/UI/Card/Card/Card";
import PartField from "../PartCard/PartField/PartField";
import AddQuoteModal from "./AddQuoteModal/AddQuoteModal";
import OrderActionItemPart from "./OrderActionItemPart/OrderActionItemPart";
import ReceiveActionItemPart from "./ReceivePartModal/RecievePartModal";
import ReturnPartModal from "./ReturnPartModal/ReturnPartModal";

type ActionItemWithPartProps = {
  parts: IActionPart[];
  actionItem: IActionItem;
  order: IOrder;
  selectedParts: IActionPart[];
  setSelectedParts: (parts: IActionPart[]) => void;
};

const ActionItemWithPart: React.FC<ActionItemWithPartProps> = ({
  parts = [],
  actionItem,
  order,
  selectedParts,
  setSelectedParts,
}) => {
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [deleteId, setDeleteId] = useState<string | null>();
  const [selectedPart, setSelectedPart] = useState<IActionPart>();
  const [openAddQuote, setOpenAddQuote] = useState(false);
  const [openOrderPart, setOpenOrderPart] = useState(false);
  const [openReceivePart, setOpenReceivePart] = useState(false);
  const [openReturnPart, setOpenReturnPart] = useState(false);
  const dispatch = useAppDispatch();

  const handleDialogClose = () => {
    setOpenDeleteModal(false);
    setDeleteId(null);
  };

  const handleDialogOpen = (id: string) => {
    setOpenDeleteModal(true);
    setDeleteId(id);
  };

  const isChecked = (partId: string) => {
    return !!selectedParts.find((part) => part.id === partId);
  };

  const onCheck = (partId: string) => {
    const curPartIndex = selectedParts.findIndex((part) => part.id === partId);
    if (curPartIndex > -1) {
      setSelectedParts([
        ...selectedParts.slice(0, curPartIndex),
        ...selectedParts.slice(curPartIndex + 1),
      ]);
      return;
    }
    const curPart = parts.find((part) => part.id === partId);
    if (curPart) {
      setSelectedParts([...selectedParts, curPart]);
    }
  };

  const onDelete = () => {
    const onSuccess = () => {
      notifySuccess("Action Item Part is deleted");
      handleDialogClose();
      dispatch(
        getOrderActionItemParts({
          order: order.id,
        })
      );
    };
    if (deleteId != null) {
      dispatch(
        deleteActionPart({
          id: deleteId,
          onSuccess,
        })
      );
    }
  };

  const handleOpenAddQuote = () => {
    setOpenAddQuote(true);
  };

  const handleOpenOrder = () => {
    setOpenOrderPart(true);
  };

  const handleOpenRecieve = () => {
    setOpenReceivePart(true);
  };

  const handleOpenReturn = () => {
    setOpenReturnPart(true);
  };

  const handleActionClick = (part: IActionPart) => {
    setSelectedPart(part);
    switch (part.status.queue) {
      case 1:
        handleOpenAddQuote();
        break;
      case 4:
        handleOpenOrder();
        break;
      case 5:
        handleOpenRecieve();
        break;
      case 6:
        handleOpenReturn();
        break;
      default:
        handleDialogOpen(part.id);
    }
  };

  if (!parts.length) return <></>;
  return (
    <Block mt={8} className={styles.parts}>
      <div className={styles.parts__head}>
        <span>{actionItem.complaint}</span>
        <span>{actionItem.service?.name}</span>
      </div>
      <div className={styles.parts__list}>
        {parts.map((part) => (
          <Card
            key={part.id}
            fields={actionItemPartFields}
            cardData={getActionItemPartData(
              part,
              <PartField
                name="part_number"
                defValue={part.part_number}
                part={part}
                orderId={order.id}
              />,
              <PartField
                name="price"
                defValue={part.price}
                part={part}
                orderId={order.id}
              />
            )}
            onCheck={onCheck}
            openDeleteModal={handleDialogOpen}
            dataId={part.id}
            status={getActionItemPartCardStatus(part.status)}
            isNeedStatus
            isNeedCheck
            actionTitle={getActionItemPartButtonTitle(part)}
            actionClick={() => handleActionClick(part)}
            checked={isChecked(part.id)}
            isNeedAction
          />
        ))}
      </div>

      {selectedPart && (
        <>
          <AddQuoteModal
            open={openAddQuote}
            setOpen={setOpenAddQuote}
            selectedParts={[selectedPart]}
            setSelectedParts={setSelectedParts}
          />
          <OrderActionItemPart
            open={openOrderPart}
            setOpen={setOpenOrderPart}
            selectedParts={[selectedPart]}
            setSelectedParts={setSelectedParts}
          />
          <ReturnPartModal
            open={openReturnPart}
            setOpen={setOpenReturnPart}
            selectedPart={selectedPart}
          />
          <ReceiveActionItemPart
            open={openReceivePart}
            setOpen={setOpenReceivePart}
            selectedParts={[selectedPart]}
            setSelectedParts={setSelectedParts}
          />
        </>
      )}
      <DeleteModal
        acceptTitle="Delete"
        open={openDeleteModal}
        handleClose={handleDialogClose}
        title="Delete"
        text="Are you sure...?"
        accept={onDelete}
      />
    </Block>
  );
};
export default ActionItemWithPart;
