import React, { useState } from "react";
import { Grid } from "@mui/material";
import IconButton from "Components/UI/IconButton/IconButton";
import { DeleteIcon, EditIcon } from "Assets/index";
import DeleteModal from "Components/UI/DialogWindow/DeleteModal";
import Button from "Components/UI/Button/Button";
import { useFormContext } from "react-hook-form";
import {
  IEstimatePartUpdate,
  IEstimatesPart,
  IEstimatesPartAdd,
} from "Shared/Types/estimate";

type ComplaintPartActionButtonsProps = {
  complaintIndex: number;
  partIndex: number;
  isAddedPart?: boolean;
  isEdit: boolean;
  setIsEdit: React.Dispatch<React.SetStateAction<boolean>>;
  addNewPart: (part: IEstimatesPartAdd, partIndex: number) => void;
  deletePart: (index: number, id: string) => void;
  updatePart: (id: string, data: IEstimatePartUpdate) => void;
};

const ComplaintPartActionButtons: React.FC<ComplaintPartActionButtonsProps> = ({
  complaintIndex,
  partIndex,
  isAddedPart,
  isEdit,
  setIsEdit,
  addNewPart,
  updatePart,
  deletePart,
}) => {
  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  const { setValue, getValues } = useFormContext();

  const handleDialogClose = () => {
    setOpenDeleteModal(false);
  };

  const handleDialogOpen = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    event.stopPropagation();
    setOpenDeleteModal(true);
  };

  const handleCreatePart = (partIndex: number) => {
    const fields: IEstimatesPart = getValues(
      `action_items.${complaintIndex}.part.${partIndex}`
    );

    addNewPart(fields, partIndex);
  };

  const handleUpdatePart = () => {
    const fields: IEstimatesPart = getValues(
      `action_items.${complaintIndex}.part.${partIndex}`
    );

    updatePart(
      getValues(`action_items.${complaintIndex}.part.${partIndex}.part_id`),
      {
        description: fields.description,
        cost: fields.cost,
        selling_price: fields.selling_price,
        quantity: fields.quantity,
        part_number: fields.part_number,
      } as IEstimatePartUpdate
    );
    setIsEdit(false);
  };

  const handleDeletePart = () =>
    deletePart(
      partIndex,
      getValues(`action_items.${complaintIndex}.part.${partIndex}.part_id`)
    );

  const clearPartInputs = () => {
    const fields = ["part_number", "selling_price", "quantity", "cost"];
    fields.forEach((field) => {
      setValue(`action_items.${complaintIndex}.part.${partIndex}.${field}`, "");
    });
  };

  return (
    <Grid
      container
      item
      xs={2}
      display={"flex"}
      justifyContent="flex-end"
      gap={2}
    >
      {isAddedPart && !isEdit && (
        <>
          <Grid
            item
            xs={2}
            display={"flex"}
            alignItems="end"
            justifyContent="flex-end"
          >
            <IconButton icon={EditIcon} onClick={() => setIsEdit(true)} />
          </Grid>
          <Grid
            item
            xs={2}
            display={"flex"}
            alignItems="end"
            justifyContent="flex-end"
          >
            <IconButton onClick={handleDialogOpen} icon={DeleteIcon} />
            <DeleteModal
              open={openDeleteModal}
              handleClose={handleDialogClose}
              title="Delete Part"
              text="Are you sure...?"
              accept={handleDeletePart}
              acceptTitle="Delete"
              color="red"
            />
          </Grid>
        </>
      )}
      {isAddedPart && isEdit && (
        <Grid
          item
          xs={2}
          display={"flex"}
          alignItems="end"
          justifyContent="flex-end"
        >
          <Button onClick={handleUpdatePart}>Save</Button>
        </Grid>
      )}
      {!isAddedPart && (
        <>
          <Grid
            item
            xs={4}
            display={"flex"}
            alignItems="end"
            justifyContent="flex-end"
          >
            <Button color="secondary" onClick={clearPartInputs}>
              Clear
            </Button>
          </Grid>
          <Grid
            item
            xs={4}
            display={"flex"}
            alignItems="end"
            justifyContent="flex-end"
          >
            <Button onClick={() => handleCreatePart(partIndex)}>Add</Button>
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default ComplaintPartActionButtons;
