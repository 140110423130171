import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "Service/api/api";
import {
  IActionItemNoteAdd,
  IActionItemNoteSearchParams,
} from "Shared/Types/notes";
import { errorHandler } from "Shared/Utils/app";

const addActionItemNote = createAsyncThunk(
  "actionItemNote/addActionItemNote",
  async (data: IActionItemNoteAdd) => {
    try {
      const response = await api.actionItem.addActionItemNotes(data);
      return response.data;
    } catch (error) {
      if (error instanceof Error) {
        errorHandler(error);
      }
      throw error;
    }
  }
);

const deleteActionItemNotes = createAsyncThunk(
  "actionItemNote/deleteActionItemNote",
  async (id: string) => {
    try {
      await api.actionItem.deleteActionItemNotes(id);
      return id;
    } catch (error) {
      if (error instanceof Error) {
        errorHandler(error);
      }
      throw error;
    }
  }
);

const getActionItemNoteList = createAsyncThunk(
  "actionItemNote/getActionItemNoteList",
  async (params: IActionItemNoteSearchParams) => {
    try {
      const response = await api.actionItem.getListActionItemNotes(params);
      return response.data;
    } catch (error) {
      if (error instanceof Error) {
        errorHandler(error);
      }
      throw error;
    }
  }
);

export { addActionItemNote, deleteActionItemNotes, getActionItemNoteList };
