import React, { useEffect, useState } from "react";
import Block from "Components/UI/Block/Block";
import InfoField from "Components/UI/InfoField/InfoField";
import { useNavigate, useParams } from "react-router";
import { useAppDispatch } from "Redux/store";
import { useSelector } from "react-redux";
import styles from "./VendorDetail.module.scss";
import { selectCurrentVendor } from "Redux/Vendor/slice";
import { extractSubstring } from "Shared/Utils/extractCertainStringPart";
import { deleteVendor, getVendor } from "Redux/Vendor/reducer";
import IconButton from "Components/UI/IconButton/IconButton";
import { DeleteIcon, EditIcon } from "Assets/index";
import DeleteModal from "Components/UI/DialogWindow/DeleteModal";

const VendorDetail = () => {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [showDelete, setShowDelete] = useState(false);
  const vendor = useSelector(selectCurrentVendor);

  const goToEditPage = () => {
    navigate(`/vendors/edit/${id}`);
  };

  const handleDialogClose = () => {
    setShowDelete(false);
  };

  const handleDialogOpen = () => {
    setShowDelete(true);
  };

  const handleDeleteVendor = async () => {
    const onSuccess = () => {
      navigate("/vendors");
    };
    if (id) {
      await dispatch(deleteVendor({ id, onSuccess }));
    }
  };

  useEffect(() => {
    if (id) {
      dispatch(getVendor(id));
    }
  }, [id]);

  return (
    <div className={styles.detail}>
      <div className={styles.detail__head}>
        <div className={styles.detail__head__action}>
          <IconButton icon={EditIcon} onClick={goToEditPage} />
          <IconButton icon={DeleteIcon} onClick={handleDialogOpen} />
        </div>
      </div>
      <div className={styles.detail__block}>
        <Block>
          <h3>Basic Info</h3>
          <InfoField
            label="Vendor name"
            value={vendor?.vendor_name}
            labelColor="gray"
          />
          <InfoField
            label="First name"
            value={vendor?.first_name}
            mt={17}
            labelColor="gray"
          />
          <InfoField
            label="Last name"
            value={vendor?.last_name}
            mt={17}
            labelColor="gray"
          />
          <InfoField
            label="Email"
            value={vendor?.email}
            mt={17}
            labelColor="gray"
          />
          <InfoField label="Phone" value={vendor?.phone} labelColor="gray" />
          <InfoField
            label="Cell phone"
            value={vendor?.cell_phone}
            labelColor="gray"
          />
        </Block>
        <Block>
          <h3>Addres Info</h3>
          <InfoField
            label="Street Address"
            value={vendor?.street_address}
            mt={17}
            labelColor="gray"
          />
          <InfoField
            label="Street Address 2"
            value={vendor?.street_address_2}
            mt={17}
            labelColor="gray"
          />
          <InfoField
            label="Country"
            value={vendor?.country}
            mt={17}
            labelColor="gray"
          />
          <InfoField
            label="State"
            value={vendor?.state}
            mt={17}
            labelColor="gray"
          />
          <InfoField
            label="City"
            value={vendor?.city}
            mt={17}
            labelColor="gray"
          />
          <InfoField
            label="Zip/Postal code"
            value={vendor?.zip_code}
            mt={17}
            labelColor="gray"
          />
        </Block>
      </div>
      <div className={styles.detail__block}>
        <Block>
          <h3>Contact Person</h3>
          <InfoField
            label="Name"
            value={vendor?.contact_name}
            mt={17}
            labelColor="gray"
          />
          <InfoField
            label="Email"
            value={vendor?.contact_email}
            mt={17}
            labelColor="gray"
          />
          <InfoField
            label="Phone"
            value={vendor?.contact_phone}
            mt={17}
            labelColor="gray"
          />
        </Block>
        <Block>
          <h3>Additional Details</h3>
          <InfoField
            label="Additional information"
            value={vendor?.additional_information}
            mt={17}
            labelColor="gray"
          />
          <div className={styles.detail__block__label}>Photo</div>
          {typeof vendor?.photo === "string" ? (
            <img
              width={200}
              height={200}
              style={{ objectFit: "cover" }}
              src={vendor.photo}
            />
          ) : (
            "-"
          )}
          <div className={styles.detail__block__label}>Document</div>
          {typeof vendor?.document === "string"
            ? extractSubstring(vendor.document)
            : "-"}
        </Block>
      </div>
      <DeleteModal
        open={showDelete}
        handleClose={handleDialogClose}
        title="Delete"
        text="Are you sure...?"
        acceptTitle="Delete"
        accept={handleDeleteVendor}
      />
    </div>
  );
};

export default VendorDetail;
