import { Grid } from "@mui/material";
import Block from "Components/UI/Block/Block";
import InfoField from "Components/UI/InfoField/InfoField";
import { selectAccount } from "Redux/Auth/slice";
import styles from "./ProfileDetails.module.scss";
import AvatarDefaultIcon from "Assets/default-avatar.svg";

import React from "react";
import { useSelector } from "react-redux";
import ProfileTable from "Components/Home/Profile/ProfileTable";

export const ProfileDetails = () => {
  const profile = useSelector(selectAccount);
  return (
    <div className={styles.details}>
      <div className={styles.details__avatar}>
        {profile?.avatar ? (
          <img src={profile?.avatar.toString()} alt="" />
        ) : (
          <img src={AvatarDefaultIcon} alt="default-icon" />
        )}
      </div>
      <Grid container className={styles.content} rowSpacing={2} rowGap={3}>
        <Grid container item xs={12} columnSpacing={2} rowGap={2}>
          <Grid sm={6} xs={12} item className={styles.details__block}>
            <Block>
              <h5>Basic Info</h5>
              <InfoField label="First name" value={profile?.first_name} />
              <InfoField label="Last name" value={profile?.last_name} />
              <InfoField label="Email" value={profile?.email} />
              <InfoField label="Phone" value={profile?.phone} />
            </Block>
          </Grid>
          <Grid sm={6} xs={12} item className={styles.details__block}>
            <Block>
              <h5>Address Info</h5>
              <InfoField
                label="Street address"
                value={profile?.street_address}
              />
              <InfoField
                label="Street address 2"
                value={profile?.street_address_2}
              />
              <InfoField label="State" value={profile?.state} />
              <InfoField label="City" value={profile?.city} />
              <InfoField label="Country" value={profile?.country} />
              <InfoField label="Zip/Postal Code" value={profile?.zip_code} />
            </Block>
          </Grid>
        </Grid>

        <Grid container item xs={12} columnSpacing={2} rowGap={2}>
          <Grid md={6} xs={12} item className={styles.details__block}>
            <Block>
              <h5>Access</h5>
              <InfoField
                label="Assigned shop"
                value={profile?.shop?.shop_name}
              />
              <InfoField label="Supervisor" value={profile?.supervisor} />
              <InfoField label="Role" value={profile?.role?.id} />
            </Block>
          </Grid>
          <Grid md={6} xs={12} item className={styles.details__block}>
            <Block>
              <h5>Access</h5>
              <InfoField
                label="Assigned shop"
                value={profile?.shop?.shop_name}
              />
              <InfoField label="Supervisor" value={profile?.supervisor} />
              <InfoField label="Role" value={profile?.role?.id} />
            </Block>
          </Grid>
        </Grid>

        <Grid container item xs={12}>
          <ProfileTable />
        </Grid>
      </Grid>
    </div>
  );
};
