import React from "react";
import styles from "./PMCount.module.scss";
import { PMUserIcon } from "Assets/index";
import Icon from "Components/UI/Icon/Icon";

type PMCountProps = {
  data?: number | string;
};

const PMCount: React.FC<PMCountProps> = ({ data }) => {
  return (
    <div className={styles.pm}>
      <div className={styles.pm__icon}>
        <Icon fontSize="large" component={PMUserIcon} />
      </div>
      <p className={styles.pm__text}>{data || 0} PM</p>
    </div>
  );
};
export default PMCount;
