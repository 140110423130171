import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { companyFields } from "Shared/Constants/company";
import { useAppDispatch } from "Redux/store";
import {
  getCompanyBlockedList,
  getCompanyList,
  updateCompanyStatus,
} from "Redux/Company/reducers";
import { useSelector } from "react-redux";
import DeleteModal from "Components/UI/DialogWindow/DeleteModal";
import { notifySuccess } from "Components/Notify/notify";
import ListLayout from "Components/Layout/ListLayout/ListLayout";
import { getCompanyCardData } from "Shared/Utils/company";
import { ICompany, ICompanySearchParams } from "Shared/Types/company";
import {
  selectCompanyBlockedList,
  selectCompanyList,
} from "Redux/Company/slice";
import { formatBackend } from "Shared/Utils/app";
import { useSearchParams } from "react-router-dom";
import Card from "Components/UI/Card/Card/Card";
import Row from "Components/UI/Card/Row/Row";

type CompanyListProps = {
  status: "active" | "inactive";
};
const CompanyList: React.FC<CompanyListProps> = ({ status }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const isActive = status === "active";
  const companyList =
    useSelector(isActive ? selectCompanyList : selectCompanyBlockedList) || [];
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [isCard, setIsCard] = useState(true);
  const [fields, setFields] = useState(companyFields);
  const [currentCompany, setCurrentCompany] = useState<ICompany>();
  const [searchParams] = useSearchParams();

  const handleDialogClose = () => {
    setOpenDeleteModal(false);
  };

  const handleDialogOpen = (id: string) => {
    const company = companyList.find(({ id: companyId }) => id === companyId);
    setCurrentCompany(company);
    setOpenDeleteModal(true);
  };

  const handleBlock = () => {
    const onSuccess = () => {
      notifySuccess("User blocked!");
      handleDialogClose();
    };
    if (currentCompany?.id) {
      dispatch(
        updateCompanyStatus({
          id: currentCompany.id,
          data: {
            ...currentCompany,
            owner: currentCompany.owner?.id,
            status: "inactive",
          },
          onSuccess,
        })
      );
    }
  };

  const handleUnblock = () => {
    const onSuccess = () => {
      notifySuccess("User unblocked!");
      handleDialogClose();
    };
    if (currentCompany?.id) {
      dispatch(
        updateCompanyStatus({
          id: currentCompany.id,
          data: {
            ...currentCompany,
            owner: currentCompany.owner?.id,
            status: "active",
          },
          onSuccess,
        })
      );
    }
  };

  const actionClick = () => {
    if (isActive) {
      handleBlock();
    } else {
      handleUnblock();
    }
  };

  const gotoCompanyPage = async (val: string) => {
    if (val) {
      navigate(`/company/${val}`);
    }
  };

  useEffect(() => {
    const params: ICompanySearchParams = {
      page: +(searchParams.get("page") || 1),
      search: searchParams.get("search") || "",
    };

    const from = searchParams.get("from");
    const to = searchParams.get("to");
    if (from && to) {
      params.created_at_after = formatBackend(new Date(from), "YYYY-MM-DD");
      params.created_at_before = formatBackend(new Date(to), "YYYY-MM-DD");
    }
    if (!isActive) {
      dispatch(getCompanyBlockedList(params));
    } else {
      dispatch(getCompanyList(params));
    }
  }, [dispatch]);

  const companyProps = (company: ICompany) => {
    return {
      dataId: company.id || "",
      openDeleteModal: handleDialogOpen,
      fields: fields,
      onClick: gotoCompanyPage,
      cardData: getCompanyCardData(company),
      onCheck: gotoCompanyPage,
      isNeedAction: true,
      actionTitle: isActive ? "Block" : "Unblock",
      actionClick: handleDialogOpen,
    };
  };

  return (
    <ListLayout
      pagesNumber={1}
      isCard={isCard}
      setIsCard={setIsCard}
      fields={fields}
      setFields={setFields}
      filters={["search", "customer", "typeOfChassis", "date"]}
      fieldAllList={companyFields}
    >
      {isCard
        ? companyList.map((company) => (
            <Card key={company.id} {...companyProps(company)} />
          ))
        : companyList.map((company) => (
            <Row key={company.id} {...companyProps(company)} />
          ))}
      <DeleteModal
        open={openDeleteModal}
        handleClose={handleDialogClose}
        title={isActive ? "Block" : "Unblock"}
        text="Are you sure...?"
        accept={actionClick}
        acceptTitle={isActive ? "Block" : "Unblock"}
        color={isActive ? "red" : "green"}
      />
    </ListLayout>
  );
};

export default CompanyList;
