import React, { useEffect, useState } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { Controller, useFormContext } from "react-hook-form";
import { useSelector } from "react-redux";
import { useAppDispatch } from "Redux/store";
import styles from "./AutoCompleteSearchInput.module.scss";
import { selectServiceList } from "Redux/Service/slice";
import { getServiceList } from "Redux/Service/reducer";
import { IService } from "Shared/Types/service";
import { componentsProps } from "./LaborRateSearch";
import { useNavigate } from "react-router";
import { readObj } from "Service/storage";
import { ArrowDownBlackIcon } from "Assets/index";

interface SearchProps {
  name: string;
  service_name: string;
  required?: boolean;
  label?: string;
  isAdd?: boolean;
  beforeCreateNew?: () => void;
  onKeyDown?: (e: React.KeyboardEvent<HTMLImageElement>) => void;
  index?: number;
}

export const ComplaintServiceSearch: React.FC<SearchProps> = ({
  name,
  required,
  label,
  service_name,
  beforeCreateNew,
  isAdd,
  onKeyDown,
  index,
}) => {
  const { control, setValue, setError } = useFormContext();
  const services = useSelector(selectServiceList) || [];
  const navigate = useNavigate();

  const goToCreateNew = () => {
    beforeCreateNew?.();
    navigate("/services/new", { state: "back" });
  };
  const dispatch = useAppDispatch();
  const [currentText, setCurrentText] = useState("");
  const localSOValues = readObj("created_service_order");

  const searchServices = (text: string | null) => {
    setCurrentText(text || "");
    dispatch(
      getServiceList({
        search: text || null,
      }),
    );
  };
  const getLabel = (service: IService | null) => {
    if (service?.id) {
      return service?.name;
    }
    return currentText || "";
  };

  useEffect(() => {
    if (localSOValues?.action_items && index !== undefined) {
      setCurrentText(localSOValues.action_items[index]?.complaint);
    }
    dispatch(
      getServiceList({
        search: control._defaultValues?.service?.name || null,
      }),
    );
  }, []);
  return (
    <div className={styles.autocomplete}>
      <label data-id={name} className={styles.autocomplete__label}>
        {label}
        {required && <span className={styles.autocomplete__error}>*</span>}
      </label>
      <Controller
        name={name}
        control={control}
        defaultValue={control._defaultValues.complaint}
        render={({ field }) => (
          <Autocomplete
            {...field}
            options={services}
            getOptionLabel={getLabel}
            onChange={(_, value) => {
              if (!value && currentText) {
                setError(name, {
                  message: "Complaint is required",
                  type: "required",
                });
              }
              field.onChange(value?.name || currentText);
              setValue(service_name, value);
              setCurrentText(value?.name || currentText);
            }}
            onBlur={() => {
              field.onBlur();
              field.onChange(currentText);
            }}
            disablePortal={true}
            componentsProps={componentsProps}
            popupIcon={<ArrowDownBlackIcon/>}

            renderInput={(params) => (
              <TextField
                sx={{
                  ".MuiOutlinedInput-notchedOutline": {
                    borderStyle: "none",
                  },
                }}
                inputRef={field.ref}
                onKeyDown={onKeyDown}
                classes={{ root: styles.autocomplete__input }}
                name={name}
                required={required}
                onChange={(event) => {
                  searchServices(event.target.value);
                }}
                {...params}
              />
            )}
            noOptionsText={
              isAdd && <label onClick={goToCreateNew}>+ Add new Service</label>
            }
          />
        )}
      />
    </div>
  );
};
