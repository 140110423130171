import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { IShop, IShopList } from "Shared/Types/shop";
import {
  addShop,
  deleteShop,
  getShop,
  getShopList,
  updateShop,
} from "Redux/Shop/reducer";

interface ICompanyRequestsState {
  shopList: IShopList[];
  currentShop?: IShop | undefined;
  loading: boolean;
  numPage?: number;
}

const initialState: ICompanyRequestsState = {
  shopList: [],
  loading: false,
  currentShop: undefined,
};

const shopSlice = createSlice({
  name: "shop",
  initialState,
  reducers: {
    removeCurrentShop(state) {
      state.currentShop = undefined;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getShopList.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getShopList.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(getShopList.fulfilled, (state, action) => {
      state.loading = false;
      state.shopList = action.payload.results;
      state.numPage = action.payload.num_pages;
    });

    builder.addCase(getShop.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getShop.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(getShop.fulfilled, (state, action) => {
      state.loading = false;
      state.currentShop = action.payload;
    });

    builder.addCase(addShop.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(addShop.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(addShop.fulfilled, (state, action) => {
      state.loading = false;
      state.currentShop = action.payload;
    });

    builder.addCase(deleteShop.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(deleteShop.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(deleteShop.fulfilled, (state) => {
      state.loading = false;
    });

    builder.addCase(updateShop.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateShop.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(updateShop.fulfilled, (state) => {
      state.loading = false;
    });
  },
});

export const { removeCurrentShop } = shopSlice.actions;

export const selectCurrentShop = (state: RootState) => state.shop.currentShop;
export const selectShopLoading = (state: RootState) => state.shop.loading;
export const selectShopNumPage = (state: RootState) => state.shop.numPage;
export const selectShopList = (state: RootState) => state.shop.shopList;

export default shopSlice;
