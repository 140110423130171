import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "Service/api/api";
import {
  IDispatchCallback,
  IDispatchCallbackWithId,
  IDispatchCallbackWithoutData,
} from "Shared/Types/common";
import {
  IUnitTypeSearchParams,
  IUnitTypeAdd,
  IUnitTypeUpdate,
} from "Shared/Types/unit-type";
import { errorHandler } from "Shared/Utils/app";

const getUnitTypeList = createAsyncThunk(
  "unitType/getUnitTypesList",
  async (params: IUnitTypeSearchParams | null) => {
    try {
      const response = await api.unitType.getUnitTypeList(params);
      return response.data;
    } catch (error) {
      if (error instanceof Error) {
        errorHandler(error);
      }
      throw error;
    }
  }
);

const getUnitType = createAsyncThunk(
  "unitType/getUnitType",
  async (id: string) => {
    try {
      const response = await api.unitType.getUnitType(id);
      return response.data;
    } catch (error) {
      if (error instanceof Error) {
        errorHandler(error);
      }
      throw error;
    }
  }
);

const addUnitType = createAsyncThunk(
  "unitType/addUnitType",
  async ({ data, onSuccess }: IDispatchCallback<IUnitTypeAdd>) => {
    try {
      const response = await api.unitType.addUnitType(data);
      onSuccess?.();
      return response.data;
    } catch (error) {
      if (error instanceof Error) {
        errorHandler(error, true);
      }
      throw error;
    }
  }
);

const updateUnitType = createAsyncThunk(
  "unitType/updateUnitType",
  async ({ id, data, onSuccess }: IDispatchCallbackWithId<IUnitTypeUpdate>) => {
    try {
      const response = await api.unitType.updateUnitType(id, data);
      onSuccess?.();
      return response.data;
    } catch (error) {
      if (error instanceof Error) {
        errorHandler(error);
      }
      throw error;
    }
  }
);

const deleteUnitType = createAsyncThunk(
  "unitType/deleteUnitType",
  async ({ id, onSuccess }: IDispatchCallbackWithoutData) => {
    try {
      const response = await api.unitType.deleteUnitType(id);
      onSuccess?.();
      return response.data;
    } catch (error) {
      if (error instanceof Error) {
        errorHandler(error, true);
      }
      throw error;
    }
  }
);

export {
  getUnitTypeList,
  getUnitType,
  addUnitType,
  updateUnitType,
  deleteUnitType,
};
