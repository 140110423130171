import React, { useEffect, useState } from "react";
import styles from "./Calendar.module.scss";
import dayjs from "dayjs";
import cn from "classnames";
import { Divider } from "@mui/material";

type CalendarProps = {
  isLong?: boolean;
};
type DayProps = {
  day: number;
  date: number;
};
const weekDays = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sut"];
const Calendar: React.FC<CalendarProps> = ({ isLong = false }) => {
  const [days, setDays] = useState<DayProps[]>([]);
  const [currenMonth, setCurrentMonth] = useState<string>("");
  useEffect(() => {
    const dayCount = isLong ? 7 : 4;
    const arr = [];
    let today = dayjs();
    const month = today.format("MMMM");
    for (let i = 0; i < dayCount; i++) {
      if (today.format("MMMM") !== month) break;
      arr.push({
        day: today.day(),
        date: today.date(),
      });

      today = today.add(1, "day");
    }

    setDays(arr);
    setCurrentMonth(month);
  }, []);
  return (
    <div className={cn(styles.calendar, isLong && styles.calendar__long)}>
      <div className={styles.calendar__head}>{currenMonth}</div>
      <div className={styles.calendar__body}>
        <div className={styles.calendar__body__week}>
          {days.map(({ day, date }) => (
            <div className={styles.calendar__body__week__day} key={date}>
              {weekDays[day]}
            </div>
          ))}
        </div>
        <Divider
          variant="fullWidth"
          color={"#fff"}
          className={styles.calendar__body__separator}
        />
        <div className={styles.calendar__body__month}>
          {days.map(({ date }) => (
            <div className={styles.calendar__body__month__day} key={date}>
              <div>{date}</div>
              <div className={styles.calendar__body__month__day__orders}>
                3 Invoices <br /> is pending
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
export default Calendar;
