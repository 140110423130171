import React from "react";

import Layout from "Components/Layout/Layout";
import { EmployeeUpdate } from "Containers/Employee/EmployeeUpdate/EmployeeUpdate";
import { useSelector } from "react-redux";
import { selectCurrentUser } from "Redux/User/userSlice";
import { useBreadcrumb } from "Shared/Hooks/use-breadcrumb";
import { getBreads } from "Shared/Utils/user";

export const EmployeeUpdatePage: React.FC = () => {
  const employee = useSelector(selectCurrentUser);

  useBreadcrumb({
    links: getBreads("Update" + " " + (employee?.last_name || "")),
    option: { upd: employee?.last_name },
  });
  return (
    <Layout>
      <EmployeeUpdate />
    </Layout>
  );
};
