import Layout from "Components/Layout/Layout";
import React from "react";
import { BreadcrumbItem } from "Components/Breadcrumb/Breadcrumb";
import { useBreadcrumb } from "Shared/Hooks/use-breadcrumb";
import { PartDashboard } from "Containers/Part/PartDashboard/PartDashboard";

export const PartDashboardPage: React.FC = () => {
  const breads: BreadcrumbItem[] = [{ title: "Parts", link: "/parts" }];
  useBreadcrumb({
    links: breads,
    createTitle: "Part",
    isNeedCreateBtn: true,
    createLink: "/parts/inventory/new",
  });
  return (
    <Layout>
      <PartDashboard />
    </Layout>
  );
};
