import React, { HTMLAttributes } from "react";
import styles from "./Block.module.scss";
import cn from "classnames";

type BlockProps = HTMLAttributes<HTMLDivElement> & {
  mr?: number;
  mt?: number;
  mb?: number;
  ml?: number;
  zIndex?: number;
  isBlur?: boolean;
  isBorder?: boolean;
  title?: string;
};

const Block: React.FC<BlockProps> = ({
  children,
  mr,
  mt,
  mb,
  ml,
  className,
  zIndex,
  isBlur,
  isBorder,
  title,
  ...props
}) => {
  return (
    <div
      className={cn(
        styles.block,
        isBlur && styles.block_blur,
        isBorder && styles.block_border,
        className
      )}
      style={{
        marginBottom: `${mb}px`,
        marginTop: `${mt}px`,
        marginLeft: `${ml}px`,
        marginRight: `${mr}px`,
        zIndex: zIndex,
      }}
      {...props}
    >
      {title && <h3>{title}</h3>}
      {children}
    </div>
  );
};
export default Block;
