import React, { useEffect, useRef, useState } from "react";
import styles from "./Profile.module.scss";
import { Icon } from "@mui/material";
import { DeleteIcon, EditIcon } from "Assets/index";
import ConfirmAvatarModal from "Components/Headers/Profile/ConfirmAvatarModal";
import ConfirmDeleteModal from "Components/Headers/Profile/ConfirmDeleteModal";
import { useSelector } from "react-redux";
import { selectAccount } from "Redux/Auth/slice";

type OptionsProps = {
  closeOptions: () => void;
};

const Options: React.FC<OptionsProps> = ({ closeOptions }) => {
  const moreContentRef = useRef<HTMLDivElement>(null);
  const account = useSelector(selectAccount);
  const [confirmModal, setConfirmModal] = useState<boolean>(false);
  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const [avatar, setAvatar] = useState<File | null>();

  const toggleConfirmModal = () => setConfirmModal(!confirmModal);
  const toggleDeleteModal = () => {
    setDeleteModal(!deleteModal);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (
      moreContentRef.current &&
      !moreContentRef.current?.contains(event.target as Node)
    ) {
      closeOptions();
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const onChooseAvatar = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = e.target.files?.[0];
    if (selectedFile) {
      setAvatar(selectedFile);
      toggleConfirmModal();
    }
  };

  return (
    <div ref={moreContentRef} className={styles.options}>
      {account?.avatar ? (
        <>
          <div
            className={styles.options__item}
            onClick={(event) => {
              event.stopPropagation();
              toggleDeleteModal();
            }}
          >
            <Icon component={DeleteIcon} /> Delete
          </div>
          <label htmlFor="avatar" className={styles.options__item}>
            <Icon component={EditIcon} /> Edit
            <input
              onChange={(e) => onChooseAvatar(e)}
              style={{ display: "none" }}
              id="avatar"
              type="file"
              accept="image/*"
            />
          </label>
        </>
      ) : (
        <label htmlFor="avatar" className={styles.options__item}>
          <Icon component={EditIcon} />
          Add photo
          <input
            onChange={(e) => onChooseAvatar(e)}
            style={{ display: "none" }}
            id="avatar"
            type="file"
            accept="image/*"
          />
        </label>
      )}

      {avatar && (
        <ConfirmAvatarModal
          closeOptions={closeOptions}
          open={confirmModal}
          onClose={toggleConfirmModal}
          img={avatar}
        />
      )}
      <ConfirmDeleteModal
        closeOptions={closeOptions}
        open={deleteModal}
        onClose={toggleDeleteModal}
      />
    </div>
  );
};

export default Options;
