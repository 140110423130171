import React from "react";
import { IActionPart, IActionPartFields } from "Shared/Types/action-item-part";
import InfoOrChangeField from "Components/UI/InfoOrChangeField/InfoOrChangeField";
import { useAppDispatch } from "Redux/store";
import { updateActionPartByField } from "Redux/ActionPart/reducer";
import { notifyError, notifySuccess } from "Components/Notify/notify";
import { getActionItem } from "Redux/ActionItem/reducer";

export const renderCellAIPart = (
  cellData: any,
  columnId: string,
  part: IActionPart
) => {
  const dispatch = useAppDispatch();
  const onSuccess = () => {
    notifySuccess("Action item is Updated");
    dispatch(getActionItem(part.action_item));
  };
  const onError = (error?: string) => {
    notifyError(error || "Someting went wrong");
  };
  const saveField = (field: IActionPartFields, value: string) => {
    dispatch(
      updateActionPartByField({
        data: { field, value },
        id: part.id,
        onSuccess,
        onError,
      })
    );
  };
  if (columnId === "markup") {
    if (part.selling_price && part.price) {
      return (
        (((part.selling_price - part.price) / part.price) * 100).toFixed(2) +
        "%"
      );
    }
    return 0 + "%";
  }

  if (columnId === "margin") {
    if (part.selling_price && part.price) {
      return (
        (
          ((part.selling_price - part.price) / part.selling_price) *
          100
        ).toFixed(2) + "%"
      );
    }
    return 0 + "%";
  }

  if (columnId === "amount") {
    return ((part.selling_price || 0) * part.quantity).toFixed(2);
  }
  if (columnId === "selling_price") {
    return (
      <InfoOrChangeField
        value={cellData}
        name="selling_price"
        onFieldChange={(text: string | null | number) =>
          saveField("selling_price", (text || "").toString())
        }
        type="number"
      />
    );
  }
  return cellData;
};
