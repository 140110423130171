import {
  PurchaseOrderIcon,
  EstimatesIcon,
  CycleCountIcon,
  InventoryIcon,
} from "Assets/index";
import FilterCard, {
  FilterStatusColor,
} from "Components/Cards/FilterCard/FilterCard";
import React from "react";
import styles from "./PartFilter.module.scss";
import { Grid } from "@mui/material";

const PartFilter = () => {
  const filterCards = [
    { title: "Part", icon: EstimatesIcon, color: "blue", link: "/parts" },
    {
      title: "Inventory",
      icon: InventoryIcon,
      color: "green",
      link: "/parts/inventory",
    },
    {
      title: "Cycle count",
      icon: CycleCountIcon,
      color: "orange",
      link: "/parts/cycle-count",
    },
    {
      title: "Purchase order",
      icon: PurchaseOrderIcon,
      color: "orange",
      link: "/parts/purchase-orders",
    },
  ];

  return (
    <Grid className={styles.filter}>
      {filterCards.map((card, index) => (
        <FilterCard
          key={index}
          title={card.title}
          icon={card.icon}
          color={card.color as FilterStatusColor}
          link={card.link}
        />
      ))}
    </Grid>
  );
};
export default PartFilter;
