import Layout from "Components/Layout/Layout";
import React from "react";
import CompanyContainer from "Containers/Company/CompanyContainer";
import { useBreadcrumb } from "Shared/Hooks/use-breadcrumb";
import { getCompanyBreadCrumbs } from "Shared/Utils/company";

export const Company: React.FC = () => {
  useBreadcrumb({ links: getCompanyBreadCrumbs() });

  return (
    <Layout>
      <CompanyContainer />
    </Layout>
  );
};
