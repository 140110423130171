import React, { useEffect, useState } from "react";
import styles from "./Notification.module.scss";
import { BellIcon, CloseIcon } from "Assets/index";
import Icon from "Components/UI/Icon/Icon";
import { Menu } from "@mui/material";
import { useSelector } from "react-redux";
import { useAppDispatch } from "Redux/store";
import { selectNotificationList } from "Redux/Notification/slice";
import { getOrderNotification } from "Redux/Notification/reducer";
import NotifcationItem from "./NotificationItem/NotifcationItem";

const Notifcation: React.FC = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const notifications = useSelector(selectNotificationList);
  const dispatch = useAppDispatch();
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  useEffect(() => {
    dispatch(getOrderNotification());
  }, []);

  return (
    <div>
      <div className={styles.notif} onClick={handleClick}>
        <Icon color="transparent" component={BellIcon} />
        {notifications.length > 0 && (
          <span className={styles.notif__count}>{notifications.length}</span>
        )}
      </div>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        classes={{ root: styles.notif__menu }}
      >
        <div className={styles.notif__menu__head}>
          <h3>Notification</h3>
          <Icon component={CloseIcon} onClick={handleClose} />
        </div>
        {notifications.map((notif) => (
          <NotifcationItem
            notif={notif}
            handleClose={handleClose}
            key={notif.id}
          />
        ))}
      </Menu>
    </div>
  );
};
export default Notifcation;
