import React from "react";
import { Grid } from "@mui/material";
import InputField from "Components/UI/Inputs/InputField";
import { Control } from "react-hook-form";
import { IVendorForm } from "./VendorForm";
import { AddressFields } from "Components/AddressField/AddressField";

type AddressInfoProps = {
  control: Control<IVendorForm>;
};
const AddressInfo: React.FC<AddressInfoProps> = ({ control }) => {
  return (
    <div>
      <h4>Address Information</h4>
      <Grid container spacing={1} rowSpacing={-1}>
        <AddressFields
          control={control}
          requiredFields={["street_address", "street_address_2"]}
          isNeedPostalCode
          zip_code="zip_code"
        />
      </Grid>
    </div>
  );
};
export default AddressInfo;
