import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import InputField from "Components/UI/Inputs/InputField";
import SelectField from "Components/UI/SelectField/SelectField";
import styles from "Components/Order/Estimate/EstimateForm/ComplaintServiceForm/ComplaintService.module.scss";
import Icon from "Components/UI/Icon/Icon";
import { EditIcon } from "Assets/index";
import Checkbox from "Components/UI/Inputs/Checkbox";
import { getLaborRate, getLaborRateList } from "Redux/LaborRate/reducer";
import { useSelector } from "react-redux";
import { selectLaborRate, selectLaborRateList } from "Redux/LaborRate/slice";
import { useFormContext } from "react-hook-form";
import { useAppDispatch } from "Redux/store";
import { selectCurrentEstimateOrder } from "Redux/Estimate/slice";
import { changeLaborRateOptions } from "Shared/Utils/app";

interface ComplaintServiceInputProps {
  index: number;
  price: number;
  isDisabledField: boolean;
  setPrice: React.Dispatch<React.SetStateAction<number>>;
  setComplaintValue: (field: string, value?: string | number) => void;
}

const ComplaintServiceInput: React.FC<ComplaintServiceInputProps> = ({
  index,
  price,
  setPrice,
  setComplaintValue,
  isDisabledField,
}) => {
  const currentEstimate = useSelector(selectCurrentEstimateOrder);
  const laborRate = useSelector(selectLaborRate);
  const laborRateList = useSelector(selectLaborRateList) || [];
  const [edit, setEdit] = useState(true);
  const dispatch = useAppDispatch();

  const { control, watch } = useFormContext();

  const hours = watch(`action_items.${index}.service.invoice_hours`);

  const onBlur = () => {
    setEdit(true);
    if (laborRate?.rate)
      setComplaintValue("invoice_hours", (price / laborRate?.rate).toFixed(3));
  };

  const fetchLaborRate = (id: string) => {
    dispatch(getLaborRate(id));
  };

  const updatePriceAndHours = () => {
    if (laborRate?.rate) {
      setPrice(+(laborRate?.rate * hours).toFixed(0));
    }
    setComplaintValue("invoice_hours", Number(hours));
    setComplaintValue("estimate", currentEstimate?.id);
    setComplaintValue("servicePriceTotal", price);
  };

  useEffect(updatePriceAndHours, [laborRate, hours]);

  useEffect(() => {
    dispatch(getLaborRateList(null));
  }, []);

  return (
    <>
      <Grid item xs={4}>
        <InputField
          name={`action_items.${index}.service.complaint`}
          label="Service"
          withController
          control={control}
          disabled={isDisabledField}
        />
      </Grid>
      <Grid item xs={2}>
        <InputField
          name={`action_items.${index}.service.invoice_hours`}
          label="Hours"
          withController
          control={control}
          type="number"
          validatePositiveValue
          disabled={isDisabledField}
        />
      </Grid>
      <Grid item xs={2.5}>
        <SelectField
          name={`action_items.${index}.service.labor_rate`}
          label={`Labor rate`}
          placeholder={`Select one`}
          withControl
          control={control}
          options={changeLaborRateOptions(laborRateList)}
          onChange={(id) => {
            if (typeof id === "string") {
              fetchLaborRate(id);
            }
          }}
          isDisabled={isDisabledField}
        />
      </Grid>
      <Grid item xs={1}>
        <div className={styles.form__price}>
          {edit ? (
            <>
              <span>{price}$</span>
              <Icon
                component={EditIcon}
                onClick={() => setEdit(false)}
                fontSize="small"
              />
            </>
          ) : (
            <InputField
              name={"rate"}
              value={price}
              onChange={(event) => setPrice(+event.target.value)}
              type="number"
              onBlur={onBlur}
              validatePositiveValue
            />
          )}
        </div>
      </Grid>
      <Grid item xs={1.5} display="flex" justifyContent="end">
        <Checkbox
          label={"Authorized"}
          labelPosition={"left"}
          className={styles.form__checkbox}
          name={`action_items.${index}.service.authorized`}
          withController
          control={control}
          disabled={isDisabledField}
        />
      </Grid>
    </>
  );
};

export default ComplaintServiceInput;
