import React from "react";
import Layout from "Components/Layout/Layout";
import SettingsLayout from "Components/Settings/Layout/Layout";
import ComponentUpdate from "Containers/ComponentSystem/Component/UpdateComponent";
import { BreadcrumbItem } from "Components/Breadcrumb/Breadcrumb";
import { useBreadcrumb } from "Shared/Hooks/use-breadcrumb";
import { useSelector } from "react-redux";
import { selectCurrentComponent } from "Redux/Component/componentSlice";

export const ComponentSystemUpdatePage: React.FC = () => {
  const component = useSelector(selectCurrentComponent);
  const breads: BreadcrumbItem[] = [
    { title: "Settings", link: "/settings/part-settings/categories" },
    { title: "Component & System", link: "/settings/component-system" },
    { title: `Update ${component?.component.name || ""}`, link: "" },
  ];

  useBreadcrumb({ links: breads, option: { upd: component?.component.name } });
  return (
    <Layout>
      <SettingsLayout>
        <ComponentUpdate />
      </SettingsLayout>
    </Layout>
  );
};
