import React, { useEffect, useState } from "react";
import Modal from "Components/UI/Modal/Modal";
import Button from "Components/UI/Button/Button";
import { Grid } from "@mui/material";
import SelectField from "Components/UI/SelectField/SelectField";
import { useSelector } from "react-redux";
import { selectUserList } from "Redux/User/userSlice";
import { getUserList } from "Redux/User/reducer";
import { useAppDispatch } from "Redux/store";
import styles from "./EstimateConvertToSOModal.module.scss";
import {
  convertToServiceOrder,
  updateEstimateOrderByField,
} from "Redux/Estimate/reducer";
import { selectCurrentEstimateOrder } from "Redux/Estimate/slice";
import { IEstimatesUpdateFields } from "Shared/Types/estimate";
import { notifyError, notifySuccess } from "Components/Notify/notify";

function EstimateConvertToSoModal() {
  const employeeList = useSelector(selectUserList) || [];
  const currentEstimate = useSelector(selectCurrentEstimateOrder);
  const dispatch = useAppDispatch();
  const [openModal, setOpenModal] = useState(false);

  const handleDialogClose = () => {
    setOpenModal(false);
  };

  const handleUpdateEstimateOrder = (
    field: IEstimatesUpdateFields,
    value: string,
  ) => {
    if (currentEstimate?.id) {
      dispatch(
        updateEstimateOrderByField({
          id: currentEstimate.id,
          data: { field, value },
        }),
      );
    }
  };

  const onSuccess = () => {
    notifySuccess("Estimate successfully converted!");
    setOpenModal(false);
  };

  const handleCreateSO = () => {
    if (currentEstimate?.id) {
      dispatch(convertToServiceOrder({ id: currentEstimate.id, onSuccess }));
    }
  };

  useEffect(() => {
    dispatch(getUserList({}));
  }, []);

  return (
    <div>
      <Button onClick={() => setOpenModal(true)}>Convert to SO</Button>
      <Modal
        open={openModal}
        onClose={handleDialogClose}
        title="Please choose to a mechanic"
        isClosed
      >
        <Grid container rowGap={3} className={styles.modal}>
          <Grid item xs={12}>
            <SelectField
              name="lead_technician"
              label="Who will be the lead technician?"
              options={employeeList.map((customer) => ({
                label: `${customer.first_name} ${customer.last_name}`,
                value: customer.id,
              }))}
              onChange={(value) => {
                if (typeof value === "string") {
                  handleUpdateEstimateOrder("lead_technician", value);
                }
              }}
              containerClassName={styles.modal__select}
            />
          </Grid>
          <Grid item xs={12} className={styles.modal__action}>
            <Button onClick={handleCreateSO}>Convert to SO</Button>
          </Grid>
        </Grid>
      </Modal>
    </div>
  );
}

export default EstimateConvertToSoModal;
