import React from "react";
import { Grid } from "@mui/material";
import InfoField from "Components/UI/InfoField/InfoField";
import InputField from "Components/UI/Inputs/InputField";
import usePurchaseOrderFields, {
  TField,
} from "Shared/Hooks/purchase-order/use-purchase-order-fields";
import { useFormContext } from "react-hook-form";
import styles from "./OrderItem.module.scss";

type OrderItemProps = {
  orderIndex: number;
};

const OrderItem: React.FC<OrderItemProps> = ({ orderIndex }) => {
  const { control, getValues } = useFormContext();
  const { fields } = usePurchaseOrderFields();

  const getName = (name: string) => `order_items[${orderIndex}].${name}`;
  const renderInput = (field: TField) => {
    switch (field.type) {
      case "info":
        return (
          <InfoField
            label={field.label}
            value={getValues(getName(field.name))}
          />
        );
      default:
        return (
          <InputField
            label={field.label}
            required={field.required}
            type={field.type}
            name={getName(field.name)}
            control={control}
            disabled={field.disabled}
            withController
          />
        );
    }
  };

  return (
    <Grid container columnSpacing={2} mb="20px" mt="20px">
      <Grid item xs={12}>
        <div className={styles.order__title}>
          {getValues(getName("vendor"))?.vendor_name}
        </div>
      </Grid>

      {fields.map((field) => (
        <Grid item xs={field.xs} key={field.name}>
          {renderInput(field)}
        </Grid>
      ))}
    </Grid>
  );
};
export default OrderItem;
