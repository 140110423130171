import React, { useEffect, useState } from "react";
import Block from "Components/UI/Block/Block";
import InfoField from "Components/UI/InfoField/InfoField";
import { useNavigate, useParams } from "react-router";
import { deleteShop, getShop } from "Redux/Shop/reducer";
import { useAppDispatch } from "Redux/store";
import { useSelector } from "react-redux";
import { selectCurrentShop } from "Redux/Shop/slice";
import { getTaxRateList } from "Redux/TaxRate/recuder";
import { getSupplyRateList } from "Redux/SupplyRate/reducer";
import { getLaborRateList } from "Redux/LaborRate/reducer";
import { selectLaborRateList } from "Redux/LaborRate/slice";
import { selectTaxRateList } from "Redux/TaxRate/slice";
import { selectSupplyRateList } from "Redux/SupplyRate/slice";
import { technicianPayTypeOptions } from "Components/Shop/Options";
import styles from "./Shop.module.scss";
import { DeleteIcon, EditIcon } from "Assets/index";
import IconButton from "Components/UI/IconButton/IconButton";
import DeleteModal from "Components/UI/DialogWindow/DeleteModal";

const ShopDetail = () => {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [showDelete, setShowDelete] = useState(false);
  const shop = useSelector(selectCurrentShop);
  const laborRateList = useSelector(selectLaborRateList) || [];
  const taxRateList = useSelector(selectTaxRateList) || [];
  const supplyRateList = useSelector(selectSupplyRateList) || [];

  const goToEditPage = () => {
    navigate(`/shops/edit/${id}`);
  };

  const handleDialogClose = () => {
    setShowDelete(false);
  };

  const handleDialogOpen = () => {
    setShowDelete(true);
  };

  const handleDeleteShop = async () => {
    if (id) {
      await dispatch(deleteShop({ id, onSuccess: () => navigate("/shops") }));
    }
  };

  const getValue = (name: string, value?: number | string) => {
    if (name === "taxRate") {
      return taxRateList.find((tax) => tax.id === value)?.title;
    }
    if (name === "laborRate") {
      return laborRateList.find((labor) => labor.id === value)?.name;
    }
    if (name === "supplyRate") {
      return supplyRateList.find((supply) => supply.id === value)?.title;
    }
    if (name === "payType") {
      return technicianPayTypeOptions.find((type) => type.value === "" + value)
        ?.label;
    }
  };

  useEffect(() => {
    if (id) {
      dispatch(getShop(id));
    }
  }, [id]);

  useEffect(() => {
    dispatch(getTaxRateList());
    dispatch(getSupplyRateList());
    dispatch(getLaborRateList(null));
  }, []);

  return (
    <div className={styles.detail}>
      <div className={styles.detail__head}>
        <div className={styles.detail__head__action}>
          <IconButton icon={EditIcon} onClick={goToEditPage} />
          <IconButton icon={DeleteIcon} onClick={handleDialogOpen} />
        </div>
      </div>
      <Block className={styles.detail__block}>
        <h3>Shop Info</h3>
        <InfoField
          label="Shop Name"
          value={shop?.shop_name}
          mt={17}
          labelColor="gray"
        />
        <InfoField
          label="Shop Manager"
          value={shop?.owner?.first_name + " " + shop?.owner?.last_name}
          mt={17}
          labelColor="gray"
        />
        <InfoField
          label="Email"
          value={shop?.email}
          mt={17}
          labelColor="gray"
        />
        <InfoField
          label="Phone"
          value={shop?.phone}
          mt={17}
          labelColor="gray"
        />
        <InfoField label="Fax" value={shop?.fax} labelColor={"grey"} />
        <InfoField
          label="Website"
          value={shop?.website}
          mt={17}
          labelColor="gray"
        />
        <InfoField
          label="Time Zone"
          value={shop?.timezone}
          mt={17}
          labelColor="gray"
        />
      </Block>
      <Block className={styles.detail__block} mt={20}>
        <h3>Rate</h3>
        <InfoField
          label="Tax Rate"
          value={getValue("taxRate", shop?.tax_rate?.toString())}
          mt={17}
          labelColor="gray"
        />
        <InfoField
          label="Technician Pay Type:"
          value={getValue("payType", shop?.technician_pay_type)}
          mt={17}
          labelColor="gray"
        />
        <InfoField
          label="Technician Pay Rate: "
          value={shop?.technician_pay_rate}
          mt={17}
          labelColor="gray"
        />
        <InfoField
          label="Overhead"
          value={shop?.phone}
          mt={17}
          labelColor="gray"
        />
        <InfoField
          label="Default Labor Rate:"
          value={getValue("laborRate", shop?.labor_rate)}
          mt={17}
          labelColor="gray"
        />
        <InfoField
          label="Default Supplies Rate:"
          value={getValue("supplyRate", shop?.supply_rate)}
          mt={17}
          labelColor="gray"
        />
        <InfoField
          label="Physical Address"
          value={shop?.physical_address}
          mt={17}
          labelColor="gray"
        />
      </Block>
      <DeleteModal
        open={showDelete}
        handleClose={handleDialogClose}
        title="Delete"
        text="Are you sure...?"
        acceptTitle="Delete"
        accept={handleDeleteShop}
      />
    </div>
  );
};

export default ShopDetail;
