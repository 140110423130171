import { BreadcrumbItem } from "Components/Breadcrumb/Breadcrumb";
import Layout from "Components/Layout/Layout";
import ContactUpdate from "Containers/Contacts/ContactUpdate/ContactUpdate";
import { selectCurrentContact } from "Redux/Contact/slice";
import { useBreadcrumb } from "Shared/Hooks/use-breadcrumb";
import React from "react";
import { useSelector } from "react-redux";

export const ContactUpdatePage: React.FC = () => {
  const contact = useSelector(selectCurrentContact);
  const breads: BreadcrumbItem[] = [
    { title: "Contacts", link: "/customers?tab=Contacts" },
    {
      title: `Update ${contact?.first_name + " " + contact?.last_name}`,
      link: "",
    },
  ];

  useBreadcrumb({ links: breads, option: { upd: contact?.last_name } });
  return (
    <Layout>
      <ContactUpdate />
    </Layout>
  );
};
