import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";
import {
  getPurchaseOrderList,
  getPurchaseOrder,
  addPurchaseOrder,
  deletePurchaseOrder,
} from "./reducer";
import { IPurchaseOrder } from "Shared/Types/purchase-order";

interface IPurchaseOrderState {
  purchaseOrderList?: IPurchaseOrder[];
  currentPurchaseOrder?: IPurchaseOrder | null;
  loading: boolean;
  numPage?: number;
}

const initialState: IPurchaseOrderState = {
  purchaseOrderList: [],
  currentPurchaseOrder: null,
  loading: false,
};

const purchaseOrderSlice = createSlice({
  name: "purchaseOrder",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getPurchaseOrder.pending, (state) => {
      state.loading = true;
      state.currentPurchaseOrder = null;
    });
    builder.addCase(getPurchaseOrder.rejected, (state) => {
      state.loading = false;
      state.currentPurchaseOrder = null;
    });
    builder.addCase(getPurchaseOrder.fulfilled, (state, action) => {
      state.loading = false;
      state.currentPurchaseOrder = action.payload;
    });

    builder.addCase(addPurchaseOrder.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(addPurchaseOrder.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(addPurchaseOrder.fulfilled, (state) => {
      state.loading = false;
    });

    builder.addCase(getPurchaseOrderList.pending, (state) => {
      state.loading = true;
      state.purchaseOrderList = state.purchaseOrderList || [];
    });
    builder.addCase(getPurchaseOrderList.rejected, (state) => {
      state.loading = false;
      state.purchaseOrderList = state.purchaseOrderList || [];
    });
    builder.addCase(getPurchaseOrderList.fulfilled, (state, action) => {
      state.loading = false;
      state.purchaseOrderList = action.payload;
      state.numPage = 1;
    });

    builder.addCase(deletePurchaseOrder.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(deletePurchaseOrder.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(deletePurchaseOrder.fulfilled, (state) => {
      state.loading = false;
    });
  },
});

export const selectCurrentPurchaseOrder = (state: RootState) =>
  state.purchaseOrder.currentPurchaseOrder;
export const selectPurchaseOrderLoading = (state: RootState) =>
  state.purchaseOrder.loading;
export const selectPurchaseOrderNumPage = (state: RootState) =>
  state.purchaseOrder.numPage;
export const selectPurchaseOrderList = (state: RootState) =>
  state.purchaseOrder.purchaseOrderList;

export default purchaseOrderSlice;
