import React from "react";
import {
  Switch as MUISwitch,
  SwitchProps as MUISwitchProps,
} from "@mui/material";
import { Control, Controller } from "react-hook-form";
import cn from "classnames";
import styles from "./Switch.module.scss";

type SwitchProps = Omit<MUISwitchProps, "label"> & {
  label?: string;
  control?: Control<any>;
  labelRight?: boolean;
  labelColor?: "gray";
};

const Switch: React.FC<SwitchProps> = ({
  label,
  control,
  name = "name",
  className,
  required,
  labelRight,
  labelColor,
  ...props
}) => {
  return (
    <div className={styles.switch}>
      {label && !labelRight && (
        <label
          className={
            (styles.switch__label,
            labelColor && styles[`switch__label_${labelColor}`])
          }
        >
          {label}
        </label>
      )}
      {control ? (
        <Controller
          control={control}
          name={name}
          defaultValue={false}
          render={({ field }) => (
            <MUISwitch
              {...field}
              className={cn(styles.switch__input, className)}
              required={required}
              {...props}
            />
          )}
        />
      ) : (
        <MUISwitch {...props} />
      )}
      {label && labelRight && (
        <label
          className={
            (styles.switch__label,
            labelColor && styles[`switch__label_${labelColor}`])
          }
        >
          {label}
        </label>
      )}
    </div>
  );
};
export default Switch;
