import React from "react";
import Layout from "Components/Layout/Layout";
import Invoice from "Containers/Office/Invoice";

export const OfficeInvoice = () => {
  return (
    <Layout>
      <Invoice />
    </Layout>
  );
};
