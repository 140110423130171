import React from "react";
import styles from "./Requests.module.scss";
import RequestOverview from "Containers/Overview/RequestOverview/RequestOverview";
import Calendar from "Components/Calendar/Calendar";
import RequestFilter from "Components/Request/RequestFilter/RequestFilter";

const RequestContainer: React.FC = () => {
  return (
    <div className={styles.requests}>
      <RequestFilter />
      <div className={styles.requests__content}>
        <div className={styles.requests__content__overview}>
          <RequestOverview />
        </div>
        <div className={styles.requests__content__calendar}>
          <Calendar isLong />
        </div>
      </div>
    </div>
  );
};
export default RequestContainer;
