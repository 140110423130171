import React, { useEffect, useState } from "react";
import { Divider, Grid } from "@mui/material";
import styles from "./EstimateInvoiced.module.scss";
import { useSelector } from "react-redux";
import { selectTaxRateList } from "Redux/TaxRate/slice";
import SelectField from "Components/UI/SelectField/SelectField";
import { useAppDispatch } from "Redux/store";
import { getTaxRateList } from "Redux/TaxRate/recuder";
import { selectSupplyRateList } from "Redux/SupplyRate/slice";
import { getSupplyRateList } from "Redux/SupplyRate/reducer";
import {
  selectCurrentEstimateOrder,
  selectEstimatesServiceList,
} from "Redux/Estimate/slice";
import { useFormContext } from "react-hook-form";
import { IEstimateComplaint } from "Shared/Types/estimate";

interface EstimateInvoiceProps {
  isDetails: boolean;
}

const EstimateInvoiced: React.FC<EstimateInvoiceProps> = ({ isDetails }) => {
  const [taxRatePrice, setTaxRatePrice] = useState<number>(0);
  const taxRateList = useSelector(selectTaxRateList) || [];
  const supplyRateList = useSelector(selectSupplyRateList) || [];
  const currentEstimate = useSelector(selectCurrentEstimateOrder);
  const currentEstimateServicesList =
    useSelector(selectEstimatesServiceList) || [];
  const dispatch = useAppDispatch();

  const getAllPrices = () => {
    let laborPrice = 0;
    let partPrice = 0;

    if (isDetails) {
      currentEstimateServicesList.forEach((service) => {
        laborPrice +=
          (service.labor_rate?.rate || 0) * (service.invoice_hours || 0);
      });
      if (currentEstimate?.labor_and_parts_total) {
        if (laborPrice === +currentEstimate?.labor_and_parts_total) {
          partPrice = 0;
        } else {
          partPrice = +currentEstimate?.labor_and_parts_total - laborPrice;
        }
      }
    } else {
      const { getValues } = useFormContext();
      const allService: IEstimateComplaint[] | undefined =
        getValues("action_items");

      allService?.map((estimate) => {
        if (estimate.service?.id) {
          laborPrice += Number(estimate.service.servicePriceTotal) || 0;
          partPrice += Number(estimate.service.partPriceTotal) || 0;
        }
      });
    }

    return {
      laborPrice,
      partPrice,
    };
  };

  const getDefaultSupplyRate = () => {
    return supplyRateList.find((supplyRate) => supplyRate.default)?.rate || 0;
  };

  const getPriceTotal = () => {
    return (
      taxRatePrice +
      getDefaultSupplyRate() +
      getAllPrices().partPrice +
      getAllPrices().laborPrice
    );
  };

  useEffect(() => {
    dispatch(getTaxRateList());
    dispatch(getSupplyRateList());
  }, []);

  return (
    <Grid item xs={6} className={styles.invoice}>
      <div className={styles.invoice__head}>Invoiced</div>
      <Grid container width="70%" className={styles.invoice__content}>
        <Grid container item xs={12} mb="10px">
          <Grid item xs={4}>
            Shop Supplier:
          </Grid>
          <Grid item xs={8}>
            $ {getDefaultSupplyRate()}
          </Grid>
        </Grid>
        <Grid
          container
          item
          xs={12}
          display="flex"
          alignItems="center"
          mb="8px"
        >
          <Grid item xs={4}>
            Select Tax:
          </Grid>
          <Grid item xs={8}>
            <SelectField
              name={"Tax Rate"}
              options={taxRateList.map((rate) => ({
                label: rate.title,
                value: rate.rate,
              }))}
              onChange={(value) => {
                if (value) {
                  setTaxRatePrice(+value);
                }
              }}
              containerClassName={styles.invoice__content__select}
            />
          </Grid>
        </Grid>
        <Grid container item xs={12} mb="10px">
          <Grid item xs={4}>
            Tax:
          </Grid>
          <Grid item xs={8}>
            $ {taxRatePrice}
          </Grid>
        </Grid>
        <Grid container item xs={12} mb="10px">
          <Grid item xs={4}>
            Labor:
          </Grid>
          <Grid item xs={8}>
            $ {getAllPrices().laborPrice}
          </Grid>
        </Grid>
        <Grid
          container
          item
          xs={12}
          className={styles.invoice__content__item}
          mb="10px"
        >
          <Grid item xs={4}>
            Parts:
          </Grid>
          <Grid item xs={8}>
            $ {getAllPrices().partPrice}
          </Grid>
        </Grid>
        <Grid
          container
          item
          xs={12}
          className={styles.invoice__content__separator}
        >
          <Divider />
        </Grid>
        <Grid container item xs={12} className={styles.invoice__content__total}>
          <Grid item xs={4}>
            Total:
          </Grid>
          <Grid item xs={8}>
            $ {getPriceTotal()}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default EstimateInvoiced;
