import React from "react";
import styles from "./Company.module.scss";
import Calendar from "Components/Calendar/Calendar";
import CompanyFilter from "Components/Company/CompanyFilter/CompanyFilter";
import CompanyOverview from "Containers/Overview/CompanyOverview/CompanyOverview";

const CompanyContainer: React.FC = () => {
  return (
    <div className={styles.container}>
      <CompanyFilter />
      <div className={styles.container__content}>
        <div className={styles.container__content__overview}>
          <CompanyOverview />
        </div>
        <div className={styles.container__content__calendar}>
          <Calendar isLong />
        </div>
      </div>
    </div>
  );
};

export default CompanyContainer;
