import Form from "Components/UI/Form/Form";
import { LOGIN, authLinks } from "Shared/Constants/auth";
import React from "react";
import { useForm } from "react-hook-form";
import styles from "./Login.module.scss";
import { useAppDispatch } from "Redux/store";
import { yupResolver } from "@hookform/resolvers/yup";
import { login } from "Redux/Auth/reducers";
import { IAuthLogin } from "Shared/Types/auth";
import { useSelector } from "react-redux";
import { selectAuthError, selectAuthLoading } from "Redux/Auth/slice";
import AuthLayout2 from "Components/Layout/AuthLayout/AuthLayout";
import InputField from "Components/Layout/Inputs/InputField";
import Button from "Components/Layout/Button/Button";
import { loginSchema } from "Shared/Utils/auth";

const Login: React.FC = () => {
  const dispatch = useAppDispatch();
  const error = useSelector(selectAuthError);
  const loading = useSelector(selectAuthLoading);
  const { control, handleSubmit } = useForm<IAuthLogin>({
    resolver: yupResolver(loginSchema),
  });

  const onSubmit = async (data: IAuthLogin) => {
    await dispatch(login(data));
  };

  return (
    <AuthLayout2
      links={authLinks}
      title={LOGIN.TITLE}
      descrption={LOGIN.DESCRIPTION}
    >
      <Form onSubmit={handleSubmit(onSubmit)} className={styles.login}>
        <InputField
          placeholder="Login"
          required
          name="email"
          control={control}
          withController
        />

        <InputField
          placeholder="Password"
          required
          name="password"
          control={control}
          withController
          isPassword
        />
        {error && <div className={styles.login__error}>{error}</div>}

        <Button
          variant="contained"
          color="primary"
          type="submit"
          fullWidth
          loading={loading}
        >
          Login
        </Button>
      </Form>
    </AuthLayout2>
  );
};
export default Login;
