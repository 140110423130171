import { client } from "Service/axios";
import { SearchResult } from "Shared/Types/common";
import {
  IActionItemNoteAdd,
  IActionItemNoteSearchParams,
} from "Shared/Types/notes";
import {
  IActionItem,
  IActionItemAddApi,
  IActionItemApi,
  IActionItemHistory,
  IActionItemSearchParams,
  IActionItemStatus,
  IActionItemUpdate,
} from "Shared/Types/order";

const ACTION_ITEM_URL = "order/action_item/";
const ACTION_ITEM_STATUS_URL = "order/so_action_item_status/";
const ACTION_ITEM_NOTES_URL = "order/action_item_note/";

class ActionItem {
  getActionItemList(searchParam: IActionItemSearchParams | null) {
    return client.get<SearchResult<IActionItem>>(`${ACTION_ITEM_URL}`, {
      params: searchParam,
    });
  }
  getActionItem(id: string) {
    return client.get<IActionItem>(`${ACTION_ITEM_URL}${id}/`);
  }
  addActionItem(data: IActionItemAddApi) {
    return client.post(`${ACTION_ITEM_URL}create/`, data);
  }
  updateActionItem(id: string, data: IActionItemApi | FormData) {
    return client.patch(`${ACTION_ITEM_URL}${id}/update/`, data, {
      headers: { "Content-Type": "multipart/form-data" },
    });
  }
  updateActionItemField(id: string, data: IActionItemUpdate) {
    return client.patch(`${ACTION_ITEM_URL}${id}/update/`, data);
  }
  deleteActionItem(id: string) {
    return client.delete(`${ACTION_ITEM_URL}${id}/delete/`);
  }
  getActionItemStatusList() {
    return client.get<IActionItemStatus[]>(ACTION_ITEM_STATUS_URL);
  }
  entreeInternally(id: string) {
    return client.post(`${ACTION_ITEM_URL}${id}/entree_internally/`);
  }
  markAction(id: string, data: IActionItemApi) {
    return client.patch(
      `${ACTION_ITEM_URL}${id}/mark_action_as_diagnosed/`,
      data
    );
  }
  readyToInvoice(id: string) {
    return client.post(`${ACTION_ITEM_URL}${id}/ready_to_invoice/`);
  }
  underReview(id: string) {
    return client.post(`${ACTION_ITEM_URL}${id}/under_review/`);
  }
  waitingForAuth(id: string) {
    return client.post(`${ACTION_ITEM_URL}${id}/waiting_for_auth/`);
  }
  addActionItemNotes(data: IActionItemNoteAdd) {
    return client.post(`${ACTION_ITEM_NOTES_URL}`, data);
  }
  getListActionItemNotes(params: IActionItemNoteSearchParams) {
    return client.get(`${ACTION_ITEM_NOTES_URL}`, {
      params,
    });
  }
  deleteActionItemNotes(id: string) {
    return client.delete(`${ACTION_ITEM_NOTES_URL}${id}/`);
  }
  getActionItemHistory(id: string) {
    return client.get<IActionItemHistory[]>(`order/action_item_history/`, {
      params: { action_item: id },
    });
  }
}

export default ActionItem;
