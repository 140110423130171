import { deleteCompanyRequest } from "Redux/CompanyRequests/reducers";
import { useAppDispatch } from "Redux/store";
import {
  requestFieldAllList,
  requestFieldList,
} from "Shared/Constants/requests";
import { ICompanyAdd, ICompanyRequest } from "Shared/Types/company";
import React, { useCallback, useState } from "react";
import { useNavigate } from "react-router";
import RequestReject from "../RequestReject/RequestReject";
import { RequestsLayout } from "Containers/Requests/RequestsLayout/RequestsLayout";
import DeleteModal from "Components/UI/DialogWindow/DeleteModal";
import { addCompany } from "Redux/Company/reducers";
import { notifySuccess } from "Components/Notify/notify";
import Row from "Components/UI/Card/Row/Row";
import Card from "Components/UI/Card/Card/Card";
import { getCompanyRequestCardData } from "Shared/Utils/companyRequest";
import { RenderActionButton } from "Components/Request/RenderActionButton/RenderActionButton";

interface RequestListProps {
  filteredBy?: "waiting" | "active" | "inactive" | "all";
  requestsList: ICompanyRequest[];
  getList: () => void;
}

const RequestList: React.FC<RequestListProps> = ({
  filteredBy = "all",
  requestsList,
  getList,
}) => {
  const isActive = filteredBy === "active";
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [rejectRequestId, setRejectRequestId] = useState<string>();
  const [isCard, setIsCard] = useState<boolean>(true);
  const [requestFields, setRequestFields] = useState(requestFieldList);
  const [deleteId, setDeleteId] = useState<string | null>();
  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  const handleAccept = (request: ICompanyAdd) => {
    const onSuccess = () => {
      notifySuccess("Successfully accept");
      navigate("/requests/");
      getList();
    };

    dispatch(addCompany({ data: request, onSuccess }));
  };

  const handleReject = useCallback(
    (request: ICompanyRequest) => {
      setShow(true);
      setRejectRequestId(request.id);
    },
    [setShow, setRequestFields]
  );

  const goToDetail = (request: ICompanyRequest) => {
    if (request) {
      if (request.status === "inactive") {
        navigate("/requests/rejected/" + request.id);
      } else if (request.status === "waiting") {
        navigate("/requests/review/" + request.id);
      } else {
        navigate("/company/" + request.company);
      }
    }
  };

  const handleDialogOpen = (id: string) => {
    setOpenDeleteModal(true);
    setDeleteId(id);
  };

  const handleDialogClose = () => {
    setOpenDeleteModal(false);
    setDeleteId(null);
  };

  const handleDeleteRequest = async () => {
    const onSuccess = () => {
      notifySuccess("Successfully accept");
      setOpenDeleteModal(false);
      getList();
    };
    if (deleteId) {
      await dispatch(deleteCompanyRequest({ id: deleteId, onSuccess }));
    }
  };

  const getStatus = (request: ICompanyRequest) => {
    let color = "lightRed";
    let title = "Rejected";
    if (request.status === "waiting") {
      color = "yellow";
      title = "Review";
    }
    if (request.status === "active") {
      color = "green";
      title = "Approved";
    }
    return { title, color };
  };

  const props = (request: ICompanyRequest) => {
    return {
      fields: requestFields,
      dataId: request.id,
      cardData: getCompanyRequestCardData(request),
      openDeleteModal: handleDialogOpen,
      status: getStatus(request),
      onClick: () => goToDetail(request),
      actionTitle: isActive ? "active" : "inactive",
      headActionNode: (
        <RenderActionButton
          companyRequest={request}
          statusColor={getStatus(request).color}
          statusTitle={getStatus(request).title}
          handleAccept={handleAccept}
          handleReject={handleReject}
          filteredBy={filteredBy}
        />
      ),
      isNeedHeadAction: true,
    };
  };

  return (
    <RequestsLayout
      isCard={isCard}
      setIsCard={setIsCard}
      fields={requestFields}
      setFields={setRequestFields}
      fieldAllList={requestFieldAllList}
    >
      {isCard
        ? requestsList.map((request) => (
            <Card key={request.id} {...props(request)} />
          ))
        : requestsList.map((request) => (
            <Row key={request.id} {...props(request)} />
          ))}
      <DeleteModal
        acceptTitle="Delete Company"
        open={openDeleteModal}
        handleClose={handleDialogClose}
        title="Delete Request Company"
        text="Are you sure you want to delete this Company?"
        accept={handleDeleteRequest}
        color="red"
      />

      {rejectRequestId && (
        <RequestReject
          open={show}
          setOpen={setShow}
          id={rejectRequestId}
          getList={getList}
        />
      )}
    </RequestsLayout>
  );
};
export default RequestList;
