import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "Service/api/api";
import { IHistorySearchParam } from "Shared/Types/order";
import { errorHandler } from "Shared/Utils/app";

const getOrderHistory = createAsyncThunk(
  "order/getOrderHistory",
  async (params: IHistorySearchParam) => {
    try {
      const response = await api.order.getOrderHistory(params);
      return response.data;
    } catch (error) {
      if (error instanceof Error) {
        errorHandler(error);
      }
      throw error;
    }
  },
);

const getOrderInnerHistory = createAsyncThunk(
  "order/getOrderInnerHistory",
  async (id: string) => {
    try {
      const response = await api.order.getOrderInnerHistory(id);
      return response.data;
    } catch (error) {
      if (error instanceof Error) {
        errorHandler(error);
      }
      throw error;
    }
  },
);

export { getOrderHistory, getOrderInnerHistory };
