import React from "react";
import { useSelector } from "react-redux";
import { selectCompanyRequestReviewList } from "Redux/CompanyRequests/slice";
import RequestList from "./List";
import { useGetRequestList } from "Shared/Hooks/request/use-get-request-list";

const RequestReviewedList: React.FC = () => {
  const requestsRequestReviewedList = useSelector(
    selectCompanyRequestReviewList
  );
  const { getCompanyReviewedRequests } = useGetRequestList();

  return (
    <RequestList
      filteredBy="waiting"
      requestsList={requestsRequestReviewedList || []}
      getList={getCompanyReviewedRequests}
    />
  );
};
export default RequestReviewedList;
