import { BreadcrumbItem } from "Components/Breadcrumb/Breadcrumb";
import { ICompany, ICompanyRequest } from "Shared/Types/company";
import { getShortValue } from "Shared/Utils/order";
import { format } from "Shared/Utils/app";
import * as Yup from "yup";

export const companySchema = Yup.object().shape({
  legal_name: Yup.string().required("Legal name is required"),
  name: Yup.string().required("Company name is required"),
  email: Yup.string()
    .required("Email is required")
    .email("Invalid email format"),
  fax: Yup.string(),
  website: Yup.string(),
  phone: Yup.string().matches(/\d/, "It must contain only digits"),

  billing_contact: Yup.string().nullable(),
  billing_address: Yup.string().nullable(),

  primary_contact: Yup.string().nullable(),
  primary_address: Yup.string().nullable(),
});

export const getCompanyCardData = (company: ICompany) => {
  const data = [
    {
      title: "Next Payment",
      value: getShortValue("02/02/24", 12) || "-",
    },
    {
      title: "Business name",
      value: getShortValue(company.name, 12) || "-",
    },
    {
      title: "First name",
      value: getShortValue(company.owner?.first_name, 12) || "-",
    },
    {
      title: "Last name",
      value: getShortValue(company.owner?.last_name, 12) || "-",
    },
    {
      title: "Email",
      value: getShortValue(company?.email, 12) || "-",
    },
    {
      title: "Contact",
      value: getShortValue(company.phone, 12) || "-",
    },
  ];
  return data;
};

export const shortenContact = (data: string) => {
  return data.split("/").map((item: string) => {
    if (item.length > 10) {
      item = getShortValue(item, 20);
    }

    return item;
  });
};

export const getCompanyBreadCrumbs = (
  type?: "user-list" | "update" | "blocked" | "detail",
  link = ""
) => {
  const breads: BreadcrumbItem[] = [{ title: "Company", link: "/company" }];
  if (type === "blocked") {
    breads.push({ title: "Blocked", link: "/company/blocked" });
  }
  if (type === "user-list") {
    breads.push({ title: "User List", link: "/company/user-list" });
  }
  if (type === "update") {
    breads.push({ title: "Update user", link: link });
  }
  if (type === "detail") {
    breads.push({ title: "User", link: link });
  }

  return breads;
};
export const getCompanyInformation = (
  company: ICompanyRequest | ICompany | null | undefined
) => {
  if (company) {
    return [
      {
        title: "Date",
        value:
          (company.created_at && format(company?.created_at, "MM-DD-YY")) ||
          "-",
      },
      { title: "Main Phone", value: company?.phone || "-" },
      { title: "Legal Name", value: company?.legal_name || "-" },
      { title: "Fax", value: company?.fax || "-" },
      {
        title: "Company name",
        value: "company_name" in company ? company.company_name : company?.name,
      },
      { title: "Website", value: company?.website || "-" },
      { title: "Email", value: company?.email || "-" },
    ];
  }
};
export const getCompanyBilling = (
  company: ICompanyRequest | ICompany | null | undefined
) => {
  const billing = [
    {
      title: "Billing contact",
      value: company?.billing_contact || "-",
      xs: 12,
    },
    {
      title: "Payment method",
      value: company?.payment_method || "-",
      xs: 12,
    },
    {
      title: "Billing address",
      value: company?.billing_address || "-",
      xs: 12,
    },
  ];
  const primary = [
    {
      title: "Primary contact",
      value: company?.primary_contact || "-",
      xs: 12,
    },
    {
      title: "Primary address",
      value: company?.primary_address || "-",
      xs: 12,
    },
  ];

  return { billing, primary };
};
