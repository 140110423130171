import React, { FC } from "react";
import { Text, View } from "@react-pdf/renderer";
import { getInvoiceHead } from "Shared/Utils/invoice";
import { OrderInvoicePDFProps } from "../InvoicePdf";
import { styles } from "./styles";

const HeadInfo: FC<OrderInvoicePDFProps> = ({ data }) => {
  return (
    <View style={styles.container}>
      {getInvoiceHead(data).map((arr, index) => (
        <View key={index} style={styles.block}>
          {arr?.map(({ title, value }, i) => (
            <View key={i} style={styles.item}>
              <Text style={styles.itemTitle}>{title}</Text>
              <Text>{value}</Text>
            </View>
          ))}
        </View>
      ))}
    </View>
  );
};

export default HeadInfo;
