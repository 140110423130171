import React from "react";
import { BreadcrumbItem } from "Components/Breadcrumb/Breadcrumb";
import { useBreadcrumb } from "Shared/Hooks/use-breadcrumb";
import RequestRejectedList from "Containers/Requests/List/RequestRejectedList";

export function RequestsReject() {
  const breads: BreadcrumbItem[] = [
    { title: "Requests", link: "/requests" },
    { title: "Rejected", link: "reject" },
  ];

  useBreadcrumb({ links: breads });
  return <RequestRejectedList />;
}
