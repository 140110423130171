import React, { ReactNode } from "react";
import styles from "./OverviewCard.module.scss";

type CardProps = {
  title: string;
  percentage: string;
  hour: string;
  children: ReactNode;
};

const OverviewCard: React.FC<CardProps> = ({
  title,
  percentage,
  hour,
  children,
}) => {
  return (
    <div className={styles.card}>
      <div className={styles.card__percentage}>
        <p className={styles.card__title}>{title}</p>
        <p className={styles.card__percentage_text}>+{percentage}%</p>
      </div>
      {children}
      <h2 className={styles.card__hour}>{hour} hr</h2>
    </div>
  );
};

export default OverviewCard;
