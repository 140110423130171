import { ArrowLeftIcon } from "Assets/index";
import Icon from "Components/UI/Icon/Icon";
import React from "react";
import { Link } from "react-router-dom";
import styles from "./BackToLogin.module.scss";

const BackToLogin: React.FC = () => {
  return (
    <div className={styles.backBlock}>
      <Link to="/login">
        <Icon height={20} component={ArrowLeftIcon} />
      </Link>
      <p>Back to login</p>
    </div>
  );
};
export default BackToLogin;
