import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "Service/api/api";
import {
  IDispatchCallback,
  IDispatchCallbackWithId,
} from "Shared/Types/common";
import {
  ICompanyAdd,
  ICompanySearchParams,
  ICompanyUpdate,
} from "Shared/Types/company";
import { errorHandler } from "Shared/Utils/app";

const getCompanyList = createAsyncThunk(
  "company/getCompanyList",
  async (params: ICompanySearchParams | null) => {
    try {
      const response = await api.company.getCompanyList({
        ...params,
        status: "active",
      });
      return response.data;
    } catch (error) {
      if (error instanceof Error) {
        errorHandler(error, true);
      }
      throw error;
    }
  },
);

const getCompanyBlockedList = createAsyncThunk(
  "company/getCompanyBlockedList",
  async (params: ICompanySearchParams | null) => {
    try {
      const response = await api.company.getCompanyList({
        ...params,
        status: "inactive",
      });
      return response.data;
    } catch (error) {
      if (error instanceof Error) {
        errorHandler(error, true);
      }
      throw error;
    }
  },
);

const getCompany = createAsyncThunk(
  "company/getCompany",
  async (id: string) => {
    try {
      const response = await api.company.getCompany(id);
      return response.data;
    } catch (error) {
      if (error instanceof Error) {
        errorHandler(error, true);
      }
      throw error;
    }
  },
);

const addCompany = createAsyncThunk(
  "company/addCompany",
  async ({ data, onSuccess }: IDispatchCallback<ICompanyAdd>) => {
    try {
      const response = await api.company.addCompany(data);
      onSuccess?.();
      return response.data;
    } catch (error) {
      if (error instanceof Error) {
        errorHandler(error, true);
      }
      throw error;
    }
  },
);

const updateCompany = createAsyncThunk(
  "company/updateCompany",
  async ({ id, data, onSuccess }: IDispatchCallbackWithId<ICompanyUpdate>) => {
    try {
      const response = await api.company.updateCompany(id, data);
      onSuccess?.();
      return response.data;
    } catch (error) {
      if (error instanceof Error) {
        errorHandler(error, true);
      }
      throw error;
    }
  },
);

const updateCompanyStatus = createAsyncThunk(
  "company/updateCompanyStatus",
  async ({ id, data, onSuccess }: IDispatchCallbackWithId<ICompanyUpdate>) => {
    try {
      const response = await api.company.updateCompanyStatus(id, data);
      onSuccess?.();
      return response.data;
    } catch (error) {
      if (error instanceof Error) {
        errorHandler(error, true);
      }
      throw error;
    }
  },
);

export {
  getCompanyList,
  getCompanyBlockedList,
  getCompany,
  addCompany,
  updateCompany,
  updateCompanyStatus,
};
