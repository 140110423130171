import React from "react";
import styles from "./UnitAdd.module.scss";
import UnitForm from "Components/Unit/UnitForm/UnitForm";
import { useAppDispatch } from "Redux/store";
import { useSelector } from "react-redux";
import { addUnit } from "Redux/Unit/reducer";
import { IUnitAdd } from "Shared/Types/unit";
import { notifySuccess } from "Components/Notify/notify";
import { unitSchema } from "Shared/Utils/units";
import { useLocation, useNavigate } from "react-router";
import { selectAccount } from "Redux/Auth/slice";
import StepProgress from "Components/UI/StepProgress/StepProgress";
import { checkFirstAuth } from "Shared/Utils/app";

const step = (
  <>
    Enter details such as the unit type, model, and owner (linked to a
    customer).
  </>
);

const UnitAdd: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const profile = useSelector(selectAccount);
  const isFirstAuth = checkFirstAuth(profile);
  const location = useLocation();

  const createUnit = (values: IUnitAdd) => {
    const onSuccess = () => {
      notifySuccess("Unit created");
      if (isFirstAuth) {
        goToVendor();
      } else if (location.state) {
        navigate(-1);
      } else {
        navigate("/units");
      }
    };
    dispatch(addUnit({ data: values, onSuccess }));
  };

  const goToVendor = () => {
    navigate("/vendors/new");
  };

  return (
    <div className={styles.unitAdd}>
      {isFirstAuth && (
        <StepProgress description={step} active={5} title="Adding Unit" />
      )}
      <div className={styles.unitAdd__form}>
        <UnitForm
          onSubmit={createUnit}
          schema={unitSchema}
          onSkip={goToVendor}
          isFirstAuth={isFirstAuth}
        />
      </div>
    </div>
  );
};
export default UnitAdd;
