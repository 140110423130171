import FilterPanel from "Components/Order/FilterPanel/FilterPanel";
import React, { ReactNode, useEffect, useRef } from "react";
import styles from "./HomeLayout.module.scss";

import { useAppDispatch } from "Redux/store";
import { getOnHoldOrderList, getOrderList } from "Redux/Order/reducer";
import Pagination from "Components/UI/Pagination/Pagination";
import { useLocation } from "react-router-dom";
import cn from "classnames";
import HomeFilter from "Components/Home/HomeFilter/HomeFilter";
import { statsFilterFields } from "Shared/Constants/stats";
import { returnsFilterFields } from "Shared/Constants/returns";


type HomeLayoutProps = {
  status?: string;
  children: ReactNode;
  isCard: boolean;
  setIsCard: (isCard: boolean) => void;
  fields: string[];
  setFields: (fields: string[]) => void;
  fieldAllList: string[];
  limitFields?: number;
  onHold?: boolean;
};

const HomeLayout: React.FC<HomeLayoutProps> = ({
  children,
  isCard,
  setIsCard,
  fields,
  setFields,
  fieldAllList,
  limitFields,
  onHold,
}) => {

  
  const location = useLocation();
  const dispatch = useAppDispatch();

  const isReturns = location.pathname.includes("returns");

  const contentRef = useRef<HTMLDivElement>(null);
  const calendarRef = useRef<HTMLDivElement>(null);

  const getNumPage = () => {
    if (isReturns) {
      return  1;
    }
    return 1
  };

  useEffect(() => {
    if (contentRef.current && calendarRef.current) {
      const contentWidth = contentRef.current.offsetWidth;
      calendarRef.current.style.width = `${contentWidth}px`;
    }
  }, [isCard]);

  useEffect(() => {
    if (onHold) {
      dispatch(getOrderList(null));
    } else {
      dispatch(getOnHoldOrderList(null));
    }
  }, []);

  return (
    <div>
      <HomeFilter />
      <FilterPanel
        setIsCard={setIsCard}
        isCard={isCard}
        fields={fields}
        setFields={setFields}
        fieldAllList={fieldAllList}
        limitFields={limitFields}
        filters={isReturns ? returnsFilterFields : statsFilterFields}
        showCardAndFilterIcons={isReturns?true:false}
      />
      <div className={styles.orders__content} ref={contentRef}>
        <div
          className={cn(
            styles.orders__container,
            !isCard && styles.orders__container_row
          )}
        >
          {children}
        </div>
        {getNumPage() > 1 && <Pagination count={getNumPage()} />}
      </div>
    </div>
  );
};
export default HomeLayout;
