import { BreadcrumbItem } from "Components/Breadcrumb/Breadcrumb";
import Layout from "Components/Layout/Layout";
import EstimatePartList from "Containers/Part/EstimatePartList/EstimpatePartList";
import { useBreadcrumb } from "Shared/Hooks/use-breadcrumb";
import React from "react";

export const PartEstimatePage: React.FC = () => {
  const breads: BreadcrumbItem[] = [{ title: "Parts", link: "/parts" }];
  useBreadcrumb({
    links: breads,
    createTitle: "Part",
    isNeedCreateBtn: true,
  });
  return (
    <Layout>
      <EstimatePartList />
    </Layout>
  );
};
