import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { IRole, IPermission } from "Shared/Types/role";
import {
  getRolesList,
  getRole,
  addRole,
  updateRole,
  deleteRole,
  getPermissions,
} from "./reducers";
import { IPermissionObject } from "Shared/Types/role";
import { convertPermissionsToObject } from "Shared/Utils/role";

interface IRoleState {
  objPermissions: IPermissionObject;
  permissions: IPermission[];
  rolesList?: IRole[];
  currentRole?: IRole | null;
  loading: boolean;
}

const initialState: IRoleState = {
  permissions: [],
  rolesList: [],
  currentRole: null,
  loading: false,
  objPermissions: {},
};

const roleSlice = createSlice({
  name: "role",
  initialState,
  reducers: {
    removeCurrentRole(state) {
      state.currentRole = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getPermissions.fulfilled, (state, action) => {
      state.objPermissions = convertPermissionsToObject(action.payload);

      state.permissions = action.payload;
    });
    builder.addCase(getRole.pending, (state) => {
      state.loading = true;
      state.currentRole = null;
    });
    builder.addCase(getRole.rejected, (state) => {
      state.loading = false;
      state.currentRole = null;
    });
    builder.addCase(getRole.fulfilled, (state, action) => {
      state.loading = false;
      const role = <IRole>action.payload;
      role.permissions =
        role?.permissions.map((perItem) => {
          return state.objPermissions[perItem]?.codename;
        }) || [];
      state.currentRole = role;
    });

    builder.addCase(addRole.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(addRole.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(addRole.fulfilled, (state) => {
      state.loading = false;
    });

    builder.addCase(getRolesList.pending, (state) => {
      state.loading = true;
      state.rolesList = [];
    });
    builder.addCase(getRolesList.rejected, (state) => {
      state.loading = false;
      state.rolesList = [];
    });
    builder.addCase(getRolesList.fulfilled, (state, action) => {
      state.loading = false;
      state.rolesList = action.payload;
    });

    builder.addCase(updateRole.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateRole.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(updateRole.fulfilled, (state) => {
      state.loading = false;
    });

    builder.addCase(deleteRole.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(deleteRole.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(deleteRole.fulfilled, (state) => {
      state.loading = false;
    });
  },
});

export const { removeCurrentRole } = roleSlice.actions;

export const selectCurrentRole = (state: RootState) => state.role.currentRole;
export const selectRoleLoading = (state: RootState) => state.role.loading;
export const selectRoleList = (state: RootState) => state.role.rolesList;
export const selectPermissionsObject = (state: RootState) =>
  state.role.objPermissions;
export const selectPermissions = (state: RootState) => state.role.permissions;
export default roleSlice;
