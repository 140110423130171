import { createSlice } from "@reduxjs/toolkit";
import { getCities, getCountries, getStates } from "./reducer";
import { RootState } from "Redux/store";
import { ICountry, IState } from "Shared/Types/address";

type IAddress = {
  countries: ICountry[];
  cities: IState[];
  states: IState[];
  countriesLoading: boolean;
  citiesLoading: boolean;
  statesLoading: boolean;
};

const initialState: IAddress = {
  countries: [],
  cities: [],
  states: [],
  countriesLoading: false,
  citiesLoading: false,
  statesLoading: false,
};

const addressSlice = createSlice({
  name: "company",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getCountries.pending, (state) => {
      state.countriesLoading = true;
    });
    builder.addCase(getCountries.rejected, (state) => {
      state.countriesLoading = false;
    });
    builder.addCase(getCountries.fulfilled, (state, actions) => {
      state.countries = actions.payload;
      state.states = [];
      state.cities = [];
      state.countriesLoading = false;
    });
    builder.addCase(getStates.pending, (state) => {
      state.statesLoading = true;
    });
    builder.addCase(getStates.rejected, (state) => {
      state.statesLoading = false;
    });
    builder.addCase(getStates.fulfilled, (state, action) => {
      state.states = action.payload;
      state.cities = [];
      state.statesLoading = false;
    });
    builder.addCase(getCities.pending, (state) => {
      state.citiesLoading = true;
    });
    builder.addCase(getCities.rejected, (state) => {
      state.citiesLoading = false;
    });
    builder.addCase(getCities.fulfilled, (state, action) => {
      state.cities = action.payload;
      state.citiesLoading = false;
    });
  },
});

export const selectCountries = (state: RootState) => state.address.countries;
export const selectStates = (state: RootState) => state.address.states;
export const selectCities = (state: RootState) => state.address.cities;
export const selectCitiesLoading = (state: RootState) =>
  state.address.citiesLoading;
export const selectCountriesLoading = (state: RootState) =>
  state.address.countriesLoading;
export const selectStatesLoading = (state: RootState) =>
  state.address.statesLoading;

export default addressSlice;
