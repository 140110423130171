import { CommentIcon, DeleteIcon } from "Assets/index";
import IconButton from "Components/UI/IconButton/IconButton";
import { useAppDispatch } from "Redux/store";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import styles from "./OrderNote.module.scss";
import InputField from "Components/UI/Inputs/InputField";
import Checkbox from "Components/UI/Inputs/Checkbox";
import Modal from "Components/UI/Modal/Modal";
import Button from "Components/UI/Button/Button";
import { format } from "Shared/Utils/app";
import { notifyError, notifySuccess } from "Components/Notify/notify";
import { selectAccount } from "Redux/Auth/slice";
import Icon from "Components/UI/Icon/Icon";
import DeleteModal from "Components/UI/DialogWindow/DeleteModal";
import {
  selectOrderNoteError,
  selectOrderNoteList,
  selectOrderNoteSuccess,
  resetNoteList,
  clear,
} from "Redux/OrderNote/slice";
import {
  addOrderNote,
  deleteOrderNotes,
  getOrderNoteList,
} from "Redux/OrderNote/reducer";
import Badge from "Components/UI/Badge/Badge";

type OrderNoteProps = {
  orderId?: string;
};

const OrderNote: React.FC<OrderNoteProps> = ({ orderId }) => {
  const dispatch = useAppDispatch();
  const notesList = useSelector(selectOrderNoteList) || [];
  const success = useSelector(selectOrderNoteSuccess);
  const error = useSelector(selectOrderNoteError);
  const profile = useSelector(selectAccount);
  const [open, setOpen] = useState(false);
  const [deleteNoteId, setDeleteNoteId] = useState<string | null>();
  const [openNoteDeleteModal, setOpenNoteDeleteModal] = useState(false);
  const [newNote, setNewNote] = useState("");
  const [forCustomer, setForCustomer] = useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    dispatch(resetNoteList());
  };

  const addNote = () => {
    if (newNote === "") {
      notifyError(`note haven't be empty `);
      return;
    }
    if (orderId) {
      dispatch(
        addOrderNote({
          note: newNote,
          order: orderId,
          author: profile?.id,
          for_customer: forCustomer,
        })
      );
    }
  };

  const handlePartDialogClose = () => {
    setOpenNoteDeleteModal(false);
    setDeleteNoteId(null);
  };

  const handlePartDialogOpen = (id: string) => {
    setOpenNoteDeleteModal(true);
    setDeleteNoteId(id);
  };

  const handleDeletePart = () => {
    if (deleteNoteId) {
      dispatch(deleteOrderNotes(deleteNoteId));
    }
  };

  useEffect(() => {
    if (open) {
      if (success) {
        notifySuccess(success);
        setNewNote("");
        setForCustomer(false);
        dispatch(clear());
        handlePartDialogClose();
      } else if (error) {
        notifyError(error);
        dispatch(clear());
      }
    }
    if (orderId) {
      dispatch(
        getOrderNoteList({
          order: orderId,
        })
      );
    }
  }, [success, open]);

  useEffect(() => {
    if (orderId)
      dispatch(
        getOrderNoteList({
          order: orderId,
        })
      );
  }, [orderId]);
  return (
    <div className={styles.note}>
      <Badge messageLength={notesList.length}>
        <IconButton
          variant="primary"
          icon={CommentIcon}
          width={18}
          height={18}
          style={{ padding: "9.5px" }}
          onClick={handleOpen}
        />
      </Badge>
      <Modal isClosed open={open} onClose={handleClose} title="Note">
        {notesList.map((note) => (
          <div key={note.id} className={styles.note__modal__note}>
            <div className={styles.note__modal__note__head}>
              <div>{note.author.first_name + " " + note.author.last_name}</div>
              <div className={styles.note__modal__note__head__delete}>
                <span>{format(note.created_at)}</span>
                <Icon
                  component={DeleteIcon}
                  onClick={() => handlePartDialogOpen(note.id)}
                />
              </div>
            </div>
            <div className={styles.note__modal__note__text}>{note.note}</div>
          </div>
        ))}
        <InputField
          name="note"
          label="Add Note"
          multiline
          value={newNote}
          onChange={(event) => setNewNote(event.target.value)}
        />
        <Checkbox
          className={styles.note__modal__checkbox}
          name="for_customer"
          label="For Customer"
          checked={forCustomer}
          onChange={(event) => setForCustomer(event.target.checked)}
        />
        <div className={styles.note__modal__action}>
          <Button onClick={addNote}>Add Note</Button>
        </div>
      </Modal>
      <DeleteModal
        acceptTitle="Delete Order Note"
        open={openNoteDeleteModal}
        handleClose={handlePartDialogClose}
        title="Delete Order Note"
        text="Are you sure you want to delete this Order Note?"
        accept={handleDeletePart}
      />
    </div>
  );
};
export default OrderNote;
