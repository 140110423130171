import React, { useEffect, useMemo, useRef, useState } from "react";
import Icon from "Components/UI/Icon/Icon";
import { CheckCircleIcon, CloseIcon, SignInIcon } from "Assets/index";
import styles from "./Profile.module.scss";
import cn from "classnames";
import CameraImg from "../../../Assets/photo.svg";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { logout, selectAccount } from "Redux/Auth/slice";
import { clear, read, readObj, write } from "Service/storage";
import { IShop, IShopList } from "Shared/Types/shop";
import { notifySuccess } from "Components/Notify/notify";
import { useAppDispatch } from "Redux/store";
import { selectShopList } from "Redux/Shop/slice";
import { getShortValue } from "Shared/Utils/order";

type ProfileProps = {
  closeProfile: () => void;
  profile: boolean;
  openModal: () => void;
};

const Profile: React.FC<ProfileProps> = ({
  profile,
  closeProfile,
  openModal,
}) => {
  const account = useSelector(selectAccount);
  const dispatch = useAppDispatch();
  const shops = useSelector(selectShopList) || [];
  const profileRef = useRef<HTMLDivElement>(null);
  const [currentShop, setCurrentShop] = useState(readObj("current_shop"));

  const handleClickOutside = (event: MouseEvent) => {
    if (
      profileRef.current &&
      !profileRef.current?.contains(event.target as Node)
    ) {
      closeProfile();
    }
  };

  const handleSwitchShop = (shop: IShopList, name: string) => {
    if (account) {
      write("current_shop", shop);
      write("current_shop_name", name);
      location.reload();
      setCurrentShop(shop);
      closeProfile();
      notifySuccess(`Shop has been changed to ${read("current_shop_name")}`);
    }
  };

  const handleLogout = () => {
    dispatch(logout());
  };

  const shopName = useMemo(() => {
    if (account?.is_superuser) {
      return "Easy Shop";
    }
    if (account?.is_company_admin) {
      if (currentShop) return currentShop.shop_name;
      return (
        shops[0]?.shop_name || account?.company?.legal_name || "Your business"
      );
    }

    return currentShop?.shop_name || "Your business";
  }, [shops, account, currentShop]);

  useEffect(() => {
    if (shops.length === 0) {
      clear("current_shop");
    }
  }, [shops]);

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  return (
    <div
      ref={profileRef}
      className={cn(profile ? styles.profile : styles.close_profile)}
    >
      <div className={styles.profile__title}>
        <p>{getShortValue(shopName, 25)}</p>
        <Icon onClick={closeProfile} component={CloseIcon} />
      </div>
      {!account?.is_superuser &&
        (account?.is_company_admin ? (
          shops?.map((shop: IShopList) => (
            <div
              key={shop.id}
              onClick={() => handleSwitchShop(shop, shop?.shop_name)}
              className={cn(
                currentShop
                  ? currentShop.id == shop.id.toString()
                    ? styles.profile__active_company
                    : styles.profile__company
                  : shops[0].id == shop.id
                  ? styles.profile__active_company
                  : styles.profile__company,
              )}
            >
              <p>{getShortValue(shop?.shop_name, 25)}</p>
              {currentShop
                ? currentShop.id == shop.id.toString() && (
                    <Icon component={CheckCircleIcon} />
                  )
                : shops[0].id == shop.id && (
                    <Icon component={CheckCircleIcon} />
                  )}
            </div>
          ))
        ) : (
          <div className={styles.profile__active_company}>
            <p>{currentShop?.shop_name}</p>
            <Icon component={CheckCircleIcon} />
          </div>
        ))}
      {account?.is_company_admin && (
        <Link style={{ textDecoration: "none" }} to="/shops/new">
          <p className={styles.profile__create_company}>+ Create a new one</p>
        </Link>
      )}
      <hr />
      <div onClick={openModal} className={styles.profile__profile_avatar_cont}>
        <img
          style={{ width: "40px", height: "40px" }}
          className={styles.avatar}
          src={
            typeof account?.avatar === "string" ? account?.avatar : CameraImg
          }
          alt="/"
        />
        <p>
          {account?.first_name} {account?.last_name}
        </p>
      </div>
      <div onClick={handleLogout} className={styles.profile__logout}>
        <Icon component={SignInIcon} />
        <p>Log out</p>
      </div>
      <div className={styles.profile__footer}>
        <p>Terms</p>
        &#183;
        <p>Privacy</p>
      </div>
    </div>
  );
};

export default Profile;
