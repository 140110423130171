import React from "react";
import RadarChart from "Components/Diagrams/RadarDiagram";
import OverviewCard from "Containers/Overview/OverviewCard/OverviewCard";
import styles from "./Overview.module.scss";
import CurveChart from "Components/Diagrams/CurveDiagram";
import GaugeChart from "Components/Diagrams/RadialChart";

const Overview: React.FC = () => {
  //    TODO: replace hardcoded values
  const indicators = [
    { name: "Scheduled", max: 300 },
    { name: "Invoiced", max: 250 },
    { name: "Completed Work", max: 300 },
    { name: "Work in Progress", max: 5 },
    { name: "Authorized", max: 200 },
  ];

  return (
    <div className={styles.overview}>
      <RadarChart indicators={indicators} className={styles.overview__chart} />
      <div>
        <OverviewCard hour="12" title="Authorized Hours" percentage="12">
          <GaugeChart percentage={30} />
        </OverviewCard>
        <OverviewCard hour="24" title="Invoiced Hours" percentage="21">
          <CurveChart />
        </OverviewCard>
        <OverviewCard hour="24" title="Completed Work " percentage="15">
          <GaugeChart percentage={80} />
        </OverviewCard>
      </div>
    </div>
  );
};

export default Overview;
