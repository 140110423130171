import { yupResolver } from "@hookform/resolvers/yup";
import Form from "Components/UI/Form/Form";
import React, { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { ObjectSchema } from "yup";
import Button from "Components/UI/Button/Button";
import styles from "./CustomerForm.module.scss";
import {
  ICustomer,
  ICustomerAdd,
  ICustomerUpdate,
} from "Shared/Types/customer";
import CompanyDetails from "./CompanyDetails";
import BillingInfo from "./BillingInfo";
import Block from "Components/UI/Block/Block";
import { Grid } from "@mui/material";

type CustomerFormProps = {
  onSubmit: (values: ICustomerAdd | ICustomerUpdate) => void;
  schema: ObjectSchema<ICustomerAdd | ICustomerUpdate>;
  customer?: ICustomer;
  onSkip?: () => void;
  isFirstAuth?: boolean;
};

const CustomerForm: React.FC<CustomerFormProps> = ({
  onSubmit,
  schema,
  customer,
  onSkip,
  isFirstAuth,
}) => {
  const methods = useForm({
    resolver: yupResolver(schema),
    defaultValues: customer,
  });

  const [active, setActive] = useState(customer?.status === "active");

  return (
    <Block className={styles.form}>
      <FormProvider {...methods}>
        <Form onSubmit={methods.handleSubmit(onSubmit)}>
          <Grid container columnSpacing={2}>
            <Grid item sm={12} md={6}>
              <CompanyDetails active={active} setActive={setActive} />
            </Grid>
            <Grid item sm={12} md={6}>
              <BillingInfo />
            </Grid>
          </Grid>
          <div className={styles.form__action}>
            {isFirstAuth && (
              <Button onClick={onSkip} color="secondary" type="button">
                Skip
              </Button>
            )}
            {customer ? (
              <Button variant="outlined" type="submit">
                Save
              </Button>
            ) : (
              <Button type="submit">Create</Button>
            )}
          </div>
        </Form>
      </FormProvider>
    </Block>
  );
};
export default CustomerForm;
