import Layout from "Components/Layout/Layout";
import React from "react";
import { BreadcrumbItem } from "Components/Breadcrumb/Breadcrumb";
import { useBreadcrumb } from "Shared/Hooks/use-breadcrumb";
import PartAdd from "Containers/Part/PartAdd/PartAdd";

export const PartAddPage: React.FC = () => {
  const breads: BreadcrumbItem[] = [
    { title: "Parts", link: "/parts" },
    { title: "Create a part", link: "" },
  ];
  useBreadcrumb({ links: breads });
  return (
    <Layout>
      <PartAdd />
    </Layout>
  );
};
