import React from "react";
import styles from "Components/Headers/Profile/Profile.module.scss";
import Modal from "Components/UI/Modal/Modal";
import cn from "classnames";
import { useSelector } from "react-redux";
import { selectAccount } from "Redux/Auth/slice";
import { useAppDispatch } from "Redux/store";
import Button from "Components/UI/Button/Button";
import { updateUserField } from "Redux/User/reducer";

type ConfirmAvatarModalProps = {
  open: boolean;
  onClose: () => void;
  img: File | null;
  closeOptions: () => void;
};

const ConfirmAvatarModal: React.FC<ConfirmAvatarModalProps> = ({
  open,
  onClose,
  img,
  closeOptions,
}) => {
  const account = useSelector(selectAccount);
  const dispatch = useAppDispatch();
  const onConfirmChosenImg = async () => {
    if (account && img) {
      await dispatch(
        updateUserField({ id: account.id, data: { avatar: img } }),
      );
      onClose();
      closeOptions();
    }
  };

  return (
    <Modal
      className={styles.profile_info}
      open={open}
      onClose={onClose}
      isClosed={open}
      title="Confirm choosen avatar"
    >
      <div className={styles.confirm_avatar}>
        <img
          className={cn(styles.avatar, styles.confirm_avatar__img)}
          src={img instanceof File ? URL.createObjectURL(img) : ""}
          alt="/"
        />
      </div>
      <Button onClick={onConfirmChosenImg} style={{ float: "right" }}>
        Confirm
      </Button>
    </Modal>
  );
};

export default ConfirmAvatarModal;
