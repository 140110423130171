import Layout from "Components/Layout/Layout";
import SettingsLayout from "Components/Settings/Layout/Layout";
import RolesAdd from "Containers/Settings/Roles/RolesAdd/RolesAdd";
import React from "react";
import { BreadcrumbItem } from "Components/Breadcrumb/Breadcrumb";
import { useBreadcrumb } from "Shared/Hooks/use-breadcrumb";

export const RolesAddPage: React.FC = () => {
  const breads: BreadcrumbItem[] = [
    { title: "Settings", link: "/settings/rate-settings/roles" },
    { title: "Role & Access", link: "/settings/rate-settings/roles" },
    { title: "Create New Role", link: "" },
  ];
  useBreadcrumb({ links: breads });

  return (
    <Layout>
      <SettingsLayout>
        <RolesAdd />
      </SettingsLayout>
    </Layout>
  );
};
