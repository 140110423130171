import {
  getCompanyRequestsApprovedList,
  getCompanyRequestsList,
  getCompanyRequestsRejectedList,
  getCompanyRequestsReviewList,
} from "Redux/CompanyRequests/reducers";
import { useAppDispatch } from "Redux/store";
import { ICompanySearchParams } from "Shared/Types/company";
import { formatBackend } from "Shared/Utils/app";
import { useSearchParams } from "react-router-dom";

export const useGetRequestList = () => {
  const [searchParams] = useSearchParams();
  const dispatch = useAppDispatch();

  const params: ICompanySearchParams = {
    page: +(searchParams.get("page") || 1),
    search: searchParams.get("search") || "",
    status: searchParams.get("status") || "",
  };

  const from = searchParams.get("from");
  const to = searchParams.get("to");
  if (from && to) {
    params.created_at_after = formatBackend(new Date(from), "YYYY-MM-DD");
    params.created_at_before = formatBackend(new Date(to), "YYYY-MM-DD");
  }

  function getCompanyRequests() {
    return dispatch(getCompanyRequestsList(params));
  }
  function getCompanyRejectedRequests() {
    return dispatch(getCompanyRequestsRejectedList(params));
  }
  function getCompanyReviewedRequests() {
    return dispatch(getCompanyRequestsReviewList(params));
  }
  function getCompanyApprovedRequests() {
    return dispatch(getCompanyRequestsApprovedList(params));
  }

  return {
    getCompanyRequests,
    getCompanyRejectedRequests,
    getCompanyReviewedRequests,
    getCompanyApprovedRequests,
  };
};
