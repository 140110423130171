import React, { useEffect } from "react";
import styles from "./Dashboard.module.scss";
import { Grid } from "@mui/material";
import Calendar from "Components/Calendar/Calendar";
import Overview from "Containers/Overview/Overview";
import { useSelector } from "react-redux";
import { selectOrderCountByStatus } from "Redux/Order/slice";
import { useAppDispatch } from "Redux/store";
import { getOnHoldOrderList, getOrderCountByStatus } from "Redux/Order/reducer";
import { selectUrgencyOptionList } from "Redux/UrgencyOption/slice";
import { getUrgencyOptionList } from "Redux/UrgencyOption/reducer";
import OrderCountByStatusCard from "Components/Order/OrderCountByStatusCard/OrderCountByStatusCard";
import HomeFilter from "Components/Home/HomeFilter/HomeFilter";
import { selectAccount } from "Redux/Auth/slice";
import { getOrderCountByStatusList } from "Shared/Utils/order";

const Dashboard: React.FC = () => {
  const orderCountByStatus = useSelector(selectOrderCountByStatus);
  const statuses = useSelector(selectUrgencyOptionList) || [];
  const profile = useSelector(selectAccount);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getOrderCountByStatus());
    dispatch(getOnHoldOrderList(null));
    if (profile?.company?.id) {
      dispatch(getUrgencyOptionList({ company: profile?.company?.id }));
    }
  }, [profile]);

  return (
    <div className={styles.dashboard}>
      <HomeFilter />
      <div className={styles.dashboard__content}>
        <div className={styles.dashboard__content__left}>
          {orderCountByStatus?.total?.count && (
            <Grid container rowGap={2.5} columnSpacing={2.5} overflow="auto">
              {getOrderCountByStatusList(
                statuses,
                orderCountByStatus,
                true
              ).map((status) => (
                <>
                  {status.count && (
                    <Grid item md={4} key={status.title}>
                      <OrderCountByStatusCard {...status} />
                    </Grid>
                  )}
                </>
              ))}
            </Grid>
          )}
          <div className={styles.dashboard__content__left__calendar}>
            <Calendar isLong />
          </div>
        </div>
        <div className={styles.dashboard__content__right}>
          <Overview />
        </div>
      </div>
    </div>
  );
};
export default Dashboard;
