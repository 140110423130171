import React from "react";
import { useSelector } from "react-redux";
import { selectCompanyRequestRejectedList } from "Redux/CompanyRequests/slice";
import RequestList from "./List";
import { useGetRequestList } from "Shared/Hooks/request/use-get-request-list";

const RequestRejectedList: React.FC = () => {
  const requestsRejectedList = useSelector(selectCompanyRequestRejectedList);
  const { getCompanyRejectedRequests } = useGetRequestList();

  return (
    <RequestList
      filteredBy="inactive"
      requestsList={requestsRejectedList || []}
      getList={getCompanyRejectedRequests}
    />
  );
};
export default RequestRejectedList;
