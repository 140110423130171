import { PURCHASE_ORDER_LINE_ITEMS_FIELDS } from "Shared/Constants/purchase-order";
import { IPurchaseOrderLineItemKey } from "Shared/Types/purchase-order";

export type TField = {
  type: "text" | "number" | "info";
  label?: string;
  name: IPurchaseOrderLineItemKey;
  xs?: number;
  isBlue?: boolean;
  isInForm?: boolean;
};

const usePurchaseOrderLineItemsFields = () => {
  const XS = 1.2;

  const fields: TField[] = [
    {
      name: "part_number",
      label: PURCHASE_ORDER_LINE_ITEMS_FIELDS.PART_NUMBER,
      xs: XS,
      type: "info",
      isBlue: true,
    },
    {
      name: "part_number",
      label: PURCHASE_ORDER_LINE_ITEMS_FIELDS.VENDOR_PART_NUMBER,
      xs: XS,
      type: "info",
    },

    {
      name: "quantity",
      label: PURCHASE_ORDER_LINE_ITEMS_FIELDS.ORDER_QUANTITY,
      xs: XS,
      type: "info",
    },
    {
      name: "actual_cost",
      label: PURCHASE_ORDER_LINE_ITEMS_FIELDS.LAST_COST,
      xs: XS,
      type: "info",
    },
    {
      name: "actual_cost",
      label: PURCHASE_ORDER_LINE_ITEMS_FIELDS.ACTUAL_COST,
      xs: XS,
      type: "number",
    },
    {
      name: "actual_cost",
      label: PURCHASE_ORDER_LINE_ITEMS_FIELDS.AVERAGE_COST,
      xs: XS,
      type: "info",
    },
    {
      name: "actual_cost",
      label: PURCHASE_ORDER_LINE_ITEMS_FIELDS.EXTENDED_COST,
      xs: XS,
      type: "info",
    },
    {
      name: "saved_quantity",
      label: PURCHASE_ORDER_LINE_ITEMS_FIELDS.RECEIVED_QUANTITY,
      xs: XS,
      type: "info",
      isInForm: true,
    },
    {
      name: "received_quantity",
      label: PURCHASE_ORDER_LINE_ITEMS_FIELDS.RECEIVED_QUANTITY,
      xs: XS,
      type: "number",
      isInForm: true,
    },

    {
      name: "description",
      label: PURCHASE_ORDER_LINE_ITEMS_FIELDS.DESCRIPTION,
      xs: XS,
      type: "info",
    },
  ];

  return { fields };
};

export default usePurchaseOrderLineItemsFields;
