import React from "react";
import Calendar from "Components/Calendar/Calendar";
import styles from "./PartDashboard.module.scss";
import Overview from "Containers/Overview/Overview";
import PartFilter from "Components/Part/PartFilter/PartFilter";
import PartStatuses from "Components/Part/PartStatuses/PartStatuses";

export function PartDashboard() {
  return (
    <div className={styles.dashboard}>
      <PartFilter />
      <div className={styles.dashboard__content}>
        <div className={styles.dashboard__content__left}>
          <PartStatuses />
          <div className={styles.dashboard__content__left__calendar}>
            <Calendar isLong />
          </div>
        </div>
        <div className={styles.dashboard__content__right}>
          <Overview />
        </div>
      </div>
    </div>
  );
}
