import { Grid } from "@mui/material";
import InputField from "Components/UI/Inputs/InputField";
import { format } from "Shared/Utils/app";
import React from "react";

type InvoiceFormProps = {
  po?: string;
  setPO: (value: string) => void;
  setEmails: (value: string) => void;
  emails?: string;
};

const InvoiceForm: React.FC<InvoiceFormProps> = ({
  po,
  setPO,
  setEmails,
  emails,
}) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={6}>
        <InputField
          name="po"
          value={po}
          onChange={(event) => setPO(event.target.value)}
          label="PO"
          type="number"
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <div>
          <div>Send to</div>
          <hr />
        </div>
      </Grid>
      <Grid item xs={12} md={6}>
        <InputField
          name="date"
          value={format(new Date(), "DD-MM-YYYY")}
          disabled
          label="Invoice Date"
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <InputField
          name="po"
          value={emails}
          onChange={(event) => setEmails(event.target.value)}
          label="Email"
          required
        />
      </Grid>
    </Grid>
  );
};
export default InvoiceForm;
