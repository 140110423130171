import React from "react";
import styles from "./OrderBasicHead.module.scss";
import cn from "classnames";
import { ICardData } from "Shared/Types/common";

type OrderRowProps = {
  className?: string;
  orderData: ICardData[];
  onClick: () => void;
};

const OrderRow: React.FC<OrderRowProps> = ({
  className,
  orderData,
  onClick,
}) => {
  return (
    <div className={cn(styles.row, className)} onClick={onClick}>
      <div className={cn(styles.row__body)}>
        {orderData.map(({ title, value, isValueBlue }) => (
          <div className={styles.row__body__item} key={title}>
            <span>{title}</span>
            <span className={cn(isValueBlue && styles.row__body__item__blue)}>
              {value}
            </span>
          </div>
        ))}
      </div>
    </div>
  );
};
export default OrderRow;
