import { yupResolver } from "@hookform/resolvers/yup";
import Form from "Components/UI/Form/Form";
import React from "react";
import { useForm } from "react-hook-form";
import { ObjectSchema } from "yup";
import styles from "./ServiceForm.module.scss";
import Button from "Components/UI/Button/Button";
import { IServiceAdd, IServiceUpdate } from "Shared/Types/service";
import Basic from "./Basic";
import PM from "./PM";

type ServiceFormProps = {
  onSubmit: (values: IServiceAdd | IServiceUpdate) => void;
  schema: ObjectSchema<IServiceAdd | IServiceUpdate>;
  service?: IServiceUpdate;
  onSkip?: () => void;
  isFirstAuth?: boolean;
};

const ServiceForm: React.FC<ServiceFormProps> = ({
  onSubmit,
  schema,
  service,
  onSkip,
  isFirstAuth,
}) => {
  const { handleSubmit, control } = useForm({
    resolver: yupResolver(schema),
    defaultValues: service,
  });
  return (
    <div className={styles.form}>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Basic control={control} defaultComponent={service?.component} />
        <PM control={control} defaultTime={service?.time} />
        <div className={styles.form__action}>
          {isFirstAuth && (
            <Button onClick={onSkip} color="secondary" type="button">
              Skip
            </Button>
          )}
          {service ? (
            <Button variant="outlined" type="submit">
              Save
            </Button>
          ) : (
            <Button type="submit">Create</Button>
          )}
        </div>
      </Form>
    </div>
  );
};
export default ServiceForm;
