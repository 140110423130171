import React from "react";
import styles from "./ServiceOrderAdd.module.scss";
import Button from "Components/UI/Button/Button";
import Checkbox from "@mui/material/Checkbox";
import { IService } from "Shared/Types/service";
import { Control } from "react-hook-form";
import { IActionItemAdd, IOrderAdd } from "Shared/Types/orderAdd";

type ServicesProps = {
  services: IService[] | undefined;
  control: Control<IOrderAdd>;
  fields?: IActionItemAdd[];
  append: (e: IActionItemAdd) => void;
  remove: (e: number) => void;
  actionItemList: IService[];
  servicesList: IService[];
  setActionItemList: (e: IService[]) => void;
  setServicesList: (e: IService[]) => void;
};

const OverduePm: React.FC<ServicesProps> = ({
  services,
  append,
  actionItemList,
  servicesList,
  setActionItemList,
  setServicesList,
}) => {
  const addActionItems = () => {
    setActionItemList([...actionItemList, ...servicesList]);
    servicesList.forEach((service, index) => {
      const actionItemServiceInitialValues = {
        id: index,
        key: index,
        index: service.id,
        unit: "",
        name: "",
        complaint: service,
        is_done: false,
        is_pre_authorize: false,
        is_skip_diagnosis: false,
        invoice_hours: service.hour || 0,
        technician: null,
        labor_rate: null,
        service: service,
      };
      append(actionItemServiceInitialValues);
    });
    setServicesList([]);
  };

  const onChooseService = (service: IService) => {
    if (servicesList?.includes(service)) {
      const updatedServicesList = servicesList.filter(
        (item) => item !== service
      );
      setServicesList(updatedServicesList);
    } else {
      setServicesList([...servicesList, service]);
    }
  };

  const selectAllServices = () => {
    if (services) {
      if (services.some((el) => servicesList.includes(el))) {
        setServicesList([]);
      } else {
        const restActionItems = services.filter(
          (service) => !actionItemList.includes(service)
        );
        setServicesList(restActionItems);
      }
    }
  };

  const isAllServicesChecked = services?.every(
    (service) =>
      actionItemList.some((el) => el.id === service.id) ||
      servicesList.some((el) => el.id === service.id)
  );

  return (
    <div className={styles.overdue}>
      <p className={styles.overdue__title}>
        Overdue and Coming Due
        <span style={{ fontWeight: 700, color: "#147ED8" }}>
          {" "}
          {services?.length} PMs
        </span>
      </p>
      <div className={styles.overdue__table}>
        <div className={styles.overdue__table_head}>
          <Checkbox
            inputProps={{ "aria-label": "controlled" }}
            sx={{
              color: "#C9C9C9",
              flex: 0.5,
              "&.Mui-checked": {
                color: "#007DF2",
              },
            }}
            checked={isAllServicesChecked}
            disabled={services?.every((service) =>
              actionItemList.some((el) => el.id === service.id)
            )}
            onChange={selectAllServices}
          />
          <p className={styles.item_flex3}>PMs</p>
          <p className={styles.item_flex3}>Next Due</p>
          <p className={styles.item_flex2}>Last Preformed</p>
        </div>
        <div className={styles.overdue__table_body}>
          {services?.map((service) => (
            <div key={service?.id} className={styles.overdue__table_column}>
              <Checkbox
                inputProps={{ "aria-label": "controlled" }}
                size="medium"
                sx={{
                  color: "#C9C9C9",
                  flex: 0.5,
                  "&.Mui-checked": {
                    color: "#007DF2",
                  },
                }}
                disabled={actionItemList.some((el) => el.id === service.id)}
                checked={
                  actionItemList.some((el) => el.id === service.id) ||
                  servicesList.some((el) => el.id === service.id)
                }
                onChange={() => onChooseService(service)}
              />
              <p className={styles.item_flex3}>{service.name}</p>
              <p className={styles.item_flex3}>{service.date_choice}</p>
              <p className={styles.item_flex2}>{service.created_at}</p>
            </div>
          ))}
        </div>
      </div>
      <div onClick={addActionItems} style={{ float: "right" }}>
        <Button disabled={!servicesList.length} variant="contained">
          Add
        </Button>
      </div>
    </div>
  );
};

export default OverduePm;
