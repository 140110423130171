import React from "react";
import {
  Table as MuiTable,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";
import styles from "./StatsTable.module.scss";
import { statsData } from "Shared/Constants/stats";

const StatsTable = () => {
  return (
    <div className={styles.container}>
      <MuiTable className={styles.table}>
        <TableHead>
          <TableRow>
            <TableCell />
            {statsData.dates.map((date) => (
              <TableCell key={date}>{date}</TableCell>
            ))}
            <TableCell>Total</TableCell>
            <TableCell>Rank</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {statsData.stats.map((stat) => {
            const valuesWithPlaceholders = new Array(7).fill("");
            stat.values.forEach((value, index) => {
              if (
                value !== undefined &&
                index < valuesWithPlaceholders.length
              ) {
                valuesWithPlaceholders[index] = value;
              }
            });

            return (
              <TableRow key={stat.label}>
                <TableCell component="th" scope="row">
                  {stat.label}
                </TableCell>
                {valuesWithPlaceholders.map((value, index) => (
                  <TableCell key={index}>{value}</TableCell>
                ))}
                <TableCell>{stat.total || "-"}</TableCell>
                <TableCell>{stat.rank !== null ? stat.rank : "-"}</TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </MuiTable>
    </div>
  );
};

export default StatsTable;
