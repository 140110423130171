import React, { useState, useEffect } from "react";
import Tabs from "Components/UI/Tabs/Tabs";
import styles from "./Company.module.scss";
import DeleteModal from "Components/UI/DialogWindow/DeleteModal";
import RejectButton from "Components/UI/Button/RejectButton";
import PaymentHistory from "./PaymentHistory";
import { useSelector } from "react-redux";
import { selectCurrentCompany } from "Redux/Company/slice";
import Profile from "./Profile";
import { useAppDispatch } from "Redux/store";
import { notifySuccess } from "Components/Notify/notify";
import AcceptButton from "Components/UI/Button/AcceptButton";
import { getCompany, updateCompanyStatus } from "Redux/Company/reducers";
import { useParams, useNavigate } from "react-router";
import IconButton from "Components/UI/IconButton/IconButton";
import { EditIcon } from "Assets/index";

export const CompanyItem = () => {
  const [activeTab, setActiveTab] = useState(0);
  const [openDialog, setOpenDialog] = useState(false);
  const dispatch = useAppDispatch();
  const params = useParams();
  const company = useSelector(selectCurrentCompany);
  const navigate = useNavigate();

  const handleDialogClose = () => setOpenDialog(false);

  const goToEditPage = () => {
    navigate(`/company/edit/${company?.id}`);
  };

  const handleBlock = async () => {
    const onSuccess = () => {
      notifySuccess("User blocked!");
      handleDialogClose();
      navigate("/company");
    };
    if (company?.id) {
      await dispatch(
        updateCompanyStatus({
          id: company.id,
          data: {
            ...company,
            owner: company.owner?.id,
            status: "inactive",
          },
        })
      );
      onSuccess();
    }
  };

  const handleUnblock = async () => {
    const onSuccess = () => {
      notifySuccess("User unblocked!");
      handleDialogClose();
      navigate("/company");
    };
    if (company?.id) {
      await dispatch(
        updateCompanyStatus({
          id: company.id,
          data: {
            ...company,
            owner: company.owner?.id,
            status: "active",
          },
        })
      );
      onSuccess();
    }
  };

  useEffect(() => {
    if (params.companyId) {
      dispatch(getCompany(params.companyId));
    }
  }, [params.companyId]);

  return (
    <div className={styles.user}>
      <div className={styles.user__header}>
        {company?.status === "active" && (
          <RejectButton
            onClick={() => setOpenDialog(true)}
            className={styles.user__header__blockBtn}
          >
            Block
          </RejectButton>
        )}
        {company?.status === "inactive" && (
          <AcceptButton
            onClick={() => setOpenDialog(true)}
            className={styles.user__header__blockBtn}
          >
            Unblock
          </AcceptButton>
        )}
        <div className={styles.user__header__action}>
          <IconButton icon={EditIcon} onClick={goToEditPage} />
        </div>
      </div>
      {company?.status === "active" && (
        <DeleteModal
          open={openDialog}
          handleClose={handleDialogClose}
          title="Block"
          text="Are you sure...?"
          accept={handleBlock}
          acceptTitle="Block"
          color="red"
        />
      )}
      {company?.status === "inactive" && (
        <DeleteModal
          open={openDialog}
          handleClose={handleDialogClose}
          title="Unblock"
          text="Are you sure...?"
          accept={handleUnblock}
          acceptTitle="Unblock"
          color="green"
        />
      )}
      {company && (
        <Tabs
          tabs={["Profile", "Payment history"]}
          onChange={setActiveTab}
          activeTab={activeTab}
          tabPanels={[
            <Profile company={company} key="company" />,
            <PaymentHistory company={company} key="payment" />,
          ]}
        />
      )}
    </div>
  );
};
