import React, { useEffect, useState } from "react";
import styles from "./CustomerInfo.module.scss";

import { useNavigate, useParams } from "react-router";
import { useAppDispatch } from "Redux/store";
import IconButton from "Components/UI/IconButton/IconButton";
import { DeleteIcon, EditIcon } from "Assets/index";
import Tabs from "Components/UI/Tabs/Tabs";
import DeleteModal from "Components/UI/DialogWindow/DeleteModal";
import Details from "./CustomerDetails/CustomerDetails";
import { deleteCustomer, getCustomer } from "Redux/Customer/reducer";

const CustomerInfo: React.FC = () => {
  const [activeTab, setActiveTab] = useState(0);
  const [showDelete, setShowDelete] = useState(false);
  const [deleteId, setDeleteId] = useState<string | null>();
  const navigate = useNavigate();
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const goToEditPage = (id: string | undefined) => {
    if (!id) return;
    navigate(`/customers/edit/${id}`);
  };
  const handleDialogClose = () => {
    setShowDelete(false);
    setDeleteId(null);
  };

  const handleDialogOpen = (id: string | undefined) => {
    if (!id) return;
    setShowDelete(true);
    setDeleteId(id);
  };

  const handleDeleteEmployee = async () => {
    if (deleteId) {
      await dispatch(deleteCustomer(deleteId));
    }
  };

  useEffect(() => {
    if (id) {
      dispatch(getCustomer(id));
    }
  }, [id]);

  return (
    <div className={styles.info}>
      <div className={styles.info__head}>
        <div className={styles.info__head__icons}>
          <IconButton
            icon={EditIcon}
            size="small"
            onClick={() => goToEditPage(id)}
          />
          <IconButton
            icon={DeleteIcon}
            size="small"
            onClick={() => handleDialogOpen(id)}
          />
        </div>
      </div>
      {
        <Tabs
          tabs={["Details", "SO History", "Transactions"]}
          tabPanels={[<Details key="details" />]}
          activeTab={activeTab}
          onChange={setActiveTab}
        />
      }
      <DeleteModal
        acceptTitle="Delete"
        open={showDelete}
        handleClose={handleDialogClose}
        title="Delete"
        text="Are you sure...?"
        accept={handleDeleteEmployee}
      />
    </div>
  );
};
export default CustomerInfo;
