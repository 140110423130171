import React, { useEffect } from "react";
import styles from "./RolesUpdate.module.scss";
import Icon from "Components/UI/Icon/Icon";
import { RightIcon } from "Assets/index";
import RoleForm from "Components/Settings/Roles/Form/Form";
import { IRolesUpdate } from "Shared/Types/role";
import { useAppDispatch } from "Redux/store";
import { getPermissions, getRole, updateRole } from "Redux/Role/reducers";
import { useSelector } from "react-redux";
import { removeCurrentRole, selectCurrentRole } from "Redux/Role/slice";
import { notifySuccess } from "Components/Notify/notify";
import { useNavigate, useParams } from "react-router";

const RolesUpdate: React.FC = () => {
  const dispatch = useAppDispatch();
  const params = useParams();
  const role = useSelector(selectCurrentRole);
  const navigate = useNavigate();

  const back = () => {
    navigate("/settings/roles");
  };
  const editRole = async (values: IRolesUpdate) => {
    const onSuccess = () => {
      notifySuccess("Updated Role!");
      back();
    };
    if (role?.id) {
      await dispatch(updateRole({ id: role.id, data: values, onSuccess }));
    }
  };

  const fetchRole = async () => {
    await dispatch(getPermissions());
    if (params.id) {
      dispatch(getRole(params.id));
    }
  };

  useEffect(() => {
    fetchRole();
    return () => {
      dispatch(removeCurrentRole());
    };
  }, [params.id]);

  return (
    <div className={styles.role}>
      <div className={styles.role__head}>
        <Icon
          component={RightIcon}
          fontSize="small"
          width={10}
          onClick={back}
        />{" "}
        Update Role & Access
      </div>
      <div className={styles.role__permissions}>
        {role && <RoleForm onSubmit={editRole} role={role} />}
      </div>
    </div>
  );
};
export default RolesUpdate;
