import React, { ReactNode } from "react";
import Sidebar from "Components/Sidebar/Sidebar";
import styles from "./Layout.module.scss";
import Breadcrumb from "Components/Breadcrumb/Breadcrumb";
import { useSelector } from "react-redux";
import { selectBreadcrumb } from "Redux/App/slice";
import Header from "Components/Headers/Header";
import { useNavigate } from "react-router";
import IconButton from "Components/UI/IconButton/IconButton";
import { PlusIcon } from "Assets/index";

type LayoutProps = {
  children: ReactNode;
};

const Layout: React.FC<LayoutProps> = ({ children }) => {
  const { links, isNeedCreateBtn, createLink, createTitle } =
    useSelector(selectBreadcrumb);
  const navigate = useNavigate();
  const handleGoToCreatePage = () => {
    if (createLink) {
      navigate(createLink);
    }
  };

  return (
    <div className={styles.layout}>
      <div className={styles.layout__bg}></div>
      <Sidebar />
      <div className={styles.layout__container}>
        <div style={{ maxHeight: "18vh" }}>
          <div className={styles.layout__header}>
            <Header />
          </div>
          <div className={styles.layout__breadcrumb}>
            {links?.length > 0 && (
              <div>
                <Breadcrumb breads={links} />
              </div>
            )}
            {isNeedCreateBtn && (
              <IconButton
                onClick={handleGoToCreatePage}
                label={`Create ${createTitle}`}
                width={30}
                height={30}
                icon={PlusIcon}
              />
            )}
          </div>
        </div>
        <div className={styles.layout__content}>{children}</div>
      </div>
    </div>
  );
};
export default Layout;
