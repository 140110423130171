import React, { useState } from "react";
import Form from "Components/UI/Form/Form";
import styles from "./Login.module.scss";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { ICompanyRequestAdd } from "Shared/Types/company";
import { registerAuthSchema } from "Shared/Utils/auth";
import { addCompanyRequest } from "Redux/CompanyRequests/reducers";
import { useAppDispatch } from "Redux/store";
import { notifySuccess } from "Components/Notify/notify";
import { useSelector } from "react-redux";
import AuthLayout2 from "Components/Layout/AuthLayout/AuthLayout";
import InputField from "Components/Layout/Inputs/InputField";
import Button from "Components/Layout/Button/Button";
import { selectAuthLoading } from "Redux/Auth/slice";
import { Grid } from "@mui/material";
import RegisterSuccessFull from "Components/Layout/AuthLayout/RegisterSuccessfull/RegisterSuccessFull";
import { REGISTER, registerFields, registerLinks } from "Shared/Constants/auth";

const RegisterContainer: React.FC = () => {
  const dispatch = useAppDispatch();
  const loading = useSelector(selectAuthLoading);
  const [isRegisteredSuccessfully, setIsRegisteredSuccessfully] =
    useState<boolean>(false);
  const { handleSubmit, control, getValues } = useForm<ICompanyRequestAdd>({
    resolver: yupResolver(registerAuthSchema),
  });
  const onSubmit = async (values: ICompanyRequestAdd) => {
    const onSuccess = () => {
      notifySuccess("Your request sent");
      setIsRegisteredSuccessfully(true);
    };
    await dispatch(addCompanyRequest({ data: values, onSuccess }));
  };

  return (
    <>
      {isRegisteredSuccessfully ? (
        <RegisterSuccessFull firstName={getValues("first_name")} />
      ) : (
        <AuthLayout2
          links={registerLinks}
          title={REGISTER.TITLE}
          descrption={REGISTER.DESCRIPTION}
          isLarge
        >
          <Form onSubmit={handleSubmit(onSubmit)} className={styles.register}>
            <Grid container spacing={2}>
              {registerFields.map((field) => (
                <Grid item xs={field.xs} key={field.name}>
                  <InputField {...field} control={control} withController />
                </Grid>
              ))}
            </Grid>
            <Button
              variant="contained"
              color="primary-light"
              type="submit"
              fullWidth
              loading={loading}
            >
              Send Request
            </Button>
          </Form>
        </AuthLayout2>
      )}
    </>
  );
};
export default RegisterContainer;
