import { StyleSheet } from "@react-pdf/renderer";

export const styles = StyleSheet.create({
  costItem: {
    display: "flex",
    flexDirection: "row",
    paddingRight: 20,
  },
  costTitle: {
    fontWeight: "bold",
    marginRight: 5,
  },
  costValue: {},
  costValueGreen: {
    color: "green",
  },
});
