import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { IPart } from "Shared/Types/part";
import {
  createPart,
  deletePart,
  getEstimatePartList,
  getPart,
  getPartList,
  getPartLocation,
  getPricePartList,
  updatePart,
} from "./reducer";
import { ILocation } from "Shared/Types/location";
import { IEstimatesOrder } from "Shared/Types/estimate";
import { IOrderList } from "Shared/Types/order";

interface PartState {
  partList?: IPart[];
  estimatePartList: IEstimatesOrder[];
  pricePartList: IOrderList[];
  currentPart?: IPart | null;
  currentPartLocation?: ILocation | null;
  loading: boolean;
  numPage: number;
}

const initialState: PartState = {
  currentPart: null,
  currentPartLocation: null,
  loading: false,
  numPage: 1,
  estimatePartList: [],
  pricePartList: [],
};

const partSlice = createSlice({
  name: "part",
  initialState,
  reducers: {
    removeCurrentLocation(state) {
      state.currentPartLocation = null;
    },
  },
  extraReducers(builder) {
    builder.addCase(getPart.pending, (state) => {
      state.loading = true;
      state.currentPart = null;
    }),
      builder.addCase(getPart.rejected, (state) => {
        state.loading = false;
        state.currentPart = null;
      }),
      builder.addCase(getPart.fulfilled, (state, action) => {
        state.currentPart = action.payload;
        state.loading = false;
      });
    builder.addCase(getPartLocation.pending, (state) => {
      state.loading = true;
      state.currentPartLocation = null;
    });
    builder.addCase(getPartLocation.rejected, (state) => {
      state.loading = false;
      state.currentPartLocation = null;
    });
    builder.addCase(getPartLocation.fulfilled, (state, action) => {
      state.loading = false;
      state.currentPartLocation = action.payload;
    });
    builder.addCase(createPart.pending, (state) => {
      state.loading = true;
    }),
      builder.addCase(createPart.rejected, (state) => {
        state.loading = false;
      }),
      builder.addCase(createPart.fulfilled, (state) => {
        state.loading = false;
      });
    builder.addCase(updatePart.pending, (state) => {
      state.loading = true;
    }),
      builder.addCase(updatePart.rejected, (state) => {
        state.loading = false;
      }),
      builder.addCase(updatePart.fulfilled, (state) => {
        state.loading = false;
      }),
      builder.addCase(deletePart.pending, (state) => {
        state.loading = true;
      }),
      builder.addCase(deletePart.rejected, (state) => {
        state.loading = false;
      }),
      builder.addCase(deletePart.fulfilled, (state) => {
        state.loading = false;
      });

    builder.addCase(getPartList.pending, (state) => {
      state.loading = true;
      state.partList = state.partList || [];
    });
    builder.addCase(getPartList.rejected, (state) => {
      state.loading = false;
      state.partList = state.partList || [];
    });
    builder.addCase(getPartList.fulfilled, (state, action) => {
      state.loading = false;
      state.partList = action.payload.results;
      state.numPage = action.payload.num_pages;
    });

    builder.addCase(getEstimatePartList.pending, (state) => {
      state.loading = true;
      state.partList = state.partList || [];
    });
    builder.addCase(getEstimatePartList.rejected, (state) => {
      state.loading = false;
      state.partList = state.partList || [];
    });
    builder.addCase(getEstimatePartList.fulfilled, (state, action) => {
      state.loading = false;
      state.estimatePartList = action.payload;
      state.numPage = 1;
    });

    builder.addCase(getPricePartList.pending, (state) => {
      state.loading = true;
      state.partList = state.partList || [];
    });
    builder.addCase(getPricePartList.rejected, (state) => {
      state.loading = false;
      state.partList = state.partList || [];
    });
    builder.addCase(getPricePartList.fulfilled, (state, action) => {
      state.loading = false;
      state.pricePartList = action.payload;
      state.numPage = 1;
    });
  },
});

export const { removeCurrentLocation } = partSlice.actions;

export const selectPartLoading = (state: RootState) => state.part.loading;
export const selectPartList = (state: RootState) => state.part.partList;
export const selectPricePartList = (state: RootState) =>
  state.part.pricePartList;
export const selectEstimatePartList = (state: RootState) =>
  state.part.estimatePartList;
export const selectCurrentPart = (state: RootState) => state.part.currentPart;
export const selectPartNumPage = (state: RootState) => state.part.numPage;
export const selectCurrentPartLocation = (state: RootState) =>
  state.part.currentPartLocation;

export default partSlice;
