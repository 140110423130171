import React, { useEffect, useRef, useState } from "react";
import styles from "./PartMore.module.scss";
import { Icon } from "@mui/material";
import { DeleteIcon, EditIcon, ThreeDotsIcon } from "Assets/index";

type PartMoreProps = {
  openDelete: () => void;
  onEdit: () => void;
};

const PartMore: React.FC<PartMoreProps> = ({ openDelete, onEdit }) => {
  const [openMore, setOpenMore] = useState(false);

  const moreContentRef = useRef<HTMLDivElement>(null);

  const handleClickOutside = (event: MouseEvent) => {
    if (
      moreContentRef.current &&
      !moreContentRef.current?.contains(event.target as Node)
    ) {
      setOpenMore(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);
  return (
    <div className={styles.more}>
      <Icon
        component={ThreeDotsIcon}
        onClick={(event) => {
          event.stopPropagation();
          setOpenMore(!openMore);
        }}
      />
      {openMore && (
        <div className={styles.more__content} ref={moreContentRef}>
          <div className={styles.more__content__item} onClick={openDelete}>
            <Icon component={DeleteIcon} /> Delete
          </div>
          <div className={styles.more__content__item} onClick={onEdit}>
            <Icon component={EditIcon} /> Edit
          </div>
        </div>
      )}
    </div>
  );
};
export default PartMore;
