import { yupResolver } from "@hookform/resolvers/yup";
import Form from "Components/UI/Form/Form";
import { IUnitAdd, IUnitUpdate } from "Shared/Types/unit";
import React from "react";
import { FormProvider, useForm } from "react-hook-form";
import { ObjectSchema } from "yup";
import BasicInfo from "./BasicInfo";
import Button from "Components/UI/Button/Button";
import Additional from "./Additional";
import styles from "./UnitForm.module.scss";
import Block from "Components/UI/Block/Block";

type UnitFormProps = {
  onSubmit: (values: IUnitAdd | IUnitUpdate) => void;
  schema: ObjectSchema<IUnitAdd | IUnitUpdate>;
  unit?: IUnitAdd | IUnitUpdate;
  onSkip?: () => void;
  isFirstAuth?: boolean;
};

const UnitForm: React.FC<UnitFormProps> = ({
  onSubmit,
  schema,
  unit,
  onSkip,
  isFirstAuth,
}) => {
  const medhods = useForm({
    resolver: yupResolver(schema),
    defaultValues: unit,
  });
  return (
    <Block>
      <div className={styles.form}>
        <FormProvider {...medhods}>
          <Form onSubmit={medhods.handleSubmit(onSubmit)}>
            <BasicInfo control={medhods.control} />
            <Additional control={medhods.control} />
            <div className={styles.form__action}>
              {isFirstAuth && (
                <Button onClick={onSkip} color="secondary" type="button">
                  Skip
                </Button>
              )}
              {unit ? (
                <Button variant="outlined" type="submit">
                  Save
                </Button>
              ) : (
                <Button type="submit">Create</Button>
              )}
            </div>
          </Form>
        </FormProvider>
      </div>
    </Block>
  );
};
export default UnitForm;
