import React from "react";
import Layout from "Components/Layout/Layout";
import RequestRejectDetailsContainer from "Containers/Requests/RequestRejectDetails/RequestRejectDetails";
import { useBreadcrumb } from "Shared/Hooks/use-breadcrumb";
import { useSelector } from "react-redux";
import { selectCurrentCompanyRequest } from "Redux/CompanyRequests/slice";
import { BreadcrumbItem } from "Components/Breadcrumb/Breadcrumb";

export function RequestsRejectDetails() {
  const request = useSelector(selectCurrentCompanyRequest);
  const breads: BreadcrumbItem[] = [
    { title: "Request", link: "/requests" },
    { title: "Rejected", link: "/requests/rejected" },
    { title: request?.company_name || "", link: "" },
  ];

  useBreadcrumb({ links: breads, option: { upd: request?.company_name } });
  return (
    <Layout>
      <RequestRejectDetailsContainer />
    </Layout>
  );
}
