import React, { Dispatch, ReactNode, SetStateAction } from "react";
import Layout from "Components/Layout/Layout";
import RequestFilter from "Components/Request/RequestFilter/RequestFilter";
import styles from "./RequestsLayout.module.scss";
import { FilterPanel } from "Components/Admin/FilterPanel/FilterPanel";
import cn from "classnames";

interface RequestsLayoutProps {
  status?: string;
  children: ReactNode;
  isCard: boolean;
  setIsCard: Dispatch<SetStateAction<boolean>>;
  fields: string[];
  setFields: (fields: string[]) => void;
  fieldAllList: string[];
  limitFields?: number;
  onHold?: boolean;
}

export const RequestsLayout: React.FC<RequestsLayoutProps> = ({
  children,
  isCard,
  setIsCard,
  fields,
  setFields,
  fieldAllList,
  limitFields,
}) => {
  return (
    <Layout>
      <div className={styles.requests}>
        <RequestFilter />
        <FilterPanel
          setIsCard={setIsCard}
          isCard={isCard}
          fields={fields}
          setFields={setFields}
          fieldAllList={fieldAllList}
          limitFields={limitFields}
        />
        <div className={styles.requests__content}>
          <div
            className={cn(
              styles.requests__container,
              !isCard && styles.requests__container_row,
            )}
          >
            {children}
          </div>
        </div>
      </div>
    </Layout>
  );
};
