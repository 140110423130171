import { Grid } from "@mui/material";
import PartSearch from "Components/Order/ActionItemDetial/PartItem/PartSearch/PartSearch";
import { VendorSearch } from "Components/UI/AutoCompleteSearchInputs/VendorSearch";
import InputField from "Components/UI/Inputs/InputField";
import usePurchasePartFields, {
  TField,
} from "Shared/Hooks/purchase-order/use-purchase-part-fields";
import { IPart } from "Shared/Types/part";
import { isInstanced } from "Shared/Utils/app";
import React, { useEffect } from "react";
import { useFormContext } from "react-hook-form";

type PartItemProps = {
  partIndex: number;
  getOrders: () => void;
};

const PartItem: React.FC<PartItemProps> = ({ partIndex, getOrders }) => {
  const { control, getValues, setValue } = useFormContext();
  const { fields } = usePurchasePartFields();

  const getName = (name: string) => `parts[${partIndex}].${name}`;
  const renderInput = (field: TField) => {
    const onPartChange = (value?: IPart | null | string | number) => {
      if (isInstanced<IPart>(value)) {
        setValue(`parts[${partIndex}].in_stock`, value.in_stock);
        setValue(`parts[${partIndex}].in_transit`, false);
        setValue(`parts[${partIndex}].min_quantity`, value.min_quantity);
        setValue(`parts[${partIndex}].max_quantity`, value.max_quantity);
        setValue(`parts[${partIndex}].average_cost`, 0);
        setValue(`parts[${partIndex}].last_cost`, value.price);
        setValue(`parts[${partIndex}].description`, value.description);
      }
      getOrders();
      getValues(`parts[${partIndex}]`);
    };
    switch (field.type) {
      case "part-search":
        return (
          <PartSearch
            requered={field.required}
            name={getName(field.name)}
            label={field.label || ""}
            onChange={(value) => onPartChange(value)}
          />
        );
      case "vendor-search":
        return (
          <VendorSearch
            name={getName(field.name)}
            label={field.label}
            withControl
            value={getValues("vendor")}
            onChange={getOrders}
            required
          />
        );
      default:
        return (
          <InputField
            label={field.label}
            required={field.required}
            type={field.type}
            name={getName(field.name)}
            control={control}
            disabled={field.disabled}
            onChange={getOrders}
            withController
          />
        );
    }
  };

  useEffect(() => {
    getOrders();
  }, [getValues(getName("vendor"))]);
  return (
    <Grid container columnSpacing={2} mb="20px" mt="20px">
      {fields.map((field) => (
        <Grid item xs={field.xs} key={field.name}>
          {renderInput(field)}
        </Grid>
      ))}
    </Grid>
  );
};
export default PartItem;
