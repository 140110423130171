import React, { ReactNode } from 'react';

type FormProps = Omit<React.FormHTMLAttributes<HTMLFormElement>, 'children'> & {
  children: ReactNode,
};

const Form: React.FC<FormProps> = ({ children, ...props }) => {

  return <form {...props}>{children}</form>
}
export default Form;