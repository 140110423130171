import { selectGlobalSearchResult } from "Redux/GlobalSearch/slice";
import React from "react";
import { useSelector } from "react-redux";
import styles from "./SearchResult.module.scss";
import { useNavigate } from "react-router";

type SearchResultProps = {
  onClose: () => void;
};

const SearchResult: React.FC<SearchResultProps> = ({ onClose }) => {
  const results = useSelector(selectGlobalSearchResult);
  const navigate = useNavigate();
  const isAllEmpty =
    !results?.units?.length &&
    !results?.orders?.length &&
    !results?.products?.length &&
    !results?.customers?.length &&
    !results?.vendors?.length &&
    !results?.services?.length;
  const goToInnerPage = (url: string) => {
    navigate(url);
    onClose();
  };

  const isHave = (length?: number) => {
    if (!length) return false;
    return length > 0;
  };
  return (
    <div className={styles.modal}>
      {isAllEmpty && <h4>Nothing was found for your request</h4>}
      {isHave(results?.units?.length) && (
        <div>
          <h4>Units</h4>
          <ul>
            {results?.units.map(({ id, vin, year, number, model, brand }) => (
              <li key={id} onClick={() => goToInnerPage(`/units/edit/${id}`)}>
                {`${id} - ${year}, ${brand} ${model} Vin: ${vin}, Number: ${number}`}
              </li>
            ))}
          </ul>
        </div>
      )}
      {isHave(results?.orders?.length) && (
        <div>
          <h4>Service Orders</h4>
          <ul>
            {results?.orders.map(({ id, customer }) => (
              <li
                key={id}
                onClick={() => goToInnerPage(`/orders/assigned/${id}`)}
              >{`${id}, (${customer?.company_name || ""})`}</li>
            ))}
          </ul>
        </div>
      )}
      {isHave(results?.products?.length) && (
        <div>
          <h4>Parts</h4>
          <ul>
            {results?.products.map(({ id, description }) => (
              <li key={id}>{`${id}, (${description})`}</li>
            ))}
          </ul>
        </div>
      )}
      {isHave(results?.customers?.length) && (
        <div>
          <h4>Customers</h4>
          <ul>
            {results?.customers.map(({ id, company_name }) => (
              <li
                key={id}
                onClick={() => goToInnerPage(`/customers/edit/${id}`)}
              >{`${id}, (${company_name})`}</li>
            ))}
          </ul>
        </div>
      )}
      {isHave(results?.vendors?.length) && (
        <div>
          <h4>Vendors</h4>
          <ul>
            {results?.vendors.map(({ id, vendor_name }) => (
              <li
                onClick={() => goToInnerPage(`/vendors/${id}`)}
                key={id}
              >{`${id}, (${vendor_name})`}</li>
            ))}
          </ul>
        </div>
      )}
      {isHave(results?.services?.length) && (
        <div>
          <h4>Services</h4>
          <ul>
            {results?.services.map(({ id, name }) => (
              <li
                onClick={() => goToInnerPage(`/services/${id}`)}
                key={id}
              >{`${id}, (${name})`}</li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};
export default SearchResult;
