import React, { useRef } from "react";
import styles from "Components/Order/ServiceOrderForms/ServiceOrderAdd.module.scss";
import { Grid } from "@mui/material";
import Checkbox from "Components/UI/Inputs/Checkbox";
import { FormProvider, useForm } from "react-hook-form";
import { ComplaintServiceSearch } from "Components/UI/AutoCompleteSearchInputs/ComplaintServiceSearch";
import { ServiceSearch } from "Components/UI/AutoCompleteSearchInputs/ServiceSearch";
import Form from "Components/UI/Form/Form";
import { yupResolver } from "@hookform/resolvers/yup";
import { actionItemAddSchema } from "Shared/Utils/order";
import Button from "Components/UI/Button/Button";
import { IActionItemAdd } from "Shared/Types/order";

type ActionItemFormProps = {
  orderId: string;
  onSubmit: (values: IActionItemAdd) => void;
  onCancel?: () => void;
};

const ActionItemForm: React.FC<ActionItemFormProps> = ({
  orderId,
  onSubmit,
  onCancel,
}) => {
  const methods = useForm({
    resolver: yupResolver(actionItemAddSchema),
    defaultValues: {
      order: orderId,
      complaint: "",
      service: null,
      is_pre_authorize: false,
      is_skip_diagnosis: false,
    },
    mode: "onTouched",
  });
  const serviceInputRef = useRef<HTMLInputElement | null>(null);
  return (
    <FormProvider {...methods}>
      <Form
        className={styles.form_section}
        onSubmit={methods.handleSubmit(onSubmit)}
      >
        <Grid container>
          <Grid item xs={12} mb="10px">
            <ComplaintServiceSearch
              name="complaint"
              label="Customer Complaint"
              service_name="service"
              required
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  e.preventDefault();
                  serviceInputRef?.current?.focus();
                }
              }}
            />
          </Grid>
          <Grid item xs={12} mb="10px">
            <Checkbox
              withController
              control={methods.control}
              label="Pre-authorize"
              name="is_pre_authorize"
              labelPosition="right"
            />
          </Grid>
          <Grid item xs={12} mb="10px">
            <Checkbox
              withController
              control={methods.control}
              label="Skip Diagnosis"
              name="is_skip_diagnosis"
              labelPosition="right"
            />
          </Grid>
          <Grid item xs={12} mb="10px">
            <ServiceSearch
              inputRef={serviceInputRef}
              name="service"
              label="Component / System"
            />
          </Grid>

          <Grid item container xs={12} gap="10px" justifyContent="end">
            <Button color="secondary" onClick={onCancel}>
              Cancel
            </Button>
            <Button type="submit"> Submit</Button>
          </Grid>
        </Grid>
      </Form>
    </FormProvider>
  );
};

export default ActionItemForm;
