import React, { useEffect, useState } from "react";
import Modal from "Components/UI/Modal/Modal";
import IconButton from "Components/UI/IconButton/IconButton";
import { ActionHistoryIcon } from "Assets/index";
import { useSelector } from "react-redux";
import { useAppDispatch } from "Redux/store";
import styles from "./OrderInnerHistory.module.scss";
import { format } from "Shared/Utils/app";
import {
  clearOrderInnerHistory,
  selectInnerHistoryList,
} from "Redux/History/slice";
import { getOrderInnerHistory } from "Redux/History/reducer";

type OrderInnerHistoryProps = {
  orderItemId?: string;
};

const OrderInnerHistory: React.FC<OrderInnerHistoryProps> = ({
  orderItemId,
}) => {
  const orderInnerHistoryList = useSelector(selectInnerHistoryList);
  const dispatch = useAppDispatch();
  const [openOrderInnerHistoryModal, setOpenOrderInnerHistoryModal] =
    useState<boolean>(false);

  const toggleActionItemHistoryModal = () => {
    setOpenOrderInnerHistoryModal(!openOrderInnerHistoryModal);
  };

  useEffect(() => {
    if (openOrderInnerHistoryModal) {
      if (orderItemId) {
        dispatch(getOrderInnerHistory(orderItemId));
      }
    }
    if (!openOrderInnerHistoryModal) {
      dispatch(clearOrderInnerHistory());
    }
  }, [openOrderInnerHistoryModal]);

  return (
    <div className={styles.history}>
      <IconButton
        variant="primary"
        icon={ActionHistoryIcon}
        width={23}
        height={23}
        style={{ padding: "7px" }}
        onClick={toggleActionItemHistoryModal}
      />
      <Modal
        isClosed
        open={openOrderInnerHistoryModal}
        onClose={toggleActionItemHistoryModal}
        title="History"
        icon={ActionHistoryIcon}
      >
        {orderInnerHistoryList?.map((history) => (
          <div key={history.id} className={styles.history__item}>
            <img
              className={styles.history__item__img}
              src={history.employee.avatar || ""}
              alt="/"
            />
            <div className={styles.history__item__desc_cont}>
              <p className={styles.underline}>
                {history.employee.first_name} {history.employee.last_name}
              </p>
              <p className={styles.history__item__desc_cont__type_text}>
                Type:{" "}
                <span style={{ fontWeight: "normal" }}>{history.type}</span>{" "}
                <span className={styles.underline}>Serves Order</span>
              </p>
              <div>
                <p>
                  {history.created_at && format(history.created_at, "h:mm a")}
                </p>
                <p>
                  {history.created_at &&
                    format(history.created_at, "MM.DD.YYYY")}
                </p>
                <p>{history.employee.phone} (phone)</p>
                <p>IP Address: {history.ip_address}</p>
              </div>
            </div>
          </div>
        ))}
      </Modal>
    </div>
  );
};

export default OrderInnerHistory;
