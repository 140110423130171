import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { addOrderNote, deleteOrderNotes, getOrderNoteList } from "./reducer";
import { IOrderNote } from "Shared/Types/notes";

interface OrderNoteState {
  orderNoteList?: IOrderNote[];
  error?: string | null;
  loading: boolean;
  success?: string | null;
}

const initialState: OrderNoteState = {
  orderNoteList: [],
  loading: false,
  success: null,
};

const orderNoteSlice = createSlice({
  name: "orderNote",
  initialState,
  reducers: {
    clear(state) {
      state.loading = false;
      state.error = null;
      state.success = null;
    },
    resetNoteList(state) {
      state.orderNoteList = [];
    },
  },
  extraReducers(builder) {
    builder.addCase(getOrderNoteList.fulfilled, (state, action) => {
      state.loading = false;
      state.error = null;
      state.orderNoteList = action.payload;
    });

    builder.addCase(addOrderNote.pending, (state) => {
      state.loading = true;
      state.error = null;
      state.success = null;
    });
    builder.addCase(addOrderNote.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
      state.success = null;
    });
    builder.addCase(addOrderNote.fulfilled, (state) => {
      state.loading = false;
      state.error = null;
      state.success = "Added Note";
    });

    builder.addCase(deleteOrderNotes.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(deleteOrderNotes.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
    builder.addCase(deleteOrderNotes.fulfilled, (state) => {
      state.loading = false;
      state.error = null;
      state.success = "Note is deleted";
    });
  },
});

export const { clear, resetNoteList } = orderNoteSlice.actions;

export const selectOrderNoteLoading = (state: RootState) =>
  state.orderNote.loading;
export const selectOrderNoteError = (state: RootState) => state.orderNote.error;
export const selectOrderNoteSuccess = (state: RootState) =>
  state.orderNote.success;
export const selectOrderNoteList = (state: RootState) =>
  state.orderNote.orderNoteList;

export default orderNoteSlice;
