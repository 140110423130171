import Switch from "Components/UI/Switch/Switch";
import React, { useEffect, useState } from "react";
import styles from "./OnHold.module.scss";
import { IOrder } from "Shared/Types/order";
import { useAppDispatch } from "Redux/store";
import { updateOrder } from "Redux/Order/reducer";
import { notifySuccess } from "Components/Notify/notify";
type OnHoldProps = {
  order?: IOrder | null;
};

const OnHold: React.FC<OnHoldProps> = ({ order }) => {
  const dispatch = useAppDispatch();
  const [checked, setChecked] = useState(false);
  const handleOnHold = (value: boolean) => {
    setChecked(value);
    if (order) {
      dispatch(
        updateOrder({
          id: order.id,
          data: {
            on_hold: value,
          },
          onSuccess: () => {
            notifySuccess("Order is updated");
          },
        })
      );
    }
  };

  useEffect(() => {
    setChecked(Boolean(order?.on_hold));
  }, [order]);

  return (
    <div className={styles.onHold}>
      <Switch
        checked={checked}
        labelRight
        label="On hold"
        labelColor="gray"
        onChange={(_, checked) => handleOnHold(checked)}
      />
    </div>
  );
};
export default OnHold;
