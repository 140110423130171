import Layout from "Components/Layout/Layout";
import UnitList from "Containers/Unit/UnitList/UnitList";
import React from "react";
import { BreadcrumbItem } from "Components/Breadcrumb/Breadcrumb";
import { useBreadcrumb } from "Shared/Hooks/use-breadcrumb";

export const UnitListPage: React.FC = () => {
  const breads: BreadcrumbItem[] = [{ title: "Units", link: "/units" }];
  useBreadcrumb({ links: breads, createTitle: "Unit", isNeedCreateBtn: true });

  return (
    <Layout>
      <UnitList />
    </Layout>
  );
};
