import { client } from "Service/axios";
import {
  ILaborRate,
  ILaborRateAdd,
  ILaborRateSearchParams,
  ILaborRateUpdate,
} from "Shared/Types/laborRate";

const LABOR_RATE_URL = "company/labor_rate/";
class LaborRate {
  getLaborRateList(params: ILaborRateSearchParams | null) {
    return client.get(LABOR_RATE_URL, { params });
  }

  getLaborRate(id: string) {
    return client.get<ILaborRate>(`${LABOR_RATE_URL}${id}/`);
  }

  createLaborRate(params: ILaborRateAdd) {
    return client.post(LABOR_RATE_URL, params);
  }

  deleteLaborRate(id: string) {
    return client.delete(`${LABOR_RATE_URL}${id}/`);
  }

  updateLaborRate(id: string, data: ILaborRateUpdate) {
    return client.patch(`${LABOR_RATE_URL}${id}/`, data);
  }
}

export default LaborRate;
