import React from "react";
import styles from "./VendorAdd.module.scss";
import { notifySuccess } from "Components/Notify/notify";
import { useAppDispatch } from "Redux/store";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { selectAccount } from "Redux/Auth/slice";
import { IVendorAdd } from "Shared/Types/vendor";
import { addVendor } from "Redux/Vendor/reducer";
import VendorForm from "Components/Vendor/VendorForm/VendorForm";
import { vendorSchema } from "Shared/Utils/vendor";
import StepProgress from "Components/UI/StepProgress/StepProgress";
import { checkFirstAuth } from "Shared/Utils/app";

const step = <>Input vendor details, such as name, contact, and address.</>;

export const VendorAdd: React.FC = () => {
  const dispatch = useAppDispatch();
  const profile = useSelector(selectAccount);
  const navigate = useNavigate();
  const isFirstAuth = checkFirstAuth(profile);

  const onSkip = () => {
    navigate("/settings/part-settings/categories/new");
  };

  const onSubmit = async (values: IVendorAdd) => {
    const onSuccess = () => {
      notifySuccess("Created Vendor!");
      if (isFirstAuth) {
        onSkip();
      } else {
        navigate("/vendors");
      }
    };
    await dispatch(addVendor({ data: values, onSuccess }));
  };

  return (
    <div className={styles.vendorAdd}>
      {isFirstAuth && (
        <StepProgress description={step} active={6} title="Adding Vendor" />
      )}
      <div className={styles.vendorAdd__form}>
        <VendorForm
          onSubmit={onSubmit}
          schema={vendorSchema}
          onSkip={onSkip}
          isFirstAuth={isFirstAuth}
        />
      </div>
    </div>
  );
};
