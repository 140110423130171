import React, { useCallback, useEffect, useRef, useState } from "react";
import Webcam from "react-webcam";
import styles from "./CameraAndVideo.module.scss";
import Icon from "Components/UI/Icon/Icon";
import {
  CheckCircleIcon,
  RecordIcon,
  RepeatIcon,
  StopRecordIcon,
} from "Assets/index";

interface ConstraintsProps {
  width: number;
  height: number;
  facingMode: string | { exact: string };
}

type Constraints = {
  constraints: ConstraintsProps;
  setSelectedImages: (file: File[]) => void;
  selectedImages: File[];
  closeModal: () => void;
};
const Video: React.FC<Constraints> = ({
  constraints,
  setSelectedImages,
  selectedImages,
  closeModal,
}) => {
  const webcamRef = useRef<Webcam | null>(null);
  const mediaRecorderRef = useRef<MediaRecorder | null>(null);
  const [capturing, setCapturing] = useState<boolean>(false);
  const [recordedChunks, setRecordedChunks] = useState<Blob[]>([]);

  const videoRef = useRef<HTMLVideoElement | null>(null);

  const handleDataAvailable = useCallback(
    ({ data }: { data: Blob }) => {
      if (data.size > 0) {
        setRecordedChunks((prev) => prev.concat(data));
      }
    },
    [setRecordedChunks],
  );

  const handleStartCaptureClick = useCallback(() => {
    setCapturing(true);
    if (webcamRef.current) {
      const stream = webcamRef.current.stream;
      if (stream) {
        mediaRecorderRef.current = new MediaRecorder(stream, {
          mimeType: "video/webm",
        });
        mediaRecorderRef.current.addEventListener(
          "dataavailable",
          handleDataAvailable,
        );
        mediaRecorderRef.current.start();
      }
    }
  }, [webcamRef, setCapturing, mediaRecorderRef, handleDataAvailable]);

  const handleStopCaptureClick = useCallback(() => {
    if (mediaRecorderRef.current) {
      mediaRecorderRef.current.stop();
      setCapturing(false);
    }
  }, [mediaRecorderRef, setCapturing]);

  const confirmImage = () => {
    if (recordedChunks) {
      const fileType = recordedChunks[0].type;
      const parts = [recordedChunks[0]];
      const file = new File(parts, "captured_video.webm", { type: fileType });
      setSelectedImages([...selectedImages, file]);
    }
    closeModal();
  };

  useEffect(() => {
    if (videoRef.current) {
      const blob = new Blob(recordedChunks, {
        type: "video/webm",
      });
      const url = URL.createObjectURL(blob);
      videoRef.current.src = url;
    }
  }, [recordedChunks]);

  return (
    <>
      {recordedChunks.length ? (
        <div>
          <video
            style={{
              display: "flex",
              margin: "auto",
              height: "80vh",
            }}
            ref={videoRef}
            controls
          ></video>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              fontSize: "80px",
            }}
          >
            <Icon
              style={{
                width: "fit-content",
                fontSize: "80px",
                color: "transparent",
              }}
              className={styles.capture}
              onClick={() => setRecordedChunks([])}
              component={RepeatIcon}
            />
            <Icon
              style={{
                width: "fit-content",
                fontSize: "80px",
                color: "transparent",
              }}
              className={styles.capture}
              onClick={confirmImage}
              component={CheckCircleIcon}
            />
          </div>
        </div>
      ) : (
        <>
          <Webcam
            style={{ width: "100%", height: "75%" }}
            height={400}
            width={400}
            mirrored={true}
            ref={webcamRef}
            videoConstraints={constraints}
          />
          {capturing ? (
            <Icon
              style={{
                display: "flex",
                justifyContent: "center",
                width: "100%",
                fontSize: "80px",
                marginTop: "10px",
                color: "transparent",
              }}
              className={styles.capture}
              onClick={handleStopCaptureClick}
              component={StopRecordIcon}
            />
          ) : (
            <Icon
              style={{
                display: "flex",
                justifyContent: "center",
                width: "100%",
                fontSize: "80px",
                color: "transparent",
              }}
              className={styles.capture}
              onClick={handleStartCaptureClick}
              component={RecordIcon}
            />
          )}
        </>
      )}
    </>
  );
};

export default Video;
