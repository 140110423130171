import { ICardData } from "Shared/Types/common";
import { ICustomer } from "Shared/Types/customer";
import { IContact } from "Shared/Types/contact";

import * as yup from "yup";

const contactSchema = yup.object().shape({
  first_name: yup
    .string()
    .required("First name is required")
    .min(2, "First name must be at least 2 characters long"),
  last_name: yup
    .string()
    .required("Last name is required")
    .min(2, "Last name must be at least 2 characters long"),
  email: yup
    .string()
    .email("Must be a valid email")
    .required("Email is required"),
  phone: yup
    .string()
    .required("Phone number is required")
    .matches(/^[0-9]+$/, "Phone number must be numeric"),
  customer: yup.mixed<ICustomer>().required("Customer ID is required"),
  cell_phone: yup.string(),
});

export default contactSchema;

export const getContactCardData = (contact: IContact) => {
  const data: ICardData[] = [
    {
      title: "Customer",
      value: contact.customer.company_name,
    },
    {
      title: "Full Name",
      value: `${contact.first_name} ${contact.last_name}`,
      isWrap: true,
    },
    {
      title: "Phone Number",
      value: contact.cell_phone,
      isWrap: true,
    },
    {
      title: "Email Address",
      value: contact.email,
      isWrap: true,
    },
  ];
  return data;
};

export const getInFoContact = (contact?: IContact | null) => {
  const basicInfoContact = [
    {
      label: "Customer",
      value: contact?.customer.company_name,
      xs: 12,
    },
    {
      label: "Full Name",
      value: `${contact?.first_name} ${contact?.last_name}`,
      xs: 12,
    },
    {
      label: "Phone Number",
      value: contact?.cell_phone,
      xs: 12,
    },
    {
      label: "Email Address",
      value: contact?.email,
      xs: 12,
    },
  ];

  return basicInfoContact;
};
