import React, { useState } from "react";
import styles from "./ComplaintPartForm.module.scss";
import { Grid } from "@mui/material";
import {
  FieldValues,
  useFieldArray,
  UseFieldArrayAppend,
  UseFieldArrayRemove,
  useFormContext,
} from "react-hook-form";
import { useAppDispatch } from "Redux/store";
import ComplaintPartFields from "Components/Order/Estimate/EstimateForm/ComplaintPartForm/ComplaintPartFields/ComplaintPartFields";
import { notifyError, notifySuccess } from "Components/Notify/notify";
import {
  IEstimateOrderAdd,
  IEstimatesPart,
  IEstimatesPartAdd,
} from "Shared/Types/estimate";
import {
  createEstimatePart,
  deleteEstimatePart,
  updateEstimatePart,
} from "Redux/Estimate/reducer";

type ComplaintPartFormProps = {
  complaintIndex: number;
  partFields: Record<"id", string>[];
  append: UseFieldArrayAppend<FieldValues, `action_items.${number}.part`>;
  remove: UseFieldArrayRemove;
};

const ComplaintPartForm: React.FC<ComplaintPartFormProps> = ({
  complaintIndex,
  append,
  remove,
  partFields,
}) => {
  const [priceTotal, setPriceTotal] = useState(0);
  const dispatch = useAppDispatch();

  const { getValues, setValue } = useFormContext();

  const getPartList = (): IEstimatesPart[] =>
    getValues(`action_items.${complaintIndex}.part`);

  const calculateTotalPrice = (partList: IEstimatesPart[]) => {
    const total = partList.reduce(
      (acc, value) =>
        (acc += (Number(value?.selling_price) || 0) * (value?.quantity || 0)),
      0,
    );
    setPriceTotal(total);
    return total;
  };

  const addNewPart = async (
    partValues: IEstimatesPartAdd,
    partIndex: number,
  ) => {
    try {
      const {
        payload: { id },
      } = await dispatch(
        createEstimatePart({
          data: partValues,
          onSuccess: () => notifySuccess("Part created"),
        }),
      );
      setValue(`action_items.${complaintIndex}.part.${partIndex}.part_id`, id);
      setValue(`action_items.${complaintIndex}.part.${partIndex}.is_add`, true);
      setValue(
        `action_items.${complaintIndex}.service.partPriceTotal`,
        calculateTotalPrice(getPartList()),
      );
      append({});
    } catch (err) {
      if (typeof err == "string") notifyError(err);
    }
  };

  const deletePart = async (idx: number, partId: string) => {
    try {
      if (partFields.length > 1) {
        await dispatch(deleteEstimatePart({ id: partId }));
        remove(idx);
        setValue(
          `action_items.${complaintIndex}.service.partPriceTotal`,
          calculateTotalPrice(getPartList()),
        );
      }
    } catch (err) {
      if (typeof err == "string") notifyError(err);
    }
  };

  const updatePart = async (id: string, data: IEstimatesPart) => {
    try {
      await dispatch(
        updateEstimatePart({
          id,
          data,
        }),
      );
      setValue(
        `action_items.${complaintIndex}.service.partPriceTotal`,
        calculateTotalPrice(getPartList()),
      );
    } catch (err) {
      if (typeof err == "string") notifyError(err);
    }
  };

  return (
    <Grid container>
      {partFields.map((part: IEstimatesPart, partIndex) => (
        <ComplaintPartFields
          key={part.id}
          complaintIndex={complaintIndex}
          partIndex={partIndex}
          addNewPart={addNewPart}
          deletePart={deletePart}
          updatePart={updatePart}
          isAddedPart={getValues(
            `action_items.${complaintIndex}.part.${partIndex}.is_add`,
          )}
        />
      ))}
      <Grid item xs={12} className={styles.form__total}>
        <h4>Total:</h4>
        <span>$ {priceTotal.toFixed(3)}</span>
      </Grid>
    </Grid>
  );
};

export default ComplaintPartForm;
