import { Badge as MuiBadge } from "@mui/material";
import React, { ReactNode } from "react";
import styles from "./Badge.module.scss";
import cn from "classnames";
type BadgeProps = {
  children: ReactNode;
  messageLength: number;
  color?:
    | "primary"
    | "error"
    | "default"
    | "secondary"
    | "info"
    | "success"
    | "warning";
};

const Badge: React.FC<BadgeProps> = ({
  messageLength,
  children,
  color = "primary",
}) => {
  return (
    <div className={styles.badge}>
      <MuiBadge color={color} badgeContent={messageLength}>
        {children}
      </MuiBadge>
    </div>
  );
};

export default Badge;
