import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "Service/api/api";
import {
  IDispatchCallback,
  IDispatchCallbackWithId,
  IDispatchCallbackWithoutData,
} from "Shared/Types/common";
import {
  ICompanyRequestAdd,
  ICompanyRequestReject,
  ICompanyRequestUpdate,
  ICompanySearchParams,
} from "Shared/Types/company";
import { errorHandler } from "Shared/Utils/app";

const getCompanyRequestsList = createAsyncThunk(
  "companyRequests/getCompanyRequestsList",
  async (params: ICompanySearchParams | null) => {
    try {
      const response = await api.companyRequests.getCompanyRequestsList(params);
      return response.data;
    } catch (error) {
      if (error instanceof Error) {
        errorHandler(error, true);
      }
      throw error;
    }
  },
);

const getCompanyRequestsApprovedList = createAsyncThunk(
  "companyRequests/getCompanyRequestsApprovedList",
  async (params: ICompanySearchParams | null) => {
    try {
      const response = await api.companyRequests.getCompanyRequestsList({
        ...params,
        status: "active",
      });
      return response.data;
    } catch (error) {
      if (error instanceof Error) {
        errorHandler(error, true);
      }
      throw error;
    }
  },
);

const getCompanyRequestsRejectedList = createAsyncThunk(
  "companyRequests/getCompanyRequestsRejectedList",
  async (params: ICompanySearchParams | null) => {
    try {
      const response = await api.companyRequests.getCompanyRequestsList({
        ...params,
        status: "inactive",
      });
      return response.data;
    } catch (error) {
      if (error instanceof Error) {
        errorHandler(error, true);
      }
      throw error;
    }
  },
);

const getCompanyRequestsReviewList = createAsyncThunk(
  "companyRequests/getCompanyRequestsReviewList",
  async (params: ICompanySearchParams | null) => {
    try {
      const response = await api.companyRequests.getCompanyRequestsList({
        ...params,
        status: "waiting",
      });
      return response.data;
    } catch (error) {
      if (error instanceof Error) {
        errorHandler(error, true);
      }
      throw error;
    }
  },
);

const getCompanyRequest = createAsyncThunk(
  "companyRequests/getCompanyRequest",
  async (id: string) => {
    try {
      const response = await api.companyRequests.getCompanyRequest(id);
      return response.data;
    } catch (error) {
      if (error instanceof Error) {
        errorHandler(error, true);
      }
      throw error;
    }
  },
);

const addCompanyRequest = createAsyncThunk(
  "companyRequests/addCompanyRequest",
  async ({ data, onSuccess }: IDispatchCallback<ICompanyRequestAdd>) => {
    try {
      const response = await api.companyRequests.addCompanyRequest(data);
      onSuccess?.();
      return response.data;
    } catch (error) {
      if (error instanceof Error) {
        errorHandler(error, true);
      }
      throw error;
    }
  },
);

const updateCompanyRequest = createAsyncThunk(
  "companyRequests/updateCompanyRequest",
  async ({
    id,
    data,
    onSuccess,
  }: IDispatchCallbackWithId<ICompanyRequestUpdate>) => {
    try {
      const response = await api.companyRequests.updateCompanyRequest(id, data);
      onSuccess?.();
      return response.data;
    } catch (error) {
      if (error instanceof Error) {
        errorHandler(error, true);
      }
      throw error;
    }
  },
);

const rejectCompanyRequest = createAsyncThunk(
  "companyRequests/rejectCompanyRequest",
  async ({
    id,
    data,
    onSuccess,
  }: IDispatchCallbackWithId<ICompanyRequestReject>) => {
    try {
      const response = await api.companyRequests.rejectCompanyRequest(id, data);
      onSuccess?.();
      return response.data;
    } catch (error) {
      if (error instanceof Error) {
        errorHandler(error, true);
      }
      throw error;
    }
  },
);

const deleteCompanyRequest = createAsyncThunk(
  "companyRequests/deleteCompanyRequest",
  async ({ id, onSuccess }: IDispatchCallbackWithoutData) => {
    try {
      const response = await api.companyRequests.deleteCompanyRequest(id);
      onSuccess?.();
      return response.data;
    } catch (error) {
      if (error instanceof Error) {
        errorHandler(error);
      }
      throw error;
    }
  },
);

export {
  getCompanyRequestsList,
  getCompanyRequestsApprovedList,
  getCompanyRequestsRejectedList,
  getCompanyRequestsReviewList,
  getCompanyRequest,
  addCompanyRequest,
  updateCompanyRequest,
  rejectCompanyRequest,
  deleteCompanyRequest,
};
