import React from "react";
import styles from "./RenderActionButon.module.scss";
import AcceptButton from "Components/UI/Button/AcceptButton";
import RejectButton from "Components/UI/Button/RejectButton";
import StatusView from "Components/StatusView/StatusView";
import { ICompanyAdd, ICompanyRequest } from "Shared/Types/company";

type RenderActionButtonProps = {
  companyRequest: ICompanyRequest;
  statusTitle: string;
  statusColor: string;
  filteredBy: "waiting" | "active" | "inactive" | "all";
  handleAccept: (request: ICompanyAdd) => void;
  handleReject: (request: ICompanyRequest) => void;
};

export const RenderActionButton: React.FC<RenderActionButtonProps> = ({
  filteredBy,
  handleAccept,
  handleReject,
  companyRequest,
  statusTitle,
  statusColor,
}) => {
  if (filteredBy === "waiting") {
    return (
      <div className={styles.actions}>
        <AcceptButton
          onClick={(event) => {
            event.stopPropagation();
            handleAccept({
              ...companyRequest,
              company_request: companyRequest.id,
              name: companyRequest.company_name,
              status: "active",
            });
          }}
          className={styles.acceptBtn}
        >
          Accept
        </AcceptButton>
        <RejectButton
          onClick={(event) => {
            event.stopPropagation();
            handleReject(companyRequest);
          }}
        >
          Reject
        </RejectButton>
      </div>
    );
  }

  return (
    <StatusView
      title={statusTitle || ""}
      color={statusColor || ""}
      className={styles.card__head__status}
    />
  );
};
