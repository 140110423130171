import { Grid } from "@mui/material";
import OrderCountByStatusCard from "Components/Order/OrderCountByStatusCard/OrderCountByStatusCard";
import React from "react";
import { getPartStatuses } from "Shared/Utils/part";

function PartStatuses() {
  return (
    <Grid
      container
      paddingTop={"10px"}
      rowGap={2}
      columnSpacing={2}
      overflow="auto"
    >
      {getPartStatuses().map((part) => (
        <Grid item md={3.9} key={part.link}>
          <OrderCountByStatusCard {...part} statusDirection={"column"} isPart />
        </Grid>
      ))}
    </Grid>
  );
}

export default PartStatuses;
