import React from "react";
import { getUnitInfo } from "Shared/Utils/invoice";
import { IOrderInvoiceData } from "Shared/Types/invoice";
import { Text, View } from "@react-pdf/renderer";
import { styles } from "./style";

type UnitInfoProps = {
  orderData: IOrderInvoiceData;
};

const UnitInfo: React.FC<UnitInfoProps> = ({ orderData }) => {
  return (
    <View>
      {getUnitInfo(orderData)?.map(({ title, value, title2, value2 }) => (
        <View key={title} style={styles.unitItem}>
          <Text style={styles.unitItemTitle}>{title}</Text> <Text>{value}</Text>
          {(title2 || value2) && (
            <>
              <Text style={styles.unitItemTitle}>{title2}</Text>{" "}
              <Text>{value2}</Text>
            </>
          )}
        </View>
      ))}
    </View>
  );
};

export default UnitInfo;
