import React from "react";
import cn from "classnames";
import styles from "./ActionItemHeader.module.scss";
import { Grid } from "@mui/material";
import { IActionItem } from "Shared/Types/order";
import { getShortValue } from "Shared/Utils/order";
import { useSelector } from "react-redux";
import { selectActionItemStatusList } from "Redux/ActionItem/slice";
import Icon from "Components/UI/Icon/Icon";
import { ArrowDownIcon, ArrowUpIcon } from "Assets/index";
import { getActionItemAmount } from "Shared/Utils/action-item";

type ActionItemHeaderProps = {
  onClick: () => void;
  index: number;
  isActive: boolean;
  actionItem: IActionItem;
};

const ActionItemHeader: React.FC<ActionItemHeaderProps> = ({
  index,
  onClick,
  isActive,
  actionItem,
}) => {
  const statusList = useSelector(selectActionItemStatusList) || [];
  const getActionItemStatusLabel = () => {
    return (
      statusList.find(
        ({ id }) => (actionItem.status as unknown as string) === id
      )?.name || ""
    );
  };
  return (
    <Grid
      item
      xs={6}
      className={cn(styles.card, isActive && styles.card_active)}
      onClick={onClick}
    >
      <span>Action Item {index + 1}</span>
      <span>{getShortValue(" ( " + actionItem.complaint + " )", 25)}</span>

      <span className={styles.card__status}>{getActionItemStatusLabel()}</span>
      <span>
        Total:{" "}
        <span className={styles.card__total}>
          ${getActionItemAmount(actionItem)}
        </span>
      </span>

      <Icon component={isActive ? ArrowDownIcon : ArrowUpIcon} />
    </Grid>
  );
};
export default ActionItemHeader;
