export const shopTableHeads = [
  { id: "status", label: "Active" },
  { id: "shop_name", label: "Shop name" },
  { id: "shop_id", label: "Code" },
  { id: "owner", label: "Employee" },
  { id: "phone", label: "Phone number" },
  { id: "email", label: "Email" },
  { id: "physical_address", label: "Physical Address" },
  { id: "tax_rate", label: "Tax Rate" },
  { id: "action", label: "Action" },
];

export const shopFields = [
  "Title",
  "Tax Rate",
  "Code",
  "Employee",
  "Phone Number",
  "Email Address",
  "Physical Address",
];
