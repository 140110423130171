import ContactForm from "Components/Contacts/Form/ContactForm";
import { notifySuccess } from "Components/Notify/notify";
import { getContact, updateContact } from "Redux/Contact/reducer";
import { selectCurrentContact } from "Redux/Contact/slice";
import { useAppDispatch } from "Redux/store";
import { IContactUpdate } from "Shared/Types/contact";
import contactSchema from "Shared/Utils/contact";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router";

const ContactUpdate: React.FC = () => {
  const dispatch = useAppDispatch();
  const contact = useSelector(selectCurrentContact);
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  const onSubmit = (values: IContactUpdate) => {
    const onSuccess = () => {
      notifySuccess("Created Customer!");
      if (location.state) {
        navigate(-1);
      } else {
        navigate("/customers?tab=Contacts");
      }
    };
    if (contact) {
      dispatch(updateContact({ id: contact.id, data: values, onSuccess }));
    }
  };

  useEffect(() => {
    if (params.id) {
      dispatch(getContact(params.id));
    }
  }, [params]);
  return (
    <div>
      <ContactForm
        schema={contactSchema}
        onSubmit={onSubmit}
        contact={contact}
      />
    </div>
  );
};
export default ContactUpdate;
