import React from "react";
import { useSelector } from "react-redux";

import Layout from "Components/Layout/Layout";
import { BreadcrumbItem } from "Components/Breadcrumb/Breadcrumb";
import { useBreadcrumb } from "Shared/Hooks/use-breadcrumb";
import InventoryInfoListContainer from "Containers/Part/InventoryInfo/InventoryInfo";
import { selectCurrentPart } from "Redux/Product/slice";

export const PartInventoryDetailPage: React.FC = () => {
  const inventory = useSelector(selectCurrentPart);
  const breads: BreadcrumbItem[] = [
    { title: "Part", link: "/parts" },
    { title: "Inventory", link: "/parts/inventory" },
    { title: inventory?.part_number || "", link: "" },
  ];

  useBreadcrumb({ links: breads, option: { upd: inventory?.id } });
  return (
    <Layout>
      <InventoryInfoListContainer />
    </Layout>
  );
};
