import React from "react";
import Layout from "Components/Layout/Layout";
import Home from "Containers/Home/Home";
import { BreadcrumbItem } from "Components/Breadcrumb/Breadcrumb";
import { useBreadcrumb } from "Shared/Hooks/use-breadcrumb";

export const Home2: React.FC = () => {
  const breads: BreadcrumbItem[] = [
    { title: "Home", link: "/" },
    { title: "SO's", link: "" },
  ];

  useBreadcrumb({
    links: breads,
    createTitle: "Schedule",
  });
  return (
    <Layout>
      <Home />
    </Layout>
  );
};
