import Tabs from "Components/UI/Tabs/Tabs";
import React, { useState } from "react";
import { useSearchParams } from "react-router-dom";
import { CustomerList } from "./CustomerList";
import ContactList from "Containers/Contacts/ContactsList/ContactList";

const CustomerListBlock: React.FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [activeTab, setActiveTab] = useState(
    searchParams.get("tab") == "Contacts" ? 1 : 0
  );

  const changeTab = (tab: number) => {
    const tabStr = tab ? "Contacts" : "Customers";
    searchParams.set("tab", tabStr);
    searchParams.delete('search')
    setSearchParams(searchParams);
    setActiveTab(tab);
  };
  return (
    <div>
      <Tabs
        tabs={["Customers", "Contacts"]}
        activeTab={activeTab}
        onChange={changeTab}
        tabPanels={[
          <CustomerList key="customers" />,
          <ContactList key="contacts" />,
        ]}
      />
    </div>
  );
};
export default CustomerListBlock;
