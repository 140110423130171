import React from "react";
import { BreadcrumbItem } from "Components/Breadcrumb/Breadcrumb";
import Layout from "Components/Layout/Layout";
import { selectCurrentContact } from "Redux/Contact/slice";
import { useBreadcrumb } from "Shared/Hooks/use-breadcrumb";
import { useSelector } from "react-redux";
import ContactDetail from "Containers/Contacts/ContactDetail/ContactDetail";
export const ContactDetailPage: React.FC = () => {
  const contact = useSelector(selectCurrentContact);
  const breads: BreadcrumbItem[] = [
    { title: "Contacts", link: "/customers?tab=Contacts" },
    {
      title: contact?.customer.company_name || "",
      link: "",
    },
  ];

  useBreadcrumb({
    links: breads,
    option: { upd: contact?.customer.company_name },
  });
  return (
    <Layout>
      <ContactDetail />
    </Layout>
  );
};
