import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";
import {
  addActionItemNote,
  deleteActionItemNotes,
  getActionItemNoteList,
} from "./reducer";
import { IActionItemNote } from "Shared/Types/notes";

interface ActionItemNoteState {
  actionItemNoteList?: IActionItemNote[];
  error?: string | null;
  loading: boolean;
  success?: string | null;
}

const initialState: ActionItemNoteState = {
  actionItemNoteList: [],
  loading: false,
  success: null,
};

const actionItemNoteSlice = createSlice({
  name: "actionItemNote",
  initialState,
  reducers: {
    clear(state) {
      state.loading = false;
      state.error = null;
      state.success = null;
    },
    resetNoteList(state) {
      state.actionItemNoteList = [];
    },
  },
  extraReducers(builder) {
    builder.addCase(getActionItemNoteList.fulfilled, (state, action) => {
      state.loading = false;
      state.error = null;
      state.actionItemNoteList = action.payload;
    });

    builder.addCase(addActionItemNote.pending, (state) => {
      state.loading = true;
      state.error = null;
      state.success = null;
    });
    builder.addCase(addActionItemNote.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
      state.success = null;
    });
    builder.addCase(addActionItemNote.fulfilled, (state) => {
      state.loading = false;
      state.error = null;
      state.success = "Added Note";
    });

    builder.addCase(deleteActionItemNotes.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(deleteActionItemNotes.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
    builder.addCase(deleteActionItemNotes.fulfilled, (state) => {
      state.loading = false;
      state.error = null;
      state.success = "Note is deleted";
    });
  },
});

export const { clear, resetNoteList } = actionItemNoteSlice.actions;

export const selectActionItemNoteLoading = (state: RootState) =>
  state.actionItemNote.loading;
export const selectActionItemNoteError = (state: RootState) =>
  state.actionItemNote.error;
export const selectActionItemNoteSuccess = (state: RootState) =>
  state.actionItemNote.success;
export const selectActionItemNoteList = (state: RootState) =>
  state.actionItemNote.actionItemNoteList;


export default actionItemNoteSlice;
