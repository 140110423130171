import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router";
import { useSelector } from "react-redux";
import { useAppDispatch } from "Redux/store";
import { notifySuccess } from "Components/Notify/notify";
import styles from "./Location.module.scss";
import { locationSchema } from "Shared/Utils/part-settings";
import {
  removeCurrentLocation,
  selectCurrentLocation,
} from "Redux/PartSettings/Location/slice";
import { ILocationUpdate } from "Shared/Types/location";
import {
  getLocation,
  getLocationList,
  updateLocation,
} from "Redux/PartSettings/Location/reducer";
import LocationForm from "Components/Settings/PartSettings/Location/LocationForm";

const LocationUpdate = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const location = useSelector(selectCurrentLocation);
  const dispatch = useAppDispatch();

  const back = () => {
    navigate("/settings/part-settings/locations");
  };
  const editLocation = (values: ILocationUpdate) => {
    const onSuccess = () => {
      notifySuccess("Location updated");
      dispatch(getLocationList(null));
      back();
    };
    if (id) {
      dispatch(updateLocation({ id, data: values, onSuccess }));
    }
  };

  useEffect(() => {
    if (id) {
      dispatch(getLocation(id));
    }
    return () => {
      dispatch(removeCurrentLocation());
    };
  }, [id]);

  return (
    <div className={styles.location__form}>
      {location && (
        <LocationForm
          location={location}
          onSubmit={editLocation}
          schema={locationSchema}
        />
      )}
    </div>
  );
};

export default LocationUpdate;
