import React from "react";
import styles from "./Sidebar.module.scss";
import Icon from "Components/UI/Icon/Icon";
import Hr from "./Hr";
import {
  adminData,
  generalData,
  shopSetup,
  managementData,
  additionally,
} from "Shared/Constants/sidebar";
import { SideBarLogoIcon } from "Assets/index";
import cn from "classnames";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";
import { selectAccount } from "Redux/Auth/slice";
import SidebarItem from "./SidebarItem";
import { havePermission } from "Shared/Utils/app";
import { PERMISSIONS } from "Shared/Constants/app";

const Sidebar: React.FC = () => {
  const profile = useSelector(selectAccount);
  const isAdmin = profile?.is_superuser;
  const permissions = profile?.role?.permissions || [];
  const isCompanyAdmin = profile?.is_company_admin;

  const isHavePermission = (pagePermissions: string[]) => {
    if (isAdmin) return false;
    if (isCompanyAdmin) return true;
    return havePermission(permissions, pagePermissions);
  };

  const navigate = useNavigate();

  const handleChangeMenu = (url: string) => {
    navigate(url);
  };

  return (
    <div className={cn(styles.sidebar)}>
      <div className={styles.sidebar__logo}>
        <Icon component={SideBarLogoIcon} alt="Easy Shop" fontSize="large" />
      </div>
      {isAdmin &&
        adminData.map(({ icon, title, url }) => (
          <SidebarItem
            key={title}
            icon={icon}
            title={title}
            onClick={() => handleChangeMenu(url)}
            url={url}
            isHavePermission={isAdmin}
            isAdmin
          />
        ))}

      {isHavePermission([]) && (
        <div className={styles.sidebar__general}>
          {generalData.map(({ icon, title, url, permission }) => (
            <SidebarItem
              key={title}
              icon={icon}
              title={title}
              onClick={() => handleChangeMenu(url)}
              url={url}
              isHavePermission={isHavePermission(permission)}
            />
          ))}
          <Hr />
        </div>
      )}

      {isHavePermission([]) && (
        <div className={styles.sidebar__management}>
          {managementData.map(({ icon, title, url, permission }) => (
            <SidebarItem
              key={title}
              icon={icon}
              title={title}
              onClick={() => handleChangeMenu(url)}
              url={url}
              isHavePermission={isHavePermission(permission)}
            />
          ))}
          <Hr />
        </div>
      )}

      {isHavePermission([
        PERMISSIONS.add_shop,
        PERMISSIONS.add_unit,
        PERMISSIONS.add_vendor,
        PERMISSIONS.add_user,
        PERMISSIONS.add_unit,
      ]) && (
        <div className={styles.sidebar__list}>
          {shopSetup.map(({ icon, title, url, permission, subMenuItem }) => (
            <div className={styles.sidebar__list__sub_items} key={title}>
              <SidebarItem
                key={title}
                icon={icon}
                title={title}
                onClick={() => handleChangeMenu(url)}
                url={url}
                isHavePermission={isHavePermission(permission)}
                subMenu={subMenuItem}
                isHavePermissionFunc={isHavePermission}
              />
            </div>
          ))}
          <Hr />
        </div>
      )}

      {isHavePermission([]) && (
        <div className={styles.sidebar__support}>
          {additionally.map(({ icon, title, url, permission }) => (
            <SidebarItem
              key={title}
              icon={icon}
              title={title}
              onClick={() => handleChangeMenu(url)}
              url={url}
              isHavePermission={isHavePermission(permission)}
            />
          ))}
        </div>
      )}
    </div>
  );
};
export default Sidebar;
