import React, { useRef } from "react";
import ReactECharts from "echarts-for-react";

const GaugeChart: React.FC<{ percentage: number }> = ({ percentage }) => {
  const chartRef = useRef<any>(null);

  const gaugeData = [
    {
      value: percentage,
      title: {
        offsetCenter: ["0%", "-10%"],
      },
      detail: {
        valueAnimation: true,
        offsetCenter: ["0%", "0"],
        borderColor: "transparent",
      },
      marker: {
        show: true,
        symbol: "circle",
        symbolSize: 10,
        color: "red",
      },
    },
  ];

  const option = {
    series: [
      {
        type: "gauge",
        startAngle: 90,
        endAngle: -270,
        pointer: {
          show: false,
        },
        progress: {
          show: true,
          overlap: false,
          roundCap: false,
          clip: false,
          itemStyle: {
            borderWidth: 1,
            color: "#A076E0",
            backgroundColor: "#A076E0",
          },
        },
        axisLine: {
          lineStyle: {
            width: 3,
          },
        },
        splitLine: {
          show: false,
        },
        axisTick: {
          show: false,
        },
        axisLabel: {
          show: false,
        },
        data: gaugeData,
        title: {
          fontSize: 12,
        },
        detail: {
          width: 30,
          height: 10,
          fontSize: 16,
          color: "#A076E0",
          borderColor: "#A076E0",
          borderRadius: 20,
          borderWidth: 1,
          formatter: "{value}%",
        },
      },
    ],
  };

  return (
    <div>
      <ReactECharts
        ref={chartRef}
        option={option}
        style={{ height: "60px", width: "60px" }}
      />
    </div>
  );
};

export default GaugeChart;
