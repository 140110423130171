import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "Service/api/api";
import {
  IDispatchCallback,
  IDispatchCallbackWithoutData,
  IDispatchCallbackWithId,
} from "Shared/Types/common";
import {
  IOrderInvoiceForm,
  IOrderInvoiceUpdateByField,
  ISendEmail,
} from "Shared/Types/invoice";
import { errorHandler } from "Shared/Utils/app";

const getOrderInvoice = createAsyncThunk(
  "orderInvoice/getOrderInvoice",
  async (id: string) => {
    try {
      const response = await api.orderInvoice.getOrderInvoice(id);
      return response.data;
    } catch (error) {
      if (error instanceof Error) {
        errorHandler(error);
      }
      throw error;
    }
  }
);

const addOrderInvoice = createAsyncThunk(
  "orderInvoice/addOrderInvoice",
  async ({ data, onSuccess }: IDispatchCallback<IOrderInvoiceForm>) => {
    try {
      const formData = new FormData();
      for (const [key, value] of Object.entries(data)) {
        formData.append(key, String(value));
      }
      const response = await api.orderInvoice.addOrderInvoice(data);
      onSuccess?.(response.data.id);
      return response.data;
    } catch (error) {
      if (error instanceof Error) {
        errorHandler(error, true);
      }
      throw error;
    }
  }
);

const updateOrderInvoice = createAsyncThunk(
  "orderInvoice/updateOrderInvoice",
  async ({
    id,
    data: { field, value },
    onSuccess,
  }: IDispatchCallbackWithId<IOrderInvoiceUpdateByField>) => {
    try {
      const response = await api.orderInvoice.updateOrderInvoice(id, {
        [field]: value,
      });
      onSuccess?.();
      return response.data;
    } catch (error) {
      if (error instanceof Error) {
        errorHandler(error, true);
      }
      throw error;
    }
  }
);

const deleteOrderInvoice = createAsyncThunk(
  "orderInvoice/deleteOrderInvoice",
  async ({ id, onSuccess }: IDispatchCallbackWithoutData) => {
    try {
      const response = await api.orderInvoice.deleteOrderInvoice(id);
      onSuccess?.();
      return response.data;
    } catch (error) {
      if (error instanceof Error) {
        errorHandler(error, true);
      }
      throw error;
    }
  }
);

const payOrderInvoice = createAsyncThunk(
  "orderInvoice/payOrderInvoice",
  async ({ id, onSuccess }: IDispatchCallbackWithoutData) => {
    try {
      const response = await api.orderInvoice.payInvoice(id);
      onSuccess?.();
      return response.data;
    } catch (error) {
      if (error instanceof Error) {
        errorHandler(error, true);
      }
      throw error;
    }
  }
);

const sendEmail = createAsyncThunk(
  "orderInvoice/sendEmail",
  async ({ id, onSuccess, data }: IDispatchCallbackWithId<ISendEmail>) => {
    try {
      const response = await api.orderInvoice.sendEmail(id, data);
      onSuccess?.();
      return response.data;
    } catch (error) {
      if (error instanceof Error) {
        errorHandler(error, true);
      }
      throw error;
    }
  }
);

export {
  getOrderInvoice,
  addOrderInvoice,
  updateOrderInvoice,
  deleteOrderInvoice,
  payOrderInvoice,
  sendEmail,
};
