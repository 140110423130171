import { client } from "Service/axios";
import {
  IRole,
  IRoleSearchParams,
  IRolesAdd,
  IRolesUpdate,
} from "Shared/Types/role";

const ROLE_URL = "role/";

class Role {
  getRolesList(params: IRoleSearchParams | null) {
    return client.get<IRole[]>(ROLE_URL, { params });
  }
  getRole(id: string) {
    return client.get<IRole>(`${ROLE_URL}${id}/`);
  }
  addRole(data: IRolesAdd) {
    return client.post(ROLE_URL, data);
  }
  updateRole(id: string, data: IRolesUpdate) {
    return client.put(`${ROLE_URL}${id}/`, data);
  }
  deleteRole(id: string) {
    return client.delete(`${ROLE_URL}${id}/`);
  }
  getPermissions() {
    return client.get(`${ROLE_URL}permissions/`);
  }
}

export default Role;
