import Layout from "Components/Layout/Layout";
import SettingsLayout from "Components/Settings/Layout/Layout";
import LaborRateAdd from "Containers/Settings/Rate/LaborRate/LaborRateAdd/LaborRateAdd";
import React from "react";
import { BreadcrumbItem } from "Components/Breadcrumb/Breadcrumb";
import { useBreadcrumb } from "Shared/Hooks/use-breadcrumb";

export const LaborRateAddPages: React.FC = () => {
  const breads: BreadcrumbItem[] = [
    { title: "Settings", link: "/settings/rate-settings/labor-rate" },
    { title: "Labor Rate", link: "/settings/rate-settings/labor-rate" },
    { title: "Create New Labor Rate", link: "" },
  ];
  useBreadcrumb({ links: breads });

  return (
    <Layout>
      <SettingsLayout>
        <LaborRateAdd />
      </SettingsLayout>
    </Layout>
  );
};
