import React, { useCallback, useRef, useState } from "react";
import Webcam from "react-webcam";
import styles from "./CameraAndVideo.module.scss";
import Icon from "Components/UI/Icon/Icon";
import { CheckCircleIcon, RecordIcon, RepeatIcon } from "Assets/index";
import { urlToFile } from "Shared/Utils/urlToFile";

interface ConstraintsProps {
  width: number;
  height: number;
  facingMode: string | { exact: string };
}

type Constraints = {
  constraints: ConstraintsProps;
  setSelectedImages: (file: File[]) => void;
  selectedImages: File[];
  closeModal: () => void;
};

const Camera: React.FC<Constraints> = ({
  constraints,
  setSelectedImages,
  selectedImages,
  closeModal,
}) => {
  const [img, setImg] = useState<string | null>(null); // Define the type for 'img'
  const webcamRef = useRef<Webcam | null>(null);

  const capture = useCallback(() => {
    if (webcamRef.current) {
      const imageSrc = webcamRef.current.getScreenshot();
      setImg(imageSrc);
    }
  }, [webcamRef]);

  const confirmImage = () => {
    if (img) {
      urlToFile(img, "img.png").then((img) => {
        img && setSelectedImages([...selectedImages, img]);
      });
    }
    closeModal();
  };

  return (
    <>
      {img === null ? (
        <>
          <Webcam
            style={{ width: "100%", height: "75%" }}
            audio={false}
            mirrored={true}
            height={400}
            width={400}
            ref={webcamRef}
            screenshotFormat="image/jpeg"
            videoConstraints={constraints}
          />
          <Icon
            style={{
              display: "flex",
              justifyContent: "center",
              width: "100%",
              fontSize: "80px",
              marginTop: "10px",
              color: "transparent",
            }}
            color="transparent"
            onClick={capture}
            component={RecordIcon}
          />
        </>
      ) : (
        <>
          <img
            style={{
              width: "100%",
              height: "75%",
              objectFit: "contain",
            }}
            src={img}
            alt="screenshot"
          />
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              fontSize: "80px",
            }}
          >
            <Icon
              style={{
                width: "fit-content",
                fontSize: "80px",
                color: "transparent",
              }}
              className={styles.capture}
              onClick={() => setImg(null)}
              component={RepeatIcon}
            />
            <Icon
              style={{
                width: "fit-content",
                fontSize: "80px",
                color: "transparent",
              }}
              className={styles.capture}
              onClick={confirmImage}
              component={CheckCircleIcon}
            />
          </div>
        </>
      )}
    </>
  );
};

export default Camera;
