import { Grid } from "@mui/material";
import Button from "Components/UI/Button/Button";
import InfoField from "Components/UI/InfoField/InfoField";
import InputField from "Components/UI/Inputs/InputField";
import Modal from "Components/UI/Modal/Modal";
import React, { useState } from "react";
import { IActionPart } from "Shared/Types/action-item-part";

type ReturnPartModalProps = {
  open: boolean;
  setOpen: (open: boolean) => void;
  selectedPart: IActionPart;
};

const ReturnPartModal: React.FC<ReturnPartModalProps> = ({
  open,
  setOpen,
  selectedPart,
}) => {
  const [count, setCount] = useState(0);
  const getModalTitle = () => {
    return selectedPart.part_number + " - " + selectedPart.description;
  };
  const returnPart = () => {
    count;
    setOpen(false);
  };
  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      title={getModalTitle()}
      isClosed
      onCancel={() => setOpen(false)}
    >
      <Grid container>
        <Grid item xs={4}>
          <InfoField label="Shop part #" value={selectedPart.part_number} />
        </Grid>
        <Grid item xs={4}>
          <InfoField label="Part" value={selectedPart.description} />
        </Grid>
        <Grid item xs={4}>
          <InputField
            label="Part Number"
            name="quantity"
            value={selectedPart.part_number}
            onChange={(event) => setCount(+event.target.value)}
          />
        </Grid>
        <Grid container xs={12} item justifyContent="flex-end" mt={2} gap={1}>
          <Button color="secondary" onClick={() => setOpen(false)}>
            Cancel
          </Button>
          <Button onClick={returnPart}>Not Needed</Button>
        </Grid>
      </Grid>
    </Modal>
  );
};
export default ReturnPartModal;
