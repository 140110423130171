import React from "react";
import styles from "Containers/Settings/Rate/LaborRate/LaborRateAdd/LaborRateAdd.module.scss";
import { useNavigate } from "react-router";
import { useAppDispatch } from "Redux/store";
import { useSelector } from "react-redux";
import { selectAccount } from "Redux/Auth/slice";
import SupplyForm from "Components/Settings/SupplyRate/Form/SupplyForm";
import { notifySuccess } from "Components/Notify/notify";
import { ISupplyRateAdd } from "Shared/Types/supplyRate";
import { addSupplyRate } from "Redux/SupplyRate/reducer";
import { supplyRateScheme } from "Shared/Utils/supplyRate";
import StepProgress from "Components/UI/StepProgress/StepProgress";
import { settingLabels } from "Shared/Constants/app";
import { checkFirstAuth } from "Shared/Utils/app";
import { stepDescription } from "Containers/Home/Home";

const SupplyRateAdd = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const profile = useSelector(selectAccount);
  const isFirstAuth = checkFirstAuth(profile);

  const back = () => {
    navigate("/settings/rate-settings/supply-rate");
  };

  const createSupplyRate = async (values: ISupplyRateAdd) => {
    const onSuccess = () => {
      if (isFirstAuth) {
        navigate("/settings/roles/new");
      } else {
        back();
      }

      notifySuccess("Supply rate created");
    };

    await dispatch(addSupplyRate({ data: values, onSuccess }));
  };

  return (
    <div className={styles.labor}>
      {isFirstAuth && (
        <StepProgress
          description={stepDescription}
          labels={settingLabels}
          title="Settings"
          active={0}
        />
      )}
      <SupplyForm onSubmit={createSupplyRate} scheme={supplyRateScheme} />
    </div>
  );
};

export default SupplyRateAdd;
