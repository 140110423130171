import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { IContact } from "Shared/Types/contact";
import {
  createContact,
  deleteContact,
  getContact,
  getContactList,
  updateContact,
} from "./reducer";

interface ContactState {
  contactList?: IContact[];
  currentContact?: IContact | null;
  loading: boolean;
  numPage: number;
}

const initialState: ContactState = {
  currentContact: null,
  loading: false,
  numPage: 1,
};

const contactSlice = createSlice({
  name: "contact",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(getContact.pending, (state) => {
      state.loading = true;
      state.currentContact = null;
    }),
      builder.addCase(getContact.rejected, (state) => {
        state.loading = false;
        state.currentContact = null;
      }),
      builder.addCase(getContact.fulfilled, (state, action) => {
        state.currentContact = action.payload;
        state.loading = false;
      });
    builder.addCase(createContact.pending, (state) => {
      state.loading = true;
    }),
      builder.addCase(createContact.rejected, (state) => {
        state.loading = false;
      }),
      builder.addCase(createContact.fulfilled, (state) => {
        state.loading = false;
      });
    builder.addCase(updateContact.pending, (state) => {
      state.loading = true;
    }),
      builder.addCase(updateContact.rejected, (state) => {
        state.loading = false;
      }),
      builder.addCase(updateContact.fulfilled, (state) => {
        state.loading = false;
      }),
      builder.addCase(deleteContact.pending, (state) => {
        state.loading = true;
      }),
      builder.addCase(deleteContact.rejected, (state) => {
        state.loading = false;
      }),
      builder.addCase(deleteContact.fulfilled, (state) => {
        state.loading = false;
      }),
      builder.addCase(getContactList.pending, (state) => {
        state.loading = true;
        state.contactList = [];
      }),
      builder.addCase(getContactList.rejected, (state) => {
        state.loading = false;
        state.contactList = [];
      }),
      builder.addCase(getContactList.fulfilled, (state, action) => {
        state.loading = false;
        state.contactList = action.payload.results;
        state.numPage = action.payload.num_pages;
      });
  },
});

export const selectContactLoading = (state: RootState) => state.contact.loading;
export const selectContactNumPage = (state: RootState) => state.contact.numPage;
export const selectContactList = (state: RootState) =>
  state.contact.contactList;
export const selectCurrentContact = (state: RootState) =>
  state.contact.currentContact;

export default contactSlice;
