import React from "react";
import styles from './Tariffs.module.scss';
import TariffCard from "Components/TariffCard/TariffCard";
import Layout from "Components/Layout/Layout";

const tariffs = [
  {
    title: 'Free trial',
    price: 0,
    services: ['Powerful Metrics', 'Custom Domain', '6 style Google Maps', 'Powerful Style Metrics', 'Choose your month plan'],
  }, {
    title: 'Standard',
    price: 100,
    services: ['Powerful Metrics', 'Custom Domain', '6 style Google Maps', 'Powerful Style Metrics', 'Choose your month plan'],
  }, {
    title: 'Pro',
    price: 300,
    services: ['Powerful Metrics', 'Custom Domain', '6 style Google Maps', 'Powerful Style Metrics', 'Choose your month plan'],
  }, {
    title: 'Premium',
    price: 1000,
    services: ['Powerful Metrics', 'Custom Domain', '6 style Google Maps', 'Powerful Style Metrics', 'Choose your month plan'],
  }
];

export const TariffsContainer = () => {
  return (
    <Layout>
      <div className={styles.tariffs}>
        <h2 className={styles.tariffs__title}>
          Tariffs
        </h2>
        <div className={styles.tariffs__inner}>
          {tariffs.map(item => (
            <TariffCard
              key={item.title}
              title={item.title}
              price={item.price}
              services={item.services}
            />
          ))}
        </div>
      </div>

    </Layout>
  )
};