import Layout from "Components/Layout/Layout";
import React from "react";
import { useBreadcrumb } from "Shared/Hooks/use-breadcrumb";
import PartFilter from "Components/Part/PartFilter/PartFilter";
import { partInventoryBreads } from "Shared/Constants/part";
import InventoryPartList from "Containers/Part/InventoryPartList/InventoryPartList";

export const PartInventoryPage: React.FC = () => {
  useBreadcrumb({
    links: partInventoryBreads,
    createTitle: "Part",
    isNeedCreateBtn: true,
  });

  return (
    <Layout>
      <div>
        <PartFilter />
        <InventoryPartList />
      </div>
    </Layout>
  );
};
