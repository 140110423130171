import { IPayment } from "Shared/Types/payment";

export const getCompanyCardData = (payment: IPayment) => {
  const paymentFields = [
    { title: "Tariff", value: payment.tariff },
    { title: "Number", value: payment.number },
    { title: "Next Payment", value: payment.id },
    { title: "Price", value: payment.price },
    { title: "Payment method", value: payment.company?.payment_method },
    { title: "Business name", value: payment.company?.name },
    { title: "Email", value: payment.company?.email },
    { title: "Contact", value: payment.company?.phone },
  ];

  return paymentFields;
};
