import React from "react";
import { Grid } from "@mui/material";
import InfoField from "Components/UI/InfoField/InfoField";
import { getEstimateInfoFields } from "Shared/Utils/estimate";
import { IEstimatesOrder } from "Shared/Types/estimate";
import styles from "./EstimateInfo.module.scss";

type EstimateInfoProps = {
  estimate?: IEstimatesOrder | null;
};

const EstimateInfo: React.FC<EstimateInfoProps> = ({ estimate }) => {
  return (
    <Grid container spacing={1}>
      {getEstimateInfoFields(estimate)?.map((field, index) => (
        <Grid item xs={6} key={index} className={styles.info__field}>
          <InfoField
            label={field.label}
            value={field.value}
            value2={field.value2}
          />
        </Grid>
      ))}
    </Grid>
  );
};

export default EstimateInfo;
