import React from 'react';
import { ButtonProps, Button as MUIButton } from '@mui/material';
import cn from 'classnames';
import styles from './Button.module.scss';
type IButton = ButtonProps;

const AcceptButton: React.FC<IButton> = ({ className, ...props }) => {

  return <span className={cn(styles.accept, className)}>
    <MUIButton
      variant='contained'
      {...props}
    >
      {props.children}
    </MUIButton>
  </span>
}

export default AcceptButton;
