import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { IVendor } from "Shared/Types/vendor";
import {
  addVendor,
  deleteVendor,
  getVendor,
  getVendorList,
  updateVendor,
} from "./reducer";

interface VendorState {
  vendorList?: IVendor[];
  currentVendor?: IVendor | null;
  loading: boolean;
  numPage: number;
}

const initialState: VendorState = {
  currentVendor: null,
  loading: false,
  numPage: 1,
};

const vendorSlice = createSlice({
  name: "vendor",
  initialState,
  reducers: {
    removeCurrentVendor(state) {
      state.currentVendor = null;
    },
  },
  extraReducers(builder) {
    builder.addCase(getVendor.pending, (state) => {
      state.loading = true;
      state.currentVendor = null;
    }),
      builder.addCase(getVendor.rejected, (state) => {
        state.loading = false;
        state.currentVendor = null;
      }),
      builder.addCase(getVendor.fulfilled, (state, action) => {
        state.currentVendor = action.payload;
        state.loading = false;
      });
    builder.addCase(addVendor.pending, (state) => {
      state.loading = true;
    }),
      builder.addCase(addVendor.rejected, (state) => {
        state.loading = false;
      }),
      builder.addCase(addVendor.fulfilled, (state) => {
        state.loading = false;
      });
    builder.addCase(updateVendor.pending, (state) => {
      state.loading = true;
    }),
      builder.addCase(updateVendor.rejected, (state) => {
        state.loading = false;
      }),
      builder.addCase(updateVendor.fulfilled, (state) => {
        state.loading = false;
      }),
      builder.addCase(deleteVendor.pending, (state) => {
        state.loading = true;
      }),
      builder.addCase(deleteVendor.rejected, (state) => {
        state.loading = false;
      }),
      builder.addCase(deleteVendor.fulfilled, (state) => {
        state.loading = false;
      });

    builder.addCase(getVendorList.pending, (state) => {
      state.loading = true;
      state.vendorList = state.vendorList || [];
    });
    builder.addCase(getVendorList.rejected, (state) => {
      state.loading = false;
      state.vendorList = state.vendorList || [];
    });
    builder.addCase(getVendorList.fulfilled, (state, action) => {
      state.loading = false;
      state.vendorList = action.payload.results;
      state.numPage = action.payload.num_pages;
    });
  },
});

export const { removeCurrentVendor } = vendorSlice.actions;

export const selectVendorLoading = (state: RootState) => state.vendor.loading;
export const selectVendorNumPage = (state: RootState) => state.vendor.numPage;
export const selectVendorList = (state: RootState) => state.vendor.vendorList;
export const selectCurrentVendor = (state: RootState) =>
  state.vendor.currentVendor;

export default vendorSlice;
