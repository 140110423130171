import React from "react";
import Layout from "Components/Layout/Layout";
import { EmployeeList } from "Containers/Employee/EmployeeList/EmployeeList";
import { useBreadcrumb } from "Shared/Hooks/use-breadcrumb";
import { getBreads } from "Shared/Utils/user";

export const EmployeePage: React.FC = () => {
  useBreadcrumb({
    links: getBreads(),
    isNeedCreateBtn: true,
    createTitle: "Employee",
  });
  return (
    <Layout>
      <EmployeeList />
    </Layout>
  );
};
