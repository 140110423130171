import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { IActionPart } from "Shared/Types/action-item-part";
import {
  createActionPart,
  deleteActionPart,
  getActionItemPartStatuses,
  getActionPart,
  getActionPartList,
  updateActionPart,
} from "./reducer";
import { IOrderStatus } from "Shared/Types/order";

interface ActionPartState {
  actionPartList?: IActionPart[];
  currentActionPart?: IActionPart | null;
  loading: boolean;
  statuses: IOrderStatus[];
}

const initialState: ActionPartState = {
  currentActionPart: null,
  loading: false,
  statuses: [],
};

const actionPartSlice = createSlice({
  name: "actionPart",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(getActionPart.pending, (state) => {
      state.loading = true;
      state.currentActionPart = null;
    });
    builder.addCase(getActionPart.rejected, (state) => {
      state.loading = false;
      state.currentActionPart = null;
    });
    builder.addCase(getActionPart.fulfilled, (state, action) => {
      state.currentActionPart = action.payload;
      state.loading = false;
    });
    builder.addCase(createActionPart.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(createActionPart.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(createActionPart.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(updateActionPart.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateActionPart.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(updateActionPart.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(deleteActionPart.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(deleteActionPart.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(deleteActionPart.fulfilled, (state) => {
      state.loading = false;
    });

    builder.addCase(getActionPartList.pending, (state) => {
      state.loading = true;
      state.actionPartList = [];
    });
    builder.addCase(getActionPartList.rejected, (state) => {
      state.loading = false;
      state.actionPartList = [];
    });
    builder.addCase(getActionPartList.fulfilled, (state, action) => {
      state.loading = false;
      state.actionPartList = action.payload.results;
    });
    builder.addCase(getActionItemPartStatuses.fulfilled, (state, action) => {
      state.statuses = action.payload;
    });
  },
});

export const selectIsLoading = (state: RootState) => state.actionPart.loading;
export const selectActionPartList = (state: RootState) =>
  state.actionPart.actionPartList;
export const selectCurrentActionPart = (state: RootState) =>
  state.actionPart.currentActionPart;
export const selectActionPartStatuses = (state: RootState) =>
  state.actionPart.statuses;

export default actionPartSlice;
