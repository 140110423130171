import Layout from "Components/Layout/Layout";
import SettingsLayout from "Components/Settings/Layout/Layout";
import CategoryUpdate from "Containers/Settings/Part/Categories/CategoryUpdate/CategoryUpdate";
import React from "react";
import { BreadcrumbItem } from "Components/Breadcrumb/Breadcrumb";
import { useBreadcrumb } from "Shared/Hooks/use-breadcrumb";
import { useSelector } from "react-redux";
import { selectCurrentCategory } from "Redux/PartSettings/Categories/slice";

export const CategoryUpdatePages: React.FC = () => {
  const category = useSelector(selectCurrentCategory);

  const breads: BreadcrumbItem[] = [
    { title: "Setting", link: "/settings/part-settings/categories" },
    { title: "Categories", link: "/settings/part-settings/categories" },
    { title: `Update ${category?.name || ""}`, link: "" },
  ];
  useBreadcrumb({ links: breads, option: { upd: category?.name } });

  return (
    <Layout>
      <SettingsLayout>
        <CategoryUpdate />
      </SettingsLayout>
    </Layout>
  );
};
