import React from "react";
import cn from "classnames";
import styles from "./TimeField.module.scss";
import { Control, Controller } from "react-hook-form";
import {
  LocalizationProvider,
  TimePicker,
  TimePickerProps,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";

export type TimeFieldProps = Omit<TimePickerProps<any>, "name"> & {
  withController?: boolean;
  // TODO: remove any type
  control?: Control<any>;
  name: string;
  error?: string;
  required?: boolean;
};

const TimeField: React.FC<TimeFieldProps> = ({
  label,
  required,
  withController = false,
  control,
  name,
  className,
  defaultValue = "",
  error = null,
}) => {
  return (
    <div className={styles.inputs}>
      {label && (
        <label data-id={name} className={styles.inputs__label}>
          {label} {required && <span className={styles.inputs__error}>*</span>}
        </label>
      )}
      {withController ? (
        <Controller
          name={name}
          control={control}
          defaultValue={dayjs(defaultValue, "HH:mm")}
          render={({ field }) => {
            return (
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <TimePicker
                  views={["hours", "minutes"]}
                  format="hh:mm"
                  value={dayjs(field.value, "HH:mm")}
                  onChange={(newValue) => {
                    field.onChange(dayjs(newValue).format("HH:mm"));
                  }}
                  className={cn(styles.inputs__time, className)}
                />
              </LocalizationProvider>
            );
          }}
        />
      ) : (
        <>
          <TimePicker
            views={["minutes", "seconds"]}
            format="mm:ss"
            className={cn(styles.inputs__time, className)}
          />
          {error && <div className={styles.inputs__error}>{error}</div>}
        </>
      )}
    </div>
  );
};
export default TimeField;
