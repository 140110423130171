import { yupResolver } from "@hookform/resolvers/yup";
import Button from "Components/UI/Button/Button";
import Form from "Components/UI/Form/Form";
import InputField from "Components/UI/Inputs/InputField";
import React from "react";
import { useForm } from "react-hook-form";
import { ObjectSchema } from "yup";
import styles from "./TaxForm.module.scss";
import { ITaxRate, ITaxRateAdd } from "Shared/Types/taxRate";
import SelectField from "Components/UI/SelectField/SelectField";
import { PercentageIcon } from "Assets/index";

type TaxFormProps = {
  onSubmit: (values: ITaxRateAdd) => void;
  scheme: ObjectSchema<ITaxRateAdd>;
  taxRate?: ITaxRate | undefined;
};

const labor_types = [
  { label: "[no override]", value: "[no override]" },
  { label: "Taxable", value: "Taxable" },
  { label: "Non-Taxable", value: "Non-Taxable" },
];

const TaxForm: React.FC<TaxFormProps> = ({ onSubmit, scheme, taxRate }) => {
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(scheme),
    defaultValues: taxRate,
  });

  return (
    <Form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
      <InputField
        withController
        control={control}
        name="title"
        required
        label="Name"
      />
      <InputField
        icon={PercentageIcon}
        control={control}
        name="rate"
        required
        type="number"
        label="Rate"
        withController
      />

      <SelectField
        withControl
        control={control}
        required
        label="Labor type"
        options={labor_types}
        name="make_labor_always"
      />
      <div className={styles.form__actions}>
        {taxRate ? (
          <Button variant="outlined" type="submit">
            Save
          </Button>
        ) : (
          <Button type="submit">Create</Button>
        )}
      </div>
    </Form>
  );
};
export default TaxForm;
