import React from "react";
import { IActionPart } from "Shared/Types/action-item-part";
import { checkButtonActive } from "Shared/Utils/action-item-part";
import cn from "classnames";
import styles from "./ActionItem.module.scss";

type ActionItemProps = {
  title: string;
  status: number;
  onClick: () => void;
  selectedParts: IActionPart[];
};

const ActionItem: React.FC<ActionItemProps> = ({
  title,
  status,
  onClick,
  selectedParts,
}) => {
  return (
    <div
      key={title}
      onClick={checkButtonActive(selectedParts, status) ? onClick : undefined}
      className={cn(
        styles.btn,
        checkButtonActive(selectedParts, status) && styles.btn_active
      )}
    >
      {title}
    </div>
  );
};
export default ActionItem;
