import {
  ActiveMenuIcon,
  ChartBarIcon,
  EmployeesAddIcon,
  EmployeesIcon,
  HelpsIcon,
  HomeIcon,
  MaintenancesIcon,
  OrdersIcon,
  QuestionIcon,
  RequestsIcon,
  SchedulesIcon,
  ServicesIcon,
  SettingsIcon,
  ShopsIcon,
  TariffsIcon,
  UnitIcon,
  UsersIcon,
} from "Assets/index";
import { PERMISSIONS } from "./app";

export const adminData = [
  {
    icon: RequestsIcon,
    title: "Requests",
    isDropdown: false,
    withNotification: true,
    notificationsCount: 12,
    url: "/requests",
  },
  {
    icon: UsersIcon,
    title: "Company",
    isDropdown: false,
    withNotification: true,
    notificationsCount: 0,
    url: "/company",
  },
  {
    icon: TariffsIcon,
    title: "Tariffs",
    isDropdown: false,
    withNotification: true,
    notificationsCount: 0,
    url: "/tariffs",
  },
  {
    icon: TariffsIcon,
    title: "Role",
    isDropdown: false,
    withNotification: true,
    notificationsCount: 0,
    url: "/roles",
  },
];

export const GENERAL = {
  ORDERS: "ORDERS",
  PARTS: "PARTS",
  OFFICE: "OFFICE",
};

export const generalData = [
  {
    icon: HomeIcon,
    title: "Home",
    isDropdown: false,
    withNotification: true,
    notificationsCount: 0,
    url: "/",
    permission: [],
  },
  {
    icon: ActiveMenuIcon,
    title: "Orders",
    isDropdown: false,
    withNotification: true,
    notificationsCount: 0,
    url: "/orders",
    permission: [PERMISSIONS.add_order],
  },
  {
    icon: OrdersIcon,
    title: "Parts",
    isDropdown: false,
    withNotification: true,
    notificationsCount: 0,
    url: "/parts",
    permission: [PERMISSIONS.add_part],
  },
  {
    icon: ChartBarIcon,
    title: "Office",
    isDropdown: false,
    withNotification: true,
    notificationsCount: 0,
    url: "/offices",
    permission: [],
  },
];

export const managementData = [
  {
    icon: MaintenancesIcon,
    title: "Preventative Maintenance",
    isDropdown: false,
    withNotification: true,
    notificationsCount: 0,
    url: "/pm",
    permission: [],
  },
  {
    icon: SchedulesIcon,
    title: "Reporting",
    isDropdown: false,
    withNotification: false,
    notificationsCount: 0,
    url: "/schedule",
    permission: [],
  },
];
export const shopSetup = [
  {
    icon: ShopsIcon,
    title: "Shop Setting",
    isDropdown: false,
    withNotification: true,
    notificationsCount: 0,
    url: "/shops",
    permission: [
      PERMISSIONS.add_shop,
      PERMISSIONS.add_shopservice,
      PERMISSIONS.add_unit,
      PERMISSIONS.add_user,
      PERMISSIONS.add_vendor,
    ],
    subMenuItem: [
      {
        icon: ShopsIcon,
        title: "Shop Setting",
        isDropdown: false,
        withNotification: true,
        notificationsCount: 0,
        url: "/shops",
        permission: [PERMISSIONS.add_shop],
      },
      {
        icon: ServicesIcon,
        title: "Service",
        isDropdown: false,
        withNotification: true,
        notificationsCount: 0,
        url: "/services",
        permission: [PERMISSIONS.add_shopservice],
      },
      {
        icon: UnitIcon,
        title: "Units",
        isDropdown: false,
        withNotification: true,
        notificationsCount: 0,
        url: "/units",
        permission: [PERMISSIONS.add_unit],
      },
      {
        icon: EmployeesIcon,
        title: "Customers",
        isDropdown: false,
        withNotification: false,
        notificationsCount: 0,
        url: "/customers",
        permission: [PERMISSIONS.add_user],
      },
      {
        icon: EmployeesIcon,
        title: "Vendors",
        isDropdown: false,
        withNotification: false,
        notificationsCount: 0,
        url: "/vendors",
        permission: [PERMISSIONS.add_vendor],
      },
      {
        icon: EmployeesAddIcon,
        title: "Employee",
        isDropdown: false,
        withNotification: false,
        notificationsCount: 0,
        url: "/employees",
        permission: [PERMISSIONS.add_user],
      },
    ],
  },
];

export const additionally = [
  {
    icon: SettingsIcon,
    title: "Setting",
    isDropdown: false,
    withNotification: true,
    notificationsCount: 0,
    url: "/settings/part-settings/categories",
    permission: [
      PERMISSIONS.add_role,
      PERMISSIONS.add_category,
      PERMISSIONS.add_manufacturer,
      PERMISSIONS.add_location,
      PERMISSIONS.add_type,
      PERMISSIONS.add_laborrate,
      PERMISSIONS.add_taxrate,
      PERMISSIONS.add_supplyrate,
    ],
  },
  {
    icon: HelpsIcon,
    title: "Help & Training",
    isDropdown: false,
    withNotification: false,
    notificationsCount: 0,
    url: "/help",
    permission: [],
  },
  {
    icon: QuestionIcon,
    title: "Support",
    isDropdown: false,
    withNotification: false,
    notificationsCount: 0,
    url: "/support",
    permission: [],
  },
];

export const unitSettingsSidebar = [
  {
    title: "Unit type",
    link: "unit-types",
  },
  {
    title: "Subtype",
    link: "subtypes",
  },
];
