import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "Service/api/api";
import {
  IDispatchCallback,
  IDispatchCallbackWithId,
  IDispatchCallbackWithoutData,
} from "Shared/Types/common";
import { IComponentCreate, IComponentUpdate } from "Shared/Types/component";
import { errorHandler } from "Shared/Utils/app";

const getComponentList = createAsyncThunk(
  "component/getComponentList",
  async () => {
    try {
      const response = await api.component.getComponentList();
      return response.data;
    } catch (error) {
      if (error instanceof Error) {
        errorHandler(error);
      }
      throw error;
    }
  }
);

const getComponent = createAsyncThunk(
  "component/getComponent",
  async (id: string) => {
    try {
      const response = await api.component.getComponent(id);
      const systemsResponse = await api.system.getSystemList({ component: id });
      return {
        component: response.data,
        systems: systemsResponse.data.results,
      };
    } catch (error) {
      if (error instanceof Error) {
        errorHandler(error);
      }
      throw error;
    }
  }
);

const createComponent = createAsyncThunk(
  "component/createComponentList",
  async ({ data, onSuccess }: IDispatchCallback<IComponentCreate>) => {
    try {
      const response = await api.component.createComponent(data);
      onSuccess?.();
      return response.data;
    } catch (error) {
      if (error instanceof Error) {
        errorHandler(error, true);
      }
      throw error;
    }
  }
);

const deleteComponent = createAsyncThunk(
  "component/deleteComponent",
  async ({ id, onSuccess }: IDispatchCallbackWithoutData) => {
    try {
      const response = await api.component.deleteComponent(id);
      onSuccess?.();
      return response.data;
    } catch (error) {
      if (error instanceof Error) {
        errorHandler(error, true);
      }
      throw error;
    }
  }
);

const updateComponent = createAsyncThunk(
  "component/updateComponent",
  async ({ data, onSuccess }: IDispatchCallbackWithId<IComponentUpdate>) => {
    try {
      const response = await api.component.updateComponent(data);
      onSuccess?.();
      return response.data;
    } catch (error) {
      if (error instanceof Error) {
        errorHandler(error, true);
      }
      throw error;
    }
  }
);

export {
  getComponentList,
  createComponent,
  deleteComponent,
  updateComponent,
  getComponent,
};
