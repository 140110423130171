import React from "react";
import Layout from "Components/Layout/Layout";
import ShopList from "Containers/Shop/ShopList";
import { BreadcrumbItem } from "Components/Breadcrumb/Breadcrumb";
import { useBreadcrumb } from "Shared/Hooks/use-breadcrumb";

export const ShopListPage: React.FC = () => {
  const breads: BreadcrumbItem[] = [{ title: "Shops", link: "/shops" }];
  useBreadcrumb({ links: breads, createTitle: "Shop", isNeedCreateBtn: true });
  return (
    <Layout>
      <ShopList />
    </Layout>
  );
};
