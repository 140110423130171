import React from "react";
import Layout from "Components/Layout/Layout";
import SettingsLayout from "Components/Settings/Layout/Layout";
import ComponentAdd from "Containers/ComponentSystem/Component/ComponentAdd";
import { BreadcrumbItem } from "Components/Breadcrumb/Breadcrumb";
import { useBreadcrumb } from "Shared/Hooks/use-breadcrumb";

export const ComponentSystemAddPage: React.FC = () => {
  const breads: BreadcrumbItem[] = [
    { title: "Settings", link: "/settings/part-settings/categories" },
    { title: "Component & System", link: "/settings/component-system" },
    { title: "Create New Component", link: "" },
  ];

  useBreadcrumb({ links: breads });
  return (
    <Layout>
      <SettingsLayout>
        <ComponentAdd />
      </SettingsLayout>
    </Layout>
  );
};
