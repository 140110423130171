import Layout from "Components/Layout/Layout";
import React from "react";
import { useBreadcrumb } from "Shared/Hooks/use-breadcrumb";
import CompanyList from "Containers/Company/CompanyList";
import { getCompanyBreadCrumbs } from "Shared/Utils/company";

export const CompanyBlockedPage: React.FC = () => {
  useBreadcrumb({ links: getCompanyBreadCrumbs("blocked") });

  return (
    <Layout>
      <CompanyList status="inactive" />
    </Layout>
  );
};
