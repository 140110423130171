import { ISystem } from "Shared/Types/system";
import { createSlice } from "@reduxjs/toolkit";
import {
  createSystem,
  deleteSystem,
  getSystem,
  getSystemList,
  updateSystem,
} from "Redux/System/reducers";
import { RootState } from "Redux/store";

interface ISystemState {
  loading: boolean;
  systemList?: ISystem[];
  currentSystem?: ISystem | null;
}

const initialState: ISystemState = {
  loading: false,
  systemList: [],
};

const SystemSlice = createSlice({
  name: "system",
  initialState,
  reducers: {
    removeCurrentSystem(state) {
      state.currentSystem = null;
    },
    clear(state) {
      state.loading = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getSystemList.pending, (state) => {
      state.loading = true;
      state.systemList = [];
    });
    builder.addCase(getSystemList.rejected, (state) => {
      state.loading = false;
      state.systemList = [];
    });
    builder.addCase(getSystemList.fulfilled, (state, action) => {
      state.loading = false;
      state.systemList = action.payload.results;
    });

    builder.addCase(createSystem.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(createSystem.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(createSystem.fulfilled, (state) => {
      state.loading = false;
    });

    builder.addCase(deleteSystem.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(deleteSystem.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(deleteSystem.fulfilled, (state) => {
      state.loading = false;
    });

    builder.addCase(updateSystem.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateSystem.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(updateSystem.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(getSystem.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getSystem.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(getSystem.fulfilled, (state, action) => {
      state.loading = false;
      state.currentSystem = action.payload;
    });
  },
});

export const { removeCurrentSystem, clear } = SystemSlice.actions;

export const selectSystemList = (state: RootState) => state.system.systemList;
export const selectSystemLoading = (state: RootState) => state.system.loading;
export const selectCurrentSystem = (state: RootState) =>
  state.system.currentSystem;

export default SystemSlice;
