import React, { useEffect, useState } from "react";
import styles from "./RoleItem.module.scss";
import { IRoleItem } from "Shared/Types/role";
import Checkbox from "Components/UI/Inputs/Checkbox";
import IconButton from "Components/UI/IconButton/IconButton";
import { CloseIcon, EditIcon } from "Assets/index";
import Icon from "Components/UI/Icon/Icon";
import GroupItem from "../GroupItem/GroupItem";

type RoleItemProps = {
  role: IRoleItem;
  changeRoles: (per: string[], isAdd: boolean) => void;
  curPermissions: Set<string>;
};

const RoleItem: React.FC<RoleItemProps> = ({
  role,
  changeRoles,
  curPermissions,
}) => {
  const [open, setOpen] = useState(false);
  const [changed, setChanged] = useState(false);
  const [value, setValue] = useState(
    role.permissions.every((group) =>
      group.list.every((per) => curPermissions.has(per))
    )
  );

  const changeRole = (checked: boolean) => {
    setValue(checked);
    setChanged(!changed);
    role.permissions.forEach((group) => changeRoles(group.list, checked));
  };

  useEffect(() => {
    setValue(
      role.permissions.every((group) =>
        group.list.every((per) => curPermissions.has(per))
      )
    );
  }, [changed]);

  return (
    <div className={styles.role} key={role.title}>
      <div className={styles.role__head} onClick={() => setOpen(true)}>
        <div className={styles.role__head__title}>
          <Checkbox
            name="permission"
            checked={value}
            onClick={(event) => event.stopPropagation()}
            onChange={(_, checked) => changeRole(checked)}
          />
          <p>{role.title}</p>
        </div>
        {open ? (
          <Icon
            component={CloseIcon}
            onClick={(event) => {
              event.stopPropagation();
              setOpen(false);
            }}
          />
        ) : (
          <IconButton icon={EditIcon} />
        )}
      </div>
      {open && (
        <div className={styles.role__permissions}>
          <div>
            {role.permissions.map((group) => (
              <GroupItem
                group={group}
                key={group.perTitle}
                changeRoles={changeRoles}
                curPermissions={curPermissions}
                changed={changed}
                setChanged={setChanged}
              />
            ))}
          </div>
        </div>
      )}
    </div>
  );
};
export default RoleItem;
