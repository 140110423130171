import { Step, StepLabel, Stepper } from "@mui/material";
import React, { ReactNode } from "react";
import styles from "./StepProgress.module.scss";

type StepProgressProps = {
  labels?: string[];
  title: string;
  active: number;
  description?: ReactNode;
};

const StepProgress: React.FC<StepProgressProps> = ({
  title,
  active = 0,
  description,
}) => {
  const steps = [1, 2, 3, 4, 5, 6, 7, 8];

  return (
    <div className={styles.step}>
      <div className={styles.step__title}>{title}</div>
      <Stepper classes={{ root: styles.step__mui }} activeStep={active}>
        {steps.map((step) => (
          <Step key={step}>
            <StepLabel icon={false} />
          </Step>
        ))}
      </Stepper>
      <div className={styles.step__description}>{description}</div>
    </div>
  );
};
export default StepProgress;
