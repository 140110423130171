import { BreadcrumbItem } from "Components/Breadcrumb/Breadcrumb";
import Layout from "Components/Layout/Layout";
import OrderDetailAction from "Containers/Order/AllOrder/OrderDetail/OrderDetailAction/OrderDetailAction";
import { selectCurrentOrder } from "Redux/Order/slice";
import { orderStatuses } from "Shared/Constants/order";
import { useBreadcrumb } from "Shared/Hooks/use-breadcrumb";
import React from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router";

export const DoneDetailAction: React.FC = () => {
  const { link, title } = orderStatuses.done;
  const order = useSelector(selectCurrentOrder);
  const { state } = useLocation();
  const breads: BreadcrumbItem[] = [
    { title: state ? "Home" : "Orders", link: state ? "/" : "/orders" },
    { title: state ? "SO's" : "All orders", link: state ? "/" : "/orders" },
    { title: title, link: `/orders/${link}` },
    { title: String(order?.order_number) || "", link: "" },
  ];
  useBreadcrumb({
    links: breads,
    option: { upd: String(order?.order_number) },
  });
  return (
    <Layout>
      <OrderDetailAction status={link} />
    </Layout>
  );
};
