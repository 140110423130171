import { notifyError, notifySuccess } from "Components/Notify/notify";
import { waitingOnVendor } from "Redux/ActionPart/reducer";
import { getOrderActionItemParts } from "Redux/Order/reducer";
import { selectCurrentOrder } from "Redux/Order/slice";
import {
  addPurchaseOrder,
  receivePurchaseOrder,
} from "Redux/PurchaseOrder/reducer";
import { useAppDispatch } from "Redux/store";
import { IActionPart } from "Shared/Types/action-item-part";
import { convertActionPartToPurchaseOrder } from "Shared/Utils/action-item-part";
import { checkShopAssignedAndGet } from "Shared/Utils/app";
import { useSelector } from "react-redux";
import { useSaveActionItemPart } from "./use-save-action-item-part";
import { IPurchaseOrderRecieved } from "Shared/Types/purchase-order";

type TUsePartListOperation = {
  selectedParts?: IActionPart[];
  onClose: (value: boolean) => void;
  setSelectedParts: (parts: IActionPart[]) => void;
};
export const usePartListOperation = ({
  selectedParts,
  onClose,
  setSelectedParts,
}: TUsePartListOperation) => {
  const dispatch = useAppDispatch();
  const currentOrder = useSelector(selectCurrentOrder);
  const { saveStatusActionItemPart } = useSaveActionItemPart();

  const handleClose = (msg: string, parts?: IActionPart[]) => {
    notifySuccess(msg);
    onClose(false);
    if (parts) {
      setSelectedParts?.(
        selectedParts?.filter((part) => !parts.includes(part)) || []
      );
    } else {
      setSelectedParts([]);
    }
  };

  // Need to quote parts functionality
  const addQuote = (vendorId: string) => {
    if (selectedParts && vendorId) {
      selectedParts.forEach((part, i) => {
        dispatch(
          waitingOnVendor({
            id: part.id,
            data: { vendor: vendorId },
            onSuccess: () => {
              handleClose("Part added to quote");
              if (selectedParts && i === selectedParts?.length - 1) {
                dispatch(getOrderActionItemParts({ order: currentOrder?.id }));
              }
            },
          })
        );
      });
    }
  };

  // Order Action Item Parts
  const orderParts = (parts: IActionPart[]) => {
    const shop = checkShopAssignedAndGet();
    if (!shop || !currentOrder) return;

    dispatch(
      addPurchaseOrder({
        data: convertActionPartToPurchaseOrder(
          parts,
          currentOrder.id,
          shop?.id
        ),
        onSuccess: () => {
          handleClose("Parts ordered", parts);
          saveStatusActionItemPart(5, parts);
        },
      })
    );
  };

  const receiveParts = (values: IPurchaseOrderRecieved, reset: () => void) => {
    if (
      !selectedParts ||
      !selectedParts[0].purchase_order_line_item?.purchase_order
    ) {
      notifyError("You can't receive this part");
      return;
    }

    dispatch(
      receivePurchaseOrder({
        id: selectedParts[0].purchase_order_line_item?.purchase_order,
        data: values,
        onSuccess: () => {
          handleClose("Parts received");
          saveStatusActionItemPart(6, selectedParts);
          reset();
        },
      })
    );
  };

  return { addQuote, orderParts, receiveParts };
};
