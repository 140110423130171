import React, { useEffect } from "react";
import { Grid } from "@mui/material";
import SelectField from "Components/UI/SelectField/SelectField";
import styles from "./EmployeeForm.module.scss";
import { Control } from "react-hook-form";
import { useAppDispatch } from "Redux/store";
import { useSelector } from "react-redux";
import { getRolesList } from "Redux/Role/reducers";
import { selectRoleList } from "Redux/Role/slice";
import { selectShopList } from "Redux/Shop/slice";
import { getShopList } from "Redux/Shop/reducer";
import cn from "classnames";

type AccessProps = {
  control: Control<any>;
};

const Access: React.FC<AccessProps> = ({ control }) => {
  const dispatch = useAppDispatch();
  const roles = useSelector(selectRoleList) || [];
  const shops = useSelector(selectShopList) || [];

  useEffect(() => {
    dispatch(getRolesList(null));
    dispatch(getShopList(null));
  }, []);

  return (
    <div className={cn(styles.block, styles.block__2)}>
      <div className={styles.form__access}>
        <h4 className={styles.form__access__title}>Access</h4>
        <Grid container spacing={1} rowSpacing={-1}>
          <Grid item xs={12}>
            <SelectField
              name="shop"
              label="Asained Shop"
              options={shops.map((shop) => ({
                label: shop.shop_name,
                value: shop.id,
              }))}
              withControl
              control={control}
            />
          </Grid>
          <Grid item xs={12}>
            <SelectField
              name="role"
              label="Role"
              options={roles?.map((role) => ({
                label: role.name,
                value: role.id,
              }))}
              withControl
              control={control}
            />
          </Grid>
        </Grid>
      </div>
    </div>
  );
};
export default Access;
