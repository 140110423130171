import * as React from "react";
import styles from "./Modal.module.scss";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogProps,
  DialogActions,
} from "@mui/material";
import Icon from "../Icon/Icon";
import { CloseIcon } from "Assets/index";
import IconButton from "Components/UI/IconButton/IconButton";
import Button from "../Button/Button";

type ModalProps = DialogProps & {
  isClosed?: boolean;
  icon?: React.ElementType;
  acceptFunc?: () => void;
  acceptTitle?: string;
  onCancel?: () => void;
  isNeedAction?: boolean;
};
const Modal: React.FC<ModalProps> = ({
  open,
  onClose,
  title,
  children,
  fullScreen,
  isClosed,
  icon,
  acceptFunc,
  acceptTitle = "Save",
  onCancel,
  isNeedAction,
}) => {
  return (
    <Dialog
      fullScreen={fullScreen}
      className={styles.modal}
      open={open}
      onClose={onClose}
      fullWidth
    >
      <DialogTitle display="flex" justifyContent="space-between">
        <div className={styles.header_title_cont}>
          {icon && <IconButton variant="primary" icon={icon} />}
          <span className={styles.header_title_cont__title}>{title}</span>
        </div>
        {isClosed && (
          <Icon
            component={CloseIcon}
            onClick={(event) => onClose?.(event, "backdropClick")}
          />
        )}
      </DialogTitle>
      <DialogContent>{children}</DialogContent>
      {isNeedAction && (
        <DialogActions>
          <Button onClick={onCancel} color="secondary">
            Cancel
          </Button>
          <Button onClick={acceptFunc}>{acceptTitle}</Button>
        </DialogActions>
      )}
    </Dialog>
  );
};

export default Modal;
