import * as Yup from "yup";
import { IShopList } from "Shared/Types/shop";
import { IUser } from "Shared/Types/user";
import { ICardData, ICommonStatus } from "Shared/Types/common";
import { STATUS_COLOR } from "Shared/Constants/app";

export const shopScheme = Yup.object().shape({
  shop_name: Yup.string().required("Shop name is required"),
  email: Yup.string(),
  fax: Yup.string(),
  website: Yup.string(),
  timezone: Yup.string(),
  status: Yup.string(),
  physical_address: Yup.string(),
  technician_pay_type: Yup.string(),
  technician_pay_rate: Yup.string(),
  overhead: Yup.number(),
  phone: Yup.string().required("Field is required"),
  owner: Yup.mixed<IUser>(),
  tax_rate: Yup.string().optional(),
  labor_rate: Yup.string(),
  supply_rate: Yup.string(),
});

export const getShopCardData = (shop: IShopList) => {
  const data: ICardData[] = [
    {
      title: "Title",
      value: shop.shop_name,
      isOnlyValue: true,
      withoutBorder: true,
      isMain: true,
    },
    { title: "Tax Rate", value: shop.tax_rate?.title },
    { title: "Code", value: shop.shop_id },
    { title: "Employee", value: shop.employee },
    { title: "Phone Number", value: shop.phone, isWrap: true },
    {
      title: "Email Address",
      value: shop.email,
      isValueBlue: true,
      isWrap: true,
    },
    { title: "Physical Address", value: shop.physical_address, isWrap: true },
  ];

  return data;
};

export const getShopCardStatus = (status: ICommonStatus) => {
  return STATUS_COLOR[status];
};
