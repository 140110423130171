import { IUnitAdd } from "Shared/Types/unit";
import React from "react";
import { Control } from "react-hook-form";
import styles from "./UnitForm.module.scss";
import { Grid } from "@mui/material";
import InputField from "Components/UI/Inputs/InputField";
import DragDrop from "Components/UI/DragDrop/DragDrop";

type AdditionalProps = {
  control: Control<IUnitAdd>;
};

const Additional: React.FC<AdditionalProps> = ({ control }) => {
  return (
    <div className={styles.block}>
      <h4>Additional Details</h4>
      <Grid container>
        <Grid item xs={12}>
          <InputField
            control={control}
            withController
            name="description"
            label="Additional information"
            multiline
            rows={4}
          />
        </Grid>
        <Grid item xs={12} display="flex" justifyContent="start">
          {/* <DragDrop name='photo' label='Photo' />
        <DragDrop type='file' name='document' label='Document' /> */}
        </Grid>
      </Grid>
    </div>
  );
};
export default Additional;
