import React from "react";
import SettingsLayout from "Components/Settings/Layout/Layout";
import Layout from "Components/Layout/Layout";
import TaxRateUpdate from "Containers/Settings/Rate/TaxRate/TaxRateUpdate/TaxRateUpdate";
import { BreadcrumbItem } from "Components/Breadcrumb/Breadcrumb";
import { useBreadcrumb } from "Shared/Hooks/use-breadcrumb";
import { useSelector } from "react-redux";
import { selectTaxRate } from "Redux/TaxRate/slice";

export const TaxRateUpdatePage = () => {
  const taxRate = useSelector(selectTaxRate);

  const breads: BreadcrumbItem[] = [
    { title: "Settings", link: "/settings/rate-settings/tax-rate" },
    { title: "Tax Rate", link: "/settings/rate-settings/tax-rate" },
    { title: `Update ${taxRate?.title || ""}`, link: "" },
  ];
  useBreadcrumb({ links: breads, option: { upd: taxRate?.title } });

  return (
    <Layout>
      <SettingsLayout>
        <TaxRateUpdate />
      </SettingsLayout>
    </Layout>
  );
};
