import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectUserList } from "Redux/User/userSlice";
import { useAppDispatch } from "Redux/store";
import { deleteUser, getUserList } from "Redux/User/reducer";
import { useNavigate } from "react-router";
import DeleteModal from "Components/UI/DialogWindow/DeleteModal";
import { notifySuccess } from "Components/Notify/notify";
import ListLayout from "Components/Layout/ListLayout/ListLayout";
import { useSearchParams } from "react-router-dom";
import { employeeFields } from "Shared/Constants/employee";
import { selectShopNumPage } from "Redux/Shop/slice";
import { getEmployeeCardData, getEmployeeCardStatus } from "Shared/Utils/user";
import Card from "Components/UI/Card/Card/Card";
import { IUser } from "Shared/Types/user";
import Row from "Components/UI/Card/Row/Row";
import CardHead from "Components/Employee/Card/CardHead/CardHead";

export const EmployeeList: React.FC = () => {
  const dispatch = useAppDispatch();
  const employeeList = useSelector(selectUserList) || [];
  const numPage = useSelector(selectShopNumPage) || 1;
  const navigate = useNavigate();
  const [showDelete, setShowDelete] = useState(false);
  const [deleteId, setDeleteId] = useState<string | null>();
  const [fields, setFields] = useState(employeeFields);
  const [isCard, setIsCard] = useState(true);
  const [searchParams] = useSearchParams();

  const goToEditPage = (id: string) => {
    navigate(`/employees/edit/${id}`);
  };
  const gotToInfoPage = (id: string) => {
    navigate(`/employees/info/${id}`);
  };
  const handleDialogClose = () => {
    setShowDelete(false);
    setDeleteId(null);
  };

  const handleDialogOpen = (id: string) => {
    setShowDelete(true);
    setDeleteId(id);
  };

  const props = (employee: IUser) => {
    const titleComponent = <CardHead employee={employee} />;
    return {
      openDeleteModal: handleDialogOpen,
      fields: fields,
      onClick: gotToInfoPage,
      cardData: getEmployeeCardData(employee, titleComponent),
      editClick: goToEditPage,
      dataId: employee.id,
      isNeedStatus: true,
      status: getEmployeeCardStatus(employee.is_active),
    };
  };

  const handleDeleteEmployee = async () => {
    if (deleteId) {
      await dispatch(
        deleteUser({
          id: deleteId,
          onSuccess: () => {
            notifySuccess("User deleted");
            setShowDelete(false);
            setDeleteId(null);
            dispatch(getUserList(null));
          },
        })
      );
    }
  };

  useEffect(() => {
    dispatch(
      getUserList({
        search: searchParams.get("search"),
        status: searchParams.get("status"),
      })
    );
  }, [searchParams]);

  return (
    <ListLayout
      pagesNumber={numPage}
      isCard={isCard}
      setIsCard={setIsCard}
      fields={fields}
      setFields={setFields}
      filters={["search", "employeesStatus"]}
      fieldAllList={employeeFields}
    >
      {isCard
        ? employeeList.map((employee) => (
            <Card key={employee.id} {...props(employee)} />
          ))
        : employeeList.map((employee) => (
            <Row key={employee.id} {...props(employee)} />
          ))}
      <DeleteModal
        acceptTitle="Delete"
        open={showDelete}
        handleClose={handleDialogClose}
        title="Delete"
        text="Are you sure...?"
        accept={handleDeleteEmployee}
        color="red"
      />
    </ListLayout>
  );
};
