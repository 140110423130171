export const profileData = {
    dates: ["10/16/2023", "10/17/2023", "10/18/2023","10/18/2023","10/19/2023","10/20/2023"],
    stats: [
        {
        label: "Start Time",
        values: [24.0, 30.9,24.0, 30.9, 15.8,16.5,1], 
    
        },
        {
        label: "End Time",
        values: [24.0, 45], 
   
        },
        {
        label: "Hours",
        values: [10.4, 15.2], 

        },


    ],
    };