import React, { useEffect, useState } from "react";
import styles from "./ShopForm.module.scss";
import InputField from "Components/UI/Inputs/InputField";
import SelectField from "Components/UI/SelectField/SelectField";
import Button from "Components/UI/Button/Button";
import { ObjectSchema } from "yup";
import { IShop, IShopAdd } from "Shared/Types/shop";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import Checkbox from "Components/UI/Inputs/Checkbox";
import Form from "Components/UI/Form/Form";
import { useAppDispatch } from "Redux/store";
import { getTaxRateList } from "Redux/TaxRate/recuder";
import { getSupplyRateList } from "Redux/SupplyRate/reducer";
import { getLaborRateList } from "Redux/LaborRate/reducer";
import InputFieldWithIndicator from "Components/UI/Inputs/InputFieldWithIndicator";
import { getUserList } from "Redux/User/reducer";
import {
  indicatorMapping,
  technicianPayTypeOptions,
  timeZoneList,
} from "Components/Shop/Options";
import { useSelector } from "react-redux";
import { selectLaborRateList } from "Redux/LaborRate/slice";
import { selectTaxRateList } from "Redux/TaxRate/slice";
import { selectSupplyRateList } from "Redux/SupplyRate/slice";
import { DollarIcon } from "Assets/index";
import Block from "Components/UI/Block/Block";
import { UserSearch } from "Components/UI/AutoCompleteSearchInputs/UserSearch";

type ShopFormProps = {
  onSubmit: (values: IShopAdd) => void;
  scheme: ObjectSchema<IShopAdd>;
  shop?: IShop | undefined;
};

const ShopForm: React.FC<ShopFormProps> = ({ onSubmit, scheme, shop }) => {
  const methods = useForm({
    resolver: yupResolver(scheme),
    defaultValues: shop,
  });

  const dispatch = useAppDispatch();
  const [indicator, setIndicator] = useState("/month");
  const laborRateList = useSelector(selectLaborRateList) || [];
  const taxRateList = useSelector(selectTaxRateList) || [];
  const supplyRateList = useSelector(selectSupplyRateList) || [];
  const [active, setActive] = useState(shop?.status === "active");

  const laborList = laborRateList?.map(({ name, id }) => {
    return { label: name, value: id };
  });

  const taxList = taxRateList?.map(({ title, id }) => {
    return { label: title, value: id };
  });

  const supplyList = supplyRateList?.map(({ title, id }) => {
    return { label: title, value: id };
  });

  const onChangeIndicator = (value: string) => {
    const selectedIndicator = indicatorMapping[value];

    if (selectedIndicator !== undefined) {
      setIndicator(selectedIndicator);
    }
  };

  useEffect(() => {
    dispatch(getTaxRateList());
    dispatch(getSupplyRateList());
    dispatch(getLaborRateList(null));
    dispatch(getUserList(null));
  }, []);

  return (
    <Block className={styles.form}>
      <FormProvider {...methods}>
        <Form onSubmit={methods.handleSubmit(onSubmit)}>
          <h3>Basic Info</h3>
          <Checkbox
            label="Active"
            name="status"
            checked={active}
            onChange={(_, checked) => {
              setActive(checked);
              methods.setValue("status", checked ? "active" : "inactive");
            }}
            labelPosition="right"
          />
          <InputField
            control={methods.control}
            withController
            required
            label="Shop name"
            name="shop_name"
          />
          <UserSearch
            name="owner"
            label="Shop Manager"
            onChange={(selectedUser) => {
              methods.setValue("email", selectedUser?.email || "");
              methods.setValue("phone", selectedUser?.phone || "");
            }}
          />
          <InputField
            control={methods.control}
            withController
            label="Email"
            name="email"
            value={methods.watch().owner?.email ?? ""}
            disabled
          />
          <InputField
            control={methods.control}
            withController
            required
            label="Main Phone"
            name="phone"
            value={methods.watch().owner?.phone ?? ""}
            disabled
          />
          <InputField
            control={methods.control}
            withController
            label="Fax"
            name="fax"
          />
          <InputField
            control={methods.control}
            withController
            label="Website"
            name="website"
          />
          <SelectField
            control={methods.control}
            withControl
            placeholder="Select one"
            options={timeZoneList}
            name="timezone"
            label="Time Zone:"
            theme="primary"
          />
          <h3>Rate</h3>
          <SelectField
            theme="primary"
            control={methods.control}
            withControl
            placeholder="Select one"
            options={taxList}
            name="tax_rate"
            label="Tax Rate:"
            required
          />
          <SelectField
            theme="primary"
            control={methods.control}
            withControl
            placeholder="Select one"
            options={technicianPayTypeOptions}
            name="technician_pay_type"
            label="Technician Pay Type:"
            onChange={(value) => onChangeIndicator(value as string)}
            required
          />
          <InputFieldWithIndicator
            control={methods.control}
            withController
            label="Technician Pay Rate:"
            name="technician_pay_rate"
            type="number"
            indicator={indicator}
            icon={DollarIcon}
            required
          />
          <InputFieldWithIndicator
            control={methods.control}
            withController
            label="Overhead:"
            name="overhead"
            type="number"
            indicator={indicator}
            icon={DollarIcon}
            required
          />
          <SelectField
            theme="primary"
            control={methods.control}
            withControl
            options={laborList}
            placeholder="Select one"
            name="labor_rate"
            label="Default Labor Rate:"
            required
          />
          <SelectField
            theme="primary"
            control={methods.control}
            withControl
            options={supplyList}
            placeholder="Select one"
            name="supply_rate"
            label="Default Supplies Rate:"
            required
          />
          <InputField
            control={methods.control}
            withController
            label="Physical Address"
            name="physical_address"
            required
          />
          <div className={styles.form__actions}>
            {shop ? (
              <Button variant="outlined" type="submit">
                Save
              </Button>
            ) : (
              <Button type="submit">Create</Button>
            )}
          </div>
        </Form>
      </FormProvider>
    </Block>
  );
};

export default ShopForm;
