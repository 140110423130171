import { matchPath } from "react-router-dom";

import { routeArray, RouteData, routeMap } from "./route-config";

const matches = (url: string, path: string) => {
  const match = matchPath(path, url);
  return match !== null;
};

export const hasMatch = (url: string) => {
  if (routeMap.has(url)) return true;
  return routeArray.some((r) => matches(url, r.path));
};

export const hasAccess = (
  userPermissions: string[],
  url: string,
  isCompanyAdmin: boolean
) => {
  if (isCompanyAdmin) return true;
  const checkRoute = (route: RouteData) =>
    route.permissions.every((item) => userPermissions.includes(item));

  return routeMap.has(url)
    ? checkRoute(routeMap.get(url)!)
    : routeArray.some((r) => checkRoute(r) && matches(url, r.path));
};
