import React, { useEffect } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { Controller, useFormContext } from "react-hook-form";
import { useSelector } from "react-redux";
import { useAppDispatch } from "Redux/store";
import styles from "./AutoCompleteSearchInput.module.scss";
import { selectLaborRateList } from "Redux/LaborRate/slice";
import { getLaborRateList } from "Redux/LaborRate/reducer";
import { ILaborRate } from "Shared/Types/laborRate";
import { useNavigate } from "react-router";
import { ArrowDownBlackIcon } from "Assets/index";

interface SearchProps {
  name: string;
  required?: boolean;
  label?: string;
  onChange?: (laborRate:ILaborRate | null) => void;
  isAdd?: boolean;
  beforeCreateNew?: () => void;
}

export const componentsProps = {
  popper: {
    modifiers: [
      {
        name: "flip",
        enabled: false,
      },
      {
        name: "preventOverflow",
        enabled: false,
      },
    ],
  },
};

export const LaborRateSearch: React.FC<SearchProps> = ({
  name,
  required,
  label,
  onChange,
  beforeCreateNew,
  isAdd,
}) => {
  const { control } = useFormContext();
  const laborRates = useSelector(selectLaborRateList) || [];
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const goToCreateNew = () => {
    beforeCreateNew?.();
    navigate("/settings/rate-settings/labor-rate/new", { state: "back" });
  };
  const searchLaborRate = (text: string | null) => {
    dispatch(
      getLaborRateList({
        search: text || null,
      })
    );
  };
  const getLabel = (laborRate: ILaborRate | null) => {
    if (laborRate) {
      return laborRate.name;
    }
    return "";
  };

  useEffect(() => {
    dispatch(
      getLaborRateList({
        search: control._defaultValues?.labor_rate?.name || null,
      })
    );
  }, []);
  return (
    <div className={styles.autocomplete}>
      <label data-id={name} className={styles.autocomplete__label}>
        {label}
        {required && <span className={styles.autocomplete__error}>*</span>}
      </label>
      <Controller
        name={name}
        control={control}
        defaultValue={control._defaultValues.labor_rate}
        render={({ field }) => (
          <Autocomplete
            {...field}
            options={laborRates}
            getOptionLabel={getLabel}
            onChange={(_, value) => {
              onChange?.(value)
              field.onChange(value);
            }}
            disablePortal={true}
            popupIcon={<ArrowDownBlackIcon/>}

            componentsProps={componentsProps}
            noOptionsText={
              isAdd && (
                <label onClick={goToCreateNew}>+ Add new Labor Rate</label>
              )
            }
            renderInput={(params) => (
              <TextField
                sx={{
                  ".MuiOutlinedInput-notchedOutline": {
                    borderStyle: "none",
                  },
                }}
                classes={{ root: styles.autocomplete__input }}
                name="labor_rate"
                required={required}
                onChange={(event) => {
                  searchLaborRate(event.target.value);
                }}
                {...params}
              />
            )}
          />
        )}
      />
    </div>
  );
};
