import { createBrowserHistory } from "history";

export const history = createBrowserHistory();

export const changeToParams = (
  key: string,
  value: number | string | boolean,
  searchParams: URLSearchParams
) => {
  if (!value || value === "") {
    searchParams.delete(key);
  } else if (searchParams.has(key)) {
    searchParams.set(key, String(value));
  } else {
    searchParams.append(key, String(value));
  }

  return searchParams;
};
