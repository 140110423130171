import React from "react";
import styles from "./Manufacturer.module.scss";
import { useNavigate } from "react-router";
import { useAppDispatch } from "Redux/store";
import { useSelector } from "react-redux";
import { selectAccount } from "Redux/Auth/slice";
import { notifyError, notifySuccess } from "Components/Notify/notify";
import StepProgress from "Components/UI/StepProgress/StepProgress";

import ManufacturerForm from "Components/Settings/PartSettings/Manufacturer/ManufacturerForm";
import { IManufacturerAdd } from "Shared/Types/manufacturer";
import { addManufacturer } from "Redux/PartSettings/Manufacturer/reducer";
import { manufacturerSchema } from "Shared/Utils/part-settings";
import { partSettingStep } from "../Categories/CategoryAdd/CategoryAdd";
import { checkFirstAuth } from "Shared/Utils/app";
import { readObj } from "Service/storage";

const ManufactureAdd = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const profile = useSelector(selectAccount);
  const isFirstAuth = checkFirstAuth(profile);

  const onSkip = () => {
    navigate("/settings/part-settings/locations/new");
  };
  const onSuccess = () => {
    if (isFirstAuth) {
      onSkip();
    } else {
      navigate("/settings/part-settings/manufactures");
    }
    notifySuccess("Manufacture created");
  };
  const addNewManufacture = (values: IManufacturerAdd) => {
    const shop = readObj("current_shop");
    if (!shop) {
      notifyError("You can't create service, because you don't assign to shop");
      return;
    }
    dispatch(
      addManufacturer({ data: { ...values, shop: shop.id }, onSuccess })
    );
  };

  return (
    <div>
      {isFirstAuth && (
        <StepProgress
          description={partSettingStep}
          title="Part Settings"
          active={7}
        />
      )}
      <div className={styles.manufacturer__form}>
        <ManufacturerForm
          onSubmit={addNewManufacture}
          schema={manufacturerSchema}
          onSkip={onSkip}
          isFirstAuth={isFirstAuth}
        />
      </div>
    </div>
  );
};

export default ManufactureAdd;
