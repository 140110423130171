import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { shopFields } from "Shared/Constants/shop";
import { useAppDispatch } from "Redux/store";
import { deleteShop, getShopList } from "Redux/Shop/reducer";
import { useSelector } from "react-redux";
import { selectShopList, selectShopNumPage } from "Redux/Shop/slice";
import DeleteModal from "Components/UI/DialogWindow/DeleteModal";
import { notifySuccess } from "Components/Notify/notify";
import ListLayout from "Components/Layout/ListLayout/ListLayout";
import { getShopCardData, getShopCardStatus } from "Shared/Utils/shop";
import { useSearchParams } from "react-router-dom";
import Card from "Components/UI/Card/Card/Card";
import { IShopList } from "Shared/Types/shop";
import Row from "Components/UI/Card/Row/Row";
import { ICommonStatus } from "Shared/Types/common";
const ShopList = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const shopList = useSelector(selectShopList) || [];
  const numPage = useSelector(selectShopNumPage) || 1;
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [isCard, setIsCard] = useState(true);
  const [fields, setFields] = useState(shopFields);
  const [deleteId, setDeleteId] = useState<string | null>();
  const [searchParams] = useSearchParams();

  const handleDialogClose = () => {
    setOpenDeleteModal(false);
    setDeleteId(null);
  };

  const handleDialogOpen = (id: string) => {
    setOpenDeleteModal(true);
    setDeleteId(id);
  };

  const goToDetailPage = (id: string) => {
    navigate(`/shops/${id}`);
  };

  const goToEditPage = (id: string) => {
    navigate(`/shops/edit/${id}`);
  };

  const props = (shop: IShopList) => {
    return {
      openDeleteModal: handleDialogOpen,
      fields: fields,
      onClick: goToDetailPage,
      cardData: getShopCardData(shop),
      editClick: goToEditPage,
      dataId: shop.id,
      isNeedStatus: true,
      status: getShopCardStatus(shop.status as ICommonStatus),
    };
  };

  const onDelete = () => {
    if (deleteId != null) {
      dispatch(
        deleteShop({
          id: deleteId,
          onSuccess: () => {
            dispatch(getShopList(null));
            notifySuccess("Shop deleted");
            handleDialogClose();
          },
        })
      );
    }
  };

  useEffect(() => {
    dispatch(
      getShopList({
        search: searchParams.get("search"),
        status: searchParams.get("status"),
      })
    );
  }, [searchParams]);

  return (
    <ListLayout
      pagesNumber={numPage}
      isCard={isCard}
      setIsCard={setIsCard}
      fields={fields}
      setFields={setFields}
      filters={["shopStatus", "search"]}
      fieldAllList={shopFields}
    >
      {isCard
        ? shopList.map((shop) => <Card key={shop.id} {...props(shop)} />)
        : shopList.map((shop) => <Row key={shop.id} {...props(shop)} />)}
      <DeleteModal
        acceptTitle="Delete"
        open={openDeleteModal}
        handleClose={handleDialogClose}
        title="Delete"
        text="Are you sure...?"
        accept={onDelete}
      />
    </ListLayout>
  );
};

export default ShopList;
