import React from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import Button from "Components/UI/Button/Button";
import Form from "Components/UI/Form/Form";
import InputField from "Components/UI/Inputs/InputField";
import {
  IManufacturer,
  IManufacturerAdd,
  IManufacturerUpdate,
} from "Shared/Types/manufacturer";
import { useForm } from "react-hook-form";
import { ObjectSchema } from "yup";
import styles from "./Manufacturer.module.scss";

type ManufacturerFormProps = {
  onSubmit: (values: IManufacturerAdd | IManufacturerUpdate) => void;
  manufacturer?: IManufacturer;
  schema: ObjectSchema<IManufacturerAdd | IManufacturerUpdate>;
  onSkip?: () => void;
  isFirstAuth?: boolean;
};

const ManufacturerForm: React.FC<ManufacturerFormProps> = ({
  onSubmit,
  manufacturer,
  schema,
  onSkip,
  isFirstAuth,
}) => {
  const { handleSubmit, control } = useForm({
    resolver: yupResolver(schema),
    defaultValues: manufacturer,
  });

  return (
    <Form className={styles.form} onSubmit={handleSubmit(onSubmit)} id="myform">
      <InputField
        name="name"
        label="Name"
        withController
        control={control}
        required
      />
      <InputField
        name="description"
        label="Description"
        withController
        control={control}
      />
      <div className={styles.form__actions}>
        {isFirstAuth && (
          <Button onClick={onSkip} color="secondary" type="button">
            Skip
          </Button>
        )}
        <Button type="submit">{manufacturer ? "Save" : "Create"}</Button>
      </div>
    </Form>
  );
};
export default ManufacturerForm;
