import { OptionsProps } from "Components/UI/SelectField/SelectField";
import { ICardData } from "Shared/Types/common";
import { IService, IServiceUpdate } from "Shared/Types/service";
import * as Yup from "yup";
import { getShortValue } from "./order";

export const serviceSchema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  hour: Yup.number(),
  description: Yup.string(),
  miles: Yup.number(),
  time_interval: Yup.number(),
  date_choice: Yup.string(),
  time: Yup.string(),
  shop: Yup.string(),
  component: Yup.string().required("Component is required"),
  system: Yup.string(),
  labor_rate: Yup.string(),
});

export const timeIntervalOptoins: OptionsProps[] = [
  { label: "Day", value: "day" },
  { label: "Week", value: "week" },
  { label: "Month", value: "month" },
  { label: "Year", value: "year" },
];

export const getInitService = (service: IService): IServiceUpdate => {
  return {
    ...service,
    component: service.component.id,
    system: service.system?.id,
  };
};

export const getServiceCardData = (service: IService) => {
  const data: ICardData[] = [
    {
      title: "Service",
      value: getShortValue(service.component?.name, 12),
    },
    {
      title: "Category",
      value: getShortValue(service.system?.name, 12),
    },
    {
      title: "PM",
      value: service.miles ? "Yes" : "No",
    },
    {
      title: "Name",
      value: getShortValue(service.name, 12),
      isWrap: true,
    },
  ];
  return data;
};

export const getInFoService = (service?: IService | null) => {
  const basicInfoService = [
    {
      label: "Service",
      value: service?.component?.name,
      xs: 12,
    },
    {
      label: "Category",
      value: service?.system?.name,
      xs: 12,
    },
    {
      label: "Name",
      value: service?.name,
      xs: 12,
    },
    {
      label: "Description",
      value: service?.description,
      xs: 12,
    },
    {
      label: "Hours",
      value: service?.hour,
      xs: 6,
    },
    {
      label: "Labor Rate",
      value: service?.labor_rate,
      xs: 6,
    },
  ];
  const infoService = [
    {
      label: "Usege Interval",
      value: service?.time_interval + "Miles",
      xs: 17,
    },
    {
      label: "Time Interval",
      value: service?.date_choice + "" + service?.date_choice,
      xs: 17,
    },
    {
      label: "Primary Meter Due Soon Threshold",
      value: service?.time,
      xs: 17,
    },
  ];
  return { basicInfoService, infoService };
};
