import { client } from "Service/axios";
import { SearchResult } from "Shared/Types/common";
import {
  IContact,
  IContactApi,
  IContactSearchParams,
} from "Shared/Types/contact";

const CONTACT_URL = "customer/contact/";

class Contact {
  getContactList(params: IContactSearchParams) {
    return client.get<SearchResult<IContact>>(`${CONTACT_URL}`, { params });
  }
  getContact(id: string) {
    return client.get<IContact>(`${CONTACT_URL}${id}/`);
  }
  addContact(data: IContactApi) {
    return client.post(`${CONTACT_URL}`, data);
  }
  updateContact(id: string, data: IContactApi) {
    return client.patch(`${CONTACT_URL}${id}/`, data);
  }
  deleteContact(id: string) {
    return client.delete(`${CONTACT_URL}${id}/`);
  }
}

export default Contact;
