import { IVendor } from "Shared/Types/vendor";
import * as Yup from "yup";
import { ICardData } from "Shared/Types/common";
import { getShortValue } from "./order";

export const vendorSchema = Yup.object().shape({
  vendor_name: Yup.string().required("Vendor Name is required"),
  first_name: Yup.string().required("First Name is required"),
  last_name: Yup.string().required("Last Name is required"),
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
  phone: Yup.string().required("Phone is required"),
  cell_phone: Yup.string().required("Cell phone is required"),
  street_address: Yup.string().required("Street Address is required"),
  street_address_2: Yup.string().required("Street adress 2 is required"),
  state: Yup.string(),
  city: Yup.string(),
  country: Yup.string(),
  zip_code: Yup.string(),
  contact_name: Yup.string().nullable(),
  contact_email: Yup.string().nullable(),
  contact_phone: Yup.string().nullable(),
  additional_information: Yup.string(),
  photo: Yup.mixed<File>().nullable(),
});

export const getVendorCardData = (vendor: IVendor) => {
  const shortValue = 30;
  const data: ICardData[] = [
    {
      title: "Vendor Name",
      value: vendor.vendor_name,
      isOnlyValue: true,
      isMain: true,
      withoutBorder: true,
    },
    {
      title: "Full Name",
      value: `${vendor.first_name} ${vendor.last_name}`,
      isWrap: true,
    },
    { title: "Phone Number", value: vendor.phone, isWrap: true },
    {
      title: "Email Address",
      value: getShortValue(vendor.email, shortValue),
      isWrap: true,
      isValueBlue: true,
    },
  ];

  return data;
};
