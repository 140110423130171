import React, { useEffect, useState } from "react";
import ListLayout from "Components/Layout/ListLayout/ListLayout";
import { useSelector } from "react-redux";
import { useAppDispatch } from "Redux/store";
import { getSOUnitCardData, SOUnitFields } from "Shared/Constants/units";
import { useSearchParams } from "react-router-dom";
import { formatBackend } from "Shared/Utils/app";
import { IOrderSearchParams } from "Shared/Types/order";
import { selectOrderList } from "Redux/Order/slice";
import { getOrderList } from "Redux/Order/reducer";
import { IOrderList } from "Shared/Types/order";
import Card from "Components/UI/Card/Card/Card";
import Row from "Components/UI/Card/Row/Row";
import { selectCurrentUnit } from "Redux/Unit/slice";
const SoHistoryTab = () => {
  const orders = useSelector(selectOrderList);
  const dispatch = useAppDispatch();
  const [fields, setFields] = useState(SOUnitFields);
  const [isCard, setIsCard] = useState<boolean>(true);
  const [searchParams] = useSearchParams();
  const currentUnit = useSelector(selectCurrentUnit);

  useEffect(() => {
    const today = new Date();
    const thirtyDaysAgo = new Date();
    thirtyDaysAgo.setDate(today.getDate() - 30);
    const from = searchParams.get("from") || thirtyDaysAgo;
    const to = searchParams.get("to") || new Date();
    const unitId = currentUnit?.id;
    const params: IOrderSearchParams = {
      page: +(searchParams.get("page") || 1),
    };
    if (unitId) {
      params.unit = unitId;
    }
    if (from && to) {
      params.created_at_before = formatBackend(new Date(to), "YYYY-MM-DD");
      params.created_at_after = formatBackend(new Date(from), "YYYY-MM-DD");
    }
    if (unitId) {
      dispatch(getOrderList(params));
    }
  }, [searchParams, currentUnit]);

  const props = (order: IOrderList) => {
    return {
      dataId: order.id as string,
      fields: fields,
      cardData: getSOUnitCardData(order),
      isNeedStatus: true,
      isNeedCheck: true,
    };
  };

  return (
    <div>
      <ListLayout
        pagesNumber={1}
        setIsCard={setIsCard}
        isCard={isCard}
        fields={fields}
        fieldAllList={SOUnitFields}
        setFields={setFields}
        filters={["search", "date"]}
      >
        {orders?.map((order) =>
          isCard ? (
            <Card key={order.id} {...props(order)} />
          ) : (
            <Row key={order.id} {...props(order)} />
          )
        )}
      </ListLayout>
    </div>
  );
};

export default SoHistoryTab;
