import { IStatusSearchParams } from "Redux/UrgencyOption/reducer";
import { client } from "Service/axios";
import {
  IUrgencyOption,
  IUrgencyOptionAdd,
  IUrgencyOptionUpdate,
} from "Shared/Types/urgencyOption";

const TYPE_URL = "company/so-urgency-option/";
class UrgencyOption {
  getUrgencyOptionList(params: IStatusSearchParams) {
    return client.get(TYPE_URL, { params });
  }

  getUrgencyOption(id: string) {
    return client.get<IUrgencyOption>(`${TYPE_URL}${id}/`);
  }

  addUrgencyOption(params: IUrgencyOptionAdd) {
    return client.post(TYPE_URL, params);
  }

  deleteUrgencyOption(id: string) {
    return client.delete(`${TYPE_URL}${id}/`);
  }

  updateUrgencyOption(id: string, data: IUrgencyOptionUpdate) {
    return client.patch(`${TYPE_URL}${id}/`, data);
  }
}

export default UrgencyOption;
