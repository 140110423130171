import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "Service/api/api";
import { IUpdateRequest } from "Shared/Types/common";
import {
  IUrgencyOptionAdd,
  IUrgencyOptionUpdate,
} from "Shared/Types/urgencyOption";
import { errorHandler } from "Shared/Utils/app";

export type IStatusSearchParams = {
  company: string;
};

const getUrgencyOptionList = createAsyncThunk(
  "urgencyOption/getUrgencyOptionList",
  async (params: IStatusSearchParams) => {
    try {
      const response = await api.urgencyOption.getUrgencyOptionList(params);
      return response.data;
    } catch (error) {
      if (error instanceof Error) {
        errorHandler(error);
      }
      throw error;
    }
  }
);

const getUrgencyOption = createAsyncThunk(
  "urgencyOption/getUrgencyOption",
  async (id: string) => {
    try {
      const response = await api.urgencyOption.getUrgencyOption(id);
      return response.data;
    } catch (error) {
      if (error instanceof Error) {
        errorHandler(error);
      }
      throw error;
    }
  }
);

const addUrgencyOption = createAsyncThunk(
  "urgencyOption/addUrgencyOption",
  async (data: IUrgencyOptionAdd) => {
    try {
      const response = await api.urgencyOption.addUrgencyOption(data);
      return response.data;
    } catch (error) {
      if (error instanceof Error) {
        errorHandler(error);
      }
      throw error;
    }
  }
);

const deleteUrgencyOption = createAsyncThunk(
  "urgencyOption/deleteUrgencyOption",
  async (id: string) => {
    try {
      const response = await api.urgencyOption.deleteUrgencyOption(id);
      return response.data;
    } catch (error) {
      if (error instanceof Error) {
        errorHandler(error);
      }
      throw error;
    }
  }
);

const updateUrgencyOption = createAsyncThunk(
  "type/updateUrgencyOption",
  async ({ id, data }: IUpdateRequest<IUrgencyOptionUpdate>) => {
    try {
      const response = await api.urgencyOption.updateUrgencyOption(id, data);
      return response.data;
    } catch (error) {
      if (error instanceof Error) {
        errorHandler(error);
      }
      throw error;
    }
  }
);

export {
  getUrgencyOptionList,
  getUrgencyOption,
  addUrgencyOption,
  deleteUrgencyOption,
  updateUrgencyOption,
};
