import React from "react";
import ResetPasswordContainer from "Containers/Auth/ResetPassword";

export const ResetPassword: React.FC = () => {
  return (
    <div>
      <ResetPasswordContainer />
    </div>
  );
};
