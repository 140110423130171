import React, { useEffect } from "react";
import styles from "./PartItem.module.scss";
import { Grid } from "@mui/material";
import InputField from "Components/UI/Inputs/InputField";
import Button from "Components/UI/Button/Button";
import { IActionPart, IActionPartForm } from "Shared/Types/action-item-part";
import { useAppDispatch } from "Redux/store";
import { createActionPart, updateActionPart } from "Redux/ActionPart/reducer";
import { notifyError, notifySuccess } from "Components/Notify/notify";
import cn from "classnames";
import PartMore from "../PartMore/PartMore";
import { FormProvider, useForm } from "react-hook-form";
import Form from "Components/UI/Form/Form";
import { isInstanced, toCamelCase } from "Shared/Utils/app";
import PartSearch from "./PartSearch/PartSearch";
import { IPart } from "Shared/Types/part";
import { getDefaultActionPart } from "Shared/Utils/action-item-part";
import { getActionItem } from "Redux/ActionItem/reducer";
import { VendorSearch } from "Components/UI/AutoCompleteSearchInputs/VendorSearch";
import { OptionsProps } from "Components/UI/SelectField/SelectField";

type PartItemProps = {
  last?: boolean;
  edit?: boolean;
  part?: IActionPart;
  actionItemId: string;
  handleEdited?: () => void;
  handleDisableEdit?: () => void;
  openDeleteModal?: () => void;
};

const PartItem: React.FC<PartItemProps> = ({
  edit,
  last,
  actionItemId,
  part,
  handleDisableEdit,
  handleEdited,
  openDeleteModal,
}) => {
  const dispatch = useAppDispatch();
  const methods = useForm<IActionPartForm>({
    defaultValues: getDefaultActionPart(part, actionItemId),
  });
  const vendor = methods.watch("vendor");
  const onSubmit = (values: IActionPartForm) => {
    if (values.quantity == 0) {
      notifyError("Q-ty must be bigger than 0");
      return;
    }
    if (isInstanced<OptionsProps>(values.part_number)) {
      values.part_number = values.part_number.value?.toString() || "";
    }
    if (values.quantity) {
      if (part?.id) {
        dispatch(
          updateActionPart({
            id: part.id,
            data: values,
            onSuccess: () => {
              notifySuccess("Part is updated!");
              handleDisableEdit?.();
            },
          })
        );
      } else {
        dispatch(
          createActionPart({
            data: values,
            onSuccess: () => {
              notifySuccess("Part is created!");
              methods.reset(getDefaultActionPart(undefined, actionItemId));
              dispatch(getActionItem(actionItemId));
            },
          })
        );
      }
    }
  };

  const descriptionChange = (value?: IPart | string | number | null) => {
    if (isInstanced<IPart>(value)) {
      methods.setValue("price", value.price);
      methods.setValue("part", value.id);
      methods.setValue("quantity", 1);
    }
  };

  const onCancel = () => {
    if (part) {
      handleDisableEdit?.();
    } else {
      methods.reset(getDefaultActionPart(undefined, actionItemId));
    }
  };

  useEffect(() => {
    if (part) {
      methods.reset(getDefaultActionPart(part, actionItemId));
    }
  }, [part]);

  return (
    <div
      className={cn(
        styles.part,
        edit && styles.part__edit,
        last && styles.part__last
      )}
    >
      <div className={cn(styles.part__head, part && styles.part__head_full)}>
        {part && (
          <>
            <div
              className={cn(
                styles.part__head__item__status,
                styles[
                  `part__head__item__status_${toCamelCase(part.status.name)}`
                ]
              )}
            >
              {part.status?.name}
            </div>
            <PartMore
              onEdit={() => handleEdited?.()}
              openDelete={() => openDeleteModal?.()}
            />
          </>
        )}
      </div>
      <Form onSubmit={methods.handleSubmit(onSubmit)}>
        <FormProvider {...methods}>
          <Grid container columnSpacing={1}>
            <Grid item width="20%">
              <VendorSearch
                labelColor="white"
                name="vendor"
                label="Select vendor"
                value={vendor}
                withControl
              />
            </Grid>
            <Grid item width="20%">
              <PartSearch
                name="description"
                label="Description"
                onChange={descriptionChange}
                requered
              />
            </Grid>
            <Grid item width="15%">
              <PartSearch name="part_number" label="Part #" />
            </Grid>
            <Grid item width="10%">
              <InputField
                withController
                control={methods.control}
                type="number"
                labelColor="white"
                name="quantity"
                label="Qty"
                required
              />
            </Grid>
            <Grid item width="15%">
              <InputField
                labelColor="white"
                name="price"
                label="Price($)"
                type="number"
                withController
                control={methods.control}
              />
            </Grid>
            <Grid item width="20%" alignItems="end">
              <div className={styles.part__action}>
                <Button color="secondary" onClick={onCancel}>
                  {part ? "Cancel" : "Clear"}
                </Button>
                <Button type="submit">{part ? "Save" : "Add"}</Button>
              </div>
            </Grid>
          </Grid>
        </FormProvider>
      </Form>
    </div>
  );
};
export default PartItem;
