import React, { useState } from "react";
import styles from "./SendAuthorization.module.scss";
import InputField from "Components/UI/Inputs/InputField";
import Button from "Components/UI/Button/Button";
import Block from "Components/UI/Block/Block";

type SendAuthorizationProps = {
  onClick: (email: string) => void;
};

const SendAuthorization: React.FC<SendAuthorizationProps> = ({ onClick }) => {
  const [email, setEmail] = useState("");
  return (
    <Block isBorder className={styles.send}>
      <div className={styles.send__head}>Send request for authorization to</div>
      <InputField
        name="email"
        label="Email"
        onChange={(event) => setEmail(event.target.value)}
      />
      <div className={styles.send__action}>
        <Button onClick={() => onClick(email)} color="secondary">
          Send
        </Button>
      </div>
    </Block>
  );
};
export default SendAuthorization;
