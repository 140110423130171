import Address from "./address";
import Auth from "./auth";
import Category from "./category";
import Company from "./company";
import CompanyRequests from "./company-requests";
import Location from "./location";
import Manufacturer from "./manufacture";
import Role from "./role";
import Component from "./component";
import System from "./system";
import Unit from "./unit";
import User from "./user";
import UnitType from "./unit-type";
import Subtype from "./subtype";
import Service from "./service";
import Shop from "./shop";
import LaborRate from "./labor-rate";
import Customer from "./customer";
import TaxRate from "./tax-rate";
import SupplyRate from "./supply-rate";
import Vendor from "./vendor";
import Contact from "./contact";
import ActionItem from "./action-item";
import Order from "./order";
import ActionPart from "./action-part";
import Product from "./part";
import UrgencyOption from "./urgency-option";
import GlobalSearch from "./global-search";
import OrderInvoice from "./invoice";
import Estimate from "./estimate";
import PurchaseOrder from "./purchase-order";

class ApiService {
  public companyRequests = new CompanyRequests();
  public company = new Company();
  public auth = new Auth();
  public user = new User();
  public role = new Role();
  public category = new Category();
  public location = new Location();
  public manufacturer = new Manufacturer();
  public component = new Component();
  public system = new System();
  public address = new Address();
  public unit = new Unit();
  public unitType = new UnitType();
  public subtype = new Subtype();
  public service = new Service();
  public shop = new Shop();
  public laborRate = new LaborRate();
  public customer = new Customer();
  public taxRate = new TaxRate();
  public supplyRate = new SupplyRate();
  public vendor = new Vendor();
  public contact = new Contact();
  public actionItem = new ActionItem();
  public order = new Order();
  public actionPart = new ActionPart();
  public part = new Product();
  public urgencyOption = new UrgencyOption();
  public globalSearch = new GlobalSearch();
  public orderInvoice = new OrderInvoice();
  public estimate = new Estimate();
  public purchaseOrder = new PurchaseOrder();
}

export default new ApiService();
