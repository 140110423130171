import React from "react";
import {
  Checkbox as MUICheckbox,
  CheckboxProps as MUICheckboxProps,
} from "@mui/material";
import { Control, Controller } from "react-hook-form";

type CheckboxProps = Omit<MUICheckboxProps, "name"> & {
  withController?: boolean;
  control?: Control<any>;
  label?: string;
  name: string;
  labelPosition?: "left" | "right";
};

const Checkbox: React.FC<CheckboxProps> = ({
  label,
  control,
  withController,
  name,
  labelPosition = "left",
  onChange,
  defaultChecked,
  ...props
}) => {
  return (
    <div className={props.className}>
      {label && labelPosition === "left" && (
        <label
          style={{ cursor: "pointer", color: "white", fontSize: "14px" }}
          htmlFor={name}
        >
          {label}
        </label>
      )}
      {withController ? (
        <Controller
          name={name}
          control={control}
          defaultValue={defaultChecked}
          render={({ field }) => (
            <MUICheckbox
              style={{
                color: field.value ? "#007DF2" : "#C9C9C9",
                padding: "5px",
              }}
              id={name}
              {...field}
              defaultChecked={defaultChecked}
              checked={Boolean(field.value)}
              onChange={(event, checked) => {
                field.onChange(event);
                onChange?.(event, checked);
              }}
            />
          )}
        />
      ) : (
        <MUICheckbox
          id={name}
          sx={{
            color: "#C9C9C9",
            padding: "5px",
            "&.Mui-checked": {
              color: "#005DAC80, #005DAC33",
            },
          }}
          checked={props.checked}
          onChange={(event, checked) => {
            onChange?.(event, checked);
          }}
          {...props}
        />
      )}
      {label && labelPosition === "right" && (
        <label
          style={{ cursor: "pointer", color: "white", fontSize: "14px" }}
          htmlFor={name}
        >
          {label}
        </label>
      )}
    </div>
  );
};
export default Checkbox;
