import Layout from "Components/Layout/Layout";
import React from "react";
import { BreadcrumbItem } from "Components/Breadcrumb/Breadcrumb";
import { useBreadcrumb } from "Shared/Hooks/use-breadcrumb";
import { useSelector } from "react-redux";
import { selectCurrentPart } from "Redux/Product/slice";
import PartUpdate from "Containers/Part/PartUpdate/PartUpdate";

export const PartUpdatePage: React.FC = () => {
  const part = useSelector(selectCurrentPart);
  const breads: BreadcrumbItem[] = [
    { title: "Parts", link: "/parts" },
    { title: `Update ${part?.description}`, link: "" },
  ];
  useBreadcrumb({ links: breads, option: { upd: part?.description } });
  return (
    <Layout>
      <PartUpdate />
    </Layout>
  );
};
