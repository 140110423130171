import { client } from "Service/axios";
import { IGlobalSearch, IGlobalSearchQuery } from "Shared/Types/common";

const GLOBAL_SEARCH_URL = "company/search/";

class GlobalSearch {
  getGlobalSearch(params: IGlobalSearchQuery | null) {
    return client.get<IGlobalSearch>(`${GLOBAL_SEARCH_URL}`, { params });
  }
}

export default GlobalSearch;
