import { client } from "Service/axios";
import {
  ISystemCreate,
  ISystemSearchParams,
  ISystemUpdate,
} from "Shared/Types/system";

const SYSTEM_URL = "service/system/";
class System {
  getSystemList(params?: ISystemSearchParams | null) {
    return client.get(SYSTEM_URL, { params });
  }

  createSystem(params: ISystemCreate) {
    return client.post(SYSTEM_URL, params);
  }

  getSystem(id: string) {
    return client.get(`${SYSTEM_URL}${id}/`);
  }

  deleteSystem(id: string) {
    return client.delete(`${SYSTEM_URL}${id}/`);
  }

  updateSystem(params: ISystemUpdate) {
    const { id, ...rest } = params;
    return client.patch(`${SYSTEM_URL}${id}/`, rest);
  }
}

export default System;
