import { ILaborRate } from "Shared/Types/laborRate";
import {
  IActionItem,
  IActionItemStatus,
  IActionItemUpdate,
  IHistory,
  IOrder,
  IOrderCountStatus,
  IOrderCountStatusItem,
  IOrderList,
  IOrderStatus,
} from "Shared/Types/order";
import { IPart } from "Shared/Types/part";
import { IService } from "Shared/Types/service";
import { IUser } from "Shared/Types/user";
import { IVendor } from "Shared/Types/vendor";
import * as yup from "yup";
import { format, isInstanced } from "./app";
import { orderStatuses, orderStatusesList } from "Shared/Constants/order";
import { notifyError } from "Components/Notify/notify";
import { ICardData } from "Shared/Types/common";
import { IUnit } from "Shared/Types/unit";
import { IActionPart } from "Shared/Types/action-item-part";
import { IUrgencyOption } from "Shared/Types/urgencyOption";
import { access_unit, deliver_unit } from "./orderAdd";

export const actionItemValidate = yup.object().shape({
  name: yup.string(),
  complaint: yup.string().required("Complaint is required"),
  is_done: yup.boolean(),
  is_pre_authorize: yup.boolean(),
  is_skip_diagnosis: yup.boolean(),
  invoice_hours: yup.number().required("Invoice hours is required"),
  order: yup.string(),
  technician: yup.mixed<IUser>().nullable(),
  labor_rate: yup.mixed<ILaborRate>().nullable(),
  service: yup.mixed<IService>().nullable(),
  cause: yup.string(),
  cause_type: yup.string(),
  severity: yup.string(),
  status: yup.string().nullable(),
  action_item_part: yup.mixed<IActionPart[]>(),
  start_work: yup.string().nullable(),
  end_work: yup.string().nullable(),
  stop_date: yup.string().nullable(),
  play_date: yup.string().nullable(),
  is_working: yup.boolean().nullable(),
  actual_time: yup.number().nullable(),
  action_item_file: yup.mixed<File[]>(),
  action_item_gallery: yup.mixed<File[]>(),
  file_id_list: yup.string(),
  file_id_gallery: yup.string(),
  note_count: yup.string(),
});

export const actionItemPartschema = yup.object().shape({
  quantity: yup
    .number()
    .integer("Quantity must be an integer")
    .positive("Quantity must be a positive number")
    .required("Quantity is required"),
  price: yup
    .number()
    .positive("Price must be a positive number")
    .required("Price is required"),
  action_item: yup.string().required("Action item is required"),
  product: yup.mixed<IPart>().required("Part is required"),
  vendor: yup.mixed<IVendor>(),
});

export const actionItemAddSchema = yup.object().shape({
  complaint: yup.string().required("Complaint is required"),
  service: yup
    .mixed<IService>()
    .nullable()
    .typeError("Service must be a number"),
  is_pre_authorize: yup.boolean(),
  is_skip_diagnosis: yup.boolean(),
  order: yup.string().required("Order is required"),
});

export const getDefaultActionItem = (actionItem: IActionItem) => {
  const defaultActionItem: IActionItemUpdate = {
    ...actionItem,
    status: actionItem.status?.id,
  };

  return defaultActionItem;
};

export const getActoinSendLabel = (
  status: number | undefined,
  index: number,
) => {
  if (status === 2) {
    return `Mark Action item ${index} as Diagnosed `;
  }
  if (status === 6) {
    return "Mark is done";
  }
  if (status === 4 || status === 6) {
    return `Mark Action item ${index} as Corrected`;
  }
  if (status === 7) {
    return "Reviewed";
  }
  if (status === 8) {
    return "Preview invoice";
  }
};

export const isNeedSave = (status?: number) => {
  const arr = [4, 7, 8, 6];
  if (!status) return false;
  return arr.includes(status);
};

export const isNotWaiting = (status?: number | null) => {
  if (!status) return false;
  return status > 1;
};

export const getActionItemStatusLabel = (
  statusList: IActionItemStatus[],
  curStatus?: string | null,
) => {
  if (!curStatus) return "Waiting to assigned";
  return statusList.find((status) => status.id === curStatus)?.name || "";
};

export const getShortValue = (
  value: string | number | undefined | null,
  length = 36,
) => {
  value = value?.toString() || "-";
  if (value?.length > length) {
    return value.slice(0, length) + " . . . ";
  }

  return value;
};

export const getChangedFields = (
  original: IActionItemUpdate,
  changed: IActionItemUpdate,
) => {
  const data: IActionItemUpdate = {};
  if (original?.status !== changed.status && changed.status) {
    data.status = changed.status;
  }
  if (
    original?.technician?.id !== changed.technician?.id &&
    changed.technician
  ) {
    data.technician = changed.technician;
  }
  if (original?.cause !== changed.cause && changed.cause) {
    data.cause = changed.cause;
  }
  if (original?.cause_type !== changed.cause_type) {
    data.cause_type = changed.cause_type;
  }
  if (original?.severity !== changed.severity) {
    data.severity = changed.severity;
  }
  return data;
};

export const isMoreButton = (status?: number | null) => {
  return status === 4 || status === 6;
};

export function formatTime(seconds: number) {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = seconds % 60;

  const formattedTime = `${String(hours).padStart(2, "0")}:${String(
    minutes,
  ).padStart(2, "0")}:${String(remainingSeconds).padStart(2, "0")}`;

  return formattedTime;
}

export function getStartWorkLabel(actionItem: IActionItem, counter: number) {
  if (!actionItem.start_work) return "Start work";
  return formatTime(counter);
}

export const getHistoryCardData = (history: IHistory) => {
  return [
    {
      title: "Time",
      value: format(history.created_at, "h:mm A"),
    },
    {
      title: "Date",
      value: format(history.created_at, "YYYY-MM-DD"),
    },
    {
      title: "Type",
      value: history.type,
    },
    {
      title: "Employee",
      value: history.employee?.first_name + " " + history.employee?.last_name,
    },
    {
      title: "Customer",
      value: history.customer?.company_name,
    },
    {
      title: "Vendor",
      value: history.vendor?.vendor_name,
    },
    {
      title: "Completed Hours",
      value: history.completed_hours,
    },
    {
      title: "Clocked Hours",
      value: history.clocked_hours,
    },
    {
      title: "SO Hours",
      value: history.so_hours,
    },
    {
      title: "Complaint",
      value: history.information,
      isOnlyValue: true,
    },
  ] as ICardData[];
};

export const getOrderStatusId = (
  status?: string,
  timeStatusfromBackend: IOrderStatus[] = [],
) => {
  if (!status) return null;
  if (status === orderStatusesList.assigned) {
    return timeStatusfromBackend.find(
      (backendStatus) => backendStatus.queue === 1,
    )?.id;
  }
  if (status === orderStatusesList.diagnosed) {
    return timeStatusfromBackend.find(
      (backendStatus) => backendStatus.queue === 2,
    )?.id;
  }
  if (status === orderStatusesList.authorize) {
    return timeStatusfromBackend.find(
      (backendStatus) => backendStatus.queue === 3,
    )?.id;
  }
  if (status === orderStatusesList.repair) {
    return timeStatusfromBackend.find(
      (backendStatus) => backendStatus.queue === 4,
    )?.id;
  }
  if (status === orderStatusesList.invoiced) {
    return timeStatusfromBackend.find(
      (backendStatus) => backendStatus.queue === 5,
    )?.id;
  }
  return timeStatusfromBackend.find(
    (backendStatus) => backendStatus.queue === 6,
  )?.id;
};

export const getFullAddress = (order?: IOrder | null) => {
  const customer = order?.customer;
  let address = "";
  if (customer?.country) {
    address += customer.country + ", ";
  }
  if (customer?.state) {
    address += customer.state + ", ";
  }
  if (customer?.city) {
    address += customer.city + ", ";
  }
  if (customer?.street_address) {
    address += customer.street_address + ", ";
  }
  if (customer?.street_address_2) {
    address += customer.street_address_2 + ", ";
  }

  if (address) return address;
};

export const getPartListOrderDatalData = (order: IOrder) => {
  return [
    {
      label: "S0:",
      value: order.order_number,
      isUnderLine: true,
      isBlue: false,
    },
    {
      label: "Customer:",
      value: order.customer?.company_name,
      isUnderLine: true,
      isBlue: true,
    },
    {
      label: "Unit:",
      value: order.unit?.name,
      isUnderLine: true,
      isBlue: true,
    },
    {
      label: "Chassis:",
      value: order.chassis_miles,
      isUnderLine: false,
      isBlue: false,
    },
    {
      label: "P0:",
      value: order.purchase_order_number,
      isUnderLine: true,
      isBlue: false,
    },
    {
      label: "Name:",
      value: `${order.customer?.first_name} ${order.customer?.last_name}`,
      isUnderLine: false,
      isBlue: false,
    },
    {
      label: "Vin:",
      value: order.unit?.vin,
      isUnderLine: false,
      isBlue: false,
    },
    {
      label: "Year:",
      value: order.unit?.name,
      isUnderLine: false,
      isBlue: false,
    },
  ];
};
export const checkPossibityInvoice = (
  actionItems: IActionItem[],
  order?: IOrder | null,
) => {
  if (!order?.lead_technician) {
    notifyError(`This SO deosn't have a Lead Technician`);
    return false;
  }
  for (const item of actionItems) {
    if ((item.status?.queue || 0) < 8) {
      notifyError(`This Action Item didn't reviewed`);
      return false;
    }
  }

  return true;
};

export const getOrdreCardData = (order: IOrderList | IOrder) => {
  const SHORT_VALUE = 16;
  const arr: ICardData[] = [
    {
      title: "Order #",
      value: getShortValue(order.order_number, SHORT_VALUE),
    },
    {
      title: "Unit",
      value: getShortValue(
        isInstanced<IUnit>(order.unit) ? order.unit.number : String(order.unit),
        SHORT_VALUE,
      ),
      isValueBlue: true,
    },
    {
      title: "Created",
      value: format(order.created_at || ""),
    },
    {
      title: "Client",
      value: getShortValue(order.customer?.company_name, SHORT_VALUE),
    },
    {
      title: "Invoice",
      value: order.has_invoiced ? "Yes" : "No",
    },
    {
      title: "Creator",
      value: getShortValue(order.service_requester, SHORT_VALUE),
    },
    {
      title: "Invoice Hours",
      value: order.invoice_hours,
    },
    {
      title: "Lead Tech",
      value: getShortValue(
        isInstanced<IUser>(order.lead_technician)
          ? `${order.lead_technician.first_name} ${order.lead_technician.last_name}`
          : order.lead_technician,
        SHORT_VALUE,
      ),
    },
  ];
  return arr;
};

export const getOrderLaborTotal = (order: IOrder) => {
  return order.actionitem_set?.reduce(
    (acc, actionItem) =>
      acc + (actionItem.labor_rate?.rate || 0) * actionItem.invoice_hours,
    0,
  );
};

export const getOrderPartsTotal = (parts: IActionPart[]) => {
  return parts?.reduce(
    (acc, part) => acc + (part.selling_price || 0) * (part.quantity || 0),
    0,
  );
};

export const getOrderPriceTotal = (
  tax: number,
  supply: number,
  order: IOrder,
  parts: IActionPart[],
) => {
  return (
    Number(tax) +
    Number(supply) +
    getOrderLaborTotal(order) +
    getOrderPartsTotal(parts)
  ).toFixed(2);
};

const createStatuses = (
  orderStatus: IOrderCountStatusItem,
  statuses: IUrgencyOption[],
) => {
  return statuses.map((status) => ({
    name: status.title,
    count: orderStatus?.time_statuses[status.title] || 0,
    color: status.color || "none",
  }));
};

export const getOrderCountByStatusList = (
  statuses: IUrgencyOption[],
  orderCountByStatus?: IOrderCountStatus,
  isHome = false,
) => {
  return [
    {
      title: orderStatuses.assigned.title,
      count: orderCountByStatus?.assign?.count,
      link: orderStatuses.assigned.link,
      timeStatuses: createStatuses(
        orderCountByStatus?.assign as IOrderCountStatusItem,
        statuses,
      ),
      fromHome: { state: isHome },
    },

    {
      title: orderStatuses.diagnosed.title,
      count: orderCountByStatus?.diagnose?.count,
      link: orderStatuses.diagnosed.link,
      timeStatuses: createStatuses(
        orderCountByStatus?.diagnose as IOrderCountStatusItem,
        statuses,
      ),
      fromHome: { state: isHome },
    },
    {
      title: orderStatuses.authorize.title,
      count: orderCountByStatus?.authorize?.count,
      link: orderStatuses.authorize.link,
      timeStatuses: createStatuses(
        orderCountByStatus?.authorize as IOrderCountStatusItem,
        statuses,
      ),
      fromHome: { state: isHome },
    },
    {
      title: orderStatuses.repair.title,
      count: orderCountByStatus?.repair?.count,
      link: orderStatuses.repair.link,
      timeStatuses: createStatuses(
        orderCountByStatus?.repair as IOrderCountStatusItem,
        statuses,
      ),
      fromHome: { state: isHome },
    },
    {
      title: orderStatuses.invoiced.title,
      count: orderCountByStatus?.invoice?.count,
      link: orderStatuses.invoiced.link,
      timeStatuses: createStatuses(
        orderCountByStatus?.invoice as IOrderCountStatusItem,
        statuses,
      ),
      fromHome: { state: isHome },
    },
    {
      title: orderStatuses.done.title,
      count: orderCountByStatus?.done?.count || 0,
      link: orderStatuses.done.link,
      timeStatuses: createStatuses(
        orderCountByStatus?.done as IOrderCountStatusItem,
        statuses,
      ),
      fromHome: { state: isHome },
    },
  ];
};

export const getBasicInformationFields = (
  order: IOrder | null | undefined,
): {
  label: string;
  value: string;
  labelColor: string;
  valueColor?: "blue" | "gray" | "lightBlue";
  value2?: string;
}[] => {
  return [
    {
      label: "Customer",
      labelColor: "white",
      valueColor: "blue",
      value: order?.customer?.company_name || "",
      value2:
        (order?.customer?.first_name || "") +
        " " +
        (order?.customer?.last_name || ""),
    },
    {
      label: "Unit",
      labelColor: "white",
      valueColor: "blue",
      value: order?.unit?.name || "",
      value2: order?.unit?.vin || "",
    },
    {
      label: "Invoiced",
      labelColor: "white",
      value: "Total: $0",
    },
  ];
};

export const getSchedulingAccessFields = (
  order: IOrder | null | undefined,
): {
  name: string;
  label: string;
  value: string;
  borderColor: "red" | "orange" | "blue";
}[] => {
  return [
    {
      name: "availability_date",
      label: "Availability of the unit?",
      value: order?.availability_date || "",
      borderColor: "orange",
    },
    {
      name: "finish_date",
      label: "When would you like it back?",
      value: order?.finish_date || "",
      borderColor: "red",
    },
    {
      name: "start_date",
      label: "When would you like to start work?",
      value: order?.start_date || "",
      borderColor: "blue",
    },
  ];
};

export const getAccessAndStatusFields = (
  order: IOrder | null | undefined,
  urgencyOptionList?: { label: string; value: string }[],
  users?: IUser[],
) => {
  return [
    {
      name: "access_unit",
      label: "How will we access the unit?",
      value: order?.access_unit,
      options: access_unit,
    },
    {
      name: "time_status",
      label: "Status",
      value: order?.time_status?.title,
      options: urgencyOptionList,
    },
    {
      name: "lead_technician",
      label: "Who will be the lead technician?",
      value:
        (order?.lead_technician?.first_name || "") +
        " " +
        (order?.lead_technician?.last_name || "-"),
      options: users?.map((user) => ({
        label: `${user.first_name} ${user.last_name}`,
        value: user.id,
      })),
    },
    {
      name: "deliver_unit",
      label: "Will we deliver the unit?",
      value: order?.deliver_unit,
      options: deliver_unit,
    },
  ];
};

export const getOrderActiveTab = (active?: string) => {
  if (active === "part") return 2;
  return 0;
};
