import Modal from "Components/UI/Modal/Modal";
import { IActionPart } from "Shared/Types/action-item-part";
import React, { useMemo } from "react";
import OrderPartItem from "./OrderPartItem/OrderPartItem";
import { usePartListOperation } from "Shared/Hooks/order/part-list/use-part-list-operation";

type OrderActionItemPartProps = {
  open: boolean;
  setOpen: (open: boolean) => void;
  selectedParts: IActionPart[];
  setSelectedParts: (parts: IActionPart[]) => void;
};

const OrderActionItemPart: React.FC<OrderActionItemPartProps> = ({
  open,
  setOpen,
  selectedParts,
  setSelectedParts,
}) => {
  const { orderParts } = usePartListOperation({
    selectedParts,
    onClose: setOpen,
    setSelectedParts,
  });
  const filteredPartsByVendor = useMemo(() => {
    const vendorIds: string[] = [];
    selectedParts.forEach((part) => {
      if (part.vendor && !vendorIds.includes(part.vendor.id)) {
        vendorIds.push(part.vendor.id);
      }
    });

    return vendorIds.map((id) =>
      selectedParts.filter((part) => part.vendor?.id === id)
    );
  }, [selectedParts]);

  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      title="Order"
      isClosed
      onCancel={() => setOpen(false)}
    >
      {filteredPartsByVendor.map((filteredParts) => (
        <OrderPartItem
          key={filteredParts[0].vendor?.id}
          parts={filteredParts}
          orderParts={() => orderParts(filteredParts)}
        />
      ))}
    </Modal>
  );
};
export default OrderActionItemPart;
