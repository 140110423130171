import React from "react";
import styles from "./Tabs.module.scss";
import { Box, Tab, Tabs as MUITabs } from "@mui/material";
import cn from "classnames";

interface Tabs {
  tabs: string[];
  activeTab: number;
  onChange?: (index: number) => void;
  tabPanels: React.ReactNode[];
  type?: "oval" | "square";
}

const Tabs: React.FC<Tabs> = ({
  tabs,
  activeTab,
  onChange,
  tabPanels,
  type = "oval",
}) => {
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    onChange?.(newValue);
  };

  const tab_className = styles[`tab__${type}`];
  return (
    <div className={styles.tab}>
      <MUITabs
        value={activeTab}
        onChange={handleChange}
        classes={{ root: cn(styles.tabs, tab_className) }}
      >
        {tabs.map((tab, index) => (
          <Tab key={index} label={tab} />
        ))}
      </MUITabs>
      <Box mt={2}>
        {tabPanels.map((panel, index) => (
          <div key={index} hidden={activeTab !== index}>
            {panel}
          </div>
        ))}
      </Box>
    </div>
  );
};

export default Tabs;
