import React from "react";
import cn from "classnames";
import styles from "./Sidebar.module.scss";

type HrProps = {
  className?: string;
};

const Hr: React.FC<HrProps> = ({ className }) => {
  return <hr className={cn(styles.sidebar__hr, className)} />;
};
export default Hr;
