import { yupResolver } from "@hookform/resolvers/yup";
import { Grid } from "@mui/material";
import Form from "Components/UI/Form/Form";
import InputField from "Components/UI/Inputs/InputField";
import { ISubtype, ISubtypeAdd, ISubtypeUpdate } from "Shared/Types/subtype";
import React from "react";
import { useForm } from "react-hook-form";
import { ObjectSchema } from "yup";
import styles from "./SubtypeForm.module.scss";
import Button from "Components/UI/Button/Button";

type SubtypeFormProps = {
  onSubmit: (values: ISubtypeAdd | ISubtypeUpdate) => void;
  schema: ObjectSchema<ISubtypeAdd | ISubtypeUpdate>;
  subtype?: ISubtype;
};

const SubtypeForm: React.FC<SubtypeFormProps> = ({
  onSubmit,
  schema,
  subtype,
}) => {
  const { handleSubmit, control } = useForm({
    resolver: yupResolver(schema),
    defaultValues: subtype,
  });
  return (
    <Form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
      <Grid className={styles.block} container>
        <Grid item xs={12}>
          <InputField
            name="name"
            control={control}
            withController
            label="Name"
            required
          />
        </Grid>
        <Grid item xs={12}>
          <InputField
            name="description"
            control={control}
            withController
            label="Description"
          />
        </Grid>
      </Grid>
      <div className={styles.form__action}>
        {subtype ? (
          <Button variant="outlined" type="submit">
            Save
          </Button>
        ) : (
          <Button type="submit">Create</Button>
        )}
      </div>
    </Form>
  );
};
export default SubtypeForm;
