import * as Yup from "yup";
import { IUser } from "Shared/Types/user";
import { ILaborRate } from "Shared/Types/laborRate";
import { IService } from "Shared/Types/service";
import { IActionItem } from "Shared/Types/order";

export const actionItemSchema = Yup.object().shape({
  id: Yup.number(),
  index: Yup.string(),
  unit: Yup.string(),
  name: Yup.string(),
  complaint: Yup.mixed<IService | string>().required("Complaint is required"),
  is_done: Yup.boolean(),
  is_pre_authorize: Yup.boolean(),
  is_skip_diagnosis: Yup.boolean(),
  invoice_hours: Yup.number().required("Invoice hours is required"),
  technician: Yup.mixed<IUser>().nullable(),
  labor_rate: Yup.mixed<ILaborRate>().nullable(),
  service: Yup.mixed<IService>().nullable(),
});

export const actionItemInitialValues = {
  id: 0,
  key: 0,
  index: "0",
  unit: "",
  name: "",
  complaint: "",
  is_done: false,
  is_pre_authorize: false,
  is_skip_diagnosis: false,
  invoice_hours: 0,
  technician: null,
  labor_rate: null,
  service: null,
};

export const getActionItemAmount = (actionItem: IActionItem) => {
  let total = 0;
  total = +(actionItem.amount || 0);
  actionItem.action_item_part?.forEach(
    (part) => (total += (part.selling_price || 0) * part.quantity)
  );

  return total.toFixed(2);
};

export const checkActionItemNeedCalcTime = (status?: number) => {
  return status === 6 || status === 2;
};
