import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useAppDispatch } from "Redux/store";
import { selectCategoryList } from "Redux/PartSettings/Categories/slice";
import { selectManufacturerList } from "Redux/PartSettings/Manufacturer/slice";
import { getManufacturerList } from "Redux/PartSettings/Manufacturer/reducer";
import { getCategoryList } from "Redux/PartSettings/Categories/reducer";
import {
  partStatusOptions,
  unitOfMeasumenOptions,
} from "Shared/Constants/part";
import { OptionsProps } from "Components/UI/SelectField/SelectField";
import { IPartFieldKeys } from "Shared/Types/part";
import { selectLocationList } from "Redux/PartSettings/Location/slice";
import { getLocationList } from "Redux/PartSettings/Location/reducer";

type TSelectOption = OptionsProps;

export type TField = {
  type: "select" | "text" | "number" | "vendor-search";
  label?: string;
  // todo: need to remove
  name: IPartFieldKeys | "test";
  xs?: number;
  required?: boolean;
  options?: TSelectOption[];
  multiline?: boolean;
  rows?: number;
};

const usePartFields = () => {
  const dispatch = useAppDispatch();
  const categoryList = useSelector(selectCategoryList) || [];
  const manufacturerList = useSelector(selectManufacturerList) || [];
  const locationList = useSelector(selectLocationList);

  useEffect(() => {
    dispatch(getManufacturerList(null));
    dispatch(getCategoryList(null));
    dispatch(getLocationList(null));
  }, []);

  const fields: TField[] = [
    {
      name: "status",
      label: "Status",
      xs: 3,
      type: "select",
      required: true,
      options: partStatusOptions,
    },
    {
      name: "description",
      label: "Description",
      xs: 12,
      type: "text",
      required: true,
    },
    {
      name: "part_number",
      label: "Part #",
      xs: 6,
      type: "text",
    },
    {
      name: "category",
      label: "Category",
      xs: 6,
      type: "select",
      options: categoryList.map((category) => ({
        label: category.name,
        value: category.id,
      })),
    },
    {
      name: "manufacturer",
      label: "Manufacturer",
      xs: 6,
      type: "select",
      required: true,
      options: manufacturerList.map((manufacturer) => ({
        label: manufacturer.name,
        value: manufacturer.id,
      })),
    },
    {
      name: "price",
      label: "Price ($)",
      xs: 3,
      type: "number",
      required: true,
    },
    {
      name: "selling_price",
      label: "Selling price ($)",
      xs: 3,
      type: "number",
    },
    {
      name: "location",
      label: "Default Location",
      xs: 6,
      type: "select",
      options: locationList?.map((location) => ({
        label: location.name,
        value: location.id,
      })),
    },
    {
      name: "min_quantity",
      label: "Min qty",
      xs: 3,
      type: "number",
    },
    {
      name: "max_quantity",
      label: "Max qty",
      xs: 3,
      type: "number",
    },
    {
      name: "vendor",
      label: "Preferred vendor",
      xs: 6,
      type: "vendor-search",
    },
    {
      name: "initial_stock",
      label: "Initial In Stock",
      xs: 3,
      type: "number",
      required: true,
    },
    {
      name: "unit_of_measure",
      label: "Unit of measurement",
      xs: 3,
      type: "select",
      options: unitOfMeasumenOptions,
    },

    {
      name: "description", // нужно поменять на additional_info
      label: "Additional Info",
      xs: 12,
      multiline: true,
      rows: 4,
      type: "text",
    },
  ];

  return { fields };
};

export default usePartFields;
