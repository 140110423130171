import React, { Dispatch } from "react";
import Block from "Components/UI/Block/Block";

import styles from "./Amount.module.scss";
import { useSelector } from "react-redux";
import { selectAccount } from "Redux/Auth/slice";
import { getPurchaseOrderAmountTitle } from "Shared/Utils/purchase-order";
import Checkbox from "Components/UI/Inputs/Checkbox";
import usePurchaseOrderFields, {
  TReceivedField,
} from "Shared/Hooks/purchase-order/use-purchase-order-fields";
import { useFormContext } from "react-hook-form";
import InputField from "Components/UI/Inputs/InputField";
import cn from "classnames";
import { IPurchaseOrderLineItem } from "Shared/Types/purchase-order";

type AmountProps = {
  isApproved: boolean;
  setIsApproved: Dispatch<React.SetStateAction<boolean>>;
};

const Amount: React.FC<AmountProps> = ({ isApproved, setIsApproved }) => {
  const profile = useSelector(selectAccount);
  const { control, watch } = useFormContext();
  const { amountFields } = usePurchaseOrderFields();
  const subTotal = watch("sub_total") || 0;
  const discount = watch("credit_amount") || 0;
  const lineItems = watch("receive_line_items") || [];
  const subTotalCalculate = lineItems.reduce(
    (acc: number, item: IPurchaseOrderLineItem) =>
      acc + item.received_quantity * +(item.actual_cost || 0),
    0
  );
  const getSubTotal = () => {
    if (subTotal !== subTotalCalculate) {
      return subTotalCalculate;
    }
    return subTotal;
  };
  const renderInput = (field: TReceivedField) => {
    switch (field.type) {
      case "number":
        return (
          <div className={styles.amount__info}>
            <div className={styles.amount__info__label}>{field.label}</div>
            <div
              className={cn(field.isGreen && styles.amount__info__value_green)}
            >
              <InputField
                name={field.name}
                type="number"
                control={control}
                withController
                InputTheme="dark"
                borderColor="#F2F2F2"
              />
            </div>
          </div>
        );
      default:
        return (
          <div className={styles.amount__info}>
            <div className={styles.amount__info__label}>{field.label}</div>
            <div
              className={cn(
                styles.amount__info__value__info,
                field.isGreen && styles.amount__info__value_green
              )}
            >
              {field.name === "total" ? subTotal - discount : getSubTotal()}
            </div>
          </div>
        );
    }
  };

  return (
    <Block isBlur className={styles.amount}>
      <div className={styles.amount__head}>
        <p>{getPurchaseOrderAmountTitle(profile)}</p>
        <Checkbox
          name="approve"
          checked={isApproved}
          onChange={() => setIsApproved(!isApproved)}
        />
      </div>
      <div className={styles.amount__title}>Invoice</div>
      {amountFields.map((field) => (
        <div key={field.label}>{renderInput(field)}</div>
      ))}
    </Block>
  );
};
export default Amount;
