import React, { useEffect, useState } from "react";
import styles from "Containers/Settings/Rate/LaborRate/LaborRateList/LaborRate.module.scss";
import Table from "Components/UI/Table/Table";
import { taxRateTableHead } from "Shared/Constants/settings";
import { useNavigate } from "react-router";
import { useAppDispatch } from "Redux/store";
import { useSelector } from "react-redux";
import { deleteTaxRate, getTaxRateList } from "Redux/TaxRate/recuder";
import { selectTaxRateList } from "Redux/TaxRate/slice";
import { notifySuccess } from "Components/Notify/notify";
import DeleteModal from "Components/UI/DialogWindow/DeleteModal";
import { renderCell } from "Components/Settings/TaxRate/RenderCell/renderCell";
import IconButton from "Components/UI/IconButton/IconButton";
import { PlusIcon } from "Assets/index";

const TaxRateList = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const taxRateList = useSelector(selectTaxRateList) || [];

  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [deleteId, setDeleteId] = useState<string | null>();

  const handleDialogClose = () => {
    setOpenDeleteModal(false);
    setDeleteId(null);
  };

  const handleDialogOpen = (id: string) => {
    setOpenDeleteModal(true);
    setDeleteId(id);
  };

  const goToCreateNavigate = () => {
    navigate("/settings/rate-settings/tax-rate/new");
  };

  const handleDeleteTaxRate = async () => {
    const onSuccess = () => {
      notifySuccess("TaxRate is deleted");
      handleDialogClose();
      dispatch(getTaxRateList());
    };
    if (deleteId) {
      await dispatch(deleteTaxRate({ id: deleteId, onSuccess }));
    }
  };

  useEffect(() => {
    dispatch(getTaxRateList());
  }, []);

  return (
    <div className={styles.labor}>
      <div className={styles.labor__addBtn}>
        <IconButton
          onClick={goToCreateNavigate}
          label="Create"
          width={30}
          height={30}
          icon={PlusIcon}
        />
      </div>
      <Table
        title="Tax Rate"
        columns={taxRateTableHead}
        data={taxRateList}
        renderCell={renderCell}
        openDelete={handleDialogOpen}
      />
      <DeleteModal
        acceptTitle="Delete"
        open={openDeleteModal}
        handleClose={handleDialogClose}
        title="Delete"
        text="Are you sure...?"
        accept={handleDeleteTaxRate}
      />
    </div>
  );
};

export default TaxRateList;
