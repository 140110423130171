import React, { useState } from "react";
import styles from "../CalendarForSchedule.module.scss";
import "./Calendar.scss";
import { Controller, useFormContext } from "react-hook-form";
import Calendar from "react-calendar";
import { reformatData } from "Shared/Utils/reformatDate";
import cn from "classnames";
import { times } from "../mockTimeList";

const DateTimeCalendar = () => {
  const { getValues, control } = useFormContext();

  const [selectedDate, setSelectedDate] = useState(
    getValues("availability_date") || new Date(),
  );
  const [choosenTime, setChoosenTime] = useState("00:00:00");

  const formatTime = (time: string) => {
    return `${selectedDate.toISOString().split("T")[0]}T${time}.000Z`;
  };

  return (
    <Controller
      control={control}
      name="availability_date"
      defaultValue={new Date()}
      render={({ field }) => (
        <div className={`${styles.calendar} orange`}>
          <Calendar
            value={field.value}
            onChange={(selectedDate) => {
              if (selectedDate instanceof Date) {
                setSelectedDate(reformatData(selectedDate.toString()));
                field.onChange(
                  `${reformatData(selectedDate.toString())} ${choosenTime}`,
                );
              }
            }}
          />
          <div className={styles.calendar__times}>
            {times.map((el) => (
              <div
                onClick={() => {
                  if (selectedDate instanceof Date) {
                    const formattedTime = formatTime(el.value);
                    field.onChange(formattedTime);
                  } else {
                    field.onChange(`${reformatData(selectedDate)} ${el.value}`);
                  }
                  setChoosenTime(el.value);
                }}
                className={cn(
                  styles.calendar__times_time,
                  el.value === choosenTime &&
                    styles.calendar__times_time_active,
                )}
                key={el.id}
              >
                {el.time}
              </div>
            ))}
          </div>
        </div>
      )}
    />
  );
};

export default DateTimeCalendar;
