import { Grid } from "@mui/material";
import InfoField from "Components/UI/InfoField/InfoField";
import { IActionPart } from "Shared/Types/action-item-part";
import React, { useState } from "react";
import styles from "./PartInfo.module.scss";
import PartMore from "../PartMore/PartMore";
import cn from "classnames";
import { toCamelCase } from "Shared/Utils/app";
import DeleteModal from "Components/UI/DialogWindow/DeleteModal";
import Modal from "Components/UI/Modal/Modal";
import PartLocationModal from "Components/Order/ActionItemDetial/PartLocationModal/PartLocationModal";

type PartInfoProps = {
  part: IActionPart;
  handleEdited: () => void;
  openDeleteModal: () => void;
};

const PartInfo: React.FC<PartInfoProps> = ({
  part,
  handleEdited,
  openDeleteModal,
}) => {
  const [openLocationModal, setOpenLocationModal] = useState(false);
  const handleOpenModal = () => {
    setOpenLocationModal(true);
  };
  const handleCloseModal = () => {
    setOpenLocationModal(false);
  };

  const handleToUpdate = () => {
    //   TODO: add to update locations
    return;
  };

  return (
    <div className={styles.part}>
      <div className={styles.part__head}>
        {part ? (
          <>
            <div
              className={cn(
                styles.part__head__info__status,
                styles[
                  `part__head__info__status_${toCamelCase(part.status.name)}`
                ]
              )}
            >
              {part.status?.name}
            </div>
            <PartMore openDelete={openDeleteModal} onEdit={handleEdited} />
          </>
        ) : (
          <h3>Parts</h3>
        )}
      </div>
      <Grid container columnSpacing={1}>
        <Grid item xs={2}>
          <InfoField
            value={part.vendor?.vendor_name}
            label="Vendor"
            labelColor="white"
          />
        </Grid>
        <Grid item xs={2}>
          <InfoField
            value={part.description}
            label="Part name"
            labelColor="white"
          />
        </Grid>
        <Grid item xs={2}>
          <InfoField
            value={part.part_number}
            label="Part #"
            labelColor="white"
          />
        </Grid>
        <Grid item xs={2}>
          <div className={styles.part__location} onClick={handleOpenModal}>
            <InfoField
              value={"Part Location"}
              valueColor="lightBlue"
              label="Location"
              labelColor="white"
            />
          </div>
        </Grid>
        <Grid item xs={2}>
          <InfoField value={part.quantity} label="Qty" labelColor="white" />
        </Grid>
        <Grid item xs={2}>
          <InfoField
            value={part.price.toFixed(3)}
            label="Price ($)"
            labelColor="white"
          />
        </Grid>
      </Grid>
      <PartLocationModal
        open={openLocationModal}
        closeModal={handleCloseModal}
        handleToUpdate={handleToUpdate}
      />
    </div>
  );
};
export default PartInfo;
