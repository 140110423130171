import Layout from "Components/Layout/Layout";
import React, { useState } from "react";
import ReportingPageImg from "Assets/ReportingPage.png";
import homePage from "Assets/HomePage.png";
import InDevelopmentModal from "Components/InDevelopmentModal";

export const ReportingPage: React.FC = () => {
  const [openModal, setOpenModal] = useState(false);
  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);

  const OpenModal = () => {
    handleOpenModal();
    setTimeout(handleCloseModal, 3000);
  };

  return (
    <Layout>
      <img onClick={OpenModal} src={ReportingPageImg} alt="/" />
      <InDevelopmentModal
        handleModal={handleCloseModal}
        openModal={openModal}
      />
    </Layout>
  );
};
