import React, { useState } from "react";
import { IActionPart } from "Shared/Types/action-item-part";
import AddQuoteModal from "../AddQuoteModal/AddQuoteModal";
import styles from "./PartActions.module.scss";
import OrderActionItemPart from "../OrderActionItemPart/OrderActionItemPart";
import { IOrder } from "Shared/Types/order";
import ReceiveActionItemPart from "../ReceivePartModal/RecievePartModal";
import ActionItem from "./ActionItem/ActionItem";

type PartActionsProps = {
  selectedParts: IActionPart[];
  setSelectedParts: (parts: IActionPart[]) => void;
  order: IOrder;
};

const PartActions: React.FC<PartActionsProps> = ({
  selectedParts,
  setSelectedParts,
}) => {
  const [showAddQuote, setShowAddQuote] = useState(false);
  const [showOrderModal, setShowOrderModal] = useState(false);
  const [showReceiveModal, setShowReceiveModal] = useState(false);
  const openAddQuote = () => {
    setShowAddQuote(true);
  };
  const openOrderPart = () => {
    setShowOrderModal(true);
  };
  const recievePart = () => {
    setShowReceiveModal(true);
  };
  const btns = [
    { title: "Add Quote", onClick: openAddQuote, status: 1 },
    { title: "Order", onClick: openOrderPart, status: 4 },
    { title: "Receive", onClick: recievePart, status: 5 },
  ];
  return (
    <div className={styles.action}>
      {btns.map(({ title, onClick, status }) => (
        <ActionItem
          key={title}
          title={title}
          status={status}
          onClick={onClick}
          selectedParts={selectedParts}
        />
      ))}
      <AddQuoteModal
        open={showAddQuote}
        setOpen={setShowAddQuote}
        selectedParts={selectedParts}
        setSelectedParts={setSelectedParts}
      />
      <OrderActionItemPart
        open={showOrderModal}
        setOpen={setShowOrderModal}
        selectedParts={selectedParts}
        setSelectedParts={setSelectedParts}
      />
      <ReceiveActionItemPart
        open={showReceiveModal}
        setOpen={setShowReceiveModal}
        selectedParts={selectedParts}
        setSelectedParts={setSelectedParts}
      />
    </div>
  );
};
export default PartActions;
