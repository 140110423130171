export const PERMISSIONS = {
  add_actionitem: "add_actionitem",
  add_actionitemfile: "add_actionitemfile",
  add_actionitemgallery: "add_actionitemgallery",
  add_actionitemhistory: "add_actionitemhistory",
  add_actionitemnote: "add_actionitemnote",
  add_actionitempart: "add_actionitempart",
  add_category: "add_category",
  add_company: "add_company",
  add_companyrequest: "add_companyrequest",
  add_component: "add_component",
  add_contact: "add_contact",
  add_globalrole: "add_globalrole",
  add_globalrolepermission: "add_globalrolepermission",
  add_laborrate: "add_laborrate",
  add_location: "add_location",
  add_manufacturer: "add_manufacturer",
  add_notification: "add_notification",
  add_order: "add_order",
  add_orderfile: "add_orderfile",
  add_ordergallery: "add_ordergallery",
  add_orderhistory: "add_orderhistory",
  add_ordernote: "add_ordernote",
  add_part: "add_part",
  add_partlocation: "add_partlocation",
  add_permission: "add_permission",
  add_role: "add_role",
  add_rolepermission: "add_rolepermission",
  add_serviceorderinvoice: "add_serviceorderinvoice",
  add_serviceorderstatus: "add_serviceorderstatus",
  add_shop: "add_shop",
  add_shopservice: "add_shopservice",
  add_soactionitemstatus: "add_soactionitemstatus",
  add_soinnerhistory: "add_soinnerhistory",
  add_sopartstatus: "add_sopartstatus",
  add_sourgencyoption: "add_sourgencyoption",
  add_subtype: "add_subtype",
  add_supplyrate: "add_supplyrate",
  add_system: "add_system",
  add_taxrate: "add_taxrate",
  add_type: "add_type",
  add_typeofchassis: "add_typeofchassis",
  add_unit: "add_unit",
  add_user: "add_user",
  add_vendor: "add_vendor",
  change_actionitem: "change_actionitem",
  change_actionitemfile: "change_actionitemfile",
  change_actionitemgallery: "change_actionitemgallery",
  change_actionitemhistory: "change_actionitemhistory",
  change_actionitemnote: "change_actionitemnote",
  change_actionitempart: "change_actionitempart",
  change_category: "change_category",
  change_company: "change_company",
  change_companyrequest: "change_companyrequest",
  change_component: "change_component",
  change_contact: "change_contact",
  change_globalrole: "change_globalrole",
  change_globalrolepermission: "change_globalrolepermission",
  change_laborrate: "change_laborrate",
  change_location: "change_location",
  change_manufacturer: "change_manufacturer",
  change_notification: "change_notification",
  change_order: "change_order",
  change_orderfile: "change_orderfile",
  change_ordergallery: "change_ordergallery",
  change_orderhistory: "change_orderhistory",
  change_ordernote: "change_ordernote",
  change_part: "change_part",
  change_partlocation: "change_partlocation",
  change_permission: "change_permission",
  change_role: "change_role",
  change_rolepermission: "change_rolepermission",
  change_serviceorderinvoice: "change_serviceorderinvoice",
  change_serviceorderstatus: "change_serviceorderstatus",
  change_shop: "change_shop",
  change_shopservice: "change_shopservice",
  change_soactionitemstatus: "change_soactionitemstatus",
  change_soinnerhistory: "change_soinnerhistory",
  change_sopartstatus: "change_sopartstatus",
  change_sourgencyoption: "change_sourgencyoption",
  change_subtype: "change_subtype",
  change_supplyrate: "change_supplyrate",
  change_system: "change_system",
  change_taxrate: "change_taxrate",
  change_type: "change_type",
  change_typeofchassis: "change_typeofchassis",
  change_unit: "change_unit",
  change_user: "change_user",
  change_vendor: "change_vendor",
  delete_actionitem: "delete_actionitem",
  delete_actionitemfile: "delete_actionitemfile",
  delete_actionitemgallery: "delete_actionitemgallery",
  delete_actionitemhistory: "delete_actionitemhistory",
  delete_actionitemnote: "delete_actionitemnote",
  delete_actionitempart: "delete_actionitempart",
  delete_category: "delete_category",
  delete_company: "delete_company",
  delete_companyrequest: "delete_companyrequest",
  delete_component: "delete_component",
  delete_contact: "delete_contact",
  delete_globalrole: "delete_globalrole",
  delete_globalrolepermission: "delete_globalrolepermission",
  delete_laborrate: "delete_laborrate",
  delete_location: "delete_location",
  delete_manufacturer: "delete_manufacturer",
  delete_notification: "delete_notification",
  delete_order: "delete_order",
  delete_orderfile: "delete_orderfile",
  delete_ordergallery: "delete_ordergallery",
  delete_orderhistory: "delete_orderhistory",
  delete_ordernote: "delete_ordernote",
  delete_part: "delete_part",
  delete_partlocation: "delete_partlocation",
  delete_permission: "delete_permission",
  delete_role: "delete_role",
  delete_rolepermission: "delete_rolepermission",
  delete_serviceorderinvoice: "delete_serviceorderinvoice",
  delete_serviceorderstatus: "delete_serviceorderstatus",
  delete_shop: "delete_shop",
  delete_shopservice: "delete_shopservice",
  delete_soactionitemstatus: "delete_soactionitemstatus",
  delete_soinnerhistory: "delete_soinnerhistory",
  delete_sopartstatus: "delete_sopartstatus",
  delete_sourgencyoption: "delete_sourgencyoption",
  delete_subtype: "delete_subtype",
  delete_supplyrate: "delete_supplyrate",
  delete_system: "delete_system",
  delete_taxrate: "delete_taxrate",
  delete_type: "delete_type",
  delete_typeofchassis: "delete_typeofchassis",
  delete_unit: "delete_unit",
  delete_user: "delete_user",
  delete_vendor: "delete_vendor",
  view_actionitem: "view_actionitem",
  view_actionitemfile: "view_actionitemfile",
  view_actionitemgallery: "view_actionitemgallery",
  view_actionitemhistory: "view_actionitemhistory",
  view_actionitemnote: "view_actionitemnote",
  view_actionitempart: "view_actionitempart",
  view_category: "view_category",
  view_company: "view_company",
  view_companyrequest: "view_companyrequest",
  view_component: "view_component",
  view_contact: "view_contact",
  view_globalrole: "view_globalrole",
  view_globalrolepermission: "view_globalrolepermission",
  view_laborrate: "view_laborrate",
  view_location: "view_location",
  view_manufacturer: "view_manufacturer",
  view_notification: "view_notification",
  view_order: "view_order",
  view_orderfile: "view_orderfile",
  view_ordergallery: "view_ordergallery",
  view_orderhistory: "view_orderhistory",
  view_ordernote: "view_ordernote",
  view_part: "view_part",
  view_partlocation: "view_partlocation",
  view_permission: "view_permission",
  view_role: "view_role",
  view_rolepermission: "view_rolepermission",
  view_serviceorderinvoice: "view_serviceorderinvoice",
  view_serviceorderstatus: "view_serviceorderstatus",
  view_shop: "view_shop",
  view_shopservice: "view_shopservice",
  view_soactionitemstatus: "view_soactionitemstatus",
  view_soinnerhistory: "view_soinnerhistory",
  view_sopartstatus: "view_sopartstatus",
  view_sourgencyoption: "view_sourgencyoption",
  view_subtype: "view_subtype",
  view_supplyrate: "view_supplyrate",
  view_system: "view_system",
  view_taxrate: "view_taxrate",
  view_type: "view_type",
  view_typeofchassis: "view_typeofchassis",
  view_unit: "view_unit",
  view_user: "view_user",
  view_vendor: "view_vendor",
};

export const permissionsTitle: { [key: string]: string } = {
  add_company: "Can add Company",
  change_company: "Can change Company",
  delete_company: "Can delete Company",
  view_company: "Can view Company",
  add_companyrequest: "Can add Company request",
  change_companyrequest: "Can change Company request",
  delete_companyrequest: "Can delete Company request",
  view_companyrequest: "Can view Company request",
  add_laborrate: "Can add Labor rate",
  change_laborrate: "Can change Labor rate",
  delete_laborrate: "Can delete Labor rate",
  view_laborrate: "Can view Labor rate",
  add_shop: "Can add Shop",
  change_shop: "Can change Shop",
  delete_shop: "Can delete Shop",
  view_shop: "Can view Shop",
  add_sourgencyoption: "Can add SO Urgency Option",
  change_sourgencyoption: "Can change SO Urgency Option",
  delete_sourgencyoption: "Can delete SO Urgency Option",
  view_sourgencyoption: "Can view SO Urgency Option",
  add_supplyrate: "Can add Supply Rate",
  change_supplyrate: "Can change Supply Rate",
  delete_supplyrate: "Can delete Supply Rate",
  view_supplyrate: "Can view Supply Rate",
  add_taxrate: "Can add Tax Rate",
  change_taxrate: "Can change Tax Rate",
  delete_taxrate: "Can delete Tax Rate",
  view_taxrate: "Can view Tax Rate",
  add_actionitem: "Can add Action Item",
  change_actionitem: "Can change Action Item",
  delete_actionitem: "Can delete Action Item",
  view_actionitem: "Can view Action Item",
  add_actionitemfile: "Can add ActionItem File",
  change_actionitemfile: "Can change ActionItem File",
  delete_actionitemfile: "Can delete ActionItem File",
  view_actionitemfile: "Can view ActionItem File",
  add_actionitemgallery: "Can add ActionItem Gallery",
  change_actionitemgallery: "Can change ActionItem Gallery",
  delete_actionitemgallery: "Can delete ActionItem Gallery",
  view_actionitemgallery: "Can view ActionItem Gallery",
  add_actionitemhistory: "Can add action item history",
  change_actionitemhistory: "Can change action item history",
  delete_actionitemhistory: "Can delete action item history",
  view_actionitemhistory: "Can view action item history",
  add_actionitemnote: "Can add action item note",
  change_actionitemnote: "Can change action item note",
  delete_actionitemnote: "Can delete action item note",
  view_actionitemnote: "Can view action item note",
  add_actionitempart: "Can add Action Item Part",
  change_actionitempart: "Can change Action Item Part",
  delete_actionitempart: "Can delete Action Item Part",
  view_actionitempart: "Can view Action Item Part",
  add_notification: "Can add Notification",
  change_notification: "Can change Notification",
  delete_notification: "Can delete Notification",
  view_notification: "Can view Notification",
  add_order: "Can add Order",
  change_order: "Can change Order",
  delete_order: "Can delete Order",
  view_order: "Can view Order",
  add_orderfile: "Can add Order File",
  change_orderfile: "Can change Order File",
  delete_orderfile: "Can delete Order File",
  view_orderfile: "Can view Order File",
  add_ordergallery: "Can add Order Gallery",
  change_ordergallery: "Can change Order Gallery",
  delete_ordergallery: "Can delete Order Gallery",
  view_ordergallery: "Can view Order Gallery",
  add_orderhistory: "Can add order history",
  change_orderhistory: "Can change order history",
  delete_orderhistory: "Can delete order history",
  view_orderhistory: "Can view order history",
  add_ordernote: "Can add order note",
  change_ordernote: "Can change order note",
  delete_ordernote: "Can delete order note",
  view_ordernote: "Can view order note",
  add_serviceorderinvoice: "Can add Service Order Invoice",
  change_serviceorderinvoice: "Can change Service Order Invoice",
  delete_serviceorderinvoice: "Can delete Service Order Invoice",
  view_serviceorderinvoice: "Can view Service Order Invoice",
  add_serviceorderstatus: "Can add service order status",
  change_serviceorderstatus: "Can change service order status",
  delete_serviceorderstatus: "Can delete service order status",
  view_serviceorderstatus: "Can view service order status",
  add_soactionitemstatus: "Can add so action item status",
  change_soactionitemstatus: "Can change so action item status",
  delete_soactionitemstatus: "Can delete so action item status",
  view_soactionitemstatus: "Can view so action item status",
  add_soinnerhistory: "Can add so inner history",
  change_soinnerhistory: "Can change so inner history",
  delete_soinnerhistory: "Can delete so inner history",
  view_soinnerhistory: "Can view so inner history",
  add_sopartstatus: "Can add so part status",
  change_sopartstatus: "Can change so part status",
  delete_sopartstatus: "Can delete so part status",
  view_sopartstatus: "Can view so part status",
  add_category: "Can add Category",
  change_category: "Can change Category",
  delete_category: "Can delete Category",
  view_category: "Can view Category",
  add_location: "Can add Location",
  change_location: "Can change Location",
  delete_location: "Can delete Location",
  view_location: "Can view Location",
  add_manufacturer: "Can add Manufacturer",
  change_manufacturer: "Can change Manufacturer",
  delete_manufacturer: "Can delete Manufacturer",
  view_manufacturer: "Can view Manufacturer",
  add_part: "Can add Part",
  change_part: "Can change Part",
  delete_part: "Can delete Part",
  view_part: "Can view Part",
  add_partlocation: "Can add Part Location",
  change_partlocation: "Can change Part Location",
  delete_partlocation: "Can delete Part Location",
  view_partlocation: "Can view Part Location",
  add_type: "Can add Type",
  change_type: "Can change Type",
  delete_type: "Can delete Type",
  view_type: "Can view Type",
  add_vendor: "Can add Vendor",
  change_vendor: "Can change Vendor",
  delete_vendor: "Can delete Vendor",
  view_vendor: "Can view Vendor",
  add_component: "Can add Component",
  change_component: "Can change Component",
  delete_component: "Can delete Component",
  view_component: "Can view Component",
  add_shopservice: "Can add Shop service",
  change_shopservice: "Can change Shop service",
  delete_shopservice: "Can delete Shop service",
  view_shopservice: "Can view Shop service",
  add_system: "Can add System",
  change_system: "Can change System",
  delete_system: "Can delete System",
  view_system: "Can view System",
  add_subtype: "Can add Subtype",
  change_subtype: "Can change Subtype",
  delete_subtype: "Can delete Subtype",
  view_subtype: "Can view Subtype",
  add_typeofchassis: "Can add Type Of Chassis",
  change_typeofchassis: "Can change Type Of Chassis",
  delete_typeofchassis: "Can delete Type Of Chassis",
  view_typeofchassis: "Can view Type Of Chassis",
  add_unit: "Can add Unit",
  change_unit: "Can change Unit",
  delete_unit: "Can delete Unit",
  view_unit: "Can view Unit",
  add_contact: "Can add Contact",
  change_contact: "Can change Contact",
  delete_contact: "Can delete Contact",
  view_contact: "Can view Contact",
  add_role: "Can add role",
  change_role: "Can change role",
  delete_role: "Can delete role",
  view_role: "Can view role",
  view_rolepermission: "Can view role permission",
  add_user: "Can add User",
  change_user: "Can change User",
  delete_user: "Can delete User",
  view_user: "Can view User",
};

export const settingLabels = [
  "Proceed to set your service rates accurately.",
  "Define pricing for your shop services.",
  "Labor rate",
  "Tax Location Information",
  "Supply Rate Information",
  "Role & Access",
];

export const serviceLabels = [
  "List the services your shop offers, add services provided by your shop.",
];

export const unitSettingsLabels = [
  "Configure your preferred measurement units to ensure consistency in your service offerings",
];
export const customerLabels = [
  "Manage your customer database.",
  "Add and organize client information for efficient communication and tracking",
];

export const vendorLabels = [
  "Connect with your suppliers. Add and manage your relationships with parts and equipment suppliers.",
];

export const STATUS_COLOR = {
  active: {
    title: "Active",
    color: "green",
  },
  inactive: {
    title: "Inactive",
    color: "lightRed",
  },
};

export const SHOP_NOT_ASSIGNED =
  "You can't create purchase order, because you don't assign to shop";
