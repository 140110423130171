import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import {
  FieldValues,
  UseFieldArrayAppend,
  useFormContext,
} from "react-hook-form";
import { useAppDispatch } from "Redux/store";
import styles from "./ComplaintService.module.scss";
import {
  createEstimateService,
  updateEstimateService,
} from "Redux/Estimate/reducer";
import { notifySuccess } from "Components/Notify/notify";
import ComplaintServiceInput from "Components/Order/Estimate/EstimateForm/ComplaintServiceForm/ComplaintServiceFields/ComplaintServiceInput";
import ComplaintServiceActionButton from "Components/Order/Estimate/EstimateForm/ComplaintServiceForm/ComplaintServiceFields/ComplaintServiceActionButton";
import { estimatesServiceRequiredFields } from "Shared/Utils/estimate";
import { IEstimatesService } from "Shared/Types/estimate";
import { useSelector } from "react-redux";
import { selectLaborRate } from "Redux/LaborRate/slice";

type ComplaintServiceFormProps = {
  index: number;
  append: UseFieldArrayAppend<FieldValues, `action_items.${number}.part`>;
  isDetails: boolean;
};

const ComplaintServiceForm: React.FC<ComplaintServiceFormProps> = ({
  index,
  append,
  isDetails,
}) => {
  const laborRate = useSelector(selectLaborRate);
  const dispatch = useAppDispatch();
  const { setValue, getValues } = useFormContext();

  const [price, setPrice] = useState(laborRate?.rate || 0);
  const [isEdit, setIsEdit] = useState(false);
  const [isAdded, setIsAdded] = useState(false);

  const isRequiredFieldsFilled = () =>
    estimatesServiceRequiredFields.every(
      (key) => getValues(`action_items.${index}.service`)[key],
    );
  const setComplaintValue = (field: string, value?: string | number) => {
    setValue(`action_items.${index}.service.${field}`, value);
  };

  const onSuccess = () => {
    notifySuccess("Estimate Service is created");
    append({});
  };

  const addEstimateService = async () => {
    const values = getValues(`action_items.${index}.service`);
    const response = await dispatch(
      createEstimateService({
        data: values,
        onSuccess,
      }),
    );
    setComplaintValue("id", response.payload.id);
    setComplaintValue("servicePriceTotal", price);
    setIsAdded(true);
  };

  const handleUpdateEstimateService = () => {
    const values: IEstimatesService = getValues(
      `action_items.${index}.service`,
    );
    if (values.id) {
      dispatch(
        updateEstimateService({
          id: values.id,
          data: {
            ...values,
          },
          onSuccess: () => notifySuccess("Estimate Service is updated"),
        }),
      );
    }
    setIsEdit(false);
  };

  useEffect(() => {
    if (!isAdded && isRequiredFieldsFilled()) {
      addEstimateService();
    }
  }, [isRequiredFieldsFilled(), isAdded]);

  return (
    <Grid container spacing={2} className={styles.form}>
      <ComplaintServiceInput
        index={index}
        price={price}
        setPrice={setPrice}
        setComplaintValue={setComplaintValue}
        isDisabledField={isAdded && !isEdit}
      />
      <ComplaintServiceActionButton
        isEdit={isEdit}
        isAdded={isAdded}
        isDetails={isDetails}
        setIsEdit={setIsEdit}
        addEstimateService={addEstimateService}
        updateEstimateService={handleUpdateEstimateService}
      />
    </Grid>
  );
};

export default ComplaintServiceForm;
