import { client } from "Service/axios";
import { SearchResult } from "Shared/Types/common";
import {
  IActionPart,
  IActionPartApi,
  IActionPartUpdateApi,
  IActionPartWaitinOnVendor,
} from "Shared/Types/action-item-part";
import { IOrderStatus } from "Shared/Types/order";

const ACTION_PART_URL = "order/action_item_part/";

class ActionPart {
  getActionPartList() {
    return client.get<SearchResult<IActionPart>>(`${ACTION_PART_URL}`);
  }
  getActionPart(id: string) {
    return client.get<IActionPart>(`${ACTION_PART_URL}${id}/`);
  }
  addActionPart(data: IActionPartApi) {
    return client.post(`${ACTION_PART_URL}create/`, data);
  }
  updateActionPart(id: string, data: IActionPartUpdateApi) {
    return client.patch(`${ACTION_PART_URL}${id}/update/`, data);
  }
  updateActionPartField(id: string, data: IActionPartUpdateApi) {
    return client.patch(`${ACTION_PART_URL}${id}/update/`, data);
  }
  deleteActionPart(id: string) {
    return client.delete(`${ACTION_PART_URL}${id}/delete/`);
  }
  waitingOnVendor(id: string, data: IActionPartWaitinOnVendor) {
    return client.post(`${ACTION_PART_URL}${id}/waiting_on_vendor/`, data);
  }
  getActionItemPartStatuses() {
    return client.get<IOrderStatus[]>(`${ACTION_PART_URL}status`);
  }
}

export default ActionPart;
