import React, { useEffect } from "react";
import { Grid } from "@mui/material";
import InputField from "Components/UI/Inputs/InputField";
import SelectField from "Components/UI/SelectField/SelectField";
import styles from "./EmployeeForm.module.scss";
import { useSelector } from "react-redux";
import {
  selectCities,
  selectCitiesLoading,
  selectCountries,
  selectCountriesLoading,
  selectStates,
  selectStatesLoading,
} from "Redux/Address/slice";
import { useAppDispatch } from "Redux/store";
import { getCities, getCountries, getStates } from "Redux/Address/reducer";
import { Control } from "react-hook-form";
import cn from "classnames";
import { AddressFields } from "Components/AddressField/AddressField";

type AddressInfoProps = {
  control: Control<any>;
};
const AddressInfo: React.FC<AddressInfoProps> = ({ control }) => {
  return (
    <div className={cn(styles.block, styles.block__3)}>
      <div className={styles.form__address}>
        <h4 className={styles.form__address__title}>Address Information</h4>
        <Grid container spacing={1} rowSpacing={-1}>
          <AddressFields control={control} isNeedPostalCode />
        </Grid>
      </div>
    </div>
  );
};
export default AddressInfo;
