import IconButton from "Components/UI/IconButton/IconButton";
import React, { MouseEvent } from "react";
import {
  EditIcon,
  DeleteIcon,
  CheckCircleIcon,
  CircleIcon,
} from "Assets/index";
import { useNavigate } from "react-router";
import Icon from "Components/UI/Icon/Icon";
import { ILaborRate } from "Shared/Types/laborRate";
import styles from "./renderCell.module.scss";

export const renderCell = (
  cellData: any,
  columnId: string,
  laborRate: ILaborRate,
  openDelete?: (id: string) => void
) => {
  const navigate = useNavigate();

  const handleClick = (event: MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
    navigate(`/settings/rate-settings/labor-rate/${laborRate.id}`);
  };

  const handleShowDelete = (event: MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
    openDelete?.(laborRate.id);
  };

  if (columnId === "default") {
    return laborRate.default ? (
      <Icon
        component={CheckCircleIcon}
        className={styles.checkCricle}
        width={30}
        height={30}
      />
    ) : (
      <Icon component={CircleIcon} width={30} height={30} />
    );
  }

  if (columnId === "action") {
    return (
      <div className={styles.buttons}>
        <IconButton icon={EditIcon} onClick={handleClick} />
        <IconButton icon={DeleteIcon} onClick={handleShowDelete} />
      </div>
    );
  }
  return cellData;
};
