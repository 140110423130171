import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "Service/api/api";
import { readObj } from "Service/storage";
import {
  IDispatchCallback,
  IDispatchCallbackWithId,
  IDispatchCallbackWithoutData,
} from "Shared/Types/common";
import {
  IManufacturerSearchParams,
  IManufacturerAdd,
  IManufacturerUpdate,
} from "Shared/Types/manufacturer";
import { errorHandler } from "Shared/Utils/app";

const getManufacturerList = createAsyncThunk(
  "manufacturer/getManufacturerList",
  async (params: IManufacturerSearchParams | null) => {
    try {
      const shop = readObj("current_shop");
      const response = await api.manufacturer.getManufacturersList({
        ...params,
        shop: shop?.id,
      });
      return response.data;
    } catch (error) {
      if (error instanceof Error) {
        errorHandler(error);
      }
      throw error;
    }
  }
);

const getManufacturer = createAsyncThunk(
  "manufacturer/getManufacturer",
  async (id: string) => {
    try {
      const response = await api.manufacturer.getManufacturer(id);
      return response.data;
    } catch (error) {
      if (error instanceof Error) {
        errorHandler(error);
      }
      throw error;
    }
  }
);

const addManufacturer = createAsyncThunk(
  "manufacturer/addManufacturer",
  async ({ data, onSuccess }: IDispatchCallback<IManufacturerAdd>) => {
    try {
      const response = await api.manufacturer.addManufacturer(data);
      onSuccess?.();
      return response.data;
    } catch (error) {
      if (error instanceof Error) {
        errorHandler(error, true);
      }
      throw error;
    }
  }
);

const updateManufacturer = createAsyncThunk(
  "manufacturer/updateManufacturer",
  async ({
    id,
    data,
    onSuccess,
  }: IDispatchCallbackWithId<IManufacturerUpdate>) => {
    try {
      const response = await api.manufacturer.updateManufacturer(id, data);
      onSuccess?.();
      return response.data;
    } catch (error) {
      if (error instanceof Error) {
        errorHandler(error, true);
      }
      throw error;
    }
  }
);

const deleteManufacturer = createAsyncThunk(
  "manufacturer/deleteManufacturer",
  async ({ id, onSuccess }: IDispatchCallbackWithoutData) => {
    try {
      const response = await api.manufacturer.deleteManufacturer(id);
      onSuccess?.();
      return response.data;
    } catch (error) {
      if (error instanceof Error) {
        errorHandler(error, true);
      }
      throw error;
    }
  }
);

export {
  getManufacturerList,
  getManufacturer,
  addManufacturer,
  updateManufacturer,
  deleteManufacturer,
};
