import React from "react";
import AuthLayout2 from "Components/Layout/AuthLayout/AuthLayout";
import InputField from "Components/Layout/Inputs/InputField";
import Button from "Components/Layout/Button/Button";
import { useAppDispatch } from "Redux/store";
import { resetPassword } from "Redux/Auth/reducers";
import { useNavigate, useParams } from "react-router";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import styles from "Containers/Auth/Login.module.scss";
import Form from "Components/UI/Form/Form";
import { notifySuccess } from "Components/Notify/notify";
import { useSelector } from "react-redux";
import { selectAuthLoading } from "Redux/Auth/slice";
import BackToLogin from "Components/Layout/AuthLayout/BackToLogin/BackToLogin";
import { RESET_PASSWORD } from "Shared/Constants/auth";
import { resetPasswordSchema } from "Shared/Utils/auth";
import TermsOfSevice from "Components/Layout/AuthLayout/TermsOfService/TermsOfSevice";

const ResetPasswordContainer = () => {
  const dispatch = useAppDispatch();
  const loading = useSelector(selectAuthLoading);
  const { uid, token } = useParams();
  const navigate = useNavigate();

  const { control, handleSubmit } = useForm({
    resolver: yupResolver(resetPasswordSchema),
  });

  const onSuccessfullyReset = () => {
    navigate("/login");
    notifySuccess("Password successfully reset");
  };

  const handleResetPassword = ({
    password,
  }: {
    password: string;
    confirm_password: string;
  }) => {
    if (uid && token) {
      const data = {
        new_password: password,
        uid: uid,
        token: token,
      };
      dispatch(resetPassword({ data, onSuccess: onSuccessfullyReset }));
    }
  };

  return (
    <AuthLayout2
      title={RESET_PASSWORD.TITLE}
      descrption={RESET_PASSWORD.DESCRIPTION}
    >
      <Form
        onSubmit={handleSubmit(handleResetPassword)}
        className={styles.login}
      >
        <InputField
          isPassword
          placeholder="New Password"
          name="password"
          required
          control={control}
          withController
        />
        <InputField
          isPassword
          placeholder="Confirm Password"
          name="confirm_password"
          required
          control={control}
          withController
        />
        <Button
          loading={loading}
          variant="contained"
          color="primary"
          type="submit"
          fullWidth
        >
          Confirm
        </Button>
        <p>Did not receive the link? We can resend it.</p>
        <Button
          variant="contained"
          color="white"
          type="submit"
          fullWidth
          loading={loading}
        >
          Resend
        </Button>
        <BackToLogin />
      </Form>
      <TermsOfSevice />
    </AuthLayout2>
  );
};

export default ResetPasswordContainer;
