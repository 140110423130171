import React from "react";
import RadarChart from "Components/Diagrams/RadarDiagram";
import OverviewCard from "Containers/Overview/OverviewCard/OverviewCard";
import styles from "./RequestOverview.module.scss";
import CurveChart from "Components/Diagrams/CurveDiagram";
import GaugeChart from "Components/Diagrams/RadialChart";

const RequestOverview: React.FC = () => {
  //   TODO: replace hardcoded values
  const indicators = [
    { name: "Avg Time to pay \n" + "Invoice", max: 300 },
    { name: "SO Total\n" + "Value", max: 450 },
    { name: "Completed", max: 350 },
    { name: "Total Past \n" + "Due Invoices", max: 10 },
    { name: "Total Invoice\n" + "Due", max: 200 },
  ];

  return (
    <div className={styles.overview}>
      <div className={styles.overview__container}>
        <RadarChart
          indicators={indicators}
          className={styles.overview__chart}
        />
        <div className={styles.overview__stats}>
          <OverviewCard hour="24" title="To Pay Invoiced Hours" percentage="21">
            <CurveChart />
          </OverviewCard>
          <OverviewCard
            hour="12"
            title="Completed but not Invoiced"
            percentage="2"
          >
            <GaugeChart percentage={30} />
          </OverviewCard>
          <OverviewCard
            hour="24"
            title="Total Past Due Invoices "
            percentage="15"
          >
            <GaugeChart percentage={80} />
          </OverviewCard>
        </div>
      </div>
    </div>
  );
};

export default RequestOverview;
