import React from "react";
import { Grid } from "@mui/material";
import IconButton from "Components/UI/IconButton/IconButton";
import { EditIcon } from "Assets/index";
import Button from "Components/UI/Button/Button";

interface IComplaintServiceActionButtonProps {
  isEdit: boolean;
  isAdded: boolean;
  isDetails: boolean;
  setIsEdit: React.Dispatch<React.SetStateAction<boolean>>;
  addEstimateService: () => Promise<void>;
  updateEstimateService: () => void;
}

const ComplaintServiceActionButton: React.FC<
  IComplaintServiceActionButtonProps
> = ({
  isEdit,
  isAdded,
  isDetails,
  setIsEdit,
  addEstimateService,
  updateEstimateService,
}) => {
  return (
    <Grid
      container
      item
      xs={1}
      display={"flex"}
      justifyContent="flex-end"
      gap={2}
    >
      {isAdded && !isEdit && (
        <Grid
          item
          xs={2}
          display={"flex"}
          alignItems="end"
          justifyContent="flex-end"
          marginRight={"12px"}
          marginBottom={"4px"}
        >
          <IconButton icon={EditIcon} onClick={() => setIsEdit(true)} />
        </Grid>
      )}
      {isAdded && isEdit && (
        <Grid
          item
          xs={2}
          display={"flex"}
          alignItems="end"
          justifyContent="flex-end"
          marginRight={"12px"}
          marginBottom={"4px"}
        >
          <Button onClick={updateEstimateService}>Save</Button>
        </Grid>
      )}
      {isDetails && !isAdded && (
        <Grid
          item
          xs={4}
          display={"flex"}
          alignItems="end"
          justifyContent="flex-end"
          marginRight={"12px"}
          marginBottom={"4px"}
        >
          <Button onClick={addEstimateService}>Add</Button>
        </Grid>
      )}
    </Grid>
  );
};

export default ComplaintServiceActionButton;
