import { createSlice } from "@reduxjs/toolkit";
import { IOrderNotification } from "Shared/Types/order";
import { getOrderNotification, markAsReadedNotification } from "./reducer";
import { RootState } from "Redux/store";

type NotificationState = {
  loading: boolean;
  notificationList: IOrderNotification[];
};

const initialState: NotificationState = {
  loading: false,
  notificationList: [],
};

const notificationSlice = createSlice({
  name: "order",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(getOrderNotification.fulfilled, (state, action) => {
      state.loading = false;
      state.notificationList = action.payload;
    });
    builder.addCase(markAsReadedNotification.fulfilled, (state, action) => {
      state.loading = false;
      const index = state.notificationList.findIndex(
        (notif) => notif.id === action.payload.id
      );
      if (index > -1) {
        state.notificationList = [
          ...state.notificationList.slice(0, index),
          ...state.notificationList.slice(index + 1),
        ];
      }
    });
  },
});

export const selectNotificationList = (state: RootState) =>
  state.notification.notificationList;
export const selectNotificationLoading = (state: RootState) =>
  state.notification.loading;

export default notificationSlice;
