import React from "react";
import Layout from "Components/Layout/Layout";
import SubtypeAdd from "Containers/Subtype/SubtypeAdd/SubtypeAdd";
import SettingsLayout from "Components/Settings/Layout/Layout";
import { BreadcrumbItem } from "Components/Breadcrumb/Breadcrumb";
import { useBreadcrumb } from "Shared/Hooks/use-breadcrumb";

export const SubtypeAddPage: React.FC = () => {
  const breads: BreadcrumbItem[] = [
    { title: "Subtypes", link: "/unit-settings/subtypes" },
    { title: "Create New Subtype", link: "" },
  ];
  useBreadcrumb({ links: breads });

  return (
    <Layout>
      <SettingsLayout>
        <SubtypeAdd />
      </SettingsLayout>
    </Layout>
  );
};
