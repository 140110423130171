import axios from "axios";
import { baseURL, geoNamesUrl } from "./config";
import { getCookie, read, readObj } from "./storage";
import { logout, setToken } from "Redux/Auth/slice";

const client = axios.create({
  baseURL: baseURL,
  headers: {
    "Content-Type": "application/json",
  },
});

client.interceptors.request.use(async (config) => {
  const access = read("access") || null;
  const csrftoken = getCookie("csrftoken");

  config.headers["X-CSRFToken"] = csrftoken;
  if (access) {
    config.headers.Authorization = `Bearer ${access}`;
  }
  return config;
});

client.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalRequest = error.config;
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const store = require("Redux/store").default;
    if (error?.response?.data?.message?.code === "user_inactive") {
      store.dispatch(logout());
      return Promise.reject(error);
    }
    if (
      error.response.status === 401 &&
      !error.request?.responseURL?.includes("refresh") &&
      error?.response?.data?.message?.code === "token_not_valid"
    ) {
      originalRequest._retry = true;
      const refreshToken = read("refresh") || null;
      if (
        !refreshToken ||
        refreshToken === "null" ||
        refreshToken === "undefined"
      ) {
        store.dispatch(logout());
      } else {
        try {
          const response = await client.post("auth/jwt/refresh/", {
            refresh: refreshToken,
          });
          const profile = readObj("account");
          const newAccessToken = response.data.access;
          if (profile.id && profile?.is_first_auth) {
            await client.patch(
              `user/${profile.id}/`,
              {
                is_first_auth: false,
              },
              {
                headers: {
                  Authorization: `Bearer ${newAccessToken}`,
                },
              }
            );
          }

          store.dispatch(
            setToken({
              access: newAccessToken,
            })
          );

          originalRequest.headers["Authorization"] = `Bearer ${newAccessToken}`;
          return client(originalRequest);
        } catch (refreshError) {
          store.dispatch(logout());
          return Promise.reject(refreshError);
        }
      }
    }
    return Promise.reject(error);
  }
);

const geoNamesClient = axios.create({
  baseURL: geoNamesUrl,
  headers: {
    "Content-Type": "application/json",
  },
});

export { client, geoNamesClient };
