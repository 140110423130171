import IconButton from "Components/UI/IconButton/IconButton";
import React, { MouseEvent } from "react";
import { EditIcon, DeleteIcon } from "Assets/index";
import { useNavigate } from "react-router";
import { ITaxRate } from "Shared/Types/taxRate";
import labor_styles from "../../LaborRate/RenderCell/renderCell.module.scss";

export const renderCell = (
  cellData: any,
  columnId: string,
  taxRate: ITaxRate,
  openDelete?: (id: string) => void
) => {
  const navigate = useNavigate();

  const handleClick = (event: MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
    navigate(`/settings/rate-settings/tax-rate/${taxRate.id}`);
  };

  const handleShowDelete = (event: MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
    openDelete?.(taxRate.id);
  };

  if (columnId === "action") {
    return (
      <div className={labor_styles.buttons}>
        <IconButton icon={EditIcon} onClick={handleClick} />
        <IconButton icon={DeleteIcon} onClick={handleShowDelete} />
      </div>
    );
  }
  return cellData;
};
