import React from "react";
import { MenuItem } from "@mui/material";
import { notifyError } from "Components/Notify/notify";
import { markAsReadedNotification } from "Redux/Notification/reducer";
import { useAppDispatch } from "Redux/store";
import { IOrderNotification } from "Shared/Types/order";
import { useNavigate } from "react-router";
import styles from "./NotifactionItem.module.scss";
import IconButton from "Components/UI/IconButton/IconButton";
import { UserIcon } from "Assets/index";
import { format } from "Shared/Utils/app";

type NotifcationItemProps = {
  notif: IOrderNotification;
  handleClose: () => void;
};

const NotifcationItem: React.FC<NotifcationItemProps> = ({
  notif,
  handleClose,
}) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const goToOrder = (notif: IOrderNotification) => {
    if (notif.order) {
      dispatch(markAsReadedNotification(notif.id));
      let action_item: string | null = null;
      if (notif.action_item) {
        action_item = notif.action_item;
      }
      navigate(`/orders/assigned/${notif.order}`, {
        state: { action_item: action_item },
      });
    } else {
      notifyError("Order does not exist.");
    }
  };

  return (
    <MenuItem onClick={handleClose}>
      <div className={styles.item} onClick={() => goToOrder(notif)}>
        <IconButton icon={UserIcon} width={30} height={30} variant="primary" />
        <div>
          <div
            className={styles.item__text}
            dangerouslySetInnerHTML={{
              __html: notif.description as string,
            }}
          />
          <span>{format(notif.created_at)}</span>
        </div>
      </div>
    </MenuItem>
  );
};
export default NotifcationItem;
