import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";
import {
  getUnitTypeList,
  getUnitType,
  addUnitType,
  updateUnitType,
  deleteUnitType,
} from "./reducer";
import { IUnitType } from "Shared/Types/unit-type";

interface IUnitTypeState {
  unitTypeList?: IUnitType[];
  currentUnitType?: IUnitType | null;
  loading: boolean;
}

const initialState: IUnitTypeState = {
  unitTypeList: [],
  currentUnitType: null,
  loading: false,
};

const unitTypeSlice = createSlice({
  name: "unitType",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getUnitType.pending, (state) => {
      state.loading = true;
      state.currentUnitType = null;
    });
    builder.addCase(getUnitType.rejected, (state) => {
      state.loading = false;
      state.currentUnitType = null;
    });
    builder.addCase(getUnitType.fulfilled, (state, action) => {
      state.loading = false;
      state.currentUnitType = action.payload;
    });

    builder.addCase(addUnitType.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(addUnitType.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(addUnitType.fulfilled, (state) => {
      state.loading = false;
    });

    builder.addCase(getUnitTypeList.pending, (state) => {
      state.loading = true;
      state.unitTypeList = [];
    });
    builder.addCase(getUnitTypeList.rejected, (state) => {
      state.loading = false;
      state.unitTypeList = [];
    });
    builder.addCase(getUnitTypeList.fulfilled, (state, action) => {
      state.loading = false;
      state.unitTypeList = action.payload.results;
    });

    builder.addCase(updateUnitType.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateUnitType.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(updateUnitType.fulfilled, (state) => {
      state.loading = false;
    });

    builder.addCase(deleteUnitType.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(deleteUnitType.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(deleteUnitType.fulfilled, (state) => {
      state.loading = false;
    });
  },
});

export const selectCurrentUnitType = (state: RootState) =>
  state.unitType.currentUnitType;
export const selectUnitTypeLoading = (state: RootState) =>
  state.unitType.loading;
export const selectUnitTypeList = (state: RootState) =>
  state.unitType.unitTypeList;

export default unitTypeSlice;
