import React from "react";
import { Grid } from "@mui/material";
import InputField from "Components/UI/Inputs/InputField";
import { Control } from "react-hook-form";
import { ICompanyForm } from "./CompanyForm";

type PrimaryContactProps = {
  control: Control<ICompanyForm>;
};

const PrimaryContact: React.FC<PrimaryContactProps> = ({ control }) => {
  return (
    <div>
      <h4>Primary Contact and Address</h4>
      <Grid container spacing={2} rowSpacing={-1}>
        <Grid item xs={6}>
          <InputField
            name="primary_contact"
            label="Primary Contact"
            withController
            control={control}
          />
        </Grid>

        <Grid item xs={6}>
          <InputField
            name="primary_address"
            label="Primary Address"
            withController
            control={control}
          />
        </Grid>
      </Grid>
    </div>
  );
};
export default PrimaryContact;
