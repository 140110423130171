import React from "react";

import { EmployeeAdd } from "Containers/Employee/EmployeeAdd/EmployeeAdd";
import Layout from "Components/Layout/Layout";
import { useBreadcrumb } from "Shared/Hooks/use-breadcrumb";
import { getBreads } from "Shared/Utils/user";

export const AddEmployee: React.FC = () => {
  useBreadcrumb({ links: getBreads("Create an employee") });

  return (
    <Layout>
      <EmployeeAdd />
    </Layout>
  );
};
