import React from "react";
import BlueLogo from "../../../Assets/logo-blue.svg";
import styles from "./Invoice.module.scss";
import cn from "classnames";

const InvoiceComponent = () => {
  return (
    <div className={styles.invoice}>
      <img className={styles.invoice__logo} src={BlueLogo} alt="logo" />
      <div className={styles.invoice__address}>
        <ul className={styles.invoice__address__block}>
          <li className={styles.item}>Invoice:</li>
          <li className={styles.item}>Order # 23451</li>
          <li className={styles.item}>Created 02/02/23</li>
        </ul>
        <ul className={styles.invoice__address__block}>
          <li className={styles.item}>Bill To :</li>
          <li className={styles.item}>BMI International</li>
          <li className={styles.item}>Business, Address</li>
          <li className={styles.item}>City, Country -00000000</li>
          <li className={styles.item}>+971588763097</li>
        </ul>
        <ul className={styles.invoice__address__block}>
          <li className={styles.item}>From :</li>
          <li className={styles.item}>Company Name </li>
          <li className={styles.item}>Company Address</li>
          <li className={styles.item}>City, Country -0000000</li>
          <li className={styles.item}>+99676652326534</li>
        </ul>
      </div>

      <div className={styles.invoice__complaint}>
        <p className={styles.invoice__complaint__item}>
          <span className={cn(styles.bold_600, styles.title)}>
            Reason for complaint:
          </span>
          Noise Front size truck
        </p>
        <p className={styles.invoice__complaint__item}>
          <span className={cn(styles.bold_600, styles.title)}> Cause:</span>{" "}
          Customer requested
        </p>
      </div>

      <div>
        <ul className={styles.invoice__head_with_line}>
          <li className={cn(styles.invoice__head__item1, styles.bold_600)}>
            Part name:
          </li>
          <li className={cn(styles.invoice__head__item2, styles.bold_600)}>
            Qty
          </li>
          <li className={cn(styles.invoice__head__item2, styles.bold_600)}>
            Price ($)
          </li>
          <li className={cn(styles.invoice__head__item2, styles.bold_600)}>
            Total
          </li>
        </ul>
        <hr className={styles.line} />

        <ul className={styles.invoice__head}>
          <li className={styles.invoice__head__item1}>Nox sensor inlet</li>
          <li className={styles.invoice__head__item2}>3</li>
          <li className={styles.invoice__head__item2}>$320, 00</li>
          <li className={styles.invoice__head__item2}>$960, 00</li>
        </ul>

        <ul className={styles.invoice__head}>
          <li className={styles.invoice__head__item1}>Nox sensor inlet</li>
          <li className={styles.invoice__head__item2}>3</li>
          <li className={styles.invoice__head__item2}>$320, 00</li>
          <li className={styles.invoice__head__item2}>$960, 00</li>
        </ul>

        <ul className={styles.invoice__head_with_line}>
          <li className={styles.invoice__head__item1}>Nox sensor inlet</li>
          <li className={styles.invoice__head__item2}>3</li>
          <li className={styles.invoice__head__item2}>$320, 00</li>
          <li className={styles.invoice__head__item2}>$960, 00</li>
        </ul>

        <hr className={styles.line} />

        <ul className={styles.invoice__head}>
          <li className={styles.invoice__head__item1}>
            Labor: Chassis / Chassis / Inspect Trailer Doors, Gap not Lining up
            - 11,23
          </li>
          <li className={styles.invoice__head__item2}>1</li>
          <li className={styles.invoice__head__item2}>$110, 00</li>
          <li className={styles.invoice__head__item2}>$110, 00</li>
        </ul>

        <div className={styles.invoice__head__total}>
          <div>
            <p className={styles.invoice__head__item2}>Subtotal:</p>
            <p className={styles.invoice__head__item2}>$1400, 00</p>
          </div>
        </div>
      </div>

      <div className={styles.invoice__complaint}>
        <p className={styles.invoice__complaint__item}>
          <span className={cn(styles.bold_600, styles.title)}>
            Reason for complaint:
          </span>
          Noise Front size truck
        </p>
        <p className={styles.invoice__complaint__item}>
          <span className={cn(styles.bold_600, styles.title)}> Cause:</span>{" "}
          Customer requested
        </p>
      </div>

      <div>
        <ul className={styles.invoice__head_with_line}>
          <li className={cn(styles.invoice__head__item1, styles.bold_600)}>
            Part name:
          </li>
          <li className={cn(styles.invoice__head__item2, styles.bold_600)}>
            Qty
          </li>
          <li className={cn(styles.invoice__head__item2, styles.bold_600)}>
            Price ($)
          </li>
          <li className={cn(styles.invoice__head__item2, styles.bold_600)}>
            Total
          </li>
        </ul>
        <hr className={styles.line} />

        <ul className={styles.invoice__head}>
          <li className={styles.invoice__head__item1}>Nox sensor inlet</li>
          <li className={styles.invoice__head__item2}>3</li>
          <li className={styles.invoice__head__item2}>$320, 00</li>
          <li className={styles.invoice__head__item2}>$960, 00</li>
        </ul>

        <ul className={styles.invoice__head}>
          <li className={styles.invoice__head__item1}>Nox sensor inlet</li>
          <li className={styles.invoice__head__item2}>3</li>
          <li className={styles.invoice__head__item2}>$320, 00</li>
          <li className={styles.invoice__head__item2}>$960, 00</li>
        </ul>

        <ul className={styles.invoice__head_with_line}>
          <li className={styles.invoice__head__item1}>Nox sensor inlet</li>
          <li className={styles.invoice__head__item2}>3</li>
          <li className={styles.invoice__head__item2}>$320, 00</li>
          <li className={styles.invoice__head__item2}>$960, 00</li>
        </ul>

        <hr className={styles.line} />

        <ul className={styles.invoice__head}>
          <li className={styles.invoice__head__item1}>
            Labor: Chassis / Chassis / Inspect Trailer Doors, Gap not Lining up
            - 11,23
          </li>
          <li className={styles.invoice__head__item2}>1</li>
          <li className={styles.invoice__head__item2}>$110, 00</li>
          <li className={styles.invoice__head__item2}>$110, 00</li>
        </ul>

        <div className={styles.invoice__head__total}>
          <div>
            <p className={styles.invoice__head__item2}>Subtotal:</p>
            <p className={styles.invoice__head__item2}>$1400, 00</p>
          </div>
        </div>
      </div>

      <div className={styles.invoice__overview}>
        <div className={styles.invoice__overview__block}>
          <p className={styles.item1}>
            <span className={styles.bold}>Unit: 12345 VIN:</span> 1234567UTGF5
          </p>
          <p className={cn(styles.item2, styles.bold_600)}>Labor:</p>
          <p className={styles.item3}>$ 110</p>
        </div>
        <div className={styles.invoice__overview__block}>
          <p className={styles.item1}>
            <span className={styles.bold}>License Plate:</span> PT23451SDFG
          </p>
          <p className={cn(styles.item2, styles.bold_600)}>Shop Supplies:</p>
          <p className={styles.item3}>$ 2 300</p>
        </div>
        <div className={styles.invoice__overview__block}>
          <p className={styles.item1}>2023 International LTR4658</p>
          <p className={cn(styles.item2, styles.bold_600)}>
            Pre-Charge Subtotal:
          </p>
          <p className={styles.item3}>$ 2 600</p>
        </div>
        <div className={styles.invoice__overview__block}>
          <p className={styles.item1}>
            <span className={styles.bold}>Chassis:</span> 303.966 Miles
          </p>
          <p className={cn(styles.item2, styles.bold_600)}>
            Exempt (0% of $11.00)
          </p>
          <p className={styles.item3}>$ 2 600</p>
        </div>

        <div className={styles.invoice__overview__total}>
          <div className={styles.items_cont}>
            <p className={styles.item}>Subtotal:</p>
            <p className={styles.item2}>$1400, 00</p>
          </div>
        </div>
      </div>

      <div className={styles.invoice__total}>
        <p>Authorized Amount: $ 2300.00 </p>
        <p>Hours (Inv. vs Actual): 1.6 / 2.54 </p>
      </div>
    </div>
  );
};

export default InvoiceComponent;
