import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useAppDispatch } from "Redux/store";
import { useNavigate } from "react-router";
import { getOrderHistory } from "Redux/History/reducer";
import { useSearchParams } from "react-router-dom";
import { selectHistoryList } from "Redux/History/slice";
import { getHistoryCardData } from "Shared/Utils/order";
import { IHistory, IHistorySearchParam } from "Shared/Types/order";
import HomeLayout from "../HomeLayout/HomeLayout";
import { returnsFieldsList } from "Shared/Constants/returns";
import Card from "Components/UI/Card/Card/Card";
import Row from "Components/UI/Card/Row/Row";
import { historyFieldAllList } from "Shared/Constants/order";

type ReturnsList = {
  status?: string;
};

const ReturnsList: React.FC<ReturnsList> = () => {
  const [isCard, setIsCard] = useState(true);
  const historyList = useSelector(selectHistoryList) || [];
  const dispatch = useAppDispatch();
  const [returnsFields, setReturnsFields] = useState(historyFieldAllList);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const goToDetail = (id?: string) => {
    if (id) {
      navigate("/orders/assigned/" + id);
    }
  };

  const props = (history: IHistory) => {
    return {
      fields: returnsFields,
      onClick: goToDetail,
      cardData: getHistoryCardData(history),
      dataId: history.id,
      withoutHead: true,
    };
  };

  useEffect(() => {
    const params: IHistorySearchParam = {
      page: +(searchParams.get("page") || 1),
      customers: searchParams.get("customer"),
    };
    dispatch(getOrderHistory(params));
  }, [searchParams]);
  return (
    <HomeLayout
      fields={returnsFields}
      setFields={setReturnsFields}
      isCard={isCard}
      setIsCard={setIsCard}
      fieldAllList={returnsFieldsList}
      limitFields={10}
    >
      {isCard
        ? historyList.map((history) => (
            <Card key={history.id} {...props(history)} />
          ))
        : historyList.map((history) => (
            <Row key={history.id} {...props(history)} />
          ))}
    </HomeLayout>
  );
};
export default ReturnsList;
