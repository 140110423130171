import React, { useEffect } from "react";
import EstimateInfo from "Components/Order/Estimate/EstimateDetail/EstimateInfo/EstimateInfo";
import { useSelector } from "react-redux";
import {
  selectCurrentEstimateOrder,
  selectEstimatesServiceList,
} from "Redux/Estimate/slice";
import { useParams } from "react-router";
import { useAppDispatch } from "Redux/store";
import {
  getEstimateOrder,
  getEstimateServiceList,
} from "Redux/Estimate/reducer";
import Block from "Components/UI/Block/Block";
import ComplaintInfo from "Components/Order/Estimate/EstimateDetail/ComplaintInfo/ComplaintInfo";
import EstimateForm from "Components/Order/Estimate/EstimateForm/EstimateForm";
import { estimatesScheme } from "Shared/Utils/estimate";

const EstimateDetail = () => {
  const currentEstimate = useSelector(selectCurrentEstimateOrder);
  const services = useSelector(selectEstimatesServiceList);
  const dispatch = useAppDispatch();
  const params = useParams();

  useEffect(() => {
    const id = params?.id ?? "";
    if (id) {
      (async () => {
        const estimateOrder = await dispatch(getEstimateOrder(id));
        dispatch(
          getEstimateServiceList({ estimate: estimateOrder.payload.id }),
        );
      })();
    }
  }, [params.id]);

  return (
    <Block>
      <EstimateInfo estimate={currentEstimate} />
      {services &&
        services.length > 0 &&
        services.map((service, index) => (
          <ComplaintInfo index={index} key={service.id} service={service} />
        ))}
      <EstimateForm
        currentEstimate={currentEstimate}
        schema={estimatesScheme}
        defaultValues={{}}
        complaintLength={services?.length}
        isDetails
      />
    </Block>
  );
};

export default EstimateDetail;
