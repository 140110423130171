import Layout from "Components/Layout/Layout";
import SettingsLayout from "Components/Settings/Layout/Layout";
import LocationAdd from "Containers/Settings/Part/Locations/LocationAdd";
import React from "react";
import { BreadcrumbItem } from "Components/Breadcrumb/Breadcrumb";
import { useBreadcrumb } from "Shared/Hooks/use-breadcrumb";

export const LocationAddPage: React.FC = () => {
  const breads: BreadcrumbItem[] = [
    { title: "Setting", link: "/settings/part-settings/locations" },
    { title: "Locations", link: "/settings/part-settings/locations" },
    { title: "Create New Location", link: "" },
  ];
  useBreadcrumb({ links: breads });

  return (
    <Layout>
      <SettingsLayout>
        <LocationAdd />
      </SettingsLayout>
    </Layout>
  );
};
