import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useAppDispatch } from "Redux/store";
import { useNavigate } from "react-router";
import DeleteModal from "Components/UI/DialogWindow/DeleteModal";
import { notifyError, notifySuccess } from "Components/Notify/notify";
import {
  clear,
  selectCustomerError,
  selectCustomerList,
  selectCustomerSuccess,
  selectExtraErrorInfo,
} from "Redux/Customer/slice";
import {
  changeStatus,
  deleteCustomer,
  getCustomerList,
} from "Redux/Customer/reducer";
import { customerFields } from "Shared/Constants/customers";
import ListLayout from "Components/Layout/ListLayout/ListLayout";
import { selectShopNumPage } from "Redux/Shop/slice";
import { getCustomerCardData } from "Shared/Utils/customer";
import { useSearchParams } from "react-router-dom";
import InActivateModal from "Components/UI/InActivateDialogWindow/InActivateModal";
import Card from "Components/UI/Card/Card/Card";
import Row from "Components/UI/Card/Row/Row";
import { ICustomer } from "Shared/Types/customer";
import { ICommonStatus } from "Shared/Types/common";
import { getShopCardStatus } from "Shared/Utils/shop";

export const CustomerList: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const customers = useSelector(selectCustomerList) || [];
  const extraErrorInfo = useSelector(selectExtraErrorInfo);
  const error = useSelector(selectCustomerError);
  const success = useSelector(selectCustomerSuccess);
  const numPage = useSelector(selectShopNumPage) || 1;
  const [searchParams] = useSearchParams();

  const [isCard, setIsCard] = useState(true);
  const [fields, setFields] = useState(customerFields);
  const [showDelete, setShowDelete] = useState(false);
  const [deleteId, setDeleteId] = useState<string | null>();

  const [showInActivate, setShowInActivate] = useState(false);
  const [inActivateId, setInActivateId] = useState<string | null>();
  const [dependencyError, setDependencyError] = useState(extraErrorInfo);

  const goToEditPage = (id: string) => {
    navigate(`/customers/edit/${id}`);
  };
  const goToInfoPage = (id: string) => {
    navigate(`/customers/info/${id}`);
  };

  const handleDialogClose = () => {
    setShowDelete(false);
    setDeleteId(null);
  };

  const handleDialogOpen = (id: string) => {
    setShowDelete(true);
    setDeleteId(id);
    setInActivateId(id);
  };
  const handleInActivateModalClose = () => {
    setShowInActivate(false);
    setInActivateId(null);
    setDependencyError(null);
  };
  const handleInActivateModalOpen = () => {
    setDependencyError(extraErrorInfo);
    setShowDelete(false);
    setShowInActivate(true);
  };
  const handleDeleteCustomer = async () => {
    if (deleteId) {
      await dispatch(deleteCustomer(deleteId));
    }
  };
  const handleInActivateCustomer = async () => {
    if (inActivateId) {
      await dispatch(
        changeStatus({ id: inActivateId, data: { status: "inactive" } })
      );
    }
  };
  const props = (customer: ICustomer) => {
    return {
      openDeleteModal: handleDialogOpen,
      fields: fields,
      onClick: goToInfoPage,
      cardData: getCustomerCardData(customer),
      editClick: goToEditPage,
      dataId: customer.id,
      isNeedStatus: true,
      status: getShopCardStatus(customer.status as ICommonStatus),
    };
  };

  useEffect(() => {
    dispatch(
      getCustomerList({
        search: searchParams.get("search"),
        status: searchParams.get("status"),
      })
    );
  }, [searchParams]);

  useEffect(() => {
    if (extraErrorInfo) {
      handleInActivateModalOpen();
    } else {
      if (error) notifyError(error);
    }
    dispatch(clear());
  }, [error, extraErrorInfo, dispatch]);

  useEffect(() => {
    if (success) {
      const successMessage = extraErrorInfo
        ? "The customer has been inactivated"
        : "The customer has been deleted";
      notifySuccess(successMessage);
      dispatch(clear());
      handleInActivateModalClose();
      setShowDelete(false);
      setDeleteId(null);
      dispatch(getCustomerList(null));
      dispatch(clear());
    }
  }, [success, dispatch]);

  return (
    <ListLayout
      pagesNumber={numPage}
      isCard={isCard}
      setIsCard={setIsCard}
      fields={fields}
      setFields={setFields}
      filters={["search", "customersStatus"]}
      fieldAllList={customerFields}
    >
      {isCard
        ? customers.map((customer) => (
            <Card key={customer.id} {...props(customer)} />
          ))
        : customers.map((customer) => (
            <Row key={customer.id} {...props(customer)} />
          ))}

      {showInActivate ? (
        <InActivateModal
          open={showInActivate}
          handleClose={handleInActivateModalClose}
          text="Would you like to make this customer Inactive  instead?"
          dependencies={dependencyError}
          inActivate={handleInActivateCustomer}
          inActivateId={inActivateId}
        />
      ) : (
        <DeleteModal
          open={showDelete}
          handleClose={handleDialogClose}
          title="Delete Customer"
          text="Are you sure you want to delete this Customer?"
          acceptTitle="Delete"
          accept={handleDeleteCustomer}
        />
      )}
    </ListLayout>
  );
};
