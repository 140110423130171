import React from "react";
import { Grid } from "@mui/material";
import InputField from "Components/UI/Inputs/InputField";
import { Control } from "react-hook-form";
import { IVendorForm } from "./VendorForm";

type ContactPersonProps = {
  control: Control<IVendorForm>;
};

const ContactPerson: React.FC<ContactPersonProps> = ({ control }) => {
  return (
    <div>
      <h4>Contact Person</h4>
      <Grid container spacing={1} rowSpacing={-1}>
        <Grid item xs={12}>
          <InputField
            name="contact_name"
            label="Name"
            withController
            control={control}
          />
        </Grid>

        <Grid item xs={12}>
          <InputField
            name="contact_email"
            label="Email"
            withController
            control={control}
          />
        </Grid>

        <Grid item xs={12}>
          <InputField
            name="contact_phone"
            label="Phone"
            withController
            control={control}
          />
        </Grid>
      </Grid>
    </div>
  );
};
export default ContactPerson;
