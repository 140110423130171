import { BreadcrumbItem } from "Components/Breadcrumb/Breadcrumb";
import Layout from "Components/Layout/Layout";
import PricePartList from "Containers/Part/PriceList/PriceList";
import { useBreadcrumb } from "Shared/Hooks/use-breadcrumb";
import React from "react";

export const PartPricePage: React.FC = () => {
  const breads: BreadcrumbItem[] = [{ title: "Parts", link: "/parts" }];
  useBreadcrumb({
    links: breads,
    createTitle: "Part",
    isNeedCreateBtn: true,
  });
  return (
    <Layout>
      <PricePartList />
    </Layout>
  );
};
