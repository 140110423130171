import React from "react";
import { InputAdornment, TextField, TextFieldProps } from "@mui/material";
import cn from "classnames";
import styles from "./Inputs.module.scss";
import Icon from "../Icon/Icon";
import { Control, Controller } from "react-hook-form";

export type InputFieldProps = Omit<TextFieldProps, "name"> & {
  icon?: React.ElementType;
  withController?: boolean;
  // TODO: remove any type
  control?: Control<any>;
  name: string;
  error?: string;
  indicator: string;
};

const InputFieldWithIndicator: React.FC<InputFieldProps> = ({
  label,
  required,
  icon,
  withController = false,
  control,
  name,
  className,
  defaultValue = "",
  error = null,
  indicator,
  ...props
}) => {
  return (
    <div className={styles.inputs}>
      {label && (
        <label data-id={name} className={styles.inputs__label}>
          {label} {required && <span className={styles.inputs__error}>*</span>}
        </label>
      )}
      {withController ? (
        <Controller
          control={control}
          name={name}
          defaultValue={defaultValue}
          render={({ field, fieldState }) => (
            <>
              <TextField
                sx={{
                  ".MuiOutlinedInput-notchedOutline": {
                    borderStyle: "none",
                  },
                }}
                {...field}
                value={field.value || ""}
                classes={{
                  root: cn(styles.inputs__input_indicator, className),
                }}
                required={required}
                {...props}
                InputProps={
                  icon && {
                    startAdornment: (
                      <InputAdornment
                        sx={{
                          padding: "21px 10px",
                          margin: 0,
                          backgroundColor: "#C9C9C9",
                          borderTopLeftRadius: "8px",
                          borderBottomLeftRadius: "8px",
                        }}
                        position="start"
                      >
                        <Icon component={icon} color="white" />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment
                        sx={{
                          padding: "21px 10px",
                          margin: 0,
                          backgroundColor: "#C9C9C9",
                          borderTopRightRadius: "8px",
                          borderBottomRightRadius: "8px",
                        }}
                        position="end"
                      >
                        {indicator}
                      </InputAdornment>
                    ),
                  }
                }
              />
              {fieldState.error && (
                <div className={styles.inputs__error}>
                  {fieldState.error.message}
                </div>
              )}
            </>
          )}
        />
      ) : (
        <>
          <TextField
            classes={{ root: cn(styles.inputs__input, className) }}
            required={required}
            {...props}
            InputProps={
              icon && {
                endAdornment: (
                  <InputAdornment position="start">
                    <Icon component={icon} color="white" />
                  </InputAdornment>
                ),
              }
            }
          />
          {error && <div className={styles.inputs__error}>{error}</div>}
        </>
      )}
    </div>
  );
};
export default InputFieldWithIndicator;
