import React from 'react'
import Layout from 'Components/Layout/Layout';
import { BreadcrumbItem } from 'Components/Breadcrumb/Breadcrumb';
import { useBreadcrumb } from 'Shared/Hooks/use-breadcrumb';
import CustomerInfoListContainer from 'Containers/Customer/CustomerInfo/CustomerInfo';
import { selectCurrentCustomer } from 'Redux/Customer/slice';
import { useSelector } from 'react-redux';


export const CustomersInfoPage: React.FC = () => {
    const customer = useSelector(selectCurrentCustomer);
    const breads: BreadcrumbItem[] = [
      { title: "Customer", link: "/customers" },
      { title: customer?.company_name || "", link: "" },
    ];
  
    useBreadcrumb({ links: breads, option: { upd: customer?.company_name } });
    return <Layout>
        <CustomerInfoListContainer />
    </Layout>

}



