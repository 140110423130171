import React from "react";
import { Grid } from "@mui/material";
import System from "../ActionItemFields/System";
import TechnicianField from "../TechnicianField/TechnicianField";
import InfoField from "Components/UI/InfoField/InfoField";
import { IActionItem, IActionItemFields } from "Shared/Types/order";
import LaborRateField from "../LaborRateField/LaborRateField";
import { isNotWaiting } from "Shared/Utils/order";
import InputField from "Components/UI/Inputs/InputField";
import SelectField from "Components/UI/SelectField/SelectField";
import { causeTypes, severityList } from "Shared/Constants/order";
import RecommendedServiceProps from "../ActionItemFields/RecommendedService";
import { secondsToHours } from "Shared/Utils/app";

type ActionItemMainProps = {
  currentActionItem: IActionItem;
  saveActionItemField: (field: IActionItemFields, value: string) => void;
};

const ActionItemMain: React.FC<ActionItemMainProps> = ({
  currentActionItem,
  saveActionItemField,
}) => {
  return (
    <Grid container columnSpacing={4} marginBottom="8.5px">
      <Grid xs={12} item>
        <InputField
          name="complaint"
          disabled
          label="Complaint"
          value={currentActionItem.complaint}
        />
      </Grid>
      <Grid xs={12} item>
        <RecommendedServiceProps
          service={currentActionItem.recommended_service}
          saveActionItemField={saveActionItemField}
        />
      </Grid>
      <Grid item xs={3}>
        <System
          saveActionItemField={saveActionItemField}
          system={currentActionItem.system}
        />
      </Grid>
      <Grid item xs={3}>
        <TechnicianField
          saveActionItemField={saveActionItemField}
          technician={currentActionItem.technician}
        />
      </Grid>
      <Grid item xs={1.5}>
        <InfoField
          label="Invoice Hours"
          value={currentActionItem.invoice_hours}
          mt={4}
        />
      </Grid>
      <Grid item xs={1.5}>
        <InfoField
          label="Actual Hours"
          value={secondsToHours(currentActionItem.actual_time)}
          mt={4}
        />
      </Grid>
      <Grid item xs={3}>
        <LaborRateField
          saveActionItemField={saveActionItemField}
          laborRate={currentActionItem.labor_rate}
        />
      </Grid>

      {isNotWaiting(currentActionItem?.status?.queue) && (
        <Grid container item xs={12} columnSpacing={2}>
          <Grid xs={6} item>
            <InputField
              name="cause"
              label="Cause "
              defaultValue={currentActionItem.cause}
              onBlur={(event) =>
                saveActionItemField("cause", event.target.value)
              }
              required
            />
          </Grid>
          <Grid xs={3} item>
            <SelectField
              options={causeTypes}
              name="cause_type"
              label="Cause type"
              defaultValue={{
                label: currentActionItem?.cause_type || "",
                value: currentActionItem?.cause_type || "",
              }}
              onBlur={(value) =>
                saveActionItemField("cause_type", value?.toString() || "")
              }
            />
          </Grid>
          <Grid xs={3} item>
            <SelectField
              options={severityList}
              name="severity"
              label="Severity"
              defaultValue={{
                label: currentActionItem?.severity || "",
                value: currentActionItem?.severity || "",
              }}
              onBlur={(value) =>
                saveActionItemField("severity", value?.toString() || "")
              }
            />
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};
export default ActionItemMain;
