import { client } from "Service/axios";
import { SearchResult } from "Shared/Types/common";
import {
  ISubtype,
  ISubtypeAdd,
  ISubtypeSearchParams,
  ISubtypeUpdate,
} from "Shared/Types/subtype";

const SUBTYPE_URL = "unit/subtype/";

class Subtype {
  getSubtypeList(params: ISubtypeSearchParams | null) {
    return client.get<SearchResult<ISubtype>>(SUBTYPE_URL, { params });
  }
  getSubtype(id: string) {
    return client.get<ISubtype>(`${SUBTYPE_URL}${id}/`);
  }
  addSubtype(data: ISubtypeAdd) {
    return client.post(SUBTYPE_URL, data);
  }
  updateSubtype(id: string, data: ISubtypeUpdate) {
    return client.put(`${SUBTYPE_URL}${id}/`, data);
  }
  deleteSubtype(id: string) {
    return client.delete(`${SUBTYPE_URL}${id}/`);
  }
}

export default Subtype;
