import React from "react";
import { InputAdornment, TextField, TextFieldProps } from "@mui/material";
import cn from "classnames";
import styles from "./Inputs.module.scss";
import Icon from "../Icon/Icon";
import { Control, Controller } from "react-hook-form";

export type InputFieldProps = Omit<TextFieldProps, "name"> & {
  icon?: React.ElementType;
  withController?: boolean;
  // TODO: remove any type
  control?: Control<any>;
  name: string;
  error?: string;
  IconBg?: string;
  InputTheme?: "dark" | "light";
  borderColor?: string;
  labelColor?: string;
  validatePositiveValue?: boolean;
};

const InputField: React.FC<InputFieldProps> = ({
  label,
  required,
  icon,
  withController = false,
  control,
  name,
  className,
  defaultValue,
  InputTheme,
  IconBg,
  error = null,
  borderColor,
  labelColor,
  type,
  validatePositiveValue = false,
  onChange,
  ...props
}) => {
  return (
    <div
      className={styles.inputs}
      style={{
        color: InputTheme === "dark" ? "white" : "black",
      }}
    >
      {label && (
        <label
          style={{ color: labelColor }}
          data-id={name}
          className={styles.inputs__label}
        >
          {label} {required && <span className={styles.inputs__error}>*</span>}
        </label>
      )}
      {withController ? (
        <Controller
          control={control}
          name={name}
          defaultValue={defaultValue}
          render={({ field, fieldState }) => (
            <>
              <TextField
                {...field}
                value={field.value}
                onChange={(event) => {
                  if (
                    validatePositiveValue &&
                    +event.target.value < 0 &&
                    type == "number"
                  ) {
                    return;
                  }
                  field.onChange(event);
                  onChange?.(event);
                }}
                onFocus={() => {
                  if (field.value === 0 && type === "number") {
                    field.onChange("");
                  }
                }}
                onBlur={() => {
                  if (field.value === "" && type === "number") {
                    field.onChange(0);
                  }
                }}
                sx={{
                  ".MuiOutlinedInput-notchedOutline": {
                    borderStyle: "none",
                  },
                  backgroundColor:
                    InputTheme === "dark" ? "transparent" : "#E9F0FD",
                  border: `2px solid ${borderColor}`,
                }}
                classes={{ root: cn(styles.inputs__input, className) }}
                required={required}
                type={type}
                {...props}
                InputProps={
                  icon && {
                    startAdornment: (
                      <InputAdornment
                        sx={{
                          padding: "22px 10px",
                          margin: 0,
                          backgroundColor: IconBg || "#007DF2",
                          borderTopLeftRadius: "8px",
                          borderBottomLeftRadius: "8px",
                        }}
                        position="start"
                      >
                        <Icon component={icon} color="transparent" />
                      </InputAdornment>
                    ),
                  }
                }
              />
              {fieldState.error && (
                <div className={styles.inputs__error}>
                  {fieldState.error.message}
                </div>
              )}
            </>
          )}
        />
      ) : (
        <>
          <TextField
            classes={{ root: cn(styles.inputs__input, className) }}
            required={required}
            defaultValue={defaultValue}
            type={type}
            onChange={onChange}
            {...props}
            sx={{
              ".MuiOutlinedInput-notchedOutline": {
                borderStyle: "none",
              },
              backgroundColor:
                InputTheme === "dark" ? "transparent" : "#E9F0FD",
              border: `2px solid ${borderColor}`,
            }}
            InputProps={
              icon && {
                startAdornment: (
                  <InputAdornment
                    sx={{
                      padding: "21px 10px",
                      margin: 0,
                      backgroundColor: IconBg || "#007DF2",
                      borderTopLeftRadius: "8px",
                      borderBottomLeftRadius: "8px",
                    }}
                    position="start"
                  >
                    <Icon component={icon} color="transparent" />
                  </InputAdornment>
                ),
              }
            }
          />
          {error && <div className={styles.inputs__error}>{error}</div>}
        </>
      )}
    </div>
  );
};
export default InputField;
