export async function urlToFile(
  fileUrl: string,
  fileName: string,
): Promise<File | null> {
  try {
    const response = await fetch(fileUrl);
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const blob = await response.blob();
    const file = new File([blob], fileName, { type: blob.type });

    return file;
  } catch (error) {
    console.error("Error converting URL to File:", error);
    return null;
  }
}
