import React from "react";
import { Grid } from "@mui/material";
import Block from "Components/UI/Block/Block";
import InfoField from "Components/UI/InfoField/InfoField";
import { getInventoryInfo } from "Shared/Utils/part";
import { useSelector } from "react-redux";
import { selectCurrentPart } from "Redux/Product/slice";

const InventoryDetail: React.FC = () => {
  const part = useSelector(selectCurrentPart);
  const { info } = getInventoryInfo(part);

  return (
    <Block title="Info">
      <Grid container>
        {info.map(({ label, value, xs }) => (
          <Grid item xs={xs} key={label} mt={2}>
            <InfoField key={label} label={label} value={value} xs={xs} />
          </Grid>
        ))}
      </Grid>
    </Block>
  );
};
export default InventoryDetail;
