import React from "react";
import Layout from "Components/Layout/Layout";
import UpdateShop from "Containers/Shop/UpdateShop";

export const ShopUpdatePage: React.FC = () => {
  return (
    <Layout>
      <UpdateShop />
    </Layout>
  );
};
