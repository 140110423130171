import React from "react";
import styles from "./ServiceAdd.module.scss";
import { useAppDispatch } from "Redux/store";
import { useSelector } from "react-redux";
import { notifyError, notifySuccess } from "Components/Notify/notify";
import { useLocation, useNavigate } from "react-router";
import { addService } from "Redux/Service/reducer";
import { IServiceAdd } from "Shared/Types/service";
import ServiceForm from "Components/Service/ServiceForm/ServiceForm";
import { serviceSchema } from "Shared/Utils/service";
import StepProgress from "Components/UI/StepProgress/StepProgress";
import { selectAccount } from "Redux/Auth/slice";
import { checkFirstAuth } from "Shared/Utils/app";
import { readObj } from "Service/storage";

const step = (
  <>
    Now, let&apos;s add the services your shop provides:
    <br />
    Add details for each service offered, including name and description.
    <br />
    Set the time required for each service.
  </>
);

const ServiceAdd: React.FC = () => {
  const dispatch = useAppDispatch();
  const profile = useSelector(selectAccount);
  const navigate = useNavigate();
  const location = useLocation();
  const isFirstAuth = checkFirstAuth(profile);

  const createSubtype = (values: IServiceAdd) => {
    const onSuccess = () => {
      notifySuccess("Service created");
      if (isFirstAuth) {
        goToCutomerAdd();
      } else if (location.state) {
        navigate(-1);
      } else {
        navigate("/services");
      }
    };
    const shop = readObj("current_shop");
    if (!shop) {
      notifyError("You can't create service, because you don't assign to shop");
      return;
    }
    dispatch(addService({ data: { ...values, shop: shop?.id }, onSuccess }));
  };

  const goToCutomerAdd = () => {
    navigate("/customers/new");
  };

  return (
    <div className={styles.serviceAdd}>
      {isFirstAuth && (
        <StepProgress description={step} active={3} title="Creating Service" />
      )}
      <div className={styles.serviceAdd__form}>
        <ServiceForm
          onSubmit={createSubtype}
          schema={serviceSchema}
          onSkip={goToCutomerAdd}
          isFirstAuth={isFirstAuth}
        />
      </div>
    </div>
  );
};
export default ServiceAdd;
