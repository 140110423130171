import React from "react";
import Layout from "Components/Layout/Layout";
import { useBreadcrumb } from "Shared/Hooks/use-breadcrumb";
import { getPurchaseOrderBreadCrumb } from "Shared/Utils/purchase-order";
import PurchaseOrderUpdate from "Containers/PurchaseOrder/PurchaseOrderUpdate/PurchaseOrderUpdate";
import { useSelector } from "react-redux";
import { selectCurrentPurchaseOrder } from "Redux/PurchaseOrder/slice";

export const PurchaseOrderUpdatePage: React.FC = () => {
  const currentPurchaseOrder = useSelector(selectCurrentPurchaseOrder);
  useBreadcrumb({
    links: getPurchaseOrderBreadCrumb("UPDATE", currentPurchaseOrder?.number),
    option: {
      upd: currentPurchaseOrder?.number,
    },
  });

  return (
    <Layout>
      <PurchaseOrderUpdate />
    </Layout>
  );
};
