import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "Service/api/api";
import {
  IDispatchCallback,
  IDispatchCallbackWithId,
  IDispatchCallbackWithoutData,
} from "Shared/Types/common";
import {
  IVendorAdd,
  IVendorSearchParams,
  IVendorUpdate,
} from "Shared/Types/vendor";
import { errorHandler } from "Shared/Utils/app";

const getVendorList = createAsyncThunk(
  "vendor/getVendorList",
  async (params: IVendorSearchParams | null) => {
    try {
      const response = await api.vendor.getVendorList(params);
      return response.data;
    } catch (error) {
      if (error instanceof Error) {
        errorHandler(error);
      }
      throw error;
    }
  }
);

const getVendor = createAsyncThunk("vendor/getVendor", async (id: string) => {
  try {
    const response = await api.vendor.getVendor(id);
    return response.data;
  } catch (error) {
    if (error instanceof Error) {
      errorHandler(error);
    }
    throw error;
  }
});

const addVendor = createAsyncThunk(
  "vendor/addVendor",
  async ({ data, onSuccess }: IDispatchCallback<IVendorAdd>) => {
    try {
      const formData = new FormData();
      Object.entries(data).forEach(([key, value]) => {
        if (value instanceof File) {
          formData.append(key, value);
        } else if (value !== undefined && value !== null) {
          formData.append(key, String(value));
        }
      });
      const response = await api.vendor.addVendor(formData);
      onSuccess?.();

      return response.data;
    } catch (error) {
      if (error instanceof Error) {
        errorHandler(error, true);
      }
      throw error;
    }
  }
);

const updateVendor = createAsyncThunk(
  "vendor/updateVendor",
  async ({ id, data, onSuccess }: IDispatchCallbackWithId<IVendorUpdate>) => {
    try {
      const formData = new FormData();
      Object.entries(data).forEach(([key, value]) => {
        if (value instanceof File) {
          formData.append(key, value);
        } else if (
          value !== undefined &&
          key !== "photo" &&
          key !== "document"
        ) {
          formData.append(key, String(value));
        }
      });
      const response = await api.vendor.updateVendor(id, formData);
      onSuccess?.();
      return response.data;
    } catch (error) {
      if (error instanceof Error) {
        errorHandler(error, true);
      }
      throw error;
    }
  }
);

const deleteVendor = createAsyncThunk(
  "vendor/deleteVendor",
  async ({ id, onSuccess }: IDispatchCallbackWithoutData) => {
    try {
      const response = await api.vendor.deleteVendor(id);
      onSuccess?.();
      return response.data;
    } catch (error) {
      if (error instanceof Error) {
        errorHandler(error, true);
      }
      throw error;
    }
  }
);

export { getVendorList, getVendor, addVendor, updateVendor, deleteVendor };
