import React from "react";
import styles from "./InfoField.module.scss";
import cn from "classnames";

type InfoFieldProps = {
  label?: string;
  labelColor?: string;
  value?: string | number | null;
  value2?: string | number | null;
  valueColor?: "blue" | "gray" | "lightBlue";
  borderColor?: "blue" | "orange" | "red";
  mt?: number;
  mb?: number;
  isHorizontal?: boolean;
  isValueUnderline?: boolean;
  xs?: number;
};

const InfoField: React.FC<InfoFieldProps> = ({
  label,
  value,
  value2,
  valueColor,
  borderColor,
  labelColor,
  mt,
  mb,
  isHorizontal,
  isValueUnderline,
}) => {
  let curValue = value;
  if (!value && value !== 0) curValue = "-";
  return (
    <div
      className={cn(styles.info, isHorizontal && styles.info_horizontal)}
      style={{ marginBottom: `${mb}px`, marginTop: `${mt}px` }}
    >
      <label
        className={cn(
          styles.info__label,
          borderColor && styles[`info__label_${borderColor}`]
        )}
        style={{ color: labelColor }}
      >
        {label}
      </label>
      <div
        className={cn(
          styles.info__value,
          valueColor === "blue" && styles.info__value_blue,
          valueColor === "lightBlue" && styles.info__value_lightBlue,
          valueColor === "gray" && styles.info__value_gray,
          borderColor && styles[`info__value_border_${borderColor}`],
          isValueUnderline && styles.info__value_underline
        )}
      >
        {curValue}
      </div>
      {value2 && <div className={styles.info__value2}>{value2 || "-"}</div>}
    </div>
  );
};
export default InfoField;
