import { IOrder, IOrderFields } from "Shared/Types/order";
import React, { useMemo } from "react";
import styles from "./OrderBasic.module.scss";
import InfoField from "Components/UI/InfoField/InfoField";
import { Grid } from "@mui/material";
import InputField from "Components/UI/Inputs/InputField";
import Block from "Components/UI/Block/Block";
import { format } from "Shared/Utils/app";
import SelectOrTextField from "Components/UI/SelectField/SelectOrTextField";
import { getFullAddress } from "Shared/Utils/order";
import PMCount from "Components/PMCount/PMCount";
import { useAppDispatch } from "Redux/store";
import { updateOrderByField } from "Redux/Order/reducer";
import { notifySuccess } from "Components/Notify/notify";

type OrderBasicProps = {
  order?: IOrder | null;
};

const OrderBasic: React.FC<OrderBasicProps> = ({ order }) => {
  const dispatch = useAppDispatch();
  const saveField = (field: IOrderFields, value: string) => {
    const onSuccess = () => {
      notifySuccess(`${field} is updated`);
    };
    if (order) {
      dispatch(
        updateOrderByField({
          id: order?.id,
          data: { field, value },
          onSuccess,
        })
      );
    }
  };
  const option = useMemo(() => {
    const address = getFullAddress(order);
    if (address) {
      return [
        {
          value: address,
          label: address,
        },
      ];
    }

    return [];
  }, [order]);
  return (
    <Block className={styles.order}>
      <div className={styles.order__head}>
        <h3>Estimate</h3>
        <PMCount data={order?.unit?.service?.length} />
      </div>

      <div className={styles.order__customer}>
        <Grid container>
          <Grid item md={4}>
            <InfoField
              label="Customer"
              labelColor="white"
              valueColor="blue"
              value={order?.customer?.company_name || ""}
              value2={
                (order?.customer?.first_name || "") +
                " " +
                (order?.customer?.last_name || "")
              }
            />
          </Grid>
          <Grid item md={4}>
            <InfoField
              label="Unit"
              valueColor="blue"
              labelColor="white"
              value={order?.unit?.name || ""}
              value2={order?.unit?.vin || ""}
            />
          </Grid>
        </Grid>
      </div>

      <div className={styles.order__access}>
        <Grid container marginTop="12px" columnSpacing={2}>
          <Grid item md={4}>
            <InputField
              label="Estimate date"
              name="estimate_date"
              value={format(order?.created_at || "")}
              disabled
            />
          </Grid>
          <Grid item md={4}>
            <InputField
              label="Bill to"
              name="bill_to"
              value={order?.customer?.company_name}
              disabled
            />
          </Grid>
          <Grid item md={4}>
            <SelectOrTextField
              label="Bill to address"
              name="bill_to_address"
              options={option}
              onBlur={(value) =>
                saveField("bill_to_address", value?.toString() || "")
              }
            />
          </Grid>
        </Grid>
        <Grid container marginTop="12px" columnSpacing={2}>
          <Grid item md={4}>
            <SelectOrTextField
              label="Ship to address"
              name="ship_to_address"
              options={option}
              onBlur={(value) =>
                saveField("ship_to_address", value?.toString() || "")
              }
            />
          </Grid>
          <Grid item md={4}>
            <SelectOrTextField
              label="Remit to address"
              name="remit_to_address"
              options={option}
              onBlur={(value) =>
                saveField("remit_to_address", value?.toString() || "")
              }
            />
          </Grid>
          <Grid item md={4}>
            <InputField
              label="PO #"
              name="po"
              onBlur={(value) =>
                saveField("purchase_order_number", value?.toString() || "")
              }
            />
          </Grid>
        </Grid>
      </div>
    </Block>
  );
};
export default OrderBasic;
