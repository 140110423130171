import React from "react";
import { Grid } from "@mui/material";
import styles from "./CustomerForm.module.scss";
import { AddressFields } from "Components/AddressField/AddressField";
import { useFormContext } from "react-hook-form";

const BillingInfo = () => {
  const { control } = useFormContext();

  return (
    <div>
      <div className={styles.form__billing}>
        <h4 className={styles.form__billing__title}>Billing info</h4>
        <Grid container spacing={1} rowSpacing={-1}>
          <AddressFields
            control={control}
            isNeedPostalCode
            requiredFields={["country", "city", "state", "postal_code"]}
            zip_code="postal_code"
          />
        </Grid>
      </div>
    </div>
  );
};
export default BillingInfo;
