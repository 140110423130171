import React, { useState } from "react";
import styles from "./ActionIcons.module.scss";
import {
  PURCAHSE_ORDER_ACTIONS,
  purchaseOrderActions,
} from "Shared/Constants/purchase-order";
import IconButton from "Components/UI/IconButton/IconButton";

const ActionIcons: React.FC = () => {
  const [showHistory, setShowHistory] = useState(false);
  const [showReSend, setShowReSend] = useState(false);
  const [showNote, setShowNote] = useState(false);
  const handleClickIcon = (title: string) => {
    switch (title) {
      case PURCAHSE_ORDER_ACTIONS.HISTORY:
        setShowHistory(true);
        break;
      case PURCAHSE_ORDER_ACTIONS.RESEND:
        setShowReSend(true);
        break;
      case PURCAHSE_ORDER_ACTIONS.DOWNLOAD:
        console.log("download");
        break;
      case PURCAHSE_ORDER_ACTIONS.NOTE:
        setShowNote(true);
        break;
    }
  };
  return (
    <div className={styles.action}>
      {purchaseOrderActions.map(({ icon, title, padding, size }) => (
        <IconButton
          variant="primary"
          width={size}
          height={size}
          key={title}
          icon={icon}
          style={{ padding }}
          onClick={() => handleClickIcon(title)}
        />
      ))}
    </div>
  );
};
export default ActionIcons;
