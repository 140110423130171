import React from "react";
import styles from "./TermsOfService.module.scss";

const TermsOfSevice: React.FC = () => {
  return (
    <div className={styles.term}>
      <p>
        By login in you are agreeing to our <span>Terms of Service</span>
      </p>
    </div>
  );
};
export default TermsOfSevice;
