import React from "react";
import styles from "./LaborRateAdd.module.scss";
import { useLocation, useNavigate } from "react-router";
import LaborForm from "Components/Settings/LaborRate/Form/LaborForm";
import { ILaborRateAdd } from "Shared/Types/laborRate";
import { useAppDispatch } from "Redux/store";
import { useSelector } from "react-redux";
import { selectAccount } from "Redux/Auth/slice";
import { addLaborRate } from "Redux/LaborRate/reducer";
import { notifySuccess } from "Components/Notify/notify";
import { laborRateScheme } from "Shared/Utils/laborRate";
import StepProgress from "Components/UI/StepProgress/StepProgress";
import { stepDescription } from "Containers/Home/Home";
import { checkFirstAuth } from "Shared/Utils/app";

const LaborRateAdd: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const profile = useSelector(selectAccount);
  const isFirstAuth = checkFirstAuth(profile);

  const back = () => {
    navigate("/settings/rate-settings/labor-rate");
  };
  const createLaborRate = async (values: ILaborRateAdd) => {
    const onSuccess = () => {
      notifySuccess("Created LaborRate!");
      if (isFirstAuth) {
        navigate("/settings/rate-settings/tax-rate/new");
      } else if (location.state) {
        navigate(-1);
      } else {
        back();
      }
    };
    await dispatch(addLaborRate({ data: values, onSuccess }));
  };

  return (
    <div className={styles.labor}>
      {isFirstAuth && (
        <StepProgress
          description={stepDescription}
          title="Settings"
          active={0}
        />
      )}
      <LaborForm onSubmit={createLaborRate} scheme={laborRateScheme} />
    </div>
  );
};
export default LaborRateAdd;
