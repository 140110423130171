import AcynsSelectField from "Components/UI/AcyncSelect/AcyncSelect";
import { getSystemList } from "Redux/System/reducers";
import {
  selectSystemList,
  selectSystemLoading,
} from "Redux/System/systemSlice";
import { useAppDispatch } from "Redux/store";
import { IActionItemFields } from "Shared/Types/order";
import { ISystem } from "Shared/Types/system";
import { changeSytemOptions } from "Shared/Utils/app";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

type SystemProps = {
  saveActionItemField: (field: IActionItemFields, value: string) => void;
  system?: ISystem;
};

const System: React.FC<SystemProps> = ({ saveActionItemField, system }) => {
  const systemList = useSelector(selectSystemList) || [];
  const systemLoading = useSelector(selectSystemLoading);
  const dispatch = useAppDispatch();
  const [selected, setSelected] = useState<string>();
  const inputChange = (text: string | null) => {
    dispatch(getSystemList({ search: text }));
  };

  const onBlur = () => {
    if (selected) {
      saveActionItemField("system", selected);
    }
  };

  useEffect(() => {
    inputChange(null);
  }, []);

  return (
    <div>
      <AcynsSelectField
        options={changeSytemOptions(systemList)}
        label="Component & System"
        loading={systemLoading}
        defaultValue={{
          label: system?.component?.name + ":  " + system?.name,
          value: system?.id || "",
        }}
        onInputChange={(text) => inputChange(text)}
        onChange={(value) => setSelected(value as string)}
        onBlur={onBlur}
      />
    </div>
  );
};
export default System;
