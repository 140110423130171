import Layout from "Components/Layout/Layout";
import SettingsLayout from "Components/Settings/Layout/Layout";
import LaborRateUpdate from "Containers/Settings/Rate/LaborRate/LaborRateUpdate/LaborRateUpdate";
import React from "react";
import { BreadcrumbItem } from "Components/Breadcrumb/Breadcrumb";
import { useBreadcrumb } from "Shared/Hooks/use-breadcrumb";
import { useSelector } from "react-redux";
import { selectLaborRate } from "Redux/LaborRate/slice";

export const LaborRateUpdatePages: React.FC = () => {
  const laborRate = useSelector(selectLaborRate);

  const breads: BreadcrumbItem[] = [
    { title: "Settings", link: "/settings/rate-settings/labor-rate" },
    { title: "Labor Rate", link: "/settings/rate-settings/labor-rate" },
    { title: `Update ${laborRate?.name || ""}`, link: "" },
  ];
  useBreadcrumb({ links: breads, option: { upd: laborRate?.name } });

  return (
    <Layout>
      <SettingsLayout>
        <LaborRateUpdate />
      </SettingsLayout>
    </Layout>
  );
};
