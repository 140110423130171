import { ITaxRate } from "Shared/Types/taxRate";
import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "Redux/store";
import {
  addTaxRate,
  deleteTaxRate,
  getTaxRate,
  getTaxRateList,
  updateTaxRate,
} from "Redux/TaxRate/recuder";

interface ITaxRateState {
  loading: boolean;
  taxRateList?: ITaxRate[];
  taxRate?: ITaxRate;
}

const initialState: ITaxRateState = {
  loading: false,
  taxRateList: [],
  taxRate: undefined,
};

const TaxRateSlice = createSlice({
  name: "taxRate",
  initialState,
  reducers: {
    removeCurrentTaxRate(state) {
      state.taxRate = undefined;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getTaxRateList.pending, (state) => {
      state.loading = true;
      state.taxRateList = [];
    });
    builder.addCase(getTaxRateList.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(getTaxRateList.fulfilled, (state, action) => {
      state.loading = false;
      state.taxRateList = action.payload.results;
    });

    builder.addCase(getTaxRate.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getTaxRate.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(getTaxRate.fulfilled, (state, action) => {
      state.loading = false;
      state.taxRate = action.payload;
    });

    builder.addCase(addTaxRate.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(addTaxRate.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(addTaxRate.fulfilled, (state) => {
      state.loading = false;
    });

    builder.addCase(deleteTaxRate.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(deleteTaxRate.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(deleteTaxRate.fulfilled, (state) => {
      state.loading = false;
    });

    builder.addCase(updateTaxRate.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateTaxRate.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(updateTaxRate.fulfilled, (state) => {
      state.loading = false;
    });
  },
});

export const { removeCurrentTaxRate } = TaxRateSlice.actions;

export const selectTaxRateList = (state: RootState) =>
  state.taxRate.taxRateList;
export const selectTaxRate = (state: RootState) => state.taxRate.taxRate;

export const selectTaxRateLoading = (state: RootState) => state.taxRate.loading;

export default TaxRateSlice;
