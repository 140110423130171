import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "Service/api/api";
import { readObj } from "Service/storage";
import {
  IDispatchCallback,
  IDispatchCallbackWithId,
  IDispatchCallbackWithoutData,
} from "Shared/Types/common";
import {
  IServiceSearchParams,
  IServiceAdd,
  IServiceUpdate,
} from "Shared/Types/service";
import { errorHandler } from "Shared/Utils/app";

const getServiceList = createAsyncThunk(
  "service/getServiceList",
  async (params: IServiceSearchParams | null) => {
    try {
      const shop = readObj("current_shop");
      const response = await api.service.getServiceList({
        ...params,
        shop: shop?.id,
      });
      return response.data;
    } catch (error) {
      if (error instanceof Error) {
        errorHandler(error);
      }
      throw error;
    }
  }
);

const getService = createAsyncThunk(
  "service/getService",
  async (id: string) => {
    try {
      const response = await api.service.getService(id);
      return response.data;
    } catch (error) {
      if (error instanceof Error) {
        errorHandler(error);
      }
      throw error;
    }
  }
);

const addService = createAsyncThunk(
  "service/addService",
  async ({ data, onSuccess }: IDispatchCallback<IServiceAdd>) => {
    try {
      const response = await api.service.addService(data);
      onSuccess?.();
      return response.data;
    } catch (error) {
      if (error instanceof Error) {
        errorHandler(error, true);
      }
      throw error;
    }
  }
);

const updateService = createAsyncThunk(
  "service/updateService",
  async ({ id, data, onSuccess }: IDispatchCallbackWithId<IServiceUpdate>) => {
    try {
      const response = await api.service.updateService(id, data);
      onSuccess?.();
      return response.data;
    } catch (error) {
      if (error instanceof Error) {
        errorHandler(error, true);
      }
      throw error;
    }
  }
);

const deleteService = createAsyncThunk(
  "service/deleteService",
  async ({ id, onSuccess }: IDispatchCallbackWithoutData) => {
    try {
      const response = await api.service.deleteService(id);
      onSuccess?.();
      return response.data;
    } catch (error) {
      if (error instanceof Error) {
        errorHandler(error, true);
      }
      throw error;
    }
  }
);

export { getServiceList, getService, addService, updateService, deleteService };
