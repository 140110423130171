import React, { useEffect, useMemo } from "react";
import styles from "./Invoiced.module.scss";
import { Grid } from "@mui/material";
import SelectField from "Components/UI/SelectField/SelectField";
import { useSelector } from "react-redux";
import { selectTaxRateList } from "Redux/TaxRate/slice";
import { useAppDispatch } from "Redux/store";
import { getTaxRateList } from "Redux/TaxRate/recuder";
import { updateOrderByField } from "Redux/Order/reducer";
import { IOrder } from "Shared/Types/order";
import { getSupplyRateList } from "Redux/SupplyRate/reducer";
import { selectSupplyRateList } from "Redux/SupplyRate/slice";
import { getOrderLaborTotal, getOrderPartsTotal } from "Shared/Utils/order";
import { selectOrderActionItemParts } from "Redux/Order/slice";
import Block from "Components/UI/Block/Block";
import Divider from "Components/UI/Divider/Divider";
import { notifySuccess } from "Components/Notify/notify";

type InvoicedProps = {
  order: IOrder | null;
};

const Invoiced: React.FC<InvoicedProps> = ({ order }) => {
  const taxRateList = useSelector(selectTaxRateList) || [];
  const supplyRateList = useSelector(selectSupplyRateList) || [];
  const parts = useSelector(selectOrderActionItemParts) || [];
  const dispatch = useAppDispatch();
  const selectedTaxRate = useMemo(() => {
    return taxRateList.find((rate) => rate.id === order?.tax_rate);
  }, [taxRateList, order]);
  const laborTotals = useMemo(() => {
    if (!order) return 0;
    return getOrderLaborTotal(order);
  }, [order]);
  const partTotals = useMemo(() => {
    return getOrderPartsTotal(parts);
  }, [parts]);

  const supplyTotal = useMemo(() => {
    return supplyRateList.find((supply) => supply.default)?.rate || 0;
  }, [supplyRateList, order]);
  const priceTotal = useMemo(() => {
    return (
      +laborTotals +
      +partTotals +
      +(selectedTaxRate?.rate || 0) +
      +supplyTotal
    ).toFixed(2);
  }, [laborTotals, partTotals, selectedTaxRate, supplyTotal]);

  const saveTaxRate = (id: string) => {
    if (order?.id) {
      dispatch(
        updateOrderByField({
          data: { field: "tax_rate", value: id },
          id: order.id,
          onSuccess: () => {
            notifySuccess("Tax rate is updated");
          },
        })
      );
    }
  };
  useEffect(() => {
    dispatch(getTaxRateList());
    dispatch(getSupplyRateList());
  }, []);

  if (!order) return <></>;

  return (
    <Block isBorder className={styles.invoice}>
      <div className={styles.invoice__head}>Invoiced</div>
      <Grid container width="70%" className={styles.invoice__content} gap={1}>
        <Grid container item xs={12} mb="10px">
          <Grid item xs={4}>
            Shop Supplier:
          </Grid>
          <Grid item xs={8}>
            $ {supplyTotal}
          </Grid>
        </Grid>
        <Grid
          container
          item
          xs={12}
          mb="8px"
          display="flex"
          alignItems="center"
        >
          <Grid item xs={4}>
            Select Tax:
          </Grid>
          <Grid item xs={8}>
            <SelectField
              name="Tax Rate"
              options={taxRateList.map((rate) => ({
                label: rate.title,
                value: rate.id,
              }))}
              value={selectedTaxRate?.id}
              onChange={(value) => {
                saveTaxRate(value as string);
              }}
            />
          </Grid>
        </Grid>
        <Grid container item xs={12} mb="10px">
          <Grid item xs={4}>
            Tax:
          </Grid>
          <Grid item xs={8}>
            $ {+(selectedTaxRate?.rate || 0)}
          </Grid>
        </Grid>
        <Grid container item xs={12} mb="10px">
          <Grid item xs={4}>
            Labor:
          </Grid>
          <Grid item xs={8}>
            $ {laborTotals}
          </Grid>
        </Grid>
        <Grid
          container
          item
          xs={12}
          className={styles.invoice__content__item}
          mb="10px"
        >
          <Grid item xs={4}>
            Parts:
          </Grid>
          <Grid item xs={8}>
            $ {partTotals}
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid container item xs={12} className={styles.invoice__content__total}>
          <Grid item xs={4}>
            Total:
          </Grid>
          <Grid item xs={8}>
            $ {priceTotal}
          </Grid>
        </Grid>
      </Grid>
    </Block>
  );
};
export default Invoiced;
