import { updateActionPartByField } from "Redux/ActionPart/reducer";
import { selectActionPartStatuses } from "Redux/ActionPart/slice";
import { getOrderActionItemParts } from "Redux/Order/reducer";
import { selectCurrentOrder } from "Redux/Order/slice";
import { useAppDispatch } from "Redux/store";
import { IActionPart } from "Shared/Types/action-item-part";
import { useSelector } from "react-redux";

export const useSaveActionItemPart = () => {
  const dispatch = useAppDispatch();
  const partStatuses = useSelector(selectActionPartStatuses);
  const currentOrder = useSelector(selectCurrentOrder);

  const saveStatusActionItemPart = (
    statusQueue: number,
    actionItemParts: IActionPart[]
  ) => {
    const partStatusId = partStatuses.find(
      (status) => status.queue === statusQueue
    )?.id;
    actionItemParts.forEach((part, i) => {
      dispatch(
        updateActionPartByField({
          id: part?.id as string,
          data: { field: "status", value: partStatusId },
          onSuccess: () => {
            if (i === actionItemParts.length - 1) {
              dispatch(getOrderActionItemParts({ order: currentOrder?.id }));
            }
          },
        })
      );
    });
  };

  return { saveStatusActionItemPart };
};
