import React from "react";
import layout_styles from "Components/Layout/AuthLayout/AuthLayout.module.scss";
import cn from "classnames";
import SuccessRegisterConfirmationModal from "Containers/Auth/SuccessRegisterConfirmationModal";

type RegisterSuccessFullProps = {
  firstName: string;
};

const RegisterSuccessFull: React.FC<RegisterSuccessFullProps> = ({
  firstName,
}) => {
  return (
    <div className={layout_styles.wrapper}>
      <div className={layout_styles.wrapper__bg}></div>
      <div className={layout_styles.container}>
        <div className={cn(layout_styles.form_cont, layout_styles.modal_cont)}>
          <SuccessRegisterConfirmationModal name={firstName} />
        </div>
      </div>
    </div>
  );
};

export default RegisterSuccessFull;
