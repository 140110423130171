import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "Service/api/api";
import {
  IDispatchCallback,
  IDispatchCallbackWithId,
  IDispatchCallbackWithoutData,
} from "Shared/Types/common";
import {
  IContactAdd,
  IContactSearchParams,
  IContactUpdate,
} from "Shared/Types/contact";
import { errorHandler } from "Shared/Utils/app";

const getContact = createAsyncThunk(
  "contact/getContact",
  async (id: string) => {
    try {
      const response = await api.contact.getContact(id);
      return response.data;
    } catch (error) {
      if (error instanceof Error) {
        errorHandler(error);
      }
      throw error;
    }
  }
);

const getContactList = createAsyncThunk(
  "contact/getContactList",
  async (params: IContactSearchParams | null) => {
    try {
      const response = await api.contact.getContactList({ ...params });
      return response.data;
    } catch (error) {
      if (error instanceof Error) {
        errorHandler(error);
      }
      throw error;
    }
  }
);

const createContact = createAsyncThunk(
  "contact/createContactList",
  async ({ data, onSuccess }: IDispatchCallback<IContactAdd>) => {
    try {
      const response = await api.contact.addContact({
        ...data,
        cell_phone: data.phone,
        customer: data.customer.id,
      });
      onSuccess?.();
      return response.data;
    } catch (error) {
      if (error instanceof Error) {
        errorHandler(error, true);
      }
      throw error;
    }
  }
);

const deleteContact = createAsyncThunk(
  "contact/deleteContact",
  async ({ id, onSuccess }: IDispatchCallbackWithoutData) => {
    try {
      const response = await api.contact.deleteContact(id);
      onSuccess?.();
      return response.data;
    } catch (error) {
      if (error instanceof Error) {
        errorHandler(error, true);
      }
      throw error;
    }
  }
);

const updateContact = createAsyncThunk(
  "contact/updateContact",
  async ({ id, data, onSuccess }: IDispatchCallbackWithId<IContactUpdate>) => {
    try {
      const response = await api.contact.updateContact(id, {
        ...data,
        customer: data.customer.id,
      });
      onSuccess?.();
      return response.data;
    } catch (error) {
      if (error instanceof Error) {
        errorHandler(error, true);
      }
      throw error;
    }
  }
);

export {
  getContactList,
  createContact,
  deleteContact,
  updateContact,
  getContact,
};
