import { configureStore } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";
import userSlice from "./User/userSlice";
import companyRequestSlice from "./CompanyRequests/slice";
import authSlice from "./Auth/slice";
import companySlice from "./Company/slice";
import breadcrumbSlice from "./App/slice";
import roleSlice from "./Role/slice";
import categorySlice from "./PartSettings/Categories/slice";
import manufacturerSlice from "./PartSettings/Manufacturer/slice";
import locationSlice from "./PartSettings/Location/slice";
import addressSlice from "./Address/slice";
import unitSlice from "./Unit/slice";
import componentSlice from "Redux/Component/componentSlice";
import systemSlice from "Redux/System/systemSlice";
import shopSlice from "Redux/Shop/slice";
import laborRateSlice from "Redux/LaborRate/slice";
import unitTypeSlice from "./UnitType/slice";
import subtypeSlice from "./Subtype/slice";
import serviceSlice from "./Service/slice";
import customerSlice from "./Customer/slice";
import taxRateSlice from "./TaxRate/slice";
import supplyRateSlice from "./SupplyRate/slice";
import vendorSlice from "./Vendor/slice";
import contactSlice from "./Contact/slice";
import actionItemSlice from "./ActionItem/slice";
import orderSlice from "./Order/slice";
import actionPartSlice from "./ActionPart/slice";
import partSlice from "./Product/slice";
import urgencyOptionSlice from "Redux/UrgencyOption/slice";
import globalSearchSlice from "./GlobalSearch/slice";
import historySlice from "./History/slice";
import actionItemNoteSlice from "./ActionItemNotes/slice";
import orderNoteSlice from "./OrderNote/slice";
import notificationSlice from "./Notification/slice";
import orderInvoiceSlice from "./OrderInvoice/slice";
import estimateSlice from "Redux/Estimate/slice";
import purchaseOrderSlice from "./PurchaseOrder/slice";

const store = configureStore({
  reducer: {
    user: userSlice.reducer,
    companyRequest: companyRequestSlice.reducer,
    company: companySlice.reducer,
    role: roleSlice.reducer,
    auth: authSlice.reducer,
    breadcrumb: breadcrumbSlice.reducer,
    category: categorySlice.reducer,
    manufacturer: manufacturerSlice.reducer,
    location: locationSlice.reducer,
    component: componentSlice.reducer,
    system: systemSlice.reducer,
    address: addressSlice.reducer,
    unit: unitSlice.reducer,
    shop: shopSlice.reducer,
    laborRate: laborRateSlice.reducer,
    unitType: unitTypeSlice.reducer,
    subtype: subtypeSlice.reducer,
    service: serviceSlice.reducer,
    customer: customerSlice.reducer,
    taxRate: taxRateSlice.reducer,
    supplyRate: supplyRateSlice.reducer,
    vendor: vendorSlice.reducer,
    contact: contactSlice.reducer,
    actionItem: actionItemSlice.reducer,
    order: orderSlice.reducer,
    actionPart: actionPartSlice.reducer,
    part: partSlice.reducer,
    urgencyOption: urgencyOptionSlice.reducer,
    globalSearch: globalSearchSlice.reducer,
    history: historySlice.reducer,
    actionItemNote: actionItemNoteSlice.reducer,
    orderNote: orderNoteSlice.reducer,
    notification: notificationSlice.reducer,
    orderInvoice: orderInvoiceSlice.reducer,
    estimate: estimateSlice.reducer,
    purchaseOrder: purchaseOrderSlice.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();

export default store;
