import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "Service/api/api";
import { errorHandler } from "Shared/Utils/app";

const getCountries = createAsyncThunk("address/getCountries", async () => {
  try {
    const response = await api.address.getCountries();
    return response.data.geonames;
  } catch (error) {
    if (error instanceof Error) {
      errorHandler(error);
    }
    throw error;
  }
});

const getStates = createAsyncThunk(
  "address/getStates",
  async (country: number) => {
    try {
      const response = await api.address.getStates(country);
      return response.data.geonames;
    } catch (error) {
      if (error instanceof Error) {
        errorHandler(error);
      }
      throw error;
    }
  }
);

const getCities = createAsyncThunk(
  "address/getCities",
  async (geonameId: number) => {
    try {
      const response = await api.address.getStates(geonameId);
      return response.data.geonames;
    } catch (error) {
      if (error instanceof Error) {
        errorHandler(error);
      }
      throw error;
    }
  }
);

export { getCountries, getStates, getCities };
