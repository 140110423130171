import { IRole, IRolesAdd, IRolesUpdate } from "Shared/Types/role";
import React, { useState } from "react";
import styles from "./Form.module.scss";
import InputField from "Components/UI/Inputs/InputField";
import Button from "Components/UI/Button/Button";
import { roleList } from "Shared/Constants/role";
import RoleItem from "../RoleItem/RoleItem";
import { notifyError } from "Components/Notify/notify";
import { useSelector } from "react-redux";
import { selectPermissions } from "Redux/Role/slice";

type FormProps = {
  onSubmit: (values: IRolesAdd | IRolesUpdate) => void;
  role?: IRole;
};

const RoleForm: React.FC<FormProps> = ({ onSubmit, role }) => {
  const [name, setName] = useState(role?.name);
  const permissions = useSelector(selectPermissions) || [];
  const [curPermissions, setCurPermissions] = useState(
    new Set<string>(role?.permissions)
  );

  const onSave = () => {
    if (!name) {
      notifyError("Name is required");
      return;
    }
    if (!curPermissions.size) {
      notifyError("Permissions is required");
      return;
    }

    const arr: string[] = [];
    curPermissions.forEach((per) => {
      const perId = permissions.find(
        (backendPer) => backendPer.codename === per
      )?.id;
      if (perId) {
        arr.push(perId);
      }
    });
    onSubmit({
      name,
      permissions: arr,
    });
  };

  const changeRoles = (permissions: string[] = [], isAdd: boolean) => {
    const newSet = curPermissions;
    permissions.forEach((per) => {
      if (!isAdd) {
        newSet.delete(per);
      } else {
        newSet.add(per);
      }
    });
    setCurPermissions(newSet);
  };

  return (
    <div>
      <InputField
        name="name"
        label="Write the name of the role"
        value={name}
        onChange={(event) => setName(event.target.value)}
      />
      <div className={styles.form__permissions__label}>Permissions</div>
      {roleList?.map((role) => (
        <RoleItem
          role={role}
          key={role.title}
          changeRoles={changeRoles}
          curPermissions={curPermissions}
        />
      ))}
      <div className={styles.form__actions}>
        {role ? (
          <Button variant="outlined" type="button" onClick={onSave}>
            Save
          </Button>
        ) : (
          <Button type="button" onClick={onSave}>
            Create
          </Button>
        )}
      </div>
    </div>
  );
};
export default RoleForm;
