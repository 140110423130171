import { Breadcrumbs } from "@mui/material";
import React, { ReactNode } from "react";
import styles from "./Breadcrumb.module.scss";
import { Link } from "react-router-dom";

export type BreadcrumbItem = {
  title: string;
  link: string;
};
type BreadcrumbProps = {
  breads: BreadcrumbItem[];
  children?: ReactNode;
};

const Breadcrumb: React.FC<BreadcrumbProps> = ({ breads, children }) => {
  return (
    <div className={styles.container}>
      <Breadcrumbs separator="&#124;" className={styles.breadcrumb}>
        {breads.map(({ title, link }, i) =>
          i === breads.length - 1 ? (
            <div className={styles.breadcrumb__current_text} key={title}>
              <span className={styles.point}></span>
              <span>{title}</span>
            </div>
          ) : (
            <Link className={styles.breadcrumb__text} key={title} to={link}>
              {title}
            </Link>
          ),
        )}
      </Breadcrumbs>
      {children}
    </div>
  );
};
export default Breadcrumb;
