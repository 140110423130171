import { IComponent } from "Shared/Types/component";
import { createSlice } from "@reduxjs/toolkit";
import {
  createComponent,
  deleteComponent,
  getComponent,
  getComponentList,
  updateComponent,
} from "Redux/Component/reducers";
import { RootState } from "Redux/store";
import { ISystem } from "Shared/Types/system";

type ICurrentComponet = {
  component: IComponent;
  systems: ISystem[];
};

interface IComponentState {
  loading: boolean;
  componentList?: IComponent[];
  currentComponent?: ICurrentComponet | null;
  openComponents: ICurrentComponet[];
}

const initialState: IComponentState = {
  loading: false,
  componentList: [],
  openComponents: [],
};

const componentSlice = createSlice({
  name: "component",
  initialState,
  reducers: {
    clear(state) {
      state.loading = false;
    },
    removeCurrentCustomer(state) {
      state.currentComponent = null;
    },
    removeCurrentComponent(state) {
      state.currentComponent = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getComponentList.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getComponentList.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(getComponentList.fulfilled, (state, action) => {
      state.loading = false;
      state.componentList = action.payload.results;
    });

    builder.addCase(getComponent.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getComponent.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(getComponent.fulfilled, (state, action) => {
      state.loading = false;
      state.currentComponent = {
        component: action.payload.component,
        systems: action.payload.systems,
      };
    });

    builder.addCase(createComponent.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(createComponent.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(createComponent.fulfilled, (state, action) => {
      state.loading = false;
      state.currentComponent = { component: action.payload, systems: [] };
    });

    builder.addCase(deleteComponent.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(deleteComponent.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(updateComponent.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateComponent.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(updateComponent.fulfilled, (state) => {
      state.loading = false;
    });
  },
});

export const { clear, removeCurrentCustomer, removeCurrentComponent } =
  componentSlice.actions;

export const selectComponentList = (state: RootState) =>
  state.component.componentList;
export const selectCurrentComponent = (state: RootState) =>
  state.component.currentComponent;
export const selectOpenComponents = (state: RootState) =>
  state.component.openComponents;

export default componentSlice;
