export const actionTableHead = [
  {
    label: "Complain",
    id: "complain",
    width: "20%",
  },
  {
    label: "Cause",
    id: "cause",
    width: "20%",
  },
  {
    label: "Labor Rate",
    id: "labor_rate",
    width: "10%",
  },
  {
    label: "Rate",
    id: "rate",
    width: "10%",
  },
  {
    label: "Amount",
    id: "amount",
    width: "10%",
  },
  {
    label: "",
    id: "price",
    width: "10%",
  },
  {
    label: "",
    id: "price",
    width: "10%",
  },
  {
    label: "",
    id: "price",
    width: "10%",
  },
];

export const actionItemPartTableHead = [
  {
    label: "Part name",
    id: "description",
    width: "20%",
  },
  {
    label: "#",
    id: "part_number",
    width: "20%",
  },
  {
    label: "Q-ty",
    id: "quantity",
    width: "10%",
  },
  {
    label: "Selling price",
    id: "selling_price",
    width: "10%",
  },
  {
    label: "Amount",
    id: "amount",
    width: "10%",
  },
  {
    label: "Price",
    id: "price",
    width: "10%",
  },
  {
    label: "Markup",
    id: "markup",
    width: "10%",
  },
  {
    label: "Margin",
    id: "margin",
    width: "10%",
  },
];

export const invoiceActionItemPartTableHead = [
  {
    label: "Part name",
    id: "name",
    width: "70%",
    pdfWidth: "55%",
  },
  {
    label: "Q-ty",
    id: "quantity",
    width: "10%",
    pdfWidth: "15%",
  },

  {
    label: "Price",
    id: "price",
    width: "10%",
    pdfWidth: "15%",
  },
  {
    label: "Total",
    id: "amount",
    width: "10%",
    pdfWidth: "15%",
  },
];

export const actionItemPartFields = [
  "Description",
  "QTY",
  "Part #",
  "Cost",
  "Vendor",
];
