import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "Service/api/api";
import {
  IDispatchCallback,
  IDispatchCallbackWithId,
  IDispatchCallbackWithoutData,
} from "Shared/Types/common";
import { ITaxRateAdd, ITaxRateUpdate } from "Shared/Types/taxRate";
import { errorHandler } from "Shared/Utils/app";

const getTaxRateList = createAsyncThunk("taxRate/getTaxRateList", async () => {
  try {
    const response = await api.taxRate.getTaxRateList();
    return response.data;
  } catch (error) {
    if (error instanceof Error) {
      errorHandler(error, true);
    }
    throw error;
  }
});

const getTaxRate = createAsyncThunk(
  "taxRate/getTaxRate",
  async (id: string) => {
    try {
      const response = await api.taxRate.getTaxRate(id);
      return response.data;
    } catch (error) {
      if (error instanceof Error) {
        errorHandler(error, true);
      }
      throw error;
    }
  },
);

const addTaxRate = createAsyncThunk(
  "taxRate/createTaxRate",
  async ({ data, onSuccess }: IDispatchCallback<ITaxRateAdd>) => {
    try {
      const response = await api.taxRate.createTaxRate(data);
      onSuccess?.();
      return response.data;
    } catch (error) {
      if (error instanceof Error) {
        errorHandler(error, true);
      }
      throw error;
    }
  },
);

const updateTaxRate = createAsyncThunk(
  "taxRate/updateTaxRate",
  async ({ id, data, onSuccess }: IDispatchCallbackWithId<ITaxRateUpdate>) => {
    try {
      const response = await api.taxRate.updateTaxRate(id, data);
      onSuccess?.();
      return response.data;
    } catch (error) {
      if (error instanceof Error) {
        errorHandler(error, true);
      }
      throw error;
    }
  },
);

const deleteTaxRate = createAsyncThunk(
  "taxRate/deleteTaxRate",
  async ({ id, onSuccess }: IDispatchCallbackWithoutData) => {
    try {
      const response = await api.taxRate.deleteTaxRate(id);
      onSuccess?.();
      return response.data;
    } catch (error) {
      if (error instanceof Error) {
        errorHandler(error, true);
      }
      throw error;
    }
  },
);

export { getTaxRateList, addTaxRate, deleteTaxRate, updateTaxRate, getTaxRate };
