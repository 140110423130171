import React from "react";
import styles from "./CustomerForm.module.scss";
import { Grid } from "@mui/material";
import InputField from "Components/UI/Inputs/InputField";
import Checkbox from "Components/UI/Inputs/Checkbox";
import { useFormContext } from "react-hook-form";
import SelectField from "Components/UI/SelectField/SelectField";
import { customerPriceLevel } from "Shared/Constants/customers";

type CompanyDetailsProps = {
  active: boolean;
  setActive: (active: boolean) => void;
};

const CompanyDetails = ({ active, setActive }: CompanyDetailsProps) => {
  const { control, setValue } = useFormContext();
  return (
    <div>
      <h4 className={styles.form__form__title}>Company Details</h4>
      <Grid container spacing={1} rowSpacing={-1} mb="10px">
        <Grid item xs={12}>
          <Checkbox
            label="Active"
            name="status"
            checked={active}
            onChange={(_, checked) => {
              setActive(checked);
              setValue("status", checked ? "active" : "inactive");
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <InputField
            name="company_name"
            label="Company name"
            required
            withController
            control={control}
          />
        </Grid>
        <Grid item xs={12}>
          <InputField
            name="first_name"
            label="First name"
            required
            withController
            control={control}
          />
        </Grid>

        <Grid item xs={12}>
          <InputField
            name="last_name"
            label="Last name"
            required
            withController
            control={control}
          />
        </Grid>

        <Grid item xs={12}>
          <InputField
            name="email"
            label="Email"
            required
            withController
            control={control}
          />
        </Grid>

        <Grid item xs={12}>
          <InputField
            name="phone"
            label="Phone number"
            required
            withController
            control={control}
          />
        </Grid>
        <Grid item xs={12}>
          <InputField
            name="cell_phone"
            label="Cell number"
            required
            withController
            control={control}
          />
        </Grid>
        <Grid item xs={12}>
          <SelectField
            name="customer_price_level"
            label="Customer Price Level"
            options={customerPriceLevel}
            withControl
            control={control}
            required
          />
        </Grid>
      </Grid>
    </div>
  );
};
export default CompanyDetails;
