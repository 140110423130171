import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { ILaborRate } from "Shared/Types/laborRate";
import AcynsSelectField from "Components/UI/AcyncSelect/AcyncSelect";
import {
  selectLaborRateList,
  selectLaborRateLoading,
} from "Redux/LaborRate/slice";
import { useAppDispatch } from "Redux/store";
import { getLaborRateList } from "Redux/LaborRate/reducer";
import { IActionItemFields } from "Shared/Types/order";
import { changeLaborRateOptions } from "Shared/Utils/app";

type LaborRateFieldProps = {
  saveActionItemField: (field: IActionItemFields, value: string) => void;
  laborRate?: ILaborRate | null;
};

const LaborRateField: React.FC<LaborRateFieldProps> = ({
  saveActionItemField,
  laborRate,
}) => {
  const laborRateList = useSelector(selectLaborRateList) || [];
  const loading = useSelector(selectLaborRateLoading);
  const dispatch = useAppDispatch();
  const [selected, setSelected] = useState<string>();
  const inputChange = (text: string | null) => {
    dispatch(getLaborRateList({ search: text }));
  };

  const onBlur = () => {
    if (selected) {
      saveActionItemField("labor_rate", selected);
    }
  };

  useEffect(() => {
    inputChange(null);
  }, []);
  return (
    <AcynsSelectField
      options={changeLaborRateOptions(laborRateList)}
      label="Labor Rate"
      loading={loading}
      defaultValue={{
        label: laborRate?.name || "",
        value: laborRate?.id || "",
      }}
      onInputChange={(text) => inputChange(text)}
      onChange={(value) => setSelected(value as string)}
      onBlur={onBlur}
    />
  );
};
export default LaborRateField;
