import { IOrder } from "Shared/Types/order";
import React, { useEffect, useState } from "react";
import OrderRow from "../OrderBasicHead/OrderBasicHead";
import styles from "./OrderBasicInformation.module.scss";
import Icon from "Components/UI/Icon/Icon";
import { ArrowDownIcon, EditIcon } from "Assets/index";
import InfoField from "Components/UI/InfoField/InfoField";
import { Grid } from "@mui/material";
import IconButton from "Components/UI/IconButton/IconButton";
import { format } from "Shared/Utils/app";
import { useSelector } from "react-redux";
import { useAppDispatch } from "Redux/store";
import { getUrgencyOptionList } from "Redux/UrgencyOption/reducer";
import { selectAccount } from "Redux/Auth/slice";
import {
  getAccessAndStatusFields,
  getBasicInformationFields,
  getOrdreCardData,
  getSchedulingAccessFields,
} from "Shared/Utils/order";
import DatePicker from "Components/UI/DatePicker/DatePicker";
import { updateOrderByField } from "Redux/Order/reducer";
import SelectField from "Components/UI/SelectField/SelectField";
import { selectUrgencyOptionList } from "Redux/UrgencyOption/slice";
import { selectUserList } from "Redux/User/userSlice";
import { getUserList } from "Redux/User/reducer";
import { notifySuccess } from "Components/Notify/notify";

type OrderBasicInformationProps = {
  order?: IOrder | null;
  showDetail: boolean;
  setShowDetail?: (value: boolean) => void;
};

const OrderBasicInformation: React.FC<OrderBasicInformationProps> = ({
  order,
  setShowDetail,
  showDetail,
}) => {
  const [isEdit, setIsEdit] = useState(false);
  const profile = useSelector(selectAccount);
  const urgencyOptions = useSelector(selectUrgencyOptionList);
  const users = useSelector(selectUserList) || [];
  const dispatch = useAppDispatch();

  const urgencyOptionList = urgencyOptions.map((option) => ({
    label: option.title,
    value: option.id,
  }));

  const handleEdit = () => {
    setIsEdit((prevState) => !prevState);
  };

  const handleOnBlur = (
    field: keyof IOrder,
    value: string | number | null | undefined
  ) => {
    const onSuccess = () => {
      notifySuccess(`${field} is updated`);
    };
    if (typeof value === "string" && order?.id) {
      dispatch(
        updateOrderByField({
          id: order.id,
          data: { field: field, value: value },
          onSuccess,
        })
      );
    }
  };
  useEffect(() => {
    if (profile?.company?.id) {
      dispatch(getUrgencyOptionList({ company: profile?.company?.id }));
    }
    dispatch(getUserList({}));
  }, [profile]);

  if (!showDetail)
    return (
      <OrderRow
        onClick={() => setShowDetail?.(true)}
        className={styles.order__row}
        orderData={order ? getOrdreCardData(order) : []}
      />
    );

  return (
    <div className={styles.order}>
      <div className={styles.order__head}>
        <h3>Basic Information</h3>
        <Icon
          component={ArrowDownIcon}
          onClick={() => setShowDetail?.(false)}
        />
      </div>
      <div className={styles.order__customer}>
        <Grid container>
          {getBasicInformationFields(order).map((field, index) => (
            <Grid item md={4} key={index}>
              <InfoField
                label={field.label}
                labelColor={field.labelColor}
                valueColor={field.valueColor}
                value={field.value}
                value2={field.value2}
              />
            </Grid>
          ))}
        </Grid>
      </div>
      <div className={styles.order__access}>
        <div className={styles.order__access__head}>
          <h3>Scheduling & Access</h3>
          <div className={styles.order__access__head__actions}>
            <IconButton
              variant="primary"
              icon={EditIcon}
              onClick={handleEdit}
            />
          </div>
        </div>
        <Grid container marginTop="12px">
          {getSchedulingAccessFields(order).map((field) => (
            <Grid item md={4} key={field.name}>
              {isEdit ? (
                <DatePicker
                  name={field.name}
                  label={field.label}
                  value={field.value}
                  handleOnBlur={handleOnBlur}
                  borderColor={field.borderColor}
                />
              ) : (
                <InfoField
                  label={field.label}
                  value={format(field.value)}
                  borderColor={field.borderColor}
                />
              )}
            </Grid>
          ))}
        </Grid>
      </div>
      <div className={styles.order__schedul}>
        <div className={styles.order__schedul__head}>
          <h3>Access & Status</h3>
        </div>
        <Grid container columnGap={3} rowGap={1}>
          {getAccessAndStatusFields(order, urgencyOptionList, users).map(
            (field) => (
              <Grid item md={5.7} key={field.name}>
                {isEdit ? (
                  <SelectField
                    name={field.name}
                    label={field.label}
                    value={field.value}
                    options={field.options}
                    onBlur={(value) =>
                      handleOnBlur(field.name as keyof IOrder, value)
                    }
                  />
                ) : (
                  <InfoField label={field.label} value={field.value} />
                )}
              </Grid>
            )
          )}
        </Grid>
      </div>
    </div>
  );
};
export default OrderBasicInformation;
