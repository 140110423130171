import * as pages from "Pages/index";
import { FC } from "react";
import { PERMISSIONS } from "Shared/Constants/app";
import { orderStatuses } from "Shared/Constants/order";
import { PURCHASE_ORDER_ROUTE } from "Shared/Constants/purchase-order";

export interface RouteData {
  permissions: ReadonlyArray<string>;
  page: FC;
}

export const routeMap = new Map<string, RouteData>()
  .set("/login", {
    page: pages.Login,
    permissions: [],
  })
  .set("/forgot-password-email", {
    page: pages.ForgotPasswordByEmail,
    permissions: [],
  })
  .set("/password/reset/confirm/:uid/:token", {
    page: pages.ResetPassword,
    permissions: [],
  })
  .set("/first_auth/:uid/:token", {
    page: pages.FirstAuth,
    permissions: [],
  })
  .set("/register", {
    page: pages.Register,
    permissions: [],
  })
  .set("/profile", {
    page: pages.ProfilePage,
    permissions: [],
  })
  .set("/returns", {
    page: pages.ReturnsPage,
    permissions: [],
  })
  .set("/stats", {
    page: pages.StatsPage,
    permissions: [],
  })
  .set("/not-found", {
    page: pages.Home2,
    permissions: [],
  })
  .set("/forbidden", {
    page: pages.Home2,
    permissions: [],
  })
  .set("/healthcheck", {
    page: pages.HealthCheck,
    permissions: [],
  })
  .set("/employees", {
    page: pages.EmployeePage,
    permissions: [PERMISSIONS.view_user],
  })
  .set("/employees/new", {
    page: pages.AddEmployee,
    permissions: [PERMISSIONS.add_user],
  })
  .set("/employees/info/:id", {
    page: pages.EmployeeInfoPage,
    permissions: [PERMISSIONS.view_user],
  })
  .set("/employees/edit/:id", {
    page: pages.EmployeeUpdatePage,
    permissions: [PERMISSIONS.change_user],
  })
  .set("/settings/unit-settings/unit-types", {
    page: pages.UnitTypeListPage,
    permissions: [],
  })
  .set("/settings/unit-settings/unit-types/:id", {
    page: pages.UnitTypeUpdatePage,
    permissions: [],
  })
  .set("/settings/unit-settings/unit-types/new", {
    page: pages.UnitTypeAddPage,
    permissions: [],
  })
  .set("/settings/unit-settings/subtypes", {
    page: pages.SubtypeListPage,
    permissions: [],
  })
  .set("/settings/unit-settings/subtypes/new", {
    page: pages.SubtypeAddPage,
    permissions: [],
  })
  .set("/settings/unit-settings/subtypes/:id", {
    page: pages.SubtypeUpdatePage,
    permissions: [],
  })
  .set("/units", {
    page: pages.UnitListPage,
    permissions: [PERMISSIONS.view_unit],
  })
  .set("/units/new", {
    page: pages.UnitAddPage,
    permissions: [PERMISSIONS.add_unit],
  })
  .set("/units/:id", {
    page: pages.UnitDetailPage,
    permissions: [PERMISSIONS.add_unit],
  })
  .set("/units/edit/:id", {
    page: pages.UnitUpdatePage,
    permissions: [PERMISSIONS.change_unit],
  })
  .set("/settings/component-system/:id", {
    page: pages.ComponentSystemUpdatePage,
    permissions: [],
  })
  .set("/settings/component-system/new", {
    page: pages.ComponentSystemAddPage,
    permissions: [],
  })
  .set("/settings/component-system", {
    page: pages.ComponentSystemListPage,
    permissions: [],
  })
  .set("/settings/roles", {
    page: pages.RolesListPages,
    permissions: [PERMISSIONS.view_role],
  })
  .set("/settings/roles/:id", {
    page: pages.RolesUpdatePages,
    permissions: [PERMISSIONS.change_role],
  })
  .set("/settings/roles/new", {
    page: pages.RolesAddPage,
    permissions: [PERMISSIONS.add_role],
  })
  .set("/settings/part-settings", {
    page: pages.CategoryListPages,
    permissions: [PERMISSIONS.view_category],
  })
  .set("/settings/part-settings/locations/new", {
    page: pages.LocationAddPage,
    permissions: [PERMISSIONS.view_location],
  })
  .set("/settings/part-settings/locations/:id", {
    page: pages.LocationUpdatePage,
    permissions: [PERMISSIONS.view_location],
  })
  .set("/settings/part-settings/locations", {
    page: pages.LocationListPages,
    permissions: [PERMISSIONS.view_location],
  })
  .set("/settings/part-settings/manufactures/:id", {
    page: pages.ManufactureUpdatePages,
    permissions: [PERMISSIONS.change_manufacturer],
  })
  .set("/settings/part-settings/manufactures/new", {
    page: pages.ManufactureAddPages,
    permissions: [PERMISSIONS.add_manufacturer],
  })
  .set("/settings/part-settings/manufactures", {
    page: pages.ManufactureListPages,
    permissions: [PERMISSIONS.view_manufacturer],
  })

  .set("/settings/part-settings/categories/new", {
    page: pages.CategoryAddPages,
    permissions: [PERMISSIONS.add_category],
  })
  .set("/settings/part-settings/categories/:id", {
    page: pages.CategoryUpdatePages,
    permissions: [PERMISSIONS.change_category],
  })
  .set("/settings/part-settings/categories", {
    page: pages.CategoryListPages,
    permissions: [PERMISSIONS.view_category],
  })
  .set("/settings/unit-types", {
    page: pages.UnitTypesPages,
    permissions: [PERMISSIONS.view_type],
  })
  .set("/settings/rate-settings/labor-rate", {
    page: pages.LaborRatePages,
    permissions: [PERMISSIONS.view_laborrate],
  })
  .set("/settings/rate-settings/labor-rate/new", {
    page: pages.LaborRateAddPages,
    permissions: [PERMISSIONS.add_laborrate],
  })
  .set("/settings/rate-settings/labor-rate/:id", {
    page: pages.LaborRateUpdatePages,
    permissions: [PERMISSIONS.change_laborrate],
  })
  .set("/settings/rate-settings/tax-rate", {
    page: pages.TaxRatePage,
    permissions: [PERMISSIONS.view_taxrate],
  })
  .set("/settings/rate-settings/tax-rate/new", {
    page: pages.TaxRateAddPage,
    permissions: [PERMISSIONS.add_taxrate],
  })
  .set("/settings/rate-settings/tax-rate/:id", {
    page: pages.TaxRateUpdatePage,
    permissions: [PERMISSIONS.change_taxrate],
  })
  .set("/settings/rate-settings/supply-rate", {
    page: pages.SupplyRatePage,
    permissions: [PERMISSIONS.view_supplyrate],
  })
  .set("/settings/rate-settings/supply-rate/new", {
    page: pages.SupplyRateAddPage,
    permissions: [PERMISSIONS.add_supplyrate],
  })
  .set("/settings/rate-settings/supply-rate/:id", {
    page: pages.SupplyRateUpdatePage,
    permissions: [PERMISSIONS.change_supplyrate],
  })
  .set("/shops", {
    page: pages.ShopListPage,
    permissions: [PERMISSIONS.view_shop],
  })
  .set("/shops/:id", {
    page: pages.ShopDetailPage,
    permissions: [PERMISSIONS.view_shop],
  })
  .set("/shops/edit/:id", {
    page: pages.ShopUpdatePage,
    permissions: [PERMISSIONS.change_shop],
  })
  .set("/shops/new", {
    page: pages.ShopAddPage,
    permissions: [PERMISSIONS.add_shop],
  })
  .set("/requests", {
    page: pages.Requests,
    permissions: [],
  })
  .set("/requests/all-requests", {
    page: pages.RequestsAllRequests,
    permissions: [],
  })
  .set("/requests/review", {
    page: pages.RequestsReview,
    permissions: [],
  })
  .set("/requests/review/:requestId", {
    page: pages.RequestsReviewDetails,
    permissions: [],
  })
  .set("/requests/rejected", {
    page: pages.RequestsReject,
    permissions: [],
  })
  .set("/requests/rejected/:requestId", {
    page: pages.RequestsRejectDetails,
    permissions: [],
  })
  .set("/requests/approved", {
    page: pages.RequestsApprove,
    permissions: [],
  })
  .set("/requests/approved/:requestId", {
    page: pages.RequestsApproveDetails,
    permissions: [],
  })
  .set("/company", {
    page: pages.Company,
    permissions: [],
  })
  .set("/company/user-list", {
    page: pages.CompanyListPage,
    permissions: [],
  })
  .set("/company/blocked", {
    page: pages.CompanyBlockedPage,
    permissions: [],
  })
  .set("/company/:companyId", {
    page: pages.CompanyView,
    permissions: [],
  })
  .set("/company/edit/:id", {
    page: pages.CompanyUpdatePage,
    permissions: [],
  })
  .set("/tariffs", {
    page: pages.Tariffs,
    permissions: [],
  })
  .set("/orders", {
    page: pages.OrdersPage,
    permissions: [PERMISSIONS.view_order],
  })
  .set(`/orders/${orderStatuses.done.link}`, {
    page: pages.DoneOrdersPage,
    permissions: [PERMISSIONS.view_order],
  })
  .set(`/orders/${orderStatuses.done.link}/:id`, {
    page: pages.DoneDetailAction,
    permissions: [PERMISSIONS.view_order],
  })
  .set(`/orders/${orderStatuses.unassained.link}`, {
    page: pages.UnassainedOrdersPage,
    permissions: [PERMISSIONS.view_order],
  })
  .set(`/orders/${orderStatuses.unassained.link}/:id`, {
    page: pages.UnnassianedDetailAction,
    permissions: [PERMISSIONS.view_order],
  })
  .set(`/orders/${orderStatuses.assigned.link}`, {
    page: pages.AssaingedOrdersPage,
    permissions: [PERMISSIONS.view_order],
  })
  .set(`/orders/${orderStatuses.assigned.link}/:id`, {
    page: pages.AssignedDetailAction,
    permissions: [PERMISSIONS.view_order],
  })
  .set(`/orders/${orderStatuses.diagnosed.link}`, {
    page: pages.DiagnosedOrdersPage,
    permissions: [PERMISSIONS.view_order],
  })
  .set(`/orders/${orderStatuses.diagnosed.link}/:id`, {
    page: pages.DiagnosedDetailAction,
    permissions: [PERMISSIONS.view_order],
  })
  .set(`/orders/${orderStatuses.authorize.link}`, {
    page: pages.AuthorizeOrdersPage,
    permissions: [PERMISSIONS.view_order],
  })
  .set(`/orders/${orderStatuses.authorize.link}/:id`, {
    page: pages.AuthorizeDetailAction,
    permissions: [PERMISSIONS.view_order],
  })
  .set(`/orders/${orderStatuses.repair.link}`, {
    page: pages.RepairOrdersPage,
    permissions: [PERMISSIONS.view_order],
  })
  .set(`/orders/${orderStatuses.repair.link}/:id`, {
    page: pages.RepairDetailAction,
    permissions: [PERMISSIONS.view_order],
  })
  .set(`/orders/${orderStatuses.invoiced.link}`, {
    page: pages.InvoicedOrdersPage,
    permissions: [PERMISSIONS.view_order],
  })
  .set(`/orders/${orderStatuses.invoiced.link}/:id`, {
    page: pages.InvoicedDetailAction,
    permissions: [PERMISSIONS.view_order],
  })
  .set("/orders/history", {
    page: pages.HistoryListPage,
    permissions: [PERMISSIONS.view_orderhistory],
  })
  .set("/orders/on-hold/:id", {
    page: pages.OnHoldDetailPage,
    permissions: [PERMISSIONS.view_order],
  })
  .set("/orders/on-hold", {
    page: pages.OnHoldOrdersPage,
    permissions: [PERMISSIONS.view_order],
  })
  .set("/orders/estimates", {
    page: pages.EstimatesOrdersPage,
    permissions: [PERMISSIONS.view_order],
  })
  .set("/orders/estimates/new", {
    page: pages.EstimatesOrderAddPage,
    permissions: [PERMISSIONS.view_order],
  })
  .set("/orders/estimates/all", {
    page: pages.EstimatesAllList,
    permissions: [PERMISSIONS.view_order],
  })
  .set("/orders/estimates/open", {
    page: pages.EstimatesOpenList,
    permissions: [PERMISSIONS.view_order],
  })
  .set("/orders/estimates/close", {
    page: pages.EstimatesCloseList,
    permissions: [PERMISSIONS.view_order],
  })
  .set("/orders/estimates/:id", {
    page: pages.EstimateDetailPage,
    permissions: [PERMISSIONS.view_order],
  })
  .set("/orders/new", {
    page: pages.ServiceOrderPage,
    permissions: [PERMISSIONS.add_order],
  })

  .set("/parts", {
    page: pages.PartDashboardPage,
    permissions: [PERMISSIONS.view_part],
  })
  .set("/parts/estimate", {
    page: pages.PartEstimatePage,
    permissions: [PERMISSIONS.view_part],
  })
  .set("/parts/price", {
    page: pages.PartPricePage,
    permissions: [PERMISSIONS.view_part],
  })
  .set("/parts/list", {
    page: pages.PartListPage,
    permissions: [PERMISSIONS.view_part],
  })
  .set("/parts/inventory/new", {
    page: pages.PartAddPage,
    permissions: [PERMISSIONS.add_part],
  })
  .set("/parts/inventory/edit/:id", {
    page: pages.PartUpdatePage,
    permissions: [PERMISSIONS.change_part],
  })
  .set("/parts/inventory", {
    page: pages.PartInventoryPage,
    permissions: [PERMISSIONS.view_part],
  })
  .set("/parts/inventory/:id", {
    page: pages.PartInventoryDetailPage,
    permissions: [PERMISSIONS.change_part],
  })
  .set(`${PURCHASE_ORDER_ROUTE.UPDATE}:id`, {
    page: pages.PurchaseOrderUpdatePage,
    permissions: [PERMISSIONS.view_part],
  })
  .set(PURCHASE_ORDER_ROUTE.ADD, {
    page: pages.PurchaseOrderAddPage,
    permissions: [PERMISSIONS.view_part],
  })
  .set(PURCHASE_ORDER_ROUTE.LIST, {
    page: pages.PurchaseOrderListPage,
    permissions: [PERMISSIONS.view_part],
  })
  .set("/offices", {
    page: pages.OfficeInvoice,
    permissions: [],
  })
  .set("/pm", {
    page: pages.PMPage,
    permissions: [],
  })
  .set("/schedule", {
    page: pages.SchedulePage,
    permissions: [],
  })
  .set("/reporting", {
    page: pages.ReportingPage,
    permissions: [],
  })
  .set("/services", {
    page: pages.ServiceListPage,
    permissions: [PERMISSIONS.view_shopservice],
  })
  .set("/services/new", {
    page: pages.ServiceAddPage,
    permissions: [PERMISSIONS.add_shopservice],
  })
  .set("/services/:id", {
    page: pages.ServiceDetailPage,
    permissions: [PERMISSIONS.view_shopservice],
  })
  .set("/services/edit/:id", {
    page: pages.ServiceUpdatePage,
    permissions: [PERMISSIONS.change_shopservice],
  })
  .set("/vendors", {
    page: pages.VendorListPage,
    permissions: [PERMISSIONS.view_vendor],
  })
  .set("/vendors/new", {
    page: pages.VendorAddPage,
    permissions: [PERMISSIONS.add_vendor],
  })
  .set("/vendors/edit/:id", {
    page: pages.VendorUpdatePage,
    permissions: [PERMISSIONS.change_vendor],
  })
  .set("/vendors/:id", {
    page: pages.VendorDetailPage,
    permissions: [PERMISSIONS.view_vendor],
  })
  .set("/customers", {
    page: pages.CustemersListPage,
    permissions: [PERMISSIONS.view_user],
  })
  .set("/customers/new", {
    page: pages.CustomersAddPage,
    permissions: [PERMISSIONS.add_user],
  })
  .set("/customers/edit/:id", {
    page: pages.CustomersUpdatePage,
    permissions: [PERMISSIONS.change_user],
  })

  .set("/customers/info/:id", {
    page: pages.CustomersInfoPage,
    permissions: [PERMISSIONS.view_user],
  })
  .set("/customers/contacts/edit/:id", {
    page: pages.ContactUpdatePage,
    permissions: [PERMISSIONS.change_contact],
  })
  .set("/customers/contacts/:id", {
    page: pages.ContactDetailPage,
    permissions: [PERMISSIONS.view_contact],
  })
  .set("/customers/contacts/new", {
    page: pages.ContactAddPage,
    permissions: [PERMISSIONS.add_contact],
  })
  .set("/help", {
    page: pages.HelpPage,
    permissions: [],
  })
  .set("/support", {
    page: pages.SupportPage,
    permissions: [],
  })
  .set("/", {
    page: pages.Home2,
    permissions: [],
  });

export const routeArray = Array.from(routeMap, ([path, r]) => ({ ...r, path }));
