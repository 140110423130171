import React from "react";
import Layout from "Components/Layout/Layout";
import AddShop from "Containers/Shop/AddShop";
import { BreadcrumbItem } from "Components/Breadcrumb/Breadcrumb";
import { useBreadcrumb } from "Shared/Hooks/use-breadcrumb";

export const ShopAddPage: React.FC = () => {
  const breads: BreadcrumbItem[] = [
    { title: "Shop", link: "/shops" },
    { title: "Create Shop", link: "" },
  ];

  useBreadcrumb({ links: breads });

  return (
    <Layout>
      <AddShop />
    </Layout>
  );
};
