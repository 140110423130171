import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "Service/api/api";
import {
  IDispatchCallback,
  IDispatchCallbackWithId,
  IDispatchCallbackWithoutData,
} from "Shared/Types/common";
import { ISupplyRateAdd, ISupplyRateUpdate } from "Shared/Types/supplyRate";
import { errorHandler } from "Shared/Utils/app";

const getSupplyRateList = createAsyncThunk(
  "supplyRate/getSupplyRateList",
  async () => {
    try {
      const response = await api.supplyRate.getSupplyRateList();
      return response.data;
    } catch (error) {
      if (error instanceof Error) {
        errorHandler(error, true);
      }
      throw error;
    }
  },
);

const getSupplyRate = createAsyncThunk(
  "supplyRate/getSupplyRate",
  async (id: string) => {
    try {
      const response = await api.supplyRate.getSupplyRate(id);
      return response.data;
    } catch (error) {
      if (error instanceof Error) {
        errorHandler(error, true);
      }
      throw error;
    }
  },
);

const addSupplyRate = createAsyncThunk(
  "supplyRate/createSupplyRate",
  async ({ data, onSuccess }: IDispatchCallback<ISupplyRateAdd>) => {
    try {
      const response = await api.supplyRate.createSupplyRate(data);
      onSuccess?.();
      return response.data;
    } catch (error) {
      if (error instanceof Error) {
        errorHandler(error, true);
      }
      throw error;
    }
  },
);

const updateSupplyRate = createAsyncThunk(
  "supplyRate/updateSupplyRate",
  async ({
    id,
    data,
    onSuccess,
  }: IDispatchCallbackWithId<ISupplyRateUpdate>) => {
    try {
      const response = await api.supplyRate.updateSupplyRate(id, data);
      onSuccess?.();
      return response.data;
    } catch (error) {
      if (error instanceof Error) {
        errorHandler(error, true);
      }
      throw error;
    }
  },
);

const deleteSupplyRate = createAsyncThunk(
  "supplyRate/deleteSupplyRate",
  async ({ id, onSuccess }: IDispatchCallbackWithoutData) => {
    try {
      const response = await api.supplyRate.deleteSupplyRate(id);
      onSuccess?.();
      return response.data;
    } catch (error) {
      if (error instanceof Error) {
        errorHandler(error, true);
      }
      throw error;
    }
  },
);

export {
  getSupplyRateList,
  addSupplyRate,
  deleteSupplyRate,
  updateSupplyRate,
  getSupplyRate,
};
