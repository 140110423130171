import { BreadcrumbItem } from "Components/Breadcrumb/Breadcrumb";
import { clearBreadcrumb, pushBreadcrumb } from "Redux/App/slice";
import { useEffect } from "react";
import { useDispatch } from "react-redux";

type Option = {
  upd?: string | null;
  link?: string;
  label?: string;
};

type BreadcrumbProps = {
  links: BreadcrumbItem[];
  createLink?: string;
  createTitle?: string;
  handleClickToCreate?: () => void;
  option?: Option;
  isNeedCreateBtn?: boolean;
};

export function useBreadcrumb({
  links,
  option,
  createLink = "new",
  createTitle = "new",
  handleClickToCreate,
  isNeedCreateBtn,
}: BreadcrumbProps) {
  const dispatch = useDispatch();

  const clearBreadcrumbOnUnmount = () => {
    dispatch(clearBreadcrumb());
  };

  useEffect(() => {
    dispatch(
      pushBreadcrumb({
        links,
        createLink,
        createTitle,
        isNeedCreateBtn,
        handleClickToCreate,
      })
    );

    return clearBreadcrumbOnUnmount;
  }, [option?.upd]);
}
