import {
  AllOrdersIcon as HomeSOsIcon,
  PlusIcon,
  ReturnsIcon,
  StatsIcon,
  ProfileIcon,
} from "Assets/index";
import FilterCard from "Components/Cards/FilterCard/FilterCard";
import React from "react";
import styles from "./HomeFilter.module.scss";
import { Grid } from "@mui/material";
import { useSelector } from "react-redux";
import { selectAccount } from "Redux/Auth/slice";

const HomeFilter: React.FC = () => {
  const account = useSelector(selectAccount);

  return (
    <Grid className={styles.filter}>
      <FilterCard title="SO's" icon={HomeSOsIcon} color="blue" link="/" />
      <FilterCard
        title="Returns"
        icon={ReturnsIcon}
        color="green"
        link="/returns"
      />
      <FilterCard title="Stats" icon={StatsIcon} color="orange" link="/stats" />
      <FilterCard
        title="Profile"
        icon={account?.avatar ? account.avatar : ProfileIcon}
        color="ghost"
        link="/profile"
      />
    </Grid>
  );
};
export default HomeFilter;
