import { StyleSheet } from "@react-pdf/renderer";

export const styles = StyleSheet.create({
  head: {
    marginTop: 20,
  },
  headItem: {
    display: "flex",
    flexDirection: "row",
    marginBottom: 5,
  },
  headItemTitle: {
    fontWeight: "bold",
    marginRight: 10,
  },
  table: {
    paddingRight: 20,
  },
  tableHead: {
    display: "flex",
    flexDirection: "row",
    borderBottom: 1,
    paddingBottom: 5,
    marginRight: 20,
  },
  tableBody: {
    borderBottom: 1,
    paddingBottom: 5,
    marginRight: 20,
    marginBottom: 20,
  },
  tableRow: {
    display: "flex",
    flexDirection: "row",
    paddingTop: 3,
    paddingBottom: 3,
  },
  cell: {
    width: "15%",
  },
  partName: {
    width: "55%",
  },
});
