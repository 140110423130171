import React, { useEffect, useState } from "react";
import Block from "Components/UI/Block/Block";
import InfoField from "Components/UI/InfoField/InfoField";
import { useNavigate, useParams } from "react-router";
import { useAppDispatch } from "Redux/store";
import { useSelector } from "react-redux";
import styles from "./ContactDetail.module.scss";
import DeleteModal from "Components/UI/DialogWindow/DeleteModal";
import IconButton from "Components/UI/IconButton/IconButton";
import { DeleteIcon, EditIcon } from "Assets/index";
import { notifySuccess } from "Components/Notify/notify";
import { selectCurrentContact } from "Redux/Contact/slice";
import { deleteContact, getContact } from "Redux/Contact/reducer";
import { Grid } from "@mui/material";
import { getInFoContact } from "Shared/Utils/contact";

const ContactDetail = () => {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [showDelete, setShowDelete] = useState(false);
  const contact = useSelector(selectCurrentContact);
  const basicInfoContact = getInFoContact(contact);
  const goToEditPage = () => {
    navigate(`/customers/contacts/edit/${id}`);
  };

  const handleDialogClose = () => {
    setShowDelete(false);
  };

  const handleDialogOpen = () => {
    setShowDelete(true);
  };

  const handleDeleteContact = async () => {
    const onSuccess = () => {
      notifySuccess("Contact deleted");
      navigate(-1);
    };
    if (id) {
      await dispatch(deleteContact({ id, onSuccess }));
    }
  };

  useEffect(() => {
    if (id) {
      dispatch(getContact(id));
    }
  }, [id]);

  return (
    <div className={styles.detail}>
      <div className={styles.detail__head}>
        <div className={styles.detail__head__action}>
          <IconButton icon={EditIcon} onClick={goToEditPage} />
          <IconButton icon={DeleteIcon} onClick={handleDialogOpen} />
        </div>
      </div>
      <Block className={styles.detail__block}>
        <h3>Contact</h3>
        <Grid container>
          {basicInfoContact.map(({ label, value, xs }) => (
            <Grid item xs={xs} key={label}>
              <InfoField key={label} label={label} value={value?.toString()} />
            </Grid>
          ))}
        </Grid>
      </Block>
      <DeleteModal
        open={showDelete}
        handleClose={handleDialogClose}
        title="Delete"
        text="Are you sure...?"
        acceptTitle="Delete"
        accept={handleDeleteContact}
      />
    </div>
  );
};

export default ContactDetail;
