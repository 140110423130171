import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { BreadcrumbItem } from "Components/Breadcrumb/Breadcrumb";

interface IBreadcrumbs {
  links: BreadcrumbItem[];
  createLink?: string;
  createTitle?: string;
  handleClickToCreate?: () => void;
  isNeedCreateBtn?: boolean;
}

const initialState: IBreadcrumbs = {
  links: [],
};

const breadcrumbSlice = createSlice({
  name: "companyRequest",
  initialState,
  reducers: {
    pushBreadcrumb(state, action) {
      state.links = action.payload.links;
      state.createLink = action.payload.createLink;
      state.createTitle = action.payload.createTitle;
      state.handleClickToCreate = action.payload.handleClickToCreate;
      state.isNeedCreateBtn = action.payload.isNeedCreateBtn;
    },
    clearBreadcrumb(state) {
      state.links = [];
      state.createLink = "";
      state.createTitle = "";
      state.handleClickToCreate = undefined;
      state.isNeedCreateBtn = false;
    },
  },
});

export const { pushBreadcrumb, clearBreadcrumb } = breadcrumbSlice.actions;

export const selectBreadcrumb = (state: RootState) => state.breadcrumb;

export default breadcrumbSlice;
