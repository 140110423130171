import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../store";
import { ICategory } from "Shared/Types/category";
import {
  getCategoryList,
  getCategory,
  addCategory,
  updateCategory,
  deleteCategory,
} from "./reducer";

interface ICategoryState {
  categoryList?: ICategory[];
  currentCategory?: ICategory | null;
  loading: boolean;
}

const initialState: ICategoryState = {
  categoryList: [],
  currentCategory: null,
  loading: false,
};

const categorySlice = createSlice({
  name: "category",
  initialState,
  reducers: {
    removeCurrentCategory(state) {
      state.currentCategory = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getCategory.pending, (state) => {
      state.loading = true;
      state.currentCategory = null;
    });
    builder.addCase(getCategory.rejected, (state) => {
      state.loading = false;
      state.currentCategory = null;
    });
    builder.addCase(getCategory.fulfilled, (state, action) => {
      state.loading = false;
      state.currentCategory = action.payload;
    });

    builder.addCase(addCategory.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(addCategory.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(addCategory.fulfilled, (state) => {
      state.loading = false;
    });

    builder.addCase(getCategoryList.pending, (state) => {
      state.loading = true;
      state.categoryList = [];
    });
    builder.addCase(getCategoryList.rejected, (state) => {
      state.loading = false;
      state.categoryList = [];
    });
    builder.addCase(getCategoryList.fulfilled, (state, action) => {
      state.loading = false;
      state.categoryList = action.payload.results;
    });

    builder.addCase(updateCategory.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateCategory.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(updateCategory.fulfilled, (state) => {
      state.loading = false;
    });

    builder.addCase(deleteCategory.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(deleteCategory.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(deleteCategory.fulfilled, (state) => {
      state.loading = false;
    });
  },
});

export const { removeCurrentCategory } = categorySlice.actions;

export const selectCurrentCategory = (state: RootState) =>
  state.category.currentCategory;
export const selectCategoryLoading = (state: RootState) =>
  state.category.loading;
export const selectCategoryList = (state: RootState) =>
  state.category.categoryList;

export default categorySlice;
