import React, { useEffect, useState } from "react";
import styles from "./Location.module.scss";
import IconButton from "Components/UI/IconButton/IconButton";
import { DeleteIcon, EditIcon, PlusIcon } from "Assets/index";
import { useAppDispatch } from "Redux/store";
import { useSelector } from "react-redux";
import { selectLocationList } from "Redux/PartSettings/Location/slice";
import {
  deleteLocation,
  getLocationList,
} from "Redux/PartSettings/Location/reducer";
import { notifySuccess } from "Components/Notify/notify";
import DeleteModal from "Components/UI/DialogWindow/DeleteModal";
import { useNavigate } from "react-router";

const Location: React.FC = () => {
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [deleteId, setDeleteId] = useState<string | null>();

  const dispatch = useAppDispatch();
  const locationList = useSelector(selectLocationList) || [];
  const navigate = useNavigate();

  const handleDeleteDialogClose = () => {
    setOpenDeleteModal(false);
    setDeleteId(null);
  };
  const handleDeleteDialogOpen = (id: string) => {
    setOpenDeleteModal(true);
    setDeleteId(id);
  };

  const goToCreatePage = () => {
    navigate("/settings/part-settings/locations/new");
  };

  const onDelete = async () => {
    const onSuccess = () => {
      notifySuccess("Location deleted");
      handleDeleteDialogClose();
      dispatch(getLocationList(null));
    };
    if (deleteId != null) {
      await dispatch(deleteLocation({ id: deleteId, onSuccess }));
    }
  };
  const editButtonClick = (id: string) => {
    navigate(`/settings/part-settings/locations/${id}`);
  };

  useEffect(() => {
    dispatch(getLocationList(null));
  }, []);

  return (
    <div className={styles.location}>
      <div className={styles.location__addBtn}>
        <IconButton
          onClick={goToCreatePage}
          label="Create"
          width={30}
          height={30}
          icon={PlusIcon}
        />
      </div>
      <div className={styles.location__list}>
        <p className={styles.location__list__title}>Locations</p>
        {locationList.map((location) => (
          <div className={styles.location__list__item} key={location.id}>
            <p className={styles.location__list__item__name}>{location.name}</p>
            <div className={styles.location__list__item__buttons}>
              <IconButton
                icon={EditIcon}
                onClick={() => editButtonClick(location?.id)}
              />
              <IconButton
                onClick={() => handleDeleteDialogOpen(location?.id)}
                icon={DeleteIcon}
              />
            </div>
          </div>
        ))}
      </div>
      <DeleteModal
        acceptTitle="Delete"
        open={openDeleteModal}
        handleClose={handleDeleteDialogClose}
        title="Delete"
        text="Are you sure...?"
        accept={onDelete}
      />
    </div>
  );
};
export default Location;
