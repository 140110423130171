import React, { useEffect, useState } from "react";
import styles from "./InventoryInfo.module.scss";
import InventoryDetail from "./InventoryDetail/InventoryDetail";
import { DeleteIcon, EditIcon } from "Assets/index";
import IconButton from "Components/UI/IconButton/IconButton";
import Tabs from "Components/UI/Tabs/Tabs";
import DeleteModal from "Components/UI/DialogWindow/DeleteModal";
import { useNavigate, useParams } from "react-router";
import { useAppDispatch } from "Redux/store";
import { deletePart, getPart } from "Redux/Product/reducer";
import { notifySuccess } from "Components/Notify/notify";
import InventoryHistory from "./InventoryHistory/InventoryHistory";
const InventoryInfo: React.FC = () => {
  const [activeTab, setActiveTab] = useState(0);
  const [showDelete, setShowDelete] = useState(false);
  const dispatch = useAppDispatch();
  const [deleteId, setDeleteId] = useState<string | null>();
  const navigate = useNavigate();
  const { id } = useParams();

  const handleDialogClose = () => {
    setShowDelete(false);
    setDeleteId(null);
  };

  const handleDeleteInventory = async () => {
    const onSuccess = () => {
      notifySuccess("Part deleted");
      navigate(-1);
      handleDialogClose();
    };
    if (deleteId) {
      await dispatch(deletePart({ id: deleteId, onSuccess }));
    }
  };

  const handleDialogOpen = (id?: string) => {
    setShowDelete(true);
    setDeleteId(id);
  };

  const goToEditPage = (id?: string) => {
    if (!id) return;
    navigate(`/parts/inventory/edit/${id}`);
  };
  useEffect(() => {
    if (id) {
      dispatch(getPart(id));
    }
  }, []);
  return (
    <div className={styles.info}>
      <div className={styles.info__icons}>
        <IconButton
          icon={DeleteIcon}
          size="small"
          onClick={() => handleDialogOpen(id)}
        />
        <IconButton
          icon={EditIcon}
          size="small"
          onClick={() => goToEditPage(id)}
        />
      </div>

      <Tabs
        tabs={["Main Info", "History"]}
        tabPanels={[
          <InventoryDetail key="mainInfo" />,
          <InventoryHistory key="history" />,
        ]}
        activeTab={activeTab}
        onChange={setActiveTab}
      />

      <DeleteModal
        acceptTitle="Delete"
        open={showDelete}
        handleClose={handleDialogClose}
        title="Delete"
        text="Are you sure...?"
        accept={handleDeleteInventory}
      />
    </div>
  );
};
export default InventoryInfo;
