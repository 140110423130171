import React, { useEffect, useState } from "react";
import Select from "react-select";
import { Control, Controller } from "react-hook-form";
import cn from "classnames";
import styles from "./SelectField.module.scss";
import { customDarkStyles, customStyles } from "./styles";

export type OptionsProps = {
  label: string | null;
  value: string | number;
};

interface SelectProps {
  withControl?: boolean;
  control?: Control<any>;
  options: OptionsProps[] | undefined;
  name: string;
  containerClassName?: string;
  theme?: "primary" | "secondary";
  labelColor?: string;
  label?: string; // Add a new prop for the label
  required?: boolean;
  onChange?: (value?: string | number) => void;
  value?: string | number | null; // Add the value prop
  placeholder?: string;
  defaultValue?: OptionsProps;
  style?: React.CSSProperties;
  isSearchable?: boolean;
  textAlign?: "center" | "start";
  loading?: boolean;
  isClearable?: boolean;
  isDisabled?: boolean;
  onBlur?: (value?: string | number | null) => void;
  onInputChange?: (text?: string | null) => void;
}

const SelectField: React.FC<SelectProps> = ({
  name,
  options = [],
  withControl,
  control,
  theme = "primary",
  label,
  isSearchable,
  textAlign = "start",
  loading,
  isClearable,
  containerClassName,
  ...props
}) => {
  const [selected, setSelected] = useState<string | number | null>();
  const [searchText, setSearchText] = useState<string | null>();
  useEffect(() => {
    props.onInputChange?.(searchText);
  }, [searchText]);

  useEffect(() => {
    setSelected(props.value);
    setSearchText("");
  }, [props.value]);
  return (
    <div
      className={cn(
        styles.select,
        theme === "primary" ? styles.select_primary : styles.select_secondary,
        containerClassName
      )}
    >
      {label && (
        <label
          className={styles.select__label}
          id={name}
          style={{ color: props.labelColor }}
        >
          {label}
          {props.required && <span className={styles.select__error}>*</span>}
        </label>
      )}
      {withControl ? (
        <Controller
          control={control}
          name={name}
          render={({ field }) => (
            <Select
              {...props}
              {...field}
              isLoading={loading}
              isSearchable={isSearchable}
              styles={
                theme === "secondary"
                  ? customDarkStyles(textAlign)
                  : customStyles
              }
              placeholder=""
              defaultValue={props.defaultValue}
              value={options.find((option) => option.value === field.value)}
              onChange={(selectedOption) => {
                field.onChange(selectedOption?.value);
                props.onChange?.(selectedOption?.value);
              }}
              className={styles.select__input}
              options={options}
              isClearable={isClearable}
              components={{
                IndicatorSeparator: () => null,
              }}
            />
          )}
        />
      ) : (
        <Select
          {...props}
          onBlur={() => props.onBlur?.(selected)}
          name={name}
          isSearchable={isSearchable}
          styles={
            theme === "secondary" ? customDarkStyles(textAlign) : customStyles
          }
          value={options.find((option) => option.value === selected)}
          onChange={(selectedOption) => {
            props.onChange?.(selectedOption?.value);
            setSelected(selectedOption?.value);
          }}
          options={options}
          isClearable={isClearable}
          components={{
            IndicatorSeparator: () => null,
          }}
          onInputChange={(text) => setSearchText(text)}
        />
      )}
    </div>
  );
};

export default SelectField;
