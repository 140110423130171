import { ILaborRate } from "Shared/Types/laborRate";
import { IUser } from "Shared/Types/user";
import { IService } from "Shared/Types/service";
import { ICustomer } from "Shared/Types/customer";
import { IUnit } from "Shared/Types/unit";

export type IContact = {
  id: string;
  first_name: string;
  last_name: string;
  email: string;
  phone: string;
  shop: string;
  customer: string;
};

export type IContactSearchParams = {
  page?: number;
  per_page?: number;
  search?: string | null;
  customer?: string | null;
};

export type IContactUpdate = Omit<IContact, "id">;

export type IContactAdd = IContactUpdate;

export type IActionItem = {
  id?: number;
  index?: string;
  unit?: string;
  name?: string;
  complaint: string | IService;
  is_done?: boolean;
  is_pre_authorize?: boolean;
  is_skip_diagnosis?: boolean;
  invoice_hours: number;
  technician?: string;
  labor_rate?: string;
  service?: string;
};

export type IActionItemAdd = Omit<
  IActionItem,
  "technician" | "labor_rate" | "service"
> & {
  technician?: IUser | null;
  labor_rate?: ILaborRate | null;
  service?: IService | null;
};

export type IActionItemAddApi = IActionItem;
export type IActionItemUpdate = IActionItemAdd;

export type IActionItemSearchParams = {
  page?: number;
  per_page?: number;
};

export type IOrder = {
  id?: string;
  availability_date?: string;
  finish_date?: string;
  start_date?: string;
  access_unit?: string;
  deliver_unit?: string;
  purchase_order_number?: string;
  notes?: string;
  auth_blank?: boolean;
  is_shown_note?: boolean;
  invoice_hours?: number | null;
  created_at?: string;
  updated_at?: string;
  shop?: string;
  customer: string;
  unit: string;
  // service_requester?: number;
  time_status?: string;
  lead_technician?: string;
  chassis_miles?: string;
  action_items: IActionItemAdd[];
  order_gallery?: File[] | null;
  order_file?: File[] | null;
};

export const OrderInitialValues = {
  availability_date: "",
  finish_date: "",
  start_date: "",
  access_unit: "",
  deliver_unit: "",
  purchase_order_number: "",
  notes: "",
  auth_blank: false,
  is_shown_note: false,
  invoice_hours: 0,
  shop: "",
  customer: null,
  unit: null,
  service_requester: null,
  vin: "",
  time_status: "",
  lead_technician: null,
  chassis_miles: "",
  order_gallery: undefined,
  order_file: undefined,
  action_items: [],
};

export type IOrderApi = {
  id?: string;
  availability_date?: string;
  finish_date?: string;
  start_date?: string;
  access_unit?: string;
  deliver_unit?: string;
  purchase_order_number?: string;
  notes: string;
  auth_blank?: boolean;
  is_shown_note?: boolean;
  invoice_hours?: number | null;
  created_at?: string;
  updated_at?: string;
  shop?: string;
  customer: string;
  unit: string;
  // service_requester?: number;
  time_status?: string;
  lead_technician?: string;
  chassis_miles?: string;
  action_items: IActionItemAddApi[];
  order_gallery?: File[];
  order_file?: File[];
};

export type IOrderAdd = Omit<
  IOrder,
  | "id"
  | "created_at"
  | "updated_at"
  | "lead_technician"
  | "order_gallery"
  | "order_file"
  | "customer"
  | "unit"
  | "service_requester"
> & {
  lead_technician?: IUser | null;
  customer: ICustomer | null;
  unit: IUnit | null;
  service_requester?: IContact | null;
  order_gallery?: File[];
  order_file?: File[];
};

export type IOrderAddApi = Omit<
  IOrderApi,
  "id" | "created_at" | "updated_at" | "action_items"
> & {
  action_items: string;
  lead_technician?: string;
};
export type IOrderUpdate = IOrderAdd;

export type IOrderSearchParams = {
  page?: number;
  per_page?: number;
};
