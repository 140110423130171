import React, { ReactNode } from "react";
import styles from "./AuthFormContainer.module.scss";
import cn from "classnames";
import Icon from "Components/UI/Icon/Icon";
import { LogoIcon, LogoTitleIcon } from "Assets/index";
import { Link } from "react-router-dom";
import TermsOfSevice from "../TermsOfService/TermsOfSevice";

export type ILink = {
  name: string;
  url: string;
};
export type AuthFormContainerProps = {
  children: ReactNode;
  isLarge?: boolean;
  title: string;
  descrption?: string;
  links?: ILink[];
  isTerm?: boolean;
};

const AuthFormContainer: React.FC<AuthFormContainerProps> = ({
  children,
  isLarge,
  title,
  descrption,
  links,
  isTerm,
}) => {
  return (
    <div className={cn(styles.form, isLarge && styles.form_large)}>
      <div className={styles.form__container}>
        <div className={styles.form__container__icons}>
          <Icon width={80} component={LogoIcon} />
          <Icon width={170} height={80} component={LogoTitleIcon} />
        </div>
        <h2 className={styles.form__title}>{title}</h2>
        <p className={styles.form__container__description}>{descrption}</p>

        <div>{children}</div>

        <div className={styles.form__container__footer}>
          <div className={styles.form__container__footer__items}>
            {links?.map((el) => (
              <Link
                className={styles.form__container__footer__items__link}
                key={el.url}
                to={el.url}
              >
                <p>{el.name}</p>
              </Link>
            ))}
          </div>
          <hr />
        </div>
        {isTerm && <TermsOfSevice />}
      </div>
    </div>
  );
};
export default AuthFormContainer;
