import React from "react";
import { Grid } from "@mui/material";
import InputField from "Components/UI/Inputs/InputField";
import { Control } from "react-hook-form";
import { IVendorForm } from "./VendorForm";
import AttachmentWithoutCamera from "Components/Attachments/AttachmentWithoutCamera/AttachmentWithoutCamera";

type AdditionalDetailsProps = {
  control?: Control<IVendorForm>;
};

const AdditionalDetails: React.FC<AdditionalDetailsProps> = ({ control }) => {
  return (
    <div>
      <h4>Addional Details</h4>
      <Grid container spacing={1} rowSpacing={-1}>
        <Grid item xs={12}>
          <InputField
            multiline
            rows={3}
            name="additional_information"
            label="Additional information"
            control={control}
            withController
          />
        </Grid>
        <Grid item xs={12} display="flex" justifyContent="start" mt="16px">
          <AttachmentWithoutCamera isHaveDocument isHaveImg />
        </Grid>
      </Grid>
    </div>
  );
};
export default AdditionalDetails;
