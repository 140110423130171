import React, { useEffect } from "react";
import styles from "./Company.module.scss";
import { notifySuccess } from "Components/Notify/notify";
import { useAppDispatch } from "Redux/store";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import { selectCurrentCompany } from "Redux/Company/slice";
import { ICompanyUpdate } from "Shared/Types/company";
import { getCompany, updateCompany } from "Redux/Company/reducers";
import CompanyForm from "Components/Company/CompanyForm/CompanyForm";
import { companySchema } from "Shared/Utils/company";

export const CompanyUpdate: React.FC = () => {
  const dispatch = useAppDispatch();
  const company = useSelector(selectCurrentCompany);
  const params = useParams();
  const navigate = useNavigate();

  const onSubmit = async (values: ICompanyUpdate) => {
    if (params.id) {
      await dispatch(
        updateCompany({
          id: params.id,
          data: values,
          onSuccess: () => {
            notifySuccess("Company Updated");
            navigate(`/company/${params.id}`);
          },
        })
      );
    }
  };

  useEffect(() => {
    if (params.id) {
      dispatch(getCompany(params.id));
    }
  }, [params.id]);

  return (
    <div className={styles.update}>
      <div className={styles.update__form}>
        {company && (
          <CompanyForm
            onSubmit={onSubmit}
            schema={companySchema}
            company={company}
          />
        )}
      </div>
    </div>
  );
};
