import { yupResolver } from "@hookform/resolvers/yup";
import { Grid } from "@mui/material";
import { VendorSearch } from "Components/UI/AutoCompleteSearchInputs/VendorSearch";
import Block from "Components/UI/Block/Block";
import InfoField from "Components/UI/InfoField/InfoField";
import { readObj } from "Service/storage";
import {
  getPurchaseOrderInitialValues,
  getPurchaseOrderPartInitialValues,
  purchaseOrderCreateSchema,
} from "Shared/Utils/purchase-order";
import React, { useState } from "react";
import { FormProvider, useFieldArray, useForm } from "react-hook-form";
import PartItem from "./PartItem/PartItem";
import styles from "./PurchaseOrderCreateForm.module.scss";
import { DeleteIcon } from "Assets/index";
import Icon from "Components/UI/Icon/Icon";
import {
  IPurchaseOrderCreateFrom,
  IPurchaseOrderCreateOrders,
  IPurchaseOrderCreateParts,
} from "Shared/Types/purchase-order";
import OrderItem from "../OrderItem/OrderItem";
import Button from "Components/UI/Button/Button";
import Form from "Components/UI/Form/Form";

type PurchaseOrderCreateFormProps = {
  onSubmit: (values: IPurchaseOrderCreateFrom, isDraft: boolean) => void;
};

const PurchaseOrderCreateForm: React.FC<PurchaseOrderCreateFormProps> = ({
  onSubmit,
}) => {
  const shop = readObj("current_shop");
  const methods = useForm({
    resolver: yupResolver(purchaseOrderCreateSchema),
    defaultValues: getPurchaseOrderInitialValues(),
  });
  const [isDraft, setIsDraft] = useState(false);
  const filledParts = methods.watch("parts") || [];

  const {
    fields: partFields,
    append: partAppend,
    remove: partRemove,
  } = useFieldArray({
    control: methods.control,
    name: "parts",
  });

  const { fields: orderFields, replace } = useFieldArray({
    control: methods.control,
    name: "order_items",
  });

  const addPart = () => {
    const vendor = methods.getValues("vendor");
    partAppend(getPurchaseOrderPartInitialValues(vendor));
  };
  const deletePart = (id: number) => {
    partRemove(id);
  };

  const getOrders = () => {
    const orders: IPurchaseOrderCreateOrders[] = [];
    const vendors = new Map<string, IPurchaseOrderCreateParts[]>();
    filledParts.forEach((field) => {
      if (!field.vendor) return;
      if (vendors.has(field.vendor.id)) {
        vendors.get(field.vendor.id)?.push(field);
      } else {
        vendors.set(field.vendor.id, [field]);
      }
    });
    vendors.forEach((value) => {
      orders.push({
        vendor: value[0].vendor,
        contact: value[0].vendor?.contact_name || "",
        order_total: value.reduce(
          (acc, cur) => acc + (cur.price || 0) * (cur.quantity || 0),
          0
        ),
        order_difference: 0,
        order_threshold: 0,
        freight_difference: 0,
        freight_threshold: 0,
        pending_order: 0,
        parts: value,
      });
    });
    replace(orders);
  };
  return (
    <FormProvider {...methods}>
      <Form
        onSubmit={methods.handleSubmit((values) => onSubmit(values, isDraft))}
      >
        <Block title="Order Part">
          <Grid container>
            <Grid item container xs={12}>
              <Grid item xs={4}>
                <InfoField value={shop?.shop_name} label="Shop" />
              </Grid>
              <Grid item xs={4} />
              <Grid item xs={4}>
                <VendorSearch
                  withControl
                  name="vendor"
                  label="Default vendor"
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid item container xs={12} columnGap={2}>
            {partFields &&
              partFields.map((part, i) => (
                <div key={i} className={styles.form__partItem}>
                  <PartItem partIndex={i} getOrders={getOrders} />
                  <div className={styles.form__partItem__delete}>
                    <Icon
                      component={DeleteIcon}
                      onClick={() => deletePart(i)}
                    />
                  </div>
                </div>
              ))}
          </Grid>
          <span className={styles.form__createBtn} onClick={addPart}>
            + Add Part
          </span>

          <Grid container>
            {orderFields.map((order, i) => (
              <Grid item xs={12} key={order.vendor?.id}>
                <OrderItem orderIndex={i} />
              </Grid>
            ))}
          </Grid>
        </Block>

        <Grid container justifyContent="end" gap="10px" mt="10px">
          <Button
            type="submit"
            color="secondary"
            onClick={() => setIsDraft(true)}
          >
            Save as a draft
          </Button>
          <Button type="submit" onClick={() => setIsDraft(false)}>
            Order part
          </Button>
        </Grid>
      </Form>
    </FormProvider>
  );
};
export default PurchaseOrderCreateForm;
