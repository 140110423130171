import React, { useState } from "react";
import { Grid } from "@mui/material";
import { notifyError, notifySuccess } from "Components/Notify/notify";
import Block from "Components/UI/Block/Block";
import Button from "Components/UI/Button/Button";
import InputField from "Components/UI/Inputs/InputField";
import { sendEmail, updateOrderInvoice } from "Redux/OrderInvoice/reducer";
import { useAppDispatch } from "Redux/store";
import {
  IOrderInvoice,
  IOrderInvoiceData,
  IOrderInvoiceFields,
} from "Shared/Types/invoice";

type EditInvoiceFormProps = {
  invoice: IOrderInvoice;
  orderData: IOrderInvoiceData;
};

const EditInvoiceForm: React.FC<EditInvoiceFormProps> = ({
  invoice,
  orderData,
}) => {
  const dispatch = useAppDispatch();
  const [po, setPO] = useState(invoice.purchase_order);
  const [promiseToPay, setPromiseToPay] = useState(invoice.promise_to_pay);
  const [email, setEmail] = useState(orderData.emails?.[0]);
  const update = (field: IOrderInvoiceFields, value?: string) => {
    const onSuccess = () => {
      notifySuccess("Invioce is updated!");
    };
    dispatch(
      updateOrderInvoice({ data: { field, value }, id: invoice.id, onSuccess })
    );
  };
  const savePO = () => update("purchase_order", po.toString());
  const savePromise = () => update("promise_to_pay", promiseToPay.toString());
  const saveEmail = () => {
    if (!email) {
      notifyError("Email must be complited");
      return;
    }
    const onSuccess = () => {
      notifySuccess("Email is sent");
    };
    dispatch(
      sendEmail({ id: invoice.id, data: { recipients: [email] }, onSuccess })
    );
  };

  return (
    <Block mt={20}>
      <Grid container spacing={2} justifyContent="space-between">
        <Grid container spacing={1} item xs={12} md={4} alignItems="end">
          <Grid item xs={9}>
            <InputField
              name="po"
              value={po}
              onChange={(event) => setPO(+event.target.value)}
              label="PO"
              type="number"
            />
          </Grid>
          <Grid item xs={3} textAlign="end">
            <Button onClick={savePO}>Save</Button>
          </Grid>
        </Grid>
        <Grid item xs={12} md={6}>
          <div>
            <div>Send to</div>
            <hr />
          </div>
        </Grid>
        <Grid container spacing={1} item xs={12} md={4} alignItems="end">
          <Grid item xs={9}>
            <InputField
              name="promise_to_pay"
              value={promiseToPay}
              label="Promise to pay"
              onChange={(event) => setPromiseToPay(+event.target.value)}
              type="number"
            />
          </Grid>
          <Grid item xs={3} textAlign="end">
            <Button onClick={savePromise}>Save</Button>
          </Grid>
        </Grid>

        <Grid item xs={6}>
          <InputField
            name="email"
            value={email}
            onChange={(event) => setEmail(event.target.value)}
            label="Email"
            required
          />
        </Grid>

        <Grid item xs={12} textAlign="end">
          <Button onClick={saveEmail} color="secondary">
            Re-Send
          </Button>
        </Grid>
      </Grid>
    </Block>
  );
};
export default EditInvoiceForm;
