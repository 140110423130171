import { BreadcrumbItem } from "Components/Breadcrumb/Breadcrumb";
import Layout from "Components/Layout/Layout";
import { useBreadcrumb } from "Shared/Hooks/use-breadcrumb";
import React from "react";
import { EstimateOrderAdd } from "Containers/Order/Estimates/EstimateOrderAdd/EstimateOrderAdd";
import { getBreadcrumbs } from "Shared/Utils/estimate";

export const EstimatesOrderAddPage: React.FC = () => {
  const breads: BreadcrumbItem[] = getBreadcrumbs("Create Estimate");
  useBreadcrumb({ links: breads });

  return (
    <Layout>
      <EstimateOrderAdd />
    </Layout>
  );
};
