import AcynsSelectField from "Components/UI/AcyncSelect/AcyncSelect";
import { getServiceList } from "Redux/Service/reducer";
import { selectServiceList } from "Redux/Service/slice";
import { selectSystemLoading } from "Redux/System/systemSlice";
import { useAppDispatch } from "Redux/store";
import { IActionItemFields } from "Shared/Types/order";
import { IService } from "Shared/Types/service";
import { changeServiceOptions } from "Shared/Utils/app";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

type RecommendedServiceProps = {
  saveActionItemField: (field: IActionItemFields, value: string) => void;
  service?: IService;
};

const RecommendedServiceProps: React.FC<RecommendedServiceProps> = ({
  saveActionItemField,
  service,
}) => {
  const serviceList = useSelector(selectServiceList) || [];
  const serviceLoading = useSelector(selectSystemLoading);
  const dispatch = useAppDispatch();
  const [selected, setSelected] = useState<string>();
  const inputChange = (text: string | null) => {
    dispatch(getServiceList({ search: text }));
  };

  const onBlur = () => {
    if (selected) {
      saveActionItemField("recommended_service", selected);
    }
  };

  useEffect(() => {
    inputChange(null);
  }, []);

  return (
    <div>
      <AcynsSelectField
        options={changeServiceOptions(serviceList)}
        label="Recommended Service"
        loading={serviceLoading}
        defaultValue={{
          label: service?.name || "",
          value: service?.id || "",
        }}
        onInputChange={(text) => inputChange(text)}
        onChange={(value) => setSelected(value as string)}
        onBlur={onBlur}
      />
    </div>
  );
};
export default RecommendedServiceProps;
