import { IPart, IPartLocation } from "Shared/Types/part";
import { IVendor } from "Shared/Types/vendor";
import * as yup from "yup";
import {
  partEstimateStatusCard,
  partStatusCard,
  partStatuses,
} from "Shared/Constants/part";
import { ICardData } from "Shared/Types/common";
import { getShortValue } from "./order";
import { IEstimatesOrder, IEstimatesOrderStatus } from "Shared/Types/estimate";
import { format, isInstanced } from "./app";
import { IOrderList } from "Shared/Types/order";
import { IUnit } from "Shared/Types/unit";
import React from "react";

export const getPartCardData = (part: IPart) => {
  const SHOPT_VALUE = 16;
  const data: ICardData[] = [
    {
      title: "Name",
      value: getShortValue(part.description, 36),
      isOnlyValue: true,
    },
    { title: "Code", value: part.part_number },
    { title: "Price", value: part.price },
    { title: "Quantity", value: part.quantity },
    {
      title: "Vendor",
      value: getShortValue(part.vendor?.vendor_name, SHOPT_VALUE),
    },
    {
      title: "Category",
      value: getShortValue(part.category?.name, SHOPT_VALUE),
    },
    {
      title: "Manufacturer",
      value: getShortValue(part.manufacturer?.name, SHOPT_VALUE),
    },
    { title: "Location", value: getShortValue(part.location, SHOPT_VALUE) },
  ];

  return data;
};

export const partSchema = yup.object().shape({
  part_number: yup.string(),
  status: yup
    .string()
    .oneOf([
      "active",
      "inactive",
      "low_stock",
      "out_of_stock",
      "obsolete",
      "needs_ordered",
    ])
    .optional(),
  price: yup.number(),
  unit_of_measure: yup
    .string()
    .oneOf([
      "cm",
      "ea",
      "ft",
      "g",
      "gal",
      "in",
      "kg",
      "kit",
      "lb",
      "lf",
      "liter",
      "m",
      "ml",
      "oz",
      "pair",
      "pt",
      "qr",
      "set",
      "sq ft",
      "yd",
    ])
    .optional(),
  selling_price: yup.string(),
  quantity: yup.number(),
  description: yup.string(),
  image: yup.string(),
  shop: yup.string(),
  vendor: yup.mixed<IVendor>(),
  category: yup.string(),
  manufacturer: yup.string(),
  location: yup.string(),
  locations: yup.mixed<IPartLocation[]>(),
  min_quantity: yup.string(),
  max_quantity: yup.string(),
  in_stock: yup.string(),
  initial_stock: yup.number(),
  initial_cost: yup.string(),
  initial_average_cost: yup.string(),
  inventory_balance: yup.string(),
  average_cost: yup.string(),
});

export const getPartItemSellingPrice = (price?: number) => {
  if (!price) return 0;

  return +(+price + price * 0.3)?.toFixed?.(1);
};

function getTimeStatuses(monthCount: number, allCount: number) {
  return [
    {
      color: "purple",
      name: "This Month",
      count: monthCount,
    },
    {
      color: "blue_gradient",
      name: "To date",
      count: allCount,
    },
  ];
}

export function getPartStatuses() {
  return [
    {
      title: partStatuses.estimates.title,
      link: partStatuses.estimates.link,
      timeStatuses: getTimeStatuses(0, 0),
    },
    {
      title: partStatuses.price.title,
      link: partStatuses.price.link,
      timeStatuses: getTimeStatuses(0, 0),
    },
    {
      title: partStatuses.request.title,
      link: partStatuses.request.link,
      timeStatuses: getTimeStatuses(0, 0),
    },
    {
      title: partStatuses.order.title,
      link: partStatuses.order.link,
      timeStatuses: getTimeStatuses(0, 0),
    },
    {
      title: partStatuses.receive.title,
      link: partStatuses.receive.link,
      timeStatuses: getTimeStatuses(0, 0),
    },
    {
      title: partStatuses.return.title,
      link: partStatuses.return.link,
      timeStatuses: getTimeStatuses(0, 0),
    },
  ];
}

type PartStatusType = keyof typeof partStatusCard;

export const getPartCardStatus = (status?: PartStatusType) => {
  if (status) {
    return partStatusCard[status];
  }
};

export const getEstimatePartCardStatus = (status?: IEstimatesOrderStatus) => {
  if (status) {
    return partEstimateStatusCard[status];
  }
};

export const getEstimatePartCardData = (part: IEstimatesOrder) => {
  const SHOPT_VALUE = 16;
  const data: ICardData[] = [
    {
      title: "Estimate #",
      value: getShortValue(part.purchase_order_number, SHOPT_VALUE),
    },
    {
      title: "Unit",
      value: typeof part.unit === "string" ? part.unit : part.unit?.number,
    },
    {
      title: "Customer",
      value:
        typeof part.customer === "string"
          ? part.customer
          : part.customer?.company_name,
    },
    { title: "Created", value: format(part.created_at || "") },
    {
      title: "Service Writer",
      value: getShortValue(
        typeof part.estimate_creator === "string"
          ? part.estimate_creator.toString()
          : part.estimate_creator?.last_name,
        SHOPT_VALUE
      ),
    },
    { title: "Labor & Parts", value: "$" + part.labor_and_parts_total },
  ];

  return data;
};

export const getPartPriceCardData = (order: IOrderList) => {
  const SHORT_VALUE = 16;
  const arr: ICardData[] = [
    {
      title: "Part %",
      value: "0.00 %",
    },
    {
      title: "Order #",
      value: getShortValue(order.order_number, SHORT_VALUE),
    },
    {
      title: "PO #",
      value: getShortValue(order.purchase_order_number, SHORT_VALUE),
    },
    {
      title: "Unit",
      value: getShortValue(
        isInstanced<IUnit>(order.unit) ? order.unit.number : String(order.unit),
        SHORT_VALUE
      ),
      isValueBlue: true,
    },

    {
      title: "Customer",
      value: getShortValue(order.customer?.company_name, SHORT_VALUE),
    },
    {
      title: "SO Creator",
      value: getShortValue(order.service_requester, SHORT_VALUE),
    },
    {
      title: "Part Manager",
      value: " - ",
    },
  ];
  return arr;
};

export const getInventoryPartCardData = (
  part: IPart,
  inStockData: React.ReactNode
) => {
  const SHORT_VALUE = 16;

  const data: ICardData[] = [
    { title: "Part #", value: part.part_number },
    { title: "In Stock", value: inStockData, isValueBlue: true },
    { title: "Average Cost", value: part.price && `$ ${part.price}` },
    {
      title: "Selling Price",
      value: part.selling_price && `$ ${part.selling_price}`,
    },
    { title: "Max", value: part.max_quantity },
    { title: "Min", value: part.min_quantity },
    {
      title: "Location",
      value: getShortValue(part.location, SHORT_VALUE),
      isValueBlue: true,
    },
    {
      title: "Vendor",
      value: getShortValue(part.vendor?.vendor_name, SHORT_VALUE),
    },
  ];

  return data;
};

export const getInventoryInfo = (part?: IPart | null) => {
  const info = [
    {
      label: "Status",
      value: part?.status?.toString(),
      xs: 3,
    },
    { label: "Barcode", xs: 3 },
    {
      label: "Description",
      value: part?.description?.toString(),
      xs: 12,
    },
    {
      label: "Part#",
      value: part?.part_number,
      xs: 5,
    },
    {
      label: "Category",
      value: part?.category?.name,
      xs: 3,
    },
    {
      label: "Manufacturer:",
      value: part?.manufacturer?.name,
      xs: 5,
    },
    {
      label: "Price ($)",
      value: part?.price,
      xs: 4,
    },
    {
      label: "Selling price ($)",
      value: part?.selling_price,
      xs: 2,
    },
    {
      label: "Default Location: ",
      value: part?.location,
      xs: 5,
    },
    {
      label: "Min qty",
      value: part?.min_quantity,
      xs: 4,
    },
    {
      label: "Max qty",
      value: part?.max_quantity,
      xs: 2,
    },
    {
      label: "Preferred vendor",
      value: part?.vendor?.vendor_name,
      xs: 5,
    },
    {
      label: "Initial In Stock",
      value: part?.initial_stock,
      xs: 4,
    },
    {
      label: "Unit of measurement",
      value: part?.unit_of_measure,
      xs: 2,
    },

    {
      label: "Additional Info",
      // value: part?.additional_info,
      xs: 12,
    },
  ];
  const historyInfo = [
    {
      label: "Part#",
      value: part?.part_number,
      xs: 6,
    },
    {
      label: "Initial in Stock: #",
      value: part?.initial_stock,
      xs: 6,
    },
    {
      label: "Initial average Cost:#",
      value: part?.initial_average_cost,
      xs: 6,
    },
    {
      label: "Current in Stock#",
      value: part?.in_stock,
      xs: 6,
    },
    {
      label: "Current average Cost#",
      value: part?.average_cost,
      xs: 6,
    },
    {
      label: "Current Inventory Balance:#",
      value: part?.inventory_balance,
      xs: 6,
    },
  ];
  return { info, historyInfo };
};

export const getInventoryModalData = () => {
  const sortFields = [
    {
      label: "Update_at ",
      value: "update_at",
    },
    {
      label: "In stock",
      value: "in_stock",
    },
    {
      label: "Selling price",
      value: "selling_price",
    },
    {
      label: "Min",
      value: "min",
    },
    {
      label: "Max",
      value: "max",
    },
  ];
  return sortFields;
};
