import React from "react";
import styles from "./CustomerAdd.module.scss";

import { notifySuccess } from "Components/Notify/notify";
import { useAppDispatch } from "Redux/store";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import { customerScheme } from "Shared/Utils/customer";
import CustomerForm from "Components/Customer/CustomerForm/CustomerForm";
import { ICustomerAdd } from "Shared/Types/customer";
import { addCustomer } from "Redux/Customer/reducer";
import { selectAccount } from "Redux/Auth/slice";
import StepProgress from "Components/UI/StepProgress/StepProgress";
import { checkFirstAuth } from "Shared/Utils/app";

const step = <>Fill in customer details, including name, contact.</>;

export const CustomerAdd: React.FC = () => {
  const dispatch = useAppDispatch();
  const profile = useSelector(selectAccount);
  const navigate = useNavigate();
  const location = useLocation();
  const isFirstAuth = checkFirstAuth(profile);

  const onSubmit = async (values: ICustomerAdd) => {
    const onSuccess = () => {
      notifySuccess("Created Customer!");
      if (isFirstAuth) {
        goToUnit();
      } else if (location.state) {
        navigate(-1);
      } else {
        navigate("/customers");
      }
    };
    await dispatch(addCustomer({ data: values, onSuccess }));
  };

  const goToUnit = () => {
    navigate("/units/new");
  };

  return (
    <div className={styles.customerAdd}>
      {isFirstAuth && (
        <StepProgress description={step} active={4} title="Adding Customer" />
      )}
      <div className={styles.customerAdd__form}>
        <CustomerForm
          onSubmit={onSubmit}
          schema={customerScheme}
          onSkip={goToUnit}
          isFirstAuth={isFirstAuth}
        />
      </div>
    </div>
  );
};
