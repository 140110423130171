import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../store";
import { IManufacturer } from "Shared/Types/manufacturer";
import {
  getManufacturerList,
  getManufacturer,
  addManufacturer,
  updateManufacturer,
  deleteManufacturer,
} from "./reducer";

interface IManufacturerState {
  manufacturerList?: IManufacturer[];
  currentManufacturer?: IManufacturer | null;
  loading: boolean;
}

const initialState: IManufacturerState = {
  manufacturerList: [],
  currentManufacturer: null,
  loading: false,
};

const manufacturerSlice = createSlice({
  name: "manufacturer",
  initialState,
  reducers: {
    removeCurrentManufacturer(state) {
      state.currentManufacturer = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getManufacturer.pending, (state) => {
      state.loading = true;
      state.currentManufacturer = null;
    });
    builder.addCase(getManufacturer.rejected, (state) => {
      state.loading = false;
      state.currentManufacturer = null;
    });
    builder.addCase(getManufacturer.fulfilled, (state, action) => {
      state.loading = false;
      state.currentManufacturer = action.payload;
    });

    builder.addCase(addManufacturer.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(addManufacturer.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(addManufacturer.fulfilled, (state) => {
      state.loading = false;
    });

    builder.addCase(getManufacturerList.pending, (state) => {
      state.loading = true;
      state.manufacturerList = [];
    });
    builder.addCase(getManufacturerList.rejected, (state) => {
      state.loading = false;
      state.manufacturerList = [];
    });
    builder.addCase(getManufacturerList.fulfilled, (state, action) => {
      state.loading = false;
      state.manufacturerList = action.payload.results;
    });

    builder.addCase(updateManufacturer.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateManufacturer.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(updateManufacturer.fulfilled, (state) => {
      state.loading = false;
    });

    builder.addCase(deleteManufacturer.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(deleteManufacturer.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(deleteManufacturer.fulfilled, (state) => {
      state.loading = false;
    });
  },
});

export const { removeCurrentManufacturer } = manufacturerSlice.actions;

export const selectCurrentManufacturer = (state: RootState) =>
  state.manufacturer.currentManufacturer;
export const selectManufacturerLoading = (state: RootState) =>
  state.manufacturer.loading;
export const selectManufacturerList = (state: RootState) =>
  state.manufacturer.manufacturerList;

export default manufacturerSlice;
