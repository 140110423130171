import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { IOrderInvoice } from "Shared/Types/invoice";
import {
  addOrderInvoice,
  deleteOrderInvoice,
  getOrderInvoice,
  payOrderInvoice,
  updateOrderInvoice,
} from "./reducer";

interface IOrderInvoiceState {
  currentInvoice: IOrderInvoice | null;
  loading: boolean;
}

const initialState: IOrderInvoiceState = {
  currentInvoice: null,
  loading: false,
};

const orderInvoiceSlice = createSlice({
  name: "OrderInvoice",
  initialState,
  reducers: {
    removecurrentInvoice(state) {
      state.currentInvoice = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getOrderInvoice.pending, (state) => {
      state.loading = true;
      state.currentInvoice = null;
    });
    builder.addCase(getOrderInvoice.rejected, (state) => {
      state.loading = false;
      state.currentInvoice = null;
    });
    builder.addCase(getOrderInvoice.fulfilled, (state, action) => {
      state.loading = false;
      state.currentInvoice = action.payload;
    });

    builder.addCase(addOrderInvoice.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(addOrderInvoice.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(addOrderInvoice.fulfilled, (state) => {
      state.loading = false;
    });

    builder.addCase(updateOrderInvoice.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateOrderInvoice.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(updateOrderInvoice.fulfilled, (state) => {
      state.loading = false;
    });

    builder.addCase(deleteOrderInvoice.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(deleteOrderInvoice.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(deleteOrderInvoice.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(payOrderInvoice.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(payOrderInvoice.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(payOrderInvoice.fulfilled, (state) => {
      state.loading = false;
    });
  },
});

export const { removecurrentInvoice } = orderInvoiceSlice.actions;

export const selectCurrentInvoice = (state: RootState) =>
  state.orderInvoice.currentInvoice;
export const selectOrderInvoiceLoading = (state: RootState) =>
  state.orderInvoice.loading;
export default orderInvoiceSlice;
