import { yupResolver } from "@hookform/resolvers/yup";
import Button from "Components/UI/Button/Button";
import Form from "Components/UI/Form/Form";
import InputField from "Components/UI/Inputs/InputField";
import {
  ILocation,
  ILocationAdd,
  ILocationUpdate,
} from "Shared/Types/location";
import React from "react";
import { useForm } from "react-hook-form";
import { ObjectSchema } from "yup";
import styles from "./LocationForm.module.scss";

type LocationFormProps = {
  onSubmit: (values: ILocationAdd | ILocationUpdate) => void;
  location?: ILocation;
  schema: ObjectSchema<ILocationAdd | ILocationUpdate>;
  onSkip?: () => void;
  isFirstAuth?: boolean;
};

const LocationForm: React.FC<LocationFormProps> = ({
  onSubmit,
  location,
  schema,
  onSkip,
  isFirstAuth,
}) => {
  const { handleSubmit, control } = useForm({
    resolver: yupResolver(schema),
    defaultValues: location,
  });

  return (
    <Form className={styles.form} onSubmit={handleSubmit(onSubmit)} id="myform">
      <InputField
        name="name"
        label="Name"
        withController
        control={control}
        required
      />
      <InputField
        name="description"
        label="Description"
        withController
        control={control}
      />
      <div className={styles.form__actions}>
        {isFirstAuth && (
          <Button onClick={onSkip} color="secondary" type="button">
            Skip
          </Button>
        )}
        <Button type="submit">{location ? "Save" : "Create"}</Button>
      </div>
    </Form>
  );
};
export default LocationForm;
