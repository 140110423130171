import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";
import {
  IActionItem,
  IActionItemHistory,
  IActionItemStatus,
} from "Shared/Types/order";
import {
  createActionItem,
  deleteActionItem,
  entreeInternally,
  getActionItem,
  getActionItemHistory,
  getActionItemStatusList,
  markActionAsDiagnosed,
  readyToInvoice,
  underReview,
  updateActionItem,
  waitingForAuth,
} from "./reducer";

interface ActionItemState {
  actionItemList?: IActionItem[];
  actionItemHistoryList?: IActionItemHistory[];
  loading: boolean;
  statusList?: IActionItemStatus[];
}

const initialState: ActionItemState = {
  actionItemList: [],
  actionItemHistoryList: [],
  loading: false,
  statusList: [],
};

const actionItemSlice = createSlice({
  name: "actionItem",
  initialState,
  reducers: {
    clearActionItemHistory(state) {
      state.actionItemHistoryList = [];
    },
    patchActionItem(state, action) {
      const idx: number = (state.actionItemList || []).findIndex(
        (actionItem) => actionItem.id === action.payload.id
      );

      if (idx != -1) {
        const updatedAction = {
          ...(state.actionItemList || [])[idx],
          [action.payload.key]: action.payload.value,
        };
        state.actionItemList = [
          ...(state.actionItemList?.slice(0, idx) || []),
          updatedAction,
          ...(state.actionItemList?.slice(idx + 1) || []),
        ];
      }
    },
  },
  extraReducers(builder) {
    builder.addCase(getActionItem.pending, (state) => {
      state.loading = true;
    }),
      builder.addCase(getActionItem.rejected, (state) => {
        state.loading = false;
      }),
      builder.addCase(getActionItem.fulfilled, (state, action) => {
        state.loading = false;
        const idx: number = (state.actionItemList || []).findIndex(
          (actionItem) => actionItem.id === action.payload.id
        );
        if (idx === -1) {
          state.actionItemList = [
            ...(state.actionItemList || []),
            action.payload,
          ];
        } else {
          state.actionItemList = [
            ...(state.actionItemList?.slice(0, idx) || []),
            action.payload,
            ...(state.actionItemList?.slice(idx + 1) || []),
          ];
        }
      });
    builder.addCase(createActionItem.pending, (state) => {
      state.loading = true;
    }),
      builder.addCase(createActionItem.rejected, (state) => {
        state.loading = false;
      }),
      builder.addCase(createActionItem.fulfilled, (state) => {
        state.loading = false;
      });
    builder.addCase(updateActionItem.pending, (state) => {
      state.loading = true;
    }),
      builder.addCase(updateActionItem.rejected, (state) => {
        state.loading = false;
      }),
      builder.addCase(updateActionItem.fulfilled, (state) => {
        state.loading = false;
      }),
      builder.addCase(deleteActionItem.pending, (state) => {
        state.loading = true;
      }),
      builder.addCase(deleteActionItem.rejected, (state) => {
        state.loading = false;
      }),
      builder.addCase(deleteActionItem.fulfilled, (state, action) => {
        state.loading = false;
        const deleted = state.actionItemList?.findIndex(
          ({ id }) => id === action.payload
        );
        if (deleted != undefined && deleted > -1) {
          state.actionItemList = [
            ...(state.actionItemList?.slice(0, deleted) || []),
            ...(state.actionItemList?.slice(deleted + 1) || []),
          ];
        }
      });
    builder.addCase(markActionAsDiagnosed.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(markActionAsDiagnosed.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(markActionAsDiagnosed.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(getActionItemHistory.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getActionItemHistory.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(getActionItemHistory.fulfilled, (state, action) => {
      state.loading = false;
      state.actionItemHistoryList = action.payload;
    });
    builder.addCase(entreeInternally.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(entreeInternally.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(entreeInternally.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(readyToInvoice.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(readyToInvoice.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(readyToInvoice.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(underReview.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(underReview.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(underReview.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(waitingForAuth.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(waitingForAuth.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(waitingForAuth.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(getActionItemStatusList.fulfilled, (state, action) => {
      state.loading = false;
      state.statusList = action.payload;
    });
  },
});

export const { clearActionItemHistory } = actionItemSlice.actions;

export const selectActionItemLoading = (state: RootState) =>
  state.actionItem.loading;
export const selectActionItemList = (state: RootState) =>
  state.actionItem.actionItemList;
export const selectActionItemStatusList = (state: RootState) =>
  state.actionItem.statusList;
export const selectActionItemHistoryList = (state: RootState) =>
  state.actionItem.actionItemHistoryList;

export default actionItemSlice;
