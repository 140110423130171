import * as yup from "yup";
import { FieldValues, UseFormWatch } from "react-hook-form";
import {
  IEstimateComplaint,
  IEstimateOrderAdd,
  IEstimatesOrder,
  IEstimatesServiceAdd,
  IEstimatesUpdateFields,
} from "Shared/Types/estimate";
import {
  estimateBreadcrumbs,
  estimateStatuses,
} from "Shared/Constants/estimates";
import { format } from "Shared/Utils/app";

export const estimatesScheme = yup.object().shape({
  customer: yup.string().required("Customer is required"),
  shop: yup.string().required("Shop is required"),
  authorization: yup.string(),
  unit: yup.string().required("Unit is required"),
  purchase_order_number: yup.string(),
  description: yup.string(),
  lead_technician: yup.string(),
  action_items: yup.mixed<IEstimateComplaint[]>(),
});
export const estimatesRequiredFields: IEstimatesUpdateFields[] = [
  "customer",
  "shop",
  "unit",
];
export const estimatesServiceRequiredFields: (keyof Partial<IEstimatesServiceAdd>)[] =
  ["complaint", "invoice_hours", "authorized", "estimate", "labor_rate"];
export const checkRequiredFieldsFilled = (watch: UseFormWatch<FieldValues>) => {
  return estimatesRequiredFields.every((key) => watch()[key]);
};
export const getEstimateDefaultValues = (): IEstimateOrderAdd => {
  return {
    customer: "",
    shop: "",
    authorization: "",
    unit: "",
    purchase_order_number: "",
    description: "",
    lead_technician: "",
  };
};
export const getEstimateComplaintServiceDefaultValues = () => {
  return {
    service: {},
  };
};

export const getStatuses = (status?: string) => {
  if (status == "Active") {
    return {
      title: "Active",
      color: "green",
    };
  }

  if (status == "Inactive") {
    return {
      title: "Inactive",
      color: "lightRed",
    };
  }
};

function getTimeStatuses(type: "all" | "open" | "close", count: number) {
  const color = type === "all" ? "blue_gradient" : "purple";
  const name = type === "all" ? "To date" : "This Month";

  if (type === "all") {
    return [
      {
        color: color,
        name: name,
        count: count,
      },
    ];
  }

  return [
    {
      color: color,
      name: name,
      count: count,
    },
    {
      color: "blue_gradient",
      name: "To date",
      count: count,
    },
  ];
}

export function getEstimates(
  allEstimatesCount: number,
  openEstimatesCount: number,
  closedEstimatesCount: number,
) {
  return [
    {
      title: estimateStatuses.all.title,
      link: estimateStatuses.all.link,
      timeStatuses: getTimeStatuses("all", allEstimatesCount),
    },
    {
      title: estimateStatuses.open.title,
      link: estimateStatuses.open.link,
      timeStatuses: getTimeStatuses("open", openEstimatesCount),
    },
    {
      title: estimateStatuses.close.title,
      link: estimateStatuses.close.link,
      timeStatuses: getTimeStatuses("close", closedEstimatesCount),
    },
  ];
}

export const getBreadcrumbs = (title: string) => {
  return [...estimateBreadcrumbs, { title: title, link: "" }];
};

export const getEstimateCardFields = (estimate: IEstimatesOrder) => {
  return [
    { title: "Estimate #", value: estimate.description },
    {
      title: "Unit",
      value: (typeof estimate.unit !== "string" && estimate.unit?.name) || "",
    },
    {
      title: "Client",
      value:
        (typeof estimate.customer !== "string" &&
          estimate.customer?.first_name) ||
        "",
    },
    { title: "Hours", value: estimate.hours },
    { title: "Total (USD)", value: estimate.labor_and_parts_total },
    {
      title: "Created",
      value: format(estimate.created_at as string, "MM/DD/YYYY"),
    },
  ];
};

export const isPathMatched = (link: string, location: string) => {
  const paths = [
    "/orders/on-hold",
    "/orders/history",
    "/orders/estimates",
    "/returns",
    "/stats",
    "/profile",
    "/requests/all-requests",
    "/requests/review",
    "/requests/rejected",
    "/requests/approved",
    "/company",
    "/parts/inventory",
    "/parts/cycle-count",
    "/parts/purchase-order",
  ];

  return paths.some((path) => location.startsWith(path));
};

export const isLinkExcluded = (link: string) => {
  const exclusions = [
    "on-hold",
    "history",
    "estimates",
    "stats",
    "returns",
    "profile",
    "requests/all-requests",
    "requests/review",
    "requests/rejected",
    "requests/approved",
    "parts/inventory",
    "parts/cycle-count",
    "parts/purchase-order",
  ];

  return exclusions.every((exclusion) => !link.includes(exclusion));
};

export const getEstimateInfoFields = (estimate?: IEstimatesOrder | null) => {
  if (!estimate) {
    return;
  }

  return [
    {
      label: "Who is the customer?",
      value:
        typeof estimate.customer == "string"
          ? estimate.customer
          : `${estimate.customer?.first_name} ${estimate.customer?.last_name}`,
    },
    {
      label: "Estimate #",
      value:
        typeof estimate.estimate_creator == "string"
          ? estimate.estimate_creator
          : `${estimate.estimate_creator?.first_name} ${estimate.estimate_creator?.last_name}`,
    },
    {
      label: "Shop",
      value: estimate.shop,
    },
    {
      label: "Authorization #",
      value: estimate.authorization,
    },
    {
      label: "Unit",
      value: " ",
      value2:
        typeof estimate.unit == "string"
          ? estimate.unit
          : `${estimate.unit?.number} - ${estimate.unit?.year}, ${estimate.unit?.nickname},${estimate.unit?.name}, Vin:${estimate.unit?.vin}`,
    },
    {
      label: "PO #",
      value: estimate.purchase_order_number,
    },
    {
      label: "Description",
      value2: estimate.description,
    },
  ];
};
