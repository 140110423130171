import React from "react";
import styles from "./RolesAdd.module.scss";
import Icon from "Components/UI/Icon/Icon";
import { RightIcon } from "Assets/index";
import RoleForm from "Components/Settings/Roles/Form/Form";
import { IRolesAdd } from "Shared/Types/role";
import { useAppDispatch } from "Redux/store";
import { addRole } from "Redux/Role/reducers";
import { useSelector } from "react-redux";
import { notifySuccess } from "Components/Notify/notify";
import { useNavigate } from "react-router";
import StepProgress from "Components/UI/StepProgress/StepProgress";
import { settingLabels } from "Shared/Constants/app";
import { selectAccount } from "Redux/Auth/slice";
import { checkFirstAuth } from "Shared/Utils/app";
import { stepDescription } from "Containers/Home/Home";

const RolesAdd: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const profile = useSelector(selectAccount);
  const isFirstAuth = checkFirstAuth(profile);

  const back = () => {
    navigate("/settings/roles");
  };
  const createRole = async (values: IRolesAdd) => {
    const onSuccess = () => {
      notifySuccess("Created Role!");
      if (isFirstAuth) {
        navigate("/settings/unit-settings/subtypes/new");
      } else {
        back();
      }
    };
    await dispatch(addRole({ data: values, onSuccess }));
  };

  return (
    <div className={styles.role}>
      {isFirstAuth && (
        <StepProgress
          description={stepDescription}
          labels={settingLabels}
          title="Settings"
          active={0}
        />
      )}
      <div className={styles.role__head}>
        <Icon
          component={RightIcon}
          fontSize="small"
          width={10}
          onClick={back}
        />
        Create Role & Access
      </div>
      <div className={styles.role__permissions}>
        <RoleForm onSubmit={createRole} />
      </div>
    </div>
  );
};
export default RolesAdd;
