import React, { useEffect } from "react";
import { IAccount } from "Shared/Types/auth";
import styles from "./Cost.module.scss";
import { readObj } from "Service/storage";
import { getInvoiceRateFields } from "Shared/Utils/invoice";
import { useAppDispatch } from "Redux/store";
import { getSupplyRate } from "Redux/SupplyRate/reducer";
import { getTaxRate } from "Redux/TaxRate/recuder";
import cn from "classnames";
import { IOrderInvoiceData } from "Shared/Types/invoice";

type CostProps = {
  orderData: IOrderInvoiceData;
  profile: IAccount;
};

const Cost: React.FC<CostProps> = ({ orderData, profile }) => {
  const dispatch = useAppDispatch();
  let { shop } = profile;
  if (profile.is_company_admin) {
    const curShop = readObj("current_shop");
    shop = curShop;
  }

  useEffect(() => {
    if (profile.is_company_admin) {
      if (shop?.supply_rate) {
        dispatch(getSupplyRate(shop.supply_rate));
      }
      if (shop?.tax_rate && typeof shop.tax_rate !== "string") {
        dispatch(getTaxRate(shop.tax_rate.id));
      }
    }
  }, []);
  return (
    <div className={styles.cost}>
      {getInvoiceRateFields(orderData)?.map(({ title, value, isGreen }) => (
        <div key={title} className={styles.cost__item}>
          <div className={styles.cost__title}>{title}</div>
          <div
            className={cn(
              styles.cost__value,
              isGreen && styles.cost__value_green
            )}
          >
            {value}
          </div>
        </div>
      ))}
    </div>
  );
};
export default Cost;
