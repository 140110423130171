import React from "react";
import styles from "./EmployeeForm.module.scss";
import { Grid } from "@mui/material";
import InputField from "Components/UI/Inputs/InputField";
import Checkbox from "Components/UI/Inputs/Checkbox";
import { Control } from "react-hook-form";
import cn from "classnames";
import { useFormContext } from "react-hook-form";

type BasicInfoProps = {
  control: Control<any>;
  isAdd?: boolean;
  active: boolean | undefined;
  setActive: (active: boolean) => void;
};

const BasicInfo: React.FC<BasicInfoProps> = ({
  control,
  isAdd,
  active,
  setActive,
}) => {
  const { setValue } = useFormContext();
  return (
    <div className={cn(styles.block, styles.block__4)}>
      <h4 className={styles.form__form__title}>Basic Info</h4>
      <Grid item xs={12}>
        <Checkbox
          label="Active"
          name="status"
          checked={active}
          onChange={(_, checked) => {
            setActive(checked);
            setValue("is_active", checked);
          }}
        />
      </Grid>
      <Grid container spacing={1} rowSpacing={-1}>
        <Grid item xs={12}>
          <InputField
            name="first_name"
            label="First name"
            required
            withController
            control={control}
          />
        </Grid>

        <Grid item xs={12}>
          <InputField
            name="last_name"
            label="Last name"
            required
            withController
            control={control}
          />
        </Grid>

        <Grid item xs={12}>
          <InputField
            name="email"
            label="Email"
            required
            withController
            control={control}
          />
        </Grid>

        <Grid item xs={12}>
          <InputField
            name="phone"
            label="Phone number"
            required
            withController
            control={control}
          />
        </Grid>
        <Grid item xs={12}>
          <InputField
            name="password"
            label="Password"
            required={isAdd}
            withController
            control={control}
          />
        </Grid>
      </Grid>
    </div>
  );
};
export default BasicInfo;
