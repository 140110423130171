import Button from "Components/UI/Button/Button";
import { createSystem, updateSystem } from "Redux/System/reducers";
import { useAppDispatch } from "Redux/store";
import React, { useState } from "react";
import styles from "../ComponentSystemContainer.module.scss";
import InputField from "Components/UI/Inputs/InputField";
import { useSelector } from "react-redux";
import { ISystem } from "Shared/Types/system";
import { selectAccount } from "Redux/Auth/slice";
import { checkFirstAuth } from "Shared/Utils/app";
import { useNavigate } from "react-router";
import { notifySuccess } from "Components/Notify/notify";
import { getComponent } from "Redux/Component/reducers";

type SystemFormProps = {
  componentId: string;
  setShow?: (show: boolean) => void;
  system?: ISystem;
};

const SystemForm: React.FC<SystemFormProps> = ({
  componentId,
  setShow,
  system,
}) => {
  const [systemValue, setSystemValue] = useState(system?.name);
  const dispatch = useAppDispatch();
  const profile = useSelector(selectAccount);
  const isFirstAuth = checkFirstAuth(profile);
  const navigate = useNavigate();
  const onSystemCraete = () => {
    const onSuccess = () => {
      if (system) {
        notifySuccess("System updated");
        setShow?.(false);
        isFirstAuth && navigate("/services/new");
      } else if (systemValue) {
        notifySuccess("System created");
      }

      dispatch(getComponent(componentId));
    };
    if (systemValue) {
      if (system) {
        dispatch(
          updateSystem({
            data: {
              id: system.id,
              name: systemValue,
            },
            onSuccess,
            id: "",
          })
        );
      } else if (systemValue) {
        dispatch(
          createSystem({
            data: {
              name: systemValue,
              component: componentId,
            },
            onSuccess,
          })
        );
        setShow?.(false);
      }
    }
  };

  const goToCustomerAdd = () => {
    navigate("/customers/new");
  };

  const onCancel = () => {
    setSystemValue("");
    setShow?.(false);
  };

  return (
    <div
      style={isFirstAuth ? { marginLeft: "44px" } : undefined}
      className={styles.component__system__add}
    >
      <InputField
        value={systemValue}
        onChange={(e) => setSystemValue(e.target.value)}
        label="System name"
        name="name"
      />
      <div className={styles.component__add__action}>
        {isFirstAuth && (
          <Button onClick={goToCustomerAdd} color="secondary" type="button">
            Skip
          </Button>
        )}
        {!isFirstAuth && (
          <Button variant="outlined" onClick={onCancel}>
            Cancel
          </Button>
        )}

        <Button variant="contained" onClick={onSystemCraete}>
          {system ? "Save" : "Create"}
        </Button>
      </div>
    </div>
  );
};
export default SystemForm;
