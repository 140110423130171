import { CommentIcon, DeleteIcon } from "Assets/index";
import IconButton from "Components/UI/IconButton/IconButton";
import {
  addActionItemNote,
  deleteActionItemNotes,
  getActionItemNoteList,
} from "Redux/ActionItemNotes/reducer";
import {
  clear,
  resetNoteList,
  selectActionItemNoteError,
  selectActionItemNoteList,
  selectActionItemNoteSuccess,
} from "Redux/ActionItemNotes/slice";
import { useAppDispatch } from "Redux/store";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import styles from "./ActionItemNote.module.scss";
import InputField from "Components/UI/Inputs/InputField";
import Checkbox from "Components/UI/Inputs/Checkbox";
import Modal from "Components/UI/Modal/Modal";
import Button from "Components/UI/Button/Button";
import { format } from "Shared/Utils/app";
import { notifyError, notifySuccess } from "Components/Notify/notify";
import { selectAccount } from "Redux/Auth/slice";
import Icon from "Components/UI/Icon/Icon";
import DeleteModal from "Components/UI/DialogWindow/DeleteModal";
import Badge from "Components/UI/Badge/Badge";

type ActionItemNoteProps = {
  actionItemId: string;
  noteCount: string;
};

const ActionItemNote: React.FC<ActionItemNoteProps> = ({
  actionItemId,
  noteCount,
}) => {
  const dispatch = useAppDispatch();
  const notesList = useSelector(selectActionItemNoteList) || [];
  const success = useSelector(selectActionItemNoteSuccess);
  const error = useSelector(selectActionItemNoteError);
  const profile = useSelector(selectAccount);
  const [open, setOpen] = useState(false);
  const [deleteNoteId, setDeleteNoteId] = useState<string | null>();
  const [openNoteDeleteModal, setOpenNoteDeleteModal] = useState(false);
  const [newNote, setNewNote] = useState("");
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    dispatch(resetNoteList());
  };

  const addNote = () => {
    if (newNote === "") {
      notifyError(`note must be empty `);
      return;
    }
    dispatch(
      addActionItemNote({
        note: newNote,
        action_item: actionItemId,
        author: profile?.id,
      })
    );
  };

  const handlePartDialogClose = () => {
    setOpenNoteDeleteModal(false);
    setDeleteNoteId(null);
  };

  const handlePartDialogOpen = (id: string) => {
    setOpenNoteDeleteModal(true);
    setDeleteNoteId(id);
  };

  const handleDeletePart = () => {
    if (deleteNoteId) {
      dispatch(deleteActionItemNotes(deleteNoteId));
    }
  };

  useEffect(() => {
    dispatch(
      getActionItemNoteList({
        action_item: actionItemId,
      })
    );
  }, [actionItemId]);

  useEffect(() => {
    if (open) {
      if (success) {
        notifySuccess(success);
        setNewNote("");
        dispatch(clear());
        handlePartDialogClose();
      } else if (error) {
        notifyError(error);
        dispatch(clear());
      }
    }
    dispatch(
      getActionItemNoteList({
        action_item: actionItemId,
      })
    );
  }, [success, open]);
  return (
    <div className={styles.note}>
      <Badge messageLength={+noteCount}>
        <IconButton
          variant="primary"
          icon={CommentIcon}
          width={18}
          height={18}
          style={{ padding: "9.5px" }}
          onClick={handleOpen}
        />
      </Badge>

      <Modal isClosed open={open} onClose={handleClose} title="Note">
        {notesList.map((note) => (
          <div key={note.id} className={styles.note__modal__note}>
            <div className={styles.note__modal__note__head}>
              <div>{note.author.first_name + " " + note.author.last_name}</div>
              <div className={styles.note__modal__note__head__delete}>
                <span>{format(note.created_at)}</span>
                <Icon
                  component={DeleteIcon}
                  onClick={() => handlePartDialogOpen(note.id)}
                />
              </div>
            </div>
            <div className={styles.note__modal__note__text}>{note.note}</div>
          </div>
        ))}
        <InputField
          name="note"
          label="Add Note"
          multiline
          value={newNote}
          onChange={(event) => setNewNote(event.target.value)}
        />
        <Checkbox
          className={styles.note__modal__checkbox}
          name="for_customer"
          label="For Customer"
        />
        <div className={styles.note__modal__action}>
          <Button onClick={addNote}>Add Note</Button>
        </div>
      </Modal>
      <DeleteModal
        acceptTitle="Delete Action Item Note"
        open={openNoteDeleteModal}
        handleClose={handlePartDialogClose}
        title="Delete Action Item Note"
        text="Are you sure you want to delete this Action Item Note?"
        accept={handleDeletePart}
      />
    </div>
  );
};
export default ActionItemNote;
