import React, { useEffect, useState } from "react";
import styles from "./SubtypeList.module.scss";
import { useSelector } from "react-redux";
import { useAppDispatch } from "Redux/store";
import Table from "Components/UI/Table/Table";
import DeleteModal from "Components/UI/DialogWindow/DeleteModal";
import { useNavigate } from "react-router";
import { notifySuccess } from "Components/Notify/notify";
import { selectSubtypeList } from "Redux/Subtype/slice";
import { deleteSubtype, getSubtypeList } from "Redux/Subtype/reducer";
import { subtypeTableHeads } from "Shared/Constants/subtype";
import { renderCell } from "Components/Subtype/renderCell/renderCell";
import IconButton from "Components/UI/IconButton/IconButton";
import { PlusIcon } from "Assets/index";

const SubtypeList: React.FC = () => {
  const subtypes = useSelector(selectSubtypeList) || [];
  const dispatch = useAppDispatch();
  const [showDelete, setShowDelete] = useState(false);
  const [deleteId, setDeleteId] = useState<string | null>();
  const navigate = useNavigate();

  const handleDialogClose = () => {
    setShowDelete(false);
    setDeleteId(null);
  };

  const handleDialogOpen = (id: string) => {
    setShowDelete(true);
    setDeleteId(id);
  };

  const handleDeleteSubtype = async () => {
    const onSuccess = () => {
      notifySuccess("Subtype deleted");
      setShowDelete(false);
      dispatch(getSubtypeList(null));
    };
    if (deleteId) {
      await dispatch(deleteSubtype({ id: deleteId, onSuccess }));
    }
  };

  const goToCreateSubtype = () => {
    navigate("/settings/unit-settings/subtypes/new");
  };

  useEffect(() => {
    dispatch(getSubtypeList(null));
  }, []);

  return (
    <div className={styles.subtype}>
      <div className={styles.subtype__addBtn}>
        <IconButton
          onClick={goToCreateSubtype}
          label="Create"
          width={30}
          height={30}
          icon={PlusIcon}
        />
      </div>
      <Table
        title="Subtypes"
        columns={subtypeTableHeads}
        data={subtypes}
        renderCell={renderCell}
        openDelete={handleDialogOpen}
      />

      <DeleteModal
        open={showDelete}
        handleClose={handleDialogClose}
        title="Delete"
        text="Are you sure...?"
        acceptTitle="Delete"
        accept={handleDeleteSubtype}
      />
    </div>
  );
};
export default SubtypeList;
