import React from "react";
import { Grid } from "@mui/material";
import InputField from "Components/UI/Inputs/InputField";
import { Control } from "react-hook-form";
import { ICompanyForm } from "./CompanyForm";

type CompanyInformationProps = {
  control: Control<ICompanyForm>;
};

const CompanyInformation: React.FC<CompanyInformationProps> = ({ control }) => {
  return (
    <div>
      <h4>Company Information</h4>
      <Grid container spacing={2} rowSpacing={-1}>
        <Grid item xs={6}>
          <InputField
            name="legal_name"
            label="Legal name"
            required
            withController
            control={control}
          />
        </Grid>

        <Grid item xs={6}>
          <InputField
            name="fax"
            label="Fax"
            required
            withController
            control={control}
          />
        </Grid>

        <Grid item xs={6}>
          <InputField
            name="name"
            label="Company name"
            required
            withController
            control={control}
          />
        </Grid>

        <Grid item xs={6}>
          <InputField
            name="website"
            label="Website"
            required
            withController
            control={control}
          />
        </Grid>

        <Grid item xs={6}>
          <InputField
            name="email"
            label="Email"
            required
            withController
            control={control}
          />
        </Grid>

        <Grid item xs={6}>
          <InputField
            name="phone"
            label="Main Phone"
            required
            withController
            control={control}
          />
        </Grid>
      </Grid>
    </div>
  );
};
export default CompanyInformation;
